import { styled } from '~shared/lib/styles';

export const PlayersHeadRow = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto repeat(3, 80px)',
  paddingLeft: 14,

  [theme.breakpoints.down('mobile')]: {
    gridTemplateColumns: 'auto repeat(2, 60px) 50px',
    paddingLeft: 14,
  },
}));

export const PlayersHeadRowCell = styled('div')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 14 / 13,
  color: theme.palette.grey.A50,
}));

export const PlayersHeadRowCellCentered = styled(PlayersHeadRowCell)(() => ({
  textAlign: 'center',
}));

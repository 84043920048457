import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

interface NftShouldBeRecoveredRoot {
  $broken: boolean;
}

export const NftShouldBeRecoveredRoot = styled(Box, {
  name: 'NftShouldBeRecovered',
  slot: 'root',
})<NftShouldBeRecoveredRoot>(({ theme, $broken }) => ({
  position: 'absolute',
  right: 7,
  bottom: 8,
  background: $broken ? theme.palette.error.dark : theme.palette.warn.secondary,
  borderRadius: '20px',
  width: '20px',
  height: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

import { FC, Fragment, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useTheme } from '~shared/lib/styles';

import { getNftStatus } from '../../../lib';

import { NftFreezed } from './NftFreezed';
import { NftShouldBeRecovered } from './NftShouldBeRecovered';
import { NftBlocked } from './NftBlockedForEvent';
import { NftStatusBadge } from './NftStatusBadge';

import { NftCardStatesRoot } from './styled';
import { NftCardStatesProps } from './types';

export const NftCardStates: FC<NftCardStatesProps> = ({ nft, size }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const hasBackdrop = nft.isFreezed || nft.isBlockedForEvent;
  const status = useMemo(() => getNftStatus(nft), [nft]);

  const isBroken = nft.livesRemaining === 0;

  const getStatusNode = () => {
    switch (true) {
      case status.isBlockedForTournament:
        return (
          <NftBlocked
            label={
              <Trans i18nKey="State.blockedForTournament" t={t}>
                Blocked <br /> till start
              </Trans>
            }
          />
        );

      case status.isBlockedForEvent:
        return (
          <Fragment>
            <NftBlocked
              label={
                <Trans i18nKey="State.blockedForEvent" t={t}>
                  Blocked <br /> for event
                </Trans>
              }
            />

            {nft.isLive && (
              <NftStatusBadge background={palette.violet.main}>{t('State.live')}</NftStatusBadge>
            )}
          </Fragment>
        );
      case status.isOnAuction:
        return (
          <Fragment>
            <NftStatusBadge background={palette.paper.main}>{t('State.auction')}</NftStatusBadge>
            {status.isShouldBeRecovered && <NftShouldBeRecovered broken={isBroken} />}
          </Fragment>
        );
      case status.isOnEvent:
        return (
          <NftStatusBadge background={palette.primary.main}>{t('State.onEvent')}</NftStatusBadge>
        );
      case status.isFreezed:
        return <NftFreezed />;
      case status.isShouldBeRecovered:
        return <NftShouldBeRecovered broken={isBroken} />;
      case status.isAvailableForMerge:
        return (
          <NftStatusBadge background={palette.success.main}>{t('State.merge')}</NftStatusBadge>
        );
    }
  };

  const statusNode = getStatusNode();

  return (
    <NftCardStatesRoot $size={size} $hasBackdrop={hasBackdrop}>
      {statusNode}
    </NftCardStatesRoot>
  );
};

import { FC, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~shared/ui';

import { useTournamentsModel } from '~entities/tournament';

import {
  Notification,
  NotificationAction,
  NotificationIconPlate,
  NotificationMessage,
} from '../../../factory';

import { NotificationTournamentDelayedProps } from './types';

export const NotificationTournamentDelayed = forwardRef<
  HTMLDivElement,
  NotificationTournamentDelayedProps
>(({ notification, place }, ref) => {
  const { t } = useTranslation();

  const { openTournamentDialogCurry } = useTournamentsModel();

  return (
    <Notification
      {...notification}
      ref={ref}
      place={place}
      icon={
        <NotificationIconPlate backgroundLight>
          <Icon name="battle" />
        </NotificationIconPlate>
      }
    >
      <NotificationMessage> {t('Notifications.tournamentDelayed')}</NotificationMessage>
      <NotificationAction onClick={openTournamentDialogCurry(notification.payload.tournamentId)}>
        {t('Notifications.openTournament')}
      </NotificationAction>
    </Notification>
  );
});

import { Icon, Typography } from '~shared/ui';

import { BoxMessage } from './../styled';

export const FirstBid = () => {
  return (
    <BoxMessage>
      <Icon name="fire" size={24} />
      <Typography color="secondary" fontSize={12} ml={1}>
        Wow! Your card got the first bid!
      </Typography>
    </BoxMessage>
  );
};

import { FC, Fragment, cloneElement } from 'react';

import { useTranslation } from 'react-i18next';

import { BattleResult, RawDifficulty } from '~shared/api';

import { isDrawSlotAvailable } from '~entities/battle';
import { EventDialogTab, useEventModel } from '~entities/event';

import { Typography } from '~shared/ui';

import { UserCaptionVote } from './UserCaptionVote';

import { Root, TeamCaption } from './styled';
import { EventUserCaptionsProps } from './types';

export const EventUserCaptions: FC<EventUserCaptionsProps> = () => {
  const { t } = useTranslation();
  const {
    event,
    choice,

    isViewerCall,
    isMakeCall,
    tab,
    noBattleForEvent,
    noCallForEvent,
    call: { callAcceptor, callCreator },
    user,
  } = useEventModel();

  if (!event || (tab === EventDialogTab.Call ? noCallForEvent : noBattleForEvent) || !user) {
    return null;
  }

  const isDrawResultAvailable = isDrawSlotAvailable(event.sport);

  const getViewerCaption = (result: BattleResult | null) => {
    switch (result) {
      case BattleResult.Draw:
        return `${t('Other.draw')}`;
      case BattleResult.HomeTeamWon:
        return (
          <Fragment>
            <Typography component="span">{t('BattlePopUp.onlyWin')}</Typography>
            &nbsp;
            {event.homeTeam.players.map((player) => player.shortName).join(', ')}
          </Fragment>
        );
      case BattleResult.AwayTeamWon:
        return (
          <Fragment>
            <Typography component="span">{t('BattlePopUp.onlyWin')}</Typography>
            &nbsp;
            {event.awayTeam.players.map((player) => player.shortName).join(', ')}
          </Fragment>
        );
    }
  };

  if (!choice && (tab === EventDialogTab.Call ? !callAcceptor && !isViewerCall : true)) {
    return (
      <Root>
        <TeamCaption align="left" justifySelf="start">
          {getViewerCaption(BattleResult.HomeTeamWon)}
        </TeamCaption>
        {isDrawResultAvailable && (
          <TeamCaption align="center" justifySelf="center">
            {getViewerCaption(BattleResult.Draw)}
          </TeamCaption>
        )}
        <TeamCaption align="right" justifySelf="end">
          {getViewerCaption(BattleResult.AwayTeamWon)}
        </TeamCaption>
      </Root>
    );
  }

  if (tab === EventDialogTab.Battle) {
    // TODO: rewrite this part
    let coef = null;

    switch (choice) {
      case BattleResult.HomeTeamWon: {
        coef = event.matchDifficulty.home;
        break;
      }

      case BattleResult.Draw: {
        coef = event.matchDifficulty.draw;
        break;
      }

      case BattleResult.AwayTeamWon: {
        coef = event.matchDifficulty.away;
        break;
      }
    }

    return (
      <Root>
        <UserCaptionVote
          avatar={user.avatar}
          nickname={user.nickname}
          caption={<TeamCaption>{getViewerCaption(choice ?? null)}</TeamCaption>}
          coef={coef}
        />
      </Root>
    );
  }

  // if (isMakeCall) {
  //   return (
  //     <Root>
  //       <UserCaptionVote
  //         avatar={user.avatar}
  //         nickname={user.nickname}
  //         caption={<TeamCaption>{getViewerCaption(choice)}</TeamCaption>}
  //       />
  //     </Root>
  //   );
  // }

  // if (tab === EventDialogTab.Call) {
  //   const participants = [
  //     <UserCaptionVote
  //       key="creator"
  //       avatar={callCreator?.avatar}
  //       nickname={callCreator?.nickname}
  //       caption={<TeamCaption>{getViewerCaption(callCreator?.choiceId ?? null)}</TeamCaption>}
  //     />,
  //     <UserCaptionVote
  //       key="acceptor"
  //       avatar={callAcceptor?.avatar}
  //       nickname={callAcceptor?.nickname}
  //       caption={
  //         <TeamCaption>
  //           {getViewerCaption(callAcceptor?.card ? callAcceptor.choiceId! : choice ?? null)}
  //         </TeamCaption>
  //       }
  //     />,
  //   ];

  //   if (callCreator?.choiceId === BattleResult.AwayTeamWon) {
  //     participants.reverse();
  //   }

  //   const left = participants[0];
  //   const right = cloneElement(participants[1], { fromRight: true });

  //   return (
  //     <Root>
  //       {left}
  //       {right}
  //     </Root>
  //   );
  // }

  return null;
};

import { styled } from '~shared/lib/styles';
import { Typography } from '~shared/ui';

export const UserVoteCaptionName = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  lineHeight: 14 / 13,
  fontWeight: 600,
  color: theme.palette.text.primary,
}));

type CoeffProps = {
  $color: string;
};

export const Coeff = styled('div')(({ $color }: CoeffProps) => {
  const colorMap: Record<string, string> = {
    red: 'rgba(204, 40, 79, 1)',
    green: 'rgba(89, 146, 54, 1)',
    blue: 'rgba(41, 137, 227, 1)',
  };

  return {
    border: '1px solid rgba(255, 255, 255, 0.2)',
    padding: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    justifySelf: 'center',
    backgroundColor: colorMap[$color],

    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '11.74px',
    textTransform: 'uppercase',
  };
});

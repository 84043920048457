import { useDrop } from 'react-dnd';

import { ConnectDropTarget } from 'react-dnd/src/types';

import { ApiGetBattlesMappedData, BetSlot, Nft, PartialNft } from '~shared/api';

import { NftDragItem, NftDragItemType, getNftByTokenId, useNftSelector } from '~entities/nft';
import { useEventModel, useEventValidateCard } from '~entities/event';

interface UseDropBattle {
  (props: {
    event: ApiGetBattlesMappedData;
    enabled: boolean;
    choice?: BetSlot;
    cards?: Array<Nft | PartialNft>;
  }): {
    dragRef: ConnectDropTarget;
    isOver: boolean;
    canDrop: boolean;
  };
}

export const useDropBattle: UseDropBattle = ({ event, enabled, choice, cards = [] }) => {
  // todo: useWalletModel
  const { nfts } = useNftSelector();
  const { openEvent } = useEventModel();
  const { validateCard } = useEventValidateCard();

  const handleOpenEvent = (nft: Nft) => {
    setTimeout(() => {
      openEvent(event, {
        choice,
        battle: {
          // todo: fix types
          // @ts-ignore
          cards,
        },
        additionalCards: [nft],
        isViewMode: true,
      });
      // it removes event dialog opening glitch
    }, 150);
  };

  const [{ isOver, canDrop }, dragRef] = useDrop<NftDragItem, any, any>({
    accept: NftDragItemType.Place,
    canDrop: () => enabled,
    drop: async (item: NftDragItem) => {
      if (!choice) {
        return;
      }

      const nft = getNftByTokenId(nfts, item.tokenId);

      if (nft) {
        const isValidated = await validateCard({
          card: nft,
          slot: choice,
          cardsAmount: cards.length,
          onWinstreakWarningConfirm: () => handleOpenEvent(nft),
        });

        if (!isValidated) {
          return;
        }

        handleOpenEvent(nft);
      }
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: enabled,
      };
    },
  });

  return {
    dragRef,

    isOver,
    canDrop,
  };
};

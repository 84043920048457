import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NFT_RARITY_TO_READABLE_RARITY_NAME_MAP } from '~entities/nft';

import { NftRarityBadgeRoot } from './styled';
import { NftRarityBadgeProps } from './types';

export const NftRarityBadge: FC<NftRarityBadgeProps> = ({ rarity, ...htmlAttributes }) => {
  const { t } = useTranslation();

  return (
    <NftRarityBadgeRoot $rarity={rarity} {...htmlAttributes}>
      {t(NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[rarity])}
    </NftRarityBadgeRoot>
  );
};

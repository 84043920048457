export const routes = {
  index: '/',
  ranking: '/ranking',
  dashboard: '/dashboard',
  marketplace: '/marketplace',
  profile: '/profile',
  tournaments: '/tournaments',
  passwordRecovery: '/reset-password',

  // mobile routes
  wallet: '/wallet',
  walletCard: '/wallet/:tokenId',
  notifications: '/notifications',

  sports: {
    basketball: '/sports/basketball',
    football: '/sports/football',
    hockey: '/sports/hockey',
    mma: '/sports/mma',
    cricket: '/sports/cricket',
    baseball: '/sports/baseball',
    tennis: '/sports/tennis',
    americanFootball: '/sports/american-football',

    esports: '/sports/esports',
    dota: '/sports/dota2',
    cs2: '/sports/cs2',
    leagueOfLegends: '/sports/league-of-legends',

    // Football leagues
    uefaEuropaLeague: '/sports/football/uefa-europa-league',
    uefaChampionsLeague: '/sports/football/uefa-champions-league',
    uefaEuropaConferenceLeague: '/sports/football/uefa-europa-conference-league',
    englishPremierLeague: '/sports/football/english-premier-league',
    ligue1: '/sports/football/ligue-1',
    laLiga: '/sports/football/la-liga',
    bundesliga: '/sports/football/bundesliga',
    serieA: '/sports/football/serie-a',

    // Tennis leagues
    legends: '/sports/tennis/legends',
    wta125K: '/sports/tennis/wta-125-K',
    wta: '/sports/tennis/wta',
    atp: '/sports/tennis/atp',

    // CS2
    CS2_1xP: '/sports/cs2/1xP',
    CS2_BLAST: '/sports/cs2/BLAST',
    CS2_CBCS: '/sports/cs2/CBCS',
    CS2_CCTCEU: '/sports/cs2/CCT_CEU',
    CS2_CCTEE: '/sports/cs2/CCT_EE',
    CS2_CCTNE: '/sports/cs2/CCT_NE',
    CS2_CCTSA: '/sports/cs2/CCT_SA',
    CS2_EME: '/sports/cs2/EME',
    CS2_EPLDiv1: '/sports/cs2/EPL_Div_1',
    CS2_ESEAPSCNA: '/sports/cs2/ESEA_PSC_NA',
    CS2_ESL: '/sports/cs2/ESL',
    CS2_ESLAPAC: '/sports/cs2/ESL_APAC',
    CS2_ESLDA: '/sports/cs2/ESL_DA',
    CS2_ESLDW: '/sports/cs2/ESL_DW',
    CS2_ESLEU: '/sports/cs2/ESL_EU',
    CS2_ESLNA: '/sports/cs2/ESL_NA',
    CS2_ESLPRO: '/sports/cs2/ESL_PRO',
    CS2_ESM: '/sports/cs2/ESM',
    CS2_FRLG: '/sports/cs2/FRLG',
    CS2_GCLS: '/sports/cs2/GCLS',
    CS2_GM8: '/sports/cs2/GM8',
    CS2_IEM: '/sports/cs2/IEM',
    CS2_IEMSYD: '/sports/cs2/IEM_SYD',
    CS2_IESF: '/sports/cs2/IESF',
    CS2_KaBuM: '/sports/cs2/KaBuM',
    CS2_LLP: '/sports/cs2/LLP',
    CS2_PCV: '/sports/cs2/PC_V',
    CS2_PGL: '/sports/cs2/PGL',
    CS2_PWAP: '/sports/cs2/PWAP',
    CS2_RBC: '/sports/cs2/RBC',
    CS2_RCL: '/sports/cs2/RCL',
    CS2_SKYM: '/sports/cs2/SKY_M',
    CS2_TPEU: '/sports/cs2/TP_EU',
    CS2_TPNA: '/sports/cs2/TP_NA',
    CS2_TPWC: '/sports/cs2/TPWC',

    // dota
    DOTA2_ASIAN: '/sports/dota2/ASIAN',
    DOTA2_MSMS: '/sports/dota2/MSMS',
    DOTA2_PGS: '/sports/dota2/PGS',
    DOTA2_RMS: '/sports/dota2/RMS',
    DOTA2_TI: '/sports/dota2/TI',

    // League of legends
    LOL_CBLOL: '/sports/league-of-legends/CBLOL',
    LOL_CBLOLA: '/sports/league-of-legends/CBLOLA',
    LOL_EBL: '/sports/league-of-legends/EBL',
    LOL_EMEA: '/sports/league-of-legends/EMEA',
    LOL_ESS: '/sports/league-of-legends/ESS',
    LOL_GLL: '/sports/league-of-legends/GLL',
    LOL_HPM: '/sports/league-of-legends/HPM',
    LOL_LCK: '/sports/league-of-legends/LCK',
    LOL_LCKCL: '/sports/league-of-legends/LCK_CL',
    LOL_LCO: '/sports/league-of-legends/LCO',
    LOL_LCS: '/sports/league-of-legends/LCS',
    LOL_LDL: '/sports/league-of-legends/LDL',
    LOL_LECEMEA: '/sports/league-of-legends/LECEMEA',
    LOL_LFL: '/sports/league-of-legends/LFL',
    LOL_LJL: '/sports/league-of-legends/LJL',
    LOL_LLA: '/sports/league-of-legends/LLA',
    LOL_LPL: '/sports/league-of-legends/LPL',
    LOL_LPLOL: '/sports/league-of-legends/LPLOL',
    LOL_LVPSL: '/sports/league-of-legends/LVP_SL',
    LOL_NACL: '/sports/league-of-legends/NACL',
    LOL_NLC: '/sports/league-of-legends/NLC',
    LOL_PCS: '/sports/league-of-legends/PCS',
    LOL_PGN: '/sports/league-of-legends/PGN',
    LOL_PRM: '/sports/league-of-legends/PRM',
    LOL_TAL: '/sports/league-of-legends/TAL',
    LOL_TCL: '/sports/league-of-legends/TCL',
    LOL_UL: '/sports/league-of-legends/UL',
    LOL_VCS: '/sports/league-of-legends/VCS',
    LOL_Worlds: '/sports/league-of-legends/Worlds',
  },

  // test
  uiKitDemo: '/ui-kit-demo',
};

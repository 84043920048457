import { useEffect } from 'react';
import { Address } from '@ton/core';
import { useTonAddress } from '@tonconnect/ui-react';
import { useQuery } from 'react-query';

import { inventoryActions } from '~widgets/inventory';
import { InventoryItem } from '~widgets/inventory/model/types';

import { useViewerSelector } from '~entities/viewer';
import { useWalletSelector } from '~entities/wallet';

import { getUserProgress, postSetUserProgress } from '~shared/api/daily-quest';
import { useDispatch } from '~shared/lib/hooks';

import { dailyQuestRewardActions } from './slice';
import { DailyQuestType } from './types';

export const useSetProgressDailyQuestQuery = () => {
  const { isProofed } = useWalletSelector();
  const dispatch = useDispatch();
  const address = useTonAddress();

  const { data } = useQuery(['postProgress', address], () => postSetUserProgress(), {
    staleTime: 60 * 1 * 1000,
    enabled: address.length !== 0 && isProofed,
  });

  useEffect(() => {
    if (data) {
      if (data.isUpdated) {
        const currentDailyQuest = data.rewardList[String(data.countedDays)] as DailyQuestType;

        dispatch(
          inventoryActions.updateInventoryItem({
            amount: currentDailyQuest.amount,
            item: currentDailyQuest.consumableKey as InventoryItem,
            operation: 'add',
          })
        );
      }

      dispatch(
        dailyQuestRewardActions.set({
          countedDays: data.countedDays - 1,
          rewardList: data.rewardList,
          allDays: Number(Object.keys(data.rewardList).pop()) - 1,
          dailyQuest: data.rewardList[String(data.countedDays)],
          isOpen: data.isUpdated && true,
        })
      );
    }
  }, [data, dispatch]);
};

export const useGetProgressDailyQuestQuery = () => {
  const { tonAddress } = useViewerSelector();

  const { data, isLoading } = useQuery(['getProgress', tonAddress], () =>
    getUserProgress({ address: Address.normalize(tonAddress) })
  );

  return {
    data,
    isLoading,
  };
};

import { FC, useRef } from 'react';
import { clsx } from 'clsx';

import { useHorizontalWheelScroll, useTabIndicator } from '~shared/lib/hooks';

import { MenuTabsProps } from './types';
import { MenuTabIndicator, UIKitMenuTabs, UIKitMenuTabsContainer, menuTabsClasses } from './styled';

export const MenuTabs: FC<MenuTabsProps> = ({ variant = 'default', className, ...props }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    position,
    onChange,
    ref: tabIndicatorRef,
  } = useTabIndicator({
    onChange: props?.onChange,
  });

  const { handleWheel } = useHorizontalWheelScroll(containerRef);

  const containerClassName = clsx(
    {
      [menuTabsClasses.secondary]: variant === 'secondary',
      [menuTabsClasses.sidebar]: variant === 'sidebar',
      [menuTabsClasses.default]: variant === 'default',
    },
    className
  );

  return (
    <UIKitMenuTabsContainer ref={containerRef} className={containerClassName} onWheel={handleWheel}>
      <UIKitMenuTabs {...props} ref={tabIndicatorRef} onChange={onChange} />

      {variant !== 'sidebar' && props.value !== null && (
        <MenuTabIndicator className={menuTabsClasses.indicator} style={position} />
      )}
    </UIKitMenuTabsContainer>
  );
};

import { styled } from '~shared/lib/styles';

export const leaderboardTableRowClasses = {
  viewer: 'LeaderboardRow-viewer',
  sticky: 'LeaderboardRow-sticky',
};

export const LeaderboardTableRowContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: 60,
  borderRadius: 8,
  background: theme.palette.grey.A5,
  display: 'grid',
  gridTemplateColumns: '65% repeat(3, 1fr)',
  transition: theme.transitions.create(['box-shadow', 'background']),

  [theme.breakpoints.down('mobile')]: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },

  [`&.${leaderboardTableRowClasses.viewer}`]: {
    position: 'sticky',
    bottom: '20px',
    inset: '20px 0 20px 0',
    zIndex: 1,
  },

  [`&.${leaderboardTableRowClasses.sticky}`]: {
    boxShadow: '0px 0px 11.85px #2EB2FF',
    background: theme.palette.paper.secondary,
  },
}));

export const LeaderboardTableRowColumnInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 21,
});

export const LeaderboardTableRowColumn = styled('div')(() => ({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: 14 / 16,
  margin: 'auto',
}));

export const LeaderboardTableRowName = styled('p')({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '24px',
  marginLeft: 12,
});

export const LeaderboardTableRowArrowContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  marginRight: '20px',
});

export const LeaderboardTableRowAvatarAndNicknameContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  marginLeft: '12px',
  cursor: 'pointer',
});

import { FC, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Typography } from '~shared/ui';

import { useSwitcher, useTimer } from '~shared/lib/hooks';

import {
  TIMER_ESTIMATE_DELAY,
  TIMER_ESTIMATE_RECORD_KEY,
  getTimerEstimateRecordDelay,
} from '../../../lib';

import { useAuthModel } from '../../../model';

import { AuthDialogTitle } from '../../AuthDialogTitle';
import { TextAlignCenter } from '../styled';

export const RecoveryLink: FC = () => {
  const { t } = useTranslation();
  const { email, recoveryPassword, setStep } = useAuthModel();

  const { createTimer, timer } = useTimer({ delay: TIMER_ESTIMATE_DELAY });

  const submitting = useSwitcher(false);

  const handleClick = useCallback(() => {
    submitting.switchOn();

    recoveryPassword({ email }).then(() => {
      createTimer(getTimerEstimateRecordDelay(TIMER_ESTIMATE_RECORD_KEY));
      submitting.switchOff();
    });
  }, [createTimer, email, recoveryPassword, submitting]);

  const handleReturnToLogin = () => {
    setStep((steps) => steps.Authentication);
  };

  useEffect(() => {
    createTimer(getTimerEstimateRecordDelay(TIMER_ESTIMATE_RECORD_KEY));
  }, [createTimer]);

  return (
    <TextAlignCenter>
      <AuthDialogTitle>{t('RecoveryLink.recovery')}</AuthDialogTitle>

      <Typography padding={10 / 8} color="secondary" mb={24 / 8}>
        {t('RecoveryLink.weHaveSent')}
        <strong>{email}</strong>.{t('RecoveryLink.IfTheLink')}
      </Typography>

      {timer ? (
        <Typography fontWeight={500} color="grey.A30" textAlign="center">
          {t('RecoveryLink.requestLink')} {timer} sec
        </Typography>
      ) : (
        <Button onClick={handleClick} disabled={submitting.value} variant="text" fullWidth>
          {t('RecoveryLink.reSend')}
        </Button>
      )}

      <Button onClick={handleReturnToLogin} variant="text" mt={24 / 8}>
        {t('RecoveryLink.returnToLogin')}
      </Button>
    </TextAlignCenter>
  );
};

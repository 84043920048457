import { FC, Fragment } from 'react';

import { Divider, Stack } from '~shared/ui';

import { TournamentInfoRowLabel, TournamentInfoRowValue } from './styled';

import { TournamentInfoRowProps } from './types';

export const TournamentInfoRow: FC<TournamentInfoRowProps> = ({ elements, hideDividers }) => {
  const showDividers = !hideDividers;

  return (
    <Stack direction="row" spacing={24 / 8} flexWrap="wrap">
      {elements.map((element, idx) => (
        <Fragment key={idx}>
          {idx !== 0 && showDividers && <Divider orientation="vertical" flexItem />}

          <Stack spacing={6 / 8}>
            <TournamentInfoRowLabel>{element.label}</TournamentInfoRowLabel>
            <TournamentInfoRowValue>{element.value}</TournamentInfoRowValue>
          </Stack>
        </Fragment>
      ))}
    </Stack>
  );
};

import { FC } from 'react';

import { Stack, Typography } from '~shared/ui';

import { MarketplaceDetailsEntriesProps } from './types';

export const MarketplaceDetailsEntries: FC<MarketplaceDetailsEntriesProps> = ({ entries }) => {
  return (
    <Stack spacing={10 / 8}>
      {entries.map(({ label, value }, i) => (
        <Stack key={i} direction="row" justifyContent="space-between" alignItems="center">
          <Typography color="secondary">{label}</Typography>
          <Typography variant="h3">{value}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};

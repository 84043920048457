import { NftRelatedBattle, NftRelatedCall } from '../nfts';

import { ApiCommonLeague, ApiCommonSport, ApiCommonSportEnum } from '../shared';

/**
 * Get battles
 */
export interface ApiGetBattlesRequestData {
  sport: Array<ApiCommonSport> | ApiCommonSport;
  league?: ApiCommonLeague;
  address?: string;
}

export interface ApiGetBattlesResponseData extends Array<ApiGetBattlesMappedData> {}

export interface ApiGetBattles {
  (data: ApiGetBattlesRequestData): Promise<ApiGetBattlesResponseData>;
}

export enum RawDifficulty {
  Obvious = 'Obvious',
  Easy = 'Easy',
  Standart = 'Standart',
  Hard = 'Hard',
  Insane = 'Insane',
  Unknown = 'Unknown',
}

export type RawMatchDifficulty = {
  away: RawDifficulty;
  draw: RawDifficulty;
  home: RawDifficulty;
};

export type MappedDifficulty = {
  label: RawDifficulty;
  color: string;
};

export type MappedMatchDifficulty = {
  away: MappedDifficulty;
  draw: MappedDifficulty;
  home: MappedDifficulty;
};
export interface ApiGetBattlesRawData {
  homeTeam: ApiGetBattlesRawTeam;
  awayTeam: ApiGetBattlesRawTeam;
  date: string;
  sport: ApiCommonSport;
  league: ApiCommonLeague;
  id: string;
  matchDifficulty: RawMatchDifficulty;

  broadcast?: string;
}

export interface ApiGetBattlesMappedData {
  homeTeam: ApiGetBattlesTeam;
  awayTeam: ApiGetBattlesTeam;
  date: Date;
  sport: ApiCommonSport;
  relatedNft?: RelatedNft;
  id: string;
  league: ApiCommonLeague;
  matchDifficulty: MappedMatchDifficulty;

  broadcastId?: string;
  broadcastProvider: ApiBattleBroadcastProvider;
}

export enum ApiBattleBroadcastProvider {
  Unknown,

  SportRadar,
  Twitch,
}

/** Raw team */
export type ApiGetBattlesRawTeam =
  | ApiGetBattlesRawCommonTeam
  | ApiGetBattlesRawFootballTeam
  | ApiGetBattlesRawTennisTeam
  | ApiGetBattlesRawEsportTeam;

interface ApiGetBattlesRawCommonTeam {
  id: string;
  name: string;
  logo: string;

  // Tennis
  players?: Array<ApiGetBattlesRawPlayer>;

  // Football
  wins?: number;
  goals?: number;
  losses?: number;
}

export interface ApiGetBattlesRawFootballTeam extends ApiGetBattlesRawCommonTeam {
  wins: number;
  goals: number;
  losses: number;
}

export interface ApiGetBattlesRawTennisTeam extends ApiGetBattlesRawCommonTeam {
  name: never;
  logo: never;
  players: Array<ApiGetBattlesTennisPlayer>;
}

export interface ApiGetBattlesRawEsportTeam extends ApiGetBattlesRawCommonTeam {
  bestStreak: number;
  currentStreak: number;
  winRate: number;
  worstStreak: number;
}

/** Raw player */
type ApiGetBattlesRawPlayer = ApiGetBattlesRawCommonPlayer | ApiGetBattlesRawTennisPlayer;

interface ApiGetBattlesRawCommonPlayer {
  name: string;
  logo: string;

  /* Tennis */
  handedness?: 'left' | 'right';
  highestDoublesRanking?: number;
  highestSinglesRanking?: number;
  points?: number;
  rank?: number;
}

export interface ApiGetBattlesRawTennisPlayer extends ApiGetBattlesRawCommonPlayer {
  name: string;
  logo: string;

  handedness: 'left' | 'right';
  highestDoublesRanking: number;
  highestSinglesRanking: number;
  points: number;
  rank: number;
}

/** Team */
export type ApiGetBattlesTeam =
  | ApiGetBattlesCommonTeam
  | ApiGetBattlesFootballTeam
  | ApiGetBattlesTennisTeam
  | ApiGetBattlesEsportTeam;

interface ApiGetBattlesCommonTeam {
  id: string;
  name: string;
  logo: string;
  players: Array<ApiGetBattlesPlayer>;

  // Copying props from ApiGetBattles%SPORT%Team types
  wins?: number;
  goals?: number;
  losses?: number;

  bestStreak?: number;
  currentStreak?: number;
  winRate?: number;
  worstStreak?: number;
  streak?: Array<'L' | 'W'>;
}

export interface ApiGetBattlesFootballTeam extends ApiGetBattlesCommonTeam {
  wins: number;
  goals: number;
  losses: number;
}

export interface ApiGetBattlesTennisTeam extends ApiGetBattlesCommonTeam {
  name: never;
  logo: never;
  players: Array<ApiGetBattlesTennisPlayer>;
}

export interface ApiGetBattlesEsportTeam extends ApiGetBattlesCommonTeam {
  bestStreak: number;
  currentStreak: number;
  winRate: number;
  worstStreak: number;
  streak: Array<'L' | 'W'>;
}

/** Player */
type ApiGetBattlesPlayer = ApiGetBattlesCommonPlayer | ApiGetBattlesTennisPlayer;

interface ApiGetBattlesCommonPlayer {
  name: string;
  shortName: string;
  logo: string;

  /* Tennis */
  handedness?: 'left' | 'right';
  highestDoublesRanking?: number;
  highestSinglesRanking?: number;
  points?: number;
  rank?: number;
}

export interface ApiGetBattlesTennisPlayer extends ApiGetBattlesCommonPlayer {
  name: string;
  shortName: string;
  logo: string;

  handedness: 'left' | 'right';

  highestDoublesRanking?: number;
  highestSinglesRanking?: number;
  points?: number;
  rank?: number;
}

/**
 * Get battles by list of ids
 */
export interface ApiGetBattlesByIdsRequestData {
  ids: Array<string | number>;
}

export interface ApiGetBattlesByIdsResponseData extends Array<ApiGetBattlesByIdsMappedData> {}

// todo: maybe unite this request with original GetBattles
export interface ApiGetBattlesByIds {
  (data: ApiGetBattlesByIdsRequestData): Promise<ApiGetBattlesByIdsResponseData>;
}

export interface ApiGetBattlesByIdsRawData {
  homeTeam: ApiGetBattlesRawTeam;
  awayTeam: ApiGetBattlesRawTeam;
  date: string;
  sport: ApiCommonSportEnum;
  id: string;
  league: ApiCommonLeague;
  result: BattleResult;
  matchDifficulty: RawMatchDifficulty;

  broadcast?: string;
}

export interface ApiGetBattlesByIdsMappedData {
  id: string;
  date: Date;
  sport: ApiCommonSport | ApiCommonSportEnum;
  league: ApiCommonLeague;
  homeTeam: ApiGetBattlesTeam;
  awayTeam: ApiGetBattlesTeam;
  result: BattleResult;
  matchDifficulty: MappedMatchDifficulty;

  broadcastId?: string;
  broadcastProvider: ApiBattleBroadcastProvider;
}

/**
 * Shared types
 */
export enum BattleChoiceId {
  HomeTeam = 2,
  Draw = 3,
  AwayTeam = 4,
}

export enum BattleResult {
  InProgress,
  Cancelled,
  HomeTeamWon,
  Draw,
  AwayTeamWon,
}

export type BattleResultChoice =
  | BattleResult.HomeTeamWon
  | BattleResult.Draw
  | BattleResult.AwayTeamWon;

interface RelatedNft {
  relatedBet?: NftRelatedBattle;
  relatedCall?: NftRelatedCall;
}

export type BetSlot = BattleResult.HomeTeamWon | BattleResult.AwayTeamWon | BattleResult.Draw;

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentPlaceholder } from '~shared/ui';

import { BattleCombinedList, useBattleList, useBattlesListQuery } from '~entities/battle';
import { BattleListProps } from '~widgets/battle-list/ui/types';

export const BattleList: FC<BattleListProps> = ({ sport, league }) => {
  const { t } = useTranslation();
  const battlesListQuery = useBattlesListQuery({ league, sport: [sport] });
  // @ts-ignore
  // todo: fix types
  const { list } = useBattleList({ list: battlesListQuery.list });

  if (battlesListQuery.isEmptyList) {
    return (
      <ContentPlaceholder
        heading={t('Other.noAvailableEventsForTheMoment')}
        message={t('Other.pleaseComebackLater')}
      />
    );
  }

  return <BattleCombinedList loading={battlesListQuery.isLoading}>{list}</BattleCombinedList>;
};

import { NftRarity } from '~shared/api';

export enum TournamentListTab {
  All = 'all',
  Upcoming = 'upcoming',
  Active = 'active',
  Past = 'past',
}

export enum TournamentListRarity {
  All = 'all',
  Common = NftRarity.Common,
  Rare = NftRarity.Rare,
  Epic = NftRarity.Epic,
  Legendary = NftRarity.Legendary,
}

export const TournamentListTabKey = 'tab';
export const TournamentListRarityKey = 'rarity';

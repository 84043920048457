export const AuthRoutes = {
  // Google OAuth
  authGoogle: () => '/auth/google?state=from=ton',

  // Identification
  checkEmail: () => '/check_email',

  // Sign Up
  signUp: () => '/signup',
  signUpVerification: () => '/signup_verification',
  resendAuthCode: () => '/resend_auth_code',

  // Sign In
  signIn: () => '/signin',

  // Sign Out
  signOut: () => '/logout',

  tonProof: () => '/auth/proof',
  tonProofPayload: () => '/auth/payload',

  // Ton Sign In
  tonSignIn: () => '/ton_signin',

  // Ton Sign Up
  tonSignUp: () => '/ton_signup',
};

import { ApiCommonLeague, ApiCommonSport, NftRarity } from '~shared/api';

/**
 * Get tournaments
 */
export interface ApiGetTournamentsPlayer {
  cards: Array<number>;
  avatar: string;
  name: string;
  place: number;
  wallet: string;
  tonWallet: string;

  wins: number;
  loses: number;
  gainedMCN: number;
  betsQuantity: number;
}

export type ApiTournamentNetwork = 'ton' | 'polygon';

export enum ApiTournamentStatus {
  Planned = 0,
  Delayed = 2,

  Active = 1,

  Finished = 3,
  CountingResults = 5,
  FinishCountringResults = 6,

  Canceled = 4,
}

export interface ApiGetTournamentsRawResponseData {
  status: ApiTournamentStatus;
  players: ApiGetTournamentsPlayer[] | null;
  prizes: [{ Value: 100; Currency: 'MCN' | 'TON'; Place: number; Items: number }];

  joinCardQuantity: 0;
  joinCurrency: 'MCN';
  joinPrice: 0;

  durationSec: number;
  generateWeekly: boolean;
  generatedAt: string;
  league: ApiCommonLeague;
  maxParticipants: number;
  minParticipants: number;
  name: string;
  participantsCount: number;
  sport: ApiCommonSport;
  startDay: string;
  startTime: string;
  tournamentId: number;
  tournamentRarity: NftRarity;

  network: ApiTournamentNetwork;

  tonContractAddress: string;
}

// todo: WIP
export interface ApiGetTournamentsMappedResponseData {
  status: ApiTournamentStatus;
  name: string;
  tournamentId: number;

  players: Array<ApiGetTournamentsPlayer>;
  playersLimit: number;

  sport: ApiCommonSport;

  startDate: Date;
  endDate: Date;

  readonly prizes: Array<{ value: number; currency: 'MCN' | 'TON'; place: number; items: number }>;

  joinCardsQuantity: number;
  tournamentRarity: NftRarity;

  joinPrices: { currency: 'MCN' | 'TON'; value: number }[];
  defaultJoinPrice: { currency: 'MCN' | 'TON'; value: number };

  network: ApiTournamentNetwork;

  tonContractAddress: string;
}

export interface ApiGetTournaments {
  (): Promise<Array<ApiGetTournamentsMappedResponseData>>;
}

/**
 * Get tournament wallet
 */
export interface ApiGetPersonalTournamentsRequestParams {
  wallet?: string;
  tonWallet: string;
}

export interface ApiGetPersonalTournamentsRawResponseData
  extends Array<ApiGetTournamentsRawResponseData> {}

export interface ApiGetPersonalTournamentsMappedResponseData
  extends Array<ApiGetTournamentsMappedResponseData> {}

export interface ApiGetPersonalTournaments {
  (
    params: ApiGetPersonalTournamentsRequestParams
  ): Promise<ApiGetPersonalTournamentsMappedResponseData>;
}

/**
 * Get tournament
 */
export interface ApiGetTournamentRequestData {
  id: string;
}

export interface ApiGetTournamentRawResponseData extends ApiGetTournamentsRawResponseData {}

export interface ApiGetTournamentMappedResponseData extends ApiGetTournamentsMappedResponseData {}

export interface ApiGetTournament {
  (options: ApiGetTournamentRequestData): Promise<ApiGetTournamentsMappedResponseData>;
}

/**
 * Join tournament
 */
export interface ApiPostJoinTournamentRequestData {
  tournamentId: number;
  cards: number[];
  caller: string;
  r: string;
  s: string;
  v: number;
}

export interface ApiPostJoinTournamentRawResponseData {
  result: string;
  transaction: string;
}

export interface ApiPostJoinTournamentMappedResponseData {
  message: string;
  hash: string;
}

export interface ApiPostJoinTournament {
  (params: ApiPostJoinTournamentRequestData): Promise<ApiPostJoinTournamentMappedResponseData>;
}

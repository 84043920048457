import { forwardRef, useRef } from 'react';
import { clsx } from 'clsx';

import { useHorizontalWheelScroll, useTabIndicator } from '~shared/lib/hooks';
import { mergeRefs } from '~shared/lib/utils';

import {
  UIKitTabs,
  UIKitTabsContainer,
  UIKitTabsEndAdornment,
  UIKitTabsIndicator,
  tabsClasses,
} from './styled';
import { TabsProps } from './types';

export const Tabs = forwardRef<HTMLDivElement, TabsProps>(
  ({ children, underlined = true, endAdornment, ...props }, forwardedContainerRef) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const {
      position,
      onChange,
      ref: tabIndicatorRef,
    } = useTabIndicator({
      onChange: props.onChange,
    });

    const { handleWheel } = useHorizontalWheelScroll(containerRef);

    const computedClassName = clsx({
      [tabsClasses.underlined]: underlined,
    });

    return (
      <UIKitTabsContainer
        ref={mergeRefs([containerRef, forwardedContainerRef])}
        className={computedClassName}
        onWheel={handleWheel}
      >
        <UIKitTabs {...props} ref={tabIndicatorRef} onChange={onChange}>
          {children}
          {endAdornment && <UIKitTabsEndAdornment>{endAdornment}</UIKitTabsEndAdornment>}
        </UIKitTabs>

        <UIKitTabsIndicator style={position} />
      </UIKitTabsContainer>
    );
  }
);

import { useTranslation } from 'react-i18next';
import { FC, Fragment, useRef } from 'react';
import { motion } from 'framer-motion';
import { clsx } from 'clsx';

import { Icon, IconAssetNamesType, Menu, MenuItem, Typography } from '~shared/ui';
import { useSwitcher } from '~shared/lib/hooks';
import { Language } from '~shared/config/i18n';

import { useViewerModel } from '~entities/viewer';

import { SwitchLanguageButton, switchLanguageButtonClasses } from './styled';
import { SwitchLanguageProps } from './types';

export const SwitchLanguage: FC<SwitchLanguageProps> = ({ variant = 'compact' }) => {
  const { i18n } = useTranslation();

  const open = useSwitcher(false);
  const buttonRef = useRef(null);
  const { switchLanguage } = useViewerModel();

  const handleChangeLanguageCurry = (language: Language) => () => {
    open.toggle();
    switchLanguage(language);
  };

  const iconName = options.find((option) => option.value === i18n.language)!.icon;

  const chevron = (
    <motion.div
      animate={{
        rotate: open.value ? '180deg' : '0',
        transition: { duration: 0.2, type: 'tween' },
      }}
    >
      <Icon size={18} name="chevron-up" className={switchLanguageButtonClasses.chevron} />
    </motion.div>
  );

  return (
    <Fragment>
      <SwitchLanguageButton
        name={iconName}
        onClick={open.toggle}
        $open={open.value}
        variant="plain"
        ref={buttonRef}
        className={clsx({
          [switchLanguageButtonClasses.default]: variant === 'default',
          [switchLanguageButtonClasses.compact]: variant === 'compact',
        })}
        append={variant === 'default' ? chevron : null}
        size={22}
      />

      <Menu
        open={open.value}
        onClose={open.switchOff}
        anchorEl={buttonRef.current}
        slotProps={{ root: { placement: 'bottom-end' } }}
      >
        {options.map((option) => {
          const selected = i18n.language === option.value;

          return (
            <MenuItem
              onClick={handleChangeLanguageCurry(option.value)}
              key={option.value}
              selected={selected}
            >
              <Icon name={option.icon} marginRight={1} />
              <Typography color="secondary">{option.label}</Typography>
              {selected && <Icon name="check" ml="auto" />}
            </MenuItem>
          );
        })}
      </Menu>
    </Fragment>
  );
};

const options: Array<{ value: Language; label: string; icon: IconAssetNamesType }> = [
  { value: Language.EN, label: 'English', icon: 'flag-gb' },
  { value: Language.RU, label: 'Русский', icon: 'flag-ru' },
  { value: Language.ES, label: 'Español', icon: 'flag-es' },
  { value: Language.PT, label: 'Português', icon: 'flag-pt' },
];

import { useCallback } from 'react';

import { useDispatch, useSnackbar } from '~shared/lib/hooks';
import { sleep } from '~shared/lib/utils';
import { Nft } from '~shared/api';

import {
  NFT_RARITY_TO_MAX_LIVES_MAP,
  NftToMergeSerialNumber,
  getNftByTokenId,
  isNftAvailableForMerge,
  nftActions,
  useNftCardModel,
} from '~entities/nft';

import { eventActions } from '~entities/event';

import { checkIsMergeAvailable } from '../lib';

import { useMergeSelector } from './selectors';
import { mergeActions } from './slice';

export const useMergeModel = () => {
  const { openSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const {
    repairBeforeMergeDialog: { open: repairBeforeMergeDialogOpen, nftsToRepair },
    successMergeDialog: { open: successMergeDialogOpen, recentlyCreatedNft },
  } = useMergeSelector();

  const {
    nfts,
    nftsToMerge: [firstNftToMerge, secondNftToMerge],
    onAddCardsToMerge: onAddCardsToMergeModel,
    onCancelAddCardsToMerge: onCancelAddCardsToMergeModel,

    setMergeDialogOpen,
    mergeDialogOpen,
  } = useNftCardModel();

  const targetNftToRepair = nftsToRepair[0] ?? null;

  const handleAddNftToMerge = useCallback(
    (tokenIds: string[]) => {
      tokenIds.forEach((tokenId) => {
        const nft = getNftByTokenId(nfts, tokenId);

        if (!nft) {
          return;
        }

        const mergeAvailability = isNftAvailableForMerge(nft);

        if (!mergeAvailability.isAvailable) {
          openSnackbar({
            type: 'error',
            message: `This NFT is not ready for merge. You need ${mergeAvailability.remainingWins} more win/wins.`,
          });

          return;
        }

        const isBothCellsPlaced = Boolean(firstNftToMerge) && Boolean(secondNftToMerge);
        const isPlacingAlreadyPlacedNft =
          (Boolean(firstNftToMerge) && firstNftToMerge?.token_id === tokenId) ||
          (Boolean(secondNftToMerge) && secondNftToMerge?.token_id === tokenId);

        if (isBothCellsPlaced || isPlacingAlreadyPlacedNft) {
          return;
        }

        if (nft) {
          const maxLives = NFT_RARITY_TO_MAX_LIVES_MAP[nft.rarity];
          const shouldBeRepaired = nft.livesRemaining !== maxLives;

          if (shouldBeRepaired) {
            dispatch(mergeActions.setNftToRepair(nft));

            return;
          }

          dispatch(nftActions.setNftsToMerge({ nft }));
        }
      });
    },
    [dispatch, firstNftToMerge, nfts, openSnackbar, secondNftToMerge]
  );

  const returnCardToMergeAfterRepair = useCallback(
    (repairedNft: Nft) => {
      dispatch(mergeActions.completeNftToRepair(repairedNft));
      dispatch(nftActions.setNftsToMerge({ nft: repairedNft }));
    },
    [dispatch]
  );

  const openMergeDialog = useCallback(() => {
    setMergeDialogOpen(true);
  }, [setMergeDialogOpen]);

  const closeMergeDialog = useCallback(() => {
    setMergeDialogOpen(false);
  }, [setMergeDialogOpen]);

  const openCardsToMergeSelection = useCallback(
    (enable: boolean) => () => {
      if (enable) {
        closeMergeDialog();
        onAddCardsToMergeModel();
      }
    },
    [closeMergeDialog, onAddCardsToMergeModel]
  );

  const unhideNftToMerge = useCallback(
    (tokenId: string) => {
      // todo: use dedicated model methods (useNftModel etc)
      dispatch(nftActions.setNftHidden({ nftIds: [tokenId], isHidden: false }));
      dispatch(eventActions.setNftHidden({ nftIds: [tokenId], isHidden: false }));
    },
    [dispatch]
  );

  const unhideAllNftsToMerge = useCallback(() => {
    [firstNftToMerge, secondNftToMerge].forEach((nft) => {
      if (nft) {
        unhideNftToMerge(nft.token_id);
      }
    });
  }, [firstNftToMerge, secondNftToMerge, unhideNftToMerge]);

  const cancelRepairBeforeMerge = useCallback(() => {
    const targetNft = targetNftToRepair;
    const nextNft = nftsToRepair[1];

    if (targetNft) {
      unhideNftToMerge(targetNft.token_id);
      dispatch(mergeActions.completeNftToRepair(targetNft));
    }

    if (nextNft) {
      dispatch(mergeActions.openNftToRepairDialog());
    }
  }, [dispatch, nftsToRepair, targetNftToRepair, unhideNftToMerge]);

  const cancelCardsMerge = useCallback(async () => {
    onCancelAddCardsToMergeModel();

    unhideAllNftsToMerge();
    closeMergeDialog();

    await sleep(200);

    dispatch(
      nftActions.removeNftToMerge([NftToMergeSerialNumber.First, NftToMergeSerialNumber.Second])
    );
  }, [closeMergeDialog, dispatch, onCancelAddCardsToMergeModel, unhideAllNftsToMerge]);

  const removeAllCards = useCallback(() => {
    unhideAllNftsToMerge();
    const payload = [NftToMergeSerialNumber.First, NftToMergeSerialNumber.Second];

    dispatch(nftActions.removeNftToMerge(payload));
  }, [dispatch, unhideAllNftsToMerge]);

  const handleSuccessMerge = useCallback(
    (nft: Nft) => {
      dispatch(mergeActions.setSuccessMergeDialog({ open: true, nft }));
    },
    [dispatch]
  );

  const closeSuccessMergeDialog = useCallback(() => {
    dispatch(mergeActions.setSuccessMergeDialog({ open: false }));
  }, [dispatch]);

  return {
    mergeDialogOpen,
    openMergeDialog,
    closeMergeDialog,

    firstNftToMerge,
    secondNftToMerge,
    removeAllCards,

    handleAddNftToMerge,
    openCardsToMergeSelection,
    cancelCardsMerge,

    cancelRepairBeforeMerge,
    repairBeforeMergeDialogOpen,
    targetNftToRepair,
    returnCardToMergeAfterRepair,

    closeSuccessMergeDialog,
    successMergeDialogOpen,
    recentlyCreatedNft,
    handleSuccessMerge,

    isMergeAvailable: checkIsMergeAvailable(nfts),
  };
};

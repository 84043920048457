import { NFT_RARITY_TO_READABLE_RARITY_NAME_MAP } from '~entities/nft';

import { TournamentsListFilter } from './types';

export const TOURNAMENTS_LIST_FILTERS: Array<{
  value: TournamentsListFilter;
  label: string;
}> = [
  {
    value: TournamentsListFilter.All,
    label: 'Tournaments.tabAll',
  },
  {
    value: TournamentsListFilter.Common,
    label: NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[TournamentsListFilter.Common],
  },
  {
    value: TournamentsListFilter.Rare,
    label: NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[TournamentsListFilter.Rare],
  },
  {
    value: TournamentsListFilter.Epic,
    label: NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[TournamentsListFilter.Epic],
  },
  {
    value: TournamentsListFilter.Legendary,
    label: NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[TournamentsListFilter.Legendary],
  },
  {
    value: TournamentsListFilter.Mythic,
    label: NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[TournamentsListFilter.Mythic],
  },
];

import {
  ApiGetBattlesByIdsMappedData,
  ApiGetBattlesMappedData,
  BattleResult,
  Nft,
  NftRarity,
} from '~shared/api';

import { EventDialogTab } from './config';

export type EventStateEvent = ApiGetBattlesMappedData | ApiGetBattlesByIdsMappedData;

export interface EventState {
  open: boolean;
  isLoading: boolean;

  closingConfirmationDialogOpen: boolean;

  needToRefetch: boolean;

  bets: {
    betId: number;
  }[];

  user: {
    nickname: string;
    avatar?: string;
    address: string;
  } | null;

  tab: EventDialogTab;

  event: EventStateEvent | null;

  eventStatus: EventStatus | null;

  result: BattleResult | null;

  isCall: boolean;

  battle: {
    cards: Array<Nft>;
    // cards that we add on already existing bet
    additionalCards: Array<Nft>;
    choice: BattleResult | null;
    isViewMode: boolean;
    rewardReceived: number | null;
  };

  call: {
    cards: Array<Nft>;
    callId: number | null;
    callAcceptableRarity: NftRarity | null;

    isViewMode: boolean;

    choice: BattleResult | null;

    rewardReceived: number | null;

    callCreator: EventCallParticipant | null;
    callAcceptor: EventCallParticipant | null;
  };

  nftWithWinstreakDialog: {
    nft: Nft | null;
    open: boolean;
    result: BattleResult | null;
    onBetCard: VoidFunction | null;
  };

  voteChoiceDialog: {
    open: boolean;
  };
}

interface EventCallParticipant {
  nickname: string;
  avatar: string;
  choiceId?: BattleResult;
  card?: string;
}

export type EventBetType = 'call' | 'battle';

export enum EventStatus {
  Pending = 'PENDING',
  Live = 'LIVE',
  Passed = 'PASSED',
}

import React, { Fragment } from 'react';

import {
  Notification,
  NotificationAction,
  NotificationCaption,
  NotificationIconFactory,
  NotificationMessage,
  NotificationTag,
  NotificationTransactionStatus,
  NotificationUser,
} from '../../factory';

import { NftCard } from '~entities/nft';

export const Examples = () => {
  return (
    <Fragment>
      <Notification
        place="snackbar"
        date={new Date()}
        icon={
          <NotificationIconFactory type="card">
            <NftCard nft={1234} size="xss" />
            <NftCard nft={1236} size="xss" />
          </NotificationIconFactory>
        }
      >
        <NotificationTransactionStatus type="success">
          Transaction -0,02 matic
        </NotificationTransactionStatus>
        <NotificationMessage>You made a vote for event</NotificationMessage>
        <NotificationCaption>“Chicago Bulls vs Detroit Wings” on 20 sep 20:20</NotificationCaption>
      </Notification>

      <Notification
        place="snackbar"
        date={new Date()}
        icon={
          <NotificationIconFactory type="card">
            <NftCard nft={1234} size="xss" />
          </NotificationIconFactory>
        }
      >
        <NotificationTransactionStatus type="error">
          Transaction error
        </NotificationTransactionStatus>
        <NotificationMessage>Vote for event failed</NotificationMessage>
        <NotificationCaption>“Chicago Bulls vs Detroit Wings” on 20 sep 20:20</NotificationCaption>
      </Notification>

      <Notification
        place="snackbar"
        date={new Date()}
        icon={
          <NotificationIconFactory type="event" color="error">
            5 min
          </NotificationIconFactory>
        }
      >
        <NotificationMessage>5 minutes to event</NotificationMessage>
        <NotificationCaption>“Chicago Bulls vs Detroit Wings” on 20 sep 20:20</NotificationCaption>
      </Notification>

      <Notification
        place="snackbar"
        date={new Date()}
        icon={
          <NotificationIconFactory type="event" color="violet">
            LIVE
          </NotificationIconFactory>
        }
      >
        <NotificationMessage>Event live</NotificationMessage>
        <NotificationCaption>“Chicago Bulls vs Detroit Wings” on 20 sep 20:20</NotificationCaption>
      </Notification>

      <Notification
        place="snackbar"
        date={new Date()}
        icon={
          <NotificationIconFactory type="card-new">
            <NftCard nft={1234} />
          </NotificationIconFactory>
        }
      >
        <NotificationTransactionStatus type="primary">
          You win 0,02 matic
        </NotificationTransactionStatus>
        <NotificationMessage>You win a Battle</NotificationMessage>
        <NotificationCaption>“Chicago Bulls vs Detroit Wings” on 20 sep 20:20</NotificationCaption>
      </Notification>

      <Notification
        place="snackbar"
        date={new Date()}
        icon={
          <NotificationIconFactory type="card-state" state="freeze">
            <NftCard nft={1234} />
          </NotificationIconFactory>
        }
      >
        <NotificationMessage>Your card is frozen</NotificationMessage>
        <NotificationCaption>Unfreeze card now to start play immediately</NotificationCaption>
        <NotificationAction>Unfreeze now</NotificationAction>
      </Notification>

      <Notification
        place="snackbar"
        date={new Date()}
        icon={
          <NotificationIconFactory type="card">
            <NftCard nft={1234} />
          </NotificationIconFactory>
        }
      >
        <NotificationUser
          name="John Doe"
          avatar="https://str.cdn.maincard.io/avatars/custom_avatars/5e3ab221-d4a2-4fae-ab5b-225c283f6aa6.jpeg"
        />
        <NotificationMessage>Joined your call</NotificationMessage>
        <NotificationCaption>“Chicago Bulls vs Detroit Wings” on 20 sep 20:20</NotificationCaption>
      </Notification>

      <Notification
        place="snackbar"
        date={new Date()}
        icon={
          <NotificationIconFactory type="card-new">
            <NftCard nft={1234} />
          </NotificationIconFactory>
        }
      >
        <NotificationTag color="primary">New card</NotificationTag>
        <NotificationMessage>Wow! You have a new card</NotificationMessage>
        <NotificationCaption>You win it from call with John Stan</NotificationCaption>
      </Notification>

      <Notification
        place="snackbar"
        date={new Date()}
        icon={
          <NotificationIconFactory type="card-lose">
            <NftCard nft={1234} />
          </NotificationIconFactory>
        }
      >
        <NotificationTransactionStatus type="error">
          You lose your card
        </NotificationTransactionStatus>
        <NotificationMessage>John Stan win your card</NotificationMessage>
        <NotificationCaption>“Chicago Bulls vs Detroit Wings” on 20 sep 20:20</NotificationCaption>
      </Notification>

      <Notification
        place="snackbar"
        date={new Date()}
        icon={
          <NotificationIconFactory type="card">
            <NftCard nft={1234} />
          </NotificationIconFactory>
        }
      >
        <NotificationTag color="success">Ready for merge</NotificationTag>
        <NotificationMessage>1 of your card is ready for merge</NotificationMessage>
        <NotificationCaption>Play more and make 2nd card ready for merge</NotificationCaption>
      </Notification>

      <Notification
        place="snackbar"
        date={new Date()}
        icon={
          <NotificationIconFactory type="card-state" state="freeze">
            <NftCard nft={1234} />
          </NotificationIconFactory>
        }
      >
        <NotificationMessage>Nobody make a bet on your auction card </NotificationMessage>
        <NotificationCaption>Card will back to your wallet</NotificationCaption>
      </Notification>

      <Notification
        place="snackbar"
        date={new Date()}
        icon={
          <NotificationIconFactory type="card-state" state="auction">
            <NftCard nft={1234} />
          </NotificationIconFactory>
        }
      >
        <NotificationMessage>Nobody make a bet on your auction card </NotificationMessage>
        <NotificationCaption>Card will back to your wallet</NotificationCaption>
      </Notification>

      <Notification
        place="snackbar"
        date={new Date()}
        icon={
          <NotificationIconFactory type="card-state" state="repair">
            <NftCard nft={1234} />
          </NotificationIconFactory>
        }
      >
        <NotificationMessage>Nobody make a bet on your auction card </NotificationMessage>
        <NotificationCaption>Card will back to your wallet</NotificationCaption>
      </Notification>

      <Notification
        place="snackbar"
        date={new Date()}
        icon={<NotificationIconFactory type="gift" />}
      >
        <NotificationMessage>MainCard gives you 2 mystery boxes</NotificationMessage>
      </Notification>

      <Notification
        place="snackbar"
        date={new Date()}
        icon={<NotificationIconFactory type="system" />}
      >
        <NotificationMessage>Welcome to Maincard</NotificationMessage>
      </Notification>
    </Fragment>
  );
};

import { ConnectDropTarget, useDrop } from 'react-dnd';

import { ApiGetBattlesMappedData, ApiGetCallsMappedData, BetSlot, Nft } from '~shared/api';

import { NftDragItem, NftDragItemType, getNftByTokenId, useNftSelector } from '~entities/nft';
import { useEventModel, useEventValidateCard } from '~entities/event';

// todo: fsd

import { isCallEvent } from '../../lib';

interface UseDropBattleSlot {
  (props: {
    slot: BetSlot;
    event: ApiGetBattlesMappedData | ApiGetCallsMappedData;
    call?: boolean;
  }): {
    dragRef: ConnectDropTarget;
    isOver: boolean;
    canDrop: boolean;
  };
}

// todo: refactoring, maybe unite some logic from `useEventDrag` in model methods
export const useDropBattleSlot: UseDropBattleSlot = ({ slot, event, call }) => {
  const { nfts } = useNftSelector();

  const { openCallEvent, openEvent } = useEventModel();
  const { validateCard, validateCallAcceptableRarity } = useEventValidateCard();

  const acceptableRarity = call && isCallEvent(event) ? event.rarity : null;

  const handleOpenEvent = (nft: Nft) => {
    const callEvent = isCallEvent(event);
    const betType = callEvent ? 'call' : 'battle';

    const cards = [nft];

    const params = {
      [betType]: {
        choice: slot,
        cards,
        isViewMode: false,
      },
    };

    setTimeout(() => {
      if (callEvent) {
        openCallEvent(event, params);
      } else {
        openEvent(event, params);
      }
      // it removes event dialog opening glitch
    }, 150);
  };

  const [{ isOver, canDrop }, dragRef] = useDrop<NftDragItem, any, any>({
    accept: NftDragItemType.Place,
    drop: async (item: NftDragItem) => {
      const nft = getNftByTokenId(nfts, item.tokenId);

      if (nft) {
        if (call && !validateCallAcceptableRarity({ rarity: item.rarity, acceptableRarity })) {
          return;
        }

        const isValidated = await validateCard({
          card: nft,
          slot,
          onWinstreakWarningConfirm: () => handleOpenEvent(nft),
        });

        if (!isValidated) {
          return;
        }

        handleOpenEvent(nft);
      }
    },
    collect: (monitor) => {
      const item = monitor.getItem();
      let canDrop = true;

      if (typeof item?.rarity === 'number' && acceptableRarity !== null) {
        canDrop = item.rarity === acceptableRarity;
      }

      return {
        isOver: monitor.isOver(),
        canDrop,
      };
    },
  });

  return {
    isOver,
    dragRef,
    canDrop,
  };
};

import { Fragment, createElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiGetTournamentMappedResponseData } from '~shared/api';

export const useTournamentPrizesInfoElements = (
  prizes: ApiGetTournamentMappedResponseData['prizes']
) => {
  const { t } = useTranslation();

  const prizePlaceLabel = [
    t('Tournaments.firstPlace'),
    t('Tournaments.secondPlace'),
    t('Tournaments.thirdPlace'),
  ];

  return prizes.map((prize, idx) => ({
    label: prizePlaceLabel[idx],
    value: createElement(Fragment, {}, [prize.value, ' ', prize.currency]),
  }));
};

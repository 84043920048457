import { FC, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { BaseTextField, Button, Stack } from '~shared/ui';
import { useSwitcher } from '~shared/lib/hooks';

import { useAuthModel } from '../../../model';
import { AuthDialogTitle } from '../../AuthDialogTitle';

import { UserProfileRow } from './UserProfileRow';

import { AuthenticationForm } from './types';

export const Authentication: FC = () => {
  const { t } = useTranslation();
  const submitting = useSwitcher(false);

  const form = useForm<AuthenticationForm>({});
  const { signIn, setStep, email } = useAuthModel();

  const invalidCredentials = useSwitcher(false);

  const handleSubmit: SubmitHandler<AuthenticationForm> = useCallback(
    ({ password }) => {
      submitting.switchOn();

      return signIn({ login: email, password }).then((result) => {
        submitting.switchOff();
        invalidCredentials.toggle(!result);
      });
    },
    [email, invalidCredentials, signIn, submitting]
  );

  const handleForgotPassword = useCallback(() => {
    setStep((steps) => steps.ForgotPassword);
  }, [setStep]);

  return (
    <Fragment>
      <AuthDialogTitle>{t('WelcomePopUp.back')}</AuthDialogTitle>

      <FormProvider {...form}>
        <Stack component="form" onSubmit={form.handleSubmit(handleSubmit)} spacing={24 / 8}>
          <Stack spacing={14 / 8}>
            <UserProfileRow />

            <BaseTextField
              name="password"
              label={t('Account.password')}
              type="password"
              helperText={invalidCredentials.value ? t('Other.invalidCredentials') : null}
              error={invalidCredentials.value}
              autoFocus
              required
            />
          </Stack>

          <div>
            <Button variant="text" onClick={handleForgotPassword}>
              {t('WelcomePopUp.forgot')}
            </Button>
          </div>

          <Button type="submit" disabled={submitting.value}>
            {t('WelcomePopUp.signIn')}
          </Button>
        </Stack>
      </FormProvider>
    </Fragment>
  );
};

import { styled } from '~shared/lib/styles';
import { NFT_CARD_SIZE_VALUES } from '~entities/nft';
import { Box } from '~shared/ui';

export const nftCardClasses = {
  sizeXss: 'NftCard-sizeXss',
  sizeXs: 'NftCard-sizeXs',
  sizeSm: 'NftCard-sizeSm',
  sizeMd: 'NftCard-sizeMd',
  sizeLg: 'NftCard-sizeLg',
  sizeXl: 'NftCard-sizeXl',

  highlight: 'NftCard-highlight',
  locked: 'NftCard-locked',

  highlightSmall: 'NftCard-highlightSmall',
  highlightMedium: 'NftCard-highlightMedium',

  clickable: 'NftCard-clickable',
};

export const Root = styled(Box, { name: 'NftCard' })(({ theme }) => ({
  background: theme.palette.paper.paulo,
  position: 'relative',
  borderRadius: 'calc(1em * 0.095)',
  border: `1px solid ${theme.palette.grey.A50}`,
  userSelect: 'none',
  overflow: 'hidden',
  transition: theme.transitions.create('box-shadow'),

  [`&.${nftCardClasses.locked}`]: {
    ['&:after']: {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: 10,
      opacity: 0.9,
      zIndex: 1,
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/nfts/nft-dragged.png)`,
      backgroundSize: 'cover',
    },
  },

  [`&.${nftCardClasses.highlight}`]: {
    [`&.${nftCardClasses.highlightSmall}`]: {
      boxShadow: `0px 0px 11px 1px ${theme.palette.primary.light}`,
    },
    [`&.${nftCardClasses.highlightMedium}`]: {
      boxShadow: `0px 0px 31px 1px ${theme.palette.primary.light}`,
    },
  },

  [`&.${nftCardClasses.clickable}:hover`]: {
    cursor: 'pointer',

    [`&.${nftCardClasses.highlightSmall}`]: {
      boxShadow: `0px 0px 11px 1px ${theme.palette.primary.light}`,
    },
    [`&.${nftCardClasses.highlightMedium}`]: {
      boxShadow: `0px 0px 31px 1px ${theme.palette.primary.light}`,
    },
  },

  [`&.${nftCardClasses.sizeXss}`]: {
    ...NFT_CARD_SIZE_VALUES.xss,
  },
  [`&.${nftCardClasses.sizeXs}`]: {
    ...NFT_CARD_SIZE_VALUES.xs,
  },
  [`&.${nftCardClasses.sizeSm}`]: {
    ...NFT_CARD_SIZE_VALUES.sm,
  },
  [`&.${nftCardClasses.sizeMd}`]: {
    ...NFT_CARD_SIZE_VALUES.md,
  },
  [`&.${nftCardClasses.sizeLg}`]: {
    ...NFT_CARD_SIZE_VALUES.lg,
  },
  [`&.${nftCardClasses.sizeXl}`]: {
    ...NFT_CARD_SIZE_VALUES.xl,
  },
}));

export const NftImage = styled('img', { name: 'NftCard', slot: 'image' })(() => ({
  width: '100%',
  position: 'absolute',
  objectFit: 'cover',
  inset: 0,
}));

import { theme } from '~shared/lib/styles';
import { NftRarity } from '~shared/api';

const COMMON_MAX_LIVES = 2;

export const NFT_RARITY_TO_MAX_LIVES_MAP: Record<NftRarity, number> = {
  [NftRarity.Common]: COMMON_MAX_LIVES,
  [NftRarity.Rare]: 3,
  [NftRarity.Epic]: 4,
  [NftRarity.Legendary]: 5,
  [NftRarity.Mythic]: 10,
  [NftRarity.Demo]: COMMON_MAX_LIVES,
};

const COMMON_MAX_WIN_STREAK = 3;

export const NFT_RARITY_TO_MAX_WIN_STREAK = {
  [NftRarity.Common]: COMMON_MAX_WIN_STREAK,
  [NftRarity.Rare]: 6,
  [NftRarity.Epic]: 12,
  [NftRarity.Legendary]: 25,
  [NftRarity.Mythic]: null,
  [NftRarity.Demo]: COMMON_MAX_WIN_STREAK,
};

export const NFT_RARITY_TO_READABLE_RARITY_NAME_MAP = {
  [NftRarity.Common]: 'Sidebar.common',
  [NftRarity.Rare]: 'Sidebar.rare',
  [NftRarity.Epic]: 'Sidebar.epic',
  [NftRarity.Legendary]: 'Sidebar.legendary',
  [NftRarity.Mythic]: 'Sidebar.mythic',
  [NftRarity.Demo]: 'Sidebar.demo',
};

export const NFT_RARITY_TO_READABLE_KEY_MAP = {
  [NftRarity.Common]: 'common',
  [NftRarity.Rare]: 'rare',
  [NftRarity.Epic]: 'epic',
  [NftRarity.Legendary]: 'legendary',
  [NftRarity.Mythic]: 'mythic',
};

export const NFT_RARITY_TO_REPAIR_PRICE = {
  [NftRarity.Common]: 1.6,
  [NftRarity.Rare]: 12,
  [NftRarity.Epic]: 80,
  [NftRarity.Legendary]: 350,
  [NftRarity.Mythic]: 2200,
};

export const NFT_RARITY_TO_MCN_REPAIR_PRICE = {
  [NftRarity.Common]: 16,
  [NftRarity.Rare]: 120,
  [NftRarity.Epic]: 800,
  [NftRarity.Legendary]: 3500,
  [NftRarity.Mythic]: 22000,
};

export const NFT_RARITY_TO_MERGE_PRICE = {
  [NftRarity.Common]: 1,
  [NftRarity.Rare]: 10,
  [NftRarity.Epic]: 50,
  [NftRarity.Legendary]: 100,
  [NftRarity.Mythic]: 0,
};

export const NFT_RARITY_TO_MCN_MERGE_PRICE = {
  [NftRarity.Common]: 10,
  [NftRarity.Rare]: 100,
  [NftRarity.Epic]: 500,
  [NftRarity.Legendary]: 1000,
  [NftRarity.Mythic]: 0,
};

export const NFT_RARITIES = [
  NftRarity.Common,
  NftRarity.Rare,
  NftRarity.Epic,
  NftRarity.Legendary,
  NftRarity.Mythic,
  NftRarity.Demo,
];

export const NFT_RARITY_TO_COLOR: Record<NftRarity, keyof typeof theme.palette.rarity> = {
  [NftRarity.Common]: 'common',
  [NftRarity.Rare]: 'rare',
  [NftRarity.Epic]: 'epic',
  [NftRarity.Legendary]: 'legendary',
  [NftRarity.Mythic]: 'mythic',
  [NftRarity.Demo]: 'common',
};

export const NftStatusText = {
  Normal: 'Events.Normal',
  BlockedForEvent: 'Events.blocked',
  OnEvent: 'Events.OnEvent',
  Freezed: 'Events.Freezed',
  ShouldBeRecovered: 'Events.ShouldBeRecovered',
  AvailableForMerge: 'Events.AvailableForMerge',
};

import { FC, Fragment, useEffect, useState } from 'react';

import { useMediaQuery, useSearchParamsState } from '~shared/lib/hooks';
import { useTheme } from '~shared/lib/styles';
import { Dialog, MobileDialog, Stack } from '~shared/ui';

import { useAuctionSelector } from '~features/auction';

import { ApiGetAuctionCardMappedData } from '~shared/api';

import { AuctionCardDetailsDialogProps } from './types';
import { AuctionCardDetailsProperties } from './AuctionCardDetailsProperties';
import { AuctionCardDetailsBids } from './AuctionCardDetailsBids';

export const AUCTION_ITEM_SEARCH_PARAM = 'auctionItemDetails';

export const AuctionCardDetailsDialog: FC<AuctionCardDetailsDialogProps> = () => {
  const { palette } = useTheme();
  const { isMobile } = useMediaQuery();

  const { auctionCards } = useAuctionSelector();

  const [cardDetails, setCardDetails] = useState<ApiGetAuctionCardMappedData | null>(null);

  const [searchParam, , omitSearchParam] = useSearchParamsState<string>(
    AUCTION_ITEM_SEARCH_PARAM,
    ''
  );

  const onClose = () => {
    omitSearchParam();
    setCardDetails(null);
  };

  useEffect(() => {
    if (searchParam && auctionCards.length !== 0) {
      const [tokenId, betsAcceptedUntil] = searchParam.split('_');
      const auctionCard = auctionCards.find(
        (card) =>
          card.tokenId === Number(tokenId) &&
          new Date(card.betsAcceptedUntil).toISOString() === betsAcceptedUntil
      );

      if (auctionCard) {
        setCardDetails(auctionCard);
      }
    }
  }, [auctionCards, searchParam]);

  if (!searchParam || !cardDetails || auctionCards.length === 0) {
    return null;
  }

  const content = (
    <Fragment>
      <AuctionCardDetailsProperties card={cardDetails} onClose={onClose} />
      <AuctionCardDetailsBids card={cardDetails} onClose={onClose} />
    </Fragment>
  );

  if (isMobile) {
    return (
      <MobileDialog open sx={{ background: palette.paper.A80 }} disableTransition>
        <Stack
          spacing={20 / 8}
          height="100%"
          p={16 / 8}
          overflow="auto"
          position="relative"
          pb="90px"
        >
          {content}
        </Stack>
      </MobileDialog>
    );
  }

  return (
    <Dialog backgroundColor={palette.paper.A80} width={700} open onClose={onClose}>
      <Stack
        display="grid"
        gridTemplateColumns="280px 1fr"
        gap={30 / 8}
        height="100%"
        position="relative"
      >
        {content}
      </Stack>
    </Dialog>
  );
};

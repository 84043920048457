import { createElement, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';

import { useDispatch, useSnackbar } from '~shared/lib/hooks';
import { queryClient } from '~shared/api';

import { useViewerModel } from '~entities/viewer';

import { NotificationConstructor } from '../ui/public/NotificationConstructor';

import { useNotificationsSelector } from './selectors';
import { notificationsActions } from './slice';
import { NotificationsService } from './service';

import { NotificationEvent, NotificationServerEventType } from './types';

export const useNotificationsModel = () => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();

  const { account } = useWeb3React();
  const { service, notifications } = useNotificationsSelector();
  const { id: userId } = useViewerModel();

  const handleNotification = (notification: NotificationEvent) => {
    openSnackbar({
      element: createElement(NotificationConstructor, { place: 'snackbar', notification }),
    });

    switch (notification.type) {
      case NotificationServerEventType.CardAdded:
      case NotificationServerEventType.CardLivesChanged:
      case NotificationServerEventType.PutCardAuction:
        // todo: create walletModel and create method for nfts invalidation
        queryClient.invalidateQueries({ queryKey: ['nfts', account] });
        break;

      default:
        break;
    }
  };

  const handleNotifications = (notifications: NotificationEvent[]) => {
    dispatch(notificationsActions.updateNotifications(notifications));
  };

  const init = useCallback(async () => {
    service?.close();

    if (userId) {
      const service = new NotificationsService({
        userId,
        handleNotification,
        handleNotifications,
      });

      await service.init();

      dispatch(notificationsActions.setService(service));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const reset = useCallback(() => {
    dispatch(notificationsActions.reset());
  }, [dispatch]);

  const newNotifications = notifications.filter((notification) => !notification.read);
  const readNotifications = notifications.filter((notification) => notification.read);

  const notificationsCount = notifications.length;
  const newNotificationsCount = newNotifications.length;
  const readNotificationsCount = readNotifications.length;

  return {
    service,
    init,
    reset,

    notifications,
    newNotifications,
    readNotifications,

    newNotificationsCount,
    readNotificationsCount,
    notificationsCount,
  };
};

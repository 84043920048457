import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTonConnectUI } from '@tonconnect/ui-react';

import { ConfirmationDialog, Stack, Typography } from '~shared/ui';
import { useTheme } from '~shared/lib/styles';

import { DisconnectWalletDialogIllustration } from './styled';
import { DisconnectWalletDialogProps } from './types';

export const DisconnectWalletDialog: FC<DisconnectWalletDialogProps> = ({ ...props }) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const [tonConnectUI] = useTonConnectUI();

  const disconnect = () => {
    tonConnectUI.disconnect();
  };

  return (
    <ConfirmationDialog
      {...props}
      backgroundColor={palette.paper.secondary}
      title={t('Other.disconnect')}
      targetButtonText={t('Other.disconnectBtn')}
      cancelButtonText={t('SignOut.no')}
      onConfirm={disconnect}
      width={440}
    >
      <Stack spacing={24 / 8}>
        <Typography>{t('Other.upText')}</Typography>

        <DisconnectWalletDialogIllustration
          src="/assets/auth/disconnect-wallet-illustration.png"
          alt="Disconnect wallet warning"
        />

        <Typography>{t('Other.bottomText')}</Typography>
      </Stack>
    </ConfirmationDialog>
  );
};

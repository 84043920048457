import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NoParticipantRoot } from './styled';

import { TournamentNoParticipantsProps } from './types';

export const TournamentNoParticipants: FC<TournamentNoParticipantsProps> = ({ ...boxProps }) => {
  const { t } = useTranslation();

  return (
    <NoParticipantRoot {...boxProps}>
      {t('Tournaments.noOneParticipatedTheTournament')}
    </NoParticipantRoot>
  );
};

import { useDispatch } from '~shared/lib/hooks';

import { dailyQuestRewardActions } from './slice';

export const useDailyQuestRewardModel = () => {
  const dispatch = useDispatch();

  const closeModal = () => dispatch(dailyQuestRewardActions.setToggle(false));
  const openModal = () => dispatch(dailyQuestRewardActions.setToggle(true));

  return {
    closeModal,
    openModal,
  };
};

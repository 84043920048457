import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const NoParticipantRoot = styled(Box)(({ theme }) => ({
  padding: '10px 16px',
  background: theme.palette.grey.A10,
  borderRadius: 8,
  justifySelf: 'flex-start',
  alignItems: 'center',

  fontSize: 13,
  fontWeight: 400,
  lineHeight: 24 / 13,
  color: theme.palette.text.secondary,
}));

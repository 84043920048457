import { FC } from 'react';

import { Icon } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { NftCard, NftCardDrag, NftDragItemType } from '~entities/nft';
import { EventStatus, useEventModel } from '~entities/event';

import { useEventDrag } from '../../../../../model';

import { Root, StyledDragCell } from './styled';
import { EventMultipleCardsProps } from './types';
import { EventMultipleCardsContainer } from './EventMultipleCardsContainer';

export const EventMultipleCards: FC<EventMultipleCardsProps> = () => {
  const { isMobile } = useMediaQuery();

  const {
    choice,
    battle: { cards, additionalCards },
    eventStatus,
    onAddCards,
  } = useEventModel();

  const multipleCell = useEventDrag({ result: choice });

  const allCards = [...cards, ...additionalCards];

  const isEventNotPending = [EventStatus.Live, EventStatus.Passed].includes(eventStatus);

  const handleAddCards = () => {
    if (choice) {
      onAddCards(choice, isMobile)();
    }
  };

  return (
    <Root ref={multipleCell.dropRef}>
      <EventMultipleCardsContainer isEventNotPending={isEventNotPending}>
        {isEventNotPending
          ? allCards.map((card) => (
              <NftCard key={card.token_id} nft={card.token_id} size={isMobile ? 'sm' : 'md'} />
            ))
          : [
              <StyledDragCell
                onClick={handleAddCards}
                isHighlighted={multipleCell.isCellHighlighted}
                key="add-cards"
              >
                <Icon opacity={0.75} name="plus" />
              </StyledDragCell>,

              ...allCards.map((card) => (
                <NftCardDrag
                  key={card.token_id}
                  nft={card}
                  size={isMobile ? 'sm' : 'md'}
                  dragKey={NftDragItemType.Leave}
                />
              )),
            ]}
      </EventMultipleCardsContainer>
    </Root>
  );
};

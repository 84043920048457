export const NftRoutes = {
  // Identification
  fixCard: () => '/fix/sign',

  //TON
  fetchTonNfts: () => '/nft',
  getReward: () => '/nft/reward',
  mintNft: () => '/nft/mint',
  getNftByTokenId: (tokenId: string) => `/nft/${tokenId}`,
};

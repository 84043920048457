import { useTranslation } from 'react-i18next';
import { useTonConnectModal } from '@tonconnect/ui-react';

import { Button, Typography } from '~shared/ui';

import { useConnect } from '../../model';

import {
  ConnectYourWalletPromoContainer,
  ConnectYourWalletPromoImage,
  ConnectYourWalletRoot,
} from './styled';

export const ConnectYourWallet = () => {
  const { t } = useTranslation();
  const { open } = useTonConnectModal();

  return (
    <ConnectYourWalletRoot>
      <ConnectYourWalletPromoContainer>
        <Typography variant="h2" sx={{ textAlign: 'center', fontWeight: 600 }}>
          {t('onBoarding.titleStep1')} <br /> {t('Other.freeMain')}
        </Typography>
        <ConnectYourWalletPromoImage
          src="/assets/auth/connect-wallet-illustration.png"
          alt="Connect wallet to claim FREE card"
        />
        <Typography maxWidth="280px" textAlign="center" variant="body" color="grey.A75">
          {t('Other.oneStep')}
        </Typography>
      </ConnectYourWalletPromoContainer>

      <Button variant="contained" fullWidth onClick={open} maxWidth={320} mt="auto">
        {t('onBoarding.titleStep1')}
      </Button>
    </ConnectYourWalletRoot>
  );
};

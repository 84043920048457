import { getKeyInKeysByHash } from '~shared/lib/utils';
import { ApiCommonSport, ApiCommonSportEnum } from '~shared/api';

export const battleShellSquareBackgroundsAmericanFootball = {
  'americanFootball-1': `${process.env.PUBLIC_URL}/assets/battle/american-football/battle-american-football-1.jpg`,
  'americanFootball-2': `${process.env.PUBLIC_URL}/assets/battle/american-football/battle-american-football-2.jpg`,
  'americanFootball-3': `${process.env.PUBLIC_URL}/assets/battle/american-football/battle-american-football-3.jpg`,
  'americanFootball-4': `${process.env.PUBLIC_URL}/assets/battle/american-football/battle-american-football-4.jpg`,
  'americanFootball-5': `${process.env.PUBLIC_URL}/assets/battle/american-football/battle-american-football-5.jpg`,
  'americanFootball-6': `${process.env.PUBLIC_URL}/assets/battle/american-football/battle-american-football-6.jpg`,
  'americanFootball-7': `${process.env.PUBLIC_URL}/assets/battle/american-football/battle-american-football-7.jpg`,
  'americanFootball-8': `${process.env.PUBLIC_URL}/assets/battle/american-football/battle-american-football-8.jpg`,
  'americanFootball-9': `${process.env.PUBLIC_URL}/assets/battle/american-football/battle-american-football-9.jpg`,
  'americanFootball-10': `${process.env.PUBLIC_URL}/assets/battle/american-football/battle-american-football-10.jpg`,
} as const;

export const battleShellSquareBackgroundsBaseball = {
  'baseball-1': `${process.env.PUBLIC_URL}/assets/battle/baseball/battle-baseball-1.jpg`,
  'baseball-2': `${process.env.PUBLIC_URL}/assets/battle/baseball/battle-baseball-2.jpg`,
  'baseball-3': `${process.env.PUBLIC_URL}/assets/battle/baseball/battle-baseball-3.jpg`,
  'baseball-4': `${process.env.PUBLIC_URL}/assets/battle/baseball/battle-baseball-4.jpg`,
  'baseball-5': `${process.env.PUBLIC_URL}/assets/battle/baseball/battle-baseball-5.jpg`,
  'baseball-6': `${process.env.PUBLIC_URL}/assets/battle/baseball/battle-baseball-6.jpg`,
  'baseball-7': `${process.env.PUBLIC_URL}/assets/battle/baseball/battle-baseball-7.jpg`,
  'baseball-8': `${process.env.PUBLIC_URL}/assets/battle/baseball/battle-baseball-8.jpg`,
  'baseball-9': `${process.env.PUBLIC_URL}/assets/battle/baseball/battle-baseball-9.jpg`,
  'baseball-10': `${process.env.PUBLIC_URL}/assets/battle/baseball/battle-baseball-10.jpg`,
} as const;

export const battleShellSquareBackgroundsBasketball = {
  'basketball-1': `${process.env.PUBLIC_URL}/assets/battle/basketball/basketball-1.jpg`,
  'basketball-2': `${process.env.PUBLIC_URL}/assets/battle/basketball/basketball-2.jpg`,
  'basketball-3': `${process.env.PUBLIC_URL}/assets/battle/basketball/basketball-3.jpg`,
  'basketball-4': `${process.env.PUBLIC_URL}/assets/battle/basketball/basketball-4.jpg`,
  'basketball-5': `${process.env.PUBLIC_URL}/assets/battle/basketball/basketball-5.jpg`,
  'basketball-6': `${process.env.PUBLIC_URL}/assets/battle/basketball/basketball-6.jpg`,
  'basketball-7': `${process.env.PUBLIC_URL}/assets/battle/basketball/basketball-7.jpg`,
  'basketball-8': `${process.env.PUBLIC_URL}/assets/battle/basketball/basketball-8.jpg`,
  'basketball-9': `${process.env.PUBLIC_URL}/assets/battle/basketball/basketball-9.jpg`,
  'basketball-10': `${process.env.PUBLIC_URL}/assets/battle/basketball/basketball-10.jpg`,
} as const;

export const battleShellSquareBackgroundsCricket = {
  'cricket-1': `${process.env.PUBLIC_URL}/assets/battle/cricket/battle-cricket-1.jpg`,
  'cricket-2': `${process.env.PUBLIC_URL}/assets/battle/cricket/battle-cricket-2.jpg`,
  'cricket-3': `${process.env.PUBLIC_URL}/assets/battle/cricket/battle-cricket-3.jpg`,
  'cricket-4': `${process.env.PUBLIC_URL}/assets/battle/cricket/battle-cricket-4.jpg`,
  'cricket-5': `${process.env.PUBLIC_URL}/assets/battle/cricket/battle-cricket-5.jpg`,
  'cricket-6': `${process.env.PUBLIC_URL}/assets/battle/cricket/battle-cricket-6.jpg`,
  'cricket-7': `${process.env.PUBLIC_URL}/assets/battle/cricket/battle-cricket-7.jpg`,
  'cricket-8': `${process.env.PUBLIC_URL}/assets/battle/cricket/battle-cricket-8.jpg`,
} as const;

export const battleShellSquareBackgroundsCS2 = {
  'cs2-1': `${process.env.PUBLIC_URL}/assets/battle/cs2/battle-cs2-1.jpg`,
  'cs2-2': `${process.env.PUBLIC_URL}/assets/battle/cs2/battle-cs2-2.jpg`,
  'cs2-3': `${process.env.PUBLIC_URL}/assets/battle/cs2/battle-cs2-3.jpg`,
  'cs2-4': `${process.env.PUBLIC_URL}/assets/battle/cs2/battle-cs2-4.jpg`,
  'cs2-5': `${process.env.PUBLIC_URL}/assets/battle/cs2/battle-cs2-5.jpg`,
  'cs2-6': `${process.env.PUBLIC_URL}/assets/battle/cs2/battle-cs2-6.jpg`,
  'cs2-7': `${process.env.PUBLIC_URL}/assets/battle/cs2/battle-cs2-7.jpg`,
  'cs2-8': `${process.env.PUBLIC_URL}/assets/battle/cs2/battle-cs2-8.jpg`,
  'cs2-9': `${process.env.PUBLIC_URL}/assets/battle/cs2/battle-cs2-9.jpg`,
  'cs2-10': `${process.env.PUBLIC_URL}/assets/battle/cs2/battle-cs2-10.jpg`,
} as const;

export const battleShellSquareBackgroundsDota = {
  'dota-1': `${process.env.PUBLIC_URL}/assets/battle/dota/battle-dota-1.jpg`,
  'dota-2': `${process.env.PUBLIC_URL}/assets/battle/dota/battle-dota-2.jpg`,
  'dota-3': `${process.env.PUBLIC_URL}/assets/battle/dota/battle-dota-3.jpg`,
  'dota-4': `${process.env.PUBLIC_URL}/assets/battle/dota/battle-dota-4.jpg`,
  'dota-5': `${process.env.PUBLIC_URL}/assets/battle/dota/battle-dota-5.jpg`,
  'dota-6': `${process.env.PUBLIC_URL}/assets/battle/dota/battle-dota-6.jpg`,
  'dota-7': `${process.env.PUBLIC_URL}/assets/battle/dota/battle-dota-7.jpg`,
  'dota-8': `${process.env.PUBLIC_URL}/assets/battle/dota/battle-dota-8.jpg`,
  'dota-9': `${process.env.PUBLIC_URL}/assets/battle/dota/battle-dota-9.jpg`,
  'dota-10': `${process.env.PUBLIC_URL}/assets/battle/dota/battle-dota-10.jpg`,
} as const;

export const battleShellSquareBackgroundsFootball = {
  'football-1': `${process.env.PUBLIC_URL}/assets/battle/football/battle-football-1.jpg`,
  'football-2': `${process.env.PUBLIC_URL}/assets/battle/football/battle-football-2.jpg`,
  'football-3': `${process.env.PUBLIC_URL}/assets/battle/football/battle-football-3.jpg`,
  'football-4': `${process.env.PUBLIC_URL}/assets/battle/football/battle-football-4.jpg`,
  'football-5': `${process.env.PUBLIC_URL}/assets/battle/football/battle-football-5.jpg`,
  'football-6': `${process.env.PUBLIC_URL}/assets/battle/football/battle-football-6.jpg`,
  'football-7': `${process.env.PUBLIC_URL}/assets/battle/football/battle-football-7.jpg`,
  'football-8': `${process.env.PUBLIC_URL}/assets/battle/football/battle-football-8.jpg`,
  'football-9': `${process.env.PUBLIC_URL}/assets/battle/football/battle-football-9.jpg`,
  'football-10': `${process.env.PUBLIC_URL}/assets/battle/football/battle-football-10.jpg`,
} as const;

export const battleShellSquareBackgroundsHockey = {
  'hockey-1': `${process.env.PUBLIC_URL}/assets/battle/hockey/battle-hockey-1.jpg`,
  'hockey-2': `${process.env.PUBLIC_URL}/assets/battle/hockey/battle-hockey-2.jpg`,
  'hockey-3': `${process.env.PUBLIC_URL}/assets/battle/hockey/battle-hockey-3.jpg`,
  'hockey-4': `${process.env.PUBLIC_URL}/assets/battle/hockey/battle-hockey-4.jpg`,
  'hockey-5': `${process.env.PUBLIC_URL}/assets/battle/hockey/battle-hockey-5.jpg`,
  'hockey-6': `${process.env.PUBLIC_URL}/assets/battle/hockey/battle-hockey-6.jpg`,
  'hockey-7': `${process.env.PUBLIC_URL}/assets/battle/hockey/battle-hockey-7.jpg`,
  'hockey-8': `${process.env.PUBLIC_URL}/assets/battle/hockey/battle-hockey-8.jpg`,
  'hockey-9': `${process.env.PUBLIC_URL}/assets/battle/hockey/battle-hockey-9.jpg`,
  'hockey-10': `${process.env.PUBLIC_URL}/assets/battle/hockey/battle-hockey-10.jpg`,
} as const;

export const battleShellSquareBackgroundsLeagueOfLegends = {
  'league-of-legends-1': `${process.env.PUBLIC_URL}/assets/battle/league-of-legends/battle-league-of-legends-1.jpg`,
  'league-of-legends-2': `${process.env.PUBLIC_URL}/assets/battle/league-of-legends/battle-league-of-legends-2.jpg`,
  'league-of-legends-3': `${process.env.PUBLIC_URL}/assets/battle/league-of-legends/battle-league-of-legends-3.jpg`,
  'league-of-legends-4': `${process.env.PUBLIC_URL}/assets/battle/league-of-legends/battle-league-of-legends-4.jpg`,
  'league-of-legends-5': `${process.env.PUBLIC_URL}/assets/battle/league-of-legends/battle-league-of-legends-5.jpg`,
  'league-of-legends-6': `${process.env.PUBLIC_URL}/assets/battle/league-of-legends/battle-league-of-legends-6.jpg`,
  'league-of-legends-7': `${process.env.PUBLIC_URL}/assets/battle/league-of-legends/battle-league-of-legends-7.jpg`,
  'league-of-legends-8': `${process.env.PUBLIC_URL}/assets/battle/league-of-legends/battle-league-of-legends-8.jpg`,
  'league-of-legends-9': `${process.env.PUBLIC_URL}/assets/battle/league-of-legends/battle-league-of-legends-9.jpg`,
  'league-of-legends-10': `${process.env.PUBLIC_URL}/assets/battle/league-of-legends/battle-league-of-legends-10.jpg`,
} as const;

export const battleShellSquareBackgroundsMMA = {
  'mma-1': `${process.env.PUBLIC_URL}/assets/battle/mma/battle-mma-1.jpg`,
  'mma-2': `${process.env.PUBLIC_URL}/assets/battle/mma/battle-mma-2.jpg`,
  'mma-3': `${process.env.PUBLIC_URL}/assets/battle/mma/battle-mma-3.jpg`,
  'mma-4': `${process.env.PUBLIC_URL}/assets/battle/mma/battle-mma-4.jpg`,
  'mma-5': `${process.env.PUBLIC_URL}/assets/battle/mma/battle-mma-5.jpg`,
  'mma-6': `${process.env.PUBLIC_URL}/assets/battle/mma/battle-mma-6.jpg`,
  'mma-7': `${process.env.PUBLIC_URL}/assets/battle/mma/battle-mma-7.jpg`,
  'mma-8': `${process.env.PUBLIC_URL}/assets/battle/mma/battle-mma-8.jpg`,
  'mma-9': `${process.env.PUBLIC_URL}/assets/battle/mma/battle-mma-9.jpg`,
  'mma-10': `${process.env.PUBLIC_URL}/assets/battle/mma/battle-mma-10.jpg`,
} as const;

export const battleShellSquareBackgroundsTennis = {
  'tennis-1': `${process.env.PUBLIC_URL}/assets/battle/tennis/battle-tennis-1.jpg`,
  'tennis-2': `${process.env.PUBLIC_URL}/assets/battle/tennis/battle-tennis-2.jpg`,
  'tennis-3': `${process.env.PUBLIC_URL}/assets/battle/tennis/battle-tennis-3.jpg`,
  'tennis-4': `${process.env.PUBLIC_URL}/assets/battle/tennis/battle-tennis-4.jpg`,
  'tennis-5': `${process.env.PUBLIC_URL}/assets/battle/tennis/battle-tennis-5.jpg`,
  'tennis-6': `${process.env.PUBLIC_URL}/assets/battle/tennis/battle-tennis-6.jpg`,
  'tennis-7': `${process.env.PUBLIC_URL}/assets/battle/tennis/battle-tennis-7.jpg`,
  'tennis-8': `${process.env.PUBLIC_URL}/assets/battle/tennis/battle-tennis-8.jpg`,
  'tennis-9': `${process.env.PUBLIC_URL}/assets/battle/tennis/battle-tennis-9.jpg`,
  'tennis-10': `${process.env.PUBLIC_URL}/assets/battle/tennis/battle-tennis-10.jpg`,
} as const;

export const battleShellSquareBackgrounds = {
  ...battleShellSquareBackgroundsMMA,
  ...battleShellSquareBackgroundsBasketball,
  ...battleShellSquareBackgroundsFootball,
  ...battleShellSquareBackgroundsHockey,
  ...battleShellSquareBackgroundsCricket,
  ...battleShellSquareBackgroundsBaseball,
  ...battleShellSquareBackgroundsDota,
  ...battleShellSquareBackgroundsCS2,
  ...battleShellSquareBackgroundsLeagueOfLegends,
  ...battleShellSquareBackgroundsTennis,
  ...battleShellSquareBackgroundsAmericanFootball,
} as const;

export type BattleShellSquareBackground = keyof typeof battleShellSquareBackgrounds;

const backgroundsMap = {
  [ApiCommonSportEnum.MMA]: battleShellSquareBackgroundsMMA,
  [ApiCommonSportEnum.Basketball]: battleShellSquareBackgroundsBasketball,
  [ApiCommonSportEnum.Football]: battleShellSquareBackgroundsFootball,
  [ApiCommonSportEnum.Hockey]: battleShellSquareBackgroundsHockey,
  [ApiCommonSportEnum.Cricket]: battleShellSquareBackgroundsCricket,
  [ApiCommonSportEnum.Baseball]: battleShellSquareBackgroundsBaseball,
  [ApiCommonSportEnum.Dota]: battleShellSquareBackgroundsDota,
  [ApiCommonSportEnum.CS2]: battleShellSquareBackgroundsCS2,
  [ApiCommonSportEnum.LeagueOfLegends]: battleShellSquareBackgroundsLeagueOfLegends,
  [ApiCommonSportEnum.Tennis]: battleShellSquareBackgroundsTennis,
  [ApiCommonSportEnum.AmericanFootball]: battleShellSquareBackgroundsAmericanFootball,
} as const;

export const isRandomBackground = (string: ApiCommonSport | string): string is ApiCommonSport => {
  switch (string as ApiCommonSportEnum) {
    case ApiCommonSportEnum.MMA:
    case ApiCommonSportEnum.Basketball:
    case ApiCommonSportEnum.Football:
    case ApiCommonSportEnum.Hockey:
    case ApiCommonSportEnum.Cricket:
    case ApiCommonSportEnum.Baseball:
    case ApiCommonSportEnum.Dota:
    case ApiCommonSportEnum.LeagueOfLegends:
    case ApiCommonSportEnum.CS2:
    case ApiCommonSportEnum.Tennis:
    case ApiCommonSportEnum.AmericanFootball:
      return true;

    default:
      return false;
  }
};

export const getRandomBackground = (type: ApiCommonSport, hash: string | number) => {
  let targetBackgrounds = backgroundsMap[type];

  if (!targetBackgrounds) {
    targetBackgrounds = battleShellSquareBackgrounds;
  }

  const backgrounds = Object.values(targetBackgrounds);
  const keys = Array.from(backgrounds.keys());
  const key = getKeyInKeysByHash(String(hash), keys);

  return backgrounds[key];
};

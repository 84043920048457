import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import { NftCard } from '~entities/nft';

import {
  Notification,
  NotificationCaption,
  NotificationIconFactory,
  NotificationMessage,
} from '../../../factory';

import { NotificationPutCardOnAuctionProps } from './types';

export const NotificationPutCardOnAuction = forwardRef<
  HTMLDivElement,
  NotificationPutCardOnAuctionProps
>(({ place, notification }, ref) => {
  const { t } = useTranslation();

  return (
    <Notification
      ref={ref}
      place={place}
      date={notification.date}
      read={notification.read}
      icon={
        <NotificationIconFactory type="card-state" state="auction">
          <NftCard nft={notification.payload.tokenId} />
        </NotificationIconFactory>
      }
    >
      <NotificationMessage>
        {t('Notifications.youPutYourCardOnAuction', {
          startPrice: notification.payload?.startPrice,
        })}
      </NotificationMessage>
      <NotificationCaption>{t('Notifications.putCardAuctionTime')}</NotificationCaption>
    </Notification>
  );
});

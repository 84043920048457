import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useEventModel } from '~entities/event';

import { useMediaQuery } from '~shared/lib/hooks';

import { Icon } from '~shared/ui';

import { EventChoiceDragCell, EventChoiceLabel, IconAndLabelContainer, Root } from './styled';
import { EventChoiceProps } from './types';

export const EventChoice: FC<EventChoiceProps> = ({
  isHighlighted,
  isReturn,
  isInitial,
  nft,
  dropRef,
  onAddCards,
  disabled = false,
  icon,
  label,
  result,
}) => {
  const { t } = useTranslation();
  const { choice } = useEventModel();

  const { isMobile } = useMediaQuery();

  const isChoiceNotEqualsResult = choice && choice !== result;

  const getLabel = () => {
    switch (true) {
      case !!label:
        return <EventChoiceLabel>{label}</EventChoiceLabel>;

      case isMobile && !isChoiceNotEqualsResult:
        return (
          <EventChoiceLabel>
            {t('EventDialog.add')} <br /> {t('EventDialog.cardHere')}
          </EventChoiceLabel>
        );

      case isChoiceNotEqualsResult:
        return (
          <EventChoiceLabel>
            {t('EventDialog.canMove')} {isMobile && <br />} {t('EventDialog.cardHere')}
          </EventChoiceLabel>
        );

      default:
        return (
          <EventChoiceLabel>
            {t('EventDialog.drag')} {t('EventDialog.cardHere')}
          </EventChoiceLabel>
        );
    }
  };

  return (
    <Root>
      <EventChoiceDragCell
        onClick={onAddCards}
        ref={dropRef}
        $isHighlighted={isHighlighted}
        $disabled={disabled}
        $isInitial={isInitial}
      >
        {nft}

        {(onAddCards || icon) && result !== choice && (
          <IconAndLabelContainer>
            <Icon
              size={isMobile ? 22 : 28}
              name={icon ?? (disabled ? 'x' : isReturn ? 'back' : 'plus-round')}
            />

            {getLabel()}
          </IconAndLabelContainer>
        )}
      </EventChoiceDragCell>
    </Root>
  );
};

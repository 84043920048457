import { styled } from '~shared/lib/styles';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '10px',

  [theme.breakpoints.down('mobile')]: {
    position: 'fixed',
    width: '100%',
    left: 0,
    bottom: '64px',
    padding: '14px 16px',
    background: theme.palette.paper.secondary,
    zIndex: theme.zIndex.modalOverlap,
  },
}));

export const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'max-content auto max-content',
  alignItems: 'center',
  gap: 12,

  [theme.breakpoints.down('mobile')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'initial',
    position: 'sticky',
    bottom: 0,
    padding: 0,
    margin: '14px -16px 0',

    boxShadow: '0 0 16px 4px rgb(0 0 0 / 60%)',
    zIndex: 1,
  },
}));

export const ButtonsContainer = styled('div', {
  name: 'EventDialogActions',
  slot: 'buttonsContainer',
})(({ theme }) => ({
  display: 'flex',
  gap: 12,

  [theme.breakpoints.down('mobile')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

import { FC } from 'react';

import { NftRarity } from '~shared/api';

import { NftCard, NftCardsStack } from '~entities/nft';

interface TournamentCardsPlaceholderProps {
  rarity: NftRarity;
  quantity: number;
}

export const TournamentCardsPlaceholder: FC<TournamentCardsPlaceholderProps> = ({
  quantity,
  rarity,
}) => {
  return (
    <NftCardsStack cardsToShow={quantity}>
      {Array.from(new Array(quantity)).map((_, key) => (
        <NftCard key={key} nft={{ token_id: 'undefined', rarity }} size="xs" placeholder />
      ))}
    </NftCardsStack>
  );
};

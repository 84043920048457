import { useQuery } from 'react-query';

import { getTournamentsList } from '~shared/api';

export const useQueryTournamentsList = () => {
  const { data: list, isLoading } = useQuery(['tournaments'], () => getTournamentsList());

  return {
    list,
    isLoading,
  };
};

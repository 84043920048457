import { FC } from 'react';

import { Button, Stack, Typography } from '~shared/ui';

import { MarketplaceUnfreezeDetailsProps } from './types';

export const MarketplaceUnfreezeDetails: FC<MarketplaceUnfreezeDetailsProps> = () => {
  return (
    <Stack flex={1} justifyContent="space-between" height="100%">
      <Typography color="secondary" sx={{ fontSize: '13px' }}>
        Unfreezing the card allows you to instantly play with it again
      </Typography>

      <Button disabled>Buy</Button>
    </Stack>
  );
};

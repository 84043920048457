import ReactDOM from 'react-dom/client';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import { enableMapSet } from 'immer';
import { Userpilot } from 'userpilot';

import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

import './shared/config/i18n/i18n';
import './codeSplittingModules';

import { isTest } from '~shared/config';

import pkgjson from '../package.json';

import { App } from './app';

TiktokPixel.init('CIJF0DJC77UD88O19UT0');
ReactPixel.init('963902214621802');

if (isTest()) {
  Userpilot.initialize('NX-772ce79e');
}

// ability to store Map and Set in redux store
enableMapSet();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://9d3777935ae28096e583473d79e9a1ae@o4506658841165824.ingest.sentry.io/4506658848768000',
    integrations: [new BrowserTracing()],

    maxBreadcrumbs: 50,
    debug: false,
    tracesSampleRate: 1,
  });
}

root.render(<App />);

/* eslint-disable no-console */
console.log(`Build version: ${pkgjson.version}`);

import { styled } from '~shared/lib/styles';

export const CellsContainer = styled('div')(({ theme }) => ({
  flex: 1,

  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(1px, 1fr))',
  gap: 16,

  [theme.breakpoints.down('mobile')]: {
    gap: 20,
  },
}));

import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, FormControlLabel } from '../../controls';

import { DontShowWindowInFutureProps } from './types';

export const DontShowWindowInFuture: FC<DontShowWindowInFutureProps> = ({ onChange }) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>, value: boolean) => {
    onChange(value);
    setChecked(value);
  };

  return (
    <FormControlLabel
      label={t('Other.dontShowInFuture')}
      checked={checked}
      onChange={handleChange}
      control={<Checkbox />}
    />
  );
};

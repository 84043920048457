import { paramsSerializerWithArray } from '~shared/lib/utils';

import { apiInstance, tonApiInstance } from '../apiInstance';

import { mapGetBattlesByIdsResponse, mapGetBattlesResponse } from './mappers';

import {
  ApiGetBattles,
  ApiGetBattlesByIds,
  ApiGetBattlesByIdsRawData,
  ApiGetBattlesRawData,
} from './types';
import { BattleRoutes } from './routes';

export const getBattles: ApiGetBattles = (params) => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .get<Array<Array<ApiGetBattlesRawData>>>(BattleRoutes.tonEvents(), { params })
      .then(({ data }) => resolve(mapGetBattlesResponse(data)))
      .catch(reject);
  });
};

export const getBattlesByIds: ApiGetBattlesByIds = (params) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get<Array<Array<ApiGetBattlesByIdsRawData>>>(BattleRoutes.fixturesByIds(), {
        params,
        paramsSerializer: paramsSerializerWithArray,
      })
      .then(({ data }) => resolve(mapGetBattlesByIdsResponse(data)))
      .catch(reject);
  });
};

import { Stack } from '~shared/ui';

export const MarketplaceListSettings = () => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" padding="14px 0">
      {/* Hidden until there is a description of the filtering */}

      {/* <Stack spacing={1} direction="row">
        <ToggleButtonGroup value="all">
          {filterOptions.map((option) => (
            <ToggleButton value={option.value} key={option.value} disabled>
              {option.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack> */}
    </Stack>
  );
};

// const filterOptions = [
//   { label: 'All', value: 'all' },
//   { label: 'Cards', value: 'cards' },
//   { label: 'Mystery box', value: 'mystery-box' },
// ];

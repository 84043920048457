import { FC, Fragment, useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { PopoverMotion } from '~shared/ui';
import { mergeRefs } from '~shared/lib/utils';
import { useSwitcher } from '~shared/lib/hooks';

import { NftCardInfo } from '../NftCardInfo';

import { isNftAvailableForMerge } from '../../lib';
import { NftDragItemType, useNftCardModel, useNftPreviewInfo } from '../../model';

import { NftCard } from '../NftCard';

import { StyledCheckbox } from './styled';
import { NftCardDragProps } from './types';

export const NftCardDrag: FC<NftCardDragProps> = ({
  nft,
  dragKey = NftDragItemType.Place,
  selectionMode,
  checked,
  location = 'free',

  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isMinting = useSwitcher(false);

  const { closePreview, getPreviewOpen } = useNftPreviewInfo();
  const { setDropZoneOpen, mergeDialogOpen } = useNftCardModel();

  const previewOpened = getPreviewOpen(nft.token_id) && location === 'wallet';

  const [{ isDragging, canDrag }, dragRef, preview] = useDrag(
    () => ({
      type: dragKey,
      item: { tokenId: nft.token_id, rarity: nft.rarity, winStreak: nft.winStreak },
      options: { dropEffect: 'link' },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        canDrag: monitor.canDrag(),
      }),
      canDrag: () => {
        const isBlockedByMergeDialog = mergeDialogOpen && !isNftAvailableForMerge(nft).isAvailable;

        const isNftBlockedForAnyActions =
          nft.isBlockedForEvent ||
          nft.isOnEvent ||
          nft.isFreezed ||
          nft.isOnAuction ||
          nft.isFreezed ||
          nft.isHidden ||
          nft.isBlockedForTransaction ||
          isMinting.value ||
          isBlockedByMergeDialog;

        return !isNftBlockedForAnyActions;
      },
    }),
    [isMinting.value, nft]
  );

  const selectionAvailable = selectionMode && canDrag;

  useEffect(() => {
    setDropZoneOpen(isDragging);
  }, [isDragging, setDropZoneOpen]);

  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, [preview, isDragging]);

  useEffect(() => {
    if (!props.handleNatively && isMinting.value) {
      if (previewOpened) {
        closePreview();
      }
    }
  }, [closePreview, isMinting.value, previewOpened, props.handleNatively]);

  return (
    <Fragment>
      <NftCard
        nft={nft}
        highlight={previewOpened}
        isMintingSwitcher={isMinting}
        ref={mergeRefs<HTMLDivElement>([ref, dragRef])}
        {...props}
      >
        <PopoverMotion
          open={previewOpened}
          onClose={closePreview}
          anchorEl={ref.current}
          placement="left-start"
          modifiers={[{ name: 'offset', options: { offset: [-20, 5] } }]}
        >
          <NftCardInfo nft={nft} />
        </PopoverMotion>

        {selectionAvailable && <StyledCheckbox checked={checked} />}
      </NftCard>
    </Fragment>
  );
};

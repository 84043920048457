/* eslint-disable no-useless-computed-key */
import { createElement } from 'react';
import { GlobalStyles } from '@mui/system';

import { Theme } from '~shared/lib/styles';

export const globalStyles = createElement(GlobalStyles, {
  styles: (theme: Theme) => ({
    ['html']: {
      color: theme.palette.text.primary,
    },

    ['body']: {
      backgroundColor: theme.palette.paper.main,
    },

    ['html, input, textarea, button']: {
      fontFamily: ['Raleway', 'sans-serif'].join(', '),
      fontVariantNumeric: 'lining-nums',
      fontSize: 14,
      lineHeight: 1.5,
    },

    ['*, *::before, *::after']: {
      padding: 0,
      margin: 0,
      boxSizing: 'border-box',
      WebkitFontSmoothing: 'antialiased',
      outline: 'none',
      ['-moz-osx-font-smoothing']: 'grayscale',
    },

    ['button']: {
      fontFamily: 'inherit',

      ['&:not([disabled])']: {
        cursor: 'pointer',
      },
    },

    ['a']: {
      color: theme.palette.primary.main,
      textDecoration: 'none',

      ['&:hover']: {
        textDecoration: 'underline',
      },

      ['&:visited']: {
        color: theme.palette.primary.main,
      },
    },

    ['img']: {
      pointerEvents: 'none',
    },

    ['ul']: {
      paddingLeft: '1em',
    },

    ['.web3modal-modal-lightbox']: {
      zIndex: theme.zIndex.web3Modal,
    },

    ['.grecaptcha-badge']: {
      visibility: 'hidden',
    },
  }),
});

import { useInitData } from '@vkruglikov/react-telegram-web-app';
import { useCallback, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSnackbar, useSwitcher } from '~shared/lib/hooks';

import { useAuthModel } from '../model';

export interface PasswordCreationForm {
  password: string;
  repeatPassword: string;
}

export const usePasswordCreationForm = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const { openSnackbar } = useSnackbar();

  const submitting = useSwitcher(false);
  const captchaV2Required = useSwitcher(false);
  const banned = useSwitcher(false);

  const captchaV2PlaceholderRef = useRef(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [captchaV2Token, setCaptchaV2Token] = useState<null | string>(null);

  const { setStep, signUp, email, nickname, renderV2Captcha } = useAuthModel();

  const [initDataUnsafe] = useInitData();

  const form = useForm<PasswordCreationForm>({});

  const onSubmit = useCallback<SubmitHandler<PasswordCreationForm>>(
    async ({ password }) => {
      submitting.switchOn();

      if (captchaV2Required.value) {
        const { result } = await signUp(
          { password, email, nickname, language, refCode: initDataUnsafe?.start_param },
          captchaV2Token!
        );

        switch (result) {
          case 'new':
            setStep((steps) => steps.VerificationCode);

            return;

          case 'Bad recaptcha v2':
            submitting.switchOff();

            break;
        }

        openSnackbar({ message: `Unable to Sign Up: ${result}`, type: 'error' });

        return;
      }

      const { result } = await signUp({
        password,
        email,
        nickname,
        language,
        refCode: initDataUnsafe?.start_param,
      });

      submitting.switchOff();

      switch (result) {
        case 'new':
          setStep((steps) => steps.VerificationCode);

          return;

        case 'Bad recaptcha score':
          captchaV2Required.switchOn();

          renderV2Captcha({
            container: captchaV2PlaceholderRef.current!,
            setToken: setCaptchaV2Token,
            omitExpiredToken: () => setCaptchaV2Token(null),
          });

          return;

        case 'Very bad recaptcha score':
          banned.switchOn();

          openSnackbar({ message: `Unable to Sign Up: ${result}`, type: 'error' });

          return;
      }
    },
    [
      submitting,
      captchaV2Required,
      signUp,
      email,
      nickname,
      language,
      captchaV2Token,
      setStep,
      openSnackbar,
      renderV2Captcha,
      banned,
      initDataUnsafe?.start_param,
    ]
  );

  const submitAvailable = (() => {
    const isBanned = banned.value;
    const isSubmitting = submitting.value;
    const isV2CaptchaComplete = Boolean(captchaV2Token);

    if (isBanned || isSubmitting) {
      return false;
    }

    if (captchaV2Required.value) {
      return isV2CaptchaComplete;
    }

    return true;
  })();

  return {
    form,
    onSubmit: form.handleSubmit(onSubmit),
    submitAvailable,

    captchaV2PlaceholderRef,
    submitButtonRef,
  };
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ApiGetAuctionCardMappedData } from '~shared/api';

import { AuctionState } from './types';

const initialState: AuctionState = {
  auctionCards: [],
  isLoading: true,
};

export const auctionSlice = createSlice({
  name: 'auction',
  initialState: initialState,
  reducers: {
    setAuctionCards: (state, action: PayloadAction<Array<ApiGetAuctionCardMappedData>>) => ({
      ...state,
      auctionCards: action.payload,
    }),
    setBidToLot: (
      state,
      action: PayloadAction<{
        lotId: number;
        bet: number;
        bettor: string;
        bettorNicname: string;
        bettorAvatar?: string;
      }>
    ) => ({
      ...state,
      auctionCards: state.auctionCards.map((lot) => {
        if (lot.lotId === action.payload.lotId) {
          return {
            ...lot,
            bestBet: action.payload.bet,
            bestBettor: action.payload.bettor,
            bestBettorAvatar: action.payload.bettorAvatar ?? '',
            bestBettorNickname: action.payload.bettorNicname,
            bidHistory: [
              ...lot.bidHistory,
              {
                bet: action.payload.bet,
                bettor: action.payload.bettor,
                bettorAvatar: action.payload.bettorAvatar ?? '',
                bettorNickname: action.payload.bettorNicname,
              },
            ],
          };
        }

        return lot;
      }),
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload,
    }),

    reset: () => {
      return { ...initialState };
    },
  },
});

export const auctionActions = auctionSlice.actions;

import { FC, Fragment } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Button, CreatePassword, Link, RepeatPassword, Stack, Typography } from '~shared/ui';

import { usePasswordCreationForm } from '../../../model';
import { AuthDialogTitle } from '../../AuthDialogTitle';

export const PasswordCreation: FC = () => {
  const { t } = useTranslation();

  const {
    /***/
    form,
    onSubmit,
    captchaV2PlaceholderRef,
    submitAvailable,
    submitButtonRef,
  } = usePasswordCreationForm();

  return (
    <Fragment>
      <AuthDialogTitle>{t('Other.createPass')}</AuthDialogTitle>

      <FormProvider {...form}>
        <Stack component="form" spacing={24 / 8} onSubmit={onSubmit}>
          <CreatePassword autoFocus />
          <RepeatPassword />

          <Box ref={captchaV2PlaceholderRef} alignSelf="center" />

          <Button type="submit" ref={submitButtonRef} disabled={!submitAvailable}>
            {t('Other.createPass')}
          </Button>

          <Typography align="center" fontSize={12}>
            <Trans i18nKey="Other.recaptchaTermsOfService" t={t}>
              This site is protected by reCAPTCHA and the Google{' '}
              <Link to="https://policies.google.com/privacy" target="_blank">
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link to="https://policies.google.com/terms" target="_blank">
                Terms of Service
              </Link>{' '}
              apply.
            </Trans>
          </Typography>
        </Stack>
      </FormProvider>
    </Fragment>
  );
};

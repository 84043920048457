import { useTonWallet } from '@tonconnect/ui-react';

import { Box, Typography } from '~shared/ui';

import { useDailyQuestRewardSelector } from '~widgets/daily-quest-reward-dialog/model/selectors';
import { useDailyQuestProgressModel } from '~widgets/daily-quest-progress-dialog';

import { ProgressBar, ProgressBarOverlap, ProgressBarWrapper } from './styled';

export const DailyQuestProgressBar = () => {
  const wallet = useTonWallet();
  const { openModal } = useDailyQuestProgressModel();
  const { countedDays, allDays } = useDailyQuestRewardSelector();

  if (!wallet) {
    return;
  }

  return (
    <Box paddingInline="16px" onClick={openModal}>
      <ProgressBarWrapper>
        <Typography
          fontSize={13}
          fontWeight={500}
          lineHeight="15.26px"
          textAlign="left"
          width="fit-content"
          color="rgba(255, 255, 255, 0.75)"
        >
          Daily tasks
        </Typography>
        <ProgressBar>
          <ProgressBarOverlap $allDays={allDays} $countedDays={countedDays} />
        </ProgressBar>
        <Typography fontSize={13} fontWeight={700} lineHeight="15.26px" textAlign="left">
          {countedDays}/{allDays}
        </Typography>
      </ProgressBarWrapper>
    </Box>
  );
};

import { ChangeEvent, MouseEvent, forwardRef, useCallback } from 'react';
import { useInput } from '@mui/base';
import { clsx } from 'clsx';

import { useSwitcher } from '~shared/lib/hooks';
import { mergeRefs } from '~shared/lib/utils';

import {
  UIKitInput,
  UIKitInputEndAdornment,
  UIKitInputPasswordButtonIcon,
  UIKitInputRoot,
  inputClasses,
} from './styled';

import { InputProps, UseInputReturnType } from './types';

export const Input = forwardRef<HTMLDivElement, InputProps>(
  (
    {
      startAdornment,
      endAdornment,
      labeled,

      multiline,
      type,
      className,
      inputRef,

      omitWhitespace = false,

      ...props
    },
    ref
  ) => {
    const passwordVisibility = useSwitcher(false);

    const { getInputProps, getRootProps, formControlContext, ...stateProps } = useInput(props);
    const inputProps = getInputProps();
    const states = getStates(formControlContext, stateProps);

    const showPasswordCondition = (states.focused || states.filled) && !states.disabled;

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (omitWhitespace) {
          event.target.value = event.target.value.replace(/\s/g, '');
        }

        inputProps.onChange.call(null, event);
      },
      [inputProps.onChange, omitWhitespace]
    );

    return (
      <UIKitInputRoot
        {...getRootProps()}
        className={clsx(
          {
            [inputClasses.focused]: states?.focused,
            [inputClasses.disabled]: states?.disabled,
            [inputClasses.filled]: states?.filled,
            [inputClasses.error]: states?.error,
            [inputClasses.labeled]: labeled,
            [inputClasses.multiline]: multiline,
          },
          className
        )}
        ref={ref}
      >
        {startAdornment}

        <UIKitInput
          {...props}
          {...inputProps}
          as={multiline ? 'textarea' : 'input'}
          onChange={handleChange}
          type={passwordVisibility.value ? 'text' : type}
          className={inputClasses.input}
          ref={mergeRefs([inputProps.ref, inputRef])}
        />

        {(endAdornment || type === 'password') && (
          <UIKitInputEndAdornment>
            {type === 'password'
              ? showPasswordCondition && (
                  <UIKitInputPasswordButtonIcon
                    variant="plain"
                    name="eye"
                    size={24}
                    onMouseDown={(event: MouseEvent) => event.preventDefault()}
                    onClick={passwordVisibility.toggle}
                    passwordVisibility={passwordVisibility.value}
                  />
                )
              : endAdornment}
          </UIKitInputEndAdornment>
        )}
      </UIKitInputRoot>
    );
  }
);

const getStates = (
  formControlContext: UseInputReturnType['formControlContext'],
  inputProps: Partial<UseInputReturnType>
) => {
  return {
    focused: formControlContext?.focused || inputProps.focused,
    disabled: formControlContext?.disabled || inputProps.disabled,
    error: formControlContext?.error || inputProps.error,
    filled: formControlContext?.filled,
  };
};

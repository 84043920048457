import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { BottomNavigation, BottomNavigationAction, Box, Icon } from '~shared/ui';
import { isValueInObjectRecursive } from '~shared/lib/utils';
import { useSwitcher } from '~shared/lib/hooks';
import { isTest, routes } from '~shared/config';

import { SportsMenu } from '~widgets/sports-menu';

const tabValues = {
  sports: 'sports',
  marketplace: routes.marketplace,
  ranking: routes.ranking,
  wallet: routes.wallet,
  tournaments: routes.tournaments,
};

export const TabBarMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const open = useSwitcher(false);
  const { pathname: value } = useLocation();

  const handleChange = (event: any, route: string) => {
    if (isValueInObjectRecursive(routes, route)) {
      navigate(route);
    }
  };

  const computedValue = useMemo(() => {
    if (open.value || isValueInObjectRecursive(routes.sports, value)) {
      return tabValues.sports;
    }

    return value;
  }, [open.value, value]);

  return (
    <Box>
      <SportsMenu open={open.value} onClose={open.switchOff} />

      <BottomNavigation onChange={handleChange} value={computedValue}>
        <BottomNavigationAction
          value={tabValues.sports}
          label={t('HeaderTabs.sports')}
          icon={<Icon name="menu" />}
          onClick={open.switchOn}
        />
        <BottomNavigationAction
          value={tabValues.ranking}
          label={t('HeaderTabs.rankings')}
          icon={<Icon name="cup" />}
        />
        <BottomNavigationAction
          value={tabValues.marketplace}
          label={t('HeaderTabs.marketplace')}
          icon={<Icon name="shop" />}
        />
        <BottomNavigationAction
          value={tabValues.tournaments}
          label={t('HeaderTabs.tournaments')}
          icon={<Icon name="battle" />}
        />
        <BottomNavigationAction
          value={tabValues.wallet}
          label={t('HeaderTabs.cards')}
          icon={<Icon name="cards-outlined" />}
        />
      </BottomNavigation>
    </Box>
  );
};

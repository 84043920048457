import { FC } from 'react';

import { Avatar } from '~shared/ui';

import { NotificationUserRoot } from './styled';

import { NotificationUserProps } from './types';

export const NotificationUser: FC<NotificationUserProps> = ({ name, avatar }) => {
  return (
    <NotificationUserRoot>
      <Avatar src={avatar} size={16} />

      {name}
    </NotificationUserRoot>
  );
};

import { useForm } from 'react-hook-form';

import { NicknameField, NicknameFieldCheckingNicknameFieldName } from '~shared/ui';

import { useViewerModel } from '~entities/viewer';

interface PersonalDataForm extends Record<NicknameFieldCheckingNicknameFieldName, boolean> {
  email: string;
  nickname: string;
}

export const usePersonalDataForm = () => {
  const { nickname, email, updateNickname } = useViewerModel();

  const form = useForm<PersonalDataForm>({
    defaultValues: { email, nickname },
    mode: 'onChange',
  });

  const handleSubmit = form.handleSubmit((data) => {
    if (data.nickname !== nickname) {
      updateNickname(data.nickname);
    }
  });

  const isSubmitAvailable =
    nickname !== form.watch('nickname') && !form.watch(NicknameField.checkingNicknameFieldName);

  return {
    currentNickname: nickname,
    isSubmitAvailable,
    handleSubmit,
    form,
  };
};

import { FC, Fragment, MouseEventHandler, cloneElement, isValidElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, ButtonIcon, Divider, Stack, Typography } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { NFT_RARITY_TO_READABLE_RARITY_NAME_MAP } from '~entities/nft';

import { TournamentPlayersPlaceholder } from '../../TournamentPlayersPlaceholder';
import { TournamentCardsPlaceholder } from '../../TournamentCardsPlaceholder';
import { TournamentInfoRow } from '../../TournamentInfoRow';
import { TournamentPlayers } from '../TournamentPlayers';
import { TournamentBadge } from '../../TournamentBadge';

import { getTournamentStatus, useTournamentPrizesInfoElements } from '../../../lib';

import { TournamentAllPrizePlacesControl } from '../../TournamentAllPrizePlacesControl';

import { TournamentPrize } from '../../TournamentPrize';

import { TournamentPrizeContainer } from '../../TournamentPrizeContainer';

import {
  TournamentBackButton,
  TournamentDescriptionContainer,
  TournamentHeading,
  TournamentHeadingMobileLabel,
  TournamentInfoPlayersValue,
} from './styled';

import { TournamentDialogContentProps } from './types';

export const TournamentDialogContent: FC<TournamentDialogContentProps> = ({
  tournament,
  joinButton,
  onClose,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const {
    startDate,
    endDate,

    joinCardsQuantity,
    tournamentRarity,
    status: tournamentStatus,
    prizes,
    players,
    playersLimit,
  } = tournament;

  const status = getTournamentStatus(startDate, endDate);

  const handleClose: MouseEventHandler = (event) => {
    if (onClose) {
      onClose(event, 'closeClick');
    }
  };

  const renderPrizes = () => {
    return prizes.map((prize, index) => (
      <TournamentPrize
        key={index}
        place={index + 1}
        items={prize.items}
        currency={prize.currency}
        prize={prize.value}
        status={tournamentStatus}
      />
    ));
  };

  return (
    <Fragment>
      <Stack spacing={4 / 8} direction="row" alignItems="center">
        {isMobile && (
          <TournamentBackButton
            name="arrow-left"
            variant="square"
            size={24}
            onClick={handleClose}
          />
        )}

        <Stack direction="row" justifyContent="space-between" alignSelf="stretch" flex={1}>
          <Stack direction="row" spacing={1}>
            <TournamentBadge type="date" startDate={startDate} endDate={endDate} />
          </Stack>

          <TournamentBadge type={status} />
        </Stack>
      </Stack>

      <TournamentDescriptionContainer>
        <Stack spacing={24 / 8}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={12 / 8}
          >
            <Stack spacing={18 / 8} direction="row" alignItems="center">
              <TournamentCardsPlaceholder quantity={1} rarity={tournamentRarity} />

              <Stack spacing={4 / 8}>
                <TournamentHeading>
                  {joinCardsQuantity}x {t(NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[tournamentRarity])}
                  {isMobile ? null : (
                    <Fragment>&nbsp; {t('Tournaments.tournament').toLowerCase()}</Fragment>
                  )}
                  <ButtonIcon name="question" variant="plain" size={24} />
                </TournamentHeading>

                {isMobile ? (
                  <TournamentHeadingMobileLabel>
                    {t('Tournaments.tournament')}
                  </TournamentHeadingMobileLabel>
                ) : null}
              </Stack>
            </Stack>

            <Stack direction={isMobile ? 'column-reverse' : 'row'} spacing={8 / 8}>
              <Stack>
                <Typography fontSize="13px" color="secondary">
                  {t('Tournaments.players')}
                </Typography>
                <TournamentInfoPlayersValue $limitReached={players.length === playersLimit}>
                  {players.length}/{playersLimit}
                </TournamentInfoPlayersValue>
              </Stack>

              {joinButton}
            </Stack>
          </Stack>

          <Stack spacing={14 / 8}>
            <TournamentPrizeContainer>{renderPrizes()}</TournamentPrizeContainer>

            <TournamentAllPrizePlacesControl tournament={tournament} />
          </Stack>
        </Stack>
      </TournamentDescriptionContainer>

      {players.length ? <TournamentPlayers /> : <TournamentPlayersPlaceholder />}
    </Fragment>
  );
};

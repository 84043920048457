import { FC, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { Button, EmailField, Stack, Typography } from '~shared/ui';
import { useSwitcher } from '~shared/lib/hooks';

import { useAuthModel } from '../../../model';
import { AuthDialogTitle } from '../../AuthDialogTitle';

interface Form {
  email: string;
}

export const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const submitting = useSwitcher(false);

  const { recoveryPassword, setStep, email } = useAuthModel();
  const form = useForm<Form>({ defaultValues: { email } });

  const handleSubmit: SubmitHandler<Form> = useCallback(
    ({ email }) => {
      submitting.switchOn();

      recoveryPassword({ email }).then(submitting.switchOff);
    },
    [recoveryPassword, submitting]
  );

  const handleRememberPassword = useCallback(() => {
    setStep((steps) => steps.Authentication);
  }, [setStep]);

  return (
    <Fragment>
      <AuthDialogTitle>{t('WelcomePopUp.forgot')}</AuthDialogTitle>

      <Stack spacing={24 / 8}>
        <Typography color="secondary" align="center">
          {t('WelcomePopUp.recoveryText')}
        </Typography>

        <FormProvider {...form}>
          <Stack component="form" spacing={24 / 8} onSubmit={form.handleSubmit(handleSubmit)}>
            <EmailField autoFocus />

            <Button type="submit" disabled={submitting.value}>
              {t('WelcomePopUp.send')}
            </Button>
            <Button variant="text" onClick={handleRememberPassword}>
              {t('WelcomePopUp.return')}
            </Button>
          </Stack>
        </FormProvider>
      </Stack>
    </Fragment>
  );
};

export const getProgressBetweenDates = (startDate: Date, endDate: Date) => {
  const currentDate = new Date();

  if (currentDate.getTime() <= startDate.getTime()) {
    return 0;
  } else if (currentDate.getTime() >= endDate.getTime()) {
    return 1;
  }

  const overallDistance = endDate.getTime() - startDate.getTime();
  const currentDistance = currentDate.getTime() - startDate.getTime();

  return currentDistance / overallDistance;
};

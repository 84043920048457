import { Fragment } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useTonWallet } from '@tonconnect/ui-react';

import { ConnectWalletOptions, ConnectYourWallet } from '~entities/wallet';
import { useViewerSelector } from '~entities/viewer';

import { ViewerWallet } from '~widgets/viewer-wallet';

import { WalletPageRoot } from './styled';

export const WalletPage = () => {
  const { account } = useWeb3React();
  const { authorized } = useViewerSelector();
  const wallet = useTonWallet();

  const isWalletBoundToEmail = Boolean(wallet);

  return (
    <WalletPageRoot>
      {!wallet && (
        <Fragment>
          {authorized && !isWalletBoundToEmail ? <ConnectYourWallet /> : <ConnectWalletOptions />}
        </Fragment>
      )}

      {wallet && <ViewerWallet />}
    </WalletPageRoot>
  );
};

import { styled } from '~shared/lib/styles';

export const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 20,
});

export const ButtonClipBoardDone = styled('div')({
  border: '1px solid #FFFFFF33',
  width: 'fit-content',
  padding: 15,
  borderRadius: 8,
  cursor: 'pointer',
});

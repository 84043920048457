import { styled } from '~shared/lib/styles';
import { Stack } from '~shared/ui';

export const MarketplaceFixedLayout = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  left: 0,
  bottom: '65px',
  padding: '14px 16px',
  background: theme.palette.paper.secondary,
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '6px',
  alignItems: 'center',
}));

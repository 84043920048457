import { format } from 'date-fns';

import { Nft, NftRarity, NftRelatedBattle, NftRelatedCall, NftRelatedEvent } from '~shared/api';

import { NFT_RARITY_TO_MAX_LIVES_MAP, NFT_RARITY_TO_MAX_WIN_STREAK, NftStatusText } from '../lib';

export const getNftUri = (tokenId: string | number, extension = 'jpg'): string =>
  `${process.env.REACT_APP_IMAGES_ADDRESS_TON}/cards/${tokenId}.${extension}`;

export const isNftShouldBeRecovered = (nft: Nft) => {
  const maxNftLives = NFT_RARITY_TO_MAX_LIVES_MAP[nft.rarity];
  const isFullAmountOfLives = nft.livesRemaining === maxNftLives;

  return !isFullAmountOfLives && !nft.isBlockedForTransaction;
};

export const isNftAvailableForMerge = (nft: Nft) => {
  const neededWinStreak = NFT_RARITY_TO_MAX_WIN_STREAK[nft.rarity];

  if (nft.isOnTournament || nft.isBlockedForTournament) {
    return {
      isAvailable: false,
      remainingWins: 0,
    };
  }

  if (!neededWinStreak) {
    return {
      isAvailable: false,
      remainingWins: 0,
    };
  }

  const isAvailable = nft.winStreak >= neededWinStreak;
  const remainingWins = isAvailable ? 0 : neededWinStreak - nft.winStreak;

  return {
    isAvailable,
    remainingWins,
  };
};

export const getNftStatus = (nft: Nft) => {
  return {
    isOnEvent: nft.isOnEvent,
    isBlockedForEvent: nft.isBlockedForEvent,
    isLive: nft.isLive,
    isFreezed: nft.isFreezed,
    isShouldBeRecovered: isNftShouldBeRecovered(nft),
    isAvailableForMerge: isNftAvailableForMerge(nft).isAvailable,
    isOnAuction: nft.isOnAuction,
    isBlockedForTournament: nft.isBlockedForTournament,
    isOnTournament: nft.isOnTournament,
  };
};

export const getNftStatusText = (nft: Nft) => {
  const status = getNftStatus(nft);

  if (status.isBlockedForEvent) {
    return NftStatusText.BlockedForEvent;
  } else if (status.isOnEvent) {
    return NftStatusText.OnEvent;
  } else if (status.isFreezed) {
    return NftStatusText.Freezed;
  } else if (status.isShouldBeRecovered) {
    return NftStatusText.ShouldBeRecovered;
  } else if (status.isAvailableForMerge) {
    return NftStatusText.AvailableForMerge;
  }

  return NftStatusText.Normal;
};

export const isNftAvailableForSell = (nft: Nft) =>
  !nft.isBlockedForTournament &&
  !nft.isOnTournament &&
  !nft.isBlockedForEvent &&
  !nft.isFreezed &&
  !nft.isOnEvent &&
  !nft.isBlockedForTransaction &&
  !nft.isOnAuction &&
  nft.rarity !== NftRarity.Demo;

export const getNftByTokenId = (nfts: Array<Nft>, tokenId: string): Nft | undefined => {
  return nfts.find((nft) => Number(nft.token_id) === Number(tokenId));
};

export const formatRelatedEventDate = (date: Date): string => format(date, 'd MMM HH:mm');

export const isNftRelatedBet = (
  relatedEvent: NftRelatedEvent
): relatedEvent is NftRelatedBattle => {
  return !relatedEvent.isCall;
};

export const isNftRelatedCall = (relatedEvent: NftRelatedEvent): relatedEvent is NftRelatedCall => {
  return relatedEvent.isCall;
};

import { styled } from '~shared/lib/styles';

export const EventDialogStreamSportRadarRoot = styled('div')(({ theme }) => ({
  position: 'relative',
  minHeight: 300,

  [theme.breakpoints.down('mobile')]: {
    marginTop: 20,
  },
}));

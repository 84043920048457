import { Tabs as MuiBaseTabs } from '@mui/base';

import { noScrollbar, styled } from '~shared/lib/styles';

import { Box } from '../../../layout';

export const UIKitTabsContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'auto',
  ...noScrollbar,

  [`&.${tabsClasses.underlined}`]: {
    borderBottom: `1px solid ${theme.palette.grey.A10}`,
  },
}));

export const UIKitTabs = styled(MuiBaseTabs)({
  display: 'flex',
});

export const UIKitTabsEndAdornment = styled(MuiBaseTabs)({
  marginLeft: 'auto',
  alignSelf: 'center',
  paddingRight: 4,
});

export const UIKitTabsIndicator = styled(Box)(({ theme }) => ({
  height: 2,
  background: theme.palette.primary.main,
  opacity: 0.7,
  bottom: -1,
  position: 'absolute',
  transition: theme.transitions.create('all', { duration: 250 }),
}));

export const tabsClasses = {
  underlined: 'Tabs-underlined',
};

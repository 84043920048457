import { styled } from '~shared/lib/styles';
import { IconAssetNamesType } from '~shared/ui';

interface NotificationIconFactoryStateIconPlateProps {
  name: IconAssetNamesType;
}

export const NotificationIconFactoryStateIconPlate = styled(
  'div'
)<NotificationIconFactoryStateIconPlateProps>(({ name, theme }) => {
  const mapIconNameToBackground: Record<
    Extract<IconAssetNamesType, 'fix' | 'snowflake' | 'auction'>,
    string
  > = {
    fix: theme.palette.warn.secondary,
    snowflake: theme.palette.primary.main,
    auction: theme.palette.violet.main,
  };

  return {
    position: 'absolute',
    top: 3,
    right: 3,
    zIndex: 1,
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 1,

    background: mapIconNameToBackground[name!],
  };
});

interface NotificationIconFactoryCardWrapperProps {
  stack?: boolean;
}

export const NotificationIconFactoryCardWrapper = styled(
  'div'
)<NotificationIconFactoryCardWrapperProps>(() => {
  return {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
});

export const NotificationIconFactoryEventText = styled('div')(() => ({
  fontSize: 8,
  fontWeight: 600,
  lineHeight: 13 / 8,
}));

import { RefObject, WheelEvent, WheelEventHandler, useCallback } from 'react';

export const useHorizontalWheelScroll = (ref: RefObject<HTMLElement>) => {
  const handleWheel = useCallback<WheelEventHandler>(
    (event) => {
      if (ref.current) {
        if (isWheelEventFromTrackpad(event)) {
          return;
        }

        if (event.deltaY === 0) {
          return;
        }

        ref.current.scrollTo({
          left: ref.current!.scrollLeft + inertia(event.deltaY),

          behavior: 'smooth',
        });
      }
    },
    [ref]
  );

  return { handleWheel };
};

function inertia(y: number): number {
  return Math.pow(Math.abs(y), 1.7) * Math.sign(y);
}

/** @link https://stackoverflow.com/a/62415754 */
const isWheelEventFromTrackpad = ({ nativeEvent: event }: WheelEvent): boolean => {
  let isTrackpad = false;

  const possibleWheelDeltaY: number = (event as any)?.wheelDeltaY;

  if (possibleWheelDeltaY) {
    if (Math.abs(possibleWheelDeltaY) !== 120) {
      isTrackpad = true;
    }
  } else if (
    // firefox fallback
    event.deltaMode === 0
  ) {
    isTrackpad = true;
  }

  return isTrackpad;
};

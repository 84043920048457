import { useMeasure } from 'react-use';
import { FC } from 'react';

import { StyledIframe } from './styled';

import { EventDialogStreamTwitchProps } from './types';

export const EventDialogStreamTwitch: FC<EventDialogStreamTwitchProps> = ({ broadcastId }) => {
  const [twitchIframeMeasurement, size] = useMeasure<HTMLIFrameElement>();

  return (
    <StyledIframe
      ref={twitchIframeMeasurement}
      src={`https://player.twitch.tv/?channel=${broadcastId}&parent=${window.location.host}`}
      allowFullScreen
      height={(size.width / 16) * 9}
    />
  );
};

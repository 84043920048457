import { useQuery } from 'react-query';

import {
  ApiGetViewerDashboardRequestData,
  ApiGetViewerDashboardResponseMappedData,
  getViewerDashboard,
} from '~shared/api';

import { useViewerModel } from '../model';

export const useQueryViewerDashboard = ({
  address,
  tonAddress,
  params,
}: ApiGetViewerDashboardRequestData) => {
  const { authorized } = useViewerModel();
  const { data, isLoading, isFetched } = useQuery<ApiGetViewerDashboardResponseMappedData>(
    ['getViewerDashboard', address, tonAddress, params],
    () => getViewerDashboard({ address, tonAddress, params })
  );

  return {
    data,
    isLoading,
    authorized,
    isFetched,
  };
};

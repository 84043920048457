import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { useTonConnectModal } from '@tonconnect/ui-react';

import {
  getDefaultAvatars,
  getViewerProfile,
  postSignIn,
  postUpdatePassword,
  postViewerAvatar,
  putNickname,
  putSwitchLanguage,
  putViewerAvatar,
} from '~shared/api';
import { useDispatch, useSnackbar } from '~shared/lib/hooks';
import { Language } from '~shared/config/i18n';

import { transformAvatarToViewerAvatar } from '~entities/viewer';

import { sliceHash } from '~shared/lib/utils';

import { useViewerSelector } from './selectors';
import { viewerActions } from './slice';

export const useViewerModel = () => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const { open: openTonConnectModal } = useTonConnectModal();

  const { ...state } = useViewerSelector();

  const setNicknameDialogOpen = state.isNicknameSet === false;

  const shortWalletAddress = state.tonAddress ? sliceHash(state.tonAddress, 5, 5) : '';

  const handleLanguageChangeFromServer = useCallback(
    (valueFromServer: Language) => {
      if (i18n.language !== valueFromServer) {
        i18n.changeLanguage(valueFromServer);
      }
    },
    [i18n]
  );

  const checkSessionToken = useCallback(() => {
    return postSignIn()
      .then(({ avatar, customAvatar, verified, language, ...data }) => {
        handleLanguageChangeFromServer(language);

        dispatch(
          viewerActions.updateData({
            avatar: avatar ? transformAvatarToViewerAvatar(avatar) : null,
            customAvatar: customAvatar ? transformAvatarToViewerAvatar(customAvatar) : null,
            sessionTokenChecked: true,
            authorized: true,
            verified,
            ...data,
          })
        );

        return { authorized: true, verified };
      })
      .catch(() => {
        dispatch(viewerActions.updateData({ authorized: false, sessionTokenChecked: true }));

        return { authorized: false, verified: null };
      });
  }, [dispatch, handleLanguageChangeFromServer]);

  const authorize = useCallback(
    (data: { login: string; password: string }) => {
      return postSignIn(data).then(({ verified, avatar, customAvatar, language, ...data }) => {
        handleLanguageChangeFromServer(language);

        dispatch(
          viewerActions.updateData({
            avatar: avatar ? transformAvatarToViewerAvatar(avatar) : null,
            customAvatar: customAvatar ? transformAvatarToViewerAvatar(customAvatar) : null,
            authorized: true,
            verified,
            ...data,
          })
        );

        return { verified };
      });
    },
    [dispatch, handleLanguageChangeFromServer]
  );

  const verification = useCallback(() => {
    dispatch(
      viewerActions.updateData({
        verified: true,
        authorized: true,
        sessionTokenChecked: true,
        registered: true,
      })
    );
  }, [dispatch]);

  const updatePassword = useCallback((data: Parameters<typeof postUpdatePassword>[0]) => {
    return postUpdatePassword(data);
  }, []);

  const fetchProfile = useCallback(() => {
    return getViewerProfile().then(({ avatar, customAvatar, language, ...data }) => {
      handleLanguageChangeFromServer(language);

      dispatch(
        viewerActions.updateData({
          avatar: avatar ? transformAvatarToViewerAvatar(avatar) : null,
          customAvatar: customAvatar ? transformAvatarToViewerAvatar(customAvatar) : null,
          ...data,
        })
      );
    });
  }, [dispatch, handleLanguageChangeFromServer]);

  const fetchDefaultAvatars = useCallback(() => {
    return getDefaultAvatars().then((data) => {
      dispatch(
        viewerActions.updateData({
          defaultAvatars: data.map((avatar) => transformAvatarToViewerAvatar(avatar)),
        })
      );
    });
  }, [dispatch]);

  const updateAvatar = useCallback((data: Parameters<typeof putViewerAvatar>[0]) => {
    return putViewerAvatar(data);
  }, []);

  const updateNickname = useCallback(
    (nickname: string) => {
      return putNickname({ nickname })
        .then(fetchProfile)
        .then(() => {
          openSnackbar({ message: t('Alerts.nicknameSuccessfullySet') });
        });
    },
    [fetchProfile, openSnackbar, t]
  );

  const postCustomAvatar = useCallback(
    (avatar: Blob, onUploadProgress?: (progress: number) => void) => {
      const formData = new FormData();

      formData.append('avatar', new File([avatar], 'avatar.jpeg', { type: 'image/jpeg' }));

      return postViewerAvatar(formData, (event) => {
        if (onUploadProgress) {
          onUploadProgress((event.progress ?? 0) * 100);
        }
      });
    },
    []
  );

  const switchLanguage = useCallback(
    (language: Language) => {
      i18n.changeLanguage(language);

      if (state.authorized) {
        return putSwitchLanguage({ language });
      }
    },
    [i18n, state.authorized]
  );

  const openSignOut = useCallback(() => {
    dispatch(viewerActions.openSignOutDialog());
  }, [dispatch]);

  return {
    authorize,
    checkSessionToken,
    verification,

    fetchProfile,
    fetchDefaultAvatars,
    updatePassword,
    updateAvatar,
    updateNickname,
    postCustomAvatar,
    switchLanguage,

    openSignOut,

    setNicknameDialogOpen,

    shortWalletAddress,

    ...state,
  };
};

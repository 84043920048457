import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { useDispatch } from '~shared/lib/hooks';
import { BetSlot, Nft } from '~shared/api';
import { sleep } from '~shared/lib/utils';
import { routes } from '~shared/config';

import {
  getSortedNfts,
  isNftAvailableForMerge,
  useNftCardModel,
  useNftPreviewInfo,
} from '~entities/nft';

import { EventDialogTab, useEventModel, useEventValidateCard } from '~entities/event';

import { useMergeModel } from '~features/nft';
import { useJoinTournamentModel } from '~features/tournament';

import {
  ViewerWalletNftRarityFilter,
  ViewerWalletTab,
  useIsAvailableToSelect,
  viewerWalletSlice,
} from '../model';

import { useViewerWalletSelector } from './selectors';
import { useViewerWalletSelectCard } from './hooks';

export const useViewerWalletModel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    /***/
    selectedCards,
    rarityFilters,
    walletTab,
    isTournamentWalletTabAlreadyToggled,
  } = useViewerWalletSelector();

  const { addNftToJoinTournament, openJoinTournamentDialog, tournament } = useJoinTournamentModel();
  const { open: isNftPreviewOpen, openPreview, closePreview } = useNftPreviewInfo();
  const { openMergeDialog, handleAddNftToMerge } = useMergeModel();

  const { isAvailableToSelect } = useIsAvailableToSelect();
  const { selectCard } = useViewerWalletSelectCard();
  const {
    isSelectionMode,
    selectionModeType,
    nfts,
    nftsToMerge,
    setSelectionMode,
    onCancelAddCardsToJoinTournament,
  } = useNftCardModel();

  const { validateWinstreak, validateMaxCardsLimit, validateZeroLivesRemaining } =
    useEventValidateCard();

  const { cards, openActiveEvent, betType, isViewMode, choice, call, tab } = useEventModel();

  const isCallCardSelected = false; //selectedCards.size > 0 && tab === EventDialogTab.Call;

  const toggleRarityFilter = useCallback(
    (rarity: ViewerWalletNftRarityFilter) => {
      dispatch(viewerWalletSlice.actions.toggleRarityFilter(rarity));
      closePreview();
    },
    [dispatch, closePreview]
  );

  const onNftClick = (nft: Nft, isMobile?: boolean) => async () => {
    if (isSelectionMode) {
      if (selectionModeType === 'bet') {
        if (isCallCardSelected && !selectedCards.has(nft.token_id)) {
          return;
        }

        const totalCardsAmount = selectedCards.size + cards.length + 1;

        if (!selectedCards.has(nft.token_id) && !validateMaxCardsLimit(totalCardsAmount)) {
          return;
        }

        const isWinstreakValidated = !selectedCards.has(nft.token_id)
          ? await validateWinstreak({
              card: nft,
              slot: choice as BetSlot,
              onWinstreakWarningConfirm: () => selectCard(nft),
            })
          : true;

        const isZeroLivesRemainingValidated = validateZeroLivesRemaining(nft);

        const isCallAcceptableRarity =
          // tab === EventDialogTab.Call && !!call.callAcceptableRarity
          //   ? validateCallAcceptableRarity({
          //       rarity: nft.rarity,
          //       acceptableRarity: call.callAcceptableRarity,
          //     })
          //  :
          true;

        const isValidated = [
          isWinstreakValidated,
          isZeroLivesRemainingValidated,
          isCallAcceptableRarity,
        ].every((validated) => validated);

        if (!isValidated) {
          return;
        }
      } else if (!isAvailableToSelect(nft)) {
        return;
      }

      return selectCard(nft);
    }

    if (isNftPreviewOpen) {
      closePreview();

      return;
    }

    if (isMobile) {
      // todo: make an anchor tag
      navigate(routes.walletCard.replace(':tokenId', nft.token_id));
    } else {
      openPreview(nft);
    }
  };

  const clearSelectedCards = useCallback(() => {
    dispatch(viewerWalletSlice.actions.clearSelectedCards());
  }, [dispatch]);

  const confirmSelectedBetCards = useCallback(() => {
    const totalAmountOfCards = selectedCards.size + cards.length;

    if (betType === 'battle' && !validateMaxCardsLimit(totalAmountOfCards)) {
      return;
    }

    navigate(routes.index);

    const resultChoice = selectedCards.size === 0 ? null : choice;

    const payload = isViewMode
      ? {
          battle: {
            additionalCards: Array.from(selectedCards.values()),
            choice: resultChoice,
          },
        }
      : {
          [betType]: {
            cards: [...cards, ...Array.from(selectedCards.values())],
            choice: resultChoice,
          },
        };

    // @ts-ignore
    openActiveEvent(payload);
    clearSelectedCards();
    setSelectionMode(false);
  }, [
    betType,
    cards,
    choice,
    clearSelectedCards,
    isViewMode,
    navigate,
    openActiveEvent,
    selectedCards,
    setSelectionMode,
    validateMaxCardsLimit,
  ]);

  const confirmSelectedCards = useCallback(async () => {
    switch (selectionModeType) {
      case 'bet':
        confirmSelectedBetCards();
        break;

      case 'merge':
        selectedCards.forEach((selectedCard) => {
          handleAddNftToMerge([selectedCard.token_id]);
        });

        openMergeDialog();
        clearSelectedCards();
        setSelectionMode(false);

        break;

      case 'tournament':
        addNftToJoinTournament(Array.from(selectedCards.values()));
        openJoinTournamentDialog();
        clearSelectedCards();
        setSelectionMode(false);
        sleep(200).then(() => navigate(-1));

        break;
    }
  }, [
    addNftToJoinTournament,
    clearSelectedCards,
    handleAddNftToMerge,
    navigate,
    openJoinTournamentDialog,
    openMergeDialog,
    confirmSelectedBetCards,
    selectedCards,
    selectionModeType,
    setSelectionMode,
  ]);

  const cancelSelectCardToMerge = useCallback(() => {
    clearSelectedCards();
    openMergeDialog();
  }, [clearSelectedCards, openMergeDialog]);

  const cancelSelectCardToJoinTournament = useCallback(() => {
    clearSelectedCards();
    openJoinTournamentDialog();
    onCancelAddCardsToJoinTournament();
    sleep(200).then(() => navigate(-1));
  }, [clearSelectedCards, navigate, onCancelAddCardsToJoinTournament, openJoinTournamentDialog]);

  const offSelectionMode = useCallback(() => {
    setSelectionMode(false);
  }, [setSelectionMode]);

  const isCardSelected = useCallback(
    (card: Nft) => {
      return selectedCards.has(card.token_id);
    },
    [selectedCards]
  );

  const filteredNfts = useMemo(() => {
    let filteredNfts = nfts;

    if (isSelectionMode) {
      filteredNfts = filteredNfts.filter((nft) => {
        const isPlacedToEvent = !!cards.find((card) => card.token_id === nft.token_id);

        return !isPlacedToEvent;
      });
    }

    if (selectionModeType === 'merge') {
      filteredNfts = filteredNfts.filter((nft) => isNftAvailableForMerge(nft).isAvailable);
    }

    return getSortedNfts([...filteredNfts]);
  }, [nfts, isSelectionMode, selectionModeType, cards]);

  const isConfirmAvailable = useMemo(() => {
    if (selectionModeType === 'bet') {
      return true;
    }

    if (selectionModeType === 'tournament') {
      if (tournament) {
        return tournament.joinCardsQuantity === selectedCards.size;
      }
    }

    if (selectionModeType === 'merge') {
      const nftsToMergeQuantity = nftsToMerge.filter((nft) => nft !== null).length;

      if (nftsToMergeQuantity === 0) {
        return selectedCards.size === 2;
      }

      if (nftsToMergeQuantity === 1) {
        return selectedCards.size === 1;
      }
    }
  }, [nftsToMerge, selectedCards.size, selectionModeType, tournament]);

  const getNftsByRarity = useCallback(
    (rarity: Set<ViewerWalletNftRarityFilter>) => {
      if (rarity.has(ViewerWalletNftRarityFilter.All)) {
        return filteredNfts;
      }

      return filteredNfts.filter((nft) =>
        rarity.has(nft.rarity as unknown as ViewerWalletNftRarityFilter)
      );
    },
    [filteredNfts]
  );

  const selectWalletTab = useCallback(
    (tab: ViewerWalletTab) => {
      dispatch(viewerWalletSlice.actions.selectWalletTab(tab));
    },
    [dispatch]
  );

  const computedWalletTab = useMemo<ViewerWalletTab>(() => {
    if (isSelectionMode) {
      switch (selectionModeType) {
        case 'tournament':
        case 'merge':
          return ViewerWalletTab.All;
      }
    }

    return walletTab;
  }, [isSelectionMode, selectionModeType, walletTab]);

  const showTabs = useMemo(() => {
    if (isSelectionMode) {
      switch (selectionModeType) {
        case 'merge':
        case 'tournament':
          return false;
      }
    }

    return true;
  }, [isSelectionMode, selectionModeType]);

  const toggleWalletTabToTournaments = useCallback(() => {
    selectWalletTab(ViewerWalletTab.Tournaments);
  }, [selectWalletTab]);

  return {
    isSelectionMode,
    isCallCardSelected,
    selectionModeType,
    selectedCards,
    selectedCardsAmount: selectedCards.size,
    confirmSelectedCards,
    cancelSelectCardToMerge,
    cancelSelectCardToJoinTournament,
    offSelectionMode,
    clearSelectedCards,
    nfts: filteredNfts,
    getNftsByRarity,

    onNftClick,

    rarityFilters,
    toggleRarityFilter,

    walletTab: computedWalletTab,
    selectWalletTab,
    toggleWalletTabToTournaments,
    showTabs,

    isCardSelected,
    isConfirmAvailable,
    isTournamentWalletTabAlreadyToggled,
  };
};

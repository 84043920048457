import { FC } from 'react';

import { useScrollPosition } from '~shared/lib/hooks';
import { ListTopShadow } from '~shared/ui';

import { useQueryViewerNfts } from '~entities/nft';

import { useViewerWalletTournamentsList } from '../../model';

import { ViewerWalletTournamentsPlaceholder } from './ViewerWalletTournamentsPlaceholder';
import { ViewerWalletTournament } from './ViewerWalletTournament';

import { ViewerWalletTournamentsList, ViewerWalletTournamentsRoot } from './styled';

export const ViewerWalletTournaments: FC = () => {
  const { onScroll, atTop } = useScrollPosition();
  const { isLoading } = useQueryViewerNfts();

  const { activeTournamentsList, hasActiveTournaments } = useViewerWalletTournamentsList();

  if (!hasActiveTournaments) {
    return <ViewerWalletTournamentsPlaceholder />;
  }

  return (
    <ViewerWalletTournamentsRoot>
      <ListTopShadow show={!atTop} />

      <ViewerWalletTournamentsList onScroll={onScroll}>
        {activeTournamentsList!.map((tournament) => (
          <ViewerWalletTournament
            key={tournament.tournamentId}
            isLoading={isLoading}
            tournament={tournament}
          />
        ))}
      </ViewerWalletTournamentsList>
    </ViewerWalletTournamentsRoot>
  );
};

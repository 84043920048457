import { LinearProgress, linearProgressClasses } from '~shared/ui';
import { styled } from '~shared/lib/styles';
import { EventStatisticsRowColumnValueDotResult } from '~entities/event';

export const EventDialogStatisticsRowTopContainer = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 100px 1fr',
}));

export const EventDialogStatisticsRowMultipleValueContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

interface EventDialogStatisticsDotsContainerProps {
  $position: 'left' | 'right';
}

export const EventDialogStatisticsDotsContainer = styled(
  'div'
)<EventDialogStatisticsDotsContainerProps>(({ $position }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: $position === 'right' ? 'row-reverse' : 'row',
  alignItems: 'center',

  margin: -4,
  marginRight: $position === 'left' ? 8 : -4,
  marginLeft: $position === 'right' ? 8 : -4,

  ['& > *']: {
    margin: 4,
  },
}));

export const EventDialogStatisticsRowBottomContainer = styled('div')(() => ({
  marginTop: 16,
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: 14,
}));

interface EventDialogStatisticsProgressProps {
  $result?: EventStatisticsRowColumnValueDotResult;
}

export const EventDialogStatisticsProgress = styled(
  LinearProgress
)<EventDialogStatisticsProgressProps>(({ $result, theme }) => {
  const RESULT_TO_COLOR_MAP: Record<EventStatisticsRowColumnValueDotResult, string> = {
    bad: theme.palette.error.main,
    good: theme.palette.primary.main,
  };

  return {
    borderRadius: 0,
    height: 6,

    [`& .${linearProgressClasses.progress}`]: {
      background: RESULT_TO_COLOR_MAP[$result],
      borderRadius: 0,
    },
  };
});

interface EventDialogStatisticsPointCircleProps {
  $result: EventStatisticsRowColumnValueDotResult;
}

export const EventDialogStatisticsPointCircle = styled(
  'div'
)<EventDialogStatisticsPointCircleProps>(({ $result, theme }) => {
  const RESULT_TO_COLOR_MAP: Record<EventStatisticsRowColumnValueDotResult, string> = {
    bad: theme.palette.error.main,
    good: theme.palette.success.main,
  };

  return {
    width: 14,
    height: 14,
    minWidth: 14,
    minHeight: 14,
    background: RESULT_TO_COLOR_MAP[$result],
    borderRadius: '100%',
  };
});

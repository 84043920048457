import { Address } from '@ton/core';

import { apiInstance, tonApiInstance } from '../apiInstance';

import { getViewerProfile } from '../viewer';
import { mapGetBattlesByIdsResponse } from '../battle';

import { mapGetBets } from './mappers';
import { BetsRoutes } from './routes';
import {
  ApiCloseBet,
  ApiCloseBetResponseData,
  ApiGetActiveBets,
  ApiGetBets,
  ApiMakeBet,
  ApiMakeBetResponseData,
} from './types';

export const getBets: ApiGetBets = (params) => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .get(BetsRoutes.tonBets(), {
        params: {
          ...params,
          address: Address.normalize(params.address!),
        },
      })
      .then(async ({ data }) => {
        const user = await getViewerProfile();

        resolve({
          user: user,
          bets: mapGetBets(data),
        });
      })
      // .catch(reject);
      .catch((axiosError) => {
        if (axiosError.response?.status === 400) {
          // @ts-ignore
          resolve(() => ({ ...data, bets: mapGetBets(axiosError.response.data.bets) }));
        } else {
          reject(axiosError);
        }
      });
  });
};

export const getActiveBets: ApiGetActiveBets = (data) => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .get(BetsRoutes.getActiveBets(data.address))
      .then(({ data }) =>
        resolve({
          ...data,
          activeBetEvents: mapGetBattlesByIdsResponse(data.activeBetEvents),
        })
      )
      .catch(reject);
  });
};

export const postMakeBet: ApiMakeBet = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post<ApiMakeBetResponseData>(BetsRoutes.makeBet(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postMakeBetTon: ApiMakeBet = (data) => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .post<ApiMakeBetResponseData>(BetsRoutes.makeBetTon(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postCloseBetTon: ApiCloseBet = (data) => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .post<ApiCloseBetResponseData>(BetsRoutes.closeBetTon(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

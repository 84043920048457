import { FC } from 'react';
import ContentLoader from 'react-content-loader';

import { useTheme } from '~shared/lib/styles';

import { ShimmerProps } from './types';

export const Shimmer: FC<ShimmerProps> = ({ ...props }) => {
  const { palette } = useTheme();

  return (
    <ContentLoader
      speed={2}
      display="block"
      backgroundColor={palette.grey.A5}
      foregroundColor={palette.grey.A10}
      {...props}
    />
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '~shared/lib/hooks';
import { CircularDiagram, Heading, Shimmer, Stack } from '~shared/ui';
import { getDashboardAccuracy } from '~widgets/profile-dashboard/lib';

import { DashboardStatsTable } from './DashboardStatsTable';
import { Root, StyledSelectField, StyledSelectOption } from './styled';
import { DashboardStatsProps } from './types';

export const DashboardStats: FC<DashboardStatsProps> = ({
  heading,
  stats,
  period,
  isLoading,
  onPeriodChange,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('sm');

  return (
    <Root>
      <Stack display="grid" gridTemplateColumns="auto 90px" justifyContent="space-between">
        <Heading>{heading}</Heading>
        <StyledSelectField defaultValue="all" value={period} onChange={onPeriodChange}>
          <StyledSelectOption value="all">{t('Tabs.all')}</StyledSelectOption>
          <StyledSelectOption value="week" textTransform="capitalize">
            {t('Other.week')}
          </StyledSelectOption>
          <StyledSelectOption value="month" textTransform="capitalize">
            {t('Other.month')}
          </StyledSelectOption>
        </StyledSelectField>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={isMobile ? 'start' : 'center'}
        mt="10px"
      >
        <DashboardStatsTable stats={stats} isLoading={isLoading} />

        {isLoading ? (
          <Shimmer width={76} height={76}>
            <rect x="0" y="0" rx="12" ry="12" width={76} height={76} />
          </Shimmer>
        ) : (
          <CircularDiagram
            label={t('Rankings.accuracy')}
            value={getDashboardAccuracy(stats!.wins, stats!.total)}
          />
        )}
      </Stack>
    </Root>
  );
};

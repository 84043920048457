import { styled } from '~shared/lib/styles';
import { Box, DragCell } from '~shared/ui';

export const Root = styled(Box, { name: 'EventMultipleCards' })(({ theme }) => ({
  padding: '32px 20px',
  borderRadius: 8,

  border: `1px dashed ${theme.palette.primary.main}`,

  [theme.breakpoints.down('mobile')]: {
    padding: '24px 16px',

    maxHeight: '322px',
    overflowY: 'auto',
  },
}));

export const StyledDragCell = styled(DragCell, {
  name: 'EventMultipleCards',
  slot: 'dragCell',
})(({ theme }) => ({
  boxShadow: 'none !important',

  [theme.breakpoints.down('mobile')]: {
    width: 52,
    height: 76,
  },
}));

import { Stack, Typography } from '~shared/ui';

import { ConnectionType, useConnectWalletDialogModel } from '../../../model';

import { WalletCard } from './WalletCard';
import { StyledDrawer } from './styled';

export const ConnectWalletDialogMobile = () => {
  const { open, handleClose, handleConnect } = useConnectWalletDialogModel();

  return (
    <StyledDrawer open={open} onClose={handleClose}>
      <Stack spacing={22 / 8}>
        <Typography variant="h2">Connect wallet</Typography>
        <Typography fontSize="13px">Try to connect your wallets</Typography>
        <WalletCard
          name="WalletConnect"
          icon="wallet-walletconnect"
          onClick={handleConnect(ConnectionType.WalletConnectV2)}
        />
        <Typography fontSize="13px">Play in wallet browsers</Typography>
        <WalletCard
          name="MetaMask Wallet"
          icon="wallet-metamask"
          onClick={handleConnect(ConnectionType.Injected)}
        />
        <WalletCard
          name="Coinbase Wallet"
          icon="wallet-coinbase"
          onClick={handleConnect(ConnectionType.Coinbase)}
        />
        <Typography>
          For better playing experience open Maincard.io on desktop or your wallet's browser
        </Typography>
      </Stack>
    </StyledDrawer>
  );
};

import { apiInstance } from '../apiInstance';

import {
  ApiGetPersonalTournaments,
  ApiGetPersonalTournamentsRawResponseData,
  ApiGetTournament,
  ApiGetTournamentRawResponseData,
  ApiGetTournaments,
  ApiGetTournamentsRawResponseData,
  ApiPostJoinTournament,
  ApiPostJoinTournamentRawResponseData,
} from './types';

import { mapJoinTournamentData, mapTournamentData, mapTournamentsListData } from './mappers';
import { TournamentRoutes } from './routes';

export const getTournamentsList: ApiGetTournaments = () => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get<Array<ApiGetTournamentsRawResponseData>>(TournamentRoutes.list())
      .then(({ data }) => resolve(mapTournamentsListData(data)))
      .catch(reject);
  });
};

export const getTournament: ApiGetTournament = (params) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get<ApiGetTournamentRawResponseData>(TournamentRoutes.tournament(), { params })
      .then(({ data }) => resolve(mapTournamentData(data)))
      .catch(reject);
  });
};

export const postJoinTournament: ApiPostJoinTournament = (params) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post<ApiPostJoinTournamentRawResponseData>(TournamentRoutes.join(), params)
      .then(({ data }) => resolve(mapJoinTournamentData(data)))
      .catch(reject);
  });
};

export const getPersonalTournaments: ApiGetPersonalTournaments = (params) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get<ApiGetPersonalTournamentsRawResponseData>(TournamentRoutes.personalList(), { params })
      .then(({ data }) => resolve(mapTournamentsListData(data)))
      .catch(reject);
  });
};

import { useTonAddress } from '@tonconnect/ui-react';
import { FC, Fragment, useEffect, useState } from 'react';

import {
  MarketplaceItem,
  MarketplaceItemType,
  useMarketplaceItemsQuery,
} from '~entities/marketplace';

import { BestAuctionList } from '~entities/auction/best-from-auction';

import { useSearchParamsState } from '~shared/lib/hooks';

import {
  MarketplaceItemMysteryBox,
  MarketplaceItemNft,
  MarketplaceItemShimmer,
  MarketplaceItemUnfreeze,
} from './items';

import { MarketplaceListGrid } from './styled';
import {
  MARKETPLACE_ITEM_ID_SEARCH_PARAM,
  MarketplaceItemDetailsDialog,
} from './items/MarketplaceItemDetailsDialog';
import { MarketplaceListSettings } from './MarketplaceListSettings';

export const MarketplaceList: FC = () => {
  const { data: marketplaceItems = [], isLoading } = useMarketplaceItemsQuery();

  const address = useTonAddress(true);

  const [itemDetails, setItemDetails] = useState<MarketplaceItem | null>(null);
  const [searchParam, setSearchParam, omitSearchParam] = useSearchParamsState<string>(
    MARKETPLACE_ITEM_ID_SEARCH_PARAM,
    ''
  );

  useEffect(() => {
    if (!isLoading) {
      const foundItem = marketplaceItems.find((item) => item.name === searchParam);

      if (foundItem) {
        setItemDetails(foundItem);
      } else {
        omitSearchParam();
      }
    }
  }, [isLoading, itemDetails, marketplaceItems, omitSearchParam, searchParam]);

  const renderMarketplaceItem = (item: MarketplaceItem) => {
    const handleOpenDetails = () => setSearchParam(item.name);

    switch (item.type) {
      case MarketplaceItemType.Nft:
        return (
          <MarketplaceItemNft
            key={`${item.type}__${item.rarity}`}
            marketplaceNft={item}
            onOpenDetails={handleOpenDetails}
          />
        );

      case MarketplaceItemType.MysteryBox:
        return (
          <MarketplaceItemMysteryBox
            key={`${item.type}__${item.rarity}`}
            marketplaceMysteryBox={item}
            onOpenDetails={handleOpenDetails}
          />
        );

      case MarketplaceItemType.Unfreeze:
        return (
          <MarketplaceItemUnfreeze
            key={item.type}
            marketplaceUnfreeze={item}
            onOpenDetails={handleOpenDetails}
          />
        );
    }
  };

  return (
    <Fragment>
      <MarketplaceListSettings />

      {address && <BestAuctionList />}

      <MarketplaceListGrid>
        {isLoading ? itemsShimmer : marketplaceItems.map(renderMarketplaceItem)}
      </MarketplaceListGrid>

      {itemDetails && (
        <MarketplaceItemDetailsDialog
          open={Boolean(searchParam)}
          onClose={omitSearchParam}
          marketplaceItem={itemDetails}
        />
      )}
    </Fragment>
  );
};

const itemsShimmer = Array.from(Array(12).keys()).map((key) => (
  <MarketplaceItemShimmer key={key} />
));

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { isProduction } from '~shared/config';
import { ToggleButton } from '~shared/ui';

import { ViewerWalletTab, useViewerWalletModel } from '../../model';

import { ViewerWalletTabsNewBadge, ViewerWalletTabsRoot } from './styled';
import { ViewerWalletTabsProps } from './types';

export const ViewerWalletTabs: FC<ViewerWalletTabsProps> = ({ marginBottom = false }) => {
  const { t } = useTranslation();

  const { walletTab, selectWalletTab } = useViewerWalletModel();

  return (
    <ViewerWalletTabsRoot $marginBottom={marginBottom}>
      {walletTabs.map(({ value, label, isNew, disabled }) => {
        const handleChange = (event: any, value: ViewerWalletTab) => {
          selectWalletTab(value);
        };

        return (
          <ToggleButton
            key={value}
            value={value}
            selected={walletTab === value}
            onChange={handleChange}
            disabled={disabled}
            variant="pill"
          >
            {t(label)}
            {isNew && <ViewerWalletTabsNewBadge>New</ViewerWalletTabsNewBadge>}
          </ToggleButton>
        );
      })}
    </ViewerWalletTabsRoot>
  );
};

const walletTabs = [
  { value: ViewerWalletTab.All, label: 'Tournaments.tabAll' },
  {
    value: ViewerWalletTab.Tournaments,
    label: 'HeaderTabs.tournaments',
    isNew: true,
    disabled: false,
  },
];

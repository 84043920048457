import { styled } from '~shared/lib/styles';

export const tournamentPlayerClasses = {
  viewer: 'TournamentPlayer-viewer',
  sticky: 'TournamentPlayer-sticky',
  notStarted: 'TournamentPlayer-notStarted',

  cell: 'TournamentPlayer-cell',
};

export const TournamentPlayerRoot = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto repeat(3, 80px)',
  alignItems: 'center',
  justifyItems: 'start',

  paddingLeft: 14,
  height: 60,
  borderRadius: 8,

  transition: theme.transitions.create('all'),

  [theme.breakpoints.down('mobile')]: {
    gridTemplateColumns: 'auto repeat(2, 60px) 50px',
    paddingLeft: 14,
    height: 50,
  },

  [`&.${tournamentPlayerClasses.viewer}`]: {
    background: theme.palette.grey.A10,
    inset: '0',
    zIndex: 1,
  },

  [`&.${tournamentPlayerClasses.sticky}`]: {
    position: 'sticky',
    boxShadow: '0px 0px 6px #2EB2FF',
    background: theme.palette.paper.semi,
  },

  [`&.${tournamentPlayerClasses.notStarted}`]: {
    [`& .${tournamentPlayerClasses.cell}`]: {
      opacity: 0.5,
    },
  },
}));

export const TournamentPlayerRowCell = styled('div')(({ theme }) => ({
  justifySelf: 'center',

  fontSize: 16,
  fontWeight: 500,
  lineHeight: 24 / 16,
  letterSpacing: -0.5,

  color: theme.palette.text.secondary,

  [theme.breakpoints.down('mobile')]: {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 14 / 13,
  },
}));

export const TournamentPlayerRowCellBold = styled(TournamentPlayerRowCell)(({ theme }) => ({
  fontWeight: 600,
  letterSpacing: -0.2,
  color: theme.palette.text.primary,
}));

export const TournamentPlayerBrief = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 12,
}));

export const TournamentPlayerPlace = styled('div')(({ theme }) => ({
  fontSize: 18,
  fontWeight: 600,
  lineHeight: 24 / 18,
  letterSpacing: -0.2,
  minWidth: 24,

  [theme.breakpoints.down('mobile')]: {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 14 / 13,
    minWidth: 18,
  },
}));

export const TournamentPlayerName = styled('div')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 14 / 14,

  [theme.breakpoints.down('mobile')]: {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 14 / 13,
  },
}));

export const TournamentPlayerAvatarContainer = styled('div')(() => ({
  position: 'relative',
}));

export const TournamentPlayerAvatarMobileCrown = styled('div')(() => ({
  position: 'absolute',
  inset: '-12px 0 auto 0',
  zIndex: 1,

  display: 'flex',
  justifyContent: 'center',
}));

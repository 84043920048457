import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Button, Dialog, DontShowWindowInFuture, Icon, Stack, Typography } from '~shared/ui';
import { useTheme } from '~shared/lib/styles';

import { NftCard } from '~entities/nft';

import { useWinstreakWarningDialog } from '~features/event-dialog';

import { WinstreakIllustrationContainer } from './styled';

export const FullWinstreakWarningDialog: FC = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const { open, nft, betNft, switchOff, handleChangeWinstreakDialogShown } =
    useWinstreakWarningDialog();

  if (!nft) {
    return null;
  }

  return (
    <Dialog
      title="Warning"
      open={open}
      backgroundColor={palette.paper.main}
      width={440}
      onClose={switchOff}
    >
      <Stack spacing={24 / 8} alignItems="center">
        <Box sx={{ position: 'relative', marginBottom: '30px' }}>
          <NftCard nft={nft} highlight highlightDensity="large" />
          <WinstreakIllustrationContainer>
            <Typography color="secondary">{t('Marketplace.winStreak')}</Typography>
            <Icon name="illustration-winstreak" size={{ width: 50, height: 20 }} />
          </WinstreakIllustrationContainer>
        </Box>
        <Typography color="secondary">
          {t('Merge.youBetCardWithWinstreak')}
          <br />
          {t('Merge.ifYouLoseWinstreakWillBeLost')}
          <br />
          {t('Merge.areYouSureYouWantToBetCard')}
        </Typography>
        <Stack direction="row" spacing={12 / 8}>
          <Button variant="outlined" onClick={switchOff}>
            {t('Merge.changedMyMind')}
          </Button>
          <Button onClick={betNft}>{t('Merge.betCard')}</Button>
        </Stack>
        <DontShowWindowInFuture onChange={handleChangeWinstreakDialogShown} />
      </Stack>
    </Dialog>
  );
};

import React, { FC } from 'react';

import { Shimmer } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

export const TournamentCardShimmer: FC = () => {
  const { isMobile } = useMediaQuery();

  return (
    <Shimmer height={isMobile ? 340 : 230}>
      <rect x="0" y="0" rx="12" ry="12" width="100%" height={isMobile ? 340 : 230} />
    </Shimmer>
  );
};

import { Address } from '@ton/core';
import axios from 'axios';

import { apiInstance, tonApiInstance } from '../apiInstance';
import { mapGetBattlesByIdsResponse } from '../battle';

import { NftRoutes } from './routes';
import {
  ApiFixCard,
  ApiFixCardResponseData,
  ApiGetNftByTokenId,
  ApiGetNftByTokenIdResponse,
  ApiGetNfts,
} from './types';

const headers = {
  'Accept': 'application/json',
  'X-API-Key': 'GlIY6I0HVJBfFrT4pCVAuopNJcqXqO9cIDbeZ4oxBJCWxbxhmA4ybpaADSENWUFM',
};

const chainName = process.env.REACT_APP_IS_MAINNET === 'true' ? 'polygon' : 'mumbai';

export const fetchNfts: ApiGetNfts = async ({ walletAddress, cursor }) => {
  // * Limit per page equals to 100 by default
  const { nfts, cursor: _cursor } = await tonApiInstance
    .get(NftRoutes.fetchTonNfts(), {
      params: {
        userAddress: Address.normalize(walletAddress),
      },
    })
    .then(({ data }) => {
      return {
        nfts: data.nfts.map((nft: any) => ({
          ...nft,
          relatedEvent: nft.relatedEvent
            ? mapGetBattlesByIdsResponse([nft.relatedEvent])[0]
            : undefined,
        })),
        cursor: null,
      };
    })
    .catch((err) => ({ nfts: [], cursor: null }));

  return { nfts: nfts, cursor: _cursor };
};

export const postFixCard: ApiFixCard = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post<ApiFixCardResponseData>(NftRoutes.fixCard(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const getNftByTokenId: ApiGetNftByTokenId = (data) => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .get<ApiGetNftByTokenIdResponse>(NftRoutes.getNftByTokenId(data.tokenId))
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

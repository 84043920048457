import { Theme, styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

const getRootBackground = ({ $isCorrectChoice, $isNobodyWins }: RootProps, theme: Theme) => {
  switch (true) {
    case $isNobodyWins:
      return theme.palette.grey.A10;

    case $isCorrectChoice:
      return theme.palette.primary.main;

    default:
      return theme.palette.error.main;
  }
};

interface RootProps {
  $isCorrectChoice: boolean;
  $isNobodyWins: boolean;
}

export const Root = styled(Box, { name: 'EventDialogChoiceResult', slot: 'root' })<RootProps>(({
  theme,
  $isCorrectChoice,
  $isNobodyWins,
}) => {
  return {
    padding: 10,
    borderRadius: 8,
    background: getRootBackground({ $isCorrectChoice, $isNobodyWins }, theme),

    [theme.breakpoints.down('mobile')]: {
      padding: 9,
    },
  };
});

export const StyledTypography = styled(Box, {
  name: 'EventDialogChoiceResult',
  slot: 'typography',
})({
  fontSize: '13px',
  lineHeight: '14px',
  fontWeight: 500,
});

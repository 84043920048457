import { motion } from 'framer-motion';

import { styled } from '~shared/lib/styles';

export const ListTopShadowPositionContainer = styled('div', {
  name: 'ListTopShadow',
  slot: 'positionContainer',
})(() => ({
  width: '100%',
  pointerEvents: 'none',
  zIndex: 10,
  marginLeft: -16,
  marginRight: -6,
  position: 'relative',
  marginTop: -1,
  height: 0,
}));

export const ListTopShadowHiddenContainer = styled(motion.div, {
  name: 'ListTopShadow',
  slot: 'hiddenContainer',
})(() => ({
  position: 'absolute',
  overflow: 'hidden',
  height: 30,
  inset: 0,
}));

export const ListTopShadowRoot = styled(motion.div, {
  name: 'ListTopShadow',
  slot: 'shadow',
})(() => ({
  background:
    'radial-gradient(farthest-side at 50% 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 100%)',
  height: 30,
  position: 'absolute',
  inset: '-1px 0 auto 0',
}));

import { useCookie } from 'react-use';
import { TonClient } from '@ton/ton';
import { Address, OpenedContract, Sender, SenderArguments } from '@ton/core';
import {
  CHAIN,
  useIsConnectionRestored,
  useTonAddress,
  useTonConnectModal,
  useTonConnectUI,
  useTonWallet,
} from '@tonconnect/ui-react';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import localforage from 'localforage';

import { openDeepLink, openLink, sliceHash, wrapTransactionMessage } from '~shared/lib/utils';
import {
  ApiCommonSportEnum,
  getBattles,
  getTonProofPayload,
  getTonUsdPrice,
  postSetTonWallet,
  postTonProof,
} from '~shared/api';
import {
  useDispatch,
  useMediaQuery,
  useSnackbar,
  useTransactionStatusDialog,
} from '~shared/lib/hooks';

import { useViewerSelector, viewerActions } from '~entities/viewer';

import {
  CardCollection,
  MassMakeBet,
  Mint,
  PutCardOnAuction,
  RandomBox,
  Restore,
  UpgradeCard,
} from '~shared/contracts/tact_CardCollection';
import { MainToken } from '~shared/contracts/tact_MainToken';
import { JettonTransfer, MainTokenWallet } from '~shared/contracts/tact_MainTokenWallet';
import { Arena, CloseBet } from '~shared/contracts/tact_Arena';
import { CardNFTItem, SetLives, SetWinStreak } from '~shared/contracts/tact_CardNFTItem';
import {
  NftProxy,
  RequestBet,
  RequestFix,
  RequestMerge,
  RequestUnfreeze,
} from '~shared/contracts/tact_NftProxy';
import {
  AuctionLot,
  CloseLot,
  JettonTransferNotification,
  PlaceBet,
} from '~shared/contracts/tact_AuctionLot';

// TODO: fsd
import { useOnboardingModel } from '~features/onboarding';
import { getAuctionLotAddress } from '~features/auction';

import { StorageKeys } from '~shared/api/localforage';

import { useWalletSelector } from '~entities/wallet';

import { Participate, Tournament } from '~shared/contracts/tact_Tournament';

import { TournamentCollection } from '~shared/contracts/tact_TournamentCollection';

import { checkTonStatus, getSeqno, waitForTx } from '../lib';

import { walletActions } from './slice';
import { ConnectionType, TransactionError } from './types';
import { connections } from './config';

const WALLET_ALREADY_TAKEN_SERVER_ERROR_MESSAGE = 'wallet already taken';

export function useAsyncInitialize<T>(func: () => Promise<T>, deps: unknown[] = []) {
  const [state, setState] = useState<T>();

  useEffect(() => {
    (async () => {
      setState(await func());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return state;
}

export function useTonClient() {
  return useAsyncInitialize(
    async () =>
      new TonClient({
        endpoint: 'https://testnet.toncenter.com/api/v2/jsonRPC',
        // endpoint: await getHttpEndpoint({ network }),
        timeout: 5000,
        apiKey: '99121c225f05c3e4a9a4db15290d08660d643e30ed7c078661461412225253a4',
      })
  );
}

export function useTonConnect(): { sender: Sender; connected: boolean } {
  const [tonConnectUI] = useTonConnectUI();

  return {
    sender: {
      send: async (args: SenderArguments) => {
        await tonConnectUI.sendTransaction({
          messages: [
            {
              address: args.to.toString(),
              amount: args.value.toString(),
              payload: args.body?.toBoc().toString('base64'),
            },
          ],
          validUntil: Date.now() + 5 * 60 * 1000,
        });
      },
    },
    connected: tonConnectUI.connected,
  };
}

export const getTonBalance = async (client: TonClient, address: Address) => {
  const balance = await client.getBalance(address);

  return Number((Number(balance) / 1e9).toFixed(2));
};

export const getMcnBalance = async (client: TonClient, address: Address) => {
  try {
    const masterContractInstance = new MainToken(
      Address.parse(process.env.REACT_APP_ADDRESS_TON_MAIN_TOKEN!)
    );

    const masterContract = client.open(masterContractInstance) as OpenedContract<MainToken>;

    const userWalletAddress = await masterContract.getGetJettonWalletAddr(address);

    const mainTokenContractInstance = new MainTokenWallet(userWalletAddress);

    const mainTokenContract = client.open(
      mainTokenContractInstance
    ) as OpenedContract<MainTokenWallet>;

    const mcnWalletData = await mainTokenContract.getGetWalletData();

    return mcnWalletData ? Number((Number(mcnWalletData.balance) / 1e9).toFixed(2)) : 0;
  } catch (error) {
    console.error(error);

    return 0;
  }
};

export const getMcnAddress = async (client: TonClient, address: string) => {
  const masterContractInstance = new MainToken(
    Address.parse(process.env.REACT_APP_ADDRESS_TON_MAIN_TOKEN!)
  );

  const masterContract = client.open(masterContractInstance) as OpenedContract<MainToken>;

  const mcnAddress = await masterContract.getGetJettonWalletAddr(Address.parse(address));

  return mcnAddress;
};

export const getTournamentAddress = async (client: TonClient, index: bigint) => {
  const masterContractInstance = new TournamentCollection(
    Address.parse(process.env.REACT_APP_ADDRESS_TOURNAMENT)
  );

  const masterContract = client.open(
    masterContractInstance
  ) as OpenedContract<TournamentCollection>;

  const tournamentAddress = await masterContract.getTournamentAddress(index);

  return tournamentAddress;
};

export const getNftAddressByTokenId = async (client: TonClient, tokenId: number) => {
  const cardCollectionContractInstance = new CardCollection(
    Address.parse(process.env.REACT_APP_ADDRESS_TON_CARD_COLLECTION!)
  );

  const cardCollectionContract = client.open(
    cardCollectionContractInstance
  ) as OpenedContract<CardCollection>;

  return await cardCollectionContract.getGetNftAddressByIndex(BigInt(tokenId));
};

export const getRecoverCostForNft = async (
  client: TonClient,
  tokenId: number,
  rarity: number,
  lives: number,
  currency: 'ton' | 'mcn'
) => {
  const nftAddress = await getNftAddressByTokenId(client, tokenId);

  const cardNftItemContractInstance = new CardNFTItem(nftAddress);

  const cardNftItemContract = client.open(
    cardNftItemContractInstance
  ) as OpenedContract<CardNFTItem>;

  const restorePrice =
    currency === 'ton'
      ? await cardNftItemContract.getGetRestorePriceTon(BigInt(rarity), BigInt(lives))
      : await cardNftItemContract.getGetRestorePriceMcn(BigInt(rarity), BigInt(lives));

  return restorePrice;
};

export const useGetBalance = () => {
  const client = useTonClient();
  const { tonAddress: address } = useViewerSelector();
  const dispatch = useDispatch();

  const getBalance = async () => {
    if (!address || !client) {
      return;
    }

    const tonBalance = await getTonBalance(client, Address.parse(address));

    const mcnBalance = await getMcnBalance(client, Address.parse(address));

    const tonUsdPrice = await getTonUsdPrice();

    dispatch(
      walletActions.setWallet({
        balance: {
          mcn: mcnBalance,
          ton: tonBalance,
        },
        balanceReference: {
          ton: {
            usd: tonUsdPrice,
          },
          // TODO: Add fetching of MCN usd price
          mcn: {
            usd: null,
          },
        },
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return getBalance;
};

export const useBalance = () => {
  const wallet = useTonWallet();
  const { tonAddress } = useViewerSelector();
  const getBalance = useGetBalance();

  useEffect(() => {
    if (wallet && tonAddress) {
      getBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, tonAddress]);
};

// TODO
export const useConnect = () => {
  const dispatch = useDispatch();
  const { isMobile } = useMediaQuery();
  const viewer = useViewerSelector();

  const connect = async (connection: ConnectionType) => {
    const { downloadLink, deepLink, installed, connector } = connections[connection];
    const shouldOpenDownloadLink = !isMobile && !installed && downloadLink;
    const shouldOpenDeepLink = isMobile && !installed && deepLink;

    if (shouldOpenDownloadLink) {
      openLink(downloadLink);

      return;
    } else if (shouldOpenDeepLink) {
      openDeepLink(deepLink);

      return;
    }

    try {
      if (connection !== ConnectionType.WalletConnectV2) {
        const addChainParameter = {
          chainId: 137,
          chainName: 'Polygon Mainnet',
          rpcUrls: [process.env.REACT_APP_NODE_RPC_URL],
          nativeCurrency: { name: 'Polygon Matic', symbol: 'MATIC', decimals: 18 },
          blockExplorerUrls: ['https://polygonscan.com/'],
        };

        await connector.activate(addChainParameter);
      } else {
        await connector.activate();
      }

      localforage.setItem(StorageKeys.ConnectionType, connection);
    } catch (e) {}
  };

  const openConnectWalletDialog = () => {
    dispatch(walletActions.setWallet({ isConnectWalletDialogOpen: true }));
  };

  const openConnectWalletDialogAndOnboarding = () => {
    if (!viewer.onboarding) {
      dispatch(walletActions.setWallet({ isOnboardingShownAfterConnect: true }));
    }

    dispatch(walletActions.setWallet({ isConnectWalletDialogOpen: true }));
  };

  const openConnectWalletWarnDialog = () => {
    dispatch(walletActions.setWarnDialog(true));
  };

  return {
    connect,
    openConnectWalletDialog,
    openConnectWalletWarnDialog,
    openConnectWalletDialogAndOnboarding,
  };
};

export const useDisconnect = () => {
  const dispatch = useDispatch();
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();

  const disconnect = () => {
    if (!wallet) {
      return;
    }

    tonConnectUI.disconnect();
    dispatch(walletActions.reset());
  };

  return disconnect;
};

const useIsCurrentChainSupported = () => {
  const wallet = useTonWallet();
  const disconnect = useDisconnect();
  const { openSnackbar } = useSnackbar();

  const checkIfChainIsUnsupported = useCallback(async () => {
    if (!wallet) {
      return;
    }

    const chainId = wallet.account.chain;

    if (chainId && chainId === CHAIN.MAINNET) {
      disconnect();

      openSnackbar({
        type: 'error',
        message:
          'Maincard Ton version is currently available only on the testnet. Please ensure that your connected wallet is set to the correct network. Note that @wallet does not support the testnet.',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  useEffect(() => {
    checkIfChainIsUnsupported();
  }, [checkIfChainIsUnsupported]);
};

const useIsCurrentWalletSupported = () => {
  const wallet = useTonWallet();
  const disconnect = useDisconnect();
  const { openSnackbar } = useSnackbar();

  const checkIfWalletUnsupported = useCallback(async () => {
    if (!wallet) {
      return;
    }

    //@ts-ignore
    const connectedWalletName = wallet.name;

    // TODO: maybe add enum instead of strings
    if (connectedWalletName === 'Wallet') {
      disconnect();

      openSnackbar({
        type: 'error',
        message:
          'We currently do not support @wallet in the MVP version of Maincard. Stay tuned for future updates.',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  useEffect(() => {
    checkIfWalletUnsupported();
  }, [checkIfWalletUnsupported]);
};

const useCheckIfEmailIsBoundedToWallet = () => {
  const { t } = useTranslation();
  const { openSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const viewer = useViewerSelector();
  const account = useTonAddress(true);
  const disconnect = useDisconnect();

  const checkIfEmailAndWalletShouldBeBound = async () => {
    const isAuthenticated = Boolean(viewer.email);
    const isConnected = !!account;

    if (!isConnected) {
      return;
    }

    if (isAuthenticated) {
      const hasUserBoundWalletToEmail = Boolean(viewer.tonAddress);

      if (!hasUserBoundWalletToEmail) {
        try {
          postSetTonWallet({ tonAddress: account })
            .then((_) => dispatch(viewerActions.updateData({ ...viewer, tonAddress: account })))
            .catch((_) => {
              openSnackbar({
                type: 'error',
                message:
                  'Sorry, the TON address you’re trying to connect is already in use. Please try connecting with a different address.',
              });

              disconnect();
            });

          // dispatch(walletActions.setWalletConnectedDialog(true));
        } catch (e) {
          disconnect();

          if (e.response?.data?.result === WALLET_ALREADY_TAKEN_SERVER_ERROR_MESSAGE) {
            dispatch(walletActions.setWallet({ isCorrectWalletConnected: false }));

            openSnackbar({
              type: 'error',
              message: `${t('Other.thisWallet')}`,
            });
          }
        }

        return;
      }

      let isConnectingWithBoundWalletAddress = false;
      let normalizedBoundedAddress = '';
      let normalizedWalletAddress = '';

      try {
        normalizedBoundedAddress = Address.normalize(viewer.tonAddress);
        normalizedWalletAddress = Address.normalize(account);

        isConnectingWithBoundWalletAddress =
          viewer.tonAddress === account ||
          normalizedBoundedAddress === normalizedWalletAddress ||
          viewer.tonAddress === normalizedWalletAddress ||
          normalizedBoundedAddress === normalizedWalletAddress;
      } catch (error: any) {
        dispatch(walletActions.setWallet({ isCorrectWalletConnected: false }));
        disconnect();

        openSnackbar({
          type: 'error',
          message: (
            <Trans i18nKey="Alerts.addressIsNotBound">
              {{ hash: sliceHash(normalizedWalletAddress || account) }} address is not bound to
              {{ email: viewer.email }}. Please, try to connect with
              {{ ownHash: sliceHash(normalizedBoundedAddress || viewer.tonAddress) }} address.
            </Trans>
          ),
        });

        return;
      }

      if (!isConnectingWithBoundWalletAddress) {
        dispatch(walletActions.setWallet({ isCorrectWalletConnected: false }));
        disconnect();

        openSnackbar({
          type: 'error',
          message: (
            <Trans i18nKey="Alerts.addressIsNotBound">
              {{ hash: sliceHash(normalizedWalletAddress || account) }} address is not bound to
              {{ email: viewer.email }}. Please, try to connect with
              {{ ownHash: sliceHash(normalizedBoundedAddress || viewer.tonAddress) }} address.
            </Trans>
          ),
        });

        return;
      }

      dispatch(walletActions.setWallet({ isCorrectWalletConnected: true }));
    }
  };

  useEffect(() => {
    if (!!account) {
      checkIfEmailAndWalletShouldBeBound();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, viewer.tonAddress, viewer.email]);
};

const useShowOnboardingAfterConnect = () => {
  const wallet = useTonWallet();
  const { isOnboardingShownAfterConnect, isCorrectWalletConnected } = useWalletSelector();
  const { onOpen: openOnboarding } = useOnboardingModel();

  const isConnected = Boolean(wallet);

  useEffect(() => {
    if (isConnected && isOnboardingShownAfterConnect && isCorrectWalletConnected) {
      setTimeout(() => {
        openOnboarding('1');
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, isOnboardingShownAfterConnect, isCorrectWalletConnected]);
};

export const useInitWallet = () => {
  useIsCurrentChainSupported();
  useIsCurrentWalletSupported();
  useBalance();
  useCheckIfEmailIsBoundedToWallet();
  useShowOnboardingAfterConnect();
};

enum Contracts {
  CardCollection = 'CardCollection',
  MainToken = 'MainToken',
  Arena = 'Arena',
  CardNFTItem = 'CardNFTItem',
  AuctionLot = 'AuctionLot',
  Tournament = 'Tournament',
  NftProxy = 'NftProxy',
}

type Contract =
  | 'CardCollection'
  | 'MainToken'
  | 'Arena'
  | 'CardNFTItem'
  | 'AuctionLot'
  | 'Tournament'
  | 'NftProxy';

type ContractMethodNames = {
  CardCollection:
    | 'Mint'
    | 'RandomBox'
    | 'UpgradeCard'
    | 'Restore'
    | 'MassMakeBet'
    | 'PutCardOnAuction';
  MainToken: 'JettonTransfer';
  Arena: 'CloseBet';
  CardNFTItem: 'SetWinstreak' | 'SetLives';
  AuctionLot: 'PlaceBet' | 'CloseLot' | 'JettonTransferNotification';
  Tournament: 'Participate';
  NftProxy: 'RequestFix' | 'RequestBet' | 'RequestUnfreeze' | 'RequestMerge';
};

type ContractMethodArgs = {
  Mint: Mint;
  RandomBox: RandomBox;
  UpgradeCard: UpgradeCard;
  MassMakeBet: MassMakeBet;
  Restore: Restore;
  JettonTransfer: JettonTransfer;
  CloseBet: CloseBet;
  SetWinstreak: SetWinStreak;
  SetLives: SetLives;
  PlaceBet: PlaceBet;
  CloseLot: CloseLot;
  JettonTransferNotification: JettonTransferNotification;
  PutCardOnAuction: PutCardOnAuction;
  Participate: Participate;
  RequestMerge: RequestMerge;
  RequestUnfreeze: RequestUnfreeze;
  RequestBet: RequestBet;
  RequestFix: RequestFix;
};

type AdditionalArgs = {
  tokenId?: number;
  lotId?: number;
  contractAddress?: string;
};

const contractNameToFactoryMap = {
  CardCollection: CardCollection,
  MainToken: MainTokenWallet,
  Arena: Arena,
  CardNFTItem: CardNFTItem,
  AuctionLot: AuctionLot,
  Tournament: Tournament,
  NftProxy: NftProxy,
};

const contractNameToAddressMap = {
  CardCollection: process.env.REACT_APP_ADDRESS_TON_CARD_COLLECTION,
  MainToken: '',
  Arena: process.env.REACT_APP_ADDRESS_TON_ARENA,
  CardNFTItem: '',
  Auction: process.env.REACT_APP_ADDRESS_TON_AUCTION,
  AuctionLot: '',
  Tournament: process.env.REACT_APP_ADDRESS_TOURNAMENT,
  NftProxy: process.env.REACT_APP_ADDRESS_TON_NFT_PROXY,
};

interface UseWriteContractParams<T extends Contract> {
  contractName: T;
  method: ContractMethodNames[T];
  transactionName?: string;
  successMessage?: string;
  errorMessage?: string;
  onSuccess?: VoidFunction;
  onError?: VoidFunction;
}

export const useWriteContract = <T extends Contract>({
  contractName,
  method,
  transactionName,
  successMessage,
  errorMessage,
  onSuccess,
  onError,
}: UseWriteContractParams<T>) => {
  const { openSnackbar } = useSnackbar();
  const { openTransactionStatusDialog, closeTransactionStatusDialog } =
    useTransactionStatusDialog();

  const { openConnectWalletWarnDialog } = useConnect();

  const getBalance = useGetBalance();

  const address = useTonAddress(true);
  const client = useTonClient();
  const { sender } = useTonConnect();

  const write = async ({
    args,
    value,
    additionalArgs,
  }: {
    args: ContractMethodArgs[typeof method];
    value: bigint;
    additionalArgs?: AdditionalArgs;
  }) => {
    if (!address || !client) {
      openConnectWalletWarnDialog();

      return;
    }

    try {
      const contractFactory = contractNameToFactoryMap[contractName];

      let contractAddress: Address;

      switch (contractName) {
        case Contracts.Tournament: {
          if (!additionalArgs?.contractAddress) {
            throw new Error();
          }

          contractAddress = Address.parse(additionalArgs.contractAddress);

          break;
        }

        case Contracts.MainToken: {
          contractAddress = await getMcnAddress(client, address);
          break;
        }

        case Contracts.CardNFTItem: {
          if (!additionalArgs || !additionalArgs.tokenId) {
            throw new Error();
          }

          contractAddress = await getNftAddressByTokenId(client, additionalArgs.tokenId);
          break;
        }

        case Contracts.AuctionLot: {
          if (!additionalArgs || !additionalArgs.lotId) {
            throw new Error();
          }

          contractAddress = await getAuctionLotAddress(client, additionalArgs.lotId);
          break;
        }

        default: {
          contractAddress = Address.parse(contractNameToAddressMap[contractName]);
          break;
        }
      }

      const contractInstance = new contractFactory(contractAddress);
      const contract = client.open(contractInstance);

      openTransactionStatusDialog(transactionName);

      const seqno = await getSeqno(Address.normalize(address));

      await contract.send(
        sender,
        {
          value,
          bounce: true,
        },
        // TODO: check types later
        //@ts-ignore
        args
      );

      await waitForTx(address, seqno);

      await getBalance();

      closeTransactionStatusDialog();
      onSuccess?.();
      openSnackbar({ type: 'info', message: successMessage || 'Transaction is successful!' });
    } catch (err) {
      await getBalance();

      closeTransactionStatusDialog();

      if (err.message.includes(TransactionError.Rejected)) {
        openSnackbar({
          type: 'error',
          message: 'You declined the transaction',
        });

        throw err;
      }

      openSnackbar({
        type: 'error',
        message: wrapTransactionMessage(errorMessage || 'Transaction is failed!'),
      });

      onError?.();
      throw err;
    }
  };

  return {
    write,
  };
};

export const useCheckTonStatus = () => {
  const [isTonAvailable, setIsTonAvailable] = useState(true);
  const client = useTonClient();

  useEffect(() => {
    let falseCount = 0;

    const checkStatusAndUpdateCounter = async () => {
      if (!client) {
        setIsTonAvailable(false);

        return;
      }

      const result = await checkTonStatus(client);

      falseCount = result ? 0 : falseCount + 1;

      if (falseCount >= 6) {
        setIsTonAvailable(false);
      }
    };

    const interval = setInterval(checkStatusAndUpdateCounter, 10000);

    return () => clearInterval(interval);
  }, [client]);

  return isTonAvailable;
};

export const useCheckTonkBackend = () => {
  const [isAvailable, setIsAvailable] = useState(true);

  const address = useTonAddress(true);

  useEffect(() => {
    const checkBackend = async () => {
      try {
        const response = await getBattles({
          sport: Object.values(ApiCommonSportEnum).filter(
            (sport) => sport !== ApiCommonSportEnum.Tennis
          ),
          address: address && address.length !== 0 ? Address.normalize(address) : undefined,
        });

        if (!response) {
          setIsAvailable(false);
        }
      } catch (error) {
        setIsAvailable(false);
      }
    };

    checkBackend();
  }, [address]);

  return isAvailable;
};

export function useBackendTonAuth() {
  const dispatch = useDispatch();
  const isConnectionRestored = useIsConnectionRestored();
  const wallet = useTonWallet();
  const { openSnackbar } = useSnackbar();
  const [tonConnectUI] = useTonConnectUI();

  useEffect(() => {
    if (!isConnectionRestored) {
      return;
    }

    if (!wallet) {
      getTonProofPayload().then((res) =>
        tonConnectUI.setConnectRequestParameters({
          state: 'ready',
          value: {
            tonProof: res.payload,
          },
        })
      );

      return;
    }

    if (wallet.connectItems?.tonProof && !('error' in wallet.connectItems.tonProof)) {
      postTonProof({
        connectItems: wallet.connectItems.tonProof,
        account: wallet.account,
      })
        .then(() => dispatch(walletActions.setWallet({ isProofed: true })))
        .catch(() => {
          openSnackbar({
            type: 'error',
            message: 'Something went wrong with your wallet, try another one',
          });

          tonConnectUI.disconnect();
        });
    } else {
      dispatch(walletActions.setWallet({ isProofed: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, isConnectionRestored, dispatch]);
}

interface UseCallGaslessParams<TCallbackParams> {
  callback: (data: TCallbackParams) => Promise<void | undefined>;
  transactionName?: string;
  successMessage?: string;
  errorMessage?: string;
}

export function useCallGasless<TCallbackParams>({
  callback,
  transactionName,
  successMessage,
  errorMessage,
}: UseCallGaslessParams<TCallbackParams>) {
  const { openSnackbar } = useSnackbar();
  const { openTransactionStatusDialog, closeTransactionStatusDialog } =
    useTransactionStatusDialog();

  const { openConnectWalletWarnDialog } = useConnect();

  const wallet = useTonWallet();

  const write = async (params: TCallbackParams) => {
    if (!wallet) {
      openConnectWalletWarnDialog();

      return;
    }

    try {
      openTransactionStatusDialog(transactionName);
      // TODO: await tx from TON?
      // send tx from backend
      await callback(params);

      // const receipt = await provider?.waitForTransaction(response.hash);

      // if (receipt?.status !== 1) {
      //   throw new Error();
      // }

      openSnackbar({ type: 'info', message: successMessage || 'Transaction is successful!' });
    } catch (err) {
      openSnackbar({
        type: 'error',
        message: wrapTransactionMessage(errorMessage || 'Transaction is failed!'),
      });

      throw err;
    } finally {
      closeTransactionStatusDialog();
    }
  };

  return write;
}

import { styled } from '~shared/lib/styles';
import { DialogTitle } from '~shared/ui';

export const StyledDialogTitle = styled(DialogTitle)(() => ({
  position: 'relative',
  paddingRight: 56,
  paddingLeft: 56,
}));

export const SwitchLanguageContainer = styled('div')(() => ({
  position: 'absolute',
  inset: '0 0 auto auto',
  display: 'flex',
  alignItems: 'center',
  height: 36,
}));

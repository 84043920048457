import { useCallback } from 'react';

import { Nft } from '~shared/api';

import {
  EventDialogTab,
  MAX_NFTS_ON_ONE_BET,
  useEventModel,
  useEventValidateCard,
} from '~entities/event';
import { useNftCardModel } from '~entities/nft';

import { useJoinTournamentModel } from '~features/tournament';

import { useViewerWalletSelector } from '../selectors';

export const useIsAvailableToSelect = () => {
  const { call, tab, cards } = useEventModel();
  const { isSelectionMode, selectionModeType, nftsToMerge } = useNftCardModel();
  const { selectedCards } = useViewerWalletSelector();
  const { tournament } = useJoinTournamentModel();
  const { isCallAcceptableRarity, isZeroLivesRemaining } = useEventValidateCard();

  const selectedCardsAmount = selectedCards.size;

  const isAvailableToSelect = useCallback(
    (nft: Nft) => {
      if (nft.isHidden) {
        return false;
      }

      if (isSelectionMode) {
        if (selectedCards.has(nft.token_id)) {
          return true;
        }

        if (selectionModeType === 'bet') {
          const isCall = tab === EventDialogTab.Call;

          const isCallAcceptableRarityValidated =
            isCall && call.callAcceptableRarity
              ? isCallAcceptableRarity({
                  rarity: nft.rarity,
                  acceptableRarity: call.callAcceptableRarity,
                })
              : true;

          const isZeroLivesRemainingValidated = !isZeroLivesRemaining(nft);

          const totalCardsAmount = selectedCards.size + cards.length;
          const isMaxLimitReached = totalCardsAmount >= MAX_NFTS_ON_ONE_BET;

          const isValidated =
            isCallAcceptableRarityValidated && isZeroLivesRemainingValidated && !isMaxLimitReached;

          return isValidated;
        }

        if (selectionModeType === 'merge') {
          if (selectedCards.has(nft?.token_id)) {
            return true;
          }

          const nftsToMergeQuantity = nftsToMerge.filter((nft) => nft !== null).length;

          switch (nftsToMergeQuantity) {
            case 0:
              return selectedCardsAmount < 2;
            case 1:
              return selectedCardsAmount < 1;
            default:
              return false;
          }
        }

        if (selectionModeType === 'tournament') {
          if (tournament) {
            if (nft.isOnTournament || nft.isBlockedForTournament) {
              return false;
            }

            if (selectedCards.size >= tournament.joinCardsQuantity) {
              return false;
            }

            if (nft.livesRemaining < 1) {
              return false;
            }

            if (nft.rarity !== tournament.tournamentRarity) {
              return false;
            }

            return true;
          }

          return false;
        }
      }

      return false;
    },
    [
      call.callAcceptableRarity,
      cards.length,
      isCallAcceptableRarity,
      isSelectionMode,
      isZeroLivesRemaining,
      nftsToMerge,
      selectedCards,
      selectedCardsAmount,
      selectionModeType,
      tab,
      tournament,
    ]
  );

  return {
    isAvailableToSelect,
  };
};

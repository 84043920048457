import { ApiCommonLeague, ApiCommonSport, ApiCommonSportEnum } from '~shared/api';
import { leagueToLogoMap } from '~entities/battle';
import { sportToIconMap } from '~shared/lib/assets';

export const getLeagueLogo = (sport: ApiCommonSport, league: ApiCommonLeague) => {
  switch (sport) {
    case ApiCommonSportEnum.Dota:
    case ApiCommonSportEnum.LeagueOfLegends:
    case ApiCommonSportEnum.CS2:
      return leagueToLogoMap[league] ?? sportToIconMap[sport];
    default:
      return leagueToLogoMap[league];
  }
};

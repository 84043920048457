import { FC } from 'react';

import { TournamentDialog, useTournamentsModel } from '~entities/tournament';

import { JoinTournamentComplexButton } from '~features/tournament';

export const TournamentDialogConstructor: FC = () => {
  const { tournament, isLoading, dialogOpen, closeTournamentDialog } = useTournamentsModel();

  return (
    <TournamentDialog
      open={dialogOpen}
      tournament={tournament ?? null}
      loading={isLoading}
      onClose={closeTournamentDialog}
      joinButton={tournament ? <JoinTournamentComplexButton tournament={tournament} /> : null}
    />
  );
};

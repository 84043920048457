import { useCallback } from 'react';

import { ApiGetTournamentsMappedResponseData } from '~shared/api';
import { useSearchParamsState } from '~shared/lib/hooks';

import { TournamentStatus, getTournamentStatus } from '~entities/tournament';

import {
  TournamentListRarity,
  TournamentListRarityKey,
  TournamentListTab,
  TournamentListTabKey,
  TournamentsListFilter,
} from '../../lib';

export const useTournamentsList = (list: ApiGetTournamentsMappedResponseData[]) => {
  const [tab, setTab] = useSearchParamsState<TournamentListTab>(
    TournamentListTabKey,
    TournamentListTab.All
  );

  const [rawRarityFilters, setRarityFilters] = useSearchParamsState<string>(
    TournamentListRarityKey,
    TournamentListRarity.All
  );

  const rarityFilters = rawRarityFilters.split(',');

  const onChangeTab = useCallback(
    (event: any, value: unknown) => {
      setTab(value as TournamentListTab);
    },
    [setTab]
  );

  const toggleRarityFilter = useCallback(
    (value: unknown) => {
      const targetTab = String(value);

      if (targetTab === TournamentsListFilter.All) {
        setRarityFilters([TournamentsListFilter.All].toString());
      } else {
        if (rarityFilters.includes(targetTab)) {
          const next = rarityFilters.filter((rarity) => rarity !== targetTab);

          if (next.length === 0) {
            setRarityFilters([TournamentsListFilter.All].toString());
          } else {
            setRarityFilters(next.toString());
          }
        } else {
          setRarityFilters(
            [
              ...rarityFilters.filter((rarity) => rarity !== TournamentsListFilter.All),
              targetTab,
            ].toString()
          );
        }
      }
    },
    [rarityFilters, setRarityFilters]
  );

  const filterByTab = (list: ApiGetTournamentsMappedResponseData[]) => {
    const filterMap: Partial<Record<TournamentListTab, TournamentStatus>> = {
      [TournamentListTab.Active]: 'active',
      [TournamentListTab.Past]: 'end',
      [TournamentListTab.Upcoming]: 'soon',
    };

    switch (tab) {
      case TournamentListTab.All:
        return list;
      default:
        return list.filter(
          (tournament) =>
            getTournamentStatus(tournament.startDate, tournament.endDate) === filterMap[tab]
        );
    }
  };

  const filterByRarity = (list: ApiGetTournamentsMappedResponseData[]) => {
    return list.filter((tournament) => {
      if (rarityFilters.includes(TournamentListRarity.All)) {
        return true;
      }

      return rarityFilters.includes(tournament.tournamentRarity.toString());
    });
  };

  return {
    list: filterByTab(filterByRarity(list)),

    tabValue: tab,
    onChangeTab,

    rarityFilters,
    toggleRarityFilter,
  };
};

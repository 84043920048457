import { FC } from 'react';

import { Shimmer, Stack } from '~shared/ui';

export const BestAuctionListShimmer: FC = () => {
  const shimmerElement = (
    <Shimmer width="100%" height={176}>
      <rect x="0" y="0" rx="12" ry="12" width="100%" height="176" />
    </Shimmer>
  );

  return (
    <Stack direction="row" spacing={16 / 8}>
      {shimmerElement}
      {shimmerElement}
      {shimmerElement}
      {shimmerElement}
      {shimmerElement}
      {shimmerElement}
      {shimmerElement}
      {shimmerElement}
    </Stack>
  );
};

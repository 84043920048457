import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Dialog, Stack, Typography } from '~shared/ui';

import { NftCardDrag } from '~entities/nft';

import { FixCard } from '~features/nft';

import { useMergeModel } from '../../../../model';

export const RepairBeforeMergeDialog: FC = () => {
  const { t } = useTranslation();

  const { cancelRepairBeforeMerge, targetNftToRepair, repairBeforeMergeDialogOpen } =
    useMergeModel();

  return (
    <Dialog open={repairBeforeMergeDialogOpen} onClose={cancelRepairBeforeMerge} width={440}>
      <Stack alignItems="center" spacing={24 / 8}>
        <Typography variant="h1" mb="24px" textAlign="center">
          {t('Other.fixCardToMerge')}
        </Typography>

        {targetNftToRepair && (
          <NftCardDrag nft={targetNftToRepair} highlight highlightDensity="large" />
        )}

        <Typography textAlign="center" mt="24px">
          <Trans i18nKey="Other.cardIsReadyForMergeText">
            Card is ready for merge <br /> But before merge you need to fix card
          </Trans>
        </Typography>

        <Stack direction="row" justifyContent="center" spacing={12 / 8}>
          <Button variant="outlined" onClick={cancelRepairBeforeMerge}>
            {t('Other.repairBeforeMergeDoLater')}
          </Button>

          <FixCard nft={targetNftToRepair}>{t('Other.fixCard')}</FixCard>
        </Stack>
      </Stack>
    </Dialog>
  );
};

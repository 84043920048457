import { SyntheticEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Heading, Stack, Tab, Tabs, TabsList } from '~shared/ui';
import { useMediaQuery, useSearchParamsState } from '~shared/lib/hooks';

import { useViewerSelector } from '~entities/viewer';

import { ProfileWallet } from '~widgets/profile-wallet';
import { ViewerBattlesList } from '~widgets/viewer-battles-list';
import { ViewerCallsList } from '~widgets/viewer-calls-list';
import { ProfileDashboard } from '~widgets/profile-dashboard';
import { ProfileAccount } from '~widgets/profile-account';

enum ProfileTabs {
  Dashboard = 'dashboard',
  Battles = 'battles',
  Calls = 'calls',
  Wallet = 'wallet',
  Account = 'account',
}

export const ProfilePage = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const [tab, setTab] = useSearchParamsState<ProfileTabs>(
    'tab',
    isMobile ? ProfileTabs.Account : ProfileTabs.Battles
  );

  const { authorized, tonAddress } = useViewerSelector();

  const handleChangeTab = useCallback(
    (event: SyntheticEvent | null, value: any) => {
      setTab(value as ProfileTabs);
    },
    [setTab]
  );

  // todo: remove after enabling tabs
  useEffect(() => {
    if (tab === ProfileTabs.Wallet) {
      setTab(ProfileTabs.Battles);
    }
  }, [setTab, tab]);

  const tabs = {
    [ProfileTabs.Account]: <ProfileAccount />,
    [ProfileTabs.Dashboard]: <ProfileDashboard />,
    // [ProfileTabs.Battles]: <ViewerBattlesList />,
    // [ProfileTabs.Calls]: <ViewerCallsList />,
    // [ProfileTabs.Wallet]: <ProfileWallet />,
  };

  if (!authorized) {
    return null;
  }

  return (
    <Container display="flex" flexDirection="column" minHeight={1}>
      <Stack spacing={10 / 8} mb={(isMobile ? 20 : 24) / 8}>
        <Container disableGutters negative={isMobile}>
          <Heading
            layoutVariant={isMobile ? 'section' : 'default'}
            variant="h1"
            fontSize={isMobile ? 20 : 34}
            lineHeight={isMobile ? 30 / 20 : 51 / 34}
            mb={isMobile ? 0 : 10 / 8}
            mt={isMobile ? -10 / 8 : 0}
          >
            {t('Menu.profile')}
          </Heading>

          <Tabs value={tab} onChange={handleChangeTab}>
            <TabsList>
              {isMobile && (
                <Tab value={ProfileTabs.Dashboard} disabled={!tonAddress}>
                  {t('Other.dashboard')}
                </Tab>
              )}
              {/* <Tab value={ProfileTabs.Battles}>{t('Other.battles')}</Tab> */}
              {/* <Tab value={ProfileTabs.Calls}>{t('Tabs.myCalls')}</Tab> */}
              <Tab value={ProfileTabs.Account} disabled={!authorized}>
                {t('Other.account')}
              </Tab>
              {/* <Tab value={ProfileTabs.Wallet} disabled>
                {t('disconnectWallet.wallet')}
              </Tab> */}
            </TabsList>
          </Tabs>
        </Container>
      </Stack>

      {tabs[tab]}
    </Container>
  );
};

import { FC, MouseEvent } from 'react';
import { motion } from 'framer-motion';

import { PopperChildrenProps } from '@mui/base';

import { ClickAwayListener } from '../../common';

import { UIKitPopperRoot } from './styled';
import { PopoverMotionProps } from './types';

enum AnimationVariant {
  Visible = 'visible',
  Hidden = 'hidden',
}

export const PopoverMotion: FC<PopoverMotionProps> = ({
  placement = 'top',
  open,
  children,
  anchorEl,
  onClose = () => undefined,
  ...popperProps
}) => {
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <UIKitPopperRoot
      open={open}
      onClick={handleClick}
      anchorEl={anchorEl}
      placement={placement}
      transition
      {...popperProps}
    >
      {({ TransitionProps }: PopperChildrenProps) => (
        <motion.div
          variants={paperMotionVariants}
          animate={open ? AnimationVariant.Visible : AnimationVariant.Hidden}
          initial={AnimationVariant.Hidden}
          onAnimationStart={open ? TransitionProps?.onEnter : undefined}
          onAnimationComplete={!open ? TransitionProps?.onExited : undefined}
        >
          <ClickAwayListener onClickAway={onClose}>{children}</ClickAwayListener>
        </motion.div>
      )}
    </UIKitPopperRoot>
  );
};

const paperMotionVariants = {
  [AnimationVariant.Hidden]: { opacity: 0, x: 5, transition: { duration: 0.2, type: 'tween' } },
  [AnimationVariant.Visible]: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.2, type: 'tween', delay: 50 / 1000 },
  },
};

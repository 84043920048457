import { FC } from 'react';
import { MotionProps } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { useMediaQuery, useSwitcher } from '~shared/lib/hooks';
import { getProgressBetweenDates } from '~shared/lib/utils';
import { Divider, Stack } from '~shared/ui';

import { NFT_RARITY_TO_READABLE_RARITY_NAME_MAP, NftCardDrag, NftCardShimmer } from '~entities/nft';
import { formatLocaleDateString } from '~entities/tournament';

import { useWalletSelector } from '~entities/wallet';

import { useViewerSelector } from '~entities/viewer';

import { useIsAvailableToSelect, useViewerWalletModel } from '../../../model';

import {
  ViewerWalletTournamentNftsList,
  ViewerWalletTournamentPane,
  ViewerWalletTournamentPaneChevron,
  ViewerWalletTournamentPaneDate,
  ViewerWalletTournamentPaneLabel,
  ViewerWalletTournamentPanePlayers,
  ViewerWalletTournamentPaneProgress,
  ViewerWalletTournamentRoot,
} from './styled';

import { ViewerWalletTournamentProps } from './types';

export const ViewerWalletTournament: FC<ViewerWalletTournamentProps> = ({
  isLoading,
  tournament,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const open = useSwitcher(true);

  const { nfts } = useViewerWalletModel();
  const { isAvailableToSelect } = useIsAvailableToSelect();
  const { isCallCardSelected, isCardSelected, onNftClick } = useViewerWalletModel();

  const { tonAddress } = useViewerSelector();

  const cardSize = isMobile ? 'lg' : 'md';
  const progress = getProgressBetweenDates(tournament.startDate, tournament.endDate) * 100;

  const viewerTournamentPlayerInfo = tournament.players.find(
    (player) => player.tonWallet === tonAddress
  );

  const viewerTournamentPlayerCardIds = viewerTournamentPlayerInfo?.cards?.map((cardId) =>
    String(cardId)
  );

  const getTournamentCards = (cardIds?: Array<string>) => {
    if (!cardIds) {
      return;
    }

    const viewerTournamentPlayerCards = [];

    for (const cardId of cardIds) {
      const nft = nfts.find((nft) => String(nft.token_id) === cardId);

      if (nft) {
        viewerTournamentPlayerCards.push(nft);
      }
    }

    return viewerTournamentPlayerCards;
  };

  const viewerTournamentPlayerCards = getTournamentCards(viewerTournamentPlayerCardIds);

  return (
    <ViewerWalletTournamentRoot>
      <ViewerWalletTournamentPane onClick={open.toggle}>
        <ViewerWalletTournamentPaneLabel>
          {t(NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[tournament.tournamentRarity])}{' '}
          {tournament.joinCardsQuantity}x
        </ViewerWalletTournamentPaneLabel>

        <Stack direction="row" spacing={6 / 8} alignItems="center">
          <ViewerWalletTournamentPaneProgress
            progress={progress}
            progressColor={progress > 67 ? 'error' : 'primary'}
            height={4}
          />

          <ViewerWalletTournamentPaneDate>
            {t('Sidebar.tournamentEnds', { date: formatLocaleDateString(new Date(), true) })}
          </ViewerWalletTournamentPaneDate>

          <Divider orientation="vertical" flexItem />

          <ViewerWalletTournamentPanePlayers>
            {tournament.players.length}/{tournament.playersLimit}
          </ViewerWalletTournamentPanePlayers>

          <ViewerWalletTournamentPaneChevron name="chevron-down" $open={open.value} size={16} />
        </Stack>
      </ViewerWalletTournamentPane>

      <ViewerWalletTournamentNftsList {...getAnimation(open.value)}>
        {isLoading
          ? [...Array(tournament.joinCardsQuantity)].map((_, key) => (
              <NftCardShimmer key={key} size={cardSize} />
            ))
          : viewerTournamentPlayerCards &&
            viewerTournamentPlayerCards.map?.((nft) => {
              const isSelected = isCardSelected(nft);
              const isAvailableForSelection = isAvailableToSelect(nft);

              return (
                <NftCardDrag
                  key={nft.token_id}
                  nft={nft}
                  size={cardSize}
                  locked={nft.isHidden}
                  onClick={onNftClick(nft, isMobile)}
                  selectionMode={
                    isCallCardSelected ? isSelected : isSelected || isAvailableForSelection
                  }
                  checked={isSelected}
                  location="wallet"
                />
              );
            })}
      </ViewerWalletTournamentNftsList>
    </ViewerWalletTournamentRoot>
  );
};

const getAnimation = (show: boolean): MotionProps => {
  return {
    animate: show
      ? {
          height: 'auto',
          paddingBottom: 16,
          paddingTop: 16,
        }
      : {
          height: 0,
          paddingBottom: 0,
          paddingTop: 0,
        },
    initial: {
      height: 'auto',
      paddingBottom: 16,
      paddingTop: 16,
    },
  };
};

import { FC, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import TiktokPixel from 'tiktok-pixel';

import {
  CS2LeaguesConfig,
  DotaLeaguesConfig,
  FootballLeaguesConfig,
  LeagueOfLegendsLeaguesConfig,
  SportsConfig,
  TennisLeaguesConfig,
} from '~shared/config/sport';
import { routes } from '~shared/config';

import { ApiCommonSportEnum } from '~shared/api';

import {
  EsportsPage,
  HomePage,
  MarketplacePage,
  NotificationsPage,
  PasswordRecoveryPage,
  ProfilePage,
  RankingsPage,
  TournamentsPage,
  UIKitDemoPage,
  WalletCardPage,
  WalletPage,
} from '~pages';

import { Dashboard } from '~pages/Dashboard';

import { MobileRoute, PrivateRoute, TestRoute } from './routes';
import { DefaultLayout, PaperLayout } from './layouts';
import { leaguePageFabric, sportPageFabric } from './fabrics';

export const Routing: FC = () => {
  const location = useLocation();

  useEffect(() => {
    TiktokPixel.pageView();
    Userpilot.reload();
    Userpilot.track('change location', { url: location.pathname });
  }, [location.pathname]);

  const { values: v } = Object;

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route element={<DefaultLayout viewerWallet header mainGutters pinScrollbar />}>
          <Route path={routes.tournaments} element={<TournamentsPage />} />

          <Route path={routes.index} element={<HomePage />} />
          <Route path={routes.marketplace} element={<MarketplacePage />} />
          <Route path={routes.ranking} element={<RankingsPage />} />
          <Route path={routes.dashboard} element={<Dashboard />} />
          <Route path={routes.profile} element={<ProfilePage />} />
          <Route path={routes.sports.esports} element={<EsportsPage />} />

          {sportPageFabric(v(SportsConfig))}

          {leaguePageFabric(v(FootballLeaguesConfig), ApiCommonSportEnum.Football)}
          {leaguePageFabric(v(TennisLeaguesConfig), ApiCommonSportEnum.Tennis)}
          {leaguePageFabric(v(CS2LeaguesConfig), ApiCommonSportEnum.CS2)}
          {leaguePageFabric(v(DotaLeaguesConfig), ApiCommonSportEnum.Dota)}
          {leaguePageFabric(v(LeagueOfLegendsLeaguesConfig), ApiCommonSportEnum.LeagueOfLegends)}
        </Route>

        <Route element={<DefaultLayout header />}>
          <Route element={<MobileRoute />}>
            <Route path={routes.wallet} element={<WalletPage />} />
            <Route path={routes.walletCard} element={<WalletCardPage />} />
          </Route>
        </Route>

        <Route element={<DefaultLayout />}>
          <Route element={<MobileRoute />}>
            <Route path={routes.notifications} element={<NotificationsPage />} />
          </Route>
        </Route>
      </Route>

      <Route element={<PaperLayout />}>
        <Route path={routes.passwordRecovery} element={<PasswordRecoveryPage />} />
      </Route>

      <Route element={<TestRoute />}>
        <Route path={routes.uiKitDemo} element={<UIKitDemoPage />} />
      </Route>

      <Route path="*" element={<Navigate to={routes.index} />} />
    </Routes>
  );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ReferralState } from './types';

const initialState: ReferralState = {
  refCode: null,
  open: false,
};

export const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {
    set: (state, { payload: refCode }: PayloadAction<string>) => ({ ...state, refCode: refCode }),
    open: (state) => ({ ...state, open: true }),
    close: (state) => ({ ...state, open: false }),

    reset: () => {
      return { ...initialState };
    },
  },
});

export const referralActions = referralSlice.actions;

import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Box, ButtonIcon, Divider, Heading, Stack, Typography } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';
import { getAvatarAbsoluteUri } from '~shared/lib/utils';
import { useTheme } from '~shared/lib/styles';

import { useViewerSelector } from '~entities/viewer';
import { NFT_RARITY_TO_MAX_WIN_STREAK, NftCardDrag, NftCardInfoStatus } from '~entities/nft';

import { SellCard } from '~features/nft';

import { NftRarity } from '~shared/api';

import { NftCardDetailedInfoTabs } from '../NftCardDetailedInfoTabs';

import { NftCardDetailedInfoHeaderEntry } from './NftCardDetailedInfoHeaderEntry';

import { EntryValueText, Root } from './styled';
import { NftCardDetailedInfoContentHeaderProps } from './types';

export const NftCardDetailedInfoContentHeader: FC<NftCardDetailedInfoContentHeaderProps> = ({
  nft,
  maxNftLives,
  maxWinStreak,
  nickname,
  avatarUrl,
  nftStats,
  isNftAvailableForSell,
  viewMode,
  onClose,
  ...tabsProps
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();
  // todo: fsd
  const { nickname: currentUserNickname } = useViewerSelector();

  const isOwnerCurrentUser = nickname === currentUserNickname;
  const wins =
    nft.rarity !== NftRarity.Mythic
      ? NFT_RARITY_TO_MAX_WIN_STREAK[nft.rarity] >= Number(nftStats.winsAmount)
        ? nftStats.winsAmount
        : NFT_RARITY_TO_MAX_WIN_STREAK[nft.rarity]
      : nftStats.winsAmount;

  if (isMobile) {
    return (
      <Root spacing={16 / 8}>
        <Stack spacing={16 / 8}>
          <Stack direction="row" spacing={10 / 8} alignItems="center">
            <ButtonIcon size={24} name="arrow-left" variant="plain" onClick={onClose} />
            <Heading>{t('Other.info')}</Heading>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={20 / 8}>
            <NftCardDrag nft={nft} hideStates={viewMode} />

            <Stack flex={1}>
              <Stack direction="row" spacing={16 / 8}>
                <NftCardDetailedInfoHeaderEntry
                  label={t('BattlePopUp.onlyWin')}
                  value={
                    <EntryValueText color={palette.primary.main}>
                      {nftStats.winsAmount}
                    </EntryValueText>
                  }
                />
                <NftCardDetailedInfoHeaderEntry
                  label={t('BattlePopUp.lose')}
                  value={
                    <EntryValueText color={palette.error.main}>
                      {nftStats.losesAmount}
                    </EntryValueText>
                  }
                />
              </Stack>

              {Boolean(nickname) ? (
                <Fragment>
                  <Divider my="10px" />
                  <NftCardDetailedInfoHeaderEntry
                    label={t('Other.owner')}
                    value={
                      <Stack direction="row" spacing={10 / 8}>
                        <Avatar
                          src={isOwnerCurrentUser ? avatarUrl : getAvatarAbsoluteUri(avatarUrl)}
                          size={24}
                        />
                        <EntryValueText>
                          {isOwnerCurrentUser ? `${t('Other.you')}` : nickname}
                        </EntryValueText>
                      </Stack>
                    }
                  />
                </Fragment>
              ) : (
                <Divider my="10px" />
              )}
            </Stack>
          </Stack>
        </Stack>

        <NftCardDetailedInfoTabs {...tabsProps} />
      </Root>
    );
  }

  return (
    <Root direction="row" spacing={32 / 8}>
      <Box sx={{ position: 'absolute', right: 0, top: 26 }}>
        {!viewMode && isNftAvailableForSell && (
          <SellCard nft={nft} size="sm">
            {t('Other.sellCard')}
          </SellCard>
        )}
      </Box>

      <Box ml="0 !important" alignSelf="center">
        <NftCardDrag nft={nft} hideStates={viewMode} />
      </Box>

      <Stack justifyContent="space-between">
        <Stack spacing={6 / 8}>
          <Typography variant="h2" fontSize={16} lineHeight={18 / 16}>
            {t(nft.readableRarity)}
          </Typography>

          <NftCardInfoStatus nft={nft} />
        </Stack>

        <Stack direction="row" spacing={18 / 8} mt={22 / 8}>
          <NftCardDetailedInfoHeaderEntry
            label={t('BattlePopUp.tokenId')}
            value={<EntryValueText>{nft.token_id}</EntryValueText>}
          />

          <Divider orientation="vertical" />

          <NftCardDetailedInfoHeaderEntry
            label={t('BattlePopUp.onlyWin')}
            value={<EntryValueText color={palette.primary.main}>{wins}</EntryValueText>}
          />

          <Divider orientation="vertical" />

          <NftCardDetailedInfoHeaderEntry
            label={t('BattlePopUp.lose')}
            value={
              <EntryValueText color={palette.error.main}>{nftStats.losesAmount}</EntryValueText>
            }
          />
          <Divider orientation="vertical" />

          <NftCardDetailedInfoHeaderEntry
            label={t('Marketplace.lives')}
            value={
              <EntryValueText>
                {nft.livesRemaining}/{maxNftLives}
              </EntryValueText>
            }
          />

          <Divider orientation="vertical" />

          <NftCardDetailedInfoHeaderEntry
            label={t('Marketplace.winStreak')}
            value={
              <EntryValueText>
                {nft.winStreak}/{maxWinStreak}
              </EntryValueText>
            }
          />

          {Boolean(nickname) && (
            <Fragment>
              <Divider orientation="vertical" />
              <NftCardDetailedInfoHeaderEntry
                label={t('Other.owner')}
                value={
                  <Stack direction="row" spacing={10 / 8}>
                    <Avatar
                      src={isOwnerCurrentUser ? avatarUrl : getAvatarAbsoluteUri(avatarUrl)}
                      size={24}
                    />
                    <EntryValueText>
                      {isOwnerCurrentUser ? `${t('Other.you')}` : nickname}
                    </EntryValueText>
                  </Stack>
                }
              />
            </Fragment>
          )}
        </Stack>
      </Stack>
    </Root>
  );
};

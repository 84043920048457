import { useCallback } from 'react';

import { useDispatch } from '~shared/lib/hooks';
import { getSignOut } from '~shared/api';

import { useViewerModel, viewerActions, viewerSlice } from '~entities/viewer';
import { useDisconnect, walletSlice } from '~entities/wallet';
import { notificationsSlice } from '~entities/notifications';
import { tournamentsSlice } from '~entities/tournament';
import { eventSlice } from '~entities/event';
import { nftSlice } from '~entities/nft';

import { joinTournamentSlice } from '~features/tournament';
import { auctionSlice } from '~features/auction';
import { mergeSlice } from '~features/nft';

import { userProfileSlice } from '~widgets/leaderboard-list';
import { recoverySlice } from '~widgets/password-recovery';
import { viewerWalletSlice } from '~widgets/viewer-wallet';
import { contactUsSlice } from '~widgets/contact-us';
import { referralSlice } from '~widgets/referal';
import { authSlice } from '~widgets/auth';
import { inventorySlice } from '~widgets/inventory';
import { dailyQuestRewardSlice } from '~widgets/daily-quest-reward-dialog';
import { dailyQuestProgressSlice } from '~widgets/daily-quest-progress-dialog';

export const useViewerSignOutModel = () => {
  const dispatch = useDispatch();

  const disconnect = useDisconnect();
  const { signOutDialogOpen } = useViewerModel();

  const signOut = useCallback(() => {
    getSignOut().then(() => {
      dispatch(notificationsSlice.actions.reset());
      dispatch(tournamentsSlice.actions.reset());
      dispatch(viewerSlice.actions.reset());
      dispatch(walletSlice.actions.reset());
      dispatch(eventSlice.actions.reset());
      dispatch(nftSlice.actions.reset());

      dispatch(joinTournamentSlice.actions.reset());
      dispatch(auctionSlice.actions.reset());
      dispatch(mergeSlice.actions.reset());

      dispatch(userProfileSlice.actions.reset());
      dispatch(recoverySlice.actions.reset());
      dispatch(viewerWalletSlice.actions.reset());
      dispatch(contactUsSlice.actions.reset());
      dispatch(referralSlice.actions.reset());
      dispatch(authSlice.actions.reset());
      dispatch(inventorySlice.actions.reset());
      dispatch(dailyQuestRewardSlice.actions.reset());
      dispatch(dailyQuestProgressSlice.actions.reset());

      disconnect();
    });
  }, [dispatch, disconnect]);

  const closeSignOut = useCallback(() => {
    dispatch(viewerActions.closeSignOutDialog());
  }, [dispatch]);

  return {
    signOut,
    signOutDialogOpen,
    closeSignOut,
  };
};

import { useAuthModel, useAuthSelector } from '~widgets/auth/model';

import { Button, Typography } from '~shared/ui';

import {
  AdditionalBlock,
  Count,
  DailyQuestDialog,
  GiftImage,
  GiftWrapper,
  StyledDialog,
} from './styled';

export const FreebetDialog = () => {
  const { closeFreebetDialog } = useAuthModel();
  const { isFreebetDialogOpen } = useAuthSelector();

  return (
    <StyledDialog open={isFreebetDialogOpen} onClose={closeFreebetDialog}>
      <DailyQuestDialog>
        <AdditionalBlock />
        <Typography zIndex={1} fontSize={22} fontWeight={700} textAlign="center" lineHeight={1.2}>
          Wow! <br /> You got 10 freebets!
        </Typography>
        <GiftWrapper>
          <GiftImage src={`${process.env.PUBLIC_URL}/assets/dailyQuests/gift_Freebet.png`} alt="" />
          <Count>
            <Typography fontSize={26} lineHeight={1.17} textAlign="center" fontWeight={700}>
              x10
            </Typography>
          </Count>
        </GiftWrapper>
        <Typography
          color="secondary"
          fontSize={14}
          lineHeight={1.3}
          textAlign="center"
          fontWeight={500}
        >
          Maincard gives you 10 free bets to start playing. With its help you can bet on events for
          free
        </Typography>
        <Button onClick={closeFreebetDialog} mt="20px">
          Okay, Thank you
        </Button>
      </DailyQuestDialog>
    </StyledDialog>
  );
};

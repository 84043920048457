import { Nft } from '~shared/api';

import { NFT_RARITY_TO_MAX_LIVES_MAP } from '../../lib';

export const getRepairedNftStructure = (nft: Nft) => {
  return {
    ...nft,

    livesRemaining: NFT_RARITY_TO_MAX_LIVES_MAP[nft.rarity],
    availableAmountOfRecoveries:
      nft.availableAmountOfRecoveries === 0 ? 0 : nft.availableAmountOfRecoveries - 1,
  };
};

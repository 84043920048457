import { FC } from 'react';

import { Icon } from '~shared/ui';

import { NftFreezedRoot, NftFreezedText } from './styled';

export const NftFreezed: FC = () => {
  return (
    <NftFreezedRoot>
      <Icon name="snowflake" size={25} />
      <NftFreezedText>Freezed</NftFreezedText>
    </NftFreezedRoot>
  );
};

import { FC, SyntheticEvent, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Box,
  Container,
  HeadingTab,
  HeadingTabPanel,
  HeadingTabs,
  HeadingTabsList,
} from '~shared/ui';

import { CommonTab } from './tabs/Common';
import { IconsTab } from './tabs/Icons';
import { ButtonsTab } from './tabs/Buttons';
import { SelectorsTab } from './tabs/Selectors';
import { InputsTab } from './tabs/Inputs';
import { MenuTab } from './tabs/Menu';
import { BattleTab } from './tabs/Battle';
import { CardsTab } from './tabs/Cards';
import { NotificationsTab } from './tabs/Notifications';

const Tabs = [
  { name: 'Common', component: CommonTab },
  { name: 'Buttons', component: ButtonsTab },
  { name: 'Icons', component: IconsTab },
  { name: 'Selectors', component: SelectorsTab },
  { name: 'Inputs', component: InputsTab },
  { name: 'Menu', component: MenuTab },
  { name: 'Battle', component: BattleTab },
  { name: 'Cards', component: CardsTab },
  { name: 'Notifications', component: NotificationsTab },
];

export const UIKitDemoPage: FC = () => {
  const [params, setParams] = useSearchParams([['tab', '0']]);
  const tab = params.get('tab');

  const handleChangeTab: (event: SyntheticEvent | null, value: number | string | boolean) => void =
    useCallback((event, tab) => setParams({ tab: String(tab) }), [setParams]);

  return (
    <Box py={4}>
      <Container>
        <HeadingTabs value={Number(tab)} onChange={handleChangeTab}>
          <HeadingTabsList>
            {Tabs.map((tab, idx) => (
              <HeadingTab key={idx}>{tab.name}</HeadingTab>
            ))}
          </HeadingTabsList>

          <Box mt={4}>
            {Tabs.map((tab, idx) => (
              <HeadingTabPanel key={idx} value={idx}>
                <tab.component />
              </HeadingTabPanel>
            ))}
          </Box>
        </HeadingTabs>
      </Container>
    </Box>
  );
};

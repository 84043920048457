import { Box, Button, Divider, Stack, TransactionStatusDialog } from '~shared/ui';
import { useSnackbar, useSwitcher } from '~shared/lib/hooks';
import { wrapTransactionMessage } from '~shared/lib/utils';
import { Nft } from '~shared/api';

import { NFT_CARD_SIZE_VALUES, NftCard } from '~entities/nft';

const nftWithoutStates: Nft = {
  token_address: '0x443dbec6281ba4c2da1ff49e6fd5ec28332b657b',
  token_id: '12',
  owner_of: '0xcf41c3ce01c1da01075e8b63b07b7e1c8c6e2ee5',
  block_number: '31629539',
  block_number_minted: '31629539',
  token_hash: '99aab1b8f01c9c1387d563ce2a260ea1',
  amount: '1',
  contract_type: 'ERC721',
  name: 'MainCard',
  symbol: 'MCD',
  token_uri: 'http://app.maincard.io/cards/12',
  metadata: '{"image":"http://app.maincard.io/cards/12.png","name":"Maincard Common 12 22"}',
  last_token_uri_sync: '2023-02-01T13:10:59.614Z',
  last_metadata_sync: '2023-02-01T13:11:13.290Z',
  minter_address: '0xcf41c3ce01c1da01075e8b63b07b7e1c8c6e2ee5',
  rarity: 0,
  winStreak: 0,
  livesRemaining: 2,
  rewardForCorrectVote: 5,
  instantRestorationCost: 0,
  availableAmountOfRecoveries: 2,
  readableRarity: 'Common',
  isBlockedForTransaction: false,
  isFreezed: false,
  isLive: false,
  isBlockedForEvent: false,
  isHidden: false,
  isOnEvent: false,
  isOnAuction: false,
  frozenUntil: new Date(),
  freezePeriod: 0,
};

const nftWithRepairState: Nft = { ...nftWithoutStates, livesRemaining: 1 };
const nftBlockedForEvent: Nft = { ...nftWithoutStates, isBlockedForEvent: true };
const nftBlockedForEventAndLive: Nft = { ...nftBlockedForEvent, isLive: true };
const nftFreezed: Nft = { ...nftWithoutStates, isFreezed: true };
const nftFreezedAndRepair: Nft = { ...nftFreezed, livesRemaining: 1 };
const nftAvailableForMerge: Nft = { ...nftWithoutStates, winStreak: 10 };
const nftAvailableForMergeAndRepair: Nft = { ...nftAvailableForMerge, livesRemaining: 1 };

export const CardsTab = () => {
  const first = useSwitcher(false);
  const second = useSwitcher(false);

  const { openSnackbar } = useSnackbar();

  const handleOpenFirst = () => {
    first.switchOn();

    setTimeout(() => {
      openSnackbar({ type: 'info', message: 'Transaction is sucessfull' });
      first.switchOff();
    }, 2000);
  };

  const handleOpenSecond = () => {
    second.switchOn();

    setTimeout(() => {
      openSnackbar({ type: 'error', message: wrapTransactionMessage('Transaction is failed') });
      second.switchOff();
    }, 2000);
  };

  return (
    <Box sx={{ 'padding': 24 / 8, '& > *': { marginBottom: 16 / 8 } }}>
      <Box display="flex" gap={2}>
        <NftCard nft={nftWithoutStates} />
        <NftCard nft={nftWithRepairState} />
        <NftCard nft={nftBlockedForEvent} />
        <NftCard nft={nftBlockedForEventAndLive} />
        <NftCard nft={nftFreezed} />
        <NftCard nft={nftFreezedAndRepair} />
        <NftCard nft={nftAvailableForMerge} />
        <NftCard nft={nftAvailableForMergeAndRepair} />
      </Box>

      <Divider />

      <Box display="flex" gap={2}>
        {Object.keys(NFT_CARD_SIZE_VALUES)
          .reverse()
          .map((size) => (
            <Stack spacing={12 / 8} alignItems="center" justifyContent="flex-start" key={size}>
              <span>{size}</span>
              <NftCard size={size as any} nft={nftWithoutStates} />
            </Stack>
          ))}
      </Box>

      <Divider />

      <Box display="flex" gap={2} justifyContent="flex-start">
        <Button onClick={handleOpenFirst}>call sucessfull transaction</Button>
        <Button onClick={handleOpenSecond}>call failed transaction</Button>
        <Button onClick={first.switchOn}>just show transaction pop up</Button>
      </Box>

      <TransactionStatusDialog
        open={first.value}
        message="Mint transaction"
        onClose={first.switchOff}
      />
      <TransactionStatusDialog
        open={second.value}
        message="Mint transaction"
        onClose={second.switchOff}
      />
    </Box>
  );
};

import { customScrollbar, styled } from '~shared/lib/styles';
import { DateLabel, Stack, dateLabelClasses } from '~shared/ui';

export const EventContentInnerContainer = styled(Stack, {
  name: 'EventDialogContent',
  slot: 'eventContentInnerContainer',
})(({ theme }) => ({
  flex: 1,
  padding: '30px',
  position: 'relative',

  [theme.breakpoints.down('mobile')]: {
    padding: '16px',
  },
}));

export const EventTabsContainer = styled(Stack, {
  name: 'EventDialogContent',
  slot: 'eventTabsContainer',
})(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey.A10}`,

  ['& div']: {
    borderBottom: 'none',
  },
}));

export const EventContentMobileInnerContainer = styled('div', {
  name: 'EventDialogContent',
  slot: 'eventContentInnerContainer',
})(({ theme }) => ({
  flex: 1,
  paddingLeft: 16,
  paddingRight: 16,
  position: 'relative',
  overflowY: 'auto',

  [theme.breakpoints.down('mobile')]: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '146px',

    ...customScrollbar,
  },
}));

export const EventTabsMobileContainer = styled(Stack, {
  name: 'EventDialogContent',
  slot: 'eventTabsContainer',
})(() => ({
  alignItems: 'center',

  padding: '8px 16px 0 16px',
  margin: '0 -16px',
}));

export const EventMobileDateLabel = styled(DateLabel)(({ theme }) => ({
  [`&.${dateLabelClasses.primary}`]: {
    background: theme.palette.grey.B40,
  },
}));

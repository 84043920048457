import { Address } from '@ton/core';
import { useTonAddress } from '@tonconnect/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useMediaQuery, useSnackbar } from '~shared/lib/hooks';
import { getAvatarAbsoluteUri } from '~shared/lib/utils';
import { useTheme } from '~shared/lib/styles';

import { useAuctionAcceptBetTimer, useAuctionModel } from '~features/auction';

// todo: import
import { validationSchema } from '~features/auction/model/config';

import { AuctionPlaceBitInitialPriceForm } from '~features/auction/model/types';

import { Avatar, BaseTextField, Button, Stack, Typography } from '~shared/ui';

import { FirstBid, YourCardSold } from './StateForBid';

import { AuctionCardDetailsBidsProps } from './types';

export const AuctionCardDetailsBids: FC<AuctionCardDetailsBidsProps> = ({ card, onClose }) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();
  const { palette } = useTheme();

  const account = useTonAddress(true);
  const { openSnackbar } = useSnackbar();

  const { handlePlaceBid } = useAuctionModel();

  const form = useForm<AuctionPlaceBitInitialPriceForm>({
    resolver: yupResolver(validationSchema),
  });

  const bet = card.bestBet;
  const isViewerCard = Address.normalize(account) === card.creator;
  const { accepted } = useAuctionAcceptBetTimer(new Date(card.betsAcceptedUntil));

  const handleSubmit = form.handleSubmit(async ({ price }: AuctionPlaceBitInitialPriceForm) => {
    if (!account) {
      return;
    }

    const largestBetPrice = card.bestBet;
    const currentBetPrice = Number(price);
    const reservePrice = card.startingPrice;
    const currency = card.currency;
    const lotId = card.lotId;

    const isEnough = currentBetPrice > reservePrice && currentBetPrice > largestBetPrice;

    if (!isEnough) {
      const minimumBidPrice = (largestBetPrice === 0 ? reservePrice : largestBetPrice) + 1;

      openSnackbar({
        type: 'error',
        message: `You should bid at least ${minimumBidPrice} ${currency}`,
      });

      return;
    }

    try {
      await handlePlaceBid(lotId, currentBetPrice, currency);

      onClose();
    } catch {}
  });

  return (
    <Stack spacing={20 / 8}>
      <Stack flex={1}>
        {card.bestBet !== 0 && card.bidHistory && (
          <Stack spacing={20 / 8}>
            <Typography variant="h2">Bids</Typography>
            {card.bidHistory.length === 1 && isViewerCard && accepted && <FirstBid />}
            {!accepted && isViewerCard && (
              <YourCardSold
                lastPrice={card.bidHistory[0].bet}
                currency={card.currency}
                nickName={card.bidHistory[0].bettorNickname}
              />
            )}
            {card.bidHistory.map((value, idx) => (
              <Stack direction="row" justifyContent="space-between" alignItems="center" key={idx}>
                <Stack direction="row" spacing={10 / 8}>
                  <Avatar src={getAvatarAbsoluteUri(value.bettorAvatar)} size={24} />
                  <Typography color="secondary" sx={{ fontSize: '13px' }}>
                    {value.bettorNickname}
                  </Typography>
                </Stack>
                <Typography variant="h3" sx={{ fontSize: '14px' }}>
                  {value.bet} {card.currency}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
        {card.bestBet === 0 && accepted && (
          <Stack height="100%" justifyContent="center" mt={isMobile ? '60px' : 0}>
            <Stack spacing={6 / 8}>
              <Typography variant="h3" sx={{ fontSize: '16px' }} textAlign="center">
                {t('Marketplace.noBidsYet')}
              </Typography>
              <Typography color="secondary" sx={{ fontSize: '13px' }} textAlign="center">
                {t('Marketplace.nobodyMadeBidYet')}
              </Typography>
            </Stack>
          </Stack>
        )}
        {card.bestBet === 0 && !accepted && isViewerCard && (
          <Stack
            height="100%"
            justifyContent="center"
            alignItems="center"
            mt={isMobile ? '60px' : 0}
          >
            <Stack spacing={6 / 8}>
              <Typography variant="h3" sx={{ fontSize: '16px' }} textAlign="center">
                {t('Marketplace.noBids')}
              </Typography>
              <Typography
                color="secondary"
                sx={{ fontSize: '13px' }}
                textAlign="center"
                maxWidth="252px"
              >
                {t('Marketplace.sorryYouDontHaveAnyBids')}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
      {accepted && (
        <FormProvider {...form}>
          <Stack
            alignItems="flex-start"
            onSubmit={handleSubmit}
            component="form"
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gap={14 / 8}
            pr="10px"
            sx={
              isMobile
                ? {
                    position: 'fixed',
                    width: '100%',
                    left: 0,
                    bottom: '65px',
                    padding: '14px 16px',
                    background: palette.paper.secondary,
                  }
                : undefined
            }
          >
            {card.creator !== Address.normalize(account) &&
              accepted &&
              Address.normalize(account) !== card.creator && (
                <>
                  <BaseTextField
                    type="number"
                    name="price"
                    endAdornment={card.currency}
                    placeholder={`${bet ? bet + 1 : card.startingPrice + 1}`}
                  />
                  <Button type="submit">{t('Marketplace.makeBid')}</Button>
                </>
              )}
          </Stack>
        </FormProvider>
      )}
    </Stack>
  );
};

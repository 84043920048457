import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui/layout';

export const HeaderRoot = styled('header', { name: 'Header' })({});

export const HeaderLineContainer = styled(Box, { name: 'Header', slot: 'lineContainer' })(
  ({ theme }) => ({
    height: 70,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    background: theme.palette.paper.secondary,
    justifyContent: 'space-between',
    overflow: 'hidden',

    paddingLeft: 16,
    paddingRight: 16,

    ['& > *:not(:first-child)']: {
      marginLeft: 24,
    },

    [theme.breakpoints.down('sm')]: {
      height: 54,
    },
  })
);

export const HeaderLineEnd = styled(Box, { name: 'Header', slot: 'lineEnd' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,

  ['& > *:not(:first-child)']: {
    marginLeft: 24,

    [theme.breakpoints.down('sm')]: {
      marginLeft: 6,
      // ['& > .Pill-append']: {
      //   display: 'none',
      // },
    },
  },
}));

export const HeaderLogoWrapper = styled(Box, { name: 'Header', slot: 'logoWrapper' })({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  flexShrink: 0,
  flexBasis: 80,
});

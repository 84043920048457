import { FC, Fragment, useState } from 'react';
import { VirtuosoGrid } from 'react-virtuoso';

import { useMediaQuery, useScrollPosition } from '~shared/lib/hooks';

import { NftCardDrag, NftCardShimmer, useNftPreviewInfo } from '~entities/nft';

import { useMergeModel } from '~features/nft';

import { useIsAvailableToSelect, useViewerWalletModel } from '../../model';

import {
  ViewerWalletListGrid,
  ViewerWalletListPaddingSpace,
  ViewerWalletListShimmerContainer,
  ViewerWalletListTopShadow,
  ViewerWalletListVirtuosoGridScroller,
} from './styled';

import { ViewerWalletListProps } from './types';

export const ViewerWalletList: FC<ViewerWalletListProps> = ({ loading }) => {
  const { isMobile } = useMediaQuery();

  const { atTop, toggle } = useScrollPosition();

  const { isAvailableToSelect } = useIsAvailableToSelect();
  const { isMergeAvailable } = useMergeModel();
  const { closePreview } = useNftPreviewInfo();
  const { isCallCardSelected, isCardSelected, onNftClick, getNftsByRarity, rarityFilters } =
    useViewerWalletModel();

  const nfts = getNftsByRarity(rarityFilters).filter((nft: any) => !nft.isOnTournament);

  const hasCards = nfts.length !== 0;
  const cardSize = isMobile ? 'lg' : 'md';

  const renderNftByIndex = (index: number) => {
    const nft = nfts[index];

    const isSelected = isCardSelected(nft);
    const isAvailableForSelection = isAvailableToSelect(nft);

    return (
      <NftCardDrag
        nft={nft}
        size={cardSize}
        locked={nft.isHidden}
        onClick={onNftClick(nft, isMobile)}
        selectionMode={isCallCardSelected ? isSelected : isSelected || isAvailableForSelection}
        checked={isSelected}
        location="wallet"
      />
    );
  };

  const handleIsScrolling = (isScrolling: boolean) => {
    if (isScrolling) {
      closePreview();
    }
  };

  if (!loading && hasCards) {
    return (
      <Fragment>
        <ViewerWalletListTopShadow show={!atTop} />
        <VirtuosoGrid
          totalCount={nfts.length}
          itemContent={renderNftByIndex}
          isScrolling={handleIsScrolling}
          atTopStateChange={toggle}
          components={{
            List: ViewerWalletListGrid,
            Scroller: ViewerWalletListVirtuosoGridScroller,
            // handle MergeCards button margins
            Header: ViewerWalletListPaddingSpace,
            Footer: ViewerWalletListPaddingSpace,
          }}
          // conflicted with EventDialog
          tabIndex={undefined}
        />
      </Fragment>
    );
  }

  if (loading) {
    return (
      <ViewerWalletListShimmerContainer pt={isMergeAvailable ? 0 : 16 / 8}>
        {[...Array(9)].map((_, key) => (
          <NftCardShimmer key={key} size={cardSize} />
        ))}
      </ViewerWalletListShimmerContainer>
    );
  }

  return null;
};

import axios from 'axios';

import { paramsSerializerWithArray } from '~shared/lib/utils';

export const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ADDRESS,
  paramsSerializer: paramsSerializerWithArray,
  withCredentials: true,
});

export const tonApiInstance = axios.create({
  baseURL: process.env.REACT_APP_TON_BACKEND_ADRESS,
  paramsSerializer: paramsSerializerWithArray,
  withCredentials: true,
});

import { FC } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Box, CircularProgress, ContentPlaceholder, Icon, Link } from '~shared/ui';
import { routes } from '~shared/config';

import {
  NftCardInfoMobile,
  getNftByTokenId,
  useNftCardModel,
  useQueryViewerNfts,
} from '~entities/nft';

export const WalletCardPage: FC = () => {
  const { tokenId } = useParams();
  const navigate = useNavigate();

  const { isLoading } = useQueryViewerNfts();
  const { nfts } = useNftCardModel();

  const nft = getNftByTokenId(nfts, tokenId!);

  // todo: skeleton
  if (isLoading) {
    return (
      <Box flex={1} display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={48} />
      </Box>
    );
  }

  if (!nft) {
    return (
      <ContentPlaceholder
        heading="Nothing found"
        message={
          <Link color="primary" to={routes.index}>
            Go home
            <Icon name="chevron-right" size={16} />
          </Link>
        }
      />
    );
  }

  const handleNavigation = () => {
    navigate(-1);
  };

  return <NftCardInfoMobile nft={nft} onBack={handleNavigation} />;
};

import { useMedia as media } from 'react-use';
import { useMemo } from 'react';

import { useTheme } from '~shared/lib/styles';

import { Breakpoint, Theme, breakpointsKeys } from '../styles';

interface UseMediaQuery {
  (queryInput: string | Breakpoint): boolean;
  (queryInput: number): boolean;
  (queryInput: (theme: Theme) => string): boolean;

  (): {
    isMobile: boolean;
    isDesktop: boolean;
    isWide: boolean;
  };
}

export const useMediaQuery = ((input) => {
  const theme = useTheme();

  const query = useMemo(() => {
    switch (typeof input) {
      case 'string':
        return isBreakpointKey(input) ? theme.breakpoints.down(input) : input;
      case 'function':
        return input(theme);
      case 'number':
        return theme.breakpoints.down(input);
    }
  }, [input, theme]);

  if (input) {
    return media(query.replace(/^@media( ?)/m, ''));
  }

  const isMobile = media(theme.breakpoints.down('mobile').replace(/^@media( ?)/m, ''));

  return {
    isMobile: isMobile,
    isDesktop: !isMobile,
  };
}) as UseMediaQuery;

const isBreakpointKey = (breakpoint: string): breakpoint is Breakpoint => {
  return breakpointsKeys.includes(breakpoint);
};

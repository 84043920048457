import { ButtonIcon, buttonIconClasses } from '~shared/ui';
import { styled } from '~shared/lib/styles';

export const TournamentDescriptionContainer = styled('div')(() => ({
  paddingTop: 60,
}));

interface TournamentInfoPlayersValueProps {
  $limitReached: boolean;
}

export const TournamentInfoPlayersValue = styled('div')<TournamentInfoPlayersValueProps>(
  ({ theme, $limitReached }) => ({
    display: 'flex',
    alignItems: 'center',

    fontSize: 15,
    fontWeight: 600,
    lineHeight: 18 / 15,
    letterSpacing: -0.2,

    color: $limitReached ? theme.palette.error.main : theme.palette.text.primary,
  })
);

export const TournamentBackButton = styled(ButtonIcon)(({ theme }) => ({
  [`&.${buttonIconClasses.square}`]: {
    background: theme.palette.grey.B50,
    padding: 4,
    // borderRadius: 999,
  },
}));

export const TournamentHeading = styled('div')(({ theme }) => ({
  fontSize: 24,
  fontWeight: 600,
  lineHeight: 25 / 24,
  letterSpacing: -0.2,

  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('mobile')]: {
    fontSize: 22,
  },

  [`& > .${buttonIconClasses.root}`]: {
    marginLeft: 4,
    color: theme.palette.grey.A50,
  },
}));

export const TournamentHeadingMobileLabel = styled('div')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 14 / 13,
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
}));

import { styled } from '~shared/lib/styles';

export const cardStatusBadgeClasses = {
  sm: 'TournamentCardStatusBadge-sm',
  md: 'TournamentCardStatusBadge-md',
};

const CardStatusBadge = styled('div')(() => ({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: 14 / 12,
  borderRadius: 8,

  [`&.${cardStatusBadgeClasses.sm}`]: {
    padding: '3px 6px',
    fontSize: 11,
  },

  [`&.${cardStatusBadgeClasses.md}`]: {
    padding: '9px 10px',
  },
}));

export const CardDatesBadge = styled(CardStatusBadge)(({ theme }) => ({
  background: theme.palette.grey.B50,
}));

export const CardStatusActiveBadge = styled(CardStatusBadge)(({ theme }) => ({
  background: theme.palette.violet.main,
}));

export const CardStatusSoonBadge = styled(CardStatusBadge)(({ theme }) => ({
  background: theme.palette.error.main,
}));

export const CardStatusPassedBadge = styled(CardStatusBadge)(({ theme }) => ({
  background: theme.palette.grey.B90,
}));

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '~shared/ui';

import { MergeCards } from '~features/nft';

import { useViewerWalletModel } from '../../model';

import { ViewerWalletTabs } from '../ViewerWalletTabs';

import { ViewerWalletHeaderRoot } from './styled';

export const ViewerWalletHeader: FC = () => {
  const { t } = useTranslation();

  const { selectionModeType, isSelectionMode } = useViewerWalletModel();

  if (isSelectionMode) {
    switch (selectionModeType) {
      case 'bet':
        return (
          <ViewerWalletHeaderRoot>
            <Typography variant="h2">{t('Sidebar.myCards')}</Typography>

            <ViewerWalletTabs />
          </ViewerWalletHeaderRoot>
        );
      case 'merge':
        return (
          <ViewerWalletHeaderRoot>
            <Typography variant="h2">{t('Sidebar.selectCardsToMerge')}</Typography>
          </ViewerWalletHeaderRoot>
        );
      case 'tournament':
        return (
          <ViewerWalletHeaderRoot>
            <Typography variant="h2">{t('Sidebar.selectCardsToJoinTournament')}</Typography>
          </ViewerWalletHeaderRoot>
        );
    }
  }

  return (
    <ViewerWalletHeaderRoot>
      <Typography variant="h2">{t('Sidebar.myCards')}</Typography>

      <MergeCards />
    </ViewerWalletHeaderRoot>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '~shared/lib/hooks';
import { Button, DialogTitle, DragCell, Icon, Stack } from '~shared/ui';

import {
  NFT_RARITY_TO_READABLE_RARITY_NAME_MAP,
  NftCardDrag,
  NftDragItemType,
} from '~entities/nft';

import { useJoinTournamentDrop, useJoinTournamentModel } from '../../model';

import { JoinTournamentForm } from './JoinTournamentForm';

import { DialogCaption, DragCellContainer, JoinTournamentDialogRoot } from './styled';

import { JoinTournamentDialogProps } from './types';

export const JoinTournamentDialog: FC<JoinTournamentDialogProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const {
    tournament,
    dialogOpen,
    closeJoinTournamentDialog,
    nftsToJoin,
    clearAllCards,
    openCardsSelection,
  } = useJoinTournamentModel();

  const { dropRef, isHighlightError, isHighlightInfo } = useJoinTournamentDrop();

  if (!tournament) {
    return null;
  }

  const { joinCardsQuantity, tournamentRarity } = tournament;

  return (
    <JoinTournamentDialogRoot
      {...props}
      open={dialogOpen}
      onClose={closeJoinTournamentDialog}
      width={600}
      slotProps={{ paper: { ref: dropRef } }}
    >
      <DialogTitle>{t('Tournaments.joinTheTournament')}</DialogTitle>

      <Stack spacing={(isMobile ? 24 : 32) / 8} alignItems="center">
        <DialogCaption>
          {t('Tournaments.addCardsToThisTournaments', {
            quantity: joinCardsQuantity,
            rarity: t(NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[tournamentRarity]),
          })}
        </DialogCaption>

        <Stack spacing={16 / 8} alignSelf="stretch" alignItems="center">
          <DragCellContainer $count={joinCardsQuantity}>
            {Array.from(Array(joinCardsQuantity)).map((element, index) => {
              const isFirstCellWithoutPlacedNft = nftsToJoin.length === index;

              return (
                <DragCell
                  key={index}
                  isHighlighted={isFirstCellWithoutPlacedNft && isHighlightInfo}
                  isHighlightedError={isFirstCellWithoutPlacedNft && isHighlightError}
                  onClick={openCardsSelection(isMobile)}
                >
                  {nftsToJoin[index] ? (
                    <NftCardDrag nft={nftsToJoin[index]} dragKey={NftDragItemType.Leave} />
                  ) : (
                    <Icon opacity={0.75} name="plus" />
                  )}
                </DragCell>
              );
            })}
          </DragCellContainer>

          <Button variant="text" onClick={clearAllCards}>
            {t('Tournaments.clearAllCards')}
          </Button>
        </Stack>

        <JoinTournamentForm tournament={tournament} onClose={closeJoinTournamentDialog} />
      </Stack>
    </JoinTournamentDialogRoot>
  );
};

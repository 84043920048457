import { styled } from '~shared/lib/styles';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '10px',

  [theme.breakpoints.down('mobile')]: {
    position: 'fixed',
    width: '100%',
    left: 0,
    bottom: '64px',
    padding: '14px 16px',
    background: theme.palette.paper.secondary,
    zIndex: theme.zIndex.modalOverlap,
  },
}));

export const ButtonsContainer = styled('div', {
  name: 'EventDialogActions',
  slot: 'buttonsContainer',
})(({ theme }) => ({
  display: 'flex',
  gap: 12,

  [theme.breakpoints.down('mobile')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

type CoeffProps = {
  $color: string;
};

export const Coeff = styled('div')(({ $color }: CoeffProps) => {
  const colorMap: Record<string, string> = {
    red: 'rgba(204, 40, 79, 1)',
    green: 'rgba(89, 146, 54, 1)',
    blue: 'rgba(41, 137, 227, 1)',
  };

  return {
    border: '1px solid rgba(255, 255, 255, 0.2)',
    padding: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    justifySelf: 'center',
    backgroundColor: colorMap[$color],

    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '11.74px',
    textTransform: 'uppercase',
  };
});

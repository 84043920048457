import { styled } from '~shared/lib/styles';

export const NotificationActionRoot = styled('div')(({ theme, onClick, onClickCapture }) => ({
  color: theme.palette.primary.light,
  cursor: 'default',

  fontSize: 13,
  fontWeight: 400,
  lineHeight: 16 / 13,

  ...((onClick || onClickCapture) && {
    ['&:hover']: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  }),
}));

import {
  ApiGetViewerDashboardResponseMappedData,
  ApiGetViewerDashboardResponseRawData,
} from '~shared/api';

export const mapGetViewerDashboardResponse = (
  data: ApiGetViewerDashboardResponseRawData
): ApiGetViewerDashboardResponseMappedData => ({
  ...data,
  creationDate: data.creation_date,
  callsTotal: data.calls_total,
  battlesTotal: data.battles_total,
  battles: data.battles,
  calls: data.calls,
});

import { FC } from 'react';

import { Box } from '~shared/ui';
import { Examples } from '~entities/notifications/ui/public/notifications/examples';

export const NotificationsTab: FC = () => {
  return (
    <Box
      display="flex"
      m={-12 / 8}
      sx={{ '& > *': { margin: 12 / 8, maxWidth: 400, minWidth: 400, alignSelf: 'flex-start' } }}
      flexWrap="wrap"
    >
      <Examples />
    </Box>
  );
};

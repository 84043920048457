import { FC, PropsWithChildren } from 'react';

import { EventCardsHorizontalList } from '~features/event-dialog/ui/EventDialog/EventCardsHorizontalList';

import { useMediaQuery } from '~shared/lib/hooks';

import { MobileContainer } from './styled';

import { EventMultipleCardsContainerProps } from './types';

export const EventMultipleCardsContainer: FC<
  PropsWithChildren<EventMultipleCardsContainerProps>
> = ({ children, isEventNotPending }) => {
  const { isMobile } = useMediaQuery();

  if (isMobile) {
    return <MobileContainer>{children}</MobileContainer>;
  }

  return (
    <EventCardsHorizontalList centeredSlides={isEventNotPending}>
      {children}
    </EventCardsHorizontalList>
  );
};

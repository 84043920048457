import { FC, ReactNode } from 'react';

import { ApiCommonSportEnum } from '~shared/api';
import { Stack } from '~shared/ui';

import {
  BattleEventTab,
  BattleEventTypeTab,
  BattleListHeading,
  BattleView,
  useBattleListSettings,
} from '~entities/battle';

import { ViewerBattlesList } from '~widgets/viewer-battles-list';
import { MultipleBattleList } from '~widgets/multiple-battle-list';
import { ViewerCallsList } from '~widgets/viewer-calls-list';
import { CallsList } from '~widgets/calls-list';

import { CommonMultipleEventsListProps } from './types';

export const CommonMultipleEventsList: FC<CommonMultipleEventsListProps> = ({
  sport = Object.values(ApiCommonSportEnum),
  label,
}) => {
  const {
    /***/
    eventTypeTab,
    onChangeEventTypeTab,
    view,
    onChangeView,
    onChangeEventTab,
    eventTab,
  } = useBattleListSettings();

  const tabs: Record<BattleEventTypeTab, Record<BattleEventTab, Record<BattleView, ReactNode>>> = {
    [BattleEventTypeTab.Battles]: {
      [BattleEventTab.AllEvents]: {
        [BattleView.List]: <MultipleBattleList sport={sport} type="list" />,
        [BattleView.Grid]: <MultipleBattleList sport={sport} type="grid" />,
      },

      [BattleEventTab.MyEvents]: {
        [BattleView.List]: <ViewerBattlesList sport={sport} divider />,
        [BattleView.Grid]: <ViewerBattlesList sport={sport} divider />,
      },
    },

    // [BattleEventTypeTab.Calls]: {
    //   [BattleEventTab.AllEvents]: {
    //     [BattleView.List]: <CallsList sport={sport} />,
    //     [BattleView.Grid]: <CallsList sport={sport} />,
    //   },
    // [BattleEventTab.MyEvents]: {
    //   [BattleView.List]: <ViewerCallsList sport={sport} divider />,
    //   [BattleView.Grid]: <ViewerCallsList sport={sport} divider />,
    // },
    //},
  };

  return (
    <Stack flex={1} spacing={12 / 8}>
      <BattleListHeading
        eventTypeTab={eventTypeTab}
        onChangeEventTypeTab={onChangeEventTypeTab}
        eventTab={eventTab}
        onChangeEventTab={onChangeEventTab}
        view={view}
        onChangeView={onChangeView}
        viewDisabled={
          /*eventTypeTab === BattleEventTypeTab.Calls ||*/ eventTab === BattleEventTab.MyEvents
        }
        label={label}
      />

      {tabs[eventTypeTab][eventTab][view]}
    </Stack>
  );
};

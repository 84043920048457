import { styled } from '~shared/lib/styles';
import { Box, Stack, Typography } from '~shared/ui';

export const NftCardInfoRoot = styled(Stack, { name: 'NftCardInfo', slot: 'root' })(
  ({ theme }) => ({
    backgroundColor: theme.palette.paper.main,
    boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 1)',
    borderRadius: '18px',
    minWidth: '320px',
    marginRight: '5px',
    position: 'relative',
    bottom: '5px',
    paddingBottom: '22px',
  })
);

/**
 * Mobile
 */
export const NftCardInfoMobileContainer = styled('div')(({ theme }) => ({
  background: theme.palette.paper.semi,
  padding: '14px 16px',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

export const NftCardInfoMobileHeading = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 18 / 16,
  letterSpacing: -0.2,
}));

export const NftCardInfoMobileActionsContainer = styled(Box)(({ theme }) => ({
  margin: '20px -16px -14px -16px',
  padding: '16px 14px',
  background: theme.palette.paper.dark,
}));

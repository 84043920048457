import { Address, Cell, Dictionary, toNano } from '@ton/core';
import { useTonAddress } from '@tonconnect/ui-react';
import { useNavigate } from 'react-router';
import { SyntheticEvent, useMemo } from 'react';

import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';

import { useTranslation } from 'react-i18next';

import {
  useCreateSwitcher,
  useDispatch,
  useSearchParamsState,
  // useSnackbar,
} from '~shared/lib/hooks';
import { parseEventIdToBlockchainFormat, sleep } from '~shared/lib/utils';
import {
  ApiBattleBroadcastProvider,
  ApiGetBetsBetMappedData,
  ApiGetCallsMappedData,
  BattleResult,
  Nft,
  NftRelatedBattle,
  NftRelatedCall,
  NftRelatedEvent,
  PartialNft,
  RawDifficulty,
  getBets,
  getCalls,
  postCloseBetTon,
  postMakeBetTon,
} from '~shared/api';
import { routes } from '~shared/config';

import {
  nftActions,
  useNftCardModel,
  useNftWithRelatedBattlesSelector,
  useNftWithRelatedCallsSelector,
} from '~entities/nft';
import { useProfileModel } from '~entities/profile';
import { useViewerModel } from '~entities/viewer';

// todo: fsd
// import { useEventTransactions } from '~features/event-dialog';

import {
  signGaslessTxMessage,
  useCallGasless,
  useConnect,
  useWriteContract,
} from '~entities/wallet';

import { ApiFreeMakeBetRequest, freeMakeBet } from '~shared/api/consumables';
import { Arena__factory } from '~shared/contracts';

import { BattleEventTab, EVENT_TAB_SEARCH_PARAM_KEY } from '~entities/battle';
import { useInventoryModel } from '~widgets/inventory';

import {
  getCallParticipant,
  getEventStatus,
  isCorrectChoice,
  isEventPassed,
  isLessOrEqualFiveMinutesLeft,
  mapEventStatistics,
} from '../lib';

import { eventActions } from './slice';
import { useEventSelector } from './selectors';
import { EventBetType, EventState, EventStateEvent, EventStatus } from './types';
import { EventDialogTab } from './config';
import { useEventValidateCard } from './hooks';

export const useEventModel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { validateMaxCardsLimit } = useEventValidateCard();

  const [eventTab] = useSearchParamsState<BattleEventTab>(
    EVENT_TAB_SEARCH_PARAM_KEY,
    BattleEventTab.AllEvents
  );

  const account = useTonAddress(true);
  const { nfts, setSelectionMode } = useNftCardModel();
  const { refetch: refetchConsumables } = useInventoryModel();
  const profile = useProfileModel();
  const viewer = useViewerModel();
  const isConnected = !!account;

  const [, , omitEventIdParam] = useSearchParamsState<string>('eventId', '');

  const {
    closingConfirmationDialogOpen,

    nftWithWinstreakDialog,

    voteChoiceDialog,

    user,

    tab,

    event,
    eventStatus,
    call,
    battle,

    result,

    open,
    isCall,
    isLoading,
  } = useEventSelector();

  const broadcastId = event?.broadcastId;
  const broadcastProvider = event?.broadcastProvider ?? ApiBattleBroadcastProvider.Unknown;

  const eventBroadcastAvailable = useMemo(() => {
    switch (broadcastProvider) {
      case ApiBattleBroadcastProvider.SportRadar:
      case ApiBattleBroadcastProvider.Twitch:
        return Boolean(broadcastId);

      case ApiBattleBroadcastProvider.Unknown:
      default:
        return false;
    }
  }, [broadcastId, broadcastProvider]);

  const choice = tab === EventDialogTab.Call ? call.choice : battle.choice;

  const isViewer = user?.nickname === viewer?.nickname;

  const isViewerEqualToUser = user?.nickname === viewer.nickname;

  const isUserBattleParticipant = battle.isViewMode && battle?.cards.length > 0;
  const isUserCallParticipant = useMemo(() => {
    const currentUserAsCallParticipant = [call.callCreator, call.callAcceptor].find(
      (participant) => participant?.nickname === user?.nickname
    );

    return currentUserAsCallParticipant && !!currentUserAsCallParticipant.card;
  }, [call.callAcceptor, call.callCreator, user?.nickname]);

  const isViewerCallParticipant = isViewerEqualToUser && isUserCallParticipant;

  const isViewerBattle = isUserBattleParticipant && isViewerEqualToUser;

  const isViewerCall = isViewerEqualToUser && isViewerCallParticipant;

  const correctChoice = isCorrectChoice(result, choice);

  const { openConnectWalletWarnDialog } = useConnect();

  const nftModel = useNftCardModel();

  const betType: 'call' | 'battle' = tab === EventDialogTab.Call ? 'call' : 'battle';

  // @ts-ignore
  const noBattleForEvent = eventStatus !== EventStatus.Pending && battle.cards.length === 0;
  // @ts-ignore
  const noCallForEvent = eventStatus !== EventStatus.Pending && !isUserCallParticipant;

  // todo: Add logic to query all data for event dialog
  // const { isLoading } = useQuery(
  //   ['event', eventIdParam],
  //   () => {
  //     getBattlesByIds({ ids: [eventIdParam] }).then((data) => {
  //       if (data[0]) {
  //         dispatch(eventActions.set({ event: data[0] }));
  //       }
  //     });
  //   },
  //   { enabled: Boolean(eventIdParam) ? !event || event.id !== eventIdParam : false }
  // );

  const nftsWithRelatedBattle = useNftWithRelatedBattlesSelector();
  const nftsWithRelatedCall = useNftWithRelatedCallsSelector();

  const openEvent = (event: EventStateEvent, params?: Partial<Omit<EventState, 'event'>>) => {
    dispatch(eventActions.reset());

    const { battle, user } = params ?? {};

    const cards = battle?.cards || [];
    const additionalCards = battle?.additionalCards || [];

    const possibleNftIds = [...cards, ...additionalCards].map((card) => card.token_id);

    if (possibleNftIds.length) {
      nftModel.onSetNftHidden({ nftIds: possibleNftIds, isHidden: true });
    }

    const viewerAsUser = {
      nickname: viewer.nickname,
      avatar: viewer.avatar?.src,
    };

    dispatch(
      eventActions.set({
        event,
        ...params,
        user: user || viewerAsUser,
        eventStatus: getEventStatus(event.date, params?.result),
        open: true,
      } as Partial<Omit<EventState, 'event'>>)
    );

    // todo: uncomment
    // setEventIdParam(event.id);
  };

  const openActiveEvent = (params?: Partial<EventState>) => {
    if (event) {
      dispatch(eventActions.set({ ...params, open: true }));
      // todo: uncomment
      // setEventIdParam(event.id);
    }
  };

  const getCallDataToOpenEvent = ({
    call: { callId, firstParticipant, secondParticipant, rarity, ...event },
    choice,
    cards,
    isViewMode,
  }: {
    call: ApiGetCallsMappedData;
    choice?: BattleResult | null;
    cards?: any;
    isViewMode?: boolean;
  }) => {
    const participants = [firstParticipant, secondParticipant];
    const isViewerCall = participants.some((p) => p?.address === viewer.wallet);
    const isPassedEvent = isEventPassed(event.result);

    const viewerParticipant =
      [firstParticipant, secondParticipant].find((participant) => {
        return participant?.address === viewer.wallet;
      }) ?? null;

    const possibleViewerAcceptor =
      isViewerCall || isPassedEvent
        ? null
        : { nickname: viewer.nickname, avatar: viewer.avatar?.src ?? '' };

    // todo: add ability to open call event with rewardAmount value (for passed events)

    const viewerCard = nfts.find((nft) => nft.token_id === viewerParticipant?.card);

    return {
      callId,
      callAcceptableRarity: rarity,
      // todo: fix types
      cards: cards || (isViewerCall && viewerCard ? [viewerCard] : []),
      isViewMode: isViewMode || isViewerCall || participants.every((p) => p?.card) || isPassedEvent,
      isViewerCall,
      // todo: fix types
      choice: choice ?? viewerParticipant?.choiceId,
      callCreator: firstParticipant?.card
        ? {
            nickname: firstParticipant?.nickname,
            avatar: firstParticipant?.avatar,
            card: firstParticipant?.card,
            choiceId: firstParticipant?.choiceId,
          }
        : null,
      callAcceptor: secondParticipant?.card
        ? {
            nickname: secondParticipant?.nickname,
            avatar: secondParticipant?.avatar,
            card: secondParticipant?.card,
            choiceId: secondParticipant?.choiceId,
          }
        : possibleViewerAcceptor,
    };
  };

  const getRelatedViewerBattle = (eventId: string) => {
    const nftWithRelatedBattle = nftsWithRelatedBattle.find(
      (nft) => nft.relatedEvent?.id === eventId
    );

    const relatedBattle = nftWithRelatedBattle?.relatedEvent as NftRelatedBattle;

    if (!relatedBattle) {
      return null;
    }

    return relatedBattle;
  };

  const getRelatedBattle = async (address: string, eventId: string) => {
    try {
      const { bets } = await getBets({ address });

      const relatedBattle = bets.find(
        (bet) => bet.eventId === eventId && !bet.isTakenBeforeMatchStarted
      );

      return relatedBattle;
    } catch {}
  };

  const openCallEvent = async (
    { callId, firstParticipant, secondParticipant, rarity, ...event }: ApiGetCallsMappedData,
    params?: Partial<{ choice?: BattleResult; cards: any } & EventState>
  ) => {
    const isPassedEvent = isEventPassed(event.result);

    const firstParticipantAsDefaultUser = {
      nickname: firstParticipant?.nickname!,
      avatar: firstParticipant?.avatar,
      address: firstParticipant?.address!,
    };

    const viewerParticipantAsDefaultUser = {
      nickname: viewer?.nickname,
      avatar: viewer?.avatar?.src,
      address: viewer?.wallet,
    };

    const defaultUser =
      eventTab === BattleEventTab.MyEvents || (!isPassedEvent && !secondParticipant)
        ? viewerParticipantAsDefaultUser
        : firstParticipantAsDefaultUser;

    const resultUser = params?.user || defaultUser;

    const call = getCallDataToOpenEvent({
      call: {
        callId,
        firstParticipant,
        secondParticipant,
        rarity,
        ...event,
      },
      choice: params?.call?.choice,
      cards: params?.call?.cards,
    });

    const isViewerCall = !params?.user || params?.user.nickname === viewer.nickname;

    const userAddress = params?.user?.address || viewer.wallet;

    const relatedBattle =
      isViewerCall && !isEventPassed(event.result)
        ? getRelatedViewerBattle(event.id)
        : await getRelatedBattle(userAddress, event.id);

    // TODO: bring back

    // openEvent(event, {
    //   tab: EventDialogTab.Call,
    //   result: event.result,
    //   // @ts-ignore
    //   // todo: fix types
    //   call,
    //   battle: relatedBattle
    //     ? {
    //         // @ts-ignore
    //         // todo: fix types
    //         cards: relatedBattle.tokenIds
    //           ? // @ts-ignore
    //             relatedBattle.tokenIds.map((tokenId) => ({ token_id: String(tokenId) }))
    //           : // @ts-ignore
    //             relatedBattle.cards,
    //         additionalCards: [],
    //         // @ts-ignore
    //         // todo: fix types
    //         choice: relatedBattle.choiceId || relatedBattle.choice,
    //         isViewMode: true,
    //         // TODO: Add reward received
    //         rewardReceived: null,
    //       }
    //     : undefined,

    //   isCall: true,
    //   user: resultUser,
    // });
  };

  const getViewerRelatedCall = (eventId: string) => {
    const nftWithRelatedCall = nftsWithRelatedCall.find((nft) => nft.relatedEvent?.id === eventId);

    const relatedCall = nftWithRelatedCall?.relatedEvent as NftRelatedCall;

    if (!relatedCall) {
      return null;
    }

    return relatedCall;
  };

  const getRelatedCall = async (userAddress: string, eventId: string) => {
    try {
      const calls = await getCalls({ userAddress });

      const relatedCall = calls.find((call) => call.id === eventId);

      if (!relatedCall) {
        return null;
      }

      return relatedCall;
    } catch {}
  };

  const dropOutCard = (tokenId: string) => {
    onClearEventCards('cards', betType);

    dispatch(eventActions.setNftHidden({ nftIds: [tokenId], isHidden: false }));
  };

  const openBetEvent = async (
    bet: ApiGetBetsBetMappedData,
    params?: Partial<Omit<EventState, 'event' | 'cards'> & { cards: Array<Nft | PartialNft> }>
  ) => {
    const isPassedEvent = isEventPassed(bet.result);

    const relatedBetEvents = nfts
      .map((nft) => nft.relatedEvent)
      .filter((relatedEvent) => relatedEvent?.isCall === false) as NftRelatedBattle[];

    const relatedBattle: NftRelatedBattle | undefined = isPassedEvent
      ? { ...bet, cards: [], choice: bet.choiceId, id: bet.eventId, isCall: false }
      : {
          ...relatedBetEvents.find((relatedEvent) => relatedEvent.id === bet.eventId),
          //@ts-ignore
          bets: bet.bets,
        };

    const isViewerBet = !params?.user || params?.user.nickname === viewer.nickname;

    const userAddress = params?.user?.address || viewer.wallet;

    const relatedCall =
      isViewerBet && !isEventPassed(bet.result)
        ? getViewerRelatedCall(bet.eventId)
        : await getRelatedCall(userAddress, bet.eventId);

    const callParticipant = getCallParticipant({
      firstParticipant: relatedCall?.firstParticipant,
      secondParticipant: relatedCall?.secondParticipant,
      address: userAddress,
    });

    const relatedCallNft = nfts.find((nft) => nft.token_id === callParticipant?.card);

    const call = relatedCall
      ? getCallDataToOpenEvent({
          call: relatedCall,
          choice: callParticipant?.choiceId,
          cards: [relatedCallNft || { token_id: callParticipant?.card }],
        })
      : undefined;

    if (relatedBattle) {
      const cards = (
        isPassedEvent ? bet.tokenIds.map((id) => ({ token_id: id })) : relatedBattle.cards
      ) as Array<Nft>;

      openEvent(relatedBattle, {
        tab: EventDialogTab.Battle,
        result: bet.result,
        // @ts-ignore
        call,
        battle: {
          cards,
          additionalCards: [],
          choice: relatedBattle.choice,
          isViewMode: true,
          rewardReceived: null,
        },
        ...params,
      });
    }
  };

  const openRelatedEvent = (relatedEvent: NftRelatedEvent) => {
    if (relatedEvent.isCall) {
      openCallEvent(relatedEvent);

      return;
    }

    const nftWithRelatedCall = nftsWithRelatedCall.find(
      (nft) => nft.relatedEvent?.id === relatedEvent.id
    );

    const relatedCall = nftWithRelatedCall?.relatedEvent as NftRelatedCall;

    let call: any;

    if (relatedCall) {
      const viewerParticipant =
        [relatedCall.firstParticipant, relatedCall.secondParticipant].find((participant) => {
          return participant?.address === viewer.wallet;
        }) ?? null;

      const callChoiceId = viewerParticipant?.choiceId;

      call = getCallDataToOpenEvent({
        call: relatedCall,
        choice: callChoiceId,
        cards: relatedCall.cards,
      });
    }

    const { cards, choice, ...event } = relatedEvent;

    openEvent(event, {
      tab: EventDialogTab.Battle,
      result: event.result,
      call,
      battle: {
        choice,
        additionalCards: [],
        isViewMode: true,
        cards: cards as Nft[],
        rewardReceived: null,
      },
    });
  };

  const closeEventDialog = () => {
    omitEventIdParam();
    dispatch(eventActions.reset());
    dispatch(nftActions.resetEventDialog());

    dispatch(
      eventActions.set({
        needToRefetch: true,
      })
    );
  };

  // const { takeAllCardsFromEvent } = useEventTransactions(closeEventDialog);

  const onConfirmCloseEvent = () => {
    closeEventDialog();
    closingConfirmationDialog.switchOff();
    dispatch(nftActions.resetEventDialog());
  };

  const closingConfirmationDialog = useCreateSwitcher(closingConfirmationDialogOpen, (value) => {
    dispatch(eventActions.setClosingConfirmationDialogOpen(value));
  });

  const isCallTab = tab === EventDialogTab.Call;
  const isMakeCall = isCallTab && call.callCreator === null && call.callAcceptor === null;
  const isViewMode = isCallTab ? call.isViewMode : battle.isViewMode;

  const cards = isCallTab ? call.cards : battle.cards;
  const isAnyCardsPlaced = cards.length > 0;

  const potentialRewardAmount = useMemo(() => {
    const cards = isCallTab ? call.cards : [...battle.cards, ...battle.additionalCards];

    // TODO: rewrite this part

    const getCoeff = (label?: RawDifficulty) => {
      switch (label) {
        case RawDifficulty.Obvious:
          return 0;
        case RawDifficulty.Easy:
          return 0.5;
        case RawDifficulty.Standart:
          return 1;
        case RawDifficulty.Hard:
          return 1.5;
        case RawDifficulty.Insane:
          return 3;
        default:
          return 1;
      }
    };

    let coef: number;

    switch (battle.choice) {
      case BattleResult.HomeTeamWon: {
        coef = getCoeff(event?.matchDifficulty.home.label);
        break;
      }

      case BattleResult.Draw: {
        coef = getCoeff(event?.matchDifficulty.draw.label);
        break;
      }

      case BattleResult.AwayTeamWon: {
        coef = getCoeff(event?.matchDifficulty.away.label);
        break;
      }

      default:
        coef = 1;
    }

    return cards.reduce((acc, card) => card.rewardForCorrectVote * coef + acc, 0);
  }, [battle.additionalCards, battle.cards, call.cards, isCallTab, battle.choice]);

  const eventStatisticsEntries = useMemo(() => {
    return event ? mapEventStatistics(event) : [];
  }, [event]);

  const onAddCards = (result: BattleResult, isMobile: boolean) => () => {
    if (isMobile) {
      const totalCardsAmount = battle.cards.length + battle.additionalCards.length;

      if (tab === EventDialogTab.Battle && !validateMaxCardsLimit(totalCardsAmount)) {
        return;
      }

      setSelectionMode(true);
      dispatch(eventActions.set({ [betType]: { choice: result }, open: false }));
      navigate(routes.wallet);
    }
  };

  const onTabChange = (_: SyntheticEvent | null, tab: any) => {
    dispatch(eventActions.setTab(tab));
  };

  const onClose = () => {
    const isPlacedCardsToNewBet = !isViewMode && cards.length > 0;
    const isAddedCardsToExistingBet = isViewMode && battle.additionalCards.length > 0;

    const shouldOpenCloseEventConfirmationDialog =
      isPlacedCardsToNewBet || isAddedCardsToExistingBet;

    if (shouldOpenCloseEventConfirmationDialog) {
      closingConfirmationDialog.switchOn();

      return;
    }

    closeEventDialog();
  };

  const { provider } = useWeb3React();

  // const signMakeBetMessage = async (
  //   eventId: string,
  //   tokenIds: Array<string>,
  //   choice: BattleResult
  // ) => {
  //   const signer = provider?.getSigner();

  //   if (!signer) {
  //     return;
  //   }

  //   const address = await signer.getAddress();

  //   const arenaContract = Arena__factory.connect(process.env.REACT_APP_ADDRESS_SK_ARENA, signer);

  //   const gaslessFreeCounter = await arenaContract.gasFreeOpCounter(address);

  //   const types: Array<string> = ['uint256'];
  //   const values: Array<string | BattleResult | BigNumber> = [gaslessFreeCounter];

  //   tokenIds.forEach((tokenId) => {
  //     types.push('uint256', 'uint256', 'uint8');
  //     values.push(eventId, tokenId, choice);
  //   });

  //   const signedMessage = await signGaslessTxMessage({ signer, types, values });

  //   return signedMessage;
  // };

  const { t } = useTranslation();

  const { write: makeBet } = useWriteContract({
    contractName: 'NftProxy',
    method: 'RequestBet',
    transactionName: t('Alerts.votingEvent'),
    successMessage: `${t('Alerts.successfulVote')}`,
    errorMessage: `${t('Errors.votingFailed')}`,
  });

  const { write: closeBet } = useWriteContract({
    contractName: 'Arena',
    method: 'CloseBet',
    transactionName: 'Removing cards from event',
    successMessage: `${t('Alerts.successfulRemove')}`,
    errorMessage: `${t('Errors.removeFailed')}`,
  });

  const gaslessMakeBetCall = useCallGasless<ApiFreeMakeBetRequest>({
    callback: freeMakeBet,
    transactionName: t('Alerts.votingEvent'),
    successMessage: `${t('Alerts.successfulVote')}`,
    errorMessage: `${t('Errors.votingFailed')}`,
  });

  // const gaslessCreateCall = useCallGasless<ApiPostCreateCallRequestData>({
  //   callback: postCreateCall,
  //   transactionName: 'Call for event',
  //   successMessage: `${t('Alerts.successfulCall')}`,
  //   errorMessage: `${t('Errors.callFailed')}`,
  // });

  // const gaslessAcceptCall = useCallGasless<ApiPostAcceptCallRequestData>({
  //   callback: postAcceptCall,
  //   transactionName: 'Accept call',
  //   successMessage: `${t('Alerts.successfulAceptedCall')}`,
  //   errorMessage: `${t('Errors.failedAcccept')}`,
  // });

  const handleSingleBet = async (isGasless: boolean) => {
    if (!event) {
      return;
    }

    const targetCards = isViewMode ? battle.additionalCards : cards;
    const card = targetCards[0];
    const tokenId = card.token_id;
    //@ts-ignore
    const eventId = event.id;

    if (choice) {
      try {
        // TON PROOF

        // const signedMessage = await signMakeBetMessage(eventId, [tokenId], choice);

        // if (!signedMessage) {
        //   throw new Error('Make bet message sign is failed');
        // }

        // const { r, v, s } = signedMessage;

        // * Uncomment if you want to call contract directly cuz back-end is not available for some reason
        // const tx = await Arena__factory.connect(
        //   process.env.REACT_APP_ADDRESS_SK_ARENA,
        //   provider?.getSigner()!
        // ).makeBetsGasFree([eventId], [tokenId], [choice], account!, v, r, s);

        // await tx.wait();

        if (isGasless) {
          await gaslessMakeBetCall({
            eventId,
            result: choice,
            tokenIds: [tokenId],
            userAddress: viewer.tonAddress,
          });

          await refetchConsumables();
          nftModel.setIsBlockedForTransaction([tokenId]);
        } else {
          // const data = Dictionary.empty<bigint, bigint>();
          // data.set(BigInt(0), BigInt(tokenId));

          // await makeBet({
          //   args: {
          //     $$type: 'RequestBet',
          //     event_id: BigInt(eventId),
          //     result: BigInt(choice),
          //     token_ids: data,
          //   },
          //   value: toNano('0.05'),
          // });

          await postMakeBetTon({
            cardIds: [Number(tokenId)],
            eventId,
            matchResult: choice,
          });
        }

        onSetViewMode(true);

        nftModel.onMakeBet({
          // todo: fix types
          // @ts-ignore
          event: {
            ...event,
            choice: choice!,
            cards: isViewMode ? [...battle.cards, ...battle.additionalCards] : cards,
          },
          cardIds: [...cards.map((card) => card.token_id), card.token_id],
        });

        closeEventDialog();
      } catch (e) {
        console.error('Make bet single: ', e);
      }
    }
  };

  const handleMultipleBet = async (isGasless: boolean) => {
    if (!event || !choice) {
      return;
    }

    const targetCards = isViewMode ? battle.additionalCards : cards;
    //@ts-ignore
    const eventId = event.id;
    const cardIds = targetCards.map(({ token_id }) => Number(token_id));

    const tokenIds = targetCards.map(({ token_id }) => token_id);

    try {
      // TON PROOF

      // const signedMessage = await signMakeBetMessage(eventId, tokenIds, choice!);

      // if (!signedMessage) {
      //   throw new Error('Make bet message sign is failed');
      // }

      // const { r, v, s } = signedMessage;

      // * Uncomment if you want to call contract directly cuz back-end is not available for some reason
      // const tx = await Arena__factory.connect(
      //   process.env.REACT_APP_ADDRESS_SK_ARENA,
      //   provider?.getSigner()!
      // ).makeBetsGasFree(
      //   tokenIds.map(() => eventId),
      //   tokenIds.map((tokenId) => Number(tokenId)),
      //   tokenIds.map(() => choice),
      //   account!,
      //   v,
      //   r,
      //   s
      // );

      // await tx.wait();

      if (isGasless) {
        await gaslessMakeBetCall({
          eventId,
          result: choice,
          tokenIds,
          userAddress: viewer.tonAddress,
        });

        await refetchConsumables();
        nftModel.setIsBlockedForTransaction(tokenIds);
      } else {
        // const data = Dictionary.empty<bigint, bigint>();

        // tokenIds.forEach((tokenId, index) => {
        //   data.set(BigInt(index), BigInt(tokenId));
        // });

        // // await makeBet({
        // //   args: {
        // //     $$type: 'RequestBet',
        // //     result: BigInt(choice - 2),
        // //     token_ids: data,
        // //     event_id: BigInt(eventId),
        // //   },
        // //   value: toNano('0.05'),
        // // });

        await postMakeBetTon({
          cardIds: tokenIds.map((id) => Number(id)),
          eventId,
          matchResult: choice,
        });
      }

      // await gaslessMakeBetCall({
      //   eventIds: tokenIds.map(() => eventId),
      //   cardId: tokenIds.map((tokenId) => Number(tokenId)),
      //   choiceId: tokenIds.map(() => choice),
      //   caller: account!,
      // });

      onSetViewMode(true);

      nftModel.onMakeBet({
        event: {
          ...(event as EventStateEvent),
          isCall: false,
          result: BattleResult.InProgress,
          choice: choice!,
          cards: isViewMode ? [...battle.cards, ...battle.additionalCards] : cards,
        },
        cardIds: [...cards.map((card) => card.token_id), ...cardIds.map((id) => String(id))],
      });

      closeEventDialog();
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * @param id - id of Event or Call. If we are creating call - it is going to be id of Event, otherwise it would be id of existing Call
   * @param cardId - id of NFT
   * @param choice - Enum value of BattleResult
   * @param address - Wallet address of viewer
   */
  const signActionCallMessage = async <Id extends string | number>(
    id: Id,
    cardId: string,
    choice: BattleResult,
    address: string
  ) => {
    const signer = provider?.getSigner();

    if (!signer) {
      return;
    }

    const arenaContract = Arena__factory.connect(process.env.REACT_APP_ADDRESS_SK_ARENA, signer);

    const gaslessFreeCounter = await arenaContract.gasFreeOpCounter(address);

    const types: Array<'uint256' | 'uint8'> = ['uint256', 'uint256', 'uint256', 'uint8'];
    const values: Array<BigNumber | BattleResult> = [
      gaslessFreeCounter,
      BigNumber.from(id),
      BigNumber.from(cardId),
      choice,
    ];

    const signedMessage = await signGaslessTxMessage({ signer, types, values });

    return signedMessage;
  };

  const handleCreateCall = async () => {
    if (!event || !choice) {
      return;
    }

    try {
      const eventId = parseEventIdToBlockchainFormat(event.id);
      const card = cards[0];

      const signedMessage = await signActionCallMessage(eventId, card.token_id, choice, account!);

      if (!signedMessage) {
        return;
      }

      // const { r, s, v } = signedMessage;

      // * Uncomment if you want to call contract directly cuz back-end is not available for some reason
      // const tx = await Arena__factory.connect(
      //   process.env.REACT_APP_ADDRESS_SK_ARENA,
      //   provider?.getSigner()!
      // ).createCallGasFree(eventId, card.token_id, choice, account!, v, r, s);

      // await tx.wait();

      // await gaslessCreateCall({
      //   eventId: eventId,
      //   cardId: Number(card.token_id),
      //   choiceId: choice,
      //   caller: account!,
      //   r,
      //   s,
      //   v,
      // });

      onSetViewMode(true);

      nftModel.onMakeBet({
        // @ts-ignore
        event: {
          ...event,
          cards: cards,
          isCall: true,
          firstParticipant: {
            address: viewer.wallet,
            avatar: viewer.avatar?.src!,
            nickname: viewer.nickname,
            card: card.token_id,
            choiceId: choice!,
          },
          secondParticipant: null,
        },
        cardIds: [card.token_id],
      });

      closeEventDialog();
    } catch {}
  };

  const onSetViewMode = (isViewMode: boolean) => {
    dispatch(eventActions.set({ [betType]: { isViewMode } }));
  };

  const handleAcceptCall = async () => {
    if (!event || !call.callId || !choice) {
      return;
    }

    try {
      const card = cards[0];

      const signedMessage = await signActionCallMessage(
        call.callId,
        card.token_id,
        choice,
        account!
      );

      if (!signedMessage) {
        return;
      }

      // const { r, s, v } = signedMessage;

      // * Uncomment if you want to call contract directly cuz back-end is not available for some reason
      // const tx = await Arena__factory.connect(
      //   process.env.REACT_APP_ADDRESS_SK_ARENA,
      //   provider?.getSigner()!
      // ).acceptCallGasFree(String(call.callId), card.token_id, choice, account!, v, r, s);

      // await tx.wait();

      // await gaslessAcceptCall({
      //   callId: String(call.callId),
      //   cardId: Number(card.token_id),
      //   choiceId: choice,
      //   caller: account!,
      //   r,
      //   s,
      //   v,
      // });

      onSetViewMode(true);

      nftModel.onMakeBet({
        // todo: fix types
        // @ts-ignore
        event: {
          ...event,
          cards: cards,
          isCall: true,
          firstParticipant: call.callCreator as any,
          secondParticipant: {
            address: viewer.wallet,
            avatar: viewer.avatar?.src!,
            nickname: viewer.nickname,
            card: card.token_id,
            choiceId: choice!,
          },
        },
        cardIds: [card.token_id],
      });

      closeEventDialog();
    } catch {}
  };

  // todo: maybe move to dedicated model
  const onVote = (isGasless: boolean) => {
    // BET NFT

    if (!isConnected) {
      openConnectWalletWarnDialog();

      return;
    }

    const isAcceptingCall = isCall;
    const isMultipleBetting = cards.length > 1 || battle.additionalCards.length > 1;

    switch (true) {
      case isAcceptingCall:
        return handleAcceptCall();
      case isMakeCall:
        return handleCreateCall();
      case isMultipleBetting:
        return handleMultipleBet(isGasless);
      default:
        return handleSingleBet(isGasless);
    }
  };

  const onRemoveAllCardsFromEvent = async () => {
    if (!event) {
      return;
    }

    //@ts-ignore
    const eventId = event.id;
    // const parsedEventId = parseEventIdToBlockchainFormat(event.id);
    //@ts-ignore
    const betIds = event.bets.map(({ id }) => id);

    try {
      // await takeAllCardsFromEvent({ args: [parsedEventId, cardIds] });
      // await closeBet({
      //   args: {
      //     $$type: 'CloseBet',
      //     bet_id: BigInt(betId),
      //     event_id: BigInt(eventId),
      //   },
      //   value: toNano('0.3'),
      // });

      await postCloseBetTon({
        betIds,
        eventId,
      });

      nftModel.onRemoveAllCardsFromEvent(event.id);

      dispatch(eventActions.clearEventCards({ betType: 'battle', cardsKey: 'cards' }));
      // refetch bets in case user on the profile battles page
      setTimeout(profile.invalidateProfileBattlesQuery, 10000);
      closeEventDialog();
    } catch (e) {}
  };

  const onClearEventCards = (cardsKey: 'cards' | 'additionalCards', betType: EventBetType) => {
    dispatch(eventActions.clearEventCards({ cardsKey, betType }));
  };

  const onClearAll = () => {
    const cardsKey = isViewMode && battle.additionalCards.length > 0 ? 'additionalCards' : 'cards';
    const cardsToClear = battle.additionalCards.length > 0 ? battle.additionalCards : cards;

    onClearEventCards(cardsKey, betType);
    nftModel.onClearEventCards(cardsToClear);
  };

  return {
    closingConfirmationDialog,

    tab,

    correctChoice,
    event,
    eventStatus: eventStatus as EventStatus,
    potentialRewardAmount,
    choice,
    // todo: use result from `event`
    result,
    cards,

    user,

    call,
    battle,

    betType,

    noBattleForEvent,
    noCallForEvent,

    eventStatisticsEntries,
    nftWithWinstreakDialog,

    isConnected,

    isViewMode,
    isLoading,

    isViewer,
    isCall,
    isCallTab,
    isUserBattleParticipant,
    isUserCallParticipant,
    isViewerBattle,
    isViewerCall,
    // todo: use Boolean(eventIdParam) as isOpen
    isOpen: open,
    isStatisticsTabDisabled: eventStatisticsEntries.length === 0,
    isNoCardsSelected: cards.length === 0,
    isCallAvailable: cards.length < 2,
    isMakeCall,
    isLessOrEqualFiveMinutesLeft: event ? isLessOrEqualFiveMinutesLeft(event.date) : false,
    isAnyCardsPlaced,
    voteChoiceDialog,

    broadcastId,
    broadcastProvider,
    eventBroadcastAvailable,

    dropOutCard,
    getCallDataToOpenEvent,
    openEvent,
    openActiveEvent,
    openCallEvent,
    openBetEvent,
    openRelatedEvent,
    onClose,
    onConfirmCloseEvent,
    onTabChange,
    onVote,
    onClearAll,
    onAddCards,
    onRemoveAllCardsFromEvent,
  };
};

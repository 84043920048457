import { Avatar, Box, Stack, Typography } from '~shared/ui';

import { useDailyQuestRewardSelector } from '~widgets/daily-quest-reward-dialog';
import { useViewerSelector } from '~entities/viewer';

import { useDailyQuestProgressModel, useDailyQuestProgressSelector } from '../model';

import { formatDailyQuestKey } from '../lib';

import {
  AdditionalBlock,
  AvatarShadow,
  AvatarWrapper,
  LayoutImage,
  ProgressBar,
  ProgressBarOverlap,
  StyledDialog,
} from './styled';
import {
  MCNImageSrc,
  commonCardImagesSrc,
  freebetImageSrc,
  telegramPremiumImageSrc,
  tournamentTicketImageSrc,
  unfreezeImageSrc,
} from './assets';

export const DailyQuestProgressDialog = () => {
  const { avatar } = useViewerSelector();
  const { isOpen } = useDailyQuestProgressSelector();
  const { allDays, rewardList, countedDays } = useDailyQuestRewardSelector();

  const { closeModal } = useDailyQuestProgressModel();

  return (
    <StyledDialog open={isOpen} onClose={closeModal}>
      <Box pl="21px" paddingBlock="31px" bgcolor="#020202">
        <AdditionalBlock />
        <Stack mb="33px">
          <Typography zIndex={1} fontWeight={700} fontSize={22} lineHeight="25.83px" pb="18px">
            Daily quests
          </Typography>
          <Typography
            zIndex={1}
            fontWeight={500}
            fontSize={14}
            lineHeight="17px"
            color="rgba(255, 255, 255, 0.75)"
          >
            Enter to Maincard for 14 days <br /> and receive prizes
          </Typography>
        </Stack>
        <Stack flexDirection="row" gap="31px">
          <Stack gap="18px" alignItems="center">
            {Array.from({ length: allDays + 1 }).map((_, day) => (
              <Typography
                key={day}
                zIndex={1}
                fontWeight={500}
                fontSize={14}
                lineHeight="16.44px"
                color="rgba(255, 255, 255, 0.5)"
              >
                {day === 0 ? 'Days' : day}
              </Typography>
            ))}
          </Stack>
          <Stack alignItems="center">
            <ProgressBar>
              <ProgressBarOverlap $allDays={allDays} $countedDays={countedDays} />
              <AvatarWrapper $allDays={allDays} $countedDays={countedDays}>
                <Avatar zIndex={2} width="40px" height="40px" src={avatar?.src} />
                <AvatarShadow />
              </AvatarWrapper>
            </ProgressBar>
          </Stack>
          <Stack gap="18px">
            {Array.from({ length: allDays + 2 }).map(
              (_, day) =>
                day !== 1 && (
                  <Typography
                    key={day}
                    zIndex={1}
                    fontWeight={day === 0 ? 700 : 500}
                    fontSize={14}
                    lineHeight="16.44px"
                  >
                    {day === 0
                      ? `Completed: ${countedDays}/${allDays}`
                      : `${formatDailyQuestKey(rewardList[String(day)].key)} ${
                          rewardList[String(day)].amount !== 1
                            ? 'x' + rewardList[String(day)].amount
                            : ''
                        }`}
                  </Typography>
                )
            )}
          </Stack>
        </Stack>
      </Box>
      <LayoutImage src={commonCardImagesSrc} $top={130} $right={-50} $rotation={0} />
      <LayoutImage
        src={unfreezeImageSrc}
        $top={270}
        $right={-10}
        $rotation={-23}
        $width={100}
        $height={100}
      />
      <LayoutImage
        src={MCNImageSrc}
        $top={305}
        $right={-25}
        $rotation={0}
        $width={170}
        $height={170}
      />
      <LayoutImage src={freebetImageSrc} $top={345} $right={-70} $rotation={0} />
      <LayoutImage src={tournamentTicketImageSrc} $top={465} $right={-65} $rotation={-50} />
      <LayoutImage
        src={telegramPremiumImageSrc}
        $top={525}
        $right={-80}
        $rotation={0}
        $width={200}
        $height={200}
      />
    </StyledDialog>
  );
};

import { ApiCommonSport, ApiCommonSportEnum } from '../shared';

import { difficultyToColorMap } from './constants';

import {
  ApiBattleBroadcastProvider,
  ApiGetBattlesByIdsMappedData,
  ApiGetBattlesByIdsRawData,
  ApiGetBattlesMappedData,
  ApiGetBattlesRawData,
  ApiGetBattlesRawTeam,
  ApiGetBattlesTeam,
  MappedMatchDifficulty,
  RawMatchDifficulty,
} from './types';

export const mapGetBattlesResponse = (
  data: Array<Array<ApiGetBattlesRawData>>
): Array<ApiGetBattlesMappedData> => {
  return data
    .flat()
    .map(({ date, homeTeam, awayTeam, sport, broadcast, matchDifficulty, ...battle }) => {
      return {
        ...battle,
        homeTeam: mapBattleTeam(homeTeam, sport),
        awayTeam: mapBattleTeam(awayTeam, sport),
        date: new Date(date),
        matchDifficulty: mapMatchDifficulty(matchDifficulty),
        sport,

        broadcastId: broadcast,
        broadcastProvider: getBroadcastProvider(sport),
      };
    })
    .sort((a, b) => a.date.getTime() - b.date.getTime());
};

export const mapGetBattlesByIdsResponse = (
  data: Array<Array<ApiGetBattlesByIdsRawData>>
): Array<ApiGetBattlesByIdsMappedData> => {
  return data
    .flat()
    .map(({ date, sport, homeTeam, awayTeam, broadcast, matchDifficulty, ...battle }) => {
      return {
        ...battle,
        homeTeam: mapBattleTeam(homeTeam, sport),
        awayTeam: mapBattleTeam(awayTeam, sport),
        date: new Date(date),
        matchDifficulty: mapMatchDifficulty(matchDifficulty),
        sport,

        broadcastId: broadcast,
        broadcastProvider: getBroadcastProvider(sport),
      };
    })
    .sort((a, b) => a.date.getTime() - b.date.getTime());
};

export const mapBattleTeam = (
  team: ApiGetBattlesRawTeam,
  sport: ApiCommonSport
): ApiGetBattlesTeam => {
  if (sport === 'tennis') {
    return {
      ...team,
      name: team.players!.map((player) => getTeamFormattedName(player.name)).join(','),
      logo: team.players![0].logo,

      players: team.players!.map((player) => ({
        ...player,
        name: getTeamFormattedName(player.name),
        shortName: getTeamShortName(player.name),
      })),
    };
  }

  return {
    ...team,
    name: getTeamFormattedName(team.name),
    players: [
      {
        name: getTeamFormattedName(team.name),
        shortName: getTeamShortName(team.name),
        logo: team.logo,
      },
    ],
  };
};

// todo: maybe fix on backend
export const getTeamFormattedName = (name: string): string => {
  if (name.includes(',')) {
    return name.split(',').reverse().join(' ').trim();
  }

  return name;
};

const getTeamShortName = (name: string): string => {
  if (name.includes(',')) {
    const [lastName, firstName] = name.split(',').map((str) => str.trim());

    return `${firstName.charAt(0)}. ${lastName}`;
  }

  return name;
};

export const getBroadcastProvider = (sport: ApiCommonSport): ApiBattleBroadcastProvider => {
  switch (sport) {
    case ApiCommonSportEnum.CS2:
    case ApiCommonSportEnum.Dota:
    case ApiCommonSportEnum.LeagueOfLegends:
      return ApiBattleBroadcastProvider.Twitch;

    case ApiCommonSportEnum.AmericanFootball:
    case ApiCommonSportEnum.Football:
    case ApiCommonSportEnum.Tennis:
    case ApiCommonSportEnum.Basketball:
      return ApiBattleBroadcastProvider.SportRadar;

    default:
      return ApiBattleBroadcastProvider.Unknown;
  }
};

export const mapMatchDifficulty = (matchDifficulty: RawMatchDifficulty): MappedMatchDifficulty => {
  return {
    away: {
      color: difficultyToColorMap[matchDifficulty.away],
      label: matchDifficulty.away,
    },
    draw: {
      color: difficultyToColorMap[matchDifficulty.draw],
      label: matchDifficulty.draw,
    },
    home: {
      color: difficultyToColorMap[matchDifficulty.home],
      label: matchDifficulty.home,
    },
  };
};

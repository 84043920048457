import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const WalletPageRoot = styled(Box, { name: 'WalletPage', slot: 'root' })(({ theme }) => ({
  display: 'flex',
  padding: '14px 16px',
  background: theme.palette.paper.paulo,
  overflowY: 'auto',
  flexGrow: 1,
}));

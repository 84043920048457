import { styled } from '~shared/lib/styles';
import { Checkbox } from '~shared/ui';

export const StyledCheckbox = styled(Checkbox, {
  name: 'UserCardsList',
  slot: 'checkbox',
})(() => ({
  position: 'absolute',
  inset: 'auto auto 8px 8px',
  zIndex: 1,
}));

import {
  ApiCommonSportEnum,
  ApiGetBattlesByIdsMappedData,
  ApiGetBattlesMappedData,
} from '~shared/api';

import {
  EVENT_COMMON_STATISTIC_KEYS,
  EVENT_TENNIS_STATISTIC_KEYS,
  EventEsportsStatisticKeys,
  STATISTIC_SCORE_KEY_TO_LABEL_MAP,
} from './config';

import {
  EventStatistics,
  EventStatisticsRowColumnValueDotResult,
  EventStatisticsRowDots,
  EventStatisticsRowLinear,
  EventStatisticsRowMultipleValue,
  EventStatisticsRowValue,
} from './types';

export const mapEventStatistics = (
  event: ApiGetBattlesMappedData | ApiGetBattlesByIdsMappedData
): EventStatistics => {
  switch (event.sport) {
    case ApiCommonSportEnum.CS2:
    case ApiCommonSportEnum.LeagueOfLegends:
    case ApiCommonSportEnum.Dota:
      return mapEventStatisticsForEsports(event);
    case ApiCommonSportEnum.Tennis:
      return mapEventStatisticsForTennis(event);
    default:
      return mapEventStatisticsLinearRows(event, EVENT_COMMON_STATISTIC_KEYS);
  }
};

export const mapEventStatisticsLinearRows = (
  event: ApiGetBattlesMappedData | ApiGetBattlesByIdsMappedData,
  keys: Array<string>
): Array<EventStatisticsRowLinear> => {
  const { homeTeam, awayTeam } = event;

  return keys
    .filter((key) => hasKeyInAnyObject(key, [homeTeam, awayTeam]))
    .map((key) => {
      const homeTeamValue = homeTeam[key];
      const awayTeamValue = awayTeam[key];
      const overallValue = homeTeamValue + awayTeamValue;

      return {
        key,
        visualization: 'linear',
        label: getStatisticsRowLabel(key),
        leftColumn: {
          overallValue,
          value: homeTeamValue,
          result: homeTeamValue >= awayTeamValue ? 'good' : 'bad',
        },
        rightColumn: {
          overallValue,
          value: awayTeamValue,
          result: awayTeamValue >= homeTeamValue ? 'good' : 'bad',
        },
      };
    });
};

export const mapEventStatisticsValueRows = (
  event: ApiGetBattlesMappedData | ApiGetBattlesByIdsMappedData,
  keys: Array<string>
): Array<EventStatisticsRowValue> => {
  const { homeTeam, awayTeam } = event;

  return keys
    .filter((key) => hasKeyInAnyObject(key, [homeTeam, awayTeam]))
    .map((key) => {
      return {
        key,
        visualization: 'value',
        label: getStatisticsRowLabel(key),
        leftColumn: homeTeam[key],
        rightColumn: awayTeam[key],
      };
    });
};

export const mapEventStatisticsForEsports = (
  event: ApiGetBattlesMappedData | ApiGetBattlesByIdsMappedData
): Array<EventStatisticsRowDots | EventStatisticsRowValue> => {
  const { homeTeam, awayTeam } = event;

  const getFlatDotsRow = (
    key: EventEsportsStatisticKeys
  ): EventStatisticsRowDots | EventStatisticsRowValue => {
    const homeTeamValue = homeTeam[key] ?? null;
    const awayTeamValue = awayTeam[key] ?? null;

    const bothValuesLessThanEight =
      Math.abs(Number(homeTeamValue)) <= 10 && Math.abs(Number(awayTeamValue)) <= 10;

    if (bothValuesLessThanEight) {
      const formatColumn = (
        value: number | null
      ): Array<EventStatisticsRowColumnValueDotResult> => {
        if (value === null) {
          return [];
        }

        const sign = Math.sign(value);

        const createArrayOfResults = (
          value: number,
          result: EventStatisticsRowColumnValueDotResult
        ) => {
          return Array.from(Array(Math.abs(value)).keys()).map(() => result);
        };

        switch (sign) {
          case -1:
            return createArrayOfResults(value, 'bad');
          case 1:
            return createArrayOfResults(value, 'good');
          case 0:
          default:
            return [];
        }
      };

      return {
        key,
        label: getStatisticsRowLabel(key),
        visualization: 'dots',
        leftColumn: formatColumn(homeTeamValue),
        rightColumn: formatColumn(awayTeamValue),
      };
    }

    return {
      key,
      label: getStatisticsRowLabel(key),
      visualization: 'value',
      leftColumn: homeTeamValue ? Math.abs(homeTeamValue) : null,
      rightColumn: awayTeamValue ? Math.abs(awayTeamValue) : null,
    };
  };

  const getStreakDotsRow = (): EventStatisticsRowDots => {
    return {
      key: 'streak',
      label: getStatisticsRowLabel('streak'),
      visualization: 'dots',
      leftColumn: homeTeam.streak?.map((type) => (type === 'L' ? 'bad' : 'good')) ?? [],
      rightColumn: awayTeam.streak?.map((type) => (type === 'L' ? 'bad' : 'good')) ?? [],
    };
  };

  return [
    ...mapEventStatisticsValueRows(event, ['winRate']),
    getFlatDotsRow('currentStreak'),
    getFlatDotsRow('bestStreak'),
    getFlatDotsRow('worstStreak'),
    getStreakDotsRow(),
  ].filter((row) => {
    if (Array.isArray(row.leftColumn) && Array.isArray(row.rightColumn)) {
      const bothColumnsHasResultLength = row.leftColumn.length > 0 || row.rightColumn.length > 0;

      return bothColumnsHasResultLength;
    }

    if (typeof row.leftColumn === 'number' && typeof row.rightColumn === 'number') {
      const bothColumnsNonNullable = Math.abs(row.leftColumn) > 0 || Math.abs(row.rightColumn) > 0;

      return bothColumnsNonNullable;
    }

    return hasKeyInAnyObject(row.key, [homeTeam, awayTeam]);
  });
};

export const mapEventStatisticsForTennis = (
  event: ApiGetBattlesMappedData | ApiGetBattlesByIdsMappedData
): Array<EventStatisticsRowMultipleValue | EventStatisticsRowValue> => {
  const isMultiplePlayers = event.homeTeam.players.length > 1;

  const keys = EVENT_TENNIS_STATISTIC_KEYS;

  const { homeTeam, awayTeam } = event;

  if (isMultiplePlayers) {
    const { players: homeTeamPlayers } = homeTeam;
    const { players: awayTeamPlayers } = awayTeam;

    return keys
      .filter((key) => hasKeyInAnyObject(key, [...homeTeam.players, ...awayTeam.players]))
      .map((key) => {
        return {
          key,
          label: getStatisticsRowLabel(key),
          visualization: 'multiple-value',
          leftColumn: homeTeamPlayers.map((player) => ({
            label: player.shortName,
            value: player[key] ?? null,
          })),
          rightColumn: awayTeamPlayers.map((player) => ({
            label: player.shortName,
            value: player[key] ?? null,
          })),
        };
      });
  }

  const homeTeamPlayer = homeTeam.players[0];
  const awayTeamPlayer = awayTeam.players[0];

  return keys
    .filter((key) => hasKeyInAnyObject(key, [homeTeamPlayer, awayTeamPlayer]))
    .map((key) => ({
      key,
      label: getStatisticsRowLabel(key),
      visualization: 'value',
      leftColumn: homeTeamPlayer[key] ?? null,
      rightColumn: awayTeamPlayer[key] ?? null,
    }));
};

const getStatisticsRowLabel = (key: string): string => {
  return STATISTIC_SCORE_KEY_TO_LABEL_MAP[key] ?? key;
};

const hasKeyInAnyObject = (key: string, arrayOfObjects: Array<Object>) => {
  let hasKey = false;

  arrayOfObjects.forEach((object) => {
    if (key in object) {
      hasKey = true;
    }
  });

  return hasKey;
};

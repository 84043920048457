/* eslint-disable react/jsx-key */
import { ReactNode } from 'react';

import { Button, ButtonIcon, Pill, PillGroup, Stack, Typography } from '~shared/ui';

import { ViewerWalletBalance } from '~widgets/viewer-wallet-balance';

export const ButtonsTab = () => {
  const row = (name: string, elements: ReactNode[]) => (
    <Stack direction="row" alignItems="center" spacing={2} minHeight={44}>
      <Typography minWidth={150}>{name}</Typography>
      {elements}
    </Stack>
  );

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography
          borderBottom="1px solid #4A4A4A"
          paddingBottom={1}
          sx={{ fontSize: 20, fontWeight: 500 }}
        >
          Button
        </Typography>

        {[
          row('44px contained', [
            <Button>Save</Button>,
            <Button disabled>Save</Button>,

            <Button color="error">Save</Button>,
            <Button color="error" disabled>
              Save
            </Button>,
          ]),
          row('44px outlined', [
            <Button variant="outlined">Save</Button>,
            <Button variant="outlined" disabled>
              Save
            </Button>,
          ]),
          row('32px contained', [
            <Button size="sm">Save</Button>,
            <Button size="sm" disabled>
              Save
            </Button>,

            <Button color="error" size="sm">
              Save
            </Button>,
            <Button color="error" size="sm" disabled>
              Save
            </Button>,
          ]),
          row('32px outlined', [
            <Button variant="outlined" size="sm">
              Save
            </Button>,
            <Button variant="outlined" size="sm" disabled>
              Save
            </Button>,
          ]),
        ]}
      </Stack>

      <Stack spacing={2}>
        <Typography
          borderBottom="1px solid #4A4A4A"
          paddingBottom={1}
          sx={{ fontSize: 20, fontWeight: 500 }}
        >
          Button Icon
        </Typography>

        {[
          row('24px contained', [
            <ButtonIcon variant="contained" name="social-google" size={24} />,
            <ButtonIcon variant="contained" name="social-google" size={24} disabled />,
          ]),
          row('24px outlined', [
            <ButtonIcon variant="outlined" name="social-google" size={24} />,
            <ButtonIcon variant="outlined" name="social-google" size={24} disabled />,
          ]),

          row('20px contained', [
            <ButtonIcon variant="contained" name="plus" size={20} />,
            <ButtonIcon variant="contained" name="plus" size={20} disabled />,
          ]),
          row('20px outlined', [
            <ButtonIcon variant="outlined" name="plus" size={20} />,
            <ButtonIcon variant="outlined" name="plus" size={20} disabled />,
          ]),
        ]}
      </Stack>

      <Stack spacing={2}>
        <Typography
          borderBottom="1px solid #4A4A4A"
          paddingBottom={1}
          sx={{ fontSize: 20, fontWeight: 500 }}
        >
          MATIC / Mcc
        </Typography>

        {[
          row('Mcc', [
            <PillGroup>
              <Pill>Mcc 0,00</Pill>
              <span />
            </PillGroup>,
          ]),
          row('Matic', [
            <PillGroup>
              <span />
              <Pill append={<ButtonIcon as="span" name="plus" />}>Matic 0,00</Pill>
            </PillGroup>,
          ]),

          row('Mcc / Matic', [<ViewerWalletBalance />]),
        ]}
      </Stack>
    </Stack>
  );
};

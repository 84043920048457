import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '~shared/lib/hooks';
import { Button, Link } from '~shared/ui';
import { routes } from '~shared/config';

import { useNftCardModel } from '~entities/nft';

import { useViewerWalletModel } from '../../model';

import { Root } from './styled';

export const ViewerWalletActions: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const { isSelectionMode } = useNftCardModel();

  const {
    selectedCardsAmount,
    confirmSelectedCards,
    clearSelectedCards,
    cancelSelectCardToMerge,
    cancelSelectCardToJoinTournament,
    isConfirmAvailable,
    selectionModeType,

    walletTab,
  } = useViewerWalletModel();

  const buttonSize = isMobile ? 'sm' : 'lg';

  if (isSelectionMode) {
    switch (selectionModeType) {
      case 'bet':
        return (
          <Root>
            <Button size={buttonSize} variant="outlined" onClick={clearSelectedCards}>
              {t('Sidebar.selectionClearAll')}
            </Button>
            <Button size={buttonSize} onClick={confirmSelectedCards} disabled={!isConfirmAvailable}>
              {t('Sidebar.selectionConfirm', { quantity: selectedCardsAmount })}
            </Button>
          </Root>
        );
      case 'merge':
        return (
          <Root>
            <Button size={buttonSize} variant="outlined" onClick={cancelSelectCardToMerge}>
              {t('Sidebar.selectionCancel')}
            </Button>
            <Button size={buttonSize} onClick={confirmSelectedCards} disabled={!isConfirmAvailable}>
              {t('Sidebar.selectionConfirm', { quantity: selectedCardsAmount })}
            </Button>
          </Root>
        );
      case 'tournament':
        return (
          <Root>
            <Button size={buttonSize} variant="outlined" onClick={cancelSelectCardToJoinTournament}>
              {t('Sidebar.selectionCancel')}
            </Button>
            <Button size={buttonSize} onClick={confirmSelectedCards} disabled={!isConfirmAvailable}>
              {t('Sidebar.selectionConfirm', { quantity: selectedCardsAmount })}
            </Button>
          </Root>
        );
    }
  }

  if (walletTab === 'tournaments') {
    return (
      <Root>
        <Link to={routes.tournaments} fullWidth>
          <Button size={buttonSize} fullWidth>
            {t('Sidebar.joinTournament')}
          </Button>
        </Link>
      </Root>
    );
  }

  return (
    <Root>
      <Link to={routes.marketplace} fullWidth>
        <Button size={buttonSize} fullWidth>
          {t('Sidebar.buyCards')}
        </Button>
      </Link>
    </Root>
  );
};

import { FC, Fragment, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { ethers } from 'ethers';

import { Button, Dialog, Icon, Stack, Typography, buttonClasses } from '~shared/ui';
import { useSwitcher } from '~shared/lib/hooks';
import { ApiGetTournamentMappedResponseData, ApiTournamentStatus } from '~shared/api';

import { StyledDialogContent } from './styled';

import { TournamentAllPrizePlacesControlProps } from './types';

export const TournamentAllPrizePlacesControl: FC<TournamentAllPrizePlacesControlProps> = ({
  tournament,
  ...buttonProps
}) => {
  const { t } = useTranslation();

  const open = useSwitcher(false);

  const topPrizes = tournament.prizes.slice(0, 3);
  const restPrizes = tournament.prizes.slice(3);

  const renderPrize = (prize: ApiGetTournamentMappedResponseData['prizes'][number]) => {
    const crown = getCrownIcon(prize.place);
    const isItemTypeReward = prize.items > 0;

    return (
      <Stack
        key={prize.value}
        direction="row"
        height={40}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography fontWeight={700} fontSize={18} lineHeight={1}>
            {prize.place}
          </Typography>

          {crown}

          <Typography color="grey.A50" fontSize={13} lineHeight={1}>
            {t('Tournaments.place')}
          </Typography>
        </Stack>

        <Stack spacing={6 / 8} direction="row" alignItems="center">
          <Typography fontWeight={crown ? 700 : 600} fontSize={crown ? 18 : 14} lineHeight={1}>
            {isItemTypeReward ? prize.items : prize.value}
          </Typography>
          <Typography color="grey.A50" lineHeight={1} fontSize={13}>
            {isItemTypeReward
              ? t('Tournaments.unfreezeRewardType', { count: prize.items })
              : prize.currency}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const renderUpTo = () => {
    switch (tournament.status) {
      case ApiTournamentStatus.Planned:
      case ApiTournamentStatus.Delayed:
        return <Typography color="grey.A30">{t('Tournaments.winUpTo')}</Typography>;
      default:
        return null;
    }
  };

  const handleOpen: MouseEventHandler = (event) => {
    event.stopPropagation();
    open.switchOn();
  };

  const stopPropogation = (event: any) => {
    event?.stopPropagation();
  };

  return (
    <Fragment>
      <Button
        sx={{
          [`&.${buttonClasses.text}`]: {
            color: 'grey.A50',
            textDecoration: 'underline',
            textDecorationColor: (theme: any) => theme.palette.grey.A20,
          },
        }}
        variant="text"
        alignSelf="start"
        onClick={handleOpen}
        {...buttonProps}
      >
        {t('Tournaments.allPrizePlaces')}
      </Button>

      <Dialog
        width={310}
        open={open.value}
        onClose={open.switchOff}
        onClick={stopPropogation}
        slots={{ content: StyledDialogContent }}
      >
        <Stack spacing={14 / 8}>
          {/* <TournamentTitle
            cardsCount={tournament.joinCardsQuantity}
            rarity={tournament.tournamentRarity}
            location="prizes"
            onboarding={false}
          /> */}

          {renderUpTo()}

          <Stack>
            <Stack spacing={6 / 8}>{topPrizes.map(renderPrize)}</Stack>

            {restPrizes.length > 0 ? <Stack>{restPrizes.map(renderPrize)}</Stack> : null}
          </Stack>
        </Stack>
      </Dialog>
    </Fragment>
  );
};

const getCrownIcon = (place: number) => {
  switch (place) {
    case 1:
      return <Icon name="crown-gold" />;
    case 3:
      return <Icon name="crown-silver" />;
    case 2:
      return <Icon name="crown-bronze" />;
    default:
      return null;
  }
};

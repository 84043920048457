import { Fragment } from 'react';

import { withProviders } from './providers';
import { Routing } from './routing';
import { AppWidgets } from './widgets';

export const App = withProviders(() => {
  return (
    <Fragment>
      <Routing />
      <AppWidgets />
    </Fragment>
  );
});

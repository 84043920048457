import { Address, Cell, toNano } from '@ton/core';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSnackbar } from '~shared/lib/hooks';

import {
  NFT_RARITY_TO_MCN_MERGE_PRICE,
  NFT_RARITY_TO_MERGE_PRICE,
  NFT_RARITY_TO_READABLE_RARITY_NAME_MAP,
  NftToMergeSerialNumber,
  nftActions,
  useNftCardModel,
  useQueryLastAddedNft,
} from '~entities/nft';

import { useCallGasless, useWalletSelector, useWriteContract } from '~entities/wallet';

import { InventoryItem } from '~widgets/inventory/model/types';
import { NftRarity } from '~shared/api';
import { OnMergeSuccess, useMergeModel } from '~features/nft';
import { ApiFreeMergeRequest, freeMerge } from '~shared/api/consumables';
import { useInventoryModel, useInventorySelector } from '~widgets/inventory';

import { NFT_RARITY_TO_MERGE_CONSUMABLE_MAP, NFT_RARITY_TO_MERGE_ICON_MAP } from '../../lib';

enum Currency {
  Ton = 'ton',
  Mcn = 'mcn',
}

type MergePrice = {
  ton: number;
  mcn: number;
};

export const useMerge = (onMergeSuccess: OnMergeSuccess) => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [mergePrice, setMergePrice] = useState<MergePrice | null>(null);

  const { refetch: refetchConsumables } = useInventoryModel();
  const queryLastAddedNft = useQueryLastAddedNft();
  const { balance } = useWalletSelector();
  const { closeMergeDialog } = useMergeModel();
  const consumables = useInventorySelector();

  const {
    nftsToMerge: [firstNftToMerge, secondNftToMerge],
    setIsBlockedForTransaction,
  } = useNftCardModel();

  const { write: upgradeNft } = useWriteContract({
    contractName: 'CardCollection',
    method: 'UpgradeCard',
    transactionName: 'Merging NFTs',
    successMessage: `${t('Alerts.mergeNft')}`,
    errorMessage: `${t('Errors.mergeNftFailed')}`,
  });

  const { write: upgradeNftWithMcn } = useWriteContract({
    contractName: 'MainToken',
    method: 'JettonTransfer',
    transactionName: 'Merging NFTs',
    successMessage: `${t('Alerts.mergeNft')}`,
    errorMessage: `${t('Errors.mergeNftFailed')}`,
  });

  const gaslessMergeCards = useCallGasless<ApiFreeMergeRequest>({
    callback: freeMerge,
    transactionName: 'Merging NFTs',
    successMessage: `${t('Alerts.mergeNft')}`,
    errorMessage: `${t('Errors.mergeNftFailed')}`,
  });

  const isMergePriceFetched = !!mergePrice;
  const isSameRarity = firstNftToMerge?.rarity === secondNftToMerge?.rarity;
  const isMergeAvailable = isMergePriceFetched && isSameRarity;

  const merge = useCallback(
    async (isMergeWithTon: boolean, isMergeWithConsumable?: boolean) => {
      if (!firstNftToMerge || !secondNftToMerge || !mergePrice) {
        return;
      }

      try {
        if (isMergeWithConsumable) {
          await gaslessMergeCards({
            tokenId1: firstNftToMerge.token_id,
            tokenId2: secondNftToMerge.token_id,
          });

          let item: InventoryItem;

          switch (firstNftToMerge.rarity) {
            case NftRarity.Common: {
              item = 'rareMerge';
              break;
            }

            case NftRarity.Rare: {
              item = 'epicMerge';
              break;
            }

            case NftRarity.Epic: {
              item = 'legendaryMerge';
              break;
            }

            case NftRarity.Legendary: {
              item = 'mythicMerge';
              break;
            }

            default: {
              item = 'rareMerge';
            }
          }

          await refetchConsumables();
          setIsBlockedForTransaction([firstNftToMerge.token_id, secondNftToMerge.token_id]);
        } else if (isMergeWithTon) {
          await upgradeNft({
            args: {
              $$type: 'UpgradeCard',
              card1: BigInt(firstNftToMerge.token_id),
              card2: BigInt(secondNftToMerge.token_id),
            },
            value: toNano(mergePrice.ton + 0.8),
          });
        } else {
          const cell = Cell.EMPTY.asBuilder()
            .storeUint(BigInt(firstNftToMerge.token_id), 256)
            .storeUint(BigInt(secondNftToMerge.token_id), 256)
            .endCell();

          await upgradeNftWithMcn({
            args: {
              $$type: 'JettonTransfer',
              query_id: BigInt(0),
              amount: toNano(mergePrice.mcn),
              custom_payload: null,
              destination: Address.parse(process.env.REACT_APP_ADDRESS_TON_CARD_COLLECTION!),
              response_destination: Address.parse(
                process.env.REACT_APP_ADDRESS_TON_CARD_COLLECTION!
              ),
              forward_ton_amount: toNano('0.6'),
              forward_payload: Cell.EMPTY.asBuilder().storeUint(2, 8).storeRef(cell).endCell(),
            },
            value: toNano('0.7'),
          });
        }

        closeMergeDialog();

        dispatch(nftActions.mergeCards([firstNftToMerge, secondNftToMerge]));
      } catch {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      balance.ton,
      closeMergeDialog,
      dispatch,
      firstNftToMerge,
      mergePrice,
      onMergeSuccess,
      openSnackbar,
      queryLastAddedNft,
      secondNftToMerge,
      t,
      upgradeNft,
    ]
  );

  const getMergePrice = useCallback(async () => {
    const rarity = firstNftToMerge?.rarity;

    if (rarity !== undefined) {
      setMergePrice({
        ton: NFT_RARITY_TO_MERGE_PRICE[rarity],
        mcn: NFT_RARITY_TO_MCN_MERGE_PRICE[rarity],
      });
    }
  }, [firstNftToMerge]);

  useEffect(() => {
    const isBothNftsPlacedToCells = Boolean(firstNftToMerge) && Boolean(secondNftToMerge);
    const isNotSameRarity = firstNftToMerge?.rarity !== secondNftToMerge?.rarity;

    if (isBothNftsPlacedToCells && isNotSameRarity) {
      openSnackbar({ type: 'info', message: `${t('Alerts.mergeNftRarity')}` });
      dispatch(nftActions.removeNftToMerge([NftToMergeSerialNumber.Second]));

      setMergePrice(null);
    }
  }, [
    firstNftToMerge?.rarity,
    openSnackbar,
    secondNftToMerge?.rarity,
    dispatch,
    firstNftToMerge,
    secondNftToMerge,
    t,
  ]);

  useEffect(() => {
    const isBothNftsPlacedToCells = Boolean(firstNftToMerge) && Boolean(secondNftToMerge);

    if (isBothNftsPlacedToCells) {
      getMergePrice();

      return;
    }

    setMergePrice(null);
  }, [firstNftToMerge, secondNftToMerge, getMergePrice]);

  return {
    mergePrice,
    merge,

    isMergeAvailable,
    rarity:
      isMergeAvailable && firstNftToMerge && secondNftToMerge
        ? NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[firstNftToMerge.rarity + 1]
        : 'Sidebar.common',
    iconName:
      isMergeAvailable && firstNftToMerge && secondNftToMerge
        ? NFT_RARITY_TO_MERGE_ICON_MAP[firstNftToMerge.rarity]
        : 'rare-merge',
    selectedConsumablesCount:
      isMergeAvailable && firstNftToMerge && secondNftToMerge
        ? NFT_RARITY_TO_MERGE_CONSUMABLE_MAP(firstNftToMerge.rarity, consumables)
        : 0,
  };
};

import { Fragment } from 'react';

import { BattleGlobalStyles } from '~entities/battle';
import { WalletConstructor } from '~entities/wallet';
import { NftDragLayer } from '~entities/nft';

import { JoinTournamentDialog } from '~features/tournament';
import { MergeCardsDialogs } from '~features/nft';
import { Onboarding } from '~features/onboarding';

import { TournamentDialogConstructor } from '~widgets/tournament-dialog-constructor';
import { AuctionCardDetailsDialog } from '~widgets/auction-card-details-dialog';
import { NftDragBackdropConstructor } from '~widgets/nft-drag-backdrop';
import { SignOutDialog } from '~widgets/viewer/sign-out-dialog';
import { SetNickname } from '~widgets/viewer/set-nickname';
import { ContactUs } from '~widgets/contact-us';
import { AuthConstructor, FreebetDialog } from '~widgets/auth';
import { NftConstructor } from '~widgets/nft';
import { Referral } from '~widgets/referal';
import { DailyQuestRewardDialog } from '~widgets/daily-quest-reward-dialog';
import { DailyQuestProgressDialog } from '~widgets/daily-quest-progress-dialog';

export const AppWidgets = () => {
  return (
    <Fragment>
      <BattleGlobalStyles />
      <TournamentDialogConstructor />
      <NftDragBackdropConstructor />
      <AuctionCardDetailsDialog />
      <DailyQuestProgressDialog />
      <DailyQuestRewardDialog />
      <JoinTournamentDialog />
      <MergeCardsDialogs />
      <WalletConstructor />
      <AuthConstructor />
      <NftConstructor />
      <SignOutDialog />
      <FreebetDialog />
      <NftDragLayer />
      <SetNickname />
      <Onboarding />
      <ContactUs />
      <Referral />
    </Fragment>
  );
};

import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const ConnectWalletWarnDialogRoot = styled(Box, { name: 'ConnectWalletWarnDialog--root' })({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '24px',
});

export const ConnectWalletWarnDialogIllustration = styled('img', {
  name: 'ConnectWalletWarnDialog--illustration',
})(({ theme }) => ({
  width: '100%',
  height: '128px',

  [theme.breakpoints.down('xs')]: {
    height: '92px',
  },
}));

import { useExpand } from '@vkruglikov/react-telegram-web-app';
import { useEffect } from 'react';

export const useWebAppExpansion = () => {
  const [isExpanded, expand] = useExpand();

  useEffect(() => {
    if (!isExpanded) {
      expand();
    }
  }, [isExpanded, expand]);
};

import { UIEventHandler, useCallback } from 'react';

import { useSwitcher } from './useSwitcher';

export const useScrollPosition = () => {
  const scrollPositionAtTop = useSwitcher(true);
  const { toggle } = scrollPositionAtTop;

  const onScroll = useCallback<UIEventHandler<HTMLDivElement>>(
    (event) => {
      const target = event.target as HTMLElement;
      toggle(target.scrollTop === 0);
    },
    [toggle]
  );

  return {
    atTop: scrollPositionAtTop.value,

    onScroll,
    toggle,
  };
};

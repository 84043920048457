import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Button, Stack, Typography } from '~shared/ui';
import { routes } from '~shared/config';

export const PasswordChanged: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Typography variant="h1" align="center" gutterBottom>
        {t('PasswordChanged.changed')}
      </Typography>

      <Stack spacing={24 / 8}>
        <Typography color="secondary" textAlign="center">
          {t('PasswordChanged.passSuccess')}
        </Typography>

        <Link to={routes.index}>
          <Button fullWidth>{t('PasswordChanged.goTo')}</Button>
        </Link>
      </Stack>
    </Fragment>
  );
};

import { motion } from 'framer-motion';

import { styled } from '~shared/lib/styles';
import { Icon, LinearProgress } from '~shared/ui';
import { NFT_CARD_SIZE_VALUES } from '~entities/nft';

export const ViewerWalletTournamentRoot = styled('div', { name: 'ViewerWalletTournament' })(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ViewerWalletTournamentPane = styled('div', {
  name: 'ViewerWalletTournament',
  slot: 'pane',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTop: `1px solid ${theme.palette.grey.A10}`,

  padding: '16px 0',

  transition: theme.transitions.create('background'),
  cursor: 'pointer',

  paddingLeft: 16,
  paddingRight: 16,
  marginLeft: -16,
  marginRight: -16,
  width: 'calc(100% + 16px + 16px)',

  ['&:hover']: {
    background: theme.palette.grey.A10,
  },
}));

export const ViewerWalletTournamentPaneLabel = styled('div', {
  name: 'ViewerWalletTournament',
  slot: 'paneLabel',
})(() => ({
  fontSize: 13,
  fontWeight: 500,
  lineHeight: 14 / 13,
}));

export const ViewerWalletTournamentPaneProgress = styled(LinearProgress, {
  name: 'ViewerWalletTournament',
  slot: 'paneProgress',
})(() => ({
  maxWidth: 40,
  width: 40,
}));

export const ViewerWalletTournamentPaneDate = styled('div', {
  name: 'ViewerWalletTournament',
  slot: 'paneDate',
})(({ theme }) => ({
  fontSize: 10,
  fontWeight: 400,
  lineHeight: 14 / 10,
  whiteSpace: 'nowrap',

  color: theme.palette.grey.A50,
}));

interface ViewerWalletTournamentHeadingChevronProps {
  $open: boolean;
}

export const ViewerWalletTournamentPaneChevron = styled(Icon, {
  name: 'ViewerWalletTournament',
  slot: 'paneChevron',
})<ViewerWalletTournamentHeadingChevronProps>(({ $open, theme }) => ({
  transform: $open ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform'),
  color: theme.palette.grey.A50,
}));

export const ViewerWalletTournamentPanePlayers = styled('div', {
  name: 'ViewerWalletTournament',
  slot: 'panePlayers',
})(() => ({
  fontSize: 12,
  fontWeight: 600,
  lineHeight: 14 / 12,
}));

export const ViewerWalletTournamentNftsList = styled(motion.div, {
  name: 'ViewerWalletTournament',
  slot: 'nftsList',
})(({ theme }) => ({
  display: 'grid',
  alignContent: 'baseline',
  justifyContent: 'space-between',

  paddingLeft: 16,
  paddingRight: 16,
  marginLeft: -16,
  marginRight: -16,

  overflow: 'hidden',

  gridTemplateColumns: `repeat(3, ${NFT_CARD_SIZE_VALUES.md.width})`,
  gridAutoRows: `${NFT_CARD_SIZE_VALUES.md.height}`,
  rowGap: 20,

  [theme.breakpoints.down('mobile')]: {
    gridTemplateColumns: `repeat(3, ${NFT_CARD_SIZE_VALUES.lg.width})`,
    gridAutoRows: `${NFT_CARD_SIZE_VALUES.lg.height}`,
  },
}));

import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NFT_RARITY_TO_MAX_LIVES_MAP, NFT_RARITY_TO_MAX_WIN_STREAK } from '~entities/nft';
import { MarketplaceItemShell } from '~entities/marketplace';

import {
  MarketplaceBuyNFTForm,
  MarketplaceCurrencyValue,
  useMarketplaceItemPaymentInfo,
} from '~features/marketplace';

import { MarketplaceItemNftImage } from '../MarketplaceItemNftImage';

import { MarketplaceItemNftProps } from './types';

export const MarketplaceItemNft: FC<MarketplaceItemNftProps> = ({
  marketplaceNft,
  onOpenDetails,
}) => {
  const { t } = useTranslation();

  const [currency, setCurrency] = useState<MarketplaceCurrencyValue>(
    MarketplaceCurrencyValue.Matic
  );

  const { currentCurrencyItemInfo } = useMarketplaceItemPaymentInfo({
    rarity: marketplaceNft.rarity,
    type: marketplaceNft.type,
    currency,
  });

  const maxLives = NFT_RARITY_TO_MAX_LIVES_MAP[marketplaceNft.rarity];
  const neededWinStreak = NFT_RARITY_TO_MAX_WIN_STREAK[marketplaceNft.rarity];

  return (
    <MarketplaceItemShell
      icon={<MarketplaceItemNftImage src={marketplaceNft.imagePath} alt="Card" />}
      name={t(marketplaceNft.name)}
      params={[
        { label: `${t('Marketplace.lives')} `, value: `${maxLives}/${maxLives}` },
        { label: `${t('Marketplace.winStreak')} `, value: `${neededWinStreak}/${neededWinStreak}` },
        {
          label: `${t('Marketplace.winReward')} `,
          value: `${marketplaceNft.rewardTokensAmount} MCN`,
        },
        {
          label: `${t('Marketplace.price')} `,
          value: `${marketplaceNft.price} TON`,
        },
      ]}
    >
      <MarketplaceBuyNFTForm
        rarity={marketplaceNft.rarity}
        price={marketplaceNft.price}
        currency={currency}
        onChangeCurrency={setCurrency}
        onOpenDetails={onOpenDetails}
      />
    </MarketplaceItemShell>
  );
};

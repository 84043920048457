import { FC } from 'react';
import { AnimatePresence, AnimationProps } from 'framer-motion';
import { clsx } from 'clsx';

import { Icon } from '~shared/ui';

import { useBattleDragHighlight } from '~entities/battle';

import { useViewerWalletReturnCard } from '../../model';

import { Root, viewerWalletReturnDropZoneClasses } from './styled';

export const ViewerWalletReturnDropZone: FC = () => {
  const { highlight, ...highlightProps } = useBattleDragHighlight();

  const { dropRef, isDropZoneOpen } = useViewerWalletReturnCard();

  return (
    <AnimatePresence>
      {isDropZoneOpen && (
        <Root
          ref={dropRef}
          className={clsx(viewerWalletReturnDropZoneClasses.root, {
            [viewerWalletReturnDropZoneClasses.highlight]: highlight,
          })}
          {...highlightProps}
          {...animationProps}
        >
          <Icon name="back" size={24} />
        </Root>
      )}
    </AnimatePresence>
  );
};

const animationProps: AnimationProps = {
  initial: {
    height: 0,
    opacity: 0,
    marginBottom: 0,
  },
  animate: {
    height: 100,
    opacity: 1,
    marginBottom: 12,
    transition: { duration: 0.2, type: 'tween' },
  },
  exit: {
    height: 0,
    opacity: 0,
    marginBottom: 0,
    transition: { duration: 0.2, type: 'tween' },
  },
};

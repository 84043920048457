import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const Root = styled(Box, { name: 'EventDialogPlaceholder', slot: 'root' })(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  padding: '32px 20px',
  borderRadius: 8,
  minHeight: '236px',

  border: `1px dashed ${theme.palette.grey.A30}`,

  [theme.breakpoints.down('mobile')]: {
    marginTop: '20px',
    padding: '24px 16px',
    minHeight: 'initial',
    flex: 0.9,
    height: '100%',
  },
}));

import { NftRarity } from '~shared/api';

export enum TournamentsListFilter {
  All = 'all',
  Common = NftRarity.Common,
  Rare = NftRarity.Rare,
  Epic = NftRarity.Epic,
  Legendary = NftRarity.Legendary,
  Mythic = NftRarity.Mythic,
}

import { Web3ReactHooks } from '@web3-react/core';
import { Connector } from '@web3-react/types';

import { IconAssetNamesType } from '~shared/ui';

export enum ConnectorId {
  MetaMask = 'metaMask',
  WalletConnect = 'walletConnectLegacy',
  Coinbase = 'coinbaseWallet',
  Trust = 'trustWallet',
}

export type WalletBalance = {
  mcn: number;
  ton: number;
};

export type WalletBalanceReference = {
  ton: {
    usd: number | null;
  };
  mcn: {
    usd: number | null;
  };
};

export type WalletState = {
  isConnectWalletDialogOpen: boolean;
  balance: WalletBalance;
  balanceReference: WalletBalanceReference;
  isWarnDialogOpen: boolean;
  isWalletConnectedDialogOpen: boolean;
  isOnboardingShownAfterConnect: boolean;
  isCorrectWalletConnected: boolean;
  isProofed: boolean;
};

// TODO: check what error displays on InsufficientFunds
export enum TransactionError {
  Rejected = 'UserRejectsError',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
}

export enum ConnectionType {
  Injected = 'INJECTED',
  Coinbase = 'COINBASE_WALLET',
  WalletConnectV2 = 'WALLET_CONNECT_V2',
}

export interface Connection {
  name: string;
  connector: Connector;
  hooks: Web3ReactHooks;
  type: ConnectionType;
  icon: IconAssetNamesType;
  installed: boolean;
  downloadLink?: string;
  deepLink?: string;
}

export enum SupportedChainId {
  Polygon = 137,
}

type LastTransactionId = {
  '@type': 'internal.transactionId';
  'lt': string;
  'hash': string;
};

type Result = {
  wallet: boolean;
  balance: string;
  account_state: string;
  wallet_type: string;
  seqno: number;
  last_transaction_id: LastTransactionId;
  wallet_id: number;
};

export type ApiResponse = {
  ok: boolean;
  result: Result;
};

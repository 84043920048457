import { styled } from '~shared/lib/styles';
import { NftRarity } from '~shared/api';

import { NftRarityBadge } from '~entities/nft';

export const CardRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  transition: theme.transitions.create(['box-shadow']),

  borderRadius: 12,
  background: theme.palette.paper.semi,

  ['&:hover']: {
    boxShadow: '0px 0px 8px #42BAFF',
    cursor: 'pointer',
  },
}));

interface CardRarityLabelProps {
  $rarity: NftRarity;
}

export const CardRarityLabel = styled(NftRarityBadge)<CardRarityLabelProps>(() => ({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: 14 / 12,
  textAlign: 'center',
  writingMode: 'vertical-rl',
  transform: 'rotate(180deg)',
  padding: '0 6px',
  border: 'none',
  borderRadius: 0,
}));

export const CardContentContainer = styled('div')(({ theme }) => ({
  flex: 1,
  padding: 20,

  [theme.breakpoints.down('mobile')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const CardTopContainer = styled('div')(() => ({
  display: 'flex',
  gap: 18,
}));

export const CardBottomContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',

  marginTop: 20,
}));

interface CardInfoHeadingProps {
  $limitReached?: boolean;
}

export const CardInfoHeading = styled('div')<CardInfoHeadingProps>(({ theme, $limitReached }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  fontSize: 15,
  fontWeight: 600,
  lineHeight: 18 / 15,
  letterSpacing: -0.2,

  color: $limitReached ? theme.palette.error.main : theme.palette.text.primary,
}));

export const CardInfoDescriptionElement = styled('div')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 14 / 13,
  color: theme.palette.grey.A50,
  marginTop: 8,
}));

export const CardJoinButtonContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('mobile')]: {
    alignSelf: 'stretch',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}));

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '~shared/lib/hooks';
import { getAvatarAbsoluteUri } from '~shared/lib/utils';
import { Avatar, Box, Button, ButtonIcon, Divider, Stack, Typography } from '~shared/ui';

import {
  NFT_RARITY_TO_MAX_LIVES_MAP,
  NFT_RARITY_TO_MAX_WIN_STREAK,
  NFT_RARITY_TO_READABLE_KEY_MAP,
  NftCard,
  NftCardInfoStatusAuction,
} from '~entities/nft';

import { AuctionHotOffer } from '~entities/auction/auction-hot-offer';

import { useAuctionModel } from '~features/auction';

import { StyledHotOfferContainer } from './styled';

import { AuctionCardDetailsPropertiesProps } from './types';

export const AuctionCardDetailsProperties: FC<AuctionCardDetailsPropertiesProps> = ({
  card,
  onClose,
}) => {
  const { isMobile } = useMediaQuery();

  const { t } = useTranslation();

  const { isHotOfferCard, onOpenCardDetailedInfo } = useAuctionModel();

  return (
    <Stack display="grid" gridTemplateColumns={isMobile ? '1fr' : '1fr auto'} gap={30 / 8}>
      <Stack spacing={20 / 8}>
        <Stack
          spacing={20 / 8}
          direction={isMobile ? 'row' : 'column'}
          alignItems="center"
          position="relative"
        >
          {isMobile && <ButtonIcon variant="plain" name="chevron-left" onClick={onClose} />}
          {isHotOfferCard(card) && (
            <StyledHotOfferContainer>
              <AuctionHotOffer />
            </StyledHotOfferContainer>
          )}
          <Box>
            <NftCard hideStates nft={card.tokenId} />
          </Box>
          <Stack spacing={10 / 8} width="100%">
            <Stack spacing={6 / 8} alignItems={isMobile ? 'start' : 'center'}>
              <Typography
                variant="h2"
                sx={{ fontSize: '16px' }}
                textAlign={isMobile ? 'start' : 'center'}
                textTransform="capitalize"
              >
                {NFT_RARITY_TO_READABLE_KEY_MAP[card.rarity]}
              </Typography>
              <Button
                variant="text"
                onClick={onOpenCardDetailedInfo(
                  card.creatorNickname,
                  card.creatorAvatar,
                  String(card.tokenId),
                  card.creator
                )}
              >
                {t('Marketplace.seeCardInfo')}
              </Button>
            </Stack>

            <NftCardInfoStatusAuction
              message={t('Events.auctionTimeLeft')}
              betsAcceptedUntil={new Date(card.betsAcceptedUntil)}
            />
          </Stack>
        </Stack>
        <Stack spacing={16 / 8}>
          {isMobile && <Divider />}
          <Stack spacing={8 / 8}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography color="secondary" sx={{ fontSize: '13px' }}>
                {t('Marketplace.startingPrice')}
              </Typography>
              <Typography variant="h3">
                {card.startingPrice} {card.currency}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography color="secondary" sx={{ fontSize: '13px' }} textTransform="capitalize">
                {t('Other.owner')}
              </Typography>
              <Stack spacing={10 / 8} direction="row" alignItems="center">
                <Typography variant="h3">{card.creatorNickname}</Typography>
                <Avatar src={getAvatarAbsoluteUri(card.creatorAvatar)} size={18} />
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography color="secondary" sx={{ fontSize: '13px' }}>
                {t('Marketplace.lives')}
              </Typography>
              <Typography variant="h3">
                {card.lives}/{NFT_RARITY_TO_MAX_LIVES_MAP[card.rarity]}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography color="secondary" sx={{ fontSize: '13px' }}>
                {t('Marketplace.winStreak')}
              </Typography>
              <Typography variant="h3">
                {card.winStreak}/{NFT_RARITY_TO_MAX_WIN_STREAK[card.rarity]}
              </Typography>
            </Stack>
            {/* TODO: Display wins and loses amount */}
            {isMobile && <Divider />}
          </Stack>
        </Stack>
      </Stack>
      {!isMobile && <Divider orientation="vertical" />}
    </Stack>
  );
};

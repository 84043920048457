import { useEffect } from 'react';

import { useQueryNextNfts, useQueryViewerNfts, useSynchronizeEventDialogBet } from '~entities/nft';
import { useViewerWalletTournamentsList } from '~widgets/viewer-wallet';

export const useViewerWalletInitialization = () => {
  useQueryViewerNfts();
  useViewerWalletTournamentsList();
  useSynchronizeEventDialogBet();

  const { getNextWalletCards } = useQueryNextNfts();

  useEffect(() => {
    // getNextWalletCards will be called itself whenever the cursor updates (by useCallback dependency)
    getNextWalletCards();
  }, [getNextWalletCards]);
};

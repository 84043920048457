import { useEventModel } from '~entities/event';

import { Box, Typography } from '~shared/ui';

import { RawDifficulty } from '~shared/api';

import { Coeff, CoeffContainer, CoeffWrapper } from './styled';

export const EventDialogCoefficients = () => {
  const { event, battle } = useEventModel();

  if (!event || battle.cards.length !== 0) {
    return;
  }

  const isDrawAvailable = event.matchDifficulty.draw.label !== 'Unknown';

  // TODO: move this  later
  const getCoeff = (label: RawDifficulty) => {
    switch (label) {
      case RawDifficulty.Obvious:
        return 'x0';
      case RawDifficulty.Easy:
        return 'x0.5';
      case RawDifficulty.Standart:
        return 'x1';
      case RawDifficulty.Hard:
        return 'x1.5';
      case RawDifficulty.Insane:
        return 'x3';
    }
  };

  const homeColor = event.matchDifficulty.home.color;
  const drawColor = event.matchDifficulty.draw.color;
  const awayColor = event.matchDifficulty.away.color;

  return (
    <CoeffContainer>
      <CoeffWrapper $color={homeColor}>
        <Box display="flex" alignItems="center" justifyContent="center" gap="8px" maxHeight={16}>
          <Coeff $color={homeColor}>{event.matchDifficulty.home.label}</Coeff>
          <Typography fontSize={14} lineHeight={16.44} fontWeight={700}>
            {getCoeff(event.matchDifficulty.home.label)}
          </Typography>
        </Box>
      </CoeffWrapper>
      <CoeffWrapper $color={isDrawAvailable ? drawColor : ''}>
        {isDrawAvailable && (
          <Box display="flex" alignItems="center" justifyContent="center" gap="8px" maxHeight={16}>
            <Coeff $color={drawColor}>{event.matchDifficulty.draw.label}</Coeff>
            <Typography fontSize={14} lineHeight={16.44} fontWeight={700}>
              {getCoeff(event.matchDifficulty.draw.label)}
            </Typography>
          </Box>
        )}
      </CoeffWrapper>
      <CoeffWrapper $color={awayColor}>
        <Box display="flex" alignItems="center" justifyContent="center" gap="8px" maxHeight={16}>
          <Coeff $color={awayColor}>{event.matchDifficulty.away.label}</Coeff>
          <Typography fontSize={14} lineHeight={16.44} fontWeight={700}>
            {getCoeff(event.matchDifficulty.away.label)}
          </Typography>
        </Box>
      </CoeffWrapper>
    </CoeffContainer>
  );
};

import { useQuery } from 'react-query';

import {
  ApiGetTournamentMappedResponseData,
  ApiTournamentStatus,
  getPersonalTournaments,
} from '~shared/api';

import { useViewerModel } from '~entities/viewer';

import { useViewerWalletModel } from '../model';

export const useViewerWalletTournamentsList = () => {
  const {
    /***/
    toggleWalletTabToTournaments,
    isTournamentWalletTabAlreadyToggled,
  } = useViewerWalletModel();

  const { tonAddress } = useViewerModel();

  const filterNonActiveTournaments = (list: Array<ApiGetTournamentMappedResponseData>) => {
    return list?.filter((tournament) => {
      return [ApiTournamentStatus.Planned, ApiTournamentStatus.Active].includes(tournament.status);
    });
  };

  const { data: tournamentsList } = useQuery(
    ['wallet-tournaments', tonAddress],
    () => getPersonalTournaments({ tonWallet: tonAddress }),
    {
      enabled: Boolean(tonAddress),
      onSuccess: (list) => {
        const activeList = filterNonActiveTournaments(list);

        if (activeList.length && !isTournamentWalletTabAlreadyToggled) {
          toggleWalletTabToTournaments();
        }
      },
    }
  );

  return {
    tournamentsList: tournamentsList ?? null,
    activeTournamentsList: tournamentsList ? filterNonActiveTournaments(tournamentsList) : null,
    hasActiveTournaments: tournamentsList
      ? filterNonActiveTournaments(tournamentsList).length
      : false,
  };
};

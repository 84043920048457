import { AxiosError } from 'axios';

import { apiInstance, tonApiInstance } from '../apiInstance';

import { AuthRoutes } from './routes';

import {
  ApiAuthTonProof,
  ApiAuthTonProofPayload,
  ApiGetSignOut,
  ApiPostCheckEmail,
  ApiPostResendAuthCode,
  ApiPostSignIn,
  ApiPostSignUp,
  ApiPostSignUpResponseData,
  ApiPostSignUpVerification,
  ApiPostTonSignIn,
  ApiPostTonSignInResponseData,
  ApiPostTonSignUp,
  ApiPostTonSignUpResponseData,
} from './types';

export const postSignUp: ApiPostSignUp = (data) => {
  return new Promise<ApiPostSignUpResponseData>((resolve, reject) => {
    apiInstance
      .post<ApiPostSignUpResponseData>(AuthRoutes.signUp(), data)
      .then(({ data }) => resolve(data))
      .catch((error: AxiosError<ApiPostSignUpResponseData>) => {
        switch (error.response?.status) {
          case 403:
          case 400:
            resolve({ result: error.response.data!.result });
            break;

          default:
            reject(error);
        }
      });
  });
};

export const postTonProof: ApiAuthTonProof = (data) => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .post(AuthRoutes.tonProof(), data)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getTonProofPayload: ApiAuthTonProofPayload = () => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .get(AuthRoutes.tonProofPayload())
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const postSignIn: ApiPostSignIn = (data?) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(AuthRoutes.signIn(), data, { withCredentials: true })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error.response.data));
  });
};

export const getSignOut: ApiGetSignOut = () => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get(AuthRoutes.signOut())
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error.response.data));
  });
};

export const postResendAuthCode: ApiPostResendAuthCode = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(AuthRoutes.resendAuthCode(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postSignUpVerification: ApiPostSignUpVerification = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(AuthRoutes.signUpVerification(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postCheckEmail: ApiPostCheckEmail = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(AuthRoutes.checkEmail(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postTonSignUp: ApiPostTonSignUp = (data) => {
  return new Promise<ApiPostTonSignUpResponseData>((resolve, reject) => {
    apiInstance
      .post<ApiPostTonSignUpResponseData>(AuthRoutes.tonSignUp(), data)
      .then(({ data }) => resolve(data))
      .catch((error: AxiosError<ApiPostTonSignUpResponseData>) => {
        switch (error.response?.status) {
          case 403:
          case 400:
            resolve({ result: error.response.data!.result });
            break;

          default:
            reject(error);
        }
      });
  });
};

export const postTonSignIn: ApiPostTonSignIn = (data) => {
  return new Promise<ApiPostTonSignInResponseData>((resolve, reject) => {
    apiInstance
      .post<ApiPostTonSignInResponseData>(AuthRoutes.tonSignIn(), data)
      .then(({ data }) => resolve(data))
      .catch((error: AxiosError<ApiPostTonSignInResponseData>) => {
        switch (error.response?.status) {
          case 403:
          case 400:
            resolve({ result: error.response.data!.result });
            break;

          default:
            reject(error);
        }
      });
  });
};

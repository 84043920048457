import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Icon, Stack, Tab, Tabs, TabsList } from '~shared/ui';

import { EventDialogTab, EventStatus, useEventModel } from '~entities/event';

import { EventBadge } from '../../styled';

import { EventDialogTabsProps } from './types';

export const EventDialogTabs: FC<EventDialogTabsProps> = ({ ...tabsProps }) => {
  const { t } = useTranslation();

  const {
    tab,
    eventStatus,
    isUserBattleParticipant,
    isUserCallParticipant,
    onTabChange,
    isStatisticsTabDisabled,
    eventBroadcastAvailable,
  } = useEventModel();

  return (
    <Tabs value={tab} onChange={onTabChange} {...tabsProps}>
      <TabsList>
        <Tab value={EventDialogTab.Battle}>
          <Stack direction="row" spacing={10 / 8} alignItems="center">
            <Box>{t('BattlePopUp.battleTab')}</Box>

            {isUserBattleParticipant && <Icon name="success-circle-outlined" size={12} />}
          </Stack>
        </Tab>

        {/* <Tab value={EventDialogTab.Call}>
          <Stack direction="row" spacing={10 / 8} alignItems="center">
            <Box>{t('BattlePopUp.callTab')}</Box>

            {isUserCallParticipant && <Icon name="success-circle-outlined" size={12} />}

            {!isUserCallParticipant && eventStatus === EventStatus.Pending && (
              <EventBadge>{t('BattlePopUp.callBadgeWinCard')}</EventBadge>
            )}
          </Stack>
        </Tab> */}

        <Tab value={EventDialogTab.Statistics} disabled={isStatisticsTabDisabled}>
          {t('BattlePopUp.statisticsTab')}
        </Tab>

        <Tab value={EventDialogTab.Stream} disabled={!eventBroadcastAvailable}>
          {t('BattlePopUp.stream')}
        </Tab>
      </TabsList>
    </Tabs>
  );
};

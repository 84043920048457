import { styled } from '~shared/lib/styles';

export const checkboxClasses = {
  root: 'Checkbox-root',
  iconContainer: 'Checkbox-container',
  icon: 'Checkbox-icon',
  input: 'Checkbox-input',

  checked: 'Checkbox-checked',
  disabled: 'Checkbox-disabled',

  round: 'Checkbox-round',
};

export const UIKitCheckboxRoot = styled('div', { name: 'Checkbox' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: 18,
  height: 18,
  marginTop: 1,
  marginBottom: 1,

  borderRadius: 4,
  position: 'relative',
  borderWidth: '1.5px',
  borderStyle: 'solid',
  borderColor: theme.palette.grey.A50,
  transition: theme.transitions.create(['box-shadow', 'border-color'], {
    easing: theme.transitions.easing.easeInOut,
  }),

  [`& .${checkboxClasses.iconContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`&.${checkboxClasses.round}`]: {
    borderRadius: 999,
    borderColor: theme.palette.text.primary,

    [`&.${checkboxClasses.checked}`]: {
      borderColor: theme.palette.text.primary,
    },

    [`&:hover:not(${checkboxClasses.disabled})`]: {
      borderColor: theme.palette.text.primary,
      boxShadow: `0 0 0 9px inset ${theme.palette.grey.A10}`,
      cursor: 'pointer',
    },

    [`&.${checkboxClasses.checked}`]: {
      boxShadow: `0 0 0 9px inset ${theme.palette.primary.main}`,
      borderColor: theme.palette.text.primary,

      ['&:hover:not(:disabled)']: {
        boxShadow: `0 0 0 9px inset ${theme.palette.primary.light}`,
      },
    },
  },

  [`&:hover:not(${checkboxClasses.disabled})`]: {
    borderColor: theme.palette.primary.light,
    cursor: 'pointer',
  },

  [`&.${checkboxClasses.disabled}`]: {
    opacity: 0.5,
  },

  [`&.${checkboxClasses.checked}`]: {
    boxShadow: `0 0 0 9px inset ${theme.palette.primary.main}`,
    borderColor: theme.palette.primary.main,

    ['&:hover:not(:disabled)']: {
      boxShadow: `0 0 0 9px inset ${theme.palette.primary.light}`,
    },
  },
}));

export const UIKitCheckboxInput = styled('input', { name: 'Checkbox', slot: 'input' })(() => ({
  position: 'absolute' as const,
  inset: 0,
  opacity: 0,

  ['&:hover:not(:disabled)']: {
    cursor: 'pointer',
  },
}));

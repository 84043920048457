import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  ContentPlaceholder,
  Stack,
  Tab,
  Tabs,
  TabsList,
  ToggleButton,
  ToggleButtonPillContainer,
} from '~shared/ui';

import { useMediaQuery } from '~shared/lib/hooks';

import {
  TournamentCard,
  TournamentCardShimmer,
  useQueryTournamentsList,
} from '~entities/tournament';

import { JoinTournamentComplexButton } from '~features/tournament';

import { TOURNAMENTS_LIST_FILTERS, TournamentListTab } from '../lib';
import { useTournamentsList } from '../model';

export const TournamentsList: FC = () => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();

  const tournamentsListQuery = useQueryTournamentsList();

  const {
    /***/
    list,
    tabValue,
    rarityFilters,
    toggleRarityFilter,
    onChangeTab,
  } = useTournamentsList(tournamentsListQuery.list ?? []);

  const renderList = () => {
    const spacing = isMobile ? 16 / 8 : 24 / 8;

    if (tournamentsListQuery.isLoading) {
      return (
        <Stack mt={spacing} spacing={spacing}>
          <TournamentCardShimmer />
          <TournamentCardShimmer />
          <TournamentCardShimmer />
          <TournamentCardShimmer />
        </Stack>
      );
    }

    if (list.length) {
      return (
        <Stack mt={spacing} spacing={spacing}>
          {list.map((tournament) => (
            <TournamentCard
              key={tournament.tournamentId}
              tournament={tournament}
              joinButton={<JoinTournamentComplexButton tournament={tournament} />}
            />
          ))}
        </Stack>
      );
    }

    return (
      <ContentPlaceholder
        heading={t('Tournaments.noTournaments')}
        message={t('Tournaments.noTournamentsForSpecifiedParameters')}
      />
    );
  };

  return (
    <Fragment>
      <Tabs value={tabValue} onChange={onChangeTab}>
        <TabsList>
          <Tab value={TournamentListTab.All}>{t('Tournaments.tabAll')}</Tab>
          <Tab value={TournamentListTab.Upcoming}>{t('Tournaments.tabUpcoming')}</Tab>
          <Tab value={TournamentListTab.Active}>{t('Tournaments.tabActive')}</Tab>
          <Tab value={TournamentListTab.Past}>{t('Tournaments.tabPast')}</Tab>
        </TabsList>
      </Tabs>

      <Box alignSelf="flex-start" mt={16 / 8}>
        <ToggleButtonPillContainer>
          {TOURNAMENTS_LIST_FILTERS.map((filter) => {
            return (
              <ToggleButton
                key={filter.value}
                variant="pill"
                onChange={(event, value) => toggleRarityFilter(value)}
                selected={rarityFilters.includes(filter.value.toString())}
                value={filter.value}
              >
                {t(filter.label)}
              </ToggleButton>
            );
          })}
        </ToggleButtonPillContainer>
      </Box>

      {renderList()}
    </Fragment>
  );
};

import compose from 'compose-function';

import { withErrorBoundary } from './withErrorBoundary';

import { withQuery } from './withQuery';
import { withRouter } from './withRouter';
import { withSnackbar } from './withSnackbar';
import { withStore } from './withStore';
import { withDnd } from './withDnd';
import { withTheme } from './withTheme';
import { withTransactionStatus } from './withTransactionStatus';
import { withWeb3ReactCore } from './withWeb3ReactCore';
import { withi18n } from './withi18n';
import { withTonConnectUI } from './withTonConnectUI';
import { withWebApp } from './withWebApp';

export const withProviders = compose(
  withStore,
  withTheme,
  withRouter,
  withTransactionStatus,
  withDnd,
  withQuery,
  withErrorBoundary,
  withWeb3ReactCore,
  withi18n,
  withSnackbar,
  withTonConnectUI,
  withWebApp
);

import { useTranslation } from 'react-i18next';

import { useAuctionCardsListEffects, useAuctionModel } from '~features/auction';

import { ApiGetAuctionCardMappedData } from '~shared/api';
import { Typography } from '~shared/ui';

import { BestAuctionListShimmer } from './BestAuctionListShimmer';

import { BestAuctionItem } from './BestAuctionItem';
import { HorizontalBestAuctionList } from './HorizontalBestList';

import { BestActionContainer } from './styled';

export const BestAuctionList = () => {
  const { hotOfferCards, isLoading, onOpenCardDetailedInfo } = useAuctionModel();
  useAuctionCardsListEffects();
  const { t } = useTranslation();

  const renderAuctionCard = (card: ApiGetAuctionCardMappedData) => {
    return (
      <BestAuctionItem key={card.tokenId} card={card} onOpenCardInfo={onOpenCardDetailedInfo} />
    );
  };

  if (!isLoading && hotOfferCards.length === 0) {
    return null;
  }

  return (
    <BestActionContainer>
      <Typography variant="h1">{t('Other.bestFromAuction')}</Typography>

      <HorizontalBestAuctionList>
        {!isLoading ? (
          hotOfferCards.map((value: any) => renderAuctionCard(value))
        ) : (
          <BestAuctionListShimmer />
        )}
      </HorizontalBestAuctionList>
    </BestActionContainer>
  );
};

import { useTonWallet } from '@tonconnect/ui-react';
import { useMemo } from 'react';

import { ApiGetBattlesResponseData } from '~shared/api';

import {
  useNftSelector,
  useNftWithRelatedBattlesSelector,
  useNftWithRelatedCallsSelector,
} from '~entities/nft';

export const useSuppliedNftBattleList = (list: ApiGetBattlesResponseData) => {
  const wallet = useTonWallet();
  const { nfts } = useNftSelector();

  const nftsWithRelatedBattle = useNftWithRelatedBattlesSelector();
  const nftsWithRelatedCall = useNftWithRelatedCallsSelector();

  const auditionedList = useMemo(() => {
    if (Boolean(nfts) && Boolean(list) && Boolean(wallet)) {
      return list.map((battle) => {
        const relatedBetNft = nftsWithRelatedBattle.find(
          (nft) => nft.relatedEvent?.id === battle.id
        );

        const relatedCallNft = nftsWithRelatedCall.find(
          (nft) => nft.relatedEvent?.id === battle.id
        );

        if (relatedBetNft || relatedCallNft) {
          return {
            ...battle,
            relatedNft: {
              relatedBet: relatedBetNft?.relatedEvent,
              relatedCall: relatedCallNft?.relatedEvent,
            },
          };
        }

        return {
          ...battle,
          bets: undefined,
          relatedNft: {
            relatedBet: undefined,
            relatedCall: undefined,
          },
        };
      });
    }

    return list;
  }, [wallet, list, nfts, nftsWithRelatedBattle, nftsWithRelatedCall]);

  return {
    auditionedList,
  };
};

import { ApiCommonLeagueEnum, ApiCommonSportEnum } from '~shared/api';
import { routes } from '~shared/config';

import { SportItemConfig } from '../types';

export const CS2LeaguesConfig: { [key in ApiCommonLeagueEnum]?: SportItemConfig } = {
  [ApiCommonLeagueEnum.CS2_ESLDA]: {
    id: 'CS2_ESLDA',
    label: 'ESL DA',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_ESLDA,
    dates: [
      { startDate: new Date('2023-12-15T00:00:00Z'), endDate: new Date('2023-12-17T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_ESLDA,
    tournament: ApiCommonLeagueEnum.CS2_ESLDA,
  },

  [ApiCommonLeagueEnum.CS2_IEM]: {
    id: 'CS2_IEM',
    label: 'IEM',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_IEM,
    dates: [
      { startDate: new Date('2023-07-25T00:00:00Z'), endDate: new Date('2023-08-08T23:59:00Z') },
      { startDate: new Date('2023-10-16T00:00:00Z'), endDate: new Date('2023-10-22T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_IEM,
    tournament: ApiCommonLeagueEnum.CS2_IEM,
  },

  [ApiCommonLeagueEnum.CS2_ESLPRO]: {
    id: 'CS2_ESLPRO',
    label: 'ESL PRO',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_ESLPRO,
    dates: [
      { startDate: new Date('2023-07-10T00:00:00Z'), endDate: new Date('2023-09-26T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_ESLPRO,
    tournament: ApiCommonLeagueEnum.CS2_ESLPRO,
  },

  [ApiCommonLeagueEnum.CS2_ESLDW]: {
    id: 'CS2_ESLDW',
    label: 'ESL DW',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_ESLDW,
    dates: [
      { startDate: new Date('2023-11-24T00:00:00Z'), endDate: new Date('2023-11-28T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_ESLDW,
    tournament: ApiCommonLeagueEnum.CS2_ESLDW,
  },

  [ApiCommonLeagueEnum.CS2_FRLG]: {
    id: 'CS2_FRLG',
    label: 'FRLG',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_FRLG,
    dates: [
      { startDate: new Date('2023-10-11T00:00:00Z'), endDate: new Date('2023-10-15T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_FRLG,
    tournament: ApiCommonLeagueEnum.CS2_FRLG,
  },

  [ApiCommonLeagueEnum.CS2_BLAST]: {
    id: 'CS2_BLAST',
    label: 'BLAST',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_BLAST,
    dates: [
      { startDate: new Date('2023-12-12T00:00:00Z'), endDate: new Date('2023-12-17T23:59:00Z') },
      { startDate: new Date('2023-11-22T00:00:00Z'), endDate: new Date('2023-11-26T23:59:00Z') },
      { startDate: new Date('2023-10-04T00:00:00Z'), endDate: new Date('2023-10-08T23:59:00Z') },
      { startDate: new Date('2023-10-04T00:00:00Z'), endDate: new Date('2023-11-08T23:59:00Z') },
      { startDate: new Date('2023-07-13T00:00:00Z'), endDate: new Date('2023-07-23T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_BLAST,
    tournament: ApiCommonLeagueEnum.CS2_BLAST,
  },

  [ApiCommonLeagueEnum.CS2_IESF]: {
    id: 'CS2_IESF',
    label: 'IESF',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_IESF,
    dates: [
      { startDate: new Date('2023-08-25T00:00:00Z'), endDate: new Date('2023-09-03T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_IESF,
    tournament: ApiCommonLeagueEnum.CS2_IESF,
  },

  [ApiCommonLeagueEnum.CS2_GM8]: {
    id: 'CS2_GM8',
    label: 'GM8',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_GM8,
    dates: [
      { startDate: new Date('2023-05-17T00:00:00Z'), endDate: new Date('2023-08-20T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_GM8,
    tournament: ApiCommonLeagueEnum.CS2_GM8,
  },

  [ApiCommonLeagueEnum.CS2_ESL]: {
    id: 'CS2_ESL',
    label: 'ESL',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_ESL,
    dates: [
      { startDate: new Date('2023-12-15T00:00:00Z'), endDate: new Date('2023-12-17T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_ESL,
    tournament: ApiCommonLeagueEnum.CS2_ESL,
  },

  [ApiCommonLeagueEnum.CS2_PGL]: {
    id: 'CS2_PGL',
    label: 'PGL',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_PGL,
    dates: [
      { startDate: new Date('2024-03-17T00:00:00Z'), endDate: new Date('2024-03-31T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_PGL,
    tournament: ApiCommonLeagueEnum.CS2_PGL,
  },

  [ApiCommonLeagueEnum.CS2_RBC]: {
    id: 'CS2_RBC',
    label: 'RBC',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_RBC,
    dates: [
      { startDate: new Date('2023-10-26T00:00:00Z'), endDate: new Date('2023-11-08T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_RBC,
    tournament: ApiCommonLeagueEnum.CS2_RBC,
  },

  [ApiCommonLeagueEnum.CS2_EME]: {
    id: 'CS2_EME',
    label: 'EME',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_EME,
    dates: [
      { startDate: new Date('2023-11-29T00:00:00Z'), endDate: new Date('2023-12-03T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_EME,
    tournament: ApiCommonLeagueEnum.CS2_EME,
  },

  [ApiCommonLeagueEnum.CS2_ESLAPAC]: {
    id: 'CS2_ESLAPAC',
    label: 'ESL APAC',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_ESLAPAC,
    dates: [
      { startDate: new Date('2023-08-15T00:00:00Z'), endDate: new Date('2023-09-17T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_ESLAPAC,
    tournament: ApiCommonLeagueEnum.CS2_ESLAPAC,
  },

  [ApiCommonLeagueEnum.CS2_ESLEU]: {
    id: 'CS2_ESLEU',
    label: 'ESL EU',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_ESLEU,
    dates: [
      { startDate: new Date('2023-10-03T00:00:00Z'), endDate: new Date('2023-12-03T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_ESLEU,
    tournament: ApiCommonLeagueEnum.CS2_ESLEU,
  },

  [ApiCommonLeagueEnum.CS2_ESLNA]: {
    id: 'CS2_ESLNA',
    label: 'ESL NA',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_ESLNA,
    dates: [
      { startDate: new Date('2023-08-15T00:00:00Z'), endDate: new Date('2023-09-17T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_ESLNA,
    tournament: ApiCommonLeagueEnum.CS2_ESLNA,
  },

  [ApiCommonLeagueEnum.CS2_ESM]: {
    id: 'CS2_ESM',
    label: 'ESM',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_ESM,
    dates: [
      { startDate: new Date('2023-08-03T00:00:00Z'), endDate: new Date('2023-08-05T15:30:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_ESM,
    tournament: ApiCommonLeagueEnum.CS2_ESM,
  },

  [ApiCommonLeagueEnum.CS2_TPWC]: {
    id: 'CS2_TPWC',
    label: 'TPWC',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_TPWC,
    dates: [
      { startDate: new Date('2023-10-27T00:00:00Z'), endDate: new Date('2023-11-05T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_TPWC,
    tournament: ApiCommonLeagueEnum.CS2_TPWC,
  },

  [ApiCommonLeagueEnum.CS2_TPNA]: {
    id: 'CS2_TPNA',
    label: 'TP NA',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_TPNA,
    dates: [
      { startDate: new Date('2023-08-21T00:00:00Z'), endDate: new Date('2023-08-29T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_TPNA,
    tournament: ApiCommonLeagueEnum.CS2_TPNA,
  },

  [ApiCommonLeagueEnum.CS2_TPEU]: {
    id: 'CS2_TPEU',
    label: 'TP EU',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_TPEU,
    dates: [
      { startDate: new Date('2023-07-27T00:00:00Z'), endDate: new Date('2023-08-13T23:59:00Z') },
      { startDate: new Date('2023-09-04T00:00:00Z'), endDate: new Date('2023-09-18T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_TPEU,
    tournament: ApiCommonLeagueEnum.CS2_TPEU,
  },

  [ApiCommonLeagueEnum.CS2_CBCS]: {
    id: 'CS2_CBCS',
    label: 'CBCS',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_CBCS,
    dates: [
      { startDate: new Date('2023-08-17T00:00:00Z'), endDate: new Date('2023-08-27T23:59:00Z') },
      { startDate: new Date('2023-09-13T00:00:00Z'), endDate: new Date('2023-09-24T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_CBCS,
    tournament: ApiCommonLeagueEnum.CS2_CBCS,
  },

  [ApiCommonLeagueEnum.CS2_1xP]: {
    id: 'CS2_1xP',
    label: '1xP',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_1xP,
    dates: [
      { startDate: new Date('2023-09-25T00:00:00Z'), endDate: new Date('2023-10-06T23:59:00Z') },
      { startDate: new Date('2023-10-23T00:00:00Z'), endDate: new Date('2023-11-03T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_1xP,
    tournament: ApiCommonLeagueEnum.CS2_1xP,
  },

  [ApiCommonLeagueEnum.CS2_PWAP]: {
    id: 'CS2_PWAP',
    label: 'PWAP',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_PWAP,
    dates: [
      { startDate: new Date('2023-08-29T00:00:00Z'), endDate: new Date('2023-09-03T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_PWAP,
    tournament: ApiCommonLeagueEnum.CS2_PWAP,
  },

  [ApiCommonLeagueEnum.CS2_CCTNE]: {
    id: 'CS2_CCTNE',
    label: 'CCT NE',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_CCTNE,
    dates: [
      { startDate: new Date('2023-07-12T00:00:00Z'), endDate: new Date('2023-07-31T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_CCTNE,
    tournament: ApiCommonLeagueEnum.CS2_CCTNE,
  },

  [ApiCommonLeagueEnum.CS2_CCTCEU]: {
    id: 'CS2_CCTCEU',
    label: 'CCT CEU',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_CCTCEU,
    dates: [
      { startDate: new Date('2023-07-17T00:00:00Z'), endDate: new Date('2023-08-13T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_CCTCEU,
    tournament: ApiCommonLeagueEnum.CS2_CCTCEU,
  },

  [ApiCommonLeagueEnum.CS2_EPLDiv1]: {
    id: 'CS2_EPLDiv1',
    label: 'EPL Div 1',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_EPLDiv1,
    dates: [
      { startDate: new Date('2023-07-10T00:00:00Z'), endDate: new Date('2023-07-28T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_EPLDiv1,
    tournament: ApiCommonLeagueEnum.CS2_EPLDiv1,
  },

  [ApiCommonLeagueEnum.CS2_SKYM]: {
    id: 'CS2_SKYM',
    label: 'SKY M',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_SKYM,
    dates: [
      { startDate: new Date('2023-08-26T00:00:00Z'), endDate: new Date('2023-08-27T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_SKYM,
    tournament: ApiCommonLeagueEnum.CS2_SKYM,
  },

  [ApiCommonLeagueEnum.CS2_IEMSYD]: {
    id: 'CS2_IEMSYD',
    label: 'IEM SYD',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_IEMSYD,
    dates: [
      { startDate: new Date('2023-08-09T00:00:00Z'), endDate: new Date('2023-10-22T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_IEMSYD,
    tournament: ApiCommonLeagueEnum.CS2_IEMSYD,
  },

  [ApiCommonLeagueEnum.CS2_GCLS]: {
    id: 'CS2_GCLS',
    label: 'GCLS',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_GCLS,
    dates: [
      { startDate: new Date('2023-07-20T00:00:00Z'), endDate: new Date('2023-07-21T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_GCLS,
    tournament: ApiCommonLeagueEnum.CS2_GCLS,
  },

  [ApiCommonLeagueEnum.CS2_LLP]: {
    id: 'CS2_LLP',
    label: 'LLP',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_LLP,
    dates: [
      { startDate: new Date('2023-07-21T00:00:00Z'), endDate: new Date('2023-07-29T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_LLP,
    tournament: ApiCommonLeagueEnum.CS2_LLP,
  },

  [ApiCommonLeagueEnum.CS2_KaBuM]: {
    id: 'CS2_KaBuM',
    label: 'KaBuM',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_KaBuM,
    dates: [
      { startDate: new Date('2023-07-21T00:00:00Z'), endDate: new Date('2023-07-23T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_KaBuM,
    tournament: ApiCommonLeagueEnum.CS2_KaBuM,
  },

  [ApiCommonLeagueEnum.CS2_ESEAPSCNA]: {
    id: 'CS2_ESEAPSCNA',
    label: 'ESEA PSC NA',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_ESEAPSCNA,
    dates: [
      { startDate: new Date('2023-07-20T00:00:00Z'), endDate: new Date('2023-07-22T23:59:00Z') },
      { startDate: new Date('2023-07-21T00:00:00Z'), endDate: new Date('2023-07-22T23:59:00Z') },
      { startDate: new Date('2023-07-22T00:00:00Z'), endDate: new Date('2023-07-23T23:59:00Z') },
      { startDate: new Date('2023-07-23T00:00:00Z'), endDate: new Date('2023-07-23T23:59:00Z') },
      { startDate: new Date('2023-07-24T00:00:00Z'), endDate: new Date('2023-07-26T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_ESEAPSCNA,
    tournament: ApiCommonLeagueEnum.CS2_ESEAPSCNA,
  },

  [ApiCommonLeagueEnum.CS2_CCTEE]: {
    id: 'CS2_CCTEE',
    label: 'CCT EE',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_CCTEE,
    dates: [
      { startDate: new Date('2023-08-01T00:00:00Z'), endDate: new Date('2023-08-20T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_CCTEE,
    tournament: ApiCommonLeagueEnum.CS2_CCTEE,
  },

  [ApiCommonLeagueEnum.CS2_CCTSA]: {
    id: 'CS2_CCTSA',
    label: 'CCT SA',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_CCTSA,
    dates: [
      { startDate: new Date('2023-07-31T00:00:00Z'), endDate: new Date('2023-08-13T23:59:00Z') },
      { startDate: new Date('2023-07-10T00:00:00Z'), endDate: new Date('2023-07-23T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_CCTSA,
    tournament: ApiCommonLeagueEnum.CS2_CCTSA,
  },

  [ApiCommonLeagueEnum.CS2_RCL]: {
    id: 'CS2_RCL',
    label: 'RCL',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_RCL,
    dates: [
      { startDate: new Date('2023-07-29T00:00:00Z'), endDate: new Date('2023-07-30T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_RCL,
    tournament: ApiCommonLeagueEnum.CS2_RCL,
  },

  [ApiCommonLeagueEnum.CS2_PCV]: {
    id: 'CS2_PCV',
    label: 'PC V',
    icon: null,
    illustration: '',
    route: routes.sports.CS2_PCV,
    dates: [
      { startDate: new Date('2023-08-01T00:00:00Z'), endDate: new Date('2023-08-26T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CS2,
    league: ApiCommonLeagueEnum.CS2_PCV,
    tournament: ApiCommonLeagueEnum.CS2_PCV,
  },
};

import { FC, cloneElement, isValidElement } from 'react';

import { Avatar, Box, Stack, Typography, TypographyProps } from '~shared/ui';

import { RawDifficulty } from '~shared/api';

import { Coeff, UserVoteCaptionName } from './styled';
import { UserCaptionVoteProps } from './types';

export const UserCaptionVote: FC<UserCaptionVoteProps> = ({
  avatar,
  caption,
  fromRight,
  nickname,
  coef,
}) => {
  const captionProps = fromRight ? ({ align: 'right', justifySelf: 'end' } as const) : undefined;

  if (!nickname) {
    return null;
  }

  // TODO: rewrite this part

  const getCoeff = (label?: RawDifficulty) => {
    switch (label) {
      case RawDifficulty.Obvious:
        return 0;
      case RawDifficulty.Easy:
        return 0.5;
      case RawDifficulty.Standart:
        return 1;
      case RawDifficulty.Hard:
        return 1.5;
      case RawDifficulty.Insane:
        return 3;
      default:
        return 1;
    }
  };

  const colorMap: Record<string, string> = {
    red: 'rgba(204, 40, 79, 0.3)',
    green: 'rgba(89, 146, 54, 0.3)',
    blue: 'rgba(41, 137, 227, 0.3)',
  };

  return (
    <Stack
      direction={fromRight ? 'row-reverse' : 'row'}
      alignItems="center"
      justifyContent="space-between"
      borderRadius="8px"
      spacing={8 / 8}
      bgcolor={coef ? colorMap[coef.color] : ''}
      paddingBlock="10px"
      paddingInline="12px"
    >
      {isValidElement(caption) && cloneElement<TypographyProps>(caption, captionProps)}
      {coef && (
        <Stack flexDirection="row" gap="8px">
          <Coeff $color={coef.color}>{coef.label}</Coeff>
          <Typography fontWeight={700} fontSize={14} lineHeight="16.44px">
            x{getCoeff(coef.label)}
          </Typography>
        </Stack>
      )}
      {/* <Avatar size={24} src={avatar} />

      <Stack spacing={2 / 8}>
        <UserVoteCaptionName align={fromRight ? 'right' : 'left'}>{nickname}</UserVoteCaptionName>
      </Stack> */}
    </Stack>
  );
};

import { useSearchParamsState } from '~shared/lib/hooks';

import { PROFILE_DIALOG_USER_ADDRESS_PARAM } from './config';

export const useProfileDialogModel = () => {
  const [address] = useSearchParamsState<string>(PROFILE_DIALOG_USER_ADDRESS_PARAM, '');

  return {
    address,
  };
};

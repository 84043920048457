import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fromNano } from '@ton/core';

import { Button, Select, SelectOnChange, SelectOption } from '~shared/ui';

import { useJoinTournament } from '../../../model';

import { ActionContainer } from './styled';
import { JoinTournamentFormProps } from './types';

// todo: refactoring
export const JoinTournamentForm: FC<JoinTournamentFormProps> = ({ tournament, onClose }) => {
  const { t } = useTranslation();
  const [currency, setCurrency] = useState<JoinTournamentCurrency | null>(null);

  const { joinPrices, defaultJoinPrice } = tournament;
  const computedCurrency = currency ?? defaultJoinPrice.currency;

  const currencyLabel =
    joinTournamentOptions.find((option) => option.value === computedCurrency)?.label ??
    defaultJoinPrice.currency;

  const computedOptions = joinPrices.map(
    (joinPrice) => joinTournamentOptions.find((option) => option.value === joinPrice.currency)!
  );

  const joinPrice = joinPrices.find((joinPrice) => joinPrice.currency === computedCurrency)!.value;

  const handleChangeCurrency: SelectOnChange = (event, value) => {
    setCurrency(value as JoinTournamentCurrency);
  };

  const { joinTournament } = useJoinTournament();

  return (
    <ActionContainer>
      <Select
        defaultValue={joinPrices[0].currency}
        value={computedCurrency}
        onChange={handleChangeCurrency}
      >
        {computedOptions.map((option) => (
          <SelectOption key={option.value} value={option.value}>
            {option.label}
          </SelectOption>
        ))}
      </Select>

      <Button onClick={() => joinTournament(onClose)}>
        {t('Tournaments.joinButton', { price: fromNano(joinPrice), currency: currencyLabel })}
      </Button>
    </ActionContainer>
  );
};

enum JoinTournamentCurrency {
  MCN = 'MCN',
  TON = 'TON',
}

const joinTournamentOptions = [
  { value: JoinTournamentCurrency.MCN, label: 'MCN' },
  { value: JoinTournamentCurrency.TON, label: 'TON' },
];

import { useDrop } from 'react-dnd';

import { useDispatch } from '~shared/lib/hooks';
import {
  NftDragItem,
  NftDragItemType,
  NftToMergeSerialNumber,
  nftActions,
  useNftCardModel,
} from '~entities/nft';

import { useEventModel } from '~entities/event';
import { useJoinTournamentModel } from '~features/tournament';

export const useViewerWalletReturnCard = () => {
  const dispatch = useDispatch();

  const {
    isDropZoneOpen,
    nftsToMerge: [firstNftToMerge],
  } = useNftCardModel();

  const { dropOutCard } = useEventModel();

  const { removeNftFromJoin } = useJoinTournamentModel();

  const [, dropRef] = useDrop<NftDragItem, any, any>({
    accept: [NftDragItemType.MoveOrLeave, NftDragItemType.Leave],
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
      };
    },
    drop(item) {
      // todo: make all changes to another state trough model (useNftModel)
      dispatch(nftActions.setNftHidden({ nftIds: [item.tokenId], isHidden: false }));
      dropOutCard(item.tokenId);

      removeNftFromJoin(item.tokenId);

      const isFirstNftRemovingFromMergeCell = item.tokenId === firstNftToMerge?.token_id;

      const nftToRemoveSerialNumber = isFirstNftRemovingFromMergeCell
        ? NftToMergeSerialNumber.First
        : NftToMergeSerialNumber.Second;

      dispatch(nftActions.removeNftToMerge([nftToRemoveSerialNumber]));
    },
  });

  return {
    dropRef,
    isDropZoneOpen,
  };
};

import { forwardRef, useEffect } from 'react';
import { clsx } from 'clsx';

import { useLoaded, useSwitcher } from '~shared/lib/hooks';
import { Nft, NftRarity } from '~shared/api';

import { getNftUri } from '../../lib';

import { NftCardMintingStatus } from './NftCardMintingStatus';
import { NftCardStates } from './NftCardStates';
import { NftNew } from './NftNew';

import { NftImage, Root, nftCardClasses } from './styled';
import { PlaceholderUrlMap } from './assets';
import { NftCardProps } from './types';
import { NftCardBlockedForTxStatus } from './NftCardBlockedForTxStatus/NftCardBlockedForTxStatus';

export const NftCard = forwardRef<unknown, NftCardProps>(
  (
    {
      /***/
      nft: _nft,

      size = 'md',
      highlightDensity = 'small',

      locked,
      highlight,
      isMintingSwitcher,
      handleNatively = false,
      hideStates,
      isNew,
      placeholder,

      className,
      children,
      ...htmlAttributes
    },
    ref
  ) => {
    const nft = typeof _nft === 'object' ? _nft : { token_id: String(_nft) };
    const tokenId = nft.token_id;
    const isMinting = useSwitcher(false);
    const isBlockedForTransaction = useSwitcher(false);

    const src = getNftUri(tokenId);
    const nftImageStatus = useLoaded({ src });
    const isNftImageLoaded = nftImageStatus === 'loaded';
    const shouldShowStates = !hideStates && !placeholder && !nft.isBlockedForTransaction;

    useEffect(() => {
      if (!handleNatively && !placeholder) {
        switch (nftImageStatus) {
          case 'loaded':
            isMinting.switchOff();
            isMintingSwitcher?.switchOff();
            break;
          case 'error':
            isMinting.switchOn();
            isMintingSwitcher?.switchOn();
            break;
        }
      }
    }, [handleNatively, isMinting, isMintingSwitcher, nftImageStatus, placeholder]);

    useEffect(() => {
      if (nft.isBlockedForTransaction) {
        isBlockedForTransaction.switchOn();
      } else {
        isBlockedForTransaction.switchOff();
      }
    }, [isBlockedForTransaction, nft.isBlockedForTransaction]);

    const computedClassName = clsx(
      {
        [nftCardClasses.sizeXss]: size === 'xss',
        [nftCardClasses.sizeXs]: size === 'xs',
        [nftCardClasses.sizeSm]: size === 'sm',
        [nftCardClasses.sizeMd]: size === 'md',
        [nftCardClasses.sizeLg]: size === 'lg',
        [nftCardClasses.sizeXl]: size === 'xl',

        [nftCardClasses.highlightSmall]: highlightDensity === 'small',
        [nftCardClasses.highlightMedium]: highlightDensity === 'large',

        [nftCardClasses.highlight]: highlight,
        [nftCardClasses.locked]: locked,
        [nftCardClasses.clickable]: htmlAttributes.onClick || htmlAttributes.onClickCapture,
      },
      className
    );

    const computedSrc = (() => {
      if (placeholder) {
        return PlaceholderUrlMap[nft.rarity ?? NftRarity.Common];
      }

      if (handleNatively || isNftImageLoaded) {
        return src;
      }

      if (isMinting.value) {
        return PlaceholderUrlMap[nft.rarity ?? NftRarity.Common];
      }

      return PlaceholderUrlMap[nft.rarity ?? NftRarity.Common];
    })();

    return (
      <Root className={computedClassName} ref={ref} data-nft-id={nft.token_id} {...htmlAttributes}>
        <NftImage src={computedSrc} />
        <NftCardMintingStatus show={isMinting.value} size={size} />
        <NftCardBlockedForTxStatus show={isBlockedForTransaction.value} size={size} />

        {shouldShowStates && <NftCardStates nft={nft as Nft} size={size} />}
        {isNew && <NftNew />}
        {handleNatively ? children : isNftImageLoaded ? children : null}
      </Root>
    );
  }
);

import { UseFormReturn } from 'react-hook-form';
import { BaseSyntheticEvent } from 'react';

import { SelectValue } from '~shared/ui';

import { SellInitialPriceFormValues } from '../../../model';

export interface SellCardFormProps {
  form: UseFormReturn<SellInitialPriceFormValues>;
  onSubmit(e: BaseSyntheticEvent): void;
  onSelectSellCoin: (
    e: MouseEvent | KeyboardEvent | FocusEvent | null,
    value: SelectValue | null
  ) => void;
}

export enum SellCardCurrency {
  MCN = 'MCN',
  TON = 'TON',
}

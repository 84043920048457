import { styled } from '~shared/lib/styles';
import { Avatar, Box, Typography, avatarClasses } from '~shared/ui';

export const eventTeamsClasses = {
  teamContainer: 'EventDialogTeams-teamContainer',
  leftContainer: 'EventDialogTeams-leftTeamContainer',
  rightContainer: 'EventDialogTeams-rightTeamContainer',
  nameContainer: 'EventDialogTeams-nameContainer',

  call: 'EventDialogTeams-call',
};

export const EventTeamsContainer = styled('div', {
  name: 'EventDialogContent',
  slot: 'eventTeamsContainer',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto 1fr',

  // bypass hidden overflow for Winner badge
  paddingTop: 7,
  marginTop: '17px !important',

  [`& .${eventTeamsClasses.rightContainer}`]: {
    [`& .${eventTeamsClasses.nameContainer}`]: {
      textAlign: 'right',
    },
  },

  [theme.breakpoints.down('mobile')]: {
    paddingBottom: 14,
    paddingTop: 14,
    borderBottom: `1px solid ${theme.palette.grey.A20}`,
  },
}));

export const EventHomeTeamContainer = styled('div', {
  name: 'EventDialogContent',
  slot: 'homeTeamContainer',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  // bypass hidden overflow for Winner badge
  paddingTop: 7,
  marginTop: -7,

  overflow: 'hidden',

  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export const EventAwayTeamContainer = styled(EventHomeTeamContainer, {
  name: 'EventDialogContent',
  slot: 'awayTeamContainer',
})(({ theme }) => ({
  justifyContent: 'flex-end',

  overflow: 'hidden',

  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
  },
}));

export const EventTeamsAvatar = styled(Avatar, {
  name: 'EventDialogContent',
  slot: 'eventTeamsAvatar',
})(({ theme }) => ({
  width: 48,
  height: 48,

  position: 'relative',
  boxShadow: '0px 0px 4px 0px rgba(16, 16, 16, 0.95)',

  [`&.${avatarClasses.hasSource}`]: {
    background: 'white',
  },

  [`& .${avatarClasses.img}`]: {
    objectFit: 'cover',
  },

  ['&:after']: {
    content: '""',
    display: 'block',
    position: 'absolute',
    inset: 0,
    borderRadius: '100%',
    boxShadow: `inset 0 0 0 1.5px ${theme.palette.grey.A30}`,
  },
}));
export const EventTeamsNameContainer = styled(
  'div',
  {}
)(({ theme }) => ({
  flex: 1,

  [theme.breakpoints.up('mobile')]: {
    marginRight: 16,
    marginLeft: 16,
  },

  [theme.breakpoints.down('mobile')]: {
    alignSelf: 'stretch',
    marginTop: 12,
  },
}));

export const EventTeamsVersus = styled(Typography, {
  name: 'EventDialogContent',
  slot: 'eventTeamsVersus',
})(({ theme }) => ({
  padding: 14,
  fontSize: 26,
  lineHeight: 1,
  fontWeight: 600,
  justifySelf: 'center',

  [theme.breakpoints.down('mobile')]: {
    fontSize: 22,
    lineHeight: 30 / 22,
  },
}));

export const EventTeamBadgeContainer = styled('div', { name: '', slot: '' })({
  position: 'absolute',
  inset: '-10px 0 auto 0',
  zIndex: 1,

  display: 'flex',
  justifyContent: 'center',
});

export const EventTeamDrawResult = styled(Box, {
  name: 'EventDialogContent',
  slot: 'eventTeamDrawResult',
})(({ theme }) => ({
  display: 'inline-block',
  background: 'rgba(0, 117, 255, 0.9)',
  zIndex: 2,
  bottom: 0,
  left: 10,
  padding: '7px 10px',
  borderRadius: 4,
  whiteSpace: 'nowrap',

  fontSize: 9,
  fontWeight: 700,
  lineHeight: 1,
  letterSpacing: -0.3375000059604645,

  [theme.breakpoints.down('mobile')]: {
    bottom: '15px',
    padding: '4px 6px',
  },
}));

export const EventDialogTeamsAvatarContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',

  ['& > *:nth-child(n+3)']: {
    marginLeft: -10,
  },
});

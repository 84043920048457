import { FC, useCallback } from 'react';

import { DateLabel } from '~shared/ui';
import { BattleChoiceId } from '~shared/api';

import { NftCard, NftCardsStack } from '~entities/nft';
import { useViewerSelector } from '~entities/viewer';
import { useEventModel } from '~entities/event';

import { getBattleShellStatus, getLeagueLogo, renderResultBadge } from '../../../lib';
import { BattleShellFactory, BattleTeam, BattleVersus } from '../../factory';
import { useDropBet } from '../../../model';

import { BattleBetProps } from './types';

export const BattleBet: FC<BattleBetProps> = ({
  size,
  variant,

  onClick,
  className,
  user,

  ...bet
}) => {
  const {
    homeTeam,
    awayTeam,
    date,
    result,
    choiceId: choice,
    tokenIds,
    eventId,
    matchDifficulty,
  } = bet;

  const viewer = useViewerSelector();

  const avatar = user ? user.avatar : viewer.avatar?.src;

  const { openBetEvent } = useEventModel();

  const dropProps = useDropBet({
    event: { ...bet, id: eventId },
    choice: choice,
    tokenIds,
    result,
  });

  const handleOpenEvent = useCallback(() => {
    openBetEvent(bet, { user });
  }, [openBetEvent]);

  const renderSlotByChoiceId = (slot: BattleChoiceId) => {
    if (slot.valueOf() === choice.valueOf()) {
      return (
        <NftCardsStack>
          {tokenIds.map((tokenId) => (
            <NftCard key={tokenId} nft={tokenId} size="xs" />
          ))}
        </NftCardsStack>
      );
    }
  };

  const getSlotUserAvatar = (slot: BattleChoiceId) => {
    if (slot.valueOf() === choice.valueOf()) {
      return avatar;
    }
  };

  return (
    <BattleShellFactory
      {...dropProps}
      overlapBackdropAvailable={dropProps.isOverlapBackdropAvailable}
      className={className}
      variant={variant}
      size={size}
      onClick={handleOpenEvent}
      homeTeam={
        <BattleTeam
          players={homeTeam.players.map((player) => ({
            name: player.name,
            shortName: player.shortName,
            avatar: player.logo,
          }))}
          badge={renderResultBadge(result, BattleChoiceId.HomeTeam)}
          slot={renderSlotByChoiceId(BattleChoiceId.HomeTeam)}
          slotUserAvatar={getSlotUserAvatar(BattleChoiceId.HomeTeam)}
          coef={matchDifficulty.home}
        />
      }
      awayTeam={
        <BattleTeam
          players={awayTeam.players.map((player) => ({
            name: player.name,
            shortName: player.shortName,
            avatar: player.logo,
          }))}
          badge={renderResultBadge(result, BattleChoiceId.AwayTeam)}
          slot={renderSlotByChoiceId(BattleChoiceId.AwayTeam)}
          slotUserAvatar={getSlotUserAvatar(BattleChoiceId.AwayTeam)}
          coef={matchDifficulty.away}
        />
      }
      versus={
        <BattleVersus
          badge={renderResultBadge(result, BattleChoiceId.Draw)}
          slot={renderSlotByChoiceId(BattleChoiceId.Draw)}
          slotUserAvatar={getSlotUserAvatar(BattleChoiceId.Draw)}
        />
      }
      sport={bet.sport}
      date={<DateLabel date={new Date(date)} />}
      leagueLogo={getLeagueLogo(bet.sport, bet.league)}
      status={getBattleShellStatus({ result, choice })}
    />
  );
};

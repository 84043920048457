import { useSelector } from '~shared/lib/hooks';

export const useNftSelector = () => useSelector((state) => state.nft);

export const useNftWithRelatedEventsSelector = () =>
  useNftSelector().nfts.filter((nft) => Boolean(nft.relatedEvent));

export const useNftWithRelatedCallsSelector = () =>
  useNftWithRelatedEventsSelector().filter((nft) => nft.relatedEvent?.isCall);

export const useNftWithRelatedBattlesSelector = () =>
  useNftWithRelatedEventsSelector().filter((nft) => !nft.relatedEvent?.isCall);

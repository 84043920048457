import { FC } from 'react';

import { Icon } from '~shared/ui';

import { Label, Root } from './styled';
import { NftMintingProps } from './types';

export const NftCardMintingStatus: FC<NftMintingProps> = ({ show, size }) => {
  if (!show) {
    return null;
  }

  switch (size) {
    case 'xss':
      return (
        <Root>
          <Icon name="time" size={16} />
        </Root>
      );
    case 'xs':
      return (
        <Root>
          <Icon name="time" size={20} />
        </Root>
      );

    default:
      return (
        <Root>
          <Icon name="time" mb={1} />
          <Label>
            MINTING
            <br />A CARD
          </Label>
        </Root>
      );
  }
};

import { useQuery } from 'react-query';
import { useCopyToClipboard } from 'react-use';

import { getReferralCode } from '~shared/api';
import { useViewerModel } from '~entities/viewer';
import { useDispatch, useSelector } from '~shared/lib/hooks';

import { referralActions } from './slice';

export const useReferralQuery = () => {
  const dispatch = useDispatch();
  const { id } = useViewerModel();

  return useQuery(['referral', id], getReferralCode, {
    onSuccess: (data) => {
      dispatch(referralActions.set(data.referralCode));
    },
  });
};

export const UseReferral = () => {
  const [value, copy] = useCopyToClipboard();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.referral.open);
  const refCode = useSelector((state) => state.referral.refCode);

  const handleOpen = () => {
    dispatch(referralActions.open());
  };

  const handleClose = () => {
    copy('');
    dispatch(referralActions.close());
  };

  return {
    referralCode: refCode,
    copy,
    value,
    handleOpen,
    handleClose,
    open,
  };
};

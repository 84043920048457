import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { MarketplaceItemType } from '~entities/marketplace';
import { useMediaQuery } from '~shared/lib/hooks';

import { useTheme } from '~shared/lib/styles';
import { ButtonIcon, Dialog, MobileDialog, Stack, Typography } from '~shared/ui';

import { MarketplaceItemNftImage } from '../MarketplaceItemNftImage';

import { MarketplaceMysteryBoxDetails } from './MarketplaceMysteryBoxDetails';
import { MarketplaceNftDetails } from './MarketplaceNftDetails';
import { MarketplaceUnfreezeDetails } from './MarketplaceUnfreezeDetails';
import { ItemCard, StyledMarketplaceItemImage } from './styled';

import { MarketplaceItemDetailsDialogProps } from './types';

export const MARKETPLACE_ITEM_ID_SEARCH_PARAM = 'marketplaceItemDetails';

export const MarketplaceItemDetailsDialog: FC<MarketplaceItemDetailsDialogProps> = ({
  marketplaceItem,

  ...dialogProps
}) => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const { palette } = useTheme();

  const renderDetails = () => {
    switch (marketplaceItem.type) {
      case MarketplaceItemType.Nft:
        return <MarketplaceNftDetails marketplaceItem={marketplaceItem} />;

      case MarketplaceItemType.MysteryBox:
        return <MarketplaceMysteryBoxDetails marketplaceItem={marketplaceItem} />;

      case MarketplaceItemType.Unfreeze:
        return <MarketplaceUnfreezeDetails marketplaceItem={marketplaceItem} />;
    }
  };

  const content = (
    <Fragment>
      <ItemCard>
        {isMobile && (
          <ButtonIcon
            sx={{ alignSelf: 'start', marginBottom: '-20px' }}
            size={24}
            name="arrow-left"
            variant="plain"
            onClick={(event) => dialogProps.onClose && dialogProps.onClose(event, 'closeClick')}
          />
        )}

        <Stack spacing={2 / 8}>
          <Typography variant="h2" textAlign="center">
            {t(marketplaceItem.name)}
          </Typography>
          <Typography sx={{ fontSize: '13px' }} color="secondary" textAlign="center">
            {t('Marketplace.cardByProvider', { provider: 'Maincard' })}
          </Typography>
        </Stack>

        {marketplaceItem.type === MarketplaceItemType.Nft ? (
          <MarketplaceItemNftImage src={marketplaceItem.imagePath} />
        ) : (
          <StyledMarketplaceItemImage src={marketplaceItem.imagePath} alt="Marketplace item" />
        )}
      </ItemCard>

      <Stack spacing={20 / 8} padding="0 16px" flex={1}>
        <Typography variant="h3" sx={{ fontSize: '16px' }}>
          {t('Other.details')}
        </Typography>

        {renderDetails()}
      </Stack>
    </Fragment>
  );

  if (isMobile) {
    return (
      <MobileDialog sx={{ background: palette.paper.A80 }} disableTransition {...dialogProps}>
        <Stack
          spacing={20 / 8}
          height="100%"
          pb={marketplaceItem.type === MarketplaceItemType.Unfreeze ? '20px' : '90px'}
          overflow="auto"
        >
          {content}
        </Stack>
      </MobileDialog>
    );
  }

  return (
    <Dialog backgroundColor={palette.paper.A80} width={700} {...dialogProps}>
      <Stack
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap={40 / 8}
        height="100%"
        position="relative"
      >
        {content}
      </Stack>
    </Dialog>
  );
};

import { Address, Cell, toNano } from '@ton/core';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useTonAddress } from '@tonconnect/ui-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Nft } from '~shared/api';
import { useDispatch } from '~shared/lib/hooks';

import { nftActions, useNftSelector } from '~entities/nft';

// todo: feature <- pages, feature <- widgets

import { useWriteContract } from '~entities/wallet';

import { SelectValue } from '~shared/ui';

import { SellInitialPriceFormValues } from './types';
import { validationSchema } from './config';

export const useSellCardModel = (nft: Nft) => {
  const dispatch = useDispatch();

  const handleSellCard = () => {
    dispatch(nftActions.setDialogs({ action: 'sell', dialog: { nfts: [nft], open: true } }));
  };

  return { handleSellCard };
};

export const useSellCardDialogModel = () => {
  const [selectSellCoin, setSelectSellCoin] = useState('MCN');
  const { t } = useTranslation();

  const {
    dialogs: {
      sell: { nfts, open },
    },
  } = useNftSelector();

  const handleCurrencyChange = (
    _: MouseEvent | KeyboardEvent | FocusEvent | null,
    value: SelectValue | null
  ) => {
    setSelectSellCoin(value as any);
  };

  const { write: placeCardToAuction } = useWriteContract({
    contractName: 'CardCollection',
    method: 'PutCardOnAuction',
    transactionName: 'Putting the Card up for auction',
    successMessage: `${t('Alerts.alertAuctionPurCard')}`,
    errorMessage: `${t('Errors.auctionFailedPut')}`,
  });

  const nft = nfts[0] || null;

  const dispatch = useDispatch();
  const form = useForm<SellInitialPriceFormValues>({
    resolver: yupResolver(validationSchema),
  });

  const account = useTonAddress(true);

  const handleCloseSellCardDialog = () => {
    dispatch(nftActions.setDialogs({ action: 'sell', dialog: { nfts: [], open: false } }));
  };

  const handleSubmit = form.handleSubmit(async ({ price }: SellInitialPriceFormValues) => {
    if (nft && account) {
      const cardId = nft.token_id;

      const isMcn = selectSellCoin === 'MCN';

      try {
        await placeCardToAuction({
          args: {
            $$type: 'PutCardOnAuction',
            token_id: BigInt(Number(cardId)),
            isMcn,
            start_price: BigInt(Number(price)),
            auction: Address.parse(process.env.REACT_APP_ADDRESS_TON_AUCTION),
          },
          value: toNano('0.45'),
        });

        dispatch(nftActions.sellCard(cardId));
        handleCloseSellCardDialog();
      } catch (e) {
        console.error(e);
      }
    }
  });

  useEffect(() => {
    if (!open) {
      form.reset();
    }
  }, [open, form]);

  return {
    open,
    nft,
    form,
    handleCloseSellCardDialog,
    handleSubmit,
    handleCurrencyChange,
  };
};

import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NFT_RARITY_TO_MAX_LIVES_MAP, NFT_RARITY_TO_MAX_WIN_STREAK } from '~entities/nft';
import { MarketplaceCurrencyValue } from '~features/marketplace/lib/currencyOptions';

import { MarketplaceBuyNFTForm } from '~features/marketplace/ui/MarketplaceBuyNFTForm';
import { useMediaQuery } from '~shared/lib/hooks';

import { Stack } from '~shared/ui';

import { MarketplaceDetailsEntries } from '../MarketplaceDetailsEntries';

import { MarketplaceNftDetailsProps } from './types';

export const MarketplaceNftDetails: FC<MarketplaceNftDetailsProps> = ({ marketplaceItem }) => {
  const { t } = useTranslation();

  const { isMobile } = useMediaQuery();

  const [currency, setCurrency] = useState<MarketplaceCurrencyValue>(
    MarketplaceCurrencyValue.Matic
  );

  const maxLives = NFT_RARITY_TO_MAX_LIVES_MAP[marketplaceItem.rarity];
  const neededWinStreak = NFT_RARITY_TO_MAX_WIN_STREAK[marketplaceItem.rarity];

  return (
    <Stack flex={1} justifyContent="space-between">
      <MarketplaceDetailsEntries
        entries={[
          { label: `${t('Marketplace.lives')} `, value: `${maxLives}/${maxLives}` },
          {
            label: `${t('Marketplace.winStreak')} `,
            value: `${neededWinStreak}/${neededWinStreak}`,
          },
          {
            label: `${t('Marketplace.winReward')} `,
            value: `${marketplaceItem.rewardTokensAmount} MCN`,
          },
          { label: `${t('Marketplace.freezeTime')} `, value: `${marketplaceItem.freezeTime} h` },
          {
            label: `${t('Marketplace.repairPrice')} `,
            value: `${marketplaceItem.restorePrice.mcn} MCN`,
          },
        ]}
      />

      <MarketplaceBuyNFTForm
        rarity={marketplaceItem.rarity}
        price={marketplaceItem.price}
        currency={currency}
        fixedLayout={isMobile}
        onChangeCurrency={setCurrency}
      />
    </Stack>
  );
};

import { ButtonIcon, buttonIconClasses } from '~shared/ui';
import { styled } from '~shared/lib/styles';

export const switchLanguageButtonClasses = {
  default: 'SwitchLanguageButton-default',
  compact: 'SwitchLanguageButton-compact',
  chevron: 'SwitchLanguageButton-chevron',
};

interface SwitchLanguageButtonProps {
  $open: boolean;
}

export const SwitchLanguageButton = styled(ButtonIcon)<SwitchLanguageButtonProps>(
  ({ theme, $open }) => ({
    [`&.${buttonIconClasses.plain}`]: {
      border: '2px solid transparent',
      transition: theme.transitions.create('border'),
      display: 'flex',
      alignItems: 'center',

      ['&:hover']: {
        boxShadow: 'none',
        border: `2px solid ${theme.palette.primary.main}`,
      },

      ...($open ? { border: `2px solid ${theme.palette.primary.main}` } : null),

      [`&.${switchLanguageButtonClasses.default}`]: {
        padding: 3,
        background: theme.palette.paper.semi,
        borderRadius: '100px',

        [`& .${switchLanguageButtonClasses.chevron}, 
          &:hover .${switchLanguageButtonClasses.chevron}`]: {
          color: theme.palette.text.primary,
          marginLeft: 2,
          marginRight: 2,
        },
      },
    },
  })
);

import { apiInstance } from './../apiInstance';
import { ReferralRoutes } from './routes';
import { ApiGetReferralCode, ApiPostCreateReferral } from './types';

export const postCreateReferral: ApiPostCreateReferral = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ReferralRoutes.createReferral(), data, {
        params: {
          ton: true,
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const getReferralCode: ApiGetReferralCode = () => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get(ReferralRoutes.getReferralCode(), {
        params: {
          ton: true,
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const Root = styled(Box, { name: 'EventChoice', slot: 'root' })(({ theme }) => ({
  display: 'grid',
  gap: '20px',

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
    gap: '18px',
  },
}));

type EventChoiceDragCellProps = {
  $isHighlighted?: boolean;
  $disabled: boolean;
  $isInitial: boolean;
};

export const EventChoiceDragCell = styled(Box, {
  name: 'EventChoice',
  slot: 'dragCell',
})<EventChoiceDragCellProps>(({ theme, $isHighlighted, $disabled, $isInitial }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '34px',
  background: $isHighlighted && !$disabled ? 'rgba(46, 178, 255, 0.8)' : 'initial',
  border: `1px dashed ${
    $disabled
      ? theme.palette.grey.A20
      : $isInitial
      ? theme.palette.primary.light
      : theme.palette.grey.A50
  }`,
  color: $disabled ? theme.palette.grey.A20 : theme.palette.grey.A50,
  borderRadius: '8px',
  height: '172px',
  transition: theme.transitions.create(['background', 'color', 'border']),

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
    height: '130px',
  },
}));

export const IconAndLabelContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const EventChoiceLabel = styled('div')(({ theme }) => ({
  marginTop: 10,
  textAlign: 'center',
  lineHeight: '14px',

  [theme.breakpoints.down('mobile')]: {
    fontSize: '12px',
    marginTop: 6,
  },
}));

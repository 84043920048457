import { NftRarity } from '~shared/api';

export enum MarketplacePageTab {
  Marketplace = 'marketplace',
  Auction = 'auction',
}

export enum MarketplaceItemType {
  Unfreeze,
  Nft,
  MysteryBox,
}

interface MarketplaceItemCommon {
  type: MarketplaceItemType;
  name: string;
  price?: number;
  imagePath?: string;
}

export interface MarketplaceUnfreeze extends MarketplaceItemCommon {
  type: MarketplaceItemType.Unfreeze;
}

export interface MarketplaceNft extends MarketplaceItemCommon {
  type: MarketplaceItemType.Nft;
  rarity: NftRarity;

  maximumNumberOfRecoveries: number;
  recoveryCost: string;
  recoveryPeriod: number;
  rewardTokensAmount: number;
  freezeTime: number;
  restorePrice: {
    mcn: number;
  };
}

export interface MarketplaceMysteryBox extends MarketplaceItemCommon {
  type: MarketplaceItemType.MysteryBox;
  rarity: NftRarity;
}

export type MarketplaceItem = MarketplaceUnfreeze | MarketplaceNft | MarketplaceMysteryBox;

export type NftCardSize = 'xss' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface NftCardSizeValue {
  height: string;
  width: string;
  fontSize: string;
}

export const NFT_CARD_SIZE_VALUES: Record<NftCardSize, NftCardSizeValue> = {
  xss: {
    height: '28px',
    width: '20px',
    fontSize: '28px',
  },
  xs: {
    height: '45px',
    width: '32px',
    fontSize: '45px',
  },
  sm: {
    height: '76px',
    width: '52px',
    fontSize: '76px',
  },
  md: {
    height: '106px',
    width: '72px',
    fontSize: '106px',
  },
  lg: {
    height: '125px',
    width: '86px',
    fontSize: '125px',
  },
  xl: {
    height: '148px',
    width: '100px',
    fontSize: '148px',
  },
};

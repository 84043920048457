import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const BoxMessage = styled(Box)({
  background: '#FFFFFF0D',
  padding: '10px 15px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '6px',
});

import { FC } from 'react';

import { Icon } from '~shared/ui';

import { NftBlockedForTxProps } from './types';
import { Label, Root } from './styled';

export const NftCardBlockedForTxStatus: FC<NftBlockedForTxProps> = ({ show, size }) => {
  if (!show) {
    return null;
  }

  switch (size) {
    case 'xss':
      return (
        <Root>
          <Icon name="time" size={16} />
        </Root>
      );
    case 'xs':
      return (
        <Root>
          <Icon name="time" size={20} />
        </Root>
      );

    default:
      return (
        <Root>
          <Icon name="time" mb={1} />
          <Label>
            BLOCKED FOR
            <br />
            TRANSACTION
          </Label>
        </Root>
      );
  }
};

import { FC } from 'react';

import { Box, Typography } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { ContentPlaceholderProps } from './types';

export const ContentPlaceholder: FC<ContentPlaceholderProps> = ({
  heading,
  message,
  mobile,
  ...boxProps
}) => {
  const { isMobile } = useMediaQuery();

  const computedMobile = mobile ?? isMobile;

  return (
    <Box
      width={1}
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...boxProps}
    >
      <Typography
        variant="h1"
        fontSize={computedMobile ? 18 : 22}
        lineHeight={computedMobile ? 24 / 18 : 30 / 22}
        paddingX={16 / 8}
        align="center"
      >
        {heading}
      </Typography>
      <Typography lineHeight={1} marginTop={12 / 8} color="grey.A50" align="center">
        {message}
      </Typography>
    </Box>
  );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ViewerState } from './types';

export const initialState: ViewerState = {
  registered: false,
  authorized: false,
  verified: false,
  sessionTokenChecked: false,
  isNicknameSet: null,

  id: null,
  email: '',
  wallet: '',
  tonAddress: '',
  nickname: '',
  avatar: null,
  customAvatar: null,
  defaultAvatars: null,

  onboarding: false,

  signOutDialogOpen: false,
};

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState: initialState,
  reducers: {
    updateData: (state, action: PayloadAction<Partial<ViewerState>>) => {
      return { ...state, ...action.payload };
    },

    openSignOutDialog: (state) => {
      return { ...state, signOutDialogOpen: true };
    },

    closeSignOutDialog: (state) => {
      return { ...state, signOutDialogOpen: false };
    },

    reset: () => {
      return {
        ...initialState,
        sessionTokenChecked: true,
      };
    },
  },
});

export const viewerActions = viewerSlice.actions;

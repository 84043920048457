import { FC } from 'react';

import { SwitchLanguage } from '~features/switch-language';

import { StyledDialogTitle, SwitchLanguageContainer } from './styled';
import { AuthDialogTitleProps } from './types';

export const AuthDialogTitle: FC<AuthDialogTitleProps> = ({
  children,
  showLanguageSwitcher = true,
  ...props
}) => {
  return (
    <StyledDialogTitle {...props}>
      {children}

      {showLanguageSwitcher && (
        <SwitchLanguageContainer>
          <SwitchLanguage variant="default" />
        </SwitchLanguageContainer>
      )}
    </StyledDialogTitle>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EventDialogTab, EventStatus, useEventModel } from '~entities/event';
import { BattleResult } from '~shared/api';

import { Root, StyledTypography } from './styled';
import { EventDialogChoiceResultProps } from './types';

export const EventDialogChoiceResult: FC<EventDialogChoiceResultProps> = () => {
  const {
    correctChoice,
    eventStatus,
    tab,
    isViewerCall,
    isViewerBattle,
    isViewer,
    result,
    call: { callCreator, callAcceptor },
  } = useEventModel();

  const { t } = useTranslation();

  const isViewerBet = tab === EventDialogTab.Call ? isViewerCall : isViewerBattle;

  const isBetTab = [EventDialogTab.Battle, EventDialogTab.Call].includes(tab);

  if (correctChoice === null || !isBetTab || eventStatus !== EventStatus.Passed || !isViewerBet) {
    return null;
  }

  const isNobodyWins =
    tab === EventDialogTab.Call &&
    callCreator &&
    callAcceptor &&
    ![callCreator?.choiceId, callAcceptor?.choiceId].includes(result as BattleResult);

  const getText = () => {
    if (isNobodyWins) {
      return t('EventDialog.nobodyWins');
    }

    if (correctChoice) {
      return isViewer ? t('EventDialog.youWin') : t('BattlePopUp.onlyWin');
    }

    return isViewer ? t('EventDialog.youLose') : t('BattlePopUp.lose');
  };

  return (
    <Root $isCorrectChoice={correctChoice} $isNobodyWins={isNobodyWins}>
      <StyledTypography>{getText()}</StyledTypography>
    </Root>
  );
};

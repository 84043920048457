import { useTonConnectModal } from '@tonconnect/ui-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dialog, Typography } from '~shared/ui';
import { useDispatch } from '~shared/lib/hooks';
import { useTheme } from '~shared/lib/styles';

import { useConnect, useWalletSelector, walletActions } from '~entities/wallet/model';

import { ConnectWalletWarnDialogIllustration, ConnectWalletWarnDialogRoot } from './styled';

export const ConnectWalletWarnDialog: FC = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const dispatch = useDispatch();

  const { open } = useTonConnectModal();
  const { isWarnDialogOpen } = useWalletSelector();

  const handleClose = () => {
    dispatch(walletActions.setWarnDialog(false));
  };

  const handleConnect = () => {
    handleClose();
    open();
  };

  return (
    <Dialog
      backgroundColor={palette.paper.secondary}
      width={440}
      open={isWarnDialogOpen}
      onClose={handleClose}
    >
      <ConnectWalletWarnDialogRoot>
        <Typography textAlign="center" variant="h1">
          Connect a wallet
        </Typography>
        <Typography maxWidth="330px" textAlign="center" variant="body">
          {t('Other.upText')}
        </Typography>
        <ConnectWalletWarnDialogIllustration
          src="/assets/auth/connect-wallet-secondary-illustration.png"
          alt="Connect your wallet before do any action"
        />
        <Typography maxWidth="330px" textAlign="center" variant="body">
          You’re one step away from claiming your first Maincard. It’s on us! Use your card to start
          playing and earn tokens.
        </Typography>
        <Button onClick={handleConnect}>Connect a wallet</Button>
      </ConnectWalletWarnDialogRoot>
    </Dialog>
  );
};

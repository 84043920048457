import { TonConnectUIProvider } from '@tonconnect/ui-react';

export const withTonConnectUI = (children: Function) => () => {
  return (
    <TonConnectUIProvider
      // MOCK {`${window.location.origin}/tonconnect-manifest.json`}
      manifestUrl="https://ton.maincard.io/tonconnect-manifest.json"
      actionsConfiguration={{
        twaReturnUrl: 'https://t.me/maincardton_bot',
      }}
    >
      {children()}
    </TonConnectUIProvider>
  );
};

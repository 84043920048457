import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Stack } from '~shared/ui';

import { MarketplaceItemShell } from '~entities/marketplace';

import { MarketplaceItemImage } from '../MarketplaceItemImage';

import { MarketplaceItemUnfreezeProps } from './types';

export const MarketplaceItemUnfreeze: FC<MarketplaceItemUnfreezeProps> = ({
  marketplaceUnfreeze,
  onOpenDetails,
}) => {
  const { t } = useTranslation();

  return (
    <MarketplaceItemShell
      name={t(marketplaceUnfreeze.name)}
      description={<Fragment>{t('Marketplace.TextUnfreeze')}</Fragment>}
      icon={
        <MarketplaceItemImage
          src={marketplaceUnfreeze.imagePath}
          alt={t(marketplaceUnfreeze.name)}
        />
      }
    >
      <Stack spacing={14 / 8}>
        <Button disabled>{t('Other.buy')}</Button>
        <Button variant="text" size="sm" onClick={onOpenDetails}>
          {t('Other.details')}
        </Button>
      </Stack>
    </MarketplaceItemShell>
  );
};

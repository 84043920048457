import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AuthState, AuthSteps } from './types';

const initialState: AuthState = {
  step: AuthSteps.Identification,
  open: false,

  isFreebetDialogOpen: false,

  avatar: '',
  email: '',
  nickname: '',
  walletAddress: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeStep: (state, action: PayloadAction<AuthSteps>) => {
      return { ...state, step: action.payload };
    },
    open: (state) => {
      return { ...state, open: true };
    },
    openFreebetDialog: (state) => {
      return { ...state, isFreebetDialogOpen: true };
    },
    closeFreebetDialog: (state) => {
      return { ...state, isFreebetDialogOpen: false };
    },
    close: (state) => {
      return { ...state, open: false };
    },
    updateData: (
      state,
      action: PayloadAction<Partial<Pick<AuthState, 'avatar' | 'nickname' | 'email'>>>
    ) => {
      return { ...state, ...action.payload };
    },
    signOut: (state) => {
      state.avatar = '';
      state.email = '';
      state.nickname = '';
      state.walletAddress = '';
      state.step = AuthSteps.Identification;
      state.open = true;
    },

    reset: () => {
      return { ...initialState };
    },
  },
});

export const authActions = authSlice.actions;

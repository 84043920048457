import { styled, theme } from '~shared/lib/styles';

import { NFT_CARD_SIZE_VALUES } from '../../lib';
import { NftCard } from '../NftCard';

export const NftDragLayerRoot = styled('div')({
  position: 'fixed',
  inset: 0,
  pointerEvents: 'none',
  // todo: zIndex from theme
  zIndex: 1000,
});

export const NftDragLayerPreviewWrapper = styled('div')(() => ({
  width: 0,
  height: 0,
  // todo: zIndex from theme
  zIndex: 1000,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'visible',
}));

interface NftDragLayerPreviewProps {
  $isOverAnyTarget: boolean;
}

export const NftDragLayerPreview = styled(NftCard)<NftDragLayerPreviewProps>(
  ({ $isOverAnyTarget }) => ({
    minWidth: NFT_CARD_SIZE_VALUES.md.width,
    minHeight: NFT_CARD_SIZE_VALUES.md.height,
    fontSize: NFT_CARD_SIZE_VALUES.md.height, // proper border-radius

    transform: $isOverAnyTarget ? 'scale(0.6) rotate(12deg)' : 'none',
    opacity: $isOverAnyTarget ? 0.4 : 0.8,
    transition: theme.transitions.create(['transform', 'opacity']),
    boxShadow: '0px 0px 4px #42BAFF',
  })
);

import { RefObject, useLayoutEffect, useRef, useState } from 'react';

interface ResizeObserverState {
  width: number;
  height: number;
}

export const useResizeObserver = <T extends Element>(): [RefObject<T>, ResizeObserverState] => {
  const [size, setSize] = useState<ResizeObserverState>({ width: 0, height: 0 });
  const ref = useRef<T>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver((entries) => {
        const entry = entries[0].contentRect;

        if (entry) {
          setSize({ width: entry.width, height: entry.height });
        }
      });

      observer.observe(ref.current);

      return () => observer.disconnect();
    }
  }, []);

  return [ref, size];
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SetNftHiddenPayload } from '~entities/nft';

import { EventDialogTab } from './config';
import { EventBetType, EventState } from './types';

const initialState: EventState = {
  open: false,
  isLoading: false,

  tab: EventDialogTab.Battle,
  closingConfirmationDialogOpen: false,

  user: null,

  // TODO: remove
  needToRefetch: false,

  event: null,
  eventStatus: null,

  result: null,

  bets: [],

  battle: {
    cards: [],
    additionalCards: [],
    choice: null,
    rewardReceived: null,
    isViewMode: false,
  },

  call: {
    cards: [],
    choice: null,
    callId: null,
    callCreator: null,
    callAcceptor: null,
    callAcceptableRarity: null,
    rewardReceived: null,
    isViewMode: false,
  },

  isCall: false,

  nftWithWinstreakDialog: {
    nft: null,
    open: false,
    result: null,
    onBetCard: null,
  },

  voteChoiceDialog: {
    open: false,
  },
};

export const eventSlice = createSlice({
  name: 'event',
  initialState: initialState,
  reducers: {
    setClosingConfirmationDialogOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      closingConfirmationDialogOpen: action.payload,
    }),
    setVoteChoiceDialogOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      voteChoiceDialog: {
        open: action.payload,
      },
    }),
    setBannerDetailedInfoDialogOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      bannerDetailedInfoDialogOpen: action.payload,
    }),

    clearEventCards: (
      state,
      {
        payload: { cardsKey, betType },
      }: PayloadAction<{ cardsKey: 'cards' | 'additionalCards'; betType: EventBetType }>
    ) => {
      return {
        ...state,
        [betType]: {
          ...state[betType],
          [cardsKey]: [],
          choice: cardsKey === 'cards' ? null : state[betType].choice,
        },
      };
    },

    setNftHidden: (state, { payload }: PayloadAction<SetNftHiddenPayload>) => {
      if (!payload.isHidden) {
        state.battle.cards = state.battle.cards.filter(
          (card) => !payload.nftIds.includes(card.token_id)
        );

        state.battle.additionalCards = state.battle.additionalCards.filter(
          (card) => !payload.nftIds.includes(card.token_id)
        );
      }
    },

    setTab: (state, action: PayloadAction<EventDialogTab>) => ({
      ...state,
      tab: action.payload,
    }),

    set: (state, { payload }: PayloadAction<Partial<EventState>>) => {
      return {
        ...state,
        ...payload,
        call: {
          ...state.call,
          ...(payload.call ? payload.call : {}),
        },
        battle: {
          ...state.battle,
          ...(payload.battle ? payload.battle : {}),
        },
      };
    },

    reset: () => {
      return { ...initialState };
    },
  },
});

export const eventActions = eventSlice.actions;

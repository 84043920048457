import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ApiGetTournamentMappedResponseData } from '~shared/api';

interface TournamentsState {
  tournament: null | ApiGetTournamentMappedResponseData;
}

const initialState: TournamentsState = {
  tournament: null,
};

export const tournamentsSlice = createSlice({
  name: 'tournaments',
  initialState,
  reducers: {
    setTournament: (state, action: PayloadAction<ApiGetTournamentMappedResponseData>) => {
      state.tournament = action.payload;
    },
    clearTournament: (state) => {
      state.tournament = null;
    },

    reset: () => {
      return { ...initialState };
    },
  },
});

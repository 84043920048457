import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ContentPlaceholder } from '~shared/ui';

import { BattleSeparatedHorizontalList, useBetList } from '~entities/battle';
import { useProfileUserBattlesQuery } from '~entities/profile';

import { UserProfileBattlesListProps } from './types';

export const UserProfileBattleList: FC<UserProfileBattlesListProps> = ({
  sport,
  league,
  divider,
  address,
  nickname,
  avatar,
}) => {
  const profileBattlesQuery = useProfileUserBattlesQuery({ sport, league, address });
  const { t } = useTranslation();
  const { passedList, currentList } = useBetList({
    list: profileBattlesQuery.list,
    user: { nickname, avatar, address },
  });

  if (profileBattlesQuery.isEmptyList) {
    return (
      <ContentPlaceholder
        heading={`${t('Other.noBattles')}`}
        message={sport ? `${t('Other.noBattlesThisSection')}` : `${t('Other.YouHaveNoBattles')}`}
      />
    );
  }

  return (
    <BattleSeparatedHorizontalList
      passedList={passedList}
      currentList={currentList}
      loading={profileBattlesQuery.isLoading}
      divider={divider}
    />
  );
};

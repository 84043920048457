import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~shared/ui';

import { useNftCardModel } from '~entities/nft';

import { useMergeModel } from '../../model';

import { MergeCardsProps } from './types';

export const MergeCards: FC<MergeCardsProps> = ({ ...buttonProps }) => {
  const { t } = useTranslation();

  const { isSelectionMode } = useNftCardModel();
  const { openMergeDialog, isMergeAvailable } = useMergeModel();

  if (!isMergeAvailable) {
    return null;
  }

  if (!isSelectionMode) {
    return (
      <Button size="xs" color="success" {...buttonProps} onClick={openMergeDialog}>
        {t('Other.mergeHead')}
      </Button>
    );
  }

  return null;
};

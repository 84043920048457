import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import writeText from 'copy-to-clipboard';
import { useTonConnectModal, useTonWallet } from '@tonconnect/ui-react';

import {
  ButtonIcon,
  Divider,
  Icon,
  IconAssetNamesType,
  MenuItem,
  Pill,
  PillMenu,
  Stack,
  Typography,
} from '~shared/ui';
import { useMediaQuery, useSnackbar, useSwitcher } from '~shared/lib/hooks';
import { pillMenuClasses } from '~shared/ui/dataDisplay/PillMenu/styled';

import { useViewerModel } from '~entities/viewer';
import { useCheckTonStatus, useCheckTonkBackend, useWalletSelector } from '~entities/wallet';

import { DisconnectWalletDialog } from './DisconnectWalletDialog';

export const ViewerWalletBalance = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();
  const { openSnackbar } = useSnackbar();

  const { open } = useTonConnectModal();
  const { balance, balanceReference } = useWalletSelector();
  const disconnectDialog = useSwitcher();
  const isTonAvailable = useCheckTonStatus();
  const isBackendAvailable = useCheckTonkBackend();

  if (!isBackendAvailable) {
    throw new Error("We're making enhancements to serve you better. Appreciate your patience!");
  }

  if (!isTonAvailable) {
    throw new Error(
      'Unable to connect to the Ton Blockchain - it may be down or under maintenance. Your patience is appreciated.'
    );
  }

  const { shortWalletAddress, tonAddress } = useViewerModel();
  const wallet = useTonWallet();

  const handleCopy = () => {
    if (!wallet) {
      return;
    }

    writeText(tonAddress);
    openSnackbar({ message: t('Wallet.walletAddressCopied') });
  };

  const menuItemsMap: Record<
    string,
    { icon: IconAssetNamesType; label: ReactNode; value: ReactNode }
  > = {
    maincoin: {
      icon: 'coin-maincoin',
      label: 'MCN',
      value: (
        <Stack alignItems="flex-end" spacing={4 / 8}>
          <Typography>{formatDigits(balance.mcn)}</Typography>
          {/* TODO: Show MCN price in USD */}
          <Typography color="grey.A50" fontSize={13} lineHeight={14 / 13}>
            {t('Common.soon')}
          </Typography>
        </Stack>
      ),
    },
    matic: {
      icon: 'coin-ton',
      label: 'TON',
      value: (
        <Stack alignItems="flex-end" spacing={4 / 8}>
          <Typography>{formatDigits(balance.ton)}</Typography>

          {balanceReference.ton.usd !== null && (
            <Typography color="grey.A50" fontSize={13} lineHeight={14 / 13}>
              ${formatDigits(balanceReference.ton.usd)}
            </Typography>
          )}
        </Stack>
      ),
    },
    // unfreeze: {
    //   icon: 'item-unfreeze',
    //   label: 'Unfreeze item',
    //   value: 0,
    // },
    // repair: {
    //   icon: 'item-repair',
    //   label: 'Repair item',
    //   value: 3,
    // },
    // insurance: {
    //   icon: 'item-winstreak-insurance',
    //   label: 'WinStreak insurance',
    //   value: 3,
    // },
  };

  const renderItem = (item: (typeof menuItemsMap)[keyof typeof menuItemsMap]) => {
    return (
      <MenuItem>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={16 / 8}
          flexBasis="100%"
          alignItems="center"
        >
          <Stack direction="row" spacing={10 / 8} alignItems="center">
            <Icon name={item.icon} size={20} />
            <Typography color="secondary">{item.label}</Typography>
          </Stack>

          <Typography fontWeight={600}>{item.value}</Typography>
        </Stack>
      </MenuItem>
    );
  };

  if (!wallet) {
    return (
      <Pill onClick={open} append={<ButtonIcon name="plus" as="span" />}>
        {t('disconnectWallet.wallet')}
      </Pill>
    );
  }

  // if (isFetchingBalance) {
  //   const width = 140;

  //   return (
  //     <Shimmer width={width} height={32} viewBox={`0 0 ${width} ${32}`}>
  //       <rect x="0" y="0" rx="16" ry="16" width={width} height={32} />
  //     </Shimmer>
  //   );
  // }

  const pillMenuLabel = (
    <Stack direction="row" spacing={10 / 8}>
      {isMobile ? null : (
        <Fragment>
          <Typography color="grey.A50" textTransform="capitalize">
            {t('Other.wallet')}
          </Typography>
        </Fragment>
      )}

      <Icon size={20} name="coin-maincoin" />
      <Typography color="grey.A50">{formatDigits(balance.mcn)}</Typography>
    </Stack>
  );

  return (
    <Fragment>
      <DisconnectWalletDialog open={disconnectDialog.value} onClose={disconnectDialog.switchOff} />

      <PillMenu label={pillMenuLabel}>
        <MenuItem onClick={handleCopy}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flex={1}
            spacing={2}
          >
            <Stack spacing={10 / 8} alignItems="center" direction="row">
              <Typography color="secondary" textTransform="capitalize">
                {t('Other.wallet')}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={4 / 8} alignItems="center">
              <Typography color="secondary" fontWeight={600}>
                {shortWalletAddress}
              </Typography>

              <Icon name="copy" size={18} />
            </Stack>
          </Stack>
        </MenuItem>

        <Divider />

        {renderItem(menuItemsMap.maincoin)}
        {renderItem(menuItemsMap.matic)}

        <Divider />

        <MenuItem color="error" onClick={disconnectDialog.switchOn}>
          {t('Balance.disconnectWallet')}
        </MenuItem>
      </PillMenu>
    </Fragment>
  );
};

const formatDigits = (number: number) => {
  return number.toLocaleString('de', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

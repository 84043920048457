import { NftRarity } from '~shared/api';

import { MarketplaceItem, MarketplaceItemType } from './types';

export const MARKETPLACE_PAGE_TAB_SEARCH_PARAM_KEY = 'tab';

export const MARKETPLACE_AVAILABLE_RARITIES = [
  NftRarity.Common,
  NftRarity.Rare,
  NftRarity.Epic,
  NftRarity.Legendary,
];

export const MARKETPLACE_ITEMS: Array<MarketplaceItem> = [
  {
    type: MarketplaceItemType.Unfreeze,
    name: 'Marketplace.unfreeze',
  },
  {
    type: MarketplaceItemType.Nft,
    rarity: NftRarity.Common,
    maximumNumberOfRecoveries: 2,
    recoveryCost: '1',
    recoveryPeriod: 3,
    rewardTokensAmount: 3,
    name: 'Marketplace.commonCard',
    freezeTime: 3,
    restorePrice: {
      mcn: 16,
    },
  },
  {
    type: MarketplaceItemType.MysteryBox,
    rarity: NftRarity.Common,
    name: 'Marketplace.commonMysteryBox',
  },
  {
    type: MarketplaceItemType.Nft,
    rarity: NftRarity.Rare,
    maximumNumberOfRecoveries: 3,
    recoveryCost: '10',
    recoveryPeriod: 6,
    rewardTokensAmount: 15,
    name: 'Marketplace.rareCard',
    freezeTime: 6,
    restorePrice: {
      mcn: 120,
    },
  },
  {
    type: MarketplaceItemType.MysteryBox,
    rarity: NftRarity.Rare,
    name: 'Marketplace.rareMysteryBox',
  },
  {
    type: MarketplaceItemType.Nft,
    rarity: NftRarity.Epic,
    price: 1000,
    maximumNumberOfRecoveries: 5,
    recoveryCost: '20',
    recoveryPeriod: 12,
    rewardTokensAmount: 80,
    name: 'Marketplace.epicCard',
    freezeTime: 12,
    restorePrice: {
      mcn: 800,
    },
  },
  {
    type: MarketplaceItemType.MysteryBox,
    rarity: NftRarity.Epic,
    name: 'Marketplace.epicMysteryBox',
  },
  {
    type: MarketplaceItemType.Nft,
    rarity: NftRarity.Legendary,
    maximumNumberOfRecoveries: 4,
    recoveryCost: '75',
    recoveryPeriod: 24,
    rewardTokensAmount: 300,
    name: 'Marketplace.legendaryCard',
    freezeTime: 24,
    restorePrice: {
      mcn: 3500,
    },
  },
];

/** Images */
const BASE_MARKETPLACE_IMAGE_URL = `${process.env.PUBLIC_URL}/assets/marketplace`;

export const MARKETPLACE_MYSTERY_BOX_IMAGE_DIR = `${BASE_MARKETPLACE_IMAGE_URL}/mystery-box`;
export const MARKETPLACE_UNFREEZE_IMAGE_URL = `${BASE_MARKETPLACE_IMAGE_URL}/other/unfreeze.png`;
export const MARKETPLACE_NFT_IMAGE_URL_DIR = `${process.env.PUBLIC_URL}/assets/nfts/placeholder/`;

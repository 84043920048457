import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ViewerWalletTournamentsPlaceholderCaption,
  ViewerWalletTournamentsPlaceholderHeading,
  ViewerWalletTournamentsPlaceholderImage,
  ViewerWalletTournamentsPlaceholderRoot,
} from './styled';

export const ViewerWalletTournamentsPlaceholder: FC = () => {
  const { t } = useTranslation();

  return (
    <ViewerWalletTournamentsPlaceholderRoot>
      <ViewerWalletTournamentsPlaceholderImage
        src={process.env.PUBLIC_URL + '/assets/nfts/tournaments-placeholder.png'}
      />
      <ViewerWalletTournamentsPlaceholderHeading>
        {t('Sidebar.noTournaments')}
      </ViewerWalletTournamentsPlaceholderHeading>
      <ViewerWalletTournamentsPlaceholderCaption>
        {t('Sidebar.joinTournamentAndWinPrizes')}
      </ViewerWalletTournamentsPlaceholderCaption>
    </ViewerWalletTournamentsPlaceholderRoot>
  );
};

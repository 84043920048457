import { useCallback, useMemo } from 'react';

import { useDispatch, useSearchParamsState } from '~shared/lib/hooks';
import { queryClient } from '~shared/api';

import { getTournamentStatus } from '../lib';

import { useTournamentsSelector } from './selectors';
import { useQueryTournament } from './hooks';
import { tournamentsSlice } from './slice';

const TOURNAMENT_ID_KEY = 'tournamentId';

export const useTournamentsModel = () => {
  const dispatch = useDispatch();
  const { tournament } = useTournamentsSelector();

  const [tournamentId, setTournamentId, omitTournamentId] = useSearchParamsState<string>(
    TOURNAMENT_ID_KEY,
    ''
  );

  const dialogOpen = tournamentId.length > 0;

  const queryKey = useMemo(() => ['tournament', tournamentId], [tournamentId]);

  const { isLoading } = useQueryTournament(tournamentId, {
    queryKey,
    onSuccess: (tournament) => {
      dispatch(tournamentsSlice.actions.setTournament(tournament));
    },
  });

  const openTournamentDialog = useCallback(
    (id: string | number) => {
      if (id.toString.length) {
        if (tournament?.tournamentId.toString() !== id.toString()) {
          dispatch(tournamentsSlice.actions.clearTournament());
        }

        setTournamentId(id.toString());
      }
    },
    [dispatch, setTournamentId, tournament?.tournamentId]
  );

  const openTournamentDialogCurry = useCallback(
    (id: number | string) => () => openTournamentDialog(id),
    [openTournamentDialog]
  );

  const closeTournamentDialog = useCallback(() => {
    omitTournamentId();
  }, [omitTournamentId]);

  const invalidateTournamentQuery = useCallback(() => {
    return queryClient.invalidateQueries(queryKey);
  }, [queryKey]);

  const status = tournament ? getTournamentStatus(tournament.startDate, tournament.endDate) : null;

  return {
    tournament,
    status,
    isTournamentStartedOrEnded: status === 'active' || status === 'end',

    dialogOpen,
    isLoading,

    openTournamentDialog,
    openTournamentDialogCurry,
    closeTournamentDialog,

    invalidateTournamentQuery,
  };
};

import { configureStore } from '@reduxjs/toolkit';

import { notificationsSlice } from '~entities/notifications';
import { tournamentsSlice } from '~entities/tournament';
import { walletSlice } from '~entities/wallet';
import { viewerSlice } from '~entities/viewer';
import { eventSlice } from '~entities/event';
import { nftSlice } from '~entities/nft';

import { joinTournamentSlice } from '~features/tournament';
import { auctionSlice } from '~features/auction';
import { mergeSlice } from '~features/nft';

import { dailyQuestProgressSlice } from '~widgets/daily-quest-progress-dialog';
import { dailyQuestRewardSlice } from '~widgets/daily-quest-reward-dialog';
import { userProfileSlice } from '~widgets/leaderboard-list';
import { recoverySlice } from '~widgets/password-recovery';
import { viewerWalletSlice } from '~widgets/viewer-wallet';
import { contactUsSlice } from '~widgets/contact-us';
import { referralSlice } from '~widgets/referal';
import { authSlice } from '~widgets/auth';
import { inventorySlice } from '~widgets/inventory';

export const store = configureStore({
  reducer: {
    dailyQuestProgress: dailyQuestProgressSlice.reducer,
    dailyQuestReward: dailyQuestRewardSlice.reducer,
    joinTournament: joinTournamentSlice.reducer,
    notifications: notificationsSlice.reducer,
    viewerWallet: viewerWalletSlice.reducer,
    userProfile: userProfileSlice.reducer,
    tournaments: tournamentsSlice.reducer,
    inventory: inventorySlice.reducer,
    contactUs: contactUsSlice.reducer,
    recovery: recoverySlice.reducer,
    referral: referralSlice.reducer,
    auction: auctionSlice.reducer,
    viewer: viewerSlice.reducer,
    wallet: walletSlice.reducer,
    event: eventSlice.reducer,
    merge: mergeSlice.reducer,
    auth: authSlice.reducer,
    nft: nftSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

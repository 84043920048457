export enum MarketplaceCurrencyValue {
  Matic,
  USD,
  // TON,
}

export interface CurrencyOption {
  value: MarketplaceCurrencyValue;
  label: string;
}

export const getCurrencyLabel = (currency: MarketplaceCurrencyValue) => {
  switch (currency) {
    case MarketplaceCurrencyValue.Matic:
      return 'TON';
    case MarketplaceCurrencyValue.USD:
      return 'USD';
    // case MarketplaceCurrencyValue.TON:
    //   return 'TON';
  }
};

export const CurrencyOptionMatic: CurrencyOption = {
  value: MarketplaceCurrencyValue.Matic,
  label: getCurrencyLabel(MarketplaceCurrencyValue.Matic),
} as const;

export const CurrencyOptionPayPal: CurrencyOption = {
  value: MarketplaceCurrencyValue.USD,
  label: getCurrencyLabel(MarketplaceCurrencyValue.USD),
} as const;

// export const CurrencyOptionTON: CurrencyOption = {
//   value: MarketplaceCurrencyValue.TON,
//   label: getCurrencyLabel(MarketplaceCurrencyValue.TON),
// } as const;

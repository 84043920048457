import { getAvatarAbsoluteUri } from '~shared/lib/utils';

import { BattleResult, getBroadcastProvider, mapBattleTeam } from '../battle';

import { ApiGetCallDataParticipant, ApiGetCallsMappedData, ApiGetCallsRawData } from './types';

export const mapGetCallsResponse = (
  calls: Array<ApiGetCallsRawData>
): Array<ApiGetCallsMappedData> => {
  return calls.map(
    ({
      eventId,
      eventStartTime,
      firstParticipant,
      secondParticipant,
      result,
      extra,
      homeTeam,
      awayTeam,
      sport,
      broadcast,
      ...call
    }) => {
      return {
        ...call,

        id: eventId,
        date: new Date(eventStartTime),
        homeTeam: mapBattleTeam(homeTeam, sport),
        awayTeam: mapBattleTeam(awayTeam, sport),
        sport,

        broadcastId: broadcast,
        broadcastProvider: getBroadcastProvider(sport),

        firstParticipant: mapParticipant(firstParticipant),
        secondParticipant: mapParticipant(secondParticipant),
        result: result ?? getResultFromExtra(extra.LocalResult), // todo: remove `getResultFromExtra` after `result`-field realization on backend
        score:
          extra.LocalResult?.length > 0
            ? extra?.LocalResult?.split(':').map((score: string) => Number(score))
            : undefined, // todo: realize local event score across all Battle requests
      };
    }
  );
};

const mapParticipant = (participant: ApiGetCallDataParticipant) => {
  return participant.address
    ? { ...participant, avatar: participant.avatar ? getAvatarAbsoluteUri(participant.avatar) : '' }
    : null;
};

const getResultFromExtra = (localResult: string): BattleResult => {
  if (!localResult) {
    return BattleResult.InProgress;
  }

  const [homeTeam, awayTeam] = localResult.split(':').map((r) => Number(r)) as [number, number];

  if (homeTeam === awayTeam) {
    return BattleResult.Draw;
  }

  return homeTeam > awayTeam ? BattleResult.HomeTeamWon : BattleResult.AwayTeamWon;
};

import { WebAppProvider, useExpand } from '@vkruglikov/react-telegram-web-app';

import { useMountEffect } from '~app/utils/useMountEffect';

export const withWebApp = (children: Function) => () => {
  const [isExpanded, expand] = useExpand();

  const expandAtStart = () => {
    if (!isExpanded) {
      expand();
    }
  };

  useMountEffect(() => {
    expandAtStart();
  });

  return <WebAppProvider>{children()}</WebAppProvider>;
};

import { createStyled, createTheme, useTheme as useThemeBase } from '@mui/system';

import { Theme } from './types';

import { transitions } from './transitions';
import { palette } from './palette';
import { breakpoints } from './breakpoints';

export const theme = createTheme({
  transitions,
  palette,

  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),

    paragraph: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 20 / 14,
      letterSpacing: 0,
    },

    h1: {
      fontSize: 24,
      lineHeight: 1.5,
      fontWeight: 600,
      letterSpacing: -0.18,
    },

    h2: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 24 / 18,
      letterSpacing: -0.5,
    },

    h3: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1,
      letterSpacing: 0,
    },
  },

  breakpoints: {
    values: breakpoints,
  },

  // todo: cleanup
  zIndex: {
    layout: 0,
    drawer: 300,
    bottomNavigation: 300,
    modal: 300,
    modalOverlap: 301,
    alert: 400,
    transactionStatusDialog: 500,
    notification: 600,
    web3Modal: 800,
    preloader: 1000,
  },
}) as Theme;

export const styled = createStyled<Theme>({ defaultTheme: theme as Theme });

declare module '@mui/system/createTheme/createBreakpoints' {
  export interface BreakpointOverrides {
    mobile: true;
    desktop: true;
    wide: true;
  }
}

export const useTheme = () => {
  return useThemeBase<Theme>();
};

import { FC, Fragment, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  CreatePassword,
  RepeatPassword,
  Stack,
  TextField,
  Typography,
} from '~shared/ui';

import { useSnackbar, useSwitcher } from '~shared/lib/hooks';

import { useViewerModel } from '~entities/viewer';

export const Security: FC = () => {
  const { t } = useTranslation();
  const submitting = useSwitcher(false);
  const changingPassword = useSwitcher(false);
  const { openSnackbar } = useSnackbar();

  const form = useForm<{ password: string }>();
  const { updatePassword } = useViewerModel();

  const handleCancel = useCallback(() => {
    changingPassword.switchOff();
    form.reset();
  }, [changingPassword, form]);

  const handleSubmit = (data: { password: string }) => {
    submitting.switchOn();

    updatePassword(data)
      .then(() => {
        openSnackbar({ type: 'info', message: t('Other.passwodChange') });
        submitting.switchOff();
        changingPassword.switchOff();
        form.reset();
      })
      .catch(() => {
        openSnackbar({
          type: 'error',
          message: 'There is an error during password update. Please try again.',
        });

        submitting.switchOff();
      });
  };

  return (
    <Box maxWidth={340}>
      {changingPassword.value ? (
        <FormProvider {...form}>
          <Typography variant="h3" gutterBottom>
            {t('Account.changePassword')}
          </Typography>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Stack spacing={20 / 8}>
              <CreatePassword />
              <RepeatPassword />
            </Stack>

            <Box mt={40 / 8}>
              <Button type="submit" disabled={submitting.value}>
                {t('Account.changeBtn')}
              </Button>
              <Button variant="outlined" ml={1} onClick={handleCancel}>
                {t('Account.cancelBtn')}
              </Button>
            </Box>
          </form>
        </FormProvider>
      ) : (
        <Fragment>
          <Typography variant="h3" gutterBottom>
            {t('Account.password')}
          </Typography>
          <TextField type="password" label={t('Account.password')} value={12345678} disabled />
          <Button mt={20 / 8} onClick={changingPassword.switchOn}>
            {t('Account.changeBtn')}
          </Button>
        </Fragment>
      )}
    </Box>
  );
};

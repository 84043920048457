import { ApiGetAuctionCardMappedData } from '~shared/api';
import { useSearchParamsState } from '~shared/lib/hooks';
import { AUCTION_ITEM_SEARCH_PARAM } from '~widgets/auction-card-details-dialog/ui/AuctionCardDetailsDialog';

export const useAuctionCardDetailsModel = () => {
  const [, setSearchParam] = useSearchParamsState<string>(AUCTION_ITEM_SEARCH_PARAM, '');

  const onOpenAuctionCardDetails = (card: ApiGetAuctionCardMappedData) => {
    setSearchParam(`${card.tokenId}_${new Date(card.betsAcceptedUntil).toISOString()}`);
  };

  return {
    onOpenAuctionCardDetails,
  };
};

import { format } from 'date-fns';
import { FC, Fragment } from 'react';
import { Trans } from 'react-i18next';

import { useMediaQuery } from '~shared/lib/hooks';

import { Avatar, Divider, Shimmer, Stack, Typography } from '~shared/ui';

import { getAvatarAbsoluteUri } from '~shared/lib/utils';

import { useFilterForUserProfile } from '../../cards-list';

import { UserProfileHeadInfoProps } from './types';
import {
  CardInfo,
  CardInfoBlock,
  HorizontalListSlide,
  HorizontalListSwiper,
  UserProfileHeadInfoContainer,
  UserProfileHeadInfoLeft,
  UserProfileHeadInfoNickName,
} from './styled';

export const UserProfileHeadInfo: FC<UserProfileHeadInfoProps> = ({ dashboard, address }) => {
  const { nfts, isLoading } = useFilterForUserProfile({ address });
  const path = getAvatarAbsoluteUri(dashboard.avatar);
  const { isMobile } = useMediaQuery();

  const formattedEventTime = format(new Date(dashboard.creationDate), 'dd LLLL yyyy');

  const headInfo = [
    {
      label: 'Ranking',
      value: dashboard.rank,
    },
    {
      label: 'Cards',
      value: isLoading ? (
        <Shimmer width={40} height={24}>
          <rect x="0" y="2" rx="4" ry="4" width="40" height="20" />
        </Shimmer>
      ) : (
        nfts.length
      ),
    },
    {
      label: 'Battles',
      value: dashboard.battlesTotal,
    },
    {
      label: 'Calls',
      value: dashboard.callsTotal,
    },
    {
      label: 'Wins',
      value: dashboard.wins,
    },
    {
      label: 'Loses',
      value: dashboard.losses,
    },
  ];

  if (isMobile) {
    return (
      <UserProfileHeadInfoContainer>
        <UserProfileHeadInfoLeft>
          <Avatar size={54} src={path} />
          <UserProfileHeadInfoNickName>
            <Typography fontWeight={600} fontSize={22}>
              {dashboard.nickname}
            </Typography>
            <Typography color="secondary">
              {' '}
              <Trans i18nKey="Other.joinedAt">Joined at {{ time: formattedEventTime }}</Trans>
            </Typography>
          </UserProfileHeadInfoNickName>
        </UserProfileHeadInfoLeft>
        <Divider />
        <HorizontalListSwiper
          spaceBetween={16}
          slidesPerView="auto"
          freeMode={{ sticky: true }}
          mousewheel={{ forceToAxis: true }}
        >
          <HorizontalListSlide>
            <Stack spacing={20 / 8} direction="row">
              {headInfo.map((value, idx) => (
                <Fragment key={idx}>
                  {idx !== 0 && <Divider orientation="vertical" flexItem />}
                  <CardInfoBlock>
                    <Typography color="grey.A50" fontSize={13}>
                      {value.label}
                    </Typography>
                    <Typography fontSize={16} fontWeight={600}>
                      {value.value}
                    </Typography>
                  </CardInfoBlock>
                </Fragment>
              ))}
            </Stack>
          </HorizontalListSlide>
        </HorizontalListSwiper>
      </UserProfileHeadInfoContainer>
    );
  }

  return (
    <UserProfileHeadInfoContainer>
      <UserProfileHeadInfoLeft>
        <Avatar size={54} src={getAvatarAbsoluteUri(dashboard.avatar)} />
        <UserProfileHeadInfoNickName>
          <Typography fontWeight={600} fontSize={22}>
            {dashboard.nickname}
          </Typography>
          <Typography color="secondary">
            {' '}
            <Trans i18nKey="Other.joinedAt">Joined at {{ time: formattedEventTime }}</Trans>
          </Typography>
        </UserProfileHeadInfoNickName>
      </UserProfileHeadInfoLeft>
      <Divider />
      <CardInfo>
        <Stack spacing={20 / 8} direction="row">
          {headInfo.map((value, idx) => (
            <Fragment key={value.label}>
              {idx !== 0 && <Divider orientation="vertical" flexItem />}
              <CardInfoBlock>
                <Typography color="grey.A50" fontSize={13}>
                  {value.label}
                </Typography>
                <Typography fontSize={16} fontWeight={600}>
                  {value.value}
                </Typography>
              </CardInfoBlock>
            </Fragment>
          ))}
        </Stack>
      </CardInfo>
    </UserProfileHeadInfoContainer>
  );
};

import { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimeoutFn } from 'react-use';
import { add, format, formatDistanceToNowStrict, set } from 'date-fns';

import {
  Box,
  DateLabelConstructorProps,
  DateLabelSize,
  DateLabelStateMap,
  Icon,
  Stack,
  Typography,
} from '~shared/ui';

import { LanguageToDateLocaleMap } from '~shared/config/i18n';

import { getDistanceFromNow } from './utils';

export const DateLabelConstructor: FC<DateLabelConstructorProps> = ({ state, date, size }) => {
  switch (state) {
    case DateLabelStateMap.Upcoming:
      return <DateLabelUpcoming date={new Date(date)} size={size} />;
    case DateLabelStateMap.Ready:
      return <DateLabelReady date={new Date(date)} size={size} />;
    case DateLabelStateMap.Today:
      return <DateLabelToday date={new Date(date)} size={size} />;
    case DateLabelStateMap.Live:
      return <DateLabelLive date={new Date(date)} size={size} />;
    default:
    case DateLabelStateMap.Past:
    case DateLabelStateMap.Planned:
      return <DateLabelDefault date={new Date(date)} size={size} />;
  }
};

interface DateLabelElementCommonProps {
  size: DateLabelSize;
  date: Date;
}

const getDistance = (date: Date, language: string) => {
  const minute = 1000 * 60;
  const options = getDistanceFromNow(new Date(date)) < minute ? ({ unit: 'minute' } as const) : {};

  return formatDistanceToNowStrict(date, {
    addSuffix: true,
    locale: LanguageToDateLocaleMap[language],
    ...options,
  })
    .replace(/minutes?/i, 'min')
    .replace(/minutos?/i, 'min.')
    .replace(/horas?/i, 'hor.');
};

const useUpdatedDistance = (date: Date) => {
  const {
    i18n: { language },
  } = useTranslation();

  const [distance, setDistance] = useState(getDistance(date, language));

  const [, clear] = useTimeoutFn(
    () => {
      setDistance(getDistance(date, language));
    },
    add(set(new Date(), { milliseconds: 0, seconds: 0 }), { minutes: 1 }).getTime() -
      new Date().getTime()
  );

  useEffect(() => {
    clear();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  // handle language change
  useEffect(() => {
    setDistance(getDistance(date, language));
  }, [date, language]);

  return distance;
};

const DateLabelReady: FC<DateLabelElementCommonProps> = ({ date }) => {
  const distance = useUpdatedDistance(date);

  return <Fragment>{distance}</Fragment>;
};

const DateLabelToday: FC<DateLabelElementCommonProps> = ({ date }) => {
  const distance = useUpdatedDistance(date);

  return <Fragment>{distance}</Fragment>;
};

const DateLabelUpcoming: FC<DateLabelElementCommonProps> = ({ size, date }) => {
  const { t } = useTranslation();

  const distance = useUpdatedDistance(date);

  switch (size) {
    case 'sm':
      return (
        <Fragment>
          {distance}. {t('Other.lastBet')}
        </Fragment>
      );
    case 'md':
      return (
        <Box textAlign="center">
          <Typography>
            {distance}. {t('Other.lastBet')}
          </Typography>
        </Box>
      );
    case 'lg':
      return (
        <Box textAlign="center">
          <Typography fontSize={13} lineHeight={14 / 13}>
            {distance}
          </Typography>
          <Typography fontSize={10} lineHeight={12 / 10} mt={2 / 8}>
            {t('Other.lastBet')}
          </Typography>
        </Box>
      );
  }
};

const DateLabelDefault: FC<DateLabelElementCommonProps> = ({ date }) => {
  const { i18n } = useTranslation();

  return (
    <Fragment>
      {format(date, 'd MMM HH:mm', {
        locale: LanguageToDateLocaleMap[i18n.language],
      }).toUpperCase()}
    </Fragment>
  );
};

const DateLabelLive: FC<DateLabelElementCommonProps> = ({ size }) => {
  switch (size) {
    case 'sm':
      return (
        <Stack direction="row" spacing={2 / 8} alignItems="center">
          <Typography>LIVE</Typography>

          <Icon name="play" size={18} />
        </Stack>
      );
    case 'md':
    case 'lg':
      return (
        <Stack direction="row" spacing={4 / 8} alignItems="center">
          <Icon name="play" />

          <Typography>LIVE</Typography>
        </Stack>
      );
  }
};

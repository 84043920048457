import { FC, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  NotificationCardAdded,
  NotificationCardLivesChanged,
  NotificationDemoCardBurned,
  NotificationEventLive,
  NotificationEventSoon,
  NotificationPutCardOnAuction,
  NotificationSetWallet,
  NotificationTournamentCanceled,
  NotificationTournamentDelayed,
  NotificationTournamentFinished,
  NotificationTournamentStarted,
} from '../notifications';

import { NotificationServerEventType, useNotificationsModel } from '../../../model';

import { NotificationConstructorProps } from './types';

export const NotificationConstructor: FC<NotificationConstructorProps> = ({
  notification,
  place,
  shouldRead,
}) => {
  const { service } = useNotificationsModel();
  const candidateToRead = useRef<NodeJS.Timeout | null>(null);

  const { ref, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (shouldRead && !notification.read && inView) {
      candidateToRead.current = setTimeout(() => {
        service?.addToReadStack(notification.id);
      }, 5000);
    } else if (candidateToRead.current) {
      clearTimeout(candidateToRead.current);
    }
  }, [inView, notification.read]);

  const props = { place, ref };

  switch (notification.type) {
    case NotificationServerEventType.CardAdded:
      return <NotificationCardAdded notification={notification} {...props} />;
    case NotificationServerEventType.CardLivesChanged:
      return <NotificationCardLivesChanged notification={notification} {...props} />;
    case NotificationServerEventType.DemoCardBurned:
      return <NotificationDemoCardBurned notification={notification} {...props} />;
    case NotificationServerEventType.EventLive:
      return <NotificationEventLive notification={notification} {...props} />;
    case NotificationServerEventType.EventSoon:
      return <NotificationEventSoon notification={notification} {...props} />;
    case NotificationServerEventType.SetWalletNotification:
      return <NotificationSetWallet notification={notification} {...props} />;
    case NotificationServerEventType.PutCardAuction:
      return <NotificationPutCardOnAuction notification={notification} {...props} />;
    case NotificationServerEventType.TournamentStarted:
      return <NotificationTournamentStarted notification={notification} {...props} />;
    case NotificationServerEventType.TournamentDelayed:
      return <NotificationTournamentDelayed notification={notification} {...props} />;
    case NotificationServerEventType.TournamentCanceled:
      return <NotificationTournamentCanceled notification={notification} {...props} />;
    case NotificationServerEventType.TournamentFinished:
      return <NotificationTournamentFinished notification={notification} {...props} />;

    default:
      return null;
  }
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { InventoryItem, InventoryState } from './types';

const initialState: InventoryState = {
  unfreeze: 0,
  freeBet: 0,
  repair: 0,

  rareMerge: 0,
  epicMerge: 0,
  legendaryMerge: 0,
  mythicMerge: 0,

  rareTournamentTicket: 0,
  epicTournamentTicket: 0,
  legendaryTournamentTicket: 0,
  mythicTournamentTicket: 0,
};

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState: initialState,
  reducers: {
    set: (state: InventoryState, { payload }: PayloadAction<Partial<InventoryState>>) => ({
      ...state,
      ...payload,
    }),

    updateInventoryItem: (
      state: InventoryState,
      {
        payload,
      }: PayloadAction<{ item: InventoryItem; amount: number; operation: 'add' | 'subtract' }>
    ) => {
      const { item, amount, operation } = payload;

      if (state.hasOwnProperty(item)) {
        if (operation === 'add') {
          state[item] += amount;
        } else if (operation === 'subtract') {
          state[item] -= amount;
        } else {
          console.error(`Invalid operation '${operation}' provided.`);
        }
      } else {
        console.error(`Inventory item '${item}' does not exist.`);
      }
    },

    reset: () => {
      return { ...initialState };
    },
  },
});

export const inventoryActions = inventorySlice.actions;

import { NftRarity } from '~shared/api';

const COMMON_CARD_PLACEHOLDER_URL = process.env.PUBLIC_URL + '/assets/nfts/placeholder/common.png';

export const PlaceholderUrlMap: Record<NftRarity, string> = {
  [NftRarity.Common]: COMMON_CARD_PLACEHOLDER_URL,
  [NftRarity.Rare]: process.env.PUBLIC_URL + '/assets/nfts/placeholder/rare.png',
  [NftRarity.Epic]: process.env.PUBLIC_URL + '/assets/nfts/placeholder/epic.png',
  [NftRarity.Legendary]: process.env.PUBLIC_URL + '/assets/nfts/placeholder/legendary.png',
  // todo: add suitable mythic placeholder
  [NftRarity.Mythic]: process.env.PUBLIC_URL + '/assets/nfts/placeholder/legendary.png',
  [NftRarity.Demo]: COMMON_CARD_PLACEHOLDER_URL,
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EventStatus, useEventModel } from '~entities/event';
import { getTeamByBetChoice } from '~features/event-dialog/model';
import { useMediaQuery } from '~shared/lib/hooks';
import { Box, Stack } from '~shared/ui';

import { EventWarningMessage } from '../../EventWarningMessage';

import { EventMultipleCards } from '../EventDialogCards/EventMultipleCards';
import { EventSingleCard } from '../EventDialogCards/EventSingleCard';

import { EventDialogPlaceholder } from '../EventDialogPlaceholder';

import { EventBattleActions } from './EventBattleActions';

export const EventDialogBattle = () => {
  const { isMobile } = useMediaQuery();

  const {
    event,
    eventStatus,
    choice,
    cards,
    noBattleForEvent,
    isViewMode,
    isViewer,
    isViewerBattle,
    isLessOrEqualFiveMinutesLeft,
  } = useEventModel();

  const { t } = useTranslation();

  const team = useMemo(() => getTeamByBetChoice({ event: event, result: choice }), [choice, event]);

  const isOneCardOrMorePlaced = cards.length >= 1;
  const isMoreThanOneCardPlaced = cards.length > 1;
  const isTeamChosen = team !== null;
  const shouldShowMultipleCardsView =
    isTeamChosen && (isViewMode || isMobile ? isOneCardOrMorePlaced : isMoreThanOneCardPlaced);

  const renderContent = () => {
    switch (true) {
      case noBattleForEvent:
        return (
          <EventDialogPlaceholder
            title={t('EventDialog.noBetType', { betType: t('EventDialog.battle') })}
            description={t(
              isViewer
                ? 'EventDialog.youHaveNoBetTypeDescription'
                : 'EventDialog.haveNoBetTypeDescription',
              {
                betType: t('EventDialog.battle'),
              }
            )}
          />
        );

      case !isViewerBattle && isLessOrEqualFiveMinutesLeft:
        return (
          <EventDialogPlaceholder
            title={t('EventDialog.tooLittleTime')}
            description={t('EventDialog.tooLittleTimeDescription', {
              betType: t('EventDialog.battle'),
            })}
          />
        );

      default:
        return (
          <Stack spacing={20 / 8} mt={isMobile ? '20px' : 0}>
            <Box>{renderCards()}</Box>
            {isViewerBattle && eventStatus === EventStatus.Pending && (
              <EventWarningMessage>{t('EventDialog.changingBattle')}</EventWarningMessage>
            )}
            <EventBattleActions />
          </Stack>
        );
    }
  };

  const renderCards = () => {
    switch (true) {
      case shouldShowMultipleCardsView:
        return <EventMultipleCards />;
      default:
        return <EventSingleCard />;
    }
  };

  return renderContent();
};

import { styled } from '~shared/lib/styles';

export const TournamentInfoRowLabel = styled('div')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 14 / 13,
  color: theme.palette.grey.A50,

  [theme.breakpoints.down('mobile')]: {
    fontSize: 12,
  },
}));

export const TournamentInfoRowValue = styled('div')(({ theme }) => ({
  fontSize: 15,
  fontWeight: 600,
  lineHeight: 18 / 15,
  letterSpacing: -0.2,

  [theme.breakpoints.down('mobile')]: {
    fontSize: 14,
  },
}));

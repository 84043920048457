import { useTonAddress } from '@tonconnect/ui-react';
import { FC, SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Stack, Tab, Tabs, TabsList } from '~shared/ui';
import { useMediaQuery, useSearchParamsState } from '~shared/lib/hooks';

import { useQueryViewerDashboard } from '~entities/viewer';

import { useLeaderboardModel } from '~widgets/leaderboard-list';

import {
  UserProfileBattleList,
  UserProfileCallsList,
  UserProfileCardsList,
  UserProfileHeadInfo,
} from '~widgets/user-profile';

import { useProfileDialogModel } from '../model';

import { ProfileDialogProps } from './types';
import { StyledDialog } from './styled';

enum ProfileDialogTabs {
  Cards = 'Cards',
  Battles = 'battles',
  Calls = 'calls',
}

export const ProfileDialog: FC<ProfileDialogProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const { address } = useProfileDialogModel();

  const tonAddress = useTonAddress(true);

  const {
    data: userDashboard,
    isLoading,
    authorized,
  } = useQueryViewerDashboard({ address, tonAddress });

  const { profileDialogOpen } = useLeaderboardModel();
  const [tab, setTab] = useSearchParamsState<ProfileDialogTabs>('tab', ProfileDialogTabs.Cards);

  const handleChangeTab = useCallback(
    (_: SyntheticEvent, value: any) => {
      setTab(value as ProfileDialogTabs);
    },
    [setTab]
  );

  const getTabContent = () => {
    if (!userDashboard) {
      return null;
    }

    const tabs = {
      [ProfileDialogTabs.Cards]: <UserProfileCardsList address={address} />,
      [ProfileDialogTabs.Battles]: (
        <UserProfileBattleList
          address={address}
          nickname={userDashboard.nickname}
          avatar={userDashboard.avatar}
        />
      ),
      [ProfileDialogTabs.Calls]: (
        <UserProfileCallsList
          address={address}
          nickname={userDashboard.nickname}
          avatar={userDashboard.avatar}
        />
      ),
    };

    return tabs[tab];
  };

  if (!authorized) {
    return null;
  }

  // MOCK
  return (
    <StyledDialog open={profileDialogOpen} width={780} onClose={onClose}>
      {!isLoading && userDashboard && (
        <UserProfileHeadInfo dashboard={userDashboard} address={address} />
      )}

      <Stack spacing={10 / 8} mb={(isMobile ? 20 : 24) / 8}>
        <Container disableGutters negative={isMobile}>
          <Tabs value={tab} onChange={handleChangeTab as any}>
            <TabsList>
              <Tab value={ProfileDialogTabs.Cards}>{t('Other.cards')}</Tab>
              {/* <Tab value={ProfileDialogTabs.Battles}>{t('Other.battles')}</Tab> */}
              {/* <Tab value={ProfileDialogTabs.Calls}>{t('Tabs.myCalls')}</Tab> */}
            </TabsList>
          </Tabs>
        </Container>
      </Stack>

      {getTabContent()}
    </StyledDialog>
  );
};

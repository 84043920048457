import { FC } from 'react';
import { Modifier } from '@popperjs/core';

import { NotificationCenterPopperPaper, NotificationCenterPopperRoot } from './styled';
import { NotificationsCenterPopperProps } from './types';

export const NotificationsCenterPopper: FC<NotificationsCenterPopperProps> = ({
  modifiers = [],
  children,

  ...props
}) => {
  return (
    <NotificationCenterPopperRoot
      modifiers={[...popperModifiers, ...modifiers]}
      id="notification-center"
      placement="bottom-end"
      transition
      {...props}
    >
      {({ TransitionProps = {} }) => {
        const { in: open, onEnter, onExited } = TransitionProps as any;

        const animateVariant = open ? 'visible' : 'hidden';

        return (
          <NotificationCenterPopperPaper
            variants={motionListBoxVariants}
            animate={animateVariant}
            initial="hidden"
            onAnimationStart={open ? onEnter : undefined}
            onAnimationComplete={!open ? onExited : undefined}
          >
            {children}
          </NotificationCenterPopperPaper>
        );
      }}
    </NotificationCenterPopperRoot>
  );
};

export const popperModifiers: Partial<Modifier<any, any>>[] = [
  { name: 'offset', options: { offset: [16, 8] } },
];

const motionListBoxVariants = {
  hidden: { opacity: 0, y: -4, height: 20, transition: { duration: 0.2, type: 'tween' } },
  visible: { opacity: 1, y: 0, height: 600, transition: { duration: 0.2, type: 'tween' } },
};

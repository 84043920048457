/* eslint-disable react/jsx-key,no-alert */
/* eslint-disable react/jsx-key */
import { ReactElement, ReactNode } from 'react';
import { add, format } from 'date-fns';

import { Box, DateLabel, Stack, Typography, getStateByDate } from '~shared/ui';
import { BattleShellFactory, BattleTeam, BattleTeamProps, BattleVersus } from '~entities/battle';

export const MMAPlayers = {
  SharlezOliveira: {
    name: 'Sharlez Oliveira',
    avatar: process.env.PUBLIC_URL + '/assets/sample/SharlezOliveira.png',
  },
  IslamMachachev: {
    name: 'Islam Machachev',
    avatar: process.env.PUBLIC_URL + '/assets/sample/IslamMachachev.png',
  },
} as const;

export const MMA = {
  date: add(new Date(), { days: 3, seconds: 10 }),
  status: 'future',
  league: 'illustration-ufc',
} as const;

export const BattleTab = () => {
  const row = (name: string, elements: ReactNode[]) => (
    <Stack direction="column" spacing={2} minHeight={44} alignItems="flex-start">
      <Typography
        minWidth={150}
        borderBottom="1px solid #4A4A4A"
        paddingBottom={1}
        sx={{ fontSize: 20, fontWeight: 500 }}
      >
        {name}
      </Typography>

      <Stack spacing={4} direction="row" alignItems="flex-start">
        {elements}
      </Stack>
    </Stack>
  );

  const anotherRow = (name: string, elements: ReactNode[]) => (
    <Stack direction="column" spacing={2} minHeight={44} alignItems="flex-start">
      <Typography
        minWidth={150}
        borderBottom="1px solid #4A4A4A"
        paddingBottom={1}
        sx={{ fontSize: 20, fontWeight: 500 }}
      >
        {name}
      </Typography>

      {elements}
    </Stack>
  );

  const battleRow = (
    battles:
      | typeof battlesNoSlot
      | typeof battlesSlot
      | typeof battlesSlotLose
      | typeof battlesSlotWin
  ) => {
    return battles.map((battle) => {
      const { players, status, date, league } = battle;

      const battleProps = {
        status,
        leagueLogo: league,
        date: <DateLabel date={date} />,
        // @ts-ignore
        versus: <BattleVersus score={battle?.score} slot={battle?.versusSlot} />,
        homeTeam: (
          // @ts-ignore
          <BattleTeam
            key={players[0].name}
            players={[
              { name: players[0].name, shortName: players[0].name, avatar: players[0].avatar },
            ]}
          />
        ),
        awayTeam: (
          // @ts-ignore
          <BattleTeam
            key={players[1].name}
            players={[
              { name: players[1].name, shortName: players[0].name, avatar: players[1].avatar },
            ]}
          />
        ),
        players: players.map((player, idx) => (
          // @ts-ignore
          <BattleTeam
            // @ts-ignore
            slot={(idx === 0 && battle?.leftSlot) || (idx === 1 && battle?.rightSlot)}
            key={player.name}
            players={[{ name: player.name, shortName: players[0].name, avatar: player.avatar }]}
          />
        )) as [ReactElement<BattleTeamProps>, ReactElement<BattleTeamProps>],
      };

      return (
        <Stack
          spacing={4}
          borderBottom="1px solid grey"
          paddingBottom={4}
          sx={() => ({
            background: 'grey.A5',
            borderRadius: 2,
            padding: 2,
          })}
        >
          <Stack spacing={20 / 8} direction="row">
            <Box width={288}>
              <BattleShellFactory variant="square" size="sm" {...battleProps} />
            </Box>
            <Box width={320}>
              <BattleShellFactory variant="square" size="md" {...battleProps} />
            </Box>
            <Box width={350}>
              <BattleShellFactory variant="square" size="lg" {...battleProps} />
            </Box>
          </Stack>

          <BattleShellFactory variant="line" size="sm" {...battleProps} />
          <BattleShellFactory variant="line" size="md" {...battleProps} />
          <BattleShellFactory variant="line" size="lg" {...battleProps} />

          <Box width={328}>
            <BattleShellFactory variant="compact" {...battleProps} />
          </Box>
        </Stack>
      );
    });
  };

  return (
    <Stack spacing={4}>
      {anotherRow('Battles no slot', battleRow(battlesNoSlot))}
      {anotherRow('Battles slot', battleRow(battlesSlot))}
      {anotherRow('Battles slot win', battleRow(battlesSlotWin))}
      {anotherRow('Battles slot lose', battleRow(battlesSlotLose))}

      {dates.map((date: Date) => {
        return row(getStateByDate({ date }) + ' / ' + format(date, 'HH:mm, dd.MM.yyyy'), [
          <DateLabel date={date} size="lg" />,
          <DateLabel date={date} size="md" />,
          <DateLabel date={date} size="sm" />,
        ]);
      })}

      {row('Live', [
        <DateLabel date={add(new Date(), { minutes: -10 })} size="lg" live />,
        <DateLabel date={add(new Date(), { minutes: -10 })} size="md" live />,
        <DateLabel date={add(new Date(), { minutes: -10 })} size="sm" live />,
      ])}
    </Stack>
  );
};

const dates = [
  add(new Date(), { days: 1, seconds: 2 }),
  add(new Date(), { minutes: 30, seconds: 2 }),
  add(new Date(), { minutes: 5, seconds: 2 }),
  add(new Date(), { minutes: 0, seconds: 2 }),
  add(new Date(), { days: -1, seconds: 2 }),
];

export const slot = (
  <img
    src={process.env.PUBLIC_URL + '/assets/sample/slot.svg'}
    height="100%"
    width="auto"
    alt="slot"
  />
);

const battleOrigins = {
  ...MMA,
  status: 'future',
  players: [MMAPlayers.SharlezOliveira, MMAPlayers.IslamMachachev] as const,
} as const;

export const battlesNoSlot = [
  {
    ...battleOrigins,
  },
  {
    ...battleOrigins,
    score: [1, 2],
  },
  {
    ...battleOrigins,
    score: [2, 1],
  },
  {
    ...battleOrigins,
    score: [2, 2],
  },
] as const;

const battlesSlot = [
  {
    ...battleOrigins,
    versusSlot: slot,
  },
  {
    ...battleOrigins,
    leftSlot: slot,
  },
  {
    ...battleOrigins,
    rightSlot: slot,
  },
] as const;

const battlesSlotLose = [
  {
    ...battleOrigins,
    score: [1, 2],
    leftSlot: slot,
    status: 'lose',
  },
  {
    ...battleOrigins,
    score: [2, 1],
    rightSlot: slot,
    status: 'lose',
  },
  {
    ...battleOrigins,
    score: [1, 2],
    versusSlot: slot,
    status: 'lose',
  },
] as const;

export const battlesSlotWin = [
  {
    ...battleOrigins,
    score: [1, 2],
    rightSlot: slot,
    status: 'win',
  },
  {
    ...battleOrigins,
    score: [2, 1],
    leftSlot: slot,
    status: 'win',
  },
  {
    ...battleOrigins,
    score: [2, 2],
    versusSlot: slot,
    status: 'win',
  },
] as const;

import { useTranslation } from 'react-i18next';

import { Box } from '~shared/ui';

import { NftCard } from '~entities/nft';

import { NftCardInfoStatus } from '../../NftCardInfoStatus';

import {
  NftCardInfoHeaderMainInfo,
  NftCardInfoHeaderRoot,
  NotCardInfoHeaderMainInfoRarity,
} from './styled';

import { NftCardInfoHeaderProps } from './types';

export const NftCardInfoHeader = ({ nft }: NftCardInfoHeaderProps) => {
  const { t } = useTranslation();

  return (
    <NftCardInfoHeaderRoot>
      <NftCardInfoHeaderMainInfo>
        <NftCard nft={nft} hideStates size="xs" />
        <Box flex={1}>
          <NotCardInfoHeaderMainInfoRarity>{t(nft.readableRarity)}</NotCardInfoHeaderMainInfoRarity>
          <NftCardInfoStatus nft={nft} />
        </Box>
      </NftCardInfoHeaderMainInfo>
    </NftCardInfoHeaderRoot>
  );
};

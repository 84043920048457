import { FC } from 'react';

import { Container, Stack } from '~shared/ui';

import { Announcements } from '~widgets/announcements';
import { CommonMultipleEventsList } from '~widgets/common-multiple-events-list';
// TODO: fix import later
import { DailyQuestProgressBar } from '~widgets/daily-quest-progress-bar/ui/DailyQuestProgressBar';

import { HomeProps } from './types';

export const HomePage: FC<HomeProps> = () => {
  return (
    <Stack spacing={12 / 8}>
      <DailyQuestProgressBar />

      <Announcements />
      <Container>
        <CommonMultipleEventsList label="Home" />
      </Container>
    </Stack>
  );
};

import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { FormWithCheckNickname, NicknameField } from '~shared/ui';

import { useViewerModel } from '~entities/viewer';

type Form = FormWithCheckNickname<{
  nickname: string;
}>;

export const useSetNicknameForm = () => {
  const { updateNickname } = useViewerModel();

  const form = useForm<Form>();

  const handleSubmit: SubmitHandler<Form> = useCallback(
    ({ nickname }) => updateNickname(nickname),
    [updateNickname]
  );

  return {
    submitAvailable: form.watch(NicknameField.checkingNicknameFieldName),
    submit: form.handleSubmit(handleSubmit),
    form,
  };
};

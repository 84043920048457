import { clsx } from 'clsx';

import { BattleResult } from '~shared/api';
import { Icon, Stack } from '~shared/ui';

import { EventStatus, useEventModel } from '~entities/event';
import { BattleBadge, SportToIconMap } from '~entities/battle';

import { EventDialogTeamsName } from './EventDialogTeamsName';

import {
  EventAwayTeamContainer,
  EventDialogTeamsAvatarContainer,
  EventHomeTeamContainer,
  EventTeamBadgeContainer,
  EventTeamDrawResult,
  EventTeamsAvatar,
  EventTeamsContainer,
  EventTeamsNameContainer,
  EventTeamsVersus,
  eventTeamsClasses,
} from './styled';

export const EventDialogTeams = () => {
  const { isMakeCall, isCall, event, eventStatus, result } = useEventModel();

  if (!event) {
    return null;
  }

  const homeTeamPlayers = event.homeTeam.players.map((player) => ({
    avatar: player.logo,
    name: player.name,
    shortName: player.shortName,
  }));

  const awayTeamPlayers = event.awayTeam.players.map((player) => ({
    avatar: player.logo,
    name: player.name,
    shortName: player.shortName,
  }));

  return (
    <EventTeamsContainer className={clsx({ [eventTeamsClasses.call]: isMakeCall || isCall })}>
      <EventHomeTeamContainer
        className={clsx(eventTeamsClasses.teamContainer, eventTeamsClasses.leftContainer)}
      >
        <EventDialogTeamsAvatarContainer>
          <EventTeamBadgeContainer>
            {eventStatus === EventStatus.Passed && result === BattleResult.HomeTeamWon && (
              <BattleBadge win />
            )}
          </EventTeamBadgeContainer>

          {homeTeamPlayers.map((player, idx) => (
            <EventTeamsAvatar src={player.avatar} key={idx}>
              <Icon name={SportToIconMap[event.sport]} />
            </EventTeamsAvatar>
          ))}
        </EventDialogTeamsAvatarContainer>

        <EventTeamsNameContainer className={eventTeamsClasses.nameContainer}>
          {homeTeamPlayers.map((player, idx) => (
            <EventDialogTeamsName key={idx} name={player.name} shortName={player.shortName} />
          ))}
        </EventTeamsNameContainer>
      </EventHomeTeamContainer>

      <Stack spacing={4 / 8} alignItems="center">
        {eventStatus === EventStatus.Passed && result === BattleResult.Draw && (
          <EventTeamDrawResult>Draw</EventTeamDrawResult>
        )}
        <EventTeamsVersus>VS</EventTeamsVersus>
      </Stack>

      <EventAwayTeamContainer
        className={clsx(eventTeamsClasses.teamContainer, eventTeamsClasses.rightContainer)}
      >
        <EventTeamsNameContainer className={eventTeamsClasses.nameContainer}>
          {awayTeamPlayers.map((player, idx) => (
            <EventDialogTeamsName key={idx} name={player.name} shortName={player.shortName} />
          ))}
        </EventTeamsNameContainer>

        <EventDialogTeamsAvatarContainer>
          <EventTeamBadgeContainer>
            {eventStatus === EventStatus.Passed && result === BattleResult.AwayTeamWon && (
              <BattleBadge win />
            )}
          </EventTeamBadgeContainer>

          {awayTeamPlayers?.map((player, idx) => (
            <EventTeamsAvatar src={player.avatar} key={idx}>
              <Icon name={SportToIconMap[event.sport]} />
            </EventTeamsAvatar>
          ))}
        </EventDialogTeamsAvatarContainer>
      </EventAwayTeamContainer>
    </EventTeamsContainer>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentPlaceholder } from '~shared/ui';

import { BattleSeparatedHorizontalList, useCallList } from '~entities/battle';
import { useCallsListQuery } from '~entities/calls';

import { UserProfileCallsListProps } from './types';

export const UserProfileCallsList: FC<UserProfileCallsListProps> = ({
  sport,
  league,
  divider,
  address,
  nickname,
  avatar,
}) => {
  const { t } = useTranslation();

  const callsListQuery = useCallsListQuery({ address: address, sport, league });
  const { passedList, currentList } = useCallList({
    list: callsListQuery.list,
    user: { nickname, avatar, address },
  });

  if (callsListQuery.isEmptyList) {
    return (
      <ContentPlaceholder
        heading={t('Other.NoCalls')}
        message={sport ? `${t('Other.notHave')}` : `${t('Other.NoBetYet')}`}
      />
    );
  }

  return (
    <BattleSeparatedHorizontalList
      loading={callsListQuery.isLoading}
      passedList={passedList}
      currentList={currentList}
      divider={divider}
    />
  );
};

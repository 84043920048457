import { FC } from 'react';

import { ChipLabel, ChipRoot } from './styled';
import { ChipProps } from './types';

export const Chip: FC<ChipProps> = ({ color = 'primary', label, ...otherProps }) => {
  return (
    <ChipRoot color={color} {...otherProps}>
      <ChipLabel>{label}</ChipLabel>
    </ChipRoot>
  );
};

import { FC } from 'react';

import { Icon } from '~shared/ui';

import { NftShouldBeRecoveredRoot } from './styled';

import { NftShouldBeRecoveredProps } from './types';

export const NftShouldBeRecovered: FC<NftShouldBeRecoveredProps> = ({ broken }) => {
  return (
    <NftShouldBeRecoveredRoot $broken={broken}>
      <Icon size={18} name="fix" />
    </NftShouldBeRecoveredRoot>
  );
};

import { Address } from '@ton/core';

import { apiInstance, tonApiInstance } from '../apiInstance';

import { mapGetViewerDashboardResponse } from './mappers';
import { ViewerRoutes } from './routes';
import {
  ApiGetDefaultAvatars,
  ApiGetViewerDashboard,
  ApiGetViewerDashboardResponseRawData,
  ApiGetViewerProfile,
  ApiPostCheckNickname,
  ApiPostOnboardingPassed,
  ApiPostRecoveryPassword,
  ApiPostSetPassword,
  ApiPostSetTonWallet,
  ApiPostSetWallet,
  ApiPostUpdatePassword,
  ApiPostViewerAvatar,
  ApiPutNickname,
  ApiPutSwitchLanguage,
  ApiPutViewerAvatar,
} from './types';

export const getDefaultAvatars: ApiGetDefaultAvatars = () => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get(ViewerRoutes.defaultAvatars())
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const getViewerProfile: ApiGetViewerProfile = () => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get(ViewerRoutes.profile())
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const getViewerDashboard: ApiGetViewerDashboard = ({ tonAddress, params }) => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .get(ViewerRoutes.tonDashboard(Address.normalize(tonAddress)), { params })
      .then(async ({ data }) => {
        const profile = await getViewerProfile();

        const resultData: ApiGetViewerDashboardResponseRawData = {
          ...data,
          nickname: profile.nickname,
          avatar: profile.avatar,
        };

        resolve(mapGetViewerDashboardResponse(resultData));
      })
      .catch(reject);
  });
};

export const disconnectWallet = () => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get(ViewerRoutes.disconnectWallet())
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postUpdatePassword: ApiPostUpdatePassword = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ViewerRoutes.updatePassword(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postViewerAvatar: ApiPostViewerAvatar = (data, onUploadProgress) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ViewerRoutes.userAvatar(), data, { onUploadProgress })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const putViewerAvatar: ApiPutViewerAvatar = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .put(ViewerRoutes.userAvatar(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const putNickname: ApiPutNickname = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .put(ViewerRoutes.setNickname(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postRecoveryPassword: ApiPostRecoveryPassword = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ViewerRoutes.recoveryPassword(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postSetPassword: ApiPostSetPassword = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ViewerRoutes.setPassword(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postSetWallet: ApiPostSetWallet = async (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ViewerRoutes.setWallet(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postSetTonWallet: ApiPostSetTonWallet = async (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ViewerRoutes.setTonWallet(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postCheckNickname: ApiPostCheckNickname = (data, signal) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ViewerRoutes.checkNickname(), data, { signal })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postOnboardingPassed: ApiPostOnboardingPassed = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ViewerRoutes.setOnboardingPassed(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const putSwitchLanguage: ApiPutSwitchLanguage = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .put(ViewerRoutes.language(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

import { ReactNode, createElement } from 'react';

import { ApiGetCallsMappedData, ApiGetCallsResponseData } from '~shared/api';

import { useBattleAutoStyle } from '~entities/battle';
import { isEventCurrent, isEventNotStarted, isEventPassed } from '~entities/event';

import { BattleCall } from '../ui';

interface UseCallListProps {
  list?: ApiGetCallsResponseData;
  user?: {
    nickname: string;
    avatar?: string;
    address: string;
  };
}

interface UseCallList {
  (params: UseCallListProps): {
    list: Array<ReactNode>;
    passedList: Array<ReactNode>;
    currentList: Array<ReactNode>;
  };
}

export const useCallList: UseCallList = ({ list = [], user }) => {
  const style = useBattleAutoStyle('line');

  const renderCall = (call: ApiGetCallsMappedData) => {
    return createElement(BattleCall, { ...call, key: call.callId, user, ...style });
  };

  return {
    list: list.map(renderCall),
    passedList: list.filter((call) => isEventPassed(call.result)).map(renderCall),
    currentList: list
      .filter((call) => isEventCurrent(call.date, call.result) || isEventNotStarted(call.date))
      .map(renderCall),
  };
};

import { Dialog, dialogClasses } from '~shared/ui';
import { styled } from '~shared/lib/styles';

export const StyledDialog = styled(Dialog)({
  [`& .${dialogClasses.content}`]: {
    height: 424,
    padding: 0,
    margin: 0,
  },
});

export const DailyQuestDialog = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // justifyContent: 'space-between',
  padding: 15,
  borderRadius: 16,
  width: '100%',
  // width: 340,
  height: 424,
  background: '#020202',
  position: 'relative',
  overflow: 'hidden',
});

export const AdditionalBlock = styled('div')({
  position: 'absolute',
  bottom: '32%',
  right: 31,
  width: 450,
  height: 450,
  borderRadius: '50%',
  // background: 'radial-gradient(circle, #0000ff, #000020)',
  background: 'rgba(0, 62, 165, 0.62)',
  filter: 'blur(60px)',
});

export const GiftWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  height: 160,
});

export const GiftImageWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 3,
});

export const GiftImage = styled('img')({
  // position: 'absolute',
  height: 'auto',
  width: 'auto',
  zIndex: 1,
  // left: '19%',
  objectFit: 'contain',
});

export const AvatarImageWrapper = styled('div')({
  position: 'absolute',
  height: 80,
  width: 80,
  zIndex: 1,
  left: '37%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const AvatarImageShadow = styled('div')({
  position: 'absolute',
  height: 80,
  width: 80,
  zIndex: 1,
  background:
    'radial-gradient(rgba(58, 137, 255, 1), rgba(58, 137, 255, 1), rgba(26, 159, 255, 0.7) )',
  borderRadius: '50%',
  filter: 'blur(15px)',
});

export const ProcessBarGift = styled('div')({
  backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/dailyQuests/gift.png'})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  height: 204,
  width: 172,
  right: -70,
  position: 'absolute',
});

export const ProcessBar = styled('div')({
  backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/dailyQuests/processBar.png'})`,
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  height: 9,
  width: '100%',
  position: 'absolute',
});

export const Count = styled('div')({
  position: 'absolute',
  display: 'flex',
  backgroundColor: 'rgba(0, 0, 0, 0.57)',
  borderRadius: 51.8,
  padding: '6px 14px',
  bottom: 23,
  right: '29%',
  zIndex: 4,
});

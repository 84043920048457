import { createSlice } from '@reduxjs/toolkit';

import { UserProfileState } from './types';

const initialState: UserProfileState = {
  open: false,
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    open: (state) => {
      return { ...state, open: true };
    },
    close: (state) => {
      return { ...state, open: false };
    },

    reset: () => {
      return { ...initialState };
    },
  },
});

export const userProfileActions = userProfileSlice.actions;

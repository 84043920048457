import { styled } from '~shared/lib/styles';

import { Box } from '../../layout';

export const StyledDragCell = styled(Box, {
  name: 'MergeCardsDialog',
  slot: 'dragCell',
})(({ theme }) => ({
  transition: theme.transitions.create(['background-color', 'box-shadow'], {
    duration: 250,
  }),
  border: `1.5px solid ${theme.palette.grey.A20}`,
  borderRadius: 10,
  height: 106,
  width: 72,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [`&.${dragCellClasses.transparentBackground}`]: {
    background: 'transparent',
  },

  [`&.${dragCellClasses.highlightedBackground}`]: {
    background: theme.palette.primary.light,
  },

  [`&.${dragCellClasses.highlightedErrorBackground}`]: {
    background: theme.palette.error.main,
  },

  [`&.${dragCellClasses.highlightedBoxShadow}`]: {
    boxShadow: `0px 0px 31px 1px ${theme.palette.primary.light}`,
  },

  [`&.${dragCellClasses.highlightedErrorBoxShadow}`]: {
    boxShadow: `0px 0px 31px 1px ${theme.palette.error.main}`,
  },

  [`&.${dragCellClasses.transparentBoxShadow}`]: {
    boxShadow: '0px 0px 31px 1px rgba(46, 178, 255, 0.2)',
  },
}));

export const dragCellClasses = {
  transparentBackground: 'DragCell-transparentBackground',
  highlightedBackground: 'DragCell-highlightedBackground',
  highlightedErrorBackground: 'DragCell-highlightedErrorBackground',

  highlightedBoxShadow: 'DragCell-highlightedBoxShadow',
  highlightedErrorBoxShadow: 'DragCell-highlightedErrorBoxShadow',
  transparentBoxShadow: 'DragCell-transparentBoxShadow',
};

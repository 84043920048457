import { Address } from '@ton/core';
import { useTonAddress, useTonWallet } from '@tonconnect/ui-react';
import { useCallback, useEffect, useState } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';

import { useWeb3React } from '@web3-react/core';

import { ethers } from 'ethers';

import { Nft, PartialNft, fetchNfts, getActiveBets } from '~shared/api';
import { Multicall__factory } from '~shared/contracts';
import { useDispatch } from '~shared/lib/hooks';
import { getProvider } from '~shared/lib/utils';
import { ApiFreeUnfreezeRequest, freeUnfreeze } from '~shared/api/consumables';

import { EventStatus, eventActions, useEventModel } from '~entities/event';
import { useCallGasless } from '~entities/wallet';
import { useViewerModel } from '~entities/viewer';
import { useInventoryModel, useInventorySelector } from '~widgets/inventory';

import { getSortedNftsByRarity } from '../lib';

import {
  fetchNftInfoFromChain,
  getActiveNftStatuses,
  getFreezePeriods,
  getNftInfoMulticallRequests,
  getViewerAuctionCards,
  getViewerBetCards,
  getViewerCallCards,
  getViewerWalletCards,
  mapNftInfoFromMulticallRequest,
} from './helpers';

import { useNftSelector } from './selectors';
import { useNftCardModel } from './model';
import { nftActions } from './slice';

type UseQueryNftsResponseData = {
  nfts: Array<PartialNft>;
  cursor: string | null;
} | void;

export const useQueryNfts = (
  address?: string,
  options?: Omit<UseQueryOptions<UseQueryNftsResponseData>, 'queryKey' | 'queryFn'>
) => {
  const queryNfts = async (): Promise<UseQueryNftsResponseData> => {
    if (!address) {
      return;
    }

    const nfts = (await fetchNfts({ walletAddress: Address.normalize(address) })).nfts;

    /**
     * Initialize contract
     */
    // const provider = getProvider();
    // // prettier-ignore
    // const multicallContract = Multicall__factory.connect(process.env.REACT_APP_ADDRESS_MULTICALL, provider);
    // // prettier-ignore
    // const multicallContractAttached = multicallContract.attach(process.env.REACT_APP_ADDRESS_MULTICALL);

    // /**
    //  * Getting all NFTs on wallet and active NFT IDs in one promise
    //  */
    // const [
    //   { walletCards, cursor },
    //   { betCardIds, activeBets, activeBetEvents },
    //   { callCardIds, activeCalls, activeCallEvents },
    //   auctionCards,
    // ] = await Promise.all([
    //   // Getting viewer's wallet NFTs
    //   getViewerWalletCards(address, provider, multicallContractAttached),

    //   // Getting all active NFT IDs and related events or auctions
    //   getActiveBets({ address: Address.normalize(address) }),
    //   getViewerCallCards(address, provider),
    //   getViewerAuctionCards(address, provider),
    // ]);

    // console.log({ betCardIds, activeBets, activeBetEvents }, '1');

    // const freezePeriods = await getFreezePeriods(provider);

    // const auctionCardIds = auctionCards.map(({ cardId }) => cardId);
    // const activeCardIds = [...betCardIds, ...callCardIds, ...auctionCardIds];

    // console.log(activeCardIds, '2');

    // /**
    //  * Requesting specific NFT info for active NFTs
    //  */
    // // const activeNftsMulticallRequestPromises = activeCardIds.map((cardId) => {
    // //   return getNftInfoMulticallRequests({ tokenId: cardId, provider });
    // // });

    // // const activeNftsMulticallRequests = await Promise.all(activeNftsMulticallRequestPromises).then(
    // //   (requests) => requests.flatMap((request) => request)
    // // );

    // // // prettier-ignore
    // // const activeNftsMulticallResult = await multicallContractAttached.callStatic.multicall(activeNftsMulticallRequests);

    // const activeNfts = //mapNftInfoFromMulticallRequest(
    //   activeCardIds.map((cardId) => ({ token_id: cardId }));
    // //   activeNftsMulticallResult
    // //);

    // console.log(activeNfts, '3');

    // /**
    //  * Mapping active NFTs with related events/auctions
    //  */
    // const activeNftsWithEvents = activeNfts.map((activeNft): PartialNft => {
    //   const tokenId = activeNft.token_id;

    //   const foundBet = activeBets.find((bet) => bet.cardId === tokenId);
    //   const foundCall = activeCalls.find((call) => call.cardId === tokenId);
    //   const foundAuction = auctionCards.find((auctionCard) => auctionCard.cardId === tokenId);

    //   // if (foundAuction) {
    //   //   return {
    //   //     ...activeNft,
    //   //     relatedAuction: foundAuction,
    //   //     isOnAuction: true,
    //   //   };
    //   // }

    //   if (foundBet) {
    //     const foundBetEvent = activeBetEvents.find((betEvent) => betEvent.id === foundBet.eventId);

    //     if (foundBetEvent) {
    //       const foundBetCardIds = activeBets
    //         .filter((activeBet) => activeBet.eventId === foundBetEvent.id)
    //         .map((activeBet) => activeBet.cardId);

    //       return {
    //         ...activeNft,
    //         ...getActiveNftStatuses(foundBetEvent.result, foundBetEvent.date),
    //         relatedEvent: {
    //           ...foundBetEvent,
    //           choice: foundBet.choice,
    //           isCall: false,
    //           cards: foundBetCardIds.map((cardId) => {
    //             const foundActiveNft = activeNfts.find(
    //               (activeNft) => activeNft.token_id === cardId
    //             )!;

    //             return {
    //               ...foundActiveNft,
    //               ...getActiveNftStatuses(foundBetEvent.result, foundBetEvent.date),
    //             };
    //           }),
    //         },
    //       };
    //     }
    //   }

    //   // if (foundCall) {
    //   //   const foundCallEvent = activeCallEvents.find(
    //   //     (callEvent) => callEvent.callId.toString() === foundCall.callId
    //   //   )!;

    //   //   if (foundCallEvent) {
    //   //     return {
    //   //       ...activeNft,
    //   //       ...getActiveNftStatuses(foundCallEvent.result, foundCallEvent.date),
    //   //       relatedEvent: {
    //   //         ...foundCallEvent,
    //   //         cards: [],
    //   //         isCall: true,
    //   //       },
    //   //     };
    //   //   }

    //   //   return activeNft;
    //   // }
    // });

    // const filteredWalletCards = walletCards.filter((nft) => {
    //   return !activeCardIds.includes(nft.token_id);
    // });

    // const sortedNfts = getSortedNftsByRarity(
    //   [...activeNftsWithEvents, ...filteredWalletCards],
    //   'asc'
    // ).map((nft) => ({
    //   ...nft,
    //   freezePeriod: freezePeriods[nft.rarity],
    // }));

    return { nfts, cursor: null };
  };

  return useQuery(
    ['nfts', address],
    () => {
      return queryNfts();
    },
    options
  );
};

export const useQueryNextNfts = () => {
  const { provider } = useWeb3React<ethers.providers.JsonRpcProvider>();

  const account = useTonAddress(true);

  const { cursor } = useNftSelector();

  const dispatch = useDispatch();

  const getNextWalletCards = useCallback(async () => {
    if (!provider || !cursor || !account) {
      return;
    }

    const multicallContract = Multicall__factory.connect(
      process.env.REACT_APP_ADDRESS_MULTICALL,
      provider
    );

    // prettier-ignore
    const multicallContractAttached = multicallContract.attach(process.env.REACT_APP_ADDRESS_MULTICALL);

    const data = await getViewerWalletCards(account, provider, multicallContractAttached, cursor);

    dispatch(nftActions.safelyAddNfts(data?.walletCards as Array<Nft>));
    dispatch(nftActions.setCursor(data?.cursor as string));

    if (!data.cursor) {
      dispatch(nftActions.setNftsLoading(false));
    }
  }, [account, cursor, dispatch, provider]);

  return {
    getNextWalletCards,
  };
};

export const useQueryViewerNfts = () => {
  const dispatch = useDispatch();

  const { authorized, tonAddress } = useViewerModel();
  const { nftsLoading } = useNftCardModel();

  const isConnected = !!tonAddress;

  const { isFetched } = useQueryNfts(tonAddress, {
    onSuccess: (data) => {
      if (data) {
        dispatch(nftActions.safelyAddNfts(data.nfts as Array<Nft>));
        dispatch(nftActions.setCursor(data.cursor));

        if (!data.cursor) {
          dispatch(nftActions.setNftsLoading(false));
        }
      }
    },
    onError: (err) => {
      console.error(`Failed to query NFTs: ${err}`);
    },
    enabled: isConnected && authorized,
    staleTime: 3 * 60 * 1000,
  });

  const { nfts } = useNftSelector();

  return { nfts, isLoading: nftsLoading, isFetched };
};

export const useQueryLastAddedNft = () => {
  const { nfts } = useNftSelector();
  // const { account } = useWeb3React();
  const account = useTonAddress();

  const dispatch = useDispatch();

  const queryLastAddedNft = async () => {
    const provider = getProvider();

    try {
      let recentlyAddedNft: Nft | null = null;

      const { nfts: updatedNfts } = await fetchNfts({ walletAddress: account! });

      for (const nft of updatedNfts) {
        const foundNft = nfts.find((previousNft) => previousNft.token_id === nft.token_id);

        if (!foundNft) {
          recentlyAddedNft = nft;
          break;
        }
      }

      if (recentlyAddedNft) {
        const nftWithAdditionalProperties = await fetchNftInfoFromChain({
          nft: recentlyAddedNft,
          provider,
        });

        if (recentlyAddedNft) {
          dispatch(nftActions.addNft(nftWithAdditionalProperties));
        }

        return nftWithAdditionalProperties;
      }
    } catch {
      console.error('Failed to query last added nft');
    }
  };

  return queryLastAddedNft;
};

export const useIsAnyNftDialogOpen = () => {
  const { isOpen: eventDialogOpen, eventStatus } = useEventModel();
  const { mergeDialogOpen } = useNftCardModel();

  return [mergeDialogOpen, eventDialogOpen && eventStatus !== EventStatus.Passed].some(
    (state) => state
  );
};

export const useNftPreviewInfo = () => {
  const { previewInfo } = useNftSelector();
  const dispatch = useDispatch();

  const { openRelatedEvent } = useEventModel();

  const openPreview = useCallback(
    (nft: Nft) => {
      dispatch(nftActions.setPreviewInfo({ open: true, nft }));
    },
    [dispatch]
  );

  const closePreview = useCallback(() => {
    if (previewInfo.open) {
      dispatch(nftActions.setPreviewInfo({ open: false, nft: null }));
    }
  }, [dispatch, previewInfo.open]);

  const getPreviewOpen = useCallback(
    (tokenId: number | string) => {
      return previewInfo.open && previewInfo.nft?.token_id === tokenId;
    },
    [previewInfo.nft?.token_id, previewInfo.open]
  );

  const openNftRelatedEvent = (nft: Nft) => {
    closePreview();

    if (nft.relatedEvent) {
      closePreview();

      openRelatedEvent(nft.relatedEvent);
    }
  };

  return {
    open: previewInfo.open,
    nft: previewInfo.nft,
    openPreview: openPreview,
    closePreview: closePreview,
    getPreviewOpen,
    openNftRelatedEvent,
  };
};

export const useNftUnfreeze = () => {
  const { onUnfreeze, setIsBlockedForTransaction } = useNftCardModel();
  const { refetch: refetchConsumables } = useInventoryModel();
  const { unfreeze } = useInventorySelector();

  const gaslessUnfreezeCard = useCallGasless<ApiFreeUnfreezeRequest>({
    callback: freeUnfreeze,
    transactionName: 'Unfreeze',
    successMessage: 'Successful unfreeze',
    errorMessage: 'Error on unfreeze',
  });

  const unfreezeHandler = async (tokenId: string) => {
    await gaslessUnfreezeCard({ tokenId });

    await refetchConsumables();

    onUnfreeze(tokenId);
    setIsBlockedForTransaction([tokenId]);
  };

  return {
    unfreeze,
    unfreezeHandler,
  };
};

export const useWrapNftAction = () => {
  const { closePreview } = useNftPreviewInfo();
  const dispatch = useDispatch();

  const handleWrapNftActions = () => {
    closePreview();
    // todo: make all changes to another state trough model (useEventModel)
    dispatch(eventActions.reset());
  };

  return { handleWrapNftActions };
};

/**
 * This hook fix the race condition between wallet initialization (fetch nfts)
 * and already opened EventDialog e.g for `/event/:eventId` route
 */
export const useSynchronizeEventDialogBet = () => {
  const dispatch = useDispatch();

  const { isFetched, nfts } = useQueryViewerNfts();
  const { event, isOpen, isCall } = useEventModel();

  useEffect(() => {
    const eventDialogEventId = event?.id;

    if (eventDialogEventId && isOpen && isFetched && !isCall) {
      const relatedNft = nfts.find((nft) => nft.relatedEvent?.id === eventDialogEventId);
      const relatedEvent = relatedNft?.relatedEvent;

      if (relatedEvent && !relatedEvent.isCall) {
        // todo: make all changes to another state trough model (useEventModel)
        dispatch(
          eventActions.set({
            // todo: deep partial
            // @ts-ignore
            battle: {
              cards: relatedEvent.cards as Nft[],
            },
            choice: relatedEvent.choice,
            isViewMode: true,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);
};

import { FC } from 'react';

import { Icon, Typography } from '~shared/ui';

import { BoxMessage } from '../styled';

import { YourCardSoldTypes } from './types';

export const YourCardSold: FC<YourCardSoldTypes> = ({ lastPrice, nickName, currency }) => {
  return (
    <BoxMessage>
      <Icon name="fire" size={24} />
      <Typography color="secondary" fontSize={12} ml={1}>
        You sold your card through Auction for {lastPrice} {currency} to {nickName}
      </Typography>
    </BoxMessage>
  );
};

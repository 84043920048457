import { ApiCommonLeague, ApiCommonSport } from '~shared/api';

import { NotificationsService } from './service';

/**
 * Event types
 */
export enum NotificationServerEventType {
  /** User events */
  CardAdded = 'card_added',
  CardLivesChanged = 'card_lives_changed',
  DemoCardBurned = 'trial_ended',

  SetWalletNotification = 'set_wallet_notification',

  PutCardAuction = 'put_card_auction',

  EventSoon = 'event_soon',
  EventLive = 'event_live',

  TournamentStarted = 'tournament_started',
  TournamentDelayed = 'tournament_delayed',
  TournamentCanceled = 'tournament_canceled',
  TournamentFinished = 'tournament_finished',

  /** System events */
  NotificationsHistoryResponse = 'notifications_history_response',
}

export enum NotificationClientEventType {
  SetUser = 'set_user',
  ConfirmNotification = 'confirm_notification',
  NotificationsHistory = 'notifications_history',
}

/**
 * Slice
 */
export interface NotificationsState {
  service: NotificationsService | null;
  notifications: NotificationEvent[];
}

/**
 * Notifications
 */
export interface NotificationRawEvent {
  type: NotificationServerEventType;
  notificationId?: number;
  creationDate?: number;
  payload: Object | string;
}

export interface NotificationRawHistoryEvent extends NotificationRawEvent {
  status: 1 | 2;
}

export interface NotificationBaseEvent<Payload = {}, Type = NotificationServerEventType> {
  id: number;
  type: Type;
  payload: Payload;
  date: Date;
  read: boolean;
}

export type NotificationEvent =
  // user events
  | NotificationEventCardAdded
  | NotificationEventCardLivesChanged
  | NotificationEventDemoCardBurned
  | NotificationEventSetWallet
  | NotificationEventPutCardAuction
  | NotificationEventEventSoon
  | NotificationEventEventLive
  | NotificationEventTournamentStarted
  | NotificationEventTournamentDelayed
  | NotificationEventTournamentCanceled
  | NotificationEventTournamentFinished
  // system events
  | NotificationEventHistoryResponse;

/**
 * User Events
 */

/** Cards */
export type NotificationEventCardAdded = NotificationBaseEvent<
  { tokenId: number },
  NotificationServerEventType.CardAdded
>;

export type NotificationEventCardLivesChanged = NotificationBaseEvent<
  {
    newLives: number;
    oldLives: number;
    tokenId: number;
  },
  NotificationServerEventType.CardLivesChanged
>;

export type NotificationEventDemoCardBurned = NotificationBaseEvent<
  {
    betsTotal: number;
    betsWon: number;
    accuracy: number;
    totalReward: number;
  },
  NotificationServerEventType.DemoCardBurned
>;

/** Wallet */
export type NotificationEventSetWallet = NotificationBaseEvent<
  { message: string },
  NotificationServerEventType.SetWalletNotification
>;

/** Auction */
export type NotificationEventPutCardAuction = NotificationBaseEvent<
  { tokenId: number; startPrice: number },
  NotificationServerEventType.PutCardAuction
>;

/** Events */
export type NotificationEventEventSoon = NotificationBaseEvent<
  {
    homeTeam: string;
    awayTeam: string;
    sport: ApiCommonSport;
    league: ApiCommonLeague;
  },
  NotificationServerEventType.EventSoon
>;

export type NotificationEventEventLive = NotificationBaseEvent<
  {
    homeTeam: string;
    awayTeam: string;
    sport: ApiCommonSport;
    league: ApiCommonLeague;
  },
  NotificationServerEventType.EventLive
>;

/** Tournaments */
export type NotificationEventTournamentStarted = NotificationBaseEvent<
  { tournamentId: number },
  NotificationServerEventType.TournamentStarted
>;

export type NotificationEventTournamentDelayed = NotificationBaseEvent<
  { tournamentId: number },
  NotificationServerEventType.TournamentDelayed
>;

export type NotificationEventTournamentCanceled = NotificationBaseEvent<
  { tournamentId: number },
  NotificationServerEventType.TournamentCanceled
>;

export type NotificationEventTournamentFinished = NotificationBaseEvent<
  { tournamentId: number; finishedPlace: number; payout: number },
  NotificationServerEventType.TournamentFinished
>;

/** System events */
export type NotificationEventHistoryResponse = NotificationBaseEvent<
  { History: NotificationRawHistoryEvent[] | null },
  NotificationServerEventType.NotificationsHistoryResponse
>;

import { nftStatusBadgeClasses } from '~entities/nft/ui/NftCard/NftCardStates/NftStatusBadge';
import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const MobileContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '32px',

  [`& .${nftStatusBadgeClasses.root}`]: {
    padding: '1px 3px',
    textTransform: 'uppercase',
    fontSize: '6px',
  },
}));

import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, ButtonIcon, Divider, Stack } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { NFT_RARITY_TO_READABLE_RARITY_NAME_MAP } from '~entities/nft';

import {
  formatLocaleDateString,
  getTournamentStatus,
  useTournamentPrizesInfoElements,
} from '../../lib';

import { useTournamentsModel } from '../../model';

import { TournamentCardsPlaceholder } from '../TournamentCardsPlaceholder';
import { TournamentPlayersBadge } from '../TournamentPlayersBadge';
import { TournamentInfoRow } from '../TournamentInfoRow';
import { TournamentBadge } from '../TournamentBadge';

import { TournamentAllPrizePlacesControl } from '../TournamentAllPrizePlacesControl';

import { TournamentPrizeContainer } from '../TournamentPrizeContainer';

import { TournamentPrize } from '../TournamentPrize';

import {
  CardBottomContainer,
  CardContentContainer,
  CardInfoDescriptionElement,
  CardInfoHeading,
  CardJoinButtonContainer,
  CardRarityLabel,
  CardRoot,
  CardTopContainer,
} from './styled';

import { TournamentCardProps } from './types';

export const TournamentCard: FC<TournamentCardProps> = ({ tournament, joinButton }) => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();

  const { openTournamentDialog } = useTournamentsModel();

  const handleOpenTournament = () => {
    openTournamentDialog(tournament.tournamentId);
  };

  const {
    /***/
    prizes,
    joinCardsQuantity,
    endDate,
    startDate,
    tournamentRarity,
    playersLimit,
    players,
    status,
  } = tournament;

  const playersCount = players.length;

  /** Common elements */
  const statusBadgeElement = (
    <TournamentBadge type={getTournamentStatus(startDate, endDate)} size="sm" />
  );

  const datesElement = `${formatLocaleDateString(startDate, isMobile)} — ${formatLocaleDateString(
    endDate,
    isMobile
  )}`;

  const prizesInfoElements = useTournamentPrizesInfoElements(prizes);

  const rarityLabel = <CardRarityLabel rarity={tournamentRarity} />;

  const prizesElement = prizes.map((prize, index) => (
    <TournamentPrize
      key={index}
      place={index + 1}
      currency={prize.currency}
      prize={prize.value}
      items={prize.items}
      status={status}
    />
  ));

  const cardTopInfo = (
    <CardTopContainer>
      <TournamentCardsPlaceholder rarity={tournamentRarity} quantity={1} />

      <Stack flex={1} spacing={4 / 8}>
        <CardInfoHeading>
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              {joinCardsQuantity}x {t(NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[tournamentRarity])}
            </Box>

            <ButtonIcon name="question" variant="plain" size={24} />
          </Box>

          {statusBadgeElement}
        </CardInfoHeading>

        <CardInfoDescriptionElement>{datesElement}</CardInfoDescriptionElement>
      </Stack>
    </CardTopContainer>
  );

  const computedJoinButton = (
    <CardJoinButtonContainer onClick={(event: MouseEvent) => event.stopPropagation()}>
      {joinButton}
    </CardJoinButtonContainer>
  );

  const mobileCardElement = (
    <CardRoot onClick={handleOpenTournament}>
      {rarityLabel}

      <CardContentContainer>
        <Stack spacing={10 / 8}>
          <Stack spacing={16 / 8}>
            {cardTopInfo}

            <TournamentInfoRow elements={prizesInfoElements} />

            <TournamentPlayersBadge playersLimit={playersLimit} playersCount={playersCount} />
          </Stack>

          {computedJoinButton}
        </Stack>

        <Stack spacing={14 / 8} mt={20 / 8}>
          <Divider />

          <TournamentPrizeContainer>{prizesElement}</TournamentPrizeContainer>

          <TournamentAllPrizePlacesControl tournament={tournament} />
        </Stack>
      </CardContentContainer>
    </CardRoot>
  );

  const desktopCardElement = (
    <CardRoot onClick={handleOpenTournament}>
      {rarityLabel}

      <CardContentContainer>
        {cardTopInfo}

        <Stack spacing={14 / 8} mt="10px">
          <TournamentPrizeContainer>{prizesElement}</TournamentPrizeContainer>

          <TournamentAllPrizePlacesControl tournament={tournament} />
        </Stack>

        <CardBottomContainer>{computedJoinButton}</CardBottomContainer>
      </CardContentContainer>
    </CardRoot>
  );

  return isMobile ? mobileCardElement : desktopCardElement;
};

import { DateLabelStateMap } from './types';

const second = 1000;
const minute = 60 * second;
const hour = 60 * minute;
const day = 24 * hour;

export const getDistanceFromNow = (date: Date): number => {
  const now = new Date().getTime();
  const target = new Date(date).getTime();

  return target - now;
};

export const getUpdateTimeout = (date: Date): number => {
  const state = getStateByDate({ date });

  const distanceFromNow = getDistanceFromNow(date);

  switch (state) {
    case DateLabelStateMap.Planned:
      return distanceFromNow - day;
    case DateLabelStateMap.Today:
      return distanceFromNow - 30 * minute;
    case DateLabelStateMap.Upcoming:
      return distanceFromNow - 5 * minute;
    case DateLabelStateMap.Ready:
      return distanceFromNow;
    case DateLabelStateMap.Past:
    default:
      return 0;
  }
};

export const getStateByDate = (props: { date: Date }): Exclude<DateLabelStateMap, 'live'> => {
  const diff = getDistanceFromNow(props.date);

  switch (true) {
    case diff < 0:
      return DateLabelStateMap.Past;
    case diff < 5 * minute:
      return DateLabelStateMap.Ready;
    case diff < 30 * minute:
      return DateLabelStateMap.Upcoming;
    case diff < day:
      return DateLabelStateMap.Today;

    default:
      return DateLabelStateMap.Planned;
  }
};

import { Address, Cell, toNano } from '@ton/core';
import { FocusEvent, KeyboardEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nft, NftRarity } from '~shared/api';
import { useDispatch } from '~shared/lib/hooks';
import { SelectValue } from '~shared/ui';
import { useInventoryModel } from '~widgets/inventory';
import { ApiFreeRepairRequest, freeRepair } from '~shared/api/consumables';

import { nftActions, useNftCardModel, useNftSelector } from '~entities/nft';
import {
  getRecoverCostForNft,
  useCallGasless,
  useTonClient,
  useWriteContract,
} from '~entities/wallet';
import { getRepairedNftStructure } from '~entities/nft';

import { useMergeModel } from '~features/nft';
import { NFT_RARITY_TO_MAX_LIVES_MAP } from '~widgets/auction-list/ui/AuctionsListContainer/AuctionSellCard/constants';

import { FixCardCurrency, FixPrice } from './types';

// const getNotEnoughFundsError = (currency: FixCardCurrency) =>
//   `Looks like you don't have enough ${currency} in your wallet. Try paying with ${
//     currency === FixCardCurrency.TON ? 'MCN' : 'Matic'
//   }.`;

export const useFixCardDialogModel = () => {
  const { t } = useTranslation();

  const { refetch: refetchConsumables } = useInventoryModel();
  const { setIsBlockedForTransaction } = useNftCardModel();

  const [currency, setCurrency] = useState<FixCardCurrency>(FixCardCurrency.TON);
  const client = useTonClient();
  // TODO: check code
  const { returnCardToMergeAfterRepair, targetNftToRepair } = useMergeModel();

  const {
    dialogs: {
      repair: {
        open,
        nfts: [nft],
      },
    },
  } = useNftSelector();

  const dispatch = useDispatch();

  const [fixPrice, setFixPrice] = useState<FixPrice>({
    ton: 0,
    mcn: 0,
  });

  const { write: fixCardWithTon } = useWriteContract({
    contractName: 'CardCollection',
    method: 'Restore',
    transactionName: 'Fixing card',
    successMessage: `${t('Alerts.successfulFix')}`,
    errorMessage: t('Errors.fixFailed'),
  });

  const { write: fixCardWithMcn } = useWriteContract({
    contractName: 'MainToken',
    method: 'JettonTransfer',
    transactionName: 'Fixing card',
    successMessage: `${t('Alerts.successfulFix')}`,
    errorMessage: t('Errors.fixFailed'),
  });

  const handleCurrencyChange = (
    _: MouseEvent | KeyboardEvent | FocusEvent | null,
    value: SelectValue | null
  ) => {
    setCurrency(value as any);
  };

  const handleClose = () => {
    dispatch(
      nftActions.setDialogs({
        action: 'repair',
        dialog: {
          open: false,
          nfts: [],
        },
      })
    );
  };

  // const checkAllowance = async () => {
  //   if (!account) {
  //     return;
  //   }

  //   const provider = getProvider();
  //   const mainTokenContract = MainToken__factory.connect(
  //     process.env.REACT_APP_ADDRESS_SK_TOKEN_MAINTOKEN,
  //     provider
  //   );

  //   const allowanceInWei = await mainTokenContract.allowance(
  //     account,
  //     process.env.REACT_APP_ADDRESS_SK_CARD
  //   );

  //   const allowance = Number(ethers.utils.formatEther(allowanceInWei));

  //   return allowance >= fixPrice.mcn;
  // };

  // const checkIfUserHasEnoughFunds = async () => {
  // if (!account) {
  //   return;
  // }
  // const provider = getProvider();
  // if (currency === FixCardCurrency.TON) {
  //   // const maticBalanceInWei = await provider.getBalance(account);
  //   // const maticBalance = Number(ethers.utils.formatEther(maticBalanceInWei));
  //   return tonBalance >= fixPrice.ton;
  // }
  // if (currency === FixCardCurrency.MCN) {
  //   // const mcnContract = MainToken__factory.connect(
  //   //   process.env.REACT_APP_ADDRESS_SK_TOKEN_MAINTOKEN,
  //   //   provider
  //   // );
  //   // const mcnBalanceInWei = await mcnContract.balanceOf(account);
  //   // const mcnBalance = Number(ethers.utils.formatEther(mcnBalanceInWei));
  //   return mcnBalance >= fixPrice.mcn;
  // }
  // };

  // const gaslessPermitFixCard = useCallGasless<ApiPostPermitRequestData>({
  //   callback: postPermit,
  //   transactionName: 'Approving MCN',
  //   successMessage: `${t('Alerts.approveMCN')}`,
  //   errorMessage: `${t('Errors.approveMCN')}`,
  // });

  const gaslessFixCard = useCallGasless<ApiFreeRepairRequest>({
    callback: freeRepair,
    transactionName: 'Fixing card',
    successMessage: `${t('Alerts.successfulFix')}`,
    errorMessage: t('Errors.fixFailed'),
  });

  // const signPermitFixCardMessage = async (
  //   amount: BigNumber,
  //   spender: string,
  //   sender: string
  // ): Promise<Signature | null> => {
  //   const signer = provider?.getSigner();

  //   if (!signer) {
  //     return null;
  //   }

  //   const types: Array<'uint256' | 'address'> = ['uint256', 'address', 'uint256'];

  //   const mainTokenContract = MainToken__factory.connect(
  //     process.env.REACT_APP_ADDRESS_SK_TOKEN_MAINTOKEN,
  //     signer
  //   );

  //   const permitOpsCounter = await mainTokenContract.permitOpsCounter(sender);

  //   const values: Array<BigNumber | string> = [permitOpsCounter, spender, amount];

  //   const signedMessage = await signGaslessTxMessage({ types, values, signer });

  //   return signedMessage;
  // };

  // const signFixCardMessage = async (
  //   cardId: BigNumber,
  //   address: string
  // ): Promise<Signature | null> => {
  //   const signer = provider?.getSigner();

  //   if (!signer) {
  //     return null;
  //   }

  //   const types: Array<'uint256'> = ['uint256', 'uint256'];

  //   const nftContract = NFT__factory.connect(process.env.REACT_APP_ADDRESS_SK_CARD, signer);

  //   const gasFreeOpCounter = await nftContract.gasFreeOpCounter(address);

  //   const values: Array<BigNumber> = [gasFreeOpCounter, cardId];

  //   const signedMessage = await signGaslessTxMessage({ types, values, signer });

  //   return signedMessage;
  // };

  const handleFixCard = async (
    shouldRecoverWithMCN: boolean,
    shouldRecoverWithConsumable?: boolean
  ) => {
    try {
      // MOCK
      // const areEnoughFunds = await checkIfUserHasEnoughFunds();

      // if (!tonBalance) {
      //   openSnackbar({
      //     type: 'error',
      //     message: "You don't have enough funds to merge these cards.",
      //   });
      // }

      // if (!areEnoughFunds) {
      //   if (currency === FixCardCurrency.TON) {
      //     const remainingTonToMerge = NFT_RARITY_TO_REPAIR_PRICE[nft.rarity] - tonBalance;

      //     openSnackbar({
      //       type: 'error',
      //       message: `You don't have enough funds to merge these cards. Add ${remainingTonToMerge} MATIC to your wallet`,
      //     });
      //   }

      //   if (currency === FixCardCurrency.MCN) {
      //     openSnackbar({
      //       type: 'error',
      //       message: "You don't have enough funds to merge these cards.",
      //     });
      //   }

      //   return;
      // }

      const tokenId = nft.token_id;

      const mcnFixPrice = Number(fixPrice.mcn);
      const tonFixPrice = Number(fixPrice.ton);

      const rarityToRepairConsumablePrice = {
        [NftRarity.Common]: 1,
        [NftRarity.Rare]: 3,
        [NftRarity.Epic]: 5,
        [NftRarity.Legendary]: 7,
      };

      const consumablePrice =
        rarityToRepairConsumablePrice[nft.rarity] *
        (NFT_RARITY_TO_MAX_LIVES_MAP[nft.rarity] - nft.livesRemaining);

      if (shouldRecoverWithConsumable) {
        await gaslessFixCard({ tokenId });

        await refetchConsumables();
        setIsBlockedForTransaction([tokenId]);
      } else if (shouldRecoverWithMCN) {
        await fixCardWithMcn({
          args: {
            $$type: 'JettonTransfer',
            query_id: BigInt(0),
            amount: toNano(mcnFixPrice),
            custom_payload: null,
            destination: Address.parse(process.env.REACT_APP_ADDRESS_TON_CARD_COLLECTION!),
            response_destination: Address.parse(process.env.REACT_APP_ADDRESS_TON_CARD_COLLECTION!),
            forward_ton_amount: toNano('0.2'),
            forward_payload: Cell.EMPTY.asBuilder()
              .storeUint(1, 8)
              .storeUint(BigInt(tokenId), 256)
              .endCell(),
          },
          value: toNano('0.35'),
        });
      } else {
        await fixCardWithTon({
          args: {
            $$type: 'Restore',
            token_id: BigInt(tokenId),
          },
          value: toNano(tonFixPrice + 0.35),
        });
      }
      // const isEnoughAllowance = await checkAllowance();

      // if (!isEnoughAllowance) {
      // const signedMessage = await signPermitFixCardMessage(
      //   ethers.utils.parseEther(String(mcnFixPrice)),
      //   process.env.REACT_APP_ADDRESS_SK_CARD,
      //   account!
      // );

      // if (!signedMessage) {
      //   return;
      // }

      // const { r, s, v } = signedMessage;

      // await gaslessPermitFixCard({
      //   amount: mcnFixPrice,
      //   sender: account!,
      //   spender: process.env.REACT_APP_ADDRESS_SK_CARD,
      //   r,
      //   s,
      //   v,
      // });

      // }

      //   const signedMessage = await signFixCardMessage(BigNumber.from(tokenId), account!);

      //   if (!signedMessage) {
      //     return;
      //   }

      //   const { r, s, v } = signedMessage;

      //   // * Uncomment if you want to call contract directly
      //   // const tx = await NFT__factory.connect(
      //   //   process.env.REACT_APP_ADDRESS_SK_CARD,
      //   //   provider?.getSigner()!
      //   // ).restoreLiveGasFree(BigNumber.from(tokenId), account!, v, r, s);

      //   // await tx.wait();

      //   await gaslessFixCard({
      //     cardId: Number(tokenId),
      //     caller: account!,
      //     r,
      //     s,
      //     v,
      //   });
      // } else {
      //   await restoreLiveMatic({
      //     args: [tokenId],
      //     value: ethers.utils.parseEther(String(fixPrice.matic)),
      //   });
      // }

      dispatch(nftActions.repairNft(nft.token_id));

      if (targetNftToRepair?.token_id === nft.token_id) {
        returnCardToMergeAfterRepair(getRepairedNftStructure(nft));
      }

      handleClose();
      // getBalance();
    } catch (e) {
      console.error(e);
    }
  };

  const getRecoveryCost = useCallback(async () => {
    if (!nft || !client) {
      return;
    }

    const recoverCostForTon = await getRecoverCostForNft(
      client,
      Number(nft.token_id),
      nft.rarity,
      nft.livesRemaining,
      'ton'
    );

    const recoverCostForMcn = await getRecoverCostForNft(
      client,
      Number(nft.token_id),
      nft.rarity,
      nft.livesRemaining,
      'mcn'
    );

    setFixPrice({
      mcn: Number((Number(recoverCostForMcn) / 1e9).toFixed(2)),
      ton: Number((Number(recoverCostForTon) / 1e9).toFixed(2)),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nft]);

  useEffect(() => {
    getRecoveryCost();
  }, [getRecoveryCost]);

  return {
    open,
    nft,
    fixPrice,
    handleFixCard,
    handleCurrencyChange,
    handleClose,
  };
};

export const useFixCardModel = (nft: Nft) => {
  const dispatch = useDispatch();

  const handleOpenFixCardDialog = () => {
    dispatch(
      nftActions.setDialogs({
        action: 'repair',
        dialog: {
          open: true,
          nfts: [nft],
        },
      })
    );
  };

  const handleCloseFixCardDialog = () => {
    dispatch(
      nftActions.setDialogs({
        action: 'repair',
        dialog: {
          open: false,
          nfts: [],
        },
      })
    );
  };

  return {
    handleOpenFixCardDialog,
    handleCloseFixCardDialog,
  };
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { PasswordRecoverySteps, RecoveryState } from './types';

const initialState: RecoveryState = {
  step: PasswordRecoverySteps.ChangePassword,
};

export const recoverySlice = createSlice({
  name: 'recovery',
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<PasswordRecoverySteps>) => ({
      ...state,
      step: action.payload,
    }),

    reset: () => {
      return { ...initialState };
    },
  },
});

export const recoveryActions = recoverySlice.actions;

import { useTonAddress } from '@tonconnect/ui-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiGetAuctionCardMappedData } from '~shared/api';
import { ContentPlaceholder, WalletPlaceholder } from '~shared/ui';

import { useAuctionCardsListEffects, useAuctionModel } from '~features/auction';

import { useAuctionCardDetailsModel } from '~widgets/auction-card-details-dialog';

import { AuctionTab } from '../types';

import { AuctionSellCard } from './AuctionSellCard';
import { AuctionSellCardShimmer } from './AuctionSellCardShimmer';
import { AuctionListRoot } from './styled';
import { AuctionCardsTypeProps } from './types';

export const AuctionsListContainer: FC<AuctionCardsTypeProps> = ({ auctionCards, type }) => {
  const { isLoading, onOpenCardDetailedInfo } = useAuctionModel();
  const account = useTonAddress(true);
  const isConnected = Boolean(account);
  const TYPE_TO_PLACEHOLDER_LABELS_MAP = useTranslationAuction();
  const { onOpenAuctionCardDetails } = useAuctionCardDetailsModel();

  useAuctionCardsListEffects();

  const renderAuctionCard = (card: ApiGetAuctionCardMappedData) => {
    return (
      <AuctionSellCard
        key={`${card.tokenId}_${card.betsAcceptedUntil}`}
        card={card}
        onOpenCardInfo={onOpenCardDetailedInfo}
        onOpenCardDetails={() => {
          onOpenAuctionCardDetails(card);
        }}
      />
    );
  };

  const hasCards = auctionCards.length !== 0;

  switch (true) {
    case isLoading:
      return <AuctionListRoot>{cardsShimmer}</AuctionListRoot>;
    case !isConnected && type !== AuctionTab.All:
      return <WalletPlaceholder />;
    case hasCards:
      return <AuctionListRoot>{auctionCards.map(renderAuctionCard)}</AuctionListRoot>;
    default:
      return <ContentPlaceholder {...TYPE_TO_PLACEHOLDER_LABELS_MAP[type]} />;
  }
};

const cardsShimmer = Array.from(Array(12).keys()).map((key) => (
  <AuctionSellCardShimmer key={key} />
));

export const useTranslationAuction = () => {
  const { t } = useTranslation();

  const TYPE_TO_PLACEHOLDER_LABELS_MAP: Record<AuctionTab, { heading: string; message: string }> = {
    [AuctionTab.All]: {
      heading: `${t('Marketplace.nothingHere')}`,
      message: `${t('Marketplace.textNothing')}`,
    },
    [AuctionTab.MyBids]: {
      heading: `${t('Marketplace.nothingHere')}`,
      message: `${t('Marketplace.youHavent')}`,
    },
    [AuctionTab.MySales]: {
      heading: `${t('Marketplace.nothingHere')}`,
      message: `${t('Other.SalesYet')}`,
    },
  };

  return TYPE_TO_PLACEHOLDER_LABELS_MAP;
};

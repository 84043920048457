import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '~shared/ui';

import { StyledImage } from './styled';

export const ViewerWalletContentPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <Stack flex={1} justifyContent="center">
      <Stack spacing={20 / 8} alignItems="center">
        <StyledImage src="/assets/nfts/no-cards.png" alt="No cards" />
        <Typography textAlign="center" variant="h2">
          {t('Sidebar.noCards')}
        </Typography>
        <Typography textAlign="center" color="secondary">
          {t('Sidebar.youDontHaveCards')}
        </Typography>
      </Stack>
    </Stack>
  );
};

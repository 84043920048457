import { ApiCommonSport } from '~shared/api';
import { IconAssetNamesType } from '~shared/ui';

export const sportToIconMap: Record<ApiCommonSport, IconAssetNamesType> = {
  basketball: 'sport-basketball',
  tennis: 'sport-tennis',
  lol: 'sport-league-of-legends',
  // todo: temporary cs2 = csgo for backend compatibility
  csgo: 'sport-cs2',
  dota2: 'sport-dota',
  mma: 'sport-mma',
  cricket: 'sport-cricket',
  hockey: 'sport-hockey',
  baseball: 'sport-baseball',
  soccer: 'sport-football',
  american_football: 'sport-american-football',
};

import { SxProps } from '@mui/system';
import { FC } from 'react';

import { Box, Icon, Typography } from '~shared/ui';
import { useTheme } from '~shared/lib/styles';

interface Props {
  sx?: SxProps;
}

export const AuctionHotOffer: FC<Props> = ({ sx }) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        ...(sx ? sx : {}),
        background: palette.paper.main,
        padding: '4px 8px',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        borderRadius: '32px',
        paddingRight: '12px',
      }}
    >
      <Icon name="fire" size={18} />
      <Typography sx={{ fontSize: '11px', fontWeight: 500, lineHeight: '12px' }}>
        Hot offer
      </Typography>
    </Box>
  );
};

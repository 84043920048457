import { styled } from '~shared/lib/styles';
import { Box, Typography } from '~shared/ui';

export const NftCardInfoHeaderRoot = styled(Box, { name: 'NftCardInfoHeader', slot: 'root' })(
  ({ theme }) => ({
    backgroundColor: theme.palette.grey.A10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '18px 18px 0 0',
    padding: '14px 16px',
  })
);

export const NftCardInfoHeaderMainInfo = styled(Box, {
  name: 'NftCardInfoHeader',
  slot: 'mainInfo',
})({
  flex: 1,
  display: 'flex',
  gap: 16,
  alignItems: 'center',
});

export const NotCardInfoHeaderMainInfoRarity = styled(Typography, {
  name: 'NftCardInfoHeader',
  slot: 'mainInfoRarity',
})({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: 18 / 16,
  marginBottom: 6,
});

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NftRarity } from '~shared/api';

import { Scale, Stack, Typography } from '~shared/ui';

import { NFT_RARITY_TO_MAX_LIVES_MAP, NFT_RARITY_TO_MAX_WIN_STREAK } from '../../../lib';

import { NftCardInfoContentEntry } from '../NftCardInfoContentEntry';

import { NftCardInfoContentProps } from './types';

export const NftCardInfoContent: FC<NftCardInfoContentProps> = ({ nft, ...stackProps }) => {
  const { t } = useTranslation();

  const maxNftLives = NFT_RARITY_TO_MAX_LIVES_MAP[nft.rarity];
  const maxWinStreak = NFT_RARITY_TO_MAX_WIN_STREAK[nft.rarity];
  const livesPercent = (nft.livesRemaining / maxNftLives) * 100;
  const winStreakPercent = maxWinStreak ? (nft.winStreak / maxWinStreak) * 100 : null;

  const wins =
    nft.rarity !== NftRarity.Mythic
      ? NFT_RARITY_TO_MAX_WIN_STREAK[nft.rarity] >= Number(nft.winStreak)
        ? nft.winStreak
        : NFT_RARITY_TO_MAX_WIN_STREAK[nft.rarity]
      : nft.winStreak;

  return (
    <Stack {...stackProps}>
      <NftCardInfoContentEntry
        label={t('Other.lives')}
        value={
          <Stack direction="row" spacing={12 / 8} alignItems="center">
            <Scale progress={livesPercent} />
            <Typography fontWeight={500}>{`${nft.livesRemaining}/${maxNftLives}`}</Typography>
          </Stack>
        }
      />

      {winStreakPercent !== null && (
        <NftCardInfoContentEntry
          label={t('Marketplace.winStreak')}
          value={
            <Stack direction="row" spacing={12 / 8} alignItems="center">
              <Scale progress={winStreakPercent} />
              <Typography fontWeight={500}>{`${wins}/${maxWinStreak}`}</Typography>
            </Stack>
          }
        />
      )}

      <NftCardInfoContentEntry
        label={t('Marketplace.winReward')}
        value={`${nft.rewardForCorrectVote} MCN`}
      />

      {/* TODO: add unfreeze time info */}
      <NftCardInfoContentEntry
        label={t('Marketplace.freezeTime')}
        value={
          Number.isInteger(nft.freezePeriod)
            ? `${nft.freezePeriod / 3600} ${t('Other.hoursShort')}`
            : 'N/A'
        }
      />
    </Stack>
  );
};

import { clsx } from 'clsx';
import { Fragment, MouseEvent, forwardRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { Avatar, Box, Icon, Typography } from '~shared/ui';
import { useSwitcher } from '~shared/lib/hooks';

import { LeaderboardInfoDrawer } from './LeaderboardInfoDrawer';

import {
  LeaderboardTableRowArrowContainer,
  LeaderboardTableRowAvatarAndNicknameContainer,
  LeaderboardTableRowColumn,
  LeaderboardTableRowColumnInfo,
  LeaderboardTableRowContainer,
  LeaderboardTableRowName,
  leaderboardTableRowClasses,
} from './styled';

import { LeaderboardTableRowProps } from './types';

export const LeaderboardTableRow = forwardRef<HTMLDivElement, LeaderboardTableRowProps>(
  ({ leader, isViewer, isMobile, onProfileDialogOpen }, ref) => {
    const drawerOpen = useSwitcher();

    const {
      /***/
      ref: inViewRef,
      inView,
    } = useInView({
      rootMargin: isMobile ? '-160px' : '-120px',
    });

    const commonContainerProps = {
      className: clsx({
        [leaderboardTableRowClasses.viewer]: isViewer,
        [leaderboardTableRowClasses.sticky]: !inView && isViewer,
      }),
      ref,
    };

    const viewerStickyHandlerElement = isViewer ? (
      <Box mb="-14px !important" ref={inViewRef} />
    ) : null;

    const handleCloseDrawer = (event: MouseEvent) => {
      event.stopPropagation();
      drawerOpen.switchOff();
    };

    if (isMobile) {
      return (
        <Fragment>
          {viewerStickyHandlerElement}

          <LeaderboardTableRowContainer onClick={drawerOpen.switchOn} {...commonContainerProps}>
            <LeaderboardTableRowColumnInfo>
              <Typography variant="h2">{leader.place}</Typography>
              <LeaderboardTableRowAvatarAndNicknameContainer onClick={onProfileDialogOpen}>
                <Avatar src={leader.avatar} size={32} ml={14 / 8} />
                <LeaderboardTableRowName>{leader.nickname}</LeaderboardTableRowName>
              </LeaderboardTableRowAvatarAndNicknameContainer>
            </LeaderboardTableRowColumnInfo>

            <LeaderboardTableRowArrowContainer>
              <Icon name="arrow-right" />
            </LeaderboardTableRowArrowContainer>
          </LeaderboardTableRowContainer>

          <LeaderboardInfoDrawer
            open={drawerOpen.value}
            leaderInfo={leader}
            onClose={handleCloseDrawer}
          />
        </Fragment>
      );
    }

    return (
      <Fragment>
        {viewerStickyHandlerElement}

        <LeaderboardTableRowContainer {...commonContainerProps}>
          <LeaderboardTableRowColumnInfo>
            <Typography variant="h2">{leader.place}</Typography>
            <LeaderboardTableRowAvatarAndNicknameContainer onClick={onProfileDialogOpen}>
              <Avatar size={32} ml={14 / 8} src={leader.avatar} />
              <LeaderboardTableRowName>{leader.nickname}</LeaderboardTableRowName>
            </LeaderboardTableRowAvatarAndNicknameContainer>
          </LeaderboardTableRowColumnInfo>

          {/*<LeaderboardTableRowColumn>{leader.winRate}%</LeaderboardTableRowColumn>*/}
          <LeaderboardTableRowColumn>{leader.votes}</LeaderboardTableRowColumn>
          <LeaderboardTableRowColumn>{leader.coins}</LeaderboardTableRowColumn>
        </LeaderboardTableRowContainer>
      </Fragment>
    );
  }
);

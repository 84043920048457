import { FC } from 'react';

import {
  ListTopShadowHiddenContainer,
  ListTopShadowPositionContainer,
  ListTopShadowRoot,
} from './styled';

import { ListTopShadowProps } from './types';

export const ListTopShadow: FC<ListTopShadowProps> = ({ show, ...htmlAttributes }) => {
  const transition = { type: 'tween' };

  const animate = show ? { y: 0, opacity: 1, transition } : { y: -32, opacity: 0, transition };

  return (
    <ListTopShadowPositionContainer {...htmlAttributes}>
      <ListTopShadowHiddenContainer>
        <ListTopShadowRoot animate={animate} />
      </ListTopShadowHiddenContainer>
    </ListTopShadowPositionContainer>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Stack, Typography } from '~shared/ui';

import { useNftPreviewInfo } from '../../../model';
import { formatRelatedEventDate } from '../../../lib';

import { EventCard, LiveStatusContainer, LiveStatusText } from './styled';

import { NftCardInfoRelatedEventProps } from './types';

export const NftCardInfoRelatedEvent: FC<NftCardInfoRelatedEventProps> = ({ nft }) => {
  const { t } = useTranslation();
  const { openNftRelatedEvent } = useNftPreviewInfo();

  if (!nft.relatedEvent) {
    return null;
  }

  const { homeTeam, awayTeam, date } = nft.relatedEvent;

  return (
    <EventCard onClick={() => openNftRelatedEvent(nft)}>
      {nft.isLive && (
        <LiveStatusContainer>
          <LiveStatusText>{t('State.live').toUpperCase()}</LiveStatusText>
          <Icon name="play" size={18} color={(theme) => theme.palette.text.primary} />
        </LiveStatusContainer>
      )}

      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Stack spacing={4 / 8}>
          <Typography color="secondary">
            <Typography component="span" fontWeight={600}>
              {homeTeam.players.map(({ shortName }) => shortName).join(', ')}
            </Typography>
            &nbsp;vs&nbsp;
            <Typography component="span" fontWeight={600}>
              {awayTeam.players.map(({ shortName }) => shortName).join(', ')}
            </Typography>
          </Typography>
          <Typography color="secondary">{formatRelatedEventDate(new Date(date))}</Typography>
        </Stack>

        <Icon name="chevron-right" color={(theme) => theme.palette.text.secondary} />
      </Stack>
    </EventCard>
  );
};

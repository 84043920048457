import { motion } from 'framer-motion';
import { Popper as MuiBasePopper } from '@mui/base';

import { customScrollbar, styled } from '~shared/lib/styles';

export const NotificationCenterPopperRoot = styled(MuiBasePopper, {
  name: 'NotificationCenterPopper',
})(({ theme }) => ({
  boxShadow: '0px 0px 14px 0px #000000',
  zIndex: theme.zIndex.notification,
  borderRadius: 8,
  overflow: 'hidden',
}));

export const NotificationCenterPopperPaper = styled(motion.div, {
  name: 'NotificationCenterPopper',
  slot: 'paper',
})(({ theme }) => ({
  width: 360,
  height: 600,
  maxHeight: 'calc(100vh - 120px)',
  background: theme.palette.paper.grey,
  overflow: 'hidden',
  overflowY: 'auto',

  display: 'flex',
  flexDirection: 'column',

  ...customScrollbar,
}));

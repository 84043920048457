import { forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Icon } from '~shared/ui';

import { useTournamentsModel } from '~entities/tournament';

import {
  Notification,
  NotificationAction,
  NotificationCaption,
  NotificationIconPlate,
  NotificationMessage,
  NotificationTag,
} from '../../../factory';

import { NotificationTournamentFinishedProps } from './types';

export const NotificationTournamentFinished = forwardRef<
  HTMLDivElement,
  NotificationTournamentFinishedProps
>(({ notification, ...props }, ref) => {
  const { t } = useTranslation();

  const { openTournamentDialogCurry } = useTournamentsModel();

  return (
    <Notification
      {...notification}
      {...props}
      ref={ref}
      icon={
        <NotificationIconPlate backgroundLight>
          <Icon name="battle" />
        </NotificationIconPlate>
      }
    >
      {notification.payload.payout ?? 200 ? (
        <NotificationTag color="success">
          {t('Notifications.tournamentYouWon', {
            payout: notification.payload.payout ?? 200,
            currency: 'MCN',
          })}
        </NotificationTag>
      ) : null}

      <NotificationMessage>{t('Notifications.tournamentFinished')}</NotificationMessage>

      <NotificationCaption>
        <Trans i18nKey="Notifications.tournamentYourPlace" t={t}>
          Your place:{' '}
          <strong>
            {{
              // @ts-ignore
              place: notification.payload.finishedPlace ?? 12,
            }}
          </strong>
        </Trans>
      </NotificationCaption>

      <NotificationAction onClick={openTournamentDialogCurry(notification.payload.tournamentId)}>
        {t('Notifications.openTournament')}
      </NotificationAction>
    </Notification>
  );
});

import { NFT_RARITY_TO_MAX_WIN_STREAK } from '~entities/nft';
import { Nft, NftRarity } from '~shared/api';
import { IconAssetNamesType } from '~shared/ui';
import { InventoryState } from '~widgets/inventory/model/types';

export const checkIsMergeAvailable = (nfts: Array<Nft>) => {
  let nftsWithWinStreak = 0;

  for (const nft of nfts) {
    if (nftsWithWinStreak === 2) {
      break;
    }

    if (nft.rarity !== NftRarity.Mythic) {
      const hasWinStreak = nft.winStreak >= NFT_RARITY_TO_MAX_WIN_STREAK[nft.rarity];

      if (hasWinStreak) {
        nftsWithWinStreak++;
      }
    }
  }

  return nftsWithWinStreak === 2;
};

export const NFT_RARITY_TO_MERGE_ICON_MAP: Record<NftRarity, IconAssetNamesType> = {
  [NftRarity.Common]: 'rare-merge',
  [NftRarity.Rare]: 'epic-merge',
  [NftRarity.Epic]: 'legendary-merge',
  [NftRarity.Legendary]: 'mythic-merge',
  [NftRarity.Mythic]: 'rare-merge',
  [NftRarity.Demo]: 'common-merge',
};

export const NFT_RARITY_TO_MERGE_CONSUMABLE_MAP = (
  rarity: NftRarity,
  consumables: InventoryState
) => {
  switch (rarity) {
    case NftRarity.Common:
      return consumables.rareMerge;
    case NftRarity.Rare:
      return consumables.epicMerge;
    case NftRarity.Epic:
      return consumables.legendaryMerge;
    case NftRarity.Legendary:
      return consumables.mythicMerge;
    case NftRarity.Mythic:
      return consumables.rareMerge;
    case NftRarity.Demo:
      return consumables.rareMerge;
  }
};

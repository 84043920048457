import { FC } from 'react';

import { Icon } from '~shared/ui';

import { NftBlockedRoot, NftBlockedText } from './styled';

import { NftBlockedProps } from './types';

export const NftBlocked: FC<NftBlockedProps> = ({ label, icon = true }) => {
  return (
    <NftBlockedRoot>
      {icon ? <Icon name="lock" size={24} /> : null}

      <NftBlockedText>{label}</NftBlockedText>
    </NftBlockedRoot>
  );
};

import { styled } from '~shared/lib/styles';

export const FilterBlock = styled('div')({
  width: '127px',
});

export const MenuRare = styled('div')({
  position: 'relative',
  cursor: 'pointer',
});

export const NftBlock = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
});

export const ViewerWalletFiltersContainer = styled('div')(({ theme }) => ({
  flexShrink: 0,
  marginTop: 12,

  ['& > *']: {
    margin: 4,
  },

  [theme.breakpoints.down('mobile')]: {
    marginBottom: 16,
  },
}));

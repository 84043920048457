import { useWeb3React } from '@web3-react/core';
import { useTranslation } from 'react-i18next';

import { Avatar, Stack, Typography } from '~shared/ui';
import { sliceHash } from '~shared/lib/utils';

import { useViewerSelector } from '~entities/viewer';
import { useMediaQuery } from '~shared/lib/hooks';

export const AccountBrief = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  // todo: fsd
  const { nickname, avatar, wallet } = useViewerSelector();
  const { account } = useWeb3React();

  const isConnected = !!account;

  return (
    <Stack direction="row" alignItems="center" spacing={16 / 8} overflow="hidden">
      <Avatar src={avatar?.src} />

      <Stack overflow="hidden">
        <Typography
          variant={isMobile ? 'h2' : 'h1'}
          component="div"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {nickname}
        </Typography>

        {isConnected && (
          <Typography component="div" color="grey.A50">
            {t('Other.wallet')}{' '}
            <Typography component="span" fontWeight={600}>
              {sliceHash(wallet, 5, 5)}
            </Typography>
          </Typography>
        )}
        {/* : (
          <Typography color="grey.A30">No wallet connected</Typography>
        )}*/}
      </Stack>
    </Stack>
  );
};

import { Dialog, dialogClasses } from '~shared/ui';
import { styled } from '~shared/lib/styles';

export const StyledDialog = styled(Dialog)({
  [`& .${dialogClasses.content}`]: {
    padding: 0,
    margin: 0,
    overflow: 'hidden',
  },
});

export const AdditionalBlock = styled('div')({
  position: 'absolute',
  bottom: '42%',
  right: 31,
  width: 450,
  height: 450,
  borderRadius: '50%',
  background: 'rgba(0, 62, 165, 0.62)',
  filter: 'blur(60px)',
});

export const AvatarShadow = styled('div')({
  left: 0,
  top: 0,
  position: 'absolute',
  height: 50,
  width: 50,
  zIndex: 1,
  background:
    'radial-gradient(rgba(58, 137, 255, 1), rgba(58, 137, 255, 1), rgba(26, 159, 255, 0.7) )',
  borderRadius: '50%',
  filter: 'blur(8px)',
});

export const ProgressBar = styled('div')({
  flex: 1,
  position: 'relative',
  width: '12px',
  borderRadius: '14px',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.1)',
});

type ProgressBarOverlapProps = {
  $allDays: number;
  $countedDays: number;
};

export const ProgressBarOverlap = styled('div')(
  ({ $allDays, $countedDays }: ProgressBarOverlapProps) => ({
    position: 'absolute',
    width: '12px',
    height: `calc(100% / ${$allDays} * ${$countedDays})`,
    borderTopLeftRadius: '14px',
    borderTopRightRadius: '14px',
    borderBottomLeftRadius: $allDays === $countedDays ? '14px' : '0px',
    borderBottomRightRadius: $allDays === $countedDays ? '14px' : '0px',
    backgroundImage:
      'repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px)',
    backgroundColor: 'rgba(46, 178, 255, 1)',
    zIndex: 2,
  })
);

type AvatarWrapperProps = {
  $allDays: number;
  $countedDays: number;
};

export const AvatarWrapper = styled('div')(({ $allDays, $countedDays }: AvatarWrapperProps) => ({
  position: 'absolute',
  left: 0,
  top: `calc(100% / ${$allDays} * ${
    $countedDays < 3 ? $countedDays + 0.1 : $countedDays >= 10 ? $countedDays - 0.2 : $countedDays
  })`,
  transform: 'translate(-37%, -50%)',
  zIndex: 2,
}));

type LayoutImageProps = {
  $rotation: number;
  $top: number;
  $right: number;
  $width?: number;
  $height?: number;
};

export const LayoutImage = styled('img')(
  ({ $right, $rotation, $top, $height, $width }: LayoutImageProps) => ({
    position: 'absolute',
    top: $top,
    right: $right,
    transform: `rotate(${$rotation}deg)`,
    width: $width,
    height: $height,
  })
);

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ContactUsState } from './type';

const initialState: ContactUsState = {
  isOpen: false,
};

export const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState: initialState,
  reducers: {
    setToggle: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isOpen: action.payload,
    }),

    reset: () => {
      return { ...initialState };
    },
  },
});

export const contactUsActions = contactUsSlice.actions;

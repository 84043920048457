import { cloneElement, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Stack } from '~shared/ui';

import { BattleHorizontalList } from '../BattleHorizontalList';
import { BattleVerticalList } from '../BattleVerticalList';
import { BattleShellFactoryProps } from '../../factory';

import { BattleCombinedListProps } from './types';

export const BattleCombinedList: FC<BattleCombinedListProps> = ({
  children,
  loading,
  featuredCount = FEATURED_COUNT,
}) => {
  const { t } = useTranslation();
  const featuredList = children.slice(0, getDelimiter(children.length, featuredCount));
  const restList = children.slice(getDelimiter(children.length, featuredCount));

  return (
    <Stack spacing={24 / 8}>
      <Container negative disableRightGutters>
        <BattleHorizontalList label={t('Other.featured')} loading={loading}>
          {featuredList.map((element) =>
            cloneElement<BattleShellFactoryProps>(element, { variant: 'square' })
          )}
        </BattleHorizontalList>
      </Container>

      {/* todo: virtual list */}
      <BattleVerticalList loading={loading}>{restList}</BattleVerticalList>
    </Stack>
  );
};

const FEATURED_COUNT = 10;

const getDelimiter = (quantity: number, count: number) => {
  if (quantity < 6) {
    return Math.round(quantity / 2);
  }

  if (quantity < 11) {
    return 5;
  }

  return count;
};

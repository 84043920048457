import { CSSObject } from '@mui/system';

import { customScrollbar, styled } from '~shared/lib/styles';

import {
  Drawer,
  Heading,
  MenuTab,
  MenuTabs,
  Typography,
  drawerClasses,
  headingClasses,
  iconClasses,
  menuTabClasses,
} from '~shared/ui';

export const SportsMenuLevelHeading = styled(Heading)(() => ({
  [`& .${headingClasses.typography}`]: {
    fontSize: 22,
    lineHeight: 24 / 22,
    fontWeight: 600,

    [`& .${iconClasses.root}`]: {
      marginRight: 12,
      verticalAlign: 'bottom',
    },
  },
}));

export const SportsMenuSidebarRoot = styled('div')(
  (): CSSObject => ({
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'stretch',
  })
);

export const SportsMenuSidebarTabs = styled(MenuTabs)(() => ({
  height: '100%',
  zIndex: 2,
}));

export const ContactUsMenuTab = styled(MenuTab)(({ theme }) => ({
  background: '#2F2F2F',
  borderTop: `1px solid ${theme.palette.grey.A10}`,
  boxShadow: '0 0 8px 4px rgba(20, 30, 38, .7)',

  marginTop: 'auto !important',
  position: 'sticky',
  bottom: 0,

  [`&:hover:not(.${menuTabClasses.disabled}), 
    &:not(.${menuTabClasses.disabled}).${menuTabClasses.hovered}`]: {
    background: `${theme.palette.paper.light} !important`,
  },
}));

export const SportsMenuSidebarSubmenuHeading = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 600,

  padding: '18px 0px 18px 16px',
}));

export const SportsMenuSubmenuHeading = styled(Typography)(() => ({
  fontSize: 22,
  lineHeight: 24 / 22,
  fontWeight: 600,
  letterSpacing: -0.5,
}));

export const SportsMenuSubmenuPaper = styled('div')(({ theme }) => ({
  width: 300,
  background: theme.palette.paper.paulo,
  paddingTop: 16,
  paddingBottom: 16,
  height: '100%',
  borderLeft: `1px solid ${theme.palette.grey.A10}`,
  overflowY: 'auto',
  overflowX: 'hidden',

  ...customScrollbar,
}));

export const SportsMenuItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  padding: '8px 16px',

  transition: theme.transitions.create('background'),
  cursor: 'pointer',

  ['&:hover']: {
    background: theme.palette.grey.A10,
  },
}));

export const SportsMenuItemLabel = styled(Typography)(({ theme }) => ({
  paddingLeft: 6,

  fontSize: 14,
  fontWeight: 400,
  lineHeight: 1,

  color: theme.palette.text.secondary,
}));

export const SportsMenuDrawer = styled(Drawer)(() => ({
  [`& .${drawerClasses.paper}`]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

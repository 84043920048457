import { useState } from 'react';

import { useQueryNfts } from '~entities/nft';
import { ViewerWalletNftRarityFilter } from '~widgets/viewer-wallet';

export const useFilterForUserProfile = ({ address }: { address: string }) => {
  const [byRarity, setByRarity] = useState<any[]>(['all']);
  const [rarity, setRarity] = useState(0);
  const { data, isLoading } = useQueryNfts(address);

  const toggleRarityFilter = (rarity: ViewerWalletNftRarityFilter) => {
    if (byRarity.includes(rarity)) {
      const filteredNft = byRarity.filter((value) => {
        return value !== rarity;
      });

      setByRarity(filteredNft);

      filteredNft.length === 0 && setByRarity(['all']);
    } else {
      const filterAll = byRarity.filter((value) => value !== 'all');
      setByRarity([...filterAll, rarity]);
    }
  };

  const filteredNfts =
    data?.nfts &&
    data?.nfts
      ?.sort?.((a, b) => {
        return rarity === 0 ? a.rarity - b.rarity : b.winStreak - a.winStreak;
      })
      .filter((value) => {
        return byRarity[0] === 'all' ? value : byRarity.includes(value.rarity);
      });

  return {
    rarity,
    setRarity,
    filteredNfts,
    setByRarity,
    byRarity,
    toggleRarityFilter,
    isLoading,
    nfts: data?.nfts ?? [],
  };
};

import { NftRarity } from '~shared/api';

export enum ViewerWalletNftRarityFilter {
  All = 'all',
  Common = NftRarity.Common,
  Rare = NftRarity.Rare,
  Epic = NftRarity.Epic,
  Legendary = NftRarity.Legendary,
  Mythic = NftRarity.Mythic,
  Demo = NftRarity.Demo,
}

export enum ViewerWalletTab {
  All = 'all',
  Tournaments = 'tournaments',
}

/* eslint-disable react-extra/no-inline-styles */
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';

import { Box, Button, Stack } from '~shared/ui';

import { MarketplaceItemType } from '~entities/marketplace';

import { MarketplaceCurrencyValue } from '../../lib';
import { MarketplaceCommonFormProps } from '../types';

import {
  useBuyCardForUSD,
  useBuyMysteryBoxForMatic,
  useMarketplaceItemPaymentInfo,
} from '../../model';

// todo: fix
import { MarketplaceFixedLayout } from '../MarketplaceBuyNFTForm/styled';

export const MarketplaceBuyMysteryBoxForm: FC<MarketplaceCommonFormProps> = ({
  rarity,
  price,
  currency,
  fixedLayout = false,
  // onChangeCurrency,
  onOpenDetails,
}) => {
  const { t } = useTranslation();

  const {
    /***/
    // todo: cleanup
    // currencyOptions,
    // currencyChangeAvailable,
    currentCurrencyItemInfo,
  } = useMarketplaceItemPaymentInfo({
    type: MarketplaceItemType.MysteryBox,
    currency,
    rarity,
  });

  const { initialOptions, ...payPalButtonProps } = useBuyCardForUSD({
    id: currentCurrencyItemInfo.id,
  });

  const { handleBuyMysteryBox } = useBuyMysteryBoxForMatic({
    price: currentCurrencyItemInfo.price,
    rarity,
  });

  // todo: cleanup
  // const handleCurrencyChange = (event: any, value: any) => {
  //   if (onChangeCurrency) {
  //     onChangeCurrency(value);
  //   }
  // };

  const renderPaymentButton = () => {
    switch (currency) {
      case MarketplaceCurrencyValue.Matic:
        return (
          <Button onClick={handleBuyMysteryBox} fullWidth size={fixedLayout ? 'md' : 'lg'}>
            <Trans i18nKey="Other.buyFor" t={t}>
              Buy for {{ price: price }} {{ currency: 'TON' }}
            </Trans>
          </Button>
        );
      case MarketplaceCurrencyValue.USD:
        return (
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
              {...payPalButtonProps}
              style={{ color: 'blue', height: 44, label: 'buynow' }}
            />
          </PayPalScriptProvider>
        );
    }
  };

  if (fixedLayout) {
    return (
      <MarketplaceFixedLayout style={{ gridTemplateColumns: '1fr' }}>
        {/* <SelectField
          value={currency}
          onChange={handleCurrencyChange}
          style={{ background: 'rgba(255, 255, 255, 0.05)' }}
          disabled={!currencyChangeAvailable}
        >
          {currencyOptions.map((currency) => (
            <SelectOption key={currency.value} value={currency.value}>
              {currency.label}
            </SelectOption>
          ))}
        </SelectField> */}

        <Box minHeight={44} maxHeight={44} position="relative" zIndex={0}>
          {renderPaymentButton()}
        </Box>

        {onOpenDetails && (
          <Button variant="text" size="sm" onClick={onOpenDetails}>
            {t('Other.details')}
          </Button>
        )}
      </MarketplaceFixedLayout>
    );
  }

  return (
    <Stack spacing={14 / 8}>
      {/* <SelectField
        value={currency}
        onChange={handleCurrencyChange}
        style={{ background: 'rgba(255, 255, 255, 0.05)' }}
        disabled={!currencyChangeAvailable}
      >
        {currencyOptions.map((currency) => (
          <SelectOption key={currency.value} value={currency.value}>
            {currency.label}
          </SelectOption>
        ))}
      </SelectField> */}

      <Box minHeight={44} maxHeight={44} position="relative" zIndex={0} width="100%">
        {renderPaymentButton()}
      </Box>

      {onOpenDetails && (
        <Button variant="text" size="sm" onClick={onOpenDetails}>
          {t('Other.details')}
        </Button>
      )}
    </Stack>
  );
};

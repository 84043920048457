import { styled } from '~shared/lib/styles';

export const MarketplaceItemNftImage = styled('img', {
  name: 'MarketplaceItemImage',
  slot: 'image',
})(({ theme }) => ({
  objectFit: 'contain',
  borderRadius: 'calc(1em * 0.095)',
  border: `1px solid ${theme.palette.grey.A50}`,
  height: '140px',
  fontSize: '140px',
}));

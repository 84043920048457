import { FC, Fragment, useRef } from 'react';
import { clsx } from 'clsx';

import { Icon, Menu } from '~shared/ui';
import { useSwitcher } from '~shared/lib/hooks';

import { PillMenuProps } from './types';
import { UIKitPill, pillMenuClasses } from './styled';

export const PillMenu: FC<PillMenuProps> = ({
  children,
  label,
  menuProps = {},
  className,
  disabled,
  ...props
}) => {
  const anchorEl = useRef<HTMLElement>(null);
  const open = useSwitcher(false);

  return (
    <Fragment>
      <UIKitPill
        className={clsx(
          pillMenuClasses.root,
          {
            [pillMenuClasses.opened]: open.value,
            [pillMenuClasses.hasLabel]: label !== undefined && label !== null && label !== '',
          },
          className
        )}
        append={<Icon name="chevron-down" className={pillMenuClasses.dropdownChevron} />}
        onClick={open.toggle}
        open={open.value}
        {...props}
        ref={anchorEl}
      >
        {label}
      </UIKitPill>

      <Menu
        open={!disabled && open.value}
        anchorEl={anchorEl.current}
        onClose={open.switchOff}
        {...menuProps}
        slotProps={{
          ...menuProps?.slotProps,
          root: { placement: 'bottom-end', ...menuProps?.slotProps?.root },
        }}
      >
        {children}
      </Menu>
    </Fragment>
  );
};

import { useDispatch } from '~shared/lib/hooks';

import { dailyQuestProgressActions } from './slice';

export const useDailyQuestProgressModel = () => {
  const dispatch = useDispatch();

  const closeModal = () => dispatch(dailyQuestProgressActions.setToggle(false));
  const openModal = () => dispatch(dailyQuestProgressActions.setToggle(true));

  return {
    closeModal,
    openModal,
  };
};

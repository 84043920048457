import { useTonWallet } from '@tonconnect/ui-react';
import { Fragment } from 'react';

import { theme } from '~shared/lib/styles';
import { useDebouncedBooleanTransition } from '~shared/lib/hooks';

import { ConnectWalletOptions, ConnectYourWallet } from '~entities/wallet';

import { ViewerWallet } from '~widgets/viewer-wallet';
import { useWebAppExpansion } from '~widgets/viewer-wallet';

import { useViewerWalletSidebarModel } from '../model';

import { ViewerWalletSidebarRoot } from './styled';

export const ViewerWalletSidebar = () => {
  const { isConnected, isWalletBoundToEmail, isModalOverlap } = useViewerWalletSidebarModel();
  const wallet = useTonWallet();

  const debouncedIsModalOverlap = useDebouncedBooleanTransition(isModalOverlap, {
    strategy: 'end',
  });

  useWebAppExpansion();

  return (
    <ViewerWalletSidebarRoot
      style={{ zIndex: debouncedIsModalOverlap ? theme.zIndex.modalOverlap : 'initial' }}
    >
      {wallet ? (
        <ViewerWallet />
      ) : (
        <Fragment>
          {isWalletBoundToEmail ? <ConnectWalletOptions /> : <ConnectYourWallet />}
        </Fragment>
      )}
    </ViewerWalletSidebarRoot>
  );
};

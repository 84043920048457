import { TonClient } from '@ton/ton';
import axios from 'axios';
import { ethers } from 'ethers';

export const isWeb3Injected = (): boolean =>
  typeof window.web3 !== 'undefined' && typeof window.web3.currentProvider !== 'undefined';

export const isEthereumInjected = (): boolean => typeof window.ethereum !== 'undefined';

export const isMetamaskInjected = (): boolean =>
  isEthereumInjected() || (isWeb3Injected() && window.web3.currentProvider.isMetaMask);

export const isCoinbaseInjected = (): boolean =>
  isWeb3Injected() && window.web3.currentProvider.isCoinbaseWallet;

// account is not optional
export const getSigner = (
  provider: ethers.providers.JsonRpcProvider,
  account: string
): ethers.providers.JsonRpcSigner => {
  return provider.getSigner(account).connectUnchecked();
};

// account is optional
export const getProviderOrSigner = (
  provider: ethers.providers.JsonRpcProvider,
  account?: string
): ethers.providers.JsonRpcProvider | ethers.providers.JsonRpcSigner => {
  return account ? getSigner(provider, account) : provider;
};

type GetContractParams = {
  address: string;
  abi: any;
  provider: ethers.providers.JsonRpcProvider;
  account?: string;
};

// account is optional
export const getContract = ({
  address,
  abi,
  provider,
  account,
}: GetContractParams): ethers.Contract => {
  return new ethers.Contract(address, abi, getProviderOrSigner(provider, account) as any);
};

export const checkTonStatus = async (client: TonClient) => {
  try {
    return await client.getMasterchainInfo();
  } catch (error) {
    console.error('Ton is unavailable: ', error);

    return false;
  }
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const MAX_RETRIES = 5;
const RETRY_DELAY = 3000;

export const getSeqno = async (address: string, retryCount: number = 0): Promise<number> => {
  try {
    const apiUrl = 'https://testnet.toncenter.com/api/v2/getWalletInformation?address=' + address;

    const { data }: { data: any } = await axios.get(apiUrl);

    return data.result.seqno;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (retryCount < MAX_RETRIES) {
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));

        return getSeqno(address, retryCount + 1);
      }

      throw new Error(`Failed to get seqno. Status code: ${error.response.status}`);
    } else {
      throw new Error('Failed to get seqno');
    }
  }
};

export const waitForTx = async (address: string, seqnoPrev: number) => {
  const delay = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  while (true) {
    const currentSeqno = await getSeqno(address);

    if (currentSeqno === seqnoPrev || currentSeqno === null) {
      await delay(3000);
    } else {
      return true;
    }
  }
};

import { useDispatch } from '~shared/lib/hooks';

import { Nft } from '~shared/api';

import { useEventModel } from '~entities/event';
import { isNftAvailableForMerge } from '~entities/nft';

import {
  checkWinstreakWarnDialogNotShown,
  useWinstreakWarningDialog,
} from '~features/event-dialog';

import { useViewerWalletSelector } from '../selectors';
import { viewerWalletSlice } from '../slice';

export const useViewerWalletSelectCard = () => {
  const dispatch = useDispatch();

  const { selectedCards } = useViewerWalletSelector();

  const { switchOn } = useWinstreakWarningDialog();
  const { choice } = useEventModel();

  const selectCard = async (selectedCard: Nft) => {
    const { isOnAuction, isFreezed, isBlockedForEvent, isOnEvent, isBlockedForTransaction } =
      selectedCard;

    const isCardNotAvailableForSelection =
      isOnAuction || isFreezed || isBlockedForEvent || isOnEvent || isBlockedForTransaction;

    if (isCardNotAvailableForSelection) {
      return;
    }

    const isWinstreakDialogNotShown = await checkWinstreakWarnDialogNotShown();

    if (
      !isWinstreakDialogNotShown &&
      isNftAvailableForMerge(selectedCard).isAvailable &&
      !selectedCards.has(selectedCard.token_id) &&
      choice
    ) {
      switchOn(selectedCard, choice, () => {
        dispatch(viewerWalletSlice.actions.toggleSelectedCard(selectedCard));
      });

      return;
    }

    dispatch(viewerWalletSlice.actions.toggleSelectedCard(selectedCard));
  };

  return {
    selectCard,
  };
};

import { ToggleButtonPillContainer } from '~shared/ui';
import { styled } from '~shared/lib/styles';

interface ViewerWalletTabsRootProps {
  $marginBottom: boolean;
}

export const ViewerWalletTabsRoot = styled(ToggleButtonPillContainer, {
  name: 'ViewerWalletTabs',
})<ViewerWalletTabsRootProps>(({ $marginBottom }) => ({
  marginBottom: $marginBottom ? 12 : 0,
}));

export const ViewerWalletTabsNewBadge = styled('div')(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: 10,
  fontWeight: 600,
  lineHeight: 14 / 10,
  padding: '0px 4px',
  background: theme.palette.success.main,
  color: theme.palette.text.secondary,
  borderRadius: 8,
  marginLeft: 8,
}));

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dialog, Icon, Stack, Typography } from '~shared/ui';

import { NftCardDrag } from '~entities/nft';
import { useInventorySelector } from '~widgets/inventory';

import { useFixCardDialogModel } from '../../model';

import { FixCardDialogForm } from './FixCardDialogForm';

export const FixCardDialog: FC = () => {
  const { t } = useTranslation();

  const { repair } = useInventorySelector();

  const { open, nft, fixPrice, handleClose, handleCurrencyChange, handleFixCard } =
    useFixCardDialogModel();

  if (!nft) {
    return null;
  }

  if (!nft || !open) {
    return null;
  }

  return (
    <Dialog open={open} width={440} onClose={handleClose}>
      <Stack spacing={24 / 8} alignItems="center">
        {/* TODO: rewrite heading text */}
        <Typography variant="h1">{t('Other.fixHead')}</Typography>
        <Stack gap="10px" width="100%">
          <Button
            flex={1}
            size="sm"
            disabled={fixPrice.mcn === 0}
            onClick={() => handleFixCard(true)}
          >
            <Stack flexDirection="row" alignItems="center" justifyContent="center" gap="8px">
              <Icon name="coin-maincoin" />
              <Typography>{fixPrice.mcn} MCN</Typography>
            </Stack>
          </Button>
          <Button
            flex={1}
            size="sm"
            onClick={() => handleFixCard(false)}
            disabled={fixPrice.ton === 0}
          >
            <Stack flexDirection="row" alignItems="center" justifyContent="center" gap="8px">
              <Icon name="coin-ton" />
              <Typography>{fixPrice.ton} TON</Typography>
            </Stack>
          </Button>
          <Button flex={1} size="sm" variant="outlined" onClick={() => handleFixCard(false, true)}>
            <Stack flexDirection="row" alignItems="center" justifyContent="center" gap="8px">
              <Icon name="repair-icon" />
              <Typography>{repair} Repair items</Typography>
            </Stack>
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, ToggleButton, Typography } from '~shared/ui';

import { useTheme } from '~shared/lib/styles';

import { useQueryViewerNfts } from '~entities/nft';

import { VIEWER_WALLET_CARDS_FILTERS } from '../../lib';
import { ViewerWalletNftRarityFilter, ViewerWalletTab, useViewerWalletModel } from '../../model';

import { ViewerWalletContentPlaceholder } from '../ViewerWalletContentPlaceholder';

import { ViewerWalletRarityFiltersContainer } from './styled';

export const ViewerWalletRarityFilters: FC = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const { rarityFilters, toggleRarityFilter, nfts, walletTab } = useViewerWalletModel();

  const { isFetched } = useQueryViewerNfts();

  const filteredNfts = nfts.filter((nft: any) => !nft.isOnTournament);

  if (isFetched && filteredNfts.length === 0 && walletTab === ViewerWalletTab.All) {
    return <ViewerWalletContentPlaceholder />;
  }

  if (walletTab === ViewerWalletTab.Tournaments) {
    return null;
  }

  return (
    <Fragment>
      <Divider mb={12 / 8} />

      <ViewerWalletRarityFiltersContainer>
        {VIEWER_WALLET_CARDS_FILTERS.map((tab) => {
          const nftsQuantity =
            tab.value === ViewerWalletNftRarityFilter.All
              ? nfts.length
              : nfts.filter((nft) => nft.rarity.valueOf() === tab.value.valueOf()).length;

          const hasNfts = nftsQuantity !== 0;

          return (
            <ToggleButton
              key={tab.value}
              value={tab.value}
              selected={rarityFilters.has(tab.value)}
              disabled={!hasNfts}
              onChange={(event, value: ViewerWalletNftRarityFilter) => toggleRarityFilter(value)}
              variant="pill"
            >
              {t(tab.label)}
              {hasNfts ? (
                <Typography component="span" color={palette.grey.A50}>
                  &nbsp;{nftsQuantity}
                </Typography>
              ) : null}
            </ToggleButton>
          );
        })}
      </ViewerWalletRarityFiltersContainer>
    </Fragment>
  );
};

import { styled } from '~shared/lib/styles';

export const ProgressBarWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 14,
  backgroundImage: 'linear-gradient(to right, rgba(0, 62, 165, 0.62), rgba(22, 35, 57, 1))',
  borderRadius: 12,
  padding: 16,
});

export const ProgressBar = styled('div')({
  flex: 1,
  position: 'relative',
  width: '100%',
  borderRadius: '14px',
  height: '7px',
  background: 'rgba(255, 255, 255, 0.1)',
});

type ProgressBarOverlapProps = {
  $allDays: number;
  $countedDays: number;
};

export const ProgressBarOverlap = styled('div')(
  ({ $allDays, $countedDays }: ProgressBarOverlapProps) => ({
    position: 'absolute',
    height: '100%',
    width: `calc(100% / ${$allDays} * ${$countedDays})`,
    borderTopLeftRadius: '14px',
    borderTopRightRadius: $allDays === $countedDays ? '14px' : '0px',
    borderBottomLeftRadius: '14px',
    borderBottomRightRadius: $allDays === $countedDays ? '14px' : '0px',
    backgroundImage:
      'repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px)',
    backgroundColor: 'rgba(46, 178, 255, 1)',
    zIndex: 2,
  })
);

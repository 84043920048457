import { styled } from '~shared/lib/styles';
import { NftRarity } from '~shared/api';

import { NFT_RARITY_TO_COLOR } from '../../lib';

interface NftRarityBadgeRootProps {
  $rarity: NftRarity;
}

export const NftRarityBadgeRoot = styled('div')<NftRarityBadgeRootProps>(({ $rarity, theme }) => ({
  background: theme.palette.rarity[NFT_RARITY_TO_COLOR[$rarity]],
  border: `1px solid ${theme.palette.grey.A50}`,
  borderRadius: 8,
  padding: '8px 10px',
  textTransform: 'uppercase',

  fontSize: 11,
  fontWeight: 500,
  lineHeight: 14 / 11,
  textAlign: 'center',
}));

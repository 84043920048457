import { differenceInMinutes, isPast } from 'date-fns';

import { ApiGetCallDataParticipant, BattleResult } from '~shared/api';

import { EventStatus } from '../model';

export const isAbleToTakeCards = (date: Date) => {
  const now = new Date();
  const minutesRemainingBeforeMatchStarted = (date.getTime() - now.getTime()) / 1000 / 60;

  return minutesRemainingBeforeMatchStarted > 30;
};

export const isCorrectChoice = (result: BattleResult | null, choice: BattleResult | null) =>
  Number.isInteger(result) && Number.isInteger(choice) ? result === choice : null;

export const isLessOrEqualFiveMinutesLeft = (startDate: Date) => {
  const currentTime = new Date();

  if (isPast(startDate)) {
    return false;
  }

  const minutesLeft = differenceInMinutes(startDate, currentTime);

  return minutesLeft <= 5;
};

export const getEventStatus = (startDate: Date, result: BattleResult | null | undefined) => {
  if (result === null || result === undefined) {
    return EventStatus.Pending;
  }

  switch (true) {
    case isEventNotStarted(startDate):
      return EventStatus.Pending;

    case isEventCurrent(startDate, result):
      return EventStatus.Live;

    case isEventPassed(result):
      return EventStatus.Passed;

    default:
      break;
  }
};

export const isEventPassed = (result: BattleResult) =>
  ![BattleResult.InProgress, BattleResult.Cancelled].includes(result);

export const isEventCurrent = (startDate: Date, result: BattleResult) =>
  result === BattleResult.InProgress && new Date() >= startDate;

export const isEventNotStarted = (startDate: Date) => {
  const currentDate = new Date();
  const isEventNotStarted = currentDate < startDate;

  return isEventNotStarted;
};

interface GetCallParticipantChoice {
  firstParticipant?: ApiGetCallDataParticipant | null;
  secondParticipant?: ApiGetCallDataParticipant | null;
  address: string;
}

export const getCallParticipant = ({
  firstParticipant,
  secondParticipant,
  address,
}: GetCallParticipantChoice) => {
  const participant =
    [firstParticipant, secondParticipant].find((participant) => {
      return participant?.address === address;
    }) ?? null;

  if (!participant) {
    return null;
  }

  return participant;
};

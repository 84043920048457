import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ApiGetTournamentsMappedResponseData, Nft } from '~shared/api';

interface JoinTournamentState {
  dialogOpen: boolean;
  nftsToJoin: Array<Nft>;
  tournament: ApiGetTournamentsMappedResponseData | null;
}

const initialState: JoinTournamentState = {
  dialogOpen: false,
  nftsToJoin: [],
  tournament: null,
};

export const joinTournamentSlice = createSlice({
  name: 'joinTournament',
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<ApiGetTournamentsMappedResponseData | undefined>) => {
      if (action.payload) {
        state.tournament = action.payload;
      }

      state.dialogOpen = true;
    },
    closeDialog: (state) => {
      state.dialogOpen = false;
      state.nftsToJoin = [];
    },
    addNftsToJoin: (state, action: PayloadAction<Array<Nft>>) => {
      state.nftsToJoin = [...state.nftsToJoin, ...action.payload];
    },
    clearAllCards: (state) => {
      state.nftsToJoin = [];
    },
    removeNftToJoin: (state, action: PayloadAction<{ tokenId: string | number }>) => {
      state.nftsToJoin = state.nftsToJoin.filter(
        (nft) => nft.token_id.toString() !== action.payload.tokenId.toString()
      );
    },

    reset: () => {
      return { ...initialState };
    },
  },
});

import { forwardRef } from 'react';
import { clsx } from 'clsx';

import { Icon } from '~shared/ui';

import { ButtonIconProps, ButtonIconVariant } from './types';
import { UIKitButtonIcon, buttonIconClasses } from './styled';

export const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({ size = 20, name, variant = 'contained', className, append, prepend, ...props }, ref) => {
    return (
      <UIKitButtonIcon
        {...props}
        className={clsx(buttonIconClasses.root, resolveVariantClassName(variant), className)}
        ref={ref}
      >
        {prepend}
        <Icon className={buttonIconClasses.icon} name={name} size={size} />
        {append}
      </UIKitButtonIcon>
    );
  }
);

const resolveVariantClassName = (variant: ButtonIconVariant) => {
  switch (variant) {
    case 'square':
      return buttonIconClasses.square;
    case 'contained':
      return buttonIconClasses.contained;
    case 'outlined':
      return buttonIconClasses.outlined;
    case 'plain':
      return buttonIconClasses.plain;
  }
};

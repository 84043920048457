import { useDispatch } from '~shared/lib/hooks';

import { inventoryActions } from './slice';
import { InventoryItem } from './types';
import { useConsumablesQuery } from './hooks';

export const useInventoryModel = () => {
  const dispatch = useDispatch();
  const { refetch } = useConsumablesQuery();

  const updateInventoryItem = ({
    amount,
    item,
    operation,
  }: {
    item: InventoryItem;
    amount: number;
    operation: 'add' | 'subtract';
  }) => {
    dispatch(inventoryActions.updateInventoryItem({ amount, item, operation }));
  };

  return {
    updateInventoryItem,
    refetch,
  };
};

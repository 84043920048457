import { ApiCommonSportEnum } from '~shared/api';

const path = process.env.PUBLIC_URL + '/assets/event/background/';

export const SportToAssetMaps: Record<ApiCommonSportEnum, string> = {
  [ApiCommonSportEnum.Tennis]: path + 'tennis.jpg',
  [ApiCommonSportEnum.Basketball]: path + 'basketball.jpg',
  [ApiCommonSportEnum.Football]: path + 'football.jpg',
  [ApiCommonSportEnum.MMA]: path + 'mma.jpg',
  [ApiCommonSportEnum.Baseball]: path + 'baseball.jpg',

  [ApiCommonSportEnum.CS2]: path + 'esports.jpg',
  [ApiCommonSportEnum.LeagueOfLegends]: path + 'esports.jpg',
  [ApiCommonSportEnum.Dota]: path + 'esports.jpg',

  // common
  [ApiCommonSportEnum.Hockey]: path + 'football.jpg',
  [ApiCommonSportEnum.Cricket]: path + 'football.jpg',
  [ApiCommonSportEnum.AmericanFootball]: path + 'football.jpg',
};

import { ApiGetCallDataParticipant } from '~shared/api';

interface GetViewerCallParticipantParams {
  firstParticipant: ApiGetCallDataParticipant | null | undefined;
  secondParticipant: ApiGetCallDataParticipant | null | undefined;
  viewerWallet: string;
}

export const getViewerCallParticipant = ({
  firstParticipant,
  secondParticipant,
  viewerWallet,
}: GetViewerCallParticipantParams) =>
  [firstParticipant, secondParticipant].find((p) => p?.address === viewerWallet);

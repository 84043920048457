import { styled } from '~shared/lib/styles';

export const TournamentPlayersBadgeRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  background: theme.palette.grey.A5,
  padding: 8,
  borderRadius: 8,
}));

export const TournamentPlayersBadgeLabel = styled('div')(({ theme }) => ({
  color: theme.palette.grey.A50,
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 14 / 12,
}));

interface TournamentPlayersBadgeValue {
  $limitReached: boolean;
}

export const TournamentPlayersBadgeValue = styled('div')<TournamentPlayersBadgeValue>(
  ({ theme, $limitReached }) => ({
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 14 / 14,
    color: $limitReached ? theme.palette.error.main : theme.palette.text.primary,
  })
);

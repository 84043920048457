import { useTranslation } from 'react-i18next';

import { EventStatus, useEventModel } from '~entities/event';
import { useMediaQuery } from '~shared/lib/hooks';

import { Button, Stack, Typography } from '~shared/ui';

import { EventBadge } from '../../../styled';

import { ButtonsContainer, Root } from './styled';

export const EventCallActions = () => {
  const { isMobile } = useMediaQuery();
  const {
    potentialRewardAmount,
    eventStatus,
    isNoCardsSelected,
    isConnected,
    noCallForEvent,

    isViewerCall,
    call: { cards, callAcceptor },
    onVote,
    onClearAll,
  } = useEventModel();

  const { t } = useTranslation();

  const isVoteDisabled = isNoCardsSelected && isConnected;

  const renderReward = () => {
    switch (true) {
      case noCallForEvent || potentialRewardAmount === 0:
        return null;

      case eventStatus === EventStatus.Live && isViewerCall && !callAcceptor:
        // TODO: Display potential reward amount
        return <Typography color="secondary">{t('EventDialog.nobodyAnswerYourCall')}</Typography>;

      case [EventStatus.Pending, EventStatus.Live].includes(eventStatus as EventStatus):
        return (
          <Stack
            direction={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'baseline' : 'center'}
            spacing={(isMobile ? 5 : 10) / 8}
          >
            <Typography color="secondary">
              {isMobile ? t('BattlePopUp.onlyWin') : t('EventDialog.youCanWin')}
            </Typography>
            {isMobile ? (
              <Typography variant="h2" fontSize="16px">
                {potentialRewardAmount} MCN+{t('Other.card')}
              </Typography>
            ) : (
              <Stack direction="row" alignItems="center" spacing={10 / 8}>
                <Typography variant="h2" fontSize="16px">
                  {potentialRewardAmount} MCN
                </Typography>
                <EventBadge>+ {t('EventDialog.opponentCard')}</EventBadge>
              </Stack>
            )}
          </Stack>
        );

      // TODO: Uncomment when we will be able to get rewardAmount for calls (now this field available only for bets)
      // case [EventStatus.Passed].includes(eventStatus as EventStatus):
      //   return (
      //     <Stack direction="row" alignItems="center" spacing={10 / 8}>
      //       <Typography color="secondary">
      //         {correctChoice ? t('EventDialog.youWin') : t('EventDialog.youLose')}
      //       </Typography>
      //       <Typography
      //         variant="h2"
      //         fontSize="16px"
      //         sx={{ color: correctChoice ? theme.palette.success.main : theme.palette.error.main }}
      //       >
      //         {correctChoice ? '+' : '-'}
      //         {potentialRewardAmount} MCN
      //       </Typography>
      //     </Stack>
      //   );

      default:
        return null;
    }
  };

  const renderButtons = () => {
    switch (true) {
      case eventStatus !== EventStatus.Pending || isViewerCall:
        return null;

      default:
        return (
          <ButtonsContainer>
            <Button
              size={isMobile ? 'md' : 'lg'}
              variant="outlined"
              onClick={onClearAll}
              disabled={cards.length === 0}
            >
              {t('BattlePopUp.clear')}
            </Button>

            <Button
              size={isMobile ? 'md' : 'lg'}
              disabled={isVoteDisabled}
              onClick={() => onVote(false)}
            >
              {t('BattlePopUp.vote')}
            </Button>
          </ButtonsContainer>
        );
    }
  };

  return (
    <Root>
      {renderReward() || <span />}

      {renderButtons()}
    </Root>
  );
};

import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '~shared/lib/hooks';
import { Stack } from '~shared/ui';

import { useViewerModel } from '~entities/viewer';

import { TournamentPlayer } from '../TournamentPlayer';
import { useTournamentsModel } from '../../../model';

import { PlayersHeadRow, PlayersHeadRowCell, PlayersHeadRowCellCentered } from './styled';

import { TournamentPlayersProps } from './types';

export const TournamentPlayers: FC<TournamentPlayersProps> = () => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const ref = useRef(null);

  const { wallet } = useViewerModel();

  const { tournament, isTournamentStartedOrEnded } = useTournamentsModel();

  const { players } = tournament!;

  return (
    <Stack spacing={20 / 8}>
      <PlayersHeadRow>
        <PlayersHeadRowCell>{t('Tournaments.tournamentPlayersHeadPlayer')}</PlayersHeadRowCell>

        <PlayersHeadRowCellCentered>
          {t('Tournaments.tournamentPlayersHeadWinRate')}
        </PlayersHeadRowCellCentered>

        <PlayersHeadRowCellCentered>
          {t('Tournaments.tournamentPlayersHeadWinBattles')}
        </PlayersHeadRowCellCentered>

        <PlayersHeadRowCellCentered>MCN</PlayersHeadRowCellCentered>
      </PlayersHeadRow>

      <Stack position="relative" ref={ref}>
        {players.map((player) => (
          <TournamentPlayer
            key={player.place}
            player={player}
            isTournamentStartedOrEnded={isTournamentStartedOrEnded}
            isViewer={player.wallet === wallet}
            isMobile={isMobile}
          />
        ))}
      </Stack>
    </Stack>
  );
};

import { useTranslation } from 'react-i18next';

import { Stack } from '~shared/ui';

import { DashboardTournaments } from '~entities/tournament';

import { useMediaQuery } from '~shared/lib/hooks';
import { DashboardRanking } from '~entities/ranking';
import { DashboardFinanceCard } from '~entities/transaction';

import { DashboardAuction } from '~entities/auction/dashboard-auction';

import { useProfileDashboardModel } from '../model';

import { ProfileDashboardHorizontalList } from './ProfileDashboardHorizontalList';
import { BattlesAndCallsContainer, Content } from './styled';
import { DashboardStats } from './DashboardStats';

export const ProfileDashboard = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const {
    dashboardQuery: { data, isLoading },

    battlesPeriod,
    callsPeriod,

    handleChangeBattlesPeriod,
    handleChangeCallsPeriod,
  } = useProfileDashboardModel();

  return (
    <Stack spacing={24 / 8} pt={isMobile ? 0 : '4px'} pb={isMobile ? '20px' : '24px'}>
      {/* {isConnected ? <DashboardFinances /> : <DashboardNoWalletConnected />} */}

      <Content>
        {/* <ProfileDashboardHorizontalList>
          <DashboardRanking />
          <DashboardFinanceCard type="income" name={`${t('Other.income')}`} />
          <DashboardFinanceCard type="outcome" name={`${t('Other.outcome')}`} />
        </ProfileDashboardHorizontalList> */}

        <Stack spacing={16 / 8}>
          <BattlesAndCallsContainer>
            <DashboardStats
              heading={t('Tabs.myBattles')}
              period={battlesPeriod}
              stats={data?.battles}
              isLoading={isLoading}
              onPeriodChange={handleChangeBattlesPeriod}
            />
            {/* <DashboardStats
              heading={t('Tabs.myCalls')}
              period={callsPeriod}
              stats={data?.calls}
              isLoading={isLoading}
              onPeriodChange={handleChangeCallsPeriod}
            /> */}
          </BattlesAndCallsContainer>

          <DashboardAuction />
          <DashboardTournaments />
        </Stack>
      </Content>
    </Stack>
  );
};

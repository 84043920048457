import { Address } from '@ton/core';
import { useTonAddress } from '@tonconnect/ui-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  NFT_RARITY_TO_MAX_LIVES_MAP,
  NFT_RARITY_TO_MAX_WIN_STREAK,
  NFT_RARITY_TO_READABLE_RARITY_NAME_MAP,
  NftCard,
} from '~entities/nft';
import { useAuctionAcceptBetTimer } from '~features/auction';

import { getAvatarAbsoluteUri } from '~shared/lib/utils';
import { Avatar, Box, Button, Divider, LinearProgress, Typography } from '~shared/ui';

import { AuctionHotOffer } from '~entities/auction/auction-hot-offer';

import { useAuctionCardDetailsModel } from '~widgets/auction-card-details-dialog';

import {
  AuctionBettorBrief,
  AuctionSellCardBets,
  BestAuctionListContainer,
  BestAuctionRightContainer,
  BidContainer,
  CardInfo,
  CardInfoBlock,
  RareInfoCard,
} from './styled';
import { BestAuctionProps } from './types';

export const BestAuctionItem: FC<BestAuctionProps> = ({ card, onOpenCardInfo }) => {
  const { hoursLeft, minutesLeft, progress, accepted } = useAuctionAcceptBetTimer(
    new Date(card.betsAcceptedUntil)
  );

  const { onOpenAuctionCardDetails } = useAuctionCardDetailsModel();

  const { t } = useTranslation();

  const account = useTonAddress(true);
  const bet = card.bestBet;

  const avatars = card.bestBettorAvatar.includes('default_avatars')
    ? getAvatarAbsoluteUri(card.bestBettorAvatar.replace(' ', '%20'))
    : getAvatarAbsoluteUri(card.bestBettorAvatar);

  const onOpenCardDetails = () => {
    onOpenAuctionCardDetails(card);
  };

  return (
    <BestAuctionListContainer>
      <Box sx={{ position: 'relative' }}>
        <AuctionHotOffer sx={{ position: 'absolute', left: 8, top: 6, zIndex: 2 }} />
        <NftCard
          nft={card.tokenId}
          size="lg"
          m={2}
          onClick={onOpenCardInfo(
            card.creatorNickname,
            card.creatorAvatar,
            String(card.tokenId),
            card.creator
          )}
        />
      </Box>
      <BestAuctionRightContainer>
        <RareInfoCard>
          <Typography mt={10 / 8} fontWeight={600} fontSize={16} mr={1}>
            {t(NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[card.rarity])}
          </Typography>

          <Typography color="grey.A50" mt={10 / 8}>
            {accepted ? `${t('Marketplace.timeLeft')}` : null}{' '}
            <Typography fontWeight={500} component="span">
              {accepted ? `${hoursLeft} hr ${minutesLeft} min` : `${t('Marketplace.finished')}`}
            </Typography>
          </Typography>
        </RareInfoCard>
        <LinearProgress progress={progress} mt={12 / 8} height={4} progressColor="primary" />
        <CardInfo>
          <CardInfoBlock>
            <Typography color="grey.A50" fontSize={12}>
              Price
            </Typography>
            <Typography fontSize={15} fontWeight={600}>
              {card.bestBet === 0 ? Number(card.startingPrice) : Number(card.bestBet)}{' '}
              {card.currency}
            </Typography>
          </CardInfoBlock>

          <Divider orientation="vertical" flexItem />

          <CardInfoBlock>
            <Typography color="grey.A50" fontSize={12}>
              Lives
            </Typography>
            <Typography fontSize={15} fontWeight={600}>
              {card.lives}/{NFT_RARITY_TO_MAX_LIVES_MAP[card.rarity]}
            </Typography>
          </CardInfoBlock>

          <Divider orientation="vertical" flexItem />

          <CardInfoBlock>
            <Typography color="grey.A50" fontSize={12}>
              Win streak
            </Typography>
            <Typography fontSize={15} fontWeight={600}>
              {card.winStreak}/{NFT_RARITY_TO_MAX_WIN_STREAK[card.rarity]}
            </Typography>
          </CardInfoBlock>
        </CardInfo>
        <Divider />
        <AuctionSellCardBets>
          {Number(card.bestBet) !== 0 ? (
            <BidContainer>
              <Typography color="secondary" fontSize={12}>
                Best bid
              </Typography>
              <AuctionBettorBrief>
                <Avatar src={avatars} size={20} />
                <Typography variant="h3" ml={0.5}>
                  {bet.toFixed(2)} {card.currency}
                </Typography>
                <Button ml={10 / 8} variant="text" onClick={onOpenCardDetails}>
                  Bid
                </Button>
              </AuctionBettorBrief>
            </BidContainer>
          ) : (
            <BidContainer>
              {Address.normalize(account) !== card.creator ? (
                <>
                  <Typography color="secondary" fontSize={12}>
                    No bids yet
                  </Typography>
                </>
              ) : (
                <Typography color="secondary" marginTop={2} fontSize={12}>
                  No bids yet
                </Typography>
              )}
              <Button ml={10 / 8} variant="text" onClick={onOpenCardDetails}>
                Bid
              </Button>
            </BidContainer>
          )}
        </AuctionSellCardBets>
      </BestAuctionRightContainer>
    </BestAuctionListContainer>
  );
};

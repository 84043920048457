import { ApiBattleBroadcastProvider } from '~shared/api';

import { useEventModel } from '~entities/event';

import { EventDialogStreamSportRadar, EventDialogStreamTwitch } from './providers';

export const EventDialogStream = () => {
  const { broadcastId, broadcastProvider, eventBroadcastAvailable } = useEventModel();

  if (eventBroadcastAvailable) {
    switch (broadcastProvider) {
      case ApiBattleBroadcastProvider.Twitch:
        return <EventDialogStreamTwitch broadcastId={broadcastId!} />;
      case ApiBattleBroadcastProvider.SportRadar:
        return <EventDialogStreamSportRadar broadcastId={broadcastId!} />;
    }
  }

  return null;
};

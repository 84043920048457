import { FC, Fragment } from 'react';

import { MergeCardsDialog, RepairBeforeMergeDialog, SuccessMergeDialog } from './dialogs';

export const MergeCardsDialogs: FC = () => {
  return (
    <Fragment>
      <MergeCardsDialog />
      <RepairBeforeMergeDialog />
      <SuccessMergeDialog />
    </Fragment>
  );
};

import { MouseEvent, useCallback } from 'react';

import { useViewerModel } from '~entities/viewer';
import { ApiGetLeaderboardMappedData } from '~shared/api';
import { useDispatch, useSearchParamsState } from '~shared/lib/hooks';
import { PROFILE_DIALOG_USER_ADDRESS_PARAM } from '~widgets/profile-dialog';

import { userProfileActions } from './slice';

export const useLeaderboardModel = () => {
  const { nickname } = useViewerModel();
  const dispatch = useDispatch();
  const [ProfileWalletAddress, setProfileWalletAddress, omitProfileWalletAddress] =
    useSearchParamsState<string>(PROFILE_DIALOG_USER_ADDRESS_PARAM, '');

  const openProfileDialog = (address: string) => (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dispatch(userProfileActions.open());
    setProfileWalletAddress(address);
  };

  const closeProfileDialog = () => {
    dispatch(userProfileActions.close());
    omitProfileWalletAddress();
  };

  const getIsLeaderViewer = useCallback(
    (leader: ApiGetLeaderboardMappedData) => {
      return leader.nickname === nickname;
    },
    [nickname]
  );

  return {
    getIsLeaderViewer,
    profileDialogOpen: Boolean(ProfileWalletAddress),
    closeProfileDialog,
    openProfileDialog,
  };
};

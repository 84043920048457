import { FC, Fragment, cloneElement, isValidElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '~shared/ui';
import { ApiTournamentStatus } from '~shared/api';

import { TournamentPrizeProps } from './types';

export const TournamentPrize: FC<TournamentPrizeProps> = ({
  prize,
  place,
  items,
  currency,
  status,

  valueElement,
  currencyElement,
  prizeContainerElement,

  ...stackProps
}) => {
  const { t } = useTranslation();

  const isItemTypeReward = items > 0;
  const isRewardEstimated = isItemTypeReward ? false : isUpTo(status);

  const CURRENCY_MAP = {
    MCN: 'MCN',
    TON: 'TON',
  } as const;

  const renderPrize = () => {
    const value = isItemTypeReward ? items : prize;
    const type = isItemTypeReward
      ? t('Tournaments.unfreezeRewardType', { count: items })
      : CURRENCY_MAP[currency];

    return (
      <Fragment>
        {isValidElement(valueElement) ? (
          cloneElement(valueElement, { children: value })
        ) : (
          <Typography fontWeight={700} fontSize={18} lineHeight={1}>
            {value}
          </Typography>
        )}

        {isValidElement(currencyElement) ? (
          cloneElement(currencyElement, { children: type })
        ) : (
          <Typography fontWeight={500} fontSize={12} lineHeight={14 / 12}>
            {type}
          </Typography>
        )}
      </Fragment>
    );
  };

  return (
    <Stack spacing={4 / 8} alignSelf="stretch" {...stackProps}>
      <Typography color="grey.A50" fontWeight={500} fontSize={12} lineHeight={14 / 12} flex={1}>
        {isRewardEstimated
          ? t('Tournaments.numberedPlaceUpTo', { ordinal: true, count: place })
          : t('Tournaments.numberedPlace', { ordinal: true, count: place })}
      </Typography>

      {isValidElement(prizeContainerElement) ? (
        cloneElement(prizeContainerElement, { children: renderPrize() })
      ) : (
        <Stack spacing={4 / 8}>{renderPrize()}</Stack>
      )}
    </Stack>
  );
};

const isUpTo = (status: ApiTournamentStatus) => {
  switch (status) {
    case ApiTournamentStatus.Active:
    case ApiTournamentStatus.Finished:
    case ApiTournamentStatus.FinishCountringResults:
    case ApiTournamentStatus.CountingResults:
      return false;
    case ApiTournamentStatus.Canceled:
    case ApiTournamentStatus.Delayed:
    case ApiTournamentStatus.Planned:
      return true;
  }
};

import { FC, ReactNode } from 'react';
import { clsx } from 'clsx';

import { Icon } from '~shared/ui';

import { NotificationTransactionStatusRoot, notificationTransactionStatusClasses } from './styled';

import { NotificationTransactionStatusProps, NotificationTransactionStatusType } from './types';

export const NotificationTransactionStatus: FC<NotificationTransactionStatusProps> = ({
  type = 'primary',
  className,
  icon,
  children,

  ...htmlAttributes
}) => {
  return (
    <NotificationTransactionStatusRoot
      className={clsx(
        notificationTransactionStatusClasses.root,
        {
          [notificationTransactionStatusClasses.success]: type === 'success',
          [notificationTransactionStatusClasses.primary]: type === 'primary',
          [notificationTransactionStatusClasses.error]: type === 'error',
        },
        className
      )}
      {...htmlAttributes}
    >
      {icon ?? getIconByType(type)}

      {children}
    </NotificationTransactionStatusRoot>
  );
};

export const getIconByType = (type: NotificationTransactionStatusType): ReactNode => {
  switch (type) {
    case 'success':
      return <Icon size={16} name="check-circle" />;
    case 'error':
      return <Icon size={16} name="close" />;
    case 'primary':
    default:
      return null;
  }
};

import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const Root = styled(Box, { name: 'EventTimer', slot: 'root' })(({ theme }) => ({
  borderRadius: 8,

  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 6,

  [theme.breakpoints.down('mobile')]: {
    gridTemplateColumns: '1fr',
  },
}));

export const Text = styled('div')(({ theme }) => ({
  fontSize: 12,
  lineHeight: 14 / 12,
  color: theme.palette.grey.A50,

  ['& span']: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
}));

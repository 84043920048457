import { Address } from '@ton/core';
import { useTonAddress } from '@tonconnect/ui-react';
import { FC, Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import { Avatar, Button, Divider, Stack, Typography } from '~shared/ui';

import { NftCard, NftCardInfoStatusAuction } from '~entities/nft';

import { useAuctionAcceptBetTimer, useAuctionModel, useQueryParams } from '~features/auction';

import { getAvatarAbsoluteUri } from '~shared/lib/utils';

import { AuctionHotOffer } from '~entities/auction/auction-hot-offer';

import { AuctionTab } from '../../types';

import {
  AuctionBettorBrief,
  AuctionSellCardBet,
  AuctionSellCardBets,
  AuctionSellCardBrief,
  AuctionSellCardProperties,
  AuctionSellCardPropertiesLabel,
  AuctionSellCardPropertiesRow,
  AuctionSellCardPropertiesValue,
  AuctionSellCardRoot,
  StyledChip,
  StyledHotOfferContainer,
} from './styled';

import { AuctionSellCardProps } from './types';
import {
  NFT_RARITY_TO_MAX_LIVES_MAP,
  NFT_RARITY_TO_MAX_WIN_STREAK,
  NFT_RARITY_TO_READABLE_RARITY_NAME_MAP,
} from './constants';

// todo: Refactor in same way as MarketplaceItem
export const AuctionSellCard: FC<AuctionSellCardProps> = ({
  card,
  onOpenCardInfo,
  onOpenCardDetails,
}) => {
  const { t } = useTranslation();
  const query = useQueryParams();
  const account = useTonAddress(true);
  const tab = query.get('auctionTab');
  const { accepted } = useAuctionAcceptBetTimer(new Date(card.betsAcceptedUntil));

  const price = card.startingPrice;
  const bet = card.bestBet;
  const currency = card.currency;
  const readableRarity = NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[card.rarity];

  const { isHotOfferCard } = useAuctionModel();

  const isViewerCard = account && Address.normalize(account) === card.creator;

  return (
    <AuctionSellCardRoot>
      {isHotOfferCard(card) && (
        <StyledHotOfferContainer>
          <AuctionHotOffer />
        </StyledHotOfferContainer>
      )}
      {isViewerCard && <StyledChip label="Your card" color="secondary" />}

      <Stack justifyContent="space-between" height="100%">
        <Stack>
          <AuctionSellCardBrief>
            <NftCard nft={card.tokenId} size="lg" mx="auto" />

            <Stack spacing={6 / 8} mt="16px">
              <Typography variant="h2" mt={20 / 8}>
                {readableRarity}
              </Typography>
              <Button
                variant="text"
                onClick={onOpenCardInfo(
                  card.creatorNickname,
                  card.creatorAvatar,
                  String(card.tokenId),
                  card.creator
                )}
              >
                {t('Marketplace.seeCardInfo')}
              </Button>
            </Stack>

            <NftCardInfoStatusAuction
              message={t('Events.auctionTimeLeft')}
              betsAcceptedUntil={new Date(card.betsAcceptedUntil)}
              mt={6 / 8}
            />
          </AuctionSellCardBrief>

          <Stack direction="row" justifyContent="space-between" alignItems="center" my="16px">
            <Typography sx={{ fontSize: '13px' }} color="secondary">
              Owner
            </Typography>
            <Stack direction="row" alignItems="center" spacing={10 / 8}>
              <Typography sx={{ fontSize: '13px', fontWeight: 500 }} color="secondary">
                {card.creatorNickname}
              </Typography>
              <Avatar src={getAvatarAbsoluteUri(card.creatorAvatar)} size={18} />
            </Stack>
          </Stack>

          <Divider />
          <AuctionSellCardProperties>
            <AuctionSellCardPropertiesRow>
              <AuctionSellCardPropertiesLabel>
                {' '}
                {t('Marketplace.startPrice')}
              </AuctionSellCardPropertiesLabel>
              <AuctionSellCardPropertiesValue>
                {price} {card.currency}
              </AuctionSellCardPropertiesValue>
            </AuctionSellCardPropertiesRow>

            <AuctionSellCardPropertiesRow>
              <AuctionSellCardPropertiesLabel>
                {t('Marketplace.lives')}
              </AuctionSellCardPropertiesLabel>
              <AuctionSellCardPropertiesValue>
                {card.lives}/{NFT_RARITY_TO_MAX_LIVES_MAP[Number(card.rarity)]}
              </AuctionSellCardPropertiesValue>
            </AuctionSellCardPropertiesRow>

            <AuctionSellCardPropertiesRow>
              <AuctionSellCardPropertiesLabel>
                {t('Marketplace.winStreak')}
              </AuctionSellCardPropertiesLabel>
              <AuctionSellCardPropertiesValue>
                {card.winStreak}/{NFT_RARITY_TO_MAX_WIN_STREAK[Number(card.rarity)]}
              </AuctionSellCardPropertiesValue>
            </AuctionSellCardPropertiesRow>
          </AuctionSellCardProperties>
          <Divider />

          <AuctionSellCardBets>
            {Number(card.bestBet) !== 0 ? (
              <AuctionSellCardBet>
                <AuctionBettorBrief>
                  <Avatar src={getAvatarAbsoluteUri(card.bestBettorAvatar)} size={37} />
                  <Typography color="secondary" marginLeft={1}>
                    {card.bestBettorNickname}
                  </Typography>
                </AuctionBettorBrief>

                <Typography variant="h3">
                  {bet} {currency}
                </Typography>
              </AuctionSellCardBet>
            ) : (
              <Fragment>
                {account && Address.normalize(account) !== card.creator ? (
                  <>
                    <Typography color="secondary">{t('Other.noBets')}</Typography>
                  </>
                ) : (
                  <Typography color="secondary">{t('Marketplace.Nobets')}</Typography>
                )}
              </Fragment>
            )}
          </AuctionSellCardBets>
        </Stack>
        {(tab === AuctionTab.All || tab === null || (tab === AuctionTab.MyBids && accepted)) &&
          account &&
          Address.normalize(account) !== card.creator && (
            <Button fullWidth onClick={() => onOpenCardDetails(card)}>
              {t('Marketplace.placeABit')}
            </Button>
          )}
        {(tab === AuctionTab.MySales || !accepted || isViewerCard) && (
          <Button fullWidth variant="outlined" onClick={() => onOpenCardDetails(card)}>
            {t('Auction.details')}
          </Button>
        )}
      </Stack>
    </AuctionSellCardRoot>
  );
};

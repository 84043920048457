import { Container } from '~shared/ui';

import { ProfileDashboard } from '~widgets/profile-dashboard';

export const Dashboard = () => {
  return (
    <Container flex={1}>
      <ProfileDashboard />
    </Container>
  );
};

import { styled } from '~shared/lib/styles';
import { Typography } from '~shared/ui';

export const Root = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(1px, 1fr))',

  [theme.breakpoints.down('mobile')]: {
    paddingTop: 14,
  },
}));

export const TeamCaption = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: 14 / 12,

  color: theme.palette.text.secondary,
}));

import { Swiper, SwiperSlide } from 'swiper/react';

import { styled } from '~shared/lib/styles';

export const HorizontalListRoot = styled('div', { name: 'HorizontalList' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'stretch',
  overflow: 'hidden',
  marginTop: '30px',

  marginRight: -30,
  marginLeft: -30,
  width: 'calc(100% + 30px + 30px)',

  [theme.breakpoints.down('mobile')]: {
    marginRight: -16,
    marginLeft: -16,
    width: 'calc(100% + 16px + 16px)',
  },

  ['& .swiper-wrapper']: {},
}));

export const HorizontalListSwiper = styled(Swiper)(({ theme }) => ({
  paddingRight: 30,
  paddingLeft: 30,

  [theme.breakpoints.down('mobile')]: {
    paddingRight: 16,
    paddingLeft: 16,
  },
}));

export const HorizontalListSlide = styled(SwiperSlide)(() => ({
  ['&.swiper-slide']: {
    width: 'max-content',
  },
}));

HorizontalListSlide.displayName = 'SwiperSlide';

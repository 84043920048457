import { enUS, es, pt, ru } from 'date-fns/locale';

export enum Language {
  EN = 'en',
  ES = 'es',
  PT = 'pt',
  RU = 'ru',
}

export const LanguageToDateLocaleMap: Record<Language, Locale> = {
  [Language.EN]: enUS,
  [Language.ES]: es,
  [Language.PT]: pt,
  [Language.RU]: ru,
};

import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, SelectOption, Stack } from '~shared/ui';

import { SellCardCurrency, SellCardFormProps } from './types';
import { StyledBaseTextField, StyledSelectField } from './styled';

export const SellCardForm: FC<SellCardFormProps> = ({ form, onSubmit, onSelectSellCoin }) => {
  const { t } = useTranslation();

  return (
    <FormProvider {...form}>
      <Stack
        onSubmit={onSubmit}
        component="form"
        direction="row"
        spacing={18 / 8}
        alignItems="start"
      >
        <Stack direction="row" alignItems="stretch">
          <StyledBaseTextField name="price" autoFocus type="number" placeholder="0" />
          <StyledSelectField
            placeholder="0"
            defaultValue={SellCardCurrency.MCN}
            onChange={onSelectSellCoin}
          >
            <SelectOption value={SellCardCurrency.TON}>TON</SelectOption>
            <SelectOption value={SellCardCurrency.MCN}>MCN</SelectOption>
          </StyledSelectField>
        </Stack>
        <Button type="submit">{t('Other.sellBtn')}</Button>
      </Stack>
    </FormProvider>
  );
};

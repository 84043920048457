import { useCallback } from 'react';

import { useNavigate } from 'react-router';

import { Nft, NftRelatedBattle, NftRelatedCall } from '~shared/api';
import { useDispatch } from '~shared/lib/hooks';

import { useViewerModel } from '~entities/viewer';
import { NftSelectionModeType, SetNftHiddenPayload, useNftSelector } from '~entities/nft';

import { routes } from '~shared/config';

import { nftActions } from './slice';

export const useNftCardModel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    /***/
    isSelectionMode,
    selectionModeType,
    isDropZoneOpen,
    nfts,
    nftsLoading,
    nftsToMerge,
    previewInfo,

    dialogs: {
      merge: { open: mergeDialogOpen },
    },
  } = useNftSelector();

  const { avatar, nickname, tonAddress: wallet } = useViewerModel();

  const onOpenCardInfo = useCallback(
    (nft: Nft) => {
      dispatch(
        nftActions.setDetailedInfo({
          open: true,
          tokenId: nft.token_id,
          avatarUrl: avatar?.src,
          nickname,
          walletAddress: wallet,
        })
      );
    },
    [avatar?.src, dispatch, nickname, wallet]
  );

  const setSelectionMode = useCallback(
    (enabled: boolean) => {
      dispatch(nftActions.setSelectionMode(enabled));

      if (!enabled) {
        dispatch(nftActions.setSelectionModeType('bet'));
      }
    },
    [dispatch]
  );

  const setSelectionModeType = useCallback(
    (type: NftSelectionModeType) => {
      dispatch(nftActions.setSelectionModeType(type));
    },
    [dispatch]
  );

  const onRemoveAllCardsFromEvent = (eventId: string) => {
    dispatch(nftActions.removeAllCardsFromEvent(eventId));
  };

  const onMakeBet = (data: { event: NftRelatedBattle | NftRelatedCall; cardIds: string[] }) => {
    dispatch(nftActions.makeBet(data));
  };

  const onUnfreeze = (tokenId: string) => {
    dispatch(nftActions.unfreeze(tokenId));
  };

  const onSetNftHidden = (data: SetNftHiddenPayload) => {
    dispatch(nftActions.setNftHidden(data));
  };

  const onClearEventCards = (cards: Nft[]) => {
    dispatch(nftActions.clearEventCards(cards));
  };

  const onAddCardsToMerge = useCallback(() => {
    setSelectionModeType('merge');
    setSelectionMode(true);
    navigate(routes.wallet);
  }, [navigate, setSelectionMode, setSelectionModeType]);

  const onAddCardsToJoinTournament = useCallback(() => {
    setSelectionModeType('tournament');
    setSelectionMode(true);
    navigate(routes.wallet);
  }, [navigate, setSelectionMode, setSelectionModeType]);

  const onCancelAddCardsToMerge = useCallback(() => {
    setSelectionModeType('bet');
    setSelectionMode(false);
  }, [setSelectionMode, setSelectionModeType]);

  const onCancelAddCardsToJoinTournament = useCallback(() => {
    setSelectionModeType('bet');
    setSelectionMode(false);
  }, [setSelectionMode, setSelectionModeType]);

  const setMergeDialogOpen = useCallback(
    (open: boolean) => {
      dispatch(nftActions.setMergeDialogOpen(open));
    },
    [dispatch]
  );

  const setDropZoneOpen = useCallback(
    (open: boolean) => {
      dispatch(nftActions.setDropZone(open));
    },
    [dispatch]
  );

  const setIsBlockedForTransaction = useCallback(
    (tokenId: string[]) => {
      dispatch(nftActions.setIsBlockedForTransaction(tokenId));
    },
    [dispatch]
  );

  return {
    nfts,
    nftsLoading,
    nftsToMerge,
    previewInfo,

    isDropZoneOpen,
    setDropZoneOpen,

    setSelectionMode,
    setSelectionModeType,
    isSelectionMode,
    selectionModeType,

    onOpenCardInfo,
    onAddCardsToMerge,
    onAddCardsToJoinTournament,
    onCancelAddCardsToMerge,
    onRemoveAllCardsFromEvent,
    onMakeBet,
    onClearEventCards,
    onSetNftHidden,
    onCancelAddCardsToJoinTournament,
    onUnfreeze,
    setIsBlockedForTransaction,

    mergeDialogOpen,
    setMergeDialogOpen,
  };
};

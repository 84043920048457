import { FC, ReactNode } from 'react';

import { ButtonIcon, DateLabel, Icon, Stack } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { getLeagueLogo } from '~entities/battle';
import { useQueryViewerNfts } from '~entities/nft';
import { EventDialogTab, useEventModel } from '~entities/event';

import { EventContentLayout } from '../styled';

import { EventDialogChoiceResult } from './EventDialogChoiceResult';
import { EventDialogStatistics } from './EventDialogStatistics';
import { EventDialogTeams } from './EventDialogTeams';
import { EventDialogTabs } from './EventDialogTabs';

import {
  EventContentInnerContainer,
  EventContentMobileInnerContainer,
  EventMobileDateLabel,
  EventTabsContainer,
  EventTabsMobileContainer,
} from './styled';

import { EventDialogContentProps } from './types';
import { EventDialogBattle } from './EventDialogBattle';

import { EventCardsWalletInitialization } from './EventDialogCards/EventDialogWalletInitialization';
import { EventDialogCall } from './EventDialogCall';
import { EventUserCaptions } from './EventDialogCards/EventUserCaptions';
import { EventDialogStream } from './EventDialogStream';
import { EventDialogCoefficients } from './EventDialogCoefficients/EventDialogCoefficients';

export const EventDialogContent: FC<EventDialogContentProps> = () => {
  const { isMobile } = useMediaQuery();

  const { isLoading: isNftsLoading } = useQueryViewerNfts();

  const {
    event,
    tab,

    onClose,
  } = useEventModel();

  if (!event) {
    return null;
  }

  const tabs: Record<EventDialogTab, ReactNode> = {
    [EventDialogTab.Battle]: <EventDialogBattle />,
    [EventDialogTab.Call]: <EventDialogCall />,
    [EventDialogTab.Statistics]: <EventDialogStatistics />,
    [EventDialogTab.Stream]: <EventDialogStream />,
  };

  const logo = getLeagueLogo(event.sport, event.league);

  if (isMobile) {
    return (
      <EventContentMobileInnerContainer>
        <EventTabsMobileContainer spacing={6 / 8} direction="row">
          <ButtonIcon size={24} name="arrow-left" variant="plain" onClick={onClose} />
        </EventTabsMobileContainer>

        <Stack alignItems="center" direction="row" paddingY={10 / 8}>
          <Stack direction="row" alignItems="center" spacing={10 / 8}>
            <EventMobileDateLabel date={event.date!} defaultState="planned" size="md" />

            <EventDialogChoiceResult />
          </Stack>

          {logo && <Icon name={logo} size={48} marginLeft="auto" />}
        </Stack>

        <EventDialogTeams />

        <EventTabsContainer alignItems="center">
          <EventDialogTabs />
        </EventTabsContainer>

        <EventUserCaptions />

        <EventDialogCoefficients />

        <EventContentLayout>
          {isNftsLoading ? <EventCardsWalletInitialization /> : tabs[tab]}
        </EventContentLayout>
      </EventContentMobileInnerContainer>
    );
  }

  return (
    <EventContentInnerContainer spacing={20 / 8}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" alignSelf="stretch">
        <Stack direction="row" alignItems="center" spacing={8 / 8}>
          <DateLabel date={event.date!} defaultState="planned" size="md" />

          {logo && <Icon name={logo} size={48} />}
        </Stack>

        <EventDialogChoiceResult />
      </Stack>

      <EventDialogTeams />

      <EventTabsContainer alignItems="center">
        <EventDialogTabs />
      </EventTabsContainer>

      <EventUserCaptions />

      <EventContentLayout>
        {isNftsLoading ? <EventCardsWalletInitialization /> : tabs[tab]}
      </EventContentLayout>
    </EventContentInnerContainer>
  );
};

import { FC, useMemo } from 'react';

import { DateLabel } from '~shared/ui';
import { BattleChoiceId, BattleResult, BattleResultChoice, BetSlot } from '~shared/api';

import { NftCard, NftCardsStack } from '~entities/nft';
import { EventDialogTab, EventState, useEventModel } from '~entities/event';
import { useViewerModel } from '~entities/viewer';

import { getLeagueLogo, isDrawSlotAvailable, useBattleDragHighlight } from '../../../lib';
import { BattleShellFactory, BattleSlot, BattleTeam, BattleVersus } from '../../factory';
import { useBattleModel, useDropBattle } from '../../../model';

import { BattleProps } from './types';
import { getViewerCallParticipant } from '~entities/calls';

export const Battle: FC<BattleProps> = ({ variant = 'line', size, ...event }) => {
  const { date, homeTeam, awayTeam, id, sport, relatedNft, matchDifficulty } = event;

  const { isOverlapBackdrop } = useBattleModel();
  const highlightProps = useBattleDragHighlight(!isOverlapBackdrop);

  const { dragRef } = useDropBattle({
    enabled: !!relatedNft,
    choice: relatedNft?.relatedBet?.choice as BetSlot,
    cards: relatedNft?.relatedBet?.cards ?? [],
    event,
  });

  const { openEvent, getCallDataToOpenEvent } = useEventModel();
  const { avatar, wallet } = useViewerModel();

  const viewerCallParticipant = useMemo(
    () =>
      getViewerCallParticipant({
        firstParticipant: relatedNft?.relatedCall?.firstParticipant,
        secondParticipant: relatedNft?.relatedCall?.secondParticipant,
        viewerWallet: wallet,
      }),
    [relatedNft?.relatedCall?.firstParticipant, relatedNft?.relatedCall?.secondParticipant, wallet]
  );

  const handleOpenEvent = () => {
    const call = relatedNft?.relatedCall
      ? getCallDataToOpenEvent({ call: relatedNft?.relatedCall })
      : undefined;
    const battle = relatedNft?.relatedBet
      ? {
          cards: relatedNft.relatedBet?.cards,
          additionalCards: [],
          choice: relatedNft?.relatedBet?.choice,
          isViewMode: true,
        }
      : undefined;

    // @ts-ignore
    const additionalParams: Partial<Omit<EventState, 'event'>> = !!relatedNft
      ? {
          tab: !battle && !!call ? EventDialogTab.Call : EventDialogTab.Battle,
          call,
          battle,
        }
      : { isViewMode: false };

    openEvent(event, additionalParams);
  };

  const renderStack = () => {
    if (relatedNft?.relatedBet?.cards) {
      return (
        <NftCardsStack>
          {relatedNft?.relatedBet?.cards.map((card) => (
            <NftCard key={card.token_id!} nft={card.token_id!} size="xs" />
          ))}
        </NftCardsStack>
      );
    } else if (viewerCallParticipant) {
      return (
        <NftCardsStack>
          {[
            <NftCard key={viewerCallParticipant.card} nft={viewerCallParticipant.card} size="xs" />,
          ]}
        </NftCardsStack>
      );
    }
  };

  const renderSlotByChoiceId = (slot: BattleResultChoice) => {
    if (!relatedNft) {
      return <BattleSlot event={event} slot={slot} show={highlightProps.highlight} />;
    }

    if (slot === relatedNft?.relatedBet?.choice || slot === viewerCallParticipant?.choiceId) {
      return renderStack();
    }
  };

  const getSlotUserAvatar = (slot: BattleResultChoice) => {
    if (
      relatedNft &&
      (slot === relatedNft?.relatedBet?.choice || slot === viewerCallParticipant?.choiceId)
    ) {
      return avatar?.src;
    }
  };

  const renderVersusSlot = () => {
    if (relatedNft) {
      if (
        BattleChoiceId.Draw.valueOf() === relatedNft?.relatedBet?.choice.valueOf() ||
        BattleChoiceId.Draw.valueOf() === viewerCallParticipant?.choiceId
      ) {
        return renderStack();
      }
    } else {
      if (isDrawSlotAvailable(sport)) {
        if (highlightProps.highlight) {
          return (
            <BattleSlot event={event} slot={BattleResult.Draw} show={highlightProps.highlight} />
          );
        }
      }

      return null;
    }
  };

  return (
    <BattleShellFactory
      {...highlightProps}
      overlapBackdropAvailable
      ref={dragRef}
      onClick={handleOpenEvent}
      variant={variant}
      size={size}
      eventId={id}
      homeTeam={
        <BattleTeam
          key="home"
          players={homeTeam.players.map((player) => ({
            name: player.name,
            shortName: player.shortName,
            avatar: player.logo,
          }))}
          coef={matchDifficulty.home}
          slot={renderSlotByChoiceId(BattleResult.HomeTeamWon)}
          slotUserAvatar={getSlotUserAvatar(BattleResult.HomeTeamWon)}
        />
      }
      awayTeam={
        <BattleTeam
          key="away"
          players={awayTeam.players.map((player) => ({
            name: player.name,
            shortName: player.shortName,
            avatar: player.logo,
          }))}
          coef={matchDifficulty.away}
          slot={renderSlotByChoiceId(BattleResult.AwayTeamWon)}
          slotUserAvatar={getSlotUserAvatar(BattleResult.AwayTeamWon)}
        />
      }
      versus={
        <BattleVersus
          slot={renderVersusSlot()}
          slotUserAvatar={getSlotUserAvatar(BattleResult.Draw)}
        />
      }
      date={<DateLabel date={date} />}
      leagueLogo={getLeagueLogo(sport, event.league)}
      status="future"
      sport={sport}
    />
  );
};

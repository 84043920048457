import { getBroadcastProvider, mapBattleTeam, mapMatchDifficulty } from '../battle';

import { ApiGetBetsBetMappedData, ApiGetBetsBetRawData } from './types';

export const mapGetBets = (
  data: Array<Array<ApiGetBetsBetRawData>>
): Array<ApiGetBetsBetMappedData> => {
  return (
    data
      .flat()
      // TODO: remove this after DB bets fix of backend
      .filter((bet) => bet.matchDifficulty)
      .map(
        ({
          date,
          tokenIds,
          eventId,
          //@ts-ignore
          id,
          homeTeam,
          awayTeam,
          sport,
          broadcast,
          matchDifficulty,
          ...bet
        }) => {
          return {
            ...bet,
            tokenIds: tokenIds,
            date: new Date(date),
            homeTeam: mapBattleTeam(homeTeam, sport),
            awayTeam: mapBattleTeam(awayTeam, sport),
            sport: sport,
            eventId: id,
            id: eventId,
            matchDifficulty: mapMatchDifficulty(matchDifficulty),
            broadcastId: broadcast,
            broadcastProvider: getBroadcastProvider(sport),
          };
        }
      )
      .sort((a, b) => getTimeStamp(b.date) - getTimeStamp(a.date))
  );
};

const getTimeStamp = (date: Date) => date.getTime();

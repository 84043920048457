import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Nft } from '~shared/api';
import { MergeState } from '~features/nft';

const initialState: MergeState = {
  repairBeforeMergeDialog: {
    open: false,
    nftsToRepair: [],
  },
  successMergeDialog: {
    open: false,
    recentlyCreatedNft: null,
  },
};

export const mergeSlice = createSlice({
  name: 'merge',
  initialState,
  reducers: {
    setNftToRepair: (state, action: PayloadAction<Nft>) => {
      state.repairBeforeMergeDialog.open = true;

      state.repairBeforeMergeDialog.nftsToRepair = [
        ...state.repairBeforeMergeDialog.nftsToRepair,
        action.payload,
      ];
    },
    openNftToRepairDialog: (state) => {
      state.repairBeforeMergeDialog.open = true;
    },
    completeNftToRepair: (state, action: PayloadAction<Nft>) => {
      state.repairBeforeMergeDialog.nftsToRepair =
        state.repairBeforeMergeDialog.nftsToRepair.filter(
          (nft) => nft.token_id !== action.payload.token_id
        );

      state.repairBeforeMergeDialog.open = state.repairBeforeMergeDialog.nftsToRepair.length > 0;
    },

    setSuccessMergeDialog: (state, action: PayloadAction<{ open: boolean; nft?: Nft | null }>) => {
      state.successMergeDialog.open = action.payload.open;
      state.successMergeDialog.recentlyCreatedNft = action.payload.nft ?? null;
    },

    reset: () => {
      return { ...initialState };
    },
  },
});

export const mergeActions = mergeSlice.actions;

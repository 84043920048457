import { UseQueryOptions, useQuery } from 'react-query';

import { ApiGetTournamentMappedResponseData, getTournament } from '~shared/api';

export const useQueryTournament = (
  id: number | string,
  options?: Omit<
    UseQueryOptions<
      ApiGetTournamentMappedResponseData,
      unknown,
      ApiGetTournamentMappedResponseData,
      Array<string | number>
    >,
    'queryFn'
  >
) => {
  const { isLoading, data } = useQuery({
    queryKey: ['tournament', id],
    ...options,
    enabled: id.toString().length > 0,
    queryFn: () => getTournament({ id: id.toString() }),
  });

  return {
    tournament: data,
    isLoading,
  };
};

import { useTranslation } from 'react-i18next';

import { EventStatus, isAbleToTakeCards, useEventModel } from '~entities/event';
import { useMediaQuery } from '~shared/lib/hooks';

import { useVoteChoiceDialog } from '~features/event-dialog/model';
import { Button, ButtonIcon, Stack, Typography } from '~shared/ui';
import { BattleResult, RawDifficulty } from '~shared/api';

import { EventTimer } from '../../EventTimer';

import { ButtonsContainer, Coeff, Root } from './styled';

export const EventBattleActions = () => {
  const { isMobile } = useMediaQuery();
  const {
    potentialRewardAmount,
    event,
    eventStatus,
    isViewMode,
    isNoCardsSelected,
    isConnected,
    noBattleForEvent,
    battle: { cards, additionalCards, choice },
    onVote,
    onClearAll,
    onRemoveAllCardsFromEvent,
  } = useEventModel();

  const { openVoteChoiceDialog } = useVoteChoiceDialog();

  const { t } = useTranslation();

  const isVoteDisabled = isNoCardsSelected && isConnected;
  const isTakeCardsAvailable = isAbleToTakeCards(event!.date);

  const renderReward = () => {
    switch (true) {
      case noBattleForEvent || potentialRewardAmount === 0:
        return null;

      case [EventStatus.Pending, EventStatus.Live].includes(eventStatus as EventStatus):
        /* TODO: rewrite later */

        let coef = null;

        const getCoeff = (label?: RawDifficulty) => {
          switch (label) {
            case RawDifficulty.Obvious:
              return 0;
            case RawDifficulty.Easy:
              return 0.5;
            case RawDifficulty.Standart:
              return 1;
            case RawDifficulty.Hard:
              return 1.5;
            case RawDifficulty.Insane:
              return 3;
            default:
              return 1;
          }
        };

        switch (choice) {
          case BattleResult.HomeTeamWon: {
            coef = event?.matchDifficulty.home;
            break;
          }

          case BattleResult.Draw: {
            coef = event?.matchDifficulty.draw;
            break;
          }

          case BattleResult.AwayTeamWon: {
            coef = event?.matchDifficulty.away;
            break;
          }
        }

        return (
          <Stack>
            <Stack direction="row" alignItems="center" spacing={(isMobile ? 5 : 10) / 8}>
              <Typography color="secondary">{t('EventDialog.youCanWin')}</Typography>
              <Typography variant="h2" fontSize="16px">
                {potentialRewardAmount} MCN
              </Typography>
              <Coeff $color={coef?.color}>x{getCoeff(coef?.label)}</Coeff>
              <ButtonIcon name="question" variant="plain" size={20} style={{ opacity: 0.75 }} />
            </Stack>
            <Typography fontSize={12} color="rgba(255, 255, 255, 0.5)">
              if you win the Winstreak will be added to the card anyway
            </Typography>
          </Stack>
        );

      // TODO: Uncomment when we will be able to get rewardAmount for calls (now this field available only for bets)
      // case [EventStatus.Passed].includes(eventStatus as EventStatus):
      //   return (
      //     <Stack direction="row" alignItems="center" spacing={10 / 8}>
      //       <Typography color="secondary">
      //         {correctChoice ? t('EventDialog.youWin') : t('EventDialog.youLose')}
      //       </Typography>
      //       <Typography
      //         variant="h2"
      //         fontSize="16px"
      //         sx={{ color: correctChoice ? theme.palette.success.main : theme.palette.error.main }}
      //       >
      //         {correctChoice ? '+' : '-'}
      //         {potentialRewardAmount} MCN
      //       </Typography>
      //     </Stack>
      //   );

      default:
        return null;
    }
  };

  const renderButtons = () => {
    switch (true) {
      case eventStatus !== EventStatus.Pending:
        return null;

      case isViewMode && additionalCards.length === 0 && isTakeCardsAvailable:
        return (
          <Button
            size={isMobile ? 'md' : 'lg'}
            variant="outlined"
            onClick={onRemoveAllCardsFromEvent}
          >
            Take all cards
          </Button>
        );

      default:
        return (
          <ButtonsContainer>
            <Button size={isMobile ? 'md' : 'lg'} variant="outlined" onClick={onClearAll}>
              {t('BattlePopUp.clear')}
            </Button>

            <Button
              size={isMobile ? 'md' : 'lg'}
              disabled={isVoteDisabled}
              onClick={openVoteChoiceDialog}
            >
              {t('BattlePopUp.vote')}
            </Button>
          </ButtonsContainer>
        );
    }
  };

  return (
    <Root>
      <Stack
        // direction={isMobile ? 'column' : 'row'}
        spacing={6 / 8}
        width="100%"
        alignItems={isMobile ? 'initial' : 'center'}
      >
        {renderReward() || <span />}

        <Stack>{isViewMode && <EventTimer date={event!.date} />}</Stack>
        {renderButtons()}
      </Stack>
    </Root>
  );
};

import { styled } from '~shared/lib/styles';
import { Dialog, dialogClasses } from '~shared/ui';

export const StyledDialog = styled(Dialog)({
  [`& .${dialogClasses.content}`]: {
    height: 380,
    padding: 0,
    margin: 0,
  },
});

export const DailyQuestDialog = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // justifyContent: 'space-between',
  padding: 25,
  borderRadius: 16,
  width: '100%',
  // width: 340,
  height: 424,
  background: '#020202',
  position: 'relative',
  overflow: 'hidden',
});

export const AdditionalBlock = styled('div')({
  position: 'absolute',
  bottom: '32%',
  right: 31,
  width: 450,
  height: 450,
  borderRadius: '50%',
  // background: 'radial-gradient(circle, #0000ff, #000020)',
  background: 'rgba(0, 62, 165, 0.62)',
  filter: 'blur(60px)',
});

export const GiftWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  height: 160,
});

export const GiftImage = styled('img')({
  // position: 'absolute',
  height: 'auto',
  width: 'auto',
  zIndex: 1,
  // left: '19%',
  objectFit: 'contain',
});

export const Count = styled('div')({
  position: 'absolute',
  display: 'flex',
  backgroundColor: 'rgba(0, 0, 0, 0.57)',
  borderRadius: 51.8,
  padding: '6px 14px',
  bottom: 23,
  right: '29%',
  zIndex: 4,
});

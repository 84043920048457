import { apiInstance } from '~shared/api';

import { tonApiInstance } from '~shared/api/apiInstance';

import { mapGetLeaderboardByWalletResponse, mapGetLeaderboardResponse } from './mappers';
import { LeaderboardRoutes } from './routes';
import {
  ApiGetLeaderboard,
  ApiGetLeaderboardByWallet,
  ApiGetLeaderboardByWalletRawData,
  ApiGetLeaderboardRawData,
} from './types';

export const getLeaderboard: ApiGetLeaderboard = (params?) => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .get<Array<ApiGetLeaderboardRawData>>(LeaderboardRoutes.leaderboard(), { params })
      .then(({ data }) => resolve(mapGetLeaderboardResponse(data)))
      .catch(reject);
  });
};

export const getLeaderboardByWallet: ApiGetLeaderboardByWallet = (wallet, params) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get<ApiGetLeaderboardByWalletRawData>(LeaderboardRoutes.leaderboardByWallet(wallet), {
        params,
      })
      .then(({ data }) => resolve(mapGetLeaderboardByWalletResponse(data)))
      .catch(reject);
  });
};

import { Children, FC, ReactElement, cloneElement, isValidElement } from 'react';

import { Icon, IconAssetNamesType } from '~shared/ui';
import { sportToIconMap } from '~shared/lib/assets';

import { NftCardProps } from '~entities/nft';

import { NotificationIconPlate } from '../NotificationIconPlate';

import {
  NotificationIconFactoryCardWrapper,
  NotificationIconFactoryEventText,
  NotificationIconFactoryStateIconPlate,
} from './styled';

import { NotificationIconCardState, NotificationIconFactoryProps } from './types';

export const NotificationIconFactory: FC<NotificationIconFactoryProps> = ({
  type,
  children,
  state,
  sport,
  ...props
}) => {
  const isStack = Children.count(children) >= 2;

  switch (type) {
    case 'card':
    case 'card-state':
    case 'card-lose':
    case 'card-new':
      children = Children.toArray(children).slice(0, 2) as ReactElement<NftCardProps>[];
  }

  switch (type) {
    case 'system':
      return (
        <NotificationIconPlate {...props}>
          <Icon name="notification-system" size={40} />
        </NotificationIconPlate>
      );

    case 'gift':
      return (
        <NotificationIconPlate {...props}>
          <Icon name="notification-gift" size={40} />
        </NotificationIconPlate>
      );

    case 'event':
      return (
        <NotificationIconPlate {...props}>
          <Icon name={sportToIconMap[sport ?? 'soccer']} size={20} />
          <NotificationIconFactoryEventText>{children}</NotificationIconFactoryEventText>
        </NotificationIconPlate>
      );

    case 'card':
      return (
        <NotificationIconPlate {...props}>
          <NotificationIconFactoryCardWrapper stack={isStack}>
            {formatNftCardSize(children as ReactElement<NftCardProps>[])}
          </NotificationIconFactoryCardWrapper>
        </NotificationIconPlate>
      );

    case 'card-new':
      return (
        <NotificationIconPlate color="primary" {...props}>
          <NotificationIconFactoryCardWrapper>
            {formatNftCardSize(children as ReactElement<NftCardProps>[])}
          </NotificationIconFactoryCardWrapper>
        </NotificationIconPlate>
      );

    case 'card-lose':
      return (
        <NotificationIconPlate color="error" {...props}>
          <NotificationIconFactoryCardWrapper stack={isStack}>
            {formatNftCardSize(children as ReactElement<NftCardProps>[])}
          </NotificationIconFactoryCardWrapper>
        </NotificationIconPlate>
      );

    case 'card-state':
      return (
        <NotificationIconPlate {...props}>
          <NotificationIconFactoryStateIconPlate name={getCardStateIconName(state!)}>
            <Icon size={12} name={getCardStateIconName(state!)}></Icon>
          </NotificationIconFactoryStateIconPlate>

          <NotificationIconFactoryCardWrapper stack={isStack}>
            {formatNftCardSize(children as ReactElement<NftCardProps>[])}
          </NotificationIconFactoryCardWrapper>
        </NotificationIconPlate>
      );
  }

  return null;
};

const formatNftCardSize = (children: ReactElement<NftCardProps>[]) => {
  return Children.map<any, ReactElement<NftCardProps>>(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { size: 'xss' });
    }
  });
};

const getCardStateIconName = (state: NotificationIconCardState): IconAssetNamesType => {
  switch (state) {
    case 'auction':
      return 'auction';
    case 'freeze':
      return 'snowflake';
    case 'repair':
      return 'fix';
  }
};

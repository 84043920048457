import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

interface RootProps {
  $mobile?: boolean;
}

export const Root = styled(Box, { name: 'NftCardInfoActions', slot: 'root' })<RootProps>(({
  $mobile,
}) => {
  if ($mobile) {
    return {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(1px, 1fr))',
      gap: 10,
      ['& > button']: {
        fontSize: '13px',
      },
    };
  }

  return {
    display: 'flex',
    padding: '0 16px 0 16px',
    gap: 10,
  };
});

import { useInView } from 'react-intersection-observer';
import { FC, Fragment } from 'react';
import { clsx } from 'clsx';

import { Avatar, Box, Icon, IconAssetNamesType } from '~shared/ui';

import {
  TournamentPlayerAvatarContainer,
  TournamentPlayerAvatarMobileCrown,
  TournamentPlayerBrief,
  TournamentPlayerName,
  TournamentPlayerPlace,
  TournamentPlayerRoot,
  TournamentPlayerRowCell,
  TournamentPlayerRowCellBold,
  tournamentPlayerClasses,
} from './styled';

import { TournamentPlayerProps } from './types';

export const TournamentPlayer: FC<TournamentPlayerProps> = ({
  isTournamentStartedOrEnded,
  isViewer = false,
  isMobile,

  player: {
    /***/
    place,
    avatar,
    name,
    // @ts-ignore
    bets = 0,
    wins = 0,
    loses = 0,
    // @ts-ignore
    coins = 0,
  },
}) => {
  const {
    /***/
    ref: inViewRef,
    inView,
  } = useInView({
    skip: !isViewer,
    threshold: 1,
  });

  const renderCrown = () => {
    if (isTournamentStartedOrEnded) {
      const name = getCrownIcon(place);

      if (name) {
        return <Icon size={isMobile ? 20 : 24} name={name} />;
      }
    }

    return null;
  };

  return (
    <Fragment>
      {isViewer && (
        <Box position="relative">
          <Box
            position="absolute"
            left={0}
            right={0}
            ref={inViewRef}
            // 2x of row height
            height={isMobile ? 100 : 120}
            // fill Dialog's content slot padding for trigger intersection
            top={isMobile ? -25 : -30}
          />
        </Box>
      )}

      <TournamentPlayerRoot
        className={clsx({
          [tournamentPlayerClasses.viewer]: isViewer,
          [tournamentPlayerClasses.sticky]: !inView && isViewer && isTournamentStartedOrEnded,
          [tournamentPlayerClasses.notStarted]: !isTournamentStartedOrEnded,
        })}
      >
        <TournamentPlayerBrief>
          {isTournamentStartedOrEnded && <TournamentPlayerPlace>{place}</TournamentPlayerPlace>}

          <TournamentPlayerAvatarContainer>
            <TournamentPlayerAvatarMobileCrown>
              {isMobile ? renderCrown() : null}
            </TournamentPlayerAvatarMobileCrown>

            <Avatar src={avatar} size={isMobile ? 22 : 32} />
          </TournamentPlayerAvatarContainer>

          <TournamentPlayerName>{name}</TournamentPlayerName>

          {isMobile ? null : renderCrown()}
        </TournamentPlayerBrief>

        <TournamentPlayerRowCell className={tournamentPlayerClasses.cell}>
          {getWinRate(wins, loses)}%
        </TournamentPlayerRowCell>
        <TournamentPlayerRowCell className={tournamentPlayerClasses.cell}>
          {bets}
        </TournamentPlayerRowCell>
        <TournamentPlayerRowCellBold className={tournamentPlayerClasses.cell}>
          {coins}
        </TournamentPlayerRowCellBold>
      </TournamentPlayerRoot>
    </Fragment>
  );
};

const getWinRate = (wins: number, loses: number) => {
  const sum = wins + loses;

  const result = Math.floor((wins / sum) * 100);

  if (Number.isNaN(result)) {
    return 0;
  }

  return result;
};

const getCrownIcon = (place: number): IconAssetNamesType | null => {
  switch (place) {
    case 1:
      return 'crown-gold';

    case 2:
      return 'crown-silver';

    case 3:
      return 'crown-bronze';

    default:
      return null;
  }
};

export const TournamentRoutes = {
  list: () => '/tournaments/list',

  // get tournaments by wallet
  personalList: () => '/tournaments/personal',

  tournament: () => '/tournaments/tournament',

  join: () => '/tournaments/join',
};

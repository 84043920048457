import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useMount } from 'react-use';

import { useDispatch } from '~shared/lib/hooks';

// todo: fsd

import { getAuctionCards } from '~shared/api';

import { auctionActions } from './slice';

export const useAuctionCardsQuery = () => {
  const dispatch = useDispatch();

  const fetchAuctionCards = () => {
    dispatch(auctionActions.setLoading(true));

    getAuctionCards().then(async (auctionCards) => {
      const notBurnedCards = auctionCards.filter((card) => !card.isBurned);

      dispatch(auctionActions.setAuctionCards(notBurnedCards));
      dispatch(auctionActions.setLoading(false));
    });
  };

  useMount(fetchAuctionCards);
};

export const useAuctionCardsListEffects = () => {
  useAuctionCardsQuery();
};

export const useQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

/**
 * Event statistics
 */
export type EventCommonStatisticKeys = 'wins' | 'goals' | 'losses' | string;

export type EventTennisStatisticKeys =
  | 'highestDoublesRanking'
  | 'highestSinglesRanking'
  | 'points'
  | 'rank';

export type EventEsportsStatisticKeys = 'bestStreak' | 'currentStreak' | 'winRate' | 'worstStreak';

export const STATISTIC_SCORE_KEY_TO_LABEL_MAP: Record<EventCommonStatisticKeys, string> = {
  wins: 'BattlePopUp.onlyWin',
  goals: 'BattlePopUp.goals',
  losses: 'BattlePopUp.lose',
  draws: 'Other.draw',
  highestDoublesRanking: 'BattlePopUp.highestDoublesRanking',
  highestSinglesRanking: 'BattlePopUp.highestSinglesRanking',
  points: 'BattlePopUp.points',
  rank: 'BattlePopUp.rank',

  bestStreak: 'BattlePopUp.bestStreak',
  currentStreak: 'BattlePopUp.currentStreak',
  winRate: 'BattlePopUp.winRate',
  worstStreak: 'BattlePopUp.worstStreak',
  streak: 'BattlePopUp.streak',
};

export const EVENT_COMMON_STATISTIC_KEYS: Array<EventCommonStatisticKeys> = [
  'wins',
  'goals',
  'losses',
  'draws',
];

export const EVENT_TENNIS_STATISTIC_KEYS: Array<EventTennisStatisticKeys> = [
  'highestDoublesRanking',
  'highestSinglesRanking',
  'points',
  'rank',
];

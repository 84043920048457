import { ConnectDropTarget, useDrop } from 'react-dnd';

import { useMemo } from 'react';

import { ApiGetBattlesMappedData, BattleResult, BetSlot, Nft } from '~shared/api';

import { NftDragItem, NftDragItemType, getNftByTokenId, useNftSelector } from '~entities/nft';
import {
  isEventNotStarted,
  isEventPassed,
  useEventModel,
  useEventValidateCard,
} from '~entities/event';

interface UseDropBet {
  (params: {
    event: ApiGetBattlesMappedData;
    result: BattleResult;
    tokenIds: Array<string | number>;
    choice?: BetSlot;
  }): {
    highlight: boolean;
    isOverlapBackdropAvailable: boolean;
    ref: ConnectDropTarget;
  };
}

export const useDropBet: UseDropBet = ({ event, choice, tokenIds, result }) => {
  const { nfts } = useNftSelector();
  const { openEvent } = useEventModel();
  const { validateCard } = useEventValidateCard();

  const cards = useMemo(
    () => tokenIds.map((tokenId) => getNftByTokenId(nfts, tokenId.toString())!),
    [nfts, tokenIds]
  );

  const handleOpenEvent = (nft: Nft) => {
    if (choice) {
      openEvent(event, {
        battle: {
          cards,
          additionalCards: [nft],
          choice,
          isViewMode: true,
          rewardReceived: null,
        },
      });
    }
  };

  const [{ isOver, canDrop }, dragRef] = useDrop<NftDragItem, any, any>({
    accept: NftDragItemType.Place,
    drop: async (item: NftDragItem) => {
      if (!choice) {
        return;
      }

      const nft = getNftByTokenId(nfts, item.tokenId);

      if (nft) {
        const isValidated = await validateCard({
          card: nft,
          slot: choice,
          cardsAmount: cards.length,
          onWinstreakWarningConfirm: () => handleOpenEvent(nft),
        });

        if (!isValidated) {
          return;
        }

        handleOpenEvent(nft);
      }
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: !isEventPassed(result),
      };
    },
  });

  return {
    highlight: canDrop && isOver,
    isOverlapBackdropAvailable: isEventNotStarted(event.date),
    ref: dragRef,
  };
};

import { customScrollbar, styled } from '~shared/lib/styles';
import { Box, ListTopShadow } from '~shared/ui';

import { NFT_CARD_SIZE_VALUES } from '~entities/nft';

export const ViewerWalletListVirtuosoGridScroller = styled('div', {
  name: 'ViewerWalletList',
  slot: 'virtuosoGridScroller',
})(() => ({
  paddingLeft: 16,
  paddingRight: 16,
  marginLeft: -16,
  marginRight: -16,

  ...customScrollbar,

  ['& > div']: {
    width: 'calc(100% - 32px) !important',
  },
}));

export const ViewerWalletListPaddingSpace = styled('div', {
  name: 'ViewerWalletList',
  slot: 'paddingSpace',
})(() => ({
  paddingTop: 16,
}));

export const ViewerWalletListGrid = styled(Box, {
  name: 'ViewerWalletList',
  slot: 'grid',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  rowGap: 20,
  columnGap: `${NFT_CARD_SIZE_VALUES.md.width}`,
  // display: 'grid',
  // alignContent: 'baseline',
  // justifyContent: 'space-between',

  // gridTemplateColumns: `repeat(3, ${NFT_CARD_SIZE_VALUES.md.width})`,
  // gridAutoRows: `${NFT_CARD_SIZE_VALUES.md.height}`,

  // [theme.breakpoints.down('mobile')]: {
  //   gridTemplateColumns: `repeat(3, ${NFT_CARD_SIZE_VALUES.lg.width})`,
  //   gridAutoRows: `${NFT_CARD_SIZE_VALUES.lg.height}`,
  // },
}));

export const ViewerWalletListShimmerContainer = styled(ViewerWalletListGrid, {
  name: 'ViewerWalletList',
  slot: 'shimmerContainer',
})(() => ({
  paddingBottom: 16,
}));

export const ViewerWalletListTopShadow = styled(ListTopShadow)(() => ({
  width: 'calc(100% + 26px)',
}));

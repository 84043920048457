import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, Tabs, TabsList } from '~shared/ui';

import { NftCardDetailedInfoTab } from '../../../../model';

import { NftCardDetailedInfoTabsProps } from './types';

export const NftCardDetailedInfoTabs: FC<NftCardDetailedInfoTabsProps> = ({ ...tabsProps }) => {
  const { t } = useTranslation();

  return (
    <Tabs {...tabsProps} defaultValue={NftCardDetailedInfoTab.Battles}>
      <TabsList>
        <Tab value={NftCardDetailedInfoTab.Battles}>{t('Other.battles')}</Tab>
        {/* <Tab value={NftCardDetailedInfoTab.Calls}>{t('Tabs.myCalls')}</Tab> */}
        <Tab value={NftCardDetailedInfoTab.Transactions} disabled>
          {t('Other.transactions')}
        </Tab>
      </TabsList>
    </Tabs>
  );
};

export const normalizeMobileWindowHeight = () => {
  const setNormalizedHeightVariable = () => {
    const doc = document.documentElement;
    doc.style.setProperty(normalizeMobileWindowHeight.cssVariableName, `${window.innerHeight}px`);
  };

  window.addEventListener('resize', setNormalizedHeightVariable);
  window.addEventListener('orientationchange', setNormalizedHeightVariable);

  setNormalizedHeightVariable();

  return () => {
    window.removeEventListener('resize', setNormalizedHeightVariable);
    window.removeEventListener('orientationchange', setNormalizedHeightVariable);
  };
};

normalizeMobileWindowHeight.cssVariableName = '--app-height';

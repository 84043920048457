import { styled } from '~shared/lib/styles';

export const ToggleButtonPillContainerRoot = styled('div')(() => ({
  flexShrink: 0,
  margin: -4,

  ['& > *']: {
    margin: 4,
  },
}));

import { useQuery } from 'react-query';

import { ApiCommonLeague, ApiCommonSport, getBets } from '~shared/api';

import { useProfileModel } from './../model';

interface UseProfileUserBattlesQueryParams {
  sport?: Array<ApiCommonSport>;
  league?: ApiCommonLeague;
  address: string;
}

export const useProfileUserBattlesQuery = ({
  sport,
  league,
  address,
}: UseProfileUserBattlesQueryParams) => {
  const { profileBattlesQueryKey } = useProfileModel();

  const { data, isLoading } = useQuery(
    [profileBattlesQueryKey, ...(sport ?? []), league],
    () =>
      getBets({ address, sport, league }).then(({ bets }) =>
        bets.filter((bet) => bet.isTakenBeforeMatchStarted !== 1)
      ),
    { enabled: address.length > 0 }
  );

  return {
    isLoading: isLoading,
    isEmptyList: (isLoading ? false : data?.length === 0) || address.length === 0,
    list: data,
  };
};

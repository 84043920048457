import { clsx } from 'clsx';
import { forwardRef } from 'react';

import { StyledDragCell, dragCellClasses } from './styled';
import { DragCellProps } from './types';

export const DragCell = forwardRef<HTMLElement, DragCellProps>(
  (
    {
      className,
      isHighlighted,
      isHighlightedError,
      isPlacedToCell,
      removeBoxShadow,
      ...otherProps
    },
    ref
  ) => {
    const computedClassName = clsx(
      resolveBackgroundClassName(isHighlighted, isHighlightedError),
      resolveBoxShadowClassName(isHighlighted, isHighlightedError, isPlacedToCell, removeBoxShadow),
      className
    );

    return <StyledDragCell {...otherProps} ref={ref} className={computedClassName} />;
  }
);

const resolveBoxShadowClassName = (
  isHighlighted: boolean | undefined,
  isHighlightedError: boolean | undefined,
  isPlacedToCell: boolean | undefined,
  removeBoxShadow: boolean | undefined
) => {
  if (isPlacedToCell || isHighlighted) {
    return dragCellClasses.highlightedBoxShadow;
  }

  if (isPlacedToCell || isHighlightedError) {
    return dragCellClasses.highlightedErrorBoxShadow;
  }

  if (!removeBoxShadow) {
    return dragCellClasses.transparentBoxShadow;
  }
};

const resolveBackgroundClassName = (
  isHighlighted: boolean | undefined,
  isHighlightedError: boolean | undefined
) => {
  if (isHighlighted) {
    return dragCellClasses.highlightedBackground;
  }

  if (isHighlightedError) {
    return dragCellClasses.highlightedErrorBackground;
  }

  return dragCellClasses.transparentBackground;
};

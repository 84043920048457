import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentPlaceholder } from '~shared/ui';

export const TournamentPlayersPlaceholder: FC = () => {
  const { t } = useTranslation();

  return (
    <ContentPlaceholder
      py={128 / 8}
      heading={t('Tournaments.noPlayersYetPlaceholder')}
      message={t('Tournaments.beFirstWhoJoinTournament')}
    />
  );
};

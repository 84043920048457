import { RawDifficulty } from './types';

export const difficultyToColorMap = {
  [RawDifficulty.Standart]: 'blue',
  [RawDifficulty.Obvious]: 'green',
  [RawDifficulty.Easy]: 'green',
  [RawDifficulty.Hard]: 'red',
  [RawDifficulty.Insane]: 'red',
  [RawDifficulty.Unknown]: 'blue',
};

import { ethers } from 'ethers';

import { Multicall } from '~shared/contracts';
import { PartialNft, fetchNfts } from '~shared/api';

import { mapNftInfoFromMulticallRequest } from './mapNftInfoFromMulticallRequest';
import { getNftInfoMulticallRequests } from './getNftInfoMulticallRequests';

export const getViewerWalletCards = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider,
  multiCallContractAttached: Multicall,
  cursor?: string
) => {
  const { nfts, cursor: _cursor } = await fetchNfts({
    walletAddress: address,
    cursor,
  });

  // const requests = await Promise.all(
  //   nfts.map(async (nft) => {
  //     return await getNftInfoMulticallRequests({
  //       tokenId: nft.token_id,
  //       provider,
  //     });
  //   })
  // ).then((results) => results.flatMap((result) => result));

  // const result = await multiCallContractAttached.callStatic.multicall(requests);

  // const walletCards = mapNftInfoFromMulticallRequest(nfts, result);

  return {
    walletCards: nfts as PartialNft[],
    cursor: _cursor,
  };
};

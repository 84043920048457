import { FC } from 'react';

import { Box, Button, Dialog, Typography } from '~shared/ui';

import { NftCardDrag } from '~entities/nft';

import { useMergeModel } from '../../../../model';

export const SuccessMergeDialog: FC = () => {
  const { successMergeDialogOpen, closeSuccessMergeDialog, recentlyCreatedNft } = useMergeModel();

  return (
    <Dialog open={successMergeDialogOpen} onClose={closeSuccessMergeDialog} width={440}>
      <Box display="flex" flexDirection="column" alignItems="center" gap="24px">
        <Typography variant="h1" textAlign="center">
          New card
        </Typography>

        {recentlyCreatedNft && (
          <NftCardDrag nft={recentlyCreatedNft} highlight highlightDensity="large" isNew />
        )}

        <Typography textAlign="center">
          Congratulations! <br />
          You have received a new card.
        </Typography>

        <Button onClick={closeSuccessMergeDialog}>Got it</Button>
      </Box>
    </Dialog>
  );
};

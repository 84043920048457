import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';

import { useMediaQuery } from '~shared/lib/hooks';

import { formatLocaleDateString } from '../../lib';

import {
  CardDatesBadge,
  CardStatusActiveBadge,
  CardStatusPassedBadge,
  CardStatusSoonBadge,
  cardStatusBadgeClasses,
} from './styled';

import { TournamentBadgeProps } from './types';

export const TournamentBadge: FC<TournamentBadgeProps> = ({
  type,
  startDate,
  endDate,
  size = 'md',
}) => {
  const { isMobile } = useMediaQuery();

  const { t } = useTranslation();

  const className = clsx({
    [cardStatusBadgeClasses.sm]: size === 'sm',
    [cardStatusBadgeClasses.md]: size === 'md',
  });

  switch (type) {
    case 'active':
      return (
        <CardStatusActiveBadge className={className}>
          {t('Tournaments.activeStatus')}
        </CardStatusActiveBadge>
      );
    case 'soon':
      return (
        <CardStatusSoonBadge className={className}>
          {t('Tournaments.soonStatus')}
        </CardStatusSoonBadge>
      );
    case 'end':
      return (
        <CardStatusPassedBadge className={className}>
          {t('Tournaments.endStatus')}
        </CardStatusPassedBadge>
      );
    case 'date':
      return (
        <CardDatesBadge className={className}>
          {formatLocaleDateString(startDate, isMobile)} —&nbsp;
          {formatLocaleDateString(endDate, isMobile)}
        </CardDatesBadge>
      );
  }
};

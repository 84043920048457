import { useDragLayer, useDrop } from 'react-dnd';
import { useState } from 'react';
import localforage from 'localforage';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSnackbar } from '~shared/lib/hooks';
import {
  ApiGetBattlesByIdsMappedData,
  ApiGetBattlesMappedData,
  ApiGetBattlesTeam,
  BattleResult,
  Nft,
} from '~shared/api';

// import { useWriteContract } from '~entities/wallet';
import { eventActions, useEventModel } from '~entities/event';
import {
  NftDragItem,
  NftDragItemType,
  getCallSameRarityMessage,
  getNftByTokenId,
  isNftAvailableForMerge,
  nftActions,
  useNftSelector,
} from '~entities/nft';

import { checkWinstreakWarnDialogNotShown } from './model';

const MAX_NFTS_ON_ONE_BET = 10;

export const useWinstreakWarningDialogSwitch = () => {
  const dispatch = useDispatch();

  const switchOn = async (nft: Nft, result: BattleResult, onBetCardCallback?: VoidFunction) => {
    // todo: make all changes to another state trough model (useEventModel)
    dispatch(
      eventActions.set({
        nftWithWinstreakDialog: {
          nft,
          open: true,
          result,
          onBetCard: onBetCardCallback || null,
        },
      })
    );
  };

  const switchOff = () => {
    dispatch(
      eventActions.set({
        nftWithWinstreakDialog: {
          nft: null,
          open: false,
          result: null,
          onBetCard: null,
        },
      })
    );
  };

  return {
    switchOn,
    switchOff,
  };
};

export const useWinstreakWarningDialog = () => {
  const {
    nftWithWinstreakDialog: { open, nft, result, onBetCard },
  } = useEventModel();

  const [dontShowWinstreakDialogInFuture, setDontShowWinstreakDialogInFuture] = useState(false);

  const placeNftToDragCell = usePlaceNftToDragCell();

  const { switchOn, switchOff } = useWinstreakWarningDialogSwitch();

  const dontShowWinstreakDialog = () => {
    localforage.setItem('IS_FULL_WINSTREAK_WARN_DIALOG_NOT_SHOWN', dontShowWinstreakDialogInFuture);
  };

  const betNft = () => {
    if (nft && result !== null) {
      if (onBetCard) {
        onBetCard();
        switchOff();

        if (dontShowWinstreakDialogInFuture) {
          dontShowWinstreakDialog();
        }

        return;
      }

      placeNftToDragCell(nft, result);
      switchOff();

      if (dontShowWinstreakDialogInFuture) {
        dontShowWinstreakDialog();
      }
    }
  };

  return {
    open,
    nft,

    betNft,
    switchOn,
    switchOff,
    handleChangeWinstreakDialogShown: setDontShowWinstreakDialogInFuture,
  };
};

export const useVoteChoiceDialog = () => {
  const {
    voteChoiceDialog: { open },
  } = useEventModel();

  const dispatch = useDispatch();

  const openVoteChoiceDialog = () => dispatch(eventActions.setVoteChoiceDialogOpen(true));
  const closeVoteChoiceDialog = () => dispatch(eventActions.setVoteChoiceDialogOpen(false));

  return {
    open,
    openVoteChoiceDialog,
    closeVoteChoiceDialog,
  };
};

interface GetTeamByBetChoiceParams {
  event: ApiGetBattlesMappedData | ApiGetBattlesByIdsMappedData | null;
  result: BattleResult | null;
}

export const getTeamByBetChoice = ({
  event,
  result,
}: GetTeamByBetChoiceParams): ApiGetBattlesTeam | null => {
  if (result === null || event === null) {
    return null;
  }

  if (result === BattleResult.HomeTeamWon) {
    return event.homeTeam;
  }

  return event.awayTeam;
};

// todo: refactoring, maybe unite some logic from `useDropBattleSlot` in model methods
export const useEventDrag = (params: { result: BattleResult | null; viewMode?: boolean }) => {
  const { openSnackbar } = useSnackbar();
  const { switchOn } = useWinstreakWarningDialog();
  const placeNftToDragCell = usePlaceNftToDragCell();
  const { t } = useTranslation();

  const { result, viewMode } = params;

  const { nfts } = useNftSelector();
  const {
    cards,
    battle: { additionalCards },
    call: { callAcceptableRarity },
    isCall,
    isMakeCall,
    choice,
  } = useEventModel();

  const { draggingItem } = useDragLayer<{ draggingItem: NftDragItem }, NftDragItem>((monitor) => ({
    draggingItem: monitor.getItem(),
  }));

  const isAvailableToPlaceOrMove = (() => {
    if (cards.length === 0) {
      return true;
    }

    // In case we move 1 placed card to another result
    if (cards.length === 1) {
      if (cards[0].token_id === draggingItem?.tokenId) {
        return true;
      }
    }

    // In case we place second card
    if (cards.length > 0) {
      // In case of call viewer may place only one card
      if (isCall || isMakeCall) {
        return false;
      }

      return result === choice;
    }
  })();

  const isReturnCardToCell = (() => {
    if (cards.length === 1) {
      if (cards[0].token_id === draggingItem?.tokenId) {
        if (choice === result) {
          return true;
        }
      }
    }

    return false;
  })();

  const handleDrop = async (item: NftDragItem) => {
    const nft = getNftByTokenId(nfts, item.tokenId);

    const totalCardsAmount = cards.length + additionalCards.length + 1;
    const isMaxNftLimitReached = totalCardsAmount > MAX_NFTS_ON_ONE_BET;

    if (isMaxNftLimitReached) {
      openSnackbar({
        type: 'error',
        message: t('Errors.maxNftsOnOneBet', { limit: MAX_NFTS_ON_ONE_BET }),
      });

      return;
    }

    const isZeroLivesRemaining = nft?.livesRemaining === 0;

    if (isZeroLivesRemaining) {
      openSnackbar({
        type: 'error',
        message: "You can't bet card with 0 lives remaining",
      });

      return;
    }

    if (typeof item.rarity === 'number' && callAcceptableRarity !== null) {
      if (item.rarity !== callAcceptableRarity) {
        openSnackbar({
          message: getCallSameRarityMessage(callAcceptableRarity, t),
        });

        return;
      }
    }

    if (!isAvailableToPlaceOrMove) {
      if (isCall || isMakeCall) {
        openSnackbar({ type: 'error', message: `${t('Alerts.callsSupport')}` });
      } else {
        openSnackbar({
          type: 'error',
          message: 'You cannot bet on both teams simultaneously',
        });
      }

      return;
    }

    if (nft && result !== null) {
      const isWinstreakDialogNotShown = await checkWinstreakWarnDialogNotShown();

      if (!isWinstreakDialogNotShown && isNftAvailableForMerge(nft).isAvailable) {
        switchOn(nft, result);

        return;
      }

      placeNftToDragCell(nft, result);
    }
  };

  const [{ isOver, canDrop }, dragRef] = useDrop<NftDragItem, any, any>({
    accept: [NftDragItemType.Place, NftDragItemType.MoveOrLeave],
    collect(monitor) {
      const item = monitor.getItem();
      let canDrop = true;

      if (typeof item?.rarity === 'number' && callAcceptableRarity !== null) {
        canDrop = item.rarity === callAcceptableRarity;
      }

      return {
        isOver: monitor.isOver(),
        canDrop,
      };
    },
    drop: handleDrop,
  });

  const { isDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
  }));

  const ref = viewMode ? undefined : dragRef;

  const isCellHighlighted = isOver && isAvailableToPlaceOrMove && !viewMode;

  return { isCellHighlighted, dropRef: ref, canDrop, isDragging, isReturnCardToCell };
};

const usePlaceNftToDragCell = () => {
  const dispatch = useDispatch();

  const {
    isCall,
    isViewMode,
    battle: { additionalCards },
    cards,
    betType,
  } = useEventModel();

  const placeNftToDragCell = (nft: Nft, result: BattleResult | null) => {
    const cardsKey = !isCall && isViewMode ? 'additionalCards' : 'cards';
    const cardsLink = !isCall && isViewMode ? additionalCards : cards;

    const newCardsMap = new Map<Nft['token_id'], Nft>();
    cardsLink.forEach((card) => newCardsMap.set(card.token_id, card));
    newCardsMap.set(nft.token_id, nft);
    const newCards = Array.from(newCardsMap, ([, nft]) => nft);

    // todo: model method
    dispatch(
      eventActions.set({
        [betType]: {
          choice: result,
          [cardsKey]: newCards,
        },
      })
    );

    // todo: model method
    dispatch(nftActions.setNftHidden({ isHidden: true, nftIds: [nft.token_id] }));
  };

  return placeNftToDragCell;
};

export const useEventTransactions = (/*closeEventDialog: VoidFunction*/) => {
  // const { t } = useTranslation();

  // MOCK
  // TODO: make all event contract calls here
  // const { write: makeBet } = useWriteContract({
  //   contract: 'Arena',
  //   method: 'makeBet',
  //   transactionName: t('Alerts.votingEvent'),
  //   successMessage: `${t('Alerts.successfulVote')}`,
  //   errorMessage: `${t('Errors.votingFailed')}`,
  //   onSuccess: closeEventDialog,
  // });

  // const { write: makeMultipleBet } = useWriteContract({
  //   contract: 'Arena',
  //   method: 'massMakeBet',
  //   transactionName: t('Alerts.votingEvent'),
  //   successMessage: `${t('Alerts.successfulVote')}`,
  //   errorMessage: `${t('Errors.voteEventFail')}`,
  //   onSuccess: closeEventDialog,
  // });

  // const { write: makeCall } = useWriteContract({
  //   contract: 'Arena',
  //   method: 'createCall',
  //   transactionName: 'Call for event',
  //   successMessage: `${t('Alerts.successfulCall')}`,
  //   errorMessage: `${t('Errors.callFailed')}`,
  //   onSuccess: closeEventDialog,
  // });

  // const { write: acceptCall } = useWriteContract({
  //   contract: 'Arena',
  //   method: 'acceptCall',
  //   transactionName: 'Accept call',
  //   successMessage: `${t('Alerts.successfulAceptedCall')}`,
  //   errorMessage: `${t('Errors.failedAcccept')}`,
  //   onSuccess: closeEventDialog,
  // });

  // const { write: approveCard } = useWriteContract({
  //   contract: 'NFT',
  //   method: 'approve',
  //   transactionName: 'Approving card',
  //   successMessage: `${t('Alerts.approveCardsWasSuccefull')}`,
  //   errorMessage: `${t('Alerts.failedAceptedCall')}`,
  // });

  // const { write: approveMultipleCards } = useWriteContract({
  //   contract: 'NFT',
  //   method: 'massApprove',
  //   transactionName: 'Approving cards',
  //   successMessage: `${t('Alerts.approveCardsWasSuccefull')}`,
  //   errorMessage: `${t('Alerts.failedAceptedCall')}`,
  // });

  // const { write: takeAllCardsFromEvent } = useWriteContract({
  //   contract: 'Arena',
  //   method: 'massTakeCard',
  //   transactionName: 'Removing your cards from event',
  //   successMessage: `${t('Alerts.successfulRemove')}`,
  //   errorMessage: `${t('Errors.removeFailed')}`,
  // });

  return {
    makeBet: () => {},
    makeMultipleBet: () => {},

    makeCall: () => {},
    acceptCall: () => {},

    approveCard: () => {},
    approveMultipleCards: () => {},

    takeAllCardsFromEvent: () => {},
  };
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { WalletBalance, WalletBalanceReference, WalletState } from './types';

const initialState: WalletState = {
  isConnectWalletDialogOpen: false,
  isWarnDialogOpen: false,
  isWalletConnectedDialogOpen: false,
  isOnboardingShownAfterConnect: false,
  isCorrectWalletConnected: false,
  isProofed: false,
  balance: {
    mcn: 0,
    ton: 0,
  },
  balanceReference: {
    ton: {
      usd: null,
    },
    mcn: {
      usd: null,
    },
  },
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWallet: (state, action: PayloadAction<Partial<WalletState>>) => ({
      ...state,
      ...action.payload,
    }),
    setBalance: (state, action: PayloadAction<WalletBalance>) => {
      state.balance = action.payload;
    },
    setBalanceReference: (state, action: PayloadAction<WalletBalanceReference>) => {
      state.balanceReference = action.payload;
    },
    setWarnDialog: (state, action: PayloadAction<boolean>) => {
      state.isWarnDialogOpen = action.payload;
    },
    setWalletConnectedDialog: (state, action: PayloadAction<boolean>) => {
      state.isWalletConnectedDialogOpen = action.payload;
    },

    reset: () => {
      return { ...initialState };
    },
  },
});

export const walletActions = walletSlice.actions;

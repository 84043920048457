import { useCallback } from 'react';
import { BigNumber, Signature } from 'ethers';
import { useWeb3React } from '@web3-react/core';

import { Dictionary } from '@ton/core';

import { MainToken__factory, Tournament__factory } from '~shared/contracts';

import { signGaslessTxMessage, useTonClient, useWriteContract } from '~entities/wallet';

import { useDispatch } from '~shared/lib/hooks';
import { nftActions } from '~entities/nft';

import { useJoinTournamentModel } from '../model';

export const useJoinTournament = () => {
  const { account, provider } = useWeb3React();

  const { nftsToJoin, tournament } = useJoinTournamentModel();

  // MOCK

  // const joinTournamentGasless = useCallGasless<ApiPostJoinTournamentRequestData>({
  //   // todo: custom messages
  //   callback: postJoinTournament,
  // });

  // const gaslessPermitMCN = useCallGasless<ApiPostPermitRequestData>({
  //   callback: postPermit,
  //   transactionName: 'Approving MCN',
  //   // successMessage: `${t('Alerts.approveMCN')}`,
  //   // errorMessage: `${t('Errors.approveMCN')}`,
  // });

  const signPermitJoinTournament = useCallback(
    async (amount: BigNumber, spender: string, sender: string): Promise<Signature | null> => {
      const signer = provider?.getSigner();

      if (!signer) {
        return null;
      }

      const types: Array<'uint256' | 'address'> = ['uint256', 'address', 'uint256'];

      const mainTokenContract = MainToken__factory.connect(
        process.env.REACT_APP_ADDRESS_SK_TOKEN_MAINTOKEN,
        signer
      );

      const permitOpsCounter = await mainTokenContract.permitOpsCounter(sender);

      const values: Array<BigNumber | string> = [permitOpsCounter, spender, amount];

      const signedMessage = await signGaslessTxMessage({ types, values, signer });

      return signedMessage;
    },
    [provider]
  );

  const signJoinTournamentMessage = useCallback(async (): Promise<Signature | null> => {
    const signer = provider?.getSigner();

    if (!signer) {
      return null;
    }

    const contract = Tournament__factory.connect(process.env.REACT_APP_ADDRESS_TOURNAMENT, signer);

    const gasFreeOpCounter = await contract._gasFreeOpCounter(signer.getAddress());

    const types = ['uint256'];
    const values: Array<BigNumber | number | string> = [gasFreeOpCounter];

    nftsToJoin.forEach((nft) => {
      types.push('uint256');
      values.push(Number(nft.token_id));
    });

    types.push('uint256');
    values.push(tournament!.tournamentId);

    const signedMessage = await signGaslessTxMessage({ types, values, signer });

    return signedMessage;
  }, [nftsToJoin, provider, tournament]);

  const { write: participate } = useWriteContract({
    contractName: 'Tournament',
    method: 'Participate',
    // transactionName: t('Alerts.votingEvent'),
    // successMessage: `${t('Alerts.successfulVote')}`,
    // errorMessage: `${t('Errors.votingFailed')}`,
  });

  const client = useTonClient();

  const dispatch = useDispatch();

  const joinTournament = useCallback(
    async (onJoin?: VoidFunction) => {
      if (!client) {
        return;
      }

      const data = Dictionary.empty<bigint, bigint>();

      nftsToJoin.forEach((nft, i) => {
        data.set(BigInt(i), BigInt(nft.token_id));
      });

      try {
        await participate({
          args: {
            $$type: 'Participate',
            cards: data,
          },

          value: BigInt(tournament?.defaultJoinPrice.value!),

          additionalArgs: {
            contractAddress: tournament?.tonContractAddress,
          },
        });

        dispatch(nftActions.setIsBlockedForTournament(nftsToJoin.map((nft) => nft.token_id)));

        onJoin?.();
      } catch (e) {
        console.log('ERROR IN CATCH', e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, client, nftsToJoin, signJoinTournamentMessage, signPermitJoinTournament, tournament]
  );

  return {
    joinTournament,
  };
};

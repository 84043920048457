import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

interface EventBadgeProps {
  backgroundColor?: string;
}

export const EventBadge = styled(Box)<EventBadgeProps>(({ theme, backgroundColor }) => ({
  padding: '4px 10px',
  textTransform: 'uppercase',
  ...theme.typography.h2,
  fontSize: '11px',
  background: backgroundColor || theme.palette.success.main,
  borderRadius: '30px',
}));

export const EventContentLayout = styled('div')(() => ({
  flex: 1,
}));

import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from '~shared/lib/hooks';

import {
  NFT_RARITY_TO_READABLE_RARITY_NAME_MAP,
  NftDragItem,
  NftDragItemType,
  useNftCardModel,
} from '~entities/nft';

import { useJoinTournamentModel } from '../model';

export const useJoinTournamentDrop = () => {
  const { t } = useTranslation();
  const { openSnackbar } = useSnackbar();

  const { nfts } = useNftCardModel();
  const { addNftToJoinTournament, tournament, nftsToJoin } = useJoinTournamentModel();

  const canDrop = (item: NftDragItem | null): boolean | 'rarity' | 'quantity' | 'broken' => {
    if (tournament && item) {
      const foundNft = nfts.find((nft) => nft.token_id === item.tokenId)!;

      if (foundNft.livesRemaining < 1) {
        return 'broken';
      }

      if (foundNft.rarity !== tournament.tournamentRarity) {
        return 'rarity';
      }

      if (nftsToJoin.length === tournament.joinCardsQuantity) {
        return 'quantity';
      }

      return true;
    }

    return false;
  };

  const [{ isOver, isHighlightError, isHighlightInfo }, dropRef] = useDrop<NftDragItem, any, any>({
    accept: NftDragItemType.Place,

    collect: (monitor) => {
      const canDropResult = canDrop(monitor.getItem());
      const canDropBoolean = typeof canDropResult === 'string' ? false : canDropResult;

      return {
        isOver: monitor.isOver(),
        isHighlightError: monitor.isOver() ? !canDropBoolean : false,
        isHighlightInfo: monitor.isOver() ? canDropBoolean : false,
      };
    },

    drop: (item) => {
      const canDropResult = canDrop(item);

      switch (canDropResult) {
        case 'rarity':
          openSnackbar({
            message: t('Tournaments.placeCardRarityRestrictionAlert', {
              rarity: t(NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[tournament!.tournamentRarity]),
            }),
          });

          break;

        case 'broken':
          openSnackbar({
            message: t('Tournaments.placeCardRemainingLivesRestrictionAlert'),
          });

          break;

        case 'quantity':
          openSnackbar({
            message: `You can only place ${tournament?.joinCardsQuantity} number of cards`,
          });

          break;

        case true:
          const foundFullNft = nfts.find(
            (nft) => nft.token_id.toString() === item.tokenId.toString()
          )!;

          addNftToJoinTournament([foundFullNft]);

          break;

        default:
          return;
      }
    },
  });

  return {
    dropRef,
    isOver,
    isHighlightError,
    isHighlightInfo,
  };
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '~shared/lib/hooks';

import { Divider, Shimmer, Stack } from '~shared/ui';

import {
  DashboardStatsTableCell,
  DashboardStatsTableHeading,
  DashboardStatsTableRoot,
} from './styled';

import { DashboardStatsTableProps } from './types';

export const DashboardStatsTable: FC<DashboardStatsTableProps> = ({ stats, isLoading }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('sm');

  const divider = !isMobile && <Divider orientation="vertical" />;

  const renderCell = (value?: number) => {
    if (isLoading) {
      return (
        <Shimmer width={20} height={20}>
          <rect x="0" y="0" rx="12" ry="12" width={20} height={20} />
        </Shimmer>
      );
    }

    return <DashboardStatsTableCell>{value}</DashboardStatsTableCell>;
  };

  return (
    <DashboardStatsTableRoot>
      <Stack>
        <DashboardStatsTableHeading>{t('Other.total')}</DashboardStatsTableHeading>
        {renderCell(stats?.total)}
      </Stack>

      {divider}

      <Stack>
        <DashboardStatsTableHeading>{t('Other.future')}</DashboardStatsTableHeading>
        {renderCell(stats?.future)}
      </Stack>

      {divider}

      <Stack>
        <DashboardStatsTableHeading>{t('Other.wins')}</DashboardStatsTableHeading>
        {renderCell(stats?.wins)}
      </Stack>

      {divider}

      <Stack>
        <DashboardStatsTableHeading>{t('Other.loses')}</DashboardStatsTableHeading>
        {renderCell(stats?.losses)}
      </Stack>
    </DashboardStatsTableRoot>
  );
};

import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const ViewerWalletHeaderRoot = styled(Box, {
  name: 'ViewerWallet',
  slot: 'root',
})(({ theme, $enableMargin = true }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: $enableMargin ? 12 : 0,

  [theme.breakpoints.down('mobile')]: {
    marginBottom: $enableMargin ? 12 : 0,
  },
}));

import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Dialog, DialogTitle, NicknameField, Stack } from '~shared/ui';

import { useViewerModel } from '~entities/viewer';

import { useSetNicknameForm } from '../model';

export const SetNickname: FC = () => {
  const { t } = useTranslation();

  const { submit, submitAvailable, form } = useSetNicknameForm();
  const { setNicknameDialogOpen } = useViewerModel();

  return (
    <Dialog open={setNicknameDialogOpen}>
      <DialogTitle>{t('Other.introduce')}</DialogTitle>

      <FormProvider {...form}>
        <Stack spacing={24 / 8} component="form" onSubmit={submit}>
          <NicknameField autoFocus />

          <Button type="submit" disabled={submitAvailable}>
            {t('Other.continue')}
          </Button>
        </Stack>
      </FormProvider>
    </Dialog>
  );
};

import { useTranslation } from 'react-i18next';

import { getCallSameRarityMessage, isNftAvailableForMerge } from '~entities/nft';
import {
  checkWinstreakWarnDialogNotShown,
  useWinstreakWarningDialogSwitch,
} from '~features/event-dialog';
import { BetSlot, Nft, NftRarity } from '~shared/api';
import { useSnackbar } from '~shared/lib/hooks';

import { MAX_NFTS_ON_ONE_BET } from '../config';

interface CommonValidateParams {
  card: Nft;
  slot: BetSlot;
  onWinstreakWarningConfirm?: VoidFunction;
}

interface ValidateParams extends CommonValidateParams {
  cardsAmount?: number;
  callAcceptableRarity?: NftRarity;
}

interface ValidateWinstreakParams extends CommonValidateParams {}

interface ValidateCallAcceptableRarityParams {
  rarity: NftRarity;
  acceptableRarity: NftRarity | null;
}

export const useEventValidateCard = () => {
  const { openSnackbar } = useSnackbar();
  const { switchOn } = useWinstreakWarningDialogSwitch();
  const { t } = useTranslation();

  const validateWinstreak = async ({
    card,
    slot,
    onWinstreakWarningConfirm,
  }: ValidateWinstreakParams) => {
    const isWinstreakDialogNotShown = await checkWinstreakWarnDialogNotShown();

    const shouldOpenWinstreakWarning =
      !isWinstreakDialogNotShown && isNftAvailableForMerge(card).isAvailable;

    if (shouldOpenWinstreakWarning) {
      switchOn(card, slot, onWinstreakWarningConfirm);

      return false;
    }

    return true;
  };

  const isZeroLivesRemaining = (card: Nft) => card.livesRemaining === 0;

  const validateZeroLivesRemaining = (card: Nft) => {
    if (isZeroLivesRemaining(card)) {
      openSnackbar({ type: 'error', message: "You can't bet card with 0 lives remaining" });

      return false;
    }

    return true;
  };

  const isCallAcceptableRarity = ({
    rarity,
    acceptableRarity,
  }: ValidateCallAcceptableRarityParams) => {
    return typeof rarity === 'number' && acceptableRarity !== null && rarity === acceptableRarity;
  };

  const validateCallAcceptableRarity = ({
    rarity,
    acceptableRarity,
  }: ValidateCallAcceptableRarityParams) => {
    if (!isCallAcceptableRarity({ rarity, acceptableRarity })) {
      openSnackbar({ message: getCallSameRarityMessage(acceptableRarity!, t) });

      return;
    }

    return true;
  };

  const isMaxNftLimitReached = (cardsAmount: number) => cardsAmount > MAX_NFTS_ON_ONE_BET;

  const validateMaxCardsLimit = (cardsAmount: number) => {
    if (isMaxNftLimitReached(cardsAmount)) {
      openSnackbar({
        type: 'error',
        message: t('Errors.maxNftsOnOneBet', { limit: MAX_NFTS_ON_ONE_BET }),
      });

      return false;
    }

    return true;
  };

  const validateCard = async (params: ValidateParams) => {
    const isWinstreakValidated = await validateWinstreak(params);
    const isZeroLivesRemainingValidated = validateZeroLivesRemaining(params.card);
    const isMaxCardsLimitValidated =
      typeof params.cardsAmount !== 'undefined' ? validateMaxCardsLimit(params.cardsAmount) : true;

    const isCallAcceptableRarityValidated =
      typeof params.callAcceptableRarity !== 'undefined'
        ? validateCallAcceptableRarity({
            rarity: params.card.rarity,
            acceptableRarity: params.callAcceptableRarity,
          })
        : true;

    const validations = [
      isZeroLivesRemainingValidated,
      isMaxCardsLimitValidated,
      isWinstreakValidated,
      isCallAcceptableRarityValidated,
    ];

    return validations.every((validation) => validation);
  };

  return {
    isCallAcceptableRarity,
    isMaxNftLimitReached,
    isNftAvailableForMerge,
    isZeroLivesRemaining,

    validateCard,

    validateWinstreak,
    validateZeroLivesRemaining,
    validateCallAcceptableRarity,
    validateMaxCardsLimit,
  };
};

import { useCallback } from 'react';

import { useInitData } from '@vkruglikov/react-telegram-web-app';

import {
  ApiPostSignUpRequestData,
  postCheckEmail,
  postRecoveryPassword,
  postResendAuthCode,
  postSignUp,
  postSignUpVerification,
  postTonSignIn,
  postTonSignUp,
} from '~shared/api';
import { useDispatch } from '~shared/lib/hooks';

import { transformAvatarToViewerAvatar, useViewerModel } from '~entities/viewer';
import { useInventoryModel } from '~widgets/inventory';

import {
  TIMER_ESTIMATE_DELAY,
  TIMER_ESTIMATE_RECORD_KEY,
  createTimerEstimateRecord,
  getTimerEstimateRecordDelay,
} from '../lib';

import { authActions } from './slice';
import { useAuthSelector } from './selectors';
import { AuthSteps } from './types';

const { open, close, updateData, changeStep } = authActions;

export const useAuthModel = () => {
  const { authorize, verification: viewerVerification } = useViewerModel();
  const { updateInventoryItem } = useInventoryModel();
  const { step, ...state } = useAuthSelector();
  const dispatch = useDispatch();
  const [, initData] = useInitData();

  const setStep = useCallback(
    (callback: (steps: typeof AuthSteps) => AuthSteps) => {
      dispatch(changeStep(callback(AuthSteps)));
    },
    [dispatch]
  );

  const onOpen = useCallback(() => {
    dispatch(open());
  }, [dispatch]);

  const onClose = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  const identify = useCallback(
    (data: { email: string }) => {
      dispatch(updateData({ email: data.email }));

      return postCheckEmail(data).then(({ registered, nickname, avatar }) => {
        dispatch(
          updateData({
            nickname,
            avatar: avatar ? transformAvatarToViewerAvatar(avatar).src : '',
          })
        );

        if (registered) {
          setStep((steps) => steps.Authentication);
        } else {
          setStep((steps) => steps.IntroduceYourself);
        }
      });
    },
    [dispatch, setStep]
  );

  const signIn = useCallback(
    (data: { login: string; password: string }) => {
      return authorize(data)
        .then(({ verified }) => {
          if (verified) {
            onClose();
          } else {
            setStep((steps) => steps.VerificationCode);
          }
        })
        .catch(() => {
          return false;
        });
    },
    [authorize, onClose, setStep]
  );

  const signUp = useCallback(
    async (data: ApiPostSignUpRequestData, captchaV2Token?: string) => {
      if (captchaV2Token) {
        data.captchaToken2 = captchaV2Token;
      } else {
        data.captchaToken = await window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY,
          { action: 'sign_up_click' }
        );
      }

      const { result } = await postSignUp(data);

      if (result === 'new') {
        authorize({ login: data.email, password: data.password });
      }

      return { result };
    },
    [authorize]
  );

  const tonSignUp = useCallback(async () => {
    const { result } = await postTonSignUp({ data: initData! });

    return { result };
  }, [initData]);

  const tonSignIn = useCallback(async () => {
    const { result } = await postTonSignIn({ data: initData! });

    return { result };
  }, [initData]);

  const renderV2Captcha = useCallback(
    (params: {
      container: HTMLElement;
      setToken: (token: string) => void;
      omitExpiredToken: () => void;
    }) => {
      window.grecaptcha.render(params.container!, {
        'sitekey': process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY,
        'callback': params.setToken,
        'expired-callback': params.omitExpiredToken,
        'theme': 'dark',
      });
    },
    []
  );

  const recoveryPassword = useCallback(
    (data: { email: string }) => {
      if (!getTimerEstimateRecordDelay(TIMER_ESTIMATE_RECORD_KEY)) {
        return postRecoveryPassword(data).then(() => {
          createTimerEstimateRecord(TIMER_ESTIMATE_DELAY, TIMER_ESTIMATE_RECORD_KEY);
          setStep((steps) => steps.RecoveryLink);
        });
      }

      return new Promise((resolve) => resolve({})).then(() => {
        setStep((steps) => steps.RecoveryLink);
      });
    },
    [setStep]
  );

  const verification = useCallback(
    (data: { email: string; authCode: number }): Promise<boolean> => {
      return postSignUpVerification(data)
        .then(() => {
          viewerVerification();

          return true;
        })
        .catch(() => false);
    },
    [viewerVerification]
  );

  const openFreebetDialog = () => {
    dispatch(authActions.openFreebetDialog());
  };

  const closeFreebetDialog = () => {
    dispatch(authActions.closeFreebetDialog());
  };

  const resendVerification = useCallback((data: { email: string }) => {
    return postResendAuthCode(data);
  }, []);

  const submitNickname = useCallback(
    (nickname: string) => {
      dispatch(updateData({ nickname }));
      setStep((steps) => steps.CreatePassword);
    },
    [dispatch, setStep]
  );

  return {
    identify,
    signIn,
    signUp,
    tonSignUp,
    tonSignIn,
    renderV2Captcha,
    recoveryPassword,
    verification,
    resendVerification,

    closeFreebetDialog,
    openFreebetDialog,

    submitNickname,

    step,
    setStep,

    onOpen,
    onClose,

    ...state,
  };
};

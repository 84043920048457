import { motion } from 'framer-motion';

import { styled } from '~shared/lib/styles';

export const viewerWalletReturnDropZoneClasses = {
  root: 'ViewerWalletReturnDropZone',
  highlight: 'ViewerWalletReturnDropZone-highlight',
};

export const Root = styled(motion.div, { name: 'ViewerWalletReturnDropZone' })(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    height: 0,

    overflow: 'hidden',
    zIndex: theme.zIndex.modalOverlap,

    background: theme.palette.primary.light,
    border: `1px solid ${theme.palette.grey.A20}`,
    borderRadius: 6,
    transition: theme.transitions.create(['background', 'box-shadow']),

    [`&.${viewerWalletReturnDropZoneClasses.highlight}`]: {
      boxShadow: '0px 0px 9.75px rgba(46, 178, 255, 0.8)',
      background: theme.palette.primary.main,
    },
  };
});

import { Address } from '@ton/core';

import { tonApiInstance } from '../apiInstance';

import { ConsumablesRoutes } from './routes';
import {
  ApiFreeMakeBet,
  // ApiFreeMakeBetResponse,
  ApiFreeMerge,
  // ApiFreeMergeResponse,
  ApiFreeRepair,
  ApiFreeUnfreeze,
  // ApiFreeRepairResponse,
  ApiGetAllConsumables,
  ApiGetAllConsumablesResponse,
} from './types';

export const getAllConsumables: ApiGetAllConsumables = (params) => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .get<ApiGetAllConsumablesResponse>(ConsumablesRoutes.consumables(), {
        params: { address: Address.normalize(params.address) },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const freeRepair: ApiFreeRepair = (data) => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .post(ConsumablesRoutes.freeRepair(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const freeMerge: ApiFreeMerge = (data) => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .post(ConsumablesRoutes.freeMerge(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const freeMakeBet: ApiFreeMakeBet = (data) => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .post(ConsumablesRoutes.freeMakeBet(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const freeUnfreeze: ApiFreeUnfreeze = (data) => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .post(ConsumablesRoutes.freeUnfreeze(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

import { useEventModel } from '~entities/event';
import { useVoteChoiceDialog } from '~features/event-dialog/model';
import { Button, Dialog, Icon, Stack, Typography } from '~shared/ui';

import { useInventorySelector } from '~widgets/inventory';

export const VoteChoiceDialog = () => {
  const { onVote } = useEventModel();
  const { closeVoteChoiceDialog, open } = useVoteChoiceDialog();
  const { freeBet } = useInventorySelector();

  return (
    <Dialog open={open} onClose={closeVoteChoiceDialog}>
      <Stack justifyContent="center" alignItems="center" width="100%">
        <Typography fontWeight={700} mb="20px">
          Vote
        </Typography>
        <Stack gap="10px" width="100%">
          <Button flex={1} size="sm" onClick={() => onVote(false)}>
            <Stack flexDirection="row" alignItems="center" justifyContent="center" gap="8px">
              <Icon name="coin-ton" />
              <Typography>TON</Typography>
            </Stack>
          </Button>
          <Button
            flex={1}
            size="sm"
            variant="outlined"
            onClick={() => onVote(true)}
            disabled={freeBet === 0}
          >
            <Stack flexDirection="row" alignItems="center" justifyContent="center" gap="8px">
              <Icon name="freebet" />
              <Typography>Freebet</Typography>
            </Stack>
          </Button>
          <Typography textAlign="center" color="grey.A50">
            {freeBet} freebets left
          </Typography>
        </Stack>
      </Stack>
    </Dialog>
  );
};

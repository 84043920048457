import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const WinstreakIllustrationContainer = styled(Box, {
  name: 'FullWinstreakWarningDialog',
  slot: 'winstreakIllustrationContainer',
})(({ theme }) => ({
  position: 'absolute',
  right: '-42px',
  bottom: '-19px',
  zIndex: 2,
  display: 'flex',
  borderRadius: '18px',
  width: '162px',

  ['& > :not(:last-child)']: {
    marginRight: '18px',
  },

  padding: '10px',
  background: theme.palette.paper.A75,
}));

import { FC, Fragment } from 'react';
import { Outlet } from 'react-router';
import { useThrottle } from 'react-use';

import { Preloader } from '~shared/ui';

import { useViewerModel } from '~entities/viewer';
import { useBackendTonAuth } from '~entities/wallet';

import { useViewerInitialization } from '~processes/viewer';
import { useViewerWalletInitialization } from '~processes/nft';
import { useNotificationsInitialization } from '~processes/notifications';
import { useReferralVerification } from '~features/referral';
// TODO: fix import later
import { useSetProgressDailyQuestQuery } from '~widgets/daily-quest-reward-dialog/model/hooks';

export const PrivateRoute: FC = () => {
  useViewerWalletInitialization();
  useNotificationsInitialization();
  useViewerInitialization();
  useReferralVerification();
  useBackendTonAuth();
  useSetProgressDailyQuestQuery();

  const { sessionTokenChecked } = useViewerModel();

  // smooth disappear animation
  const preloaderOpen = useThrottle(!sessionTokenChecked, 150);

  return (
    <Fragment>
      <Outlet />

      <Preloader open={preloaderOpen} />
    </Fragment>
  );
};

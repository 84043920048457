import { FC, useEffect } from 'react';

import { CircularProgressView } from '~shared/ui';

import { EventDialogStreamSportRadarRoot } from './styled';

import { EventDialogStreamSportRadarProps } from './types';

import './sr-broadcast-theme.css';

export const EventDialogStreamSportRadar: FC<EventDialogStreamSportRadarProps> = ({
  broadcastId,
}) => {
  const elementId = getSportRadatrBroadcastElementId(broadcastId);

  useEffect(() => {
    window.SIR('addWidget', `#${elementId}`, 'match.lmtPlus', {
      matchId: broadcastId,
    });
  }, [broadcastId, elementId]);

  return (
    <EventDialogStreamSportRadarRoot id={elementId}>
      <CircularProgressView />
    </EventDialogStreamSportRadarRoot>
  );
};

const getSportRadatrBroadcastElementId = (broadcastId: string) => {
  return `sr-event-broadcast-${broadcastId}`;
};

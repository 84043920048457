import { FC } from 'react';

import { Button } from '~shared/ui';

import {
  TournamentNoParticipants,
  TournamentParticipant,
  getTournamentStatus,
} from '~entities/tournament';
import { useViewerModel } from '~entities/viewer';

import { JoinTournamentButton } from '../JoinTournamentButton';

import { JoinTournamentComplexButtonProps } from './types';

export const JoinTournamentComplexButton: FC<JoinTournamentComplexButtonProps> = ({
  tournament,

  ...buttonProps
}) => {
  const { tonAddress } = useViewerModel();

  const { players, endDate, startDate, playersLimit } = tournament;

  const status = getTournamentStatus(startDate, endDate);
  const viewerPlayer = players.find((player) => player.tonWallet === tonAddress);

  const firstPlacePlayer = players.find((player) => player.place === 1);

  const isFull = playersLimit === players.length;
  const isViewerJoined = Boolean(viewerPlayer);
  const isAnyoneJoined = Boolean(players.length);

  switch (status) {
    case 'soon':
      switch (true) {
        case isViewerJoined:
          return (
            <Button {...buttonProps} variant="outlined" disabled>
              Already joined
            </Button>
          );
        case isFull:
          return (
            <Button {...buttonProps} variant="outlined" disabled>
              Tournament is full
            </Button>
          );
        default:
          return <JoinTournamentButton {...buttonProps} tournament={tournament} />;
      }

    default:
      switch (true) {
        case isViewerJoined:
          return (
            <TournamentParticipant
              {...buttonProps}
              avatar={viewerPlayer!.avatar}
              place={viewerPlayer!.place}
              name={viewerPlayer!.name}
            />
          );
        case isAnyoneJoined:
          return (
            <TournamentParticipant
              {...buttonProps}
              avatar={firstPlacePlayer!.avatar}
              place={firstPlacePlayer!.place}
              name={firstPlacePlayer!.name}
            />
          );
        default:
          return <TournamentNoParticipants {...buttonProps} />;
      }
  }
};

import { useTonAddress } from '@tonconnect/ui-react';
import { getTime, subMonths } from 'date-fns';
import { useState } from 'react';

import { useQueryViewerDashboard, useViewerSelector } from '~entities/viewer';
import { ApiGetViewerDashboardRequestParams } from '~shared/api';

import { SelectValue } from '~shared/ui';
import { RANKING_ALL_TIME_QUERY_PERIOD_TS } from '~widgets/leaderboard-list';

import { DashboardQueryPeriod } from './types';

export const useProfileDashboardModel = () => {
  const [params, setParams] = useState<ApiGetViewerDashboardRequestParams>({});
  const { wallet, tonAddress } = useViewerSelector();
  const [battlesPeriod, setBattlesPeriod] = useState<DashboardQueryPeriod>('all');
  const [callsPeriod, setCallsPeriod] = useState<DashboardQueryPeriod>('all');

  const dashboardQuery = useQueryViewerDashboard({ address: wallet, tonAddress, params });

  const handleChangePeriodFactory =
    (betType: 'battles' | 'calls') =>
    (_: MouseEvent | KeyboardEvent | FocusEvent | null, value: SelectValue | null) => {
      const setter = betType === 'battles' ? setBattlesPeriod : setCallsPeriod;

      setter(value as any);

      if (value === 'week') {
        setParams((prevParams) => ({
          ...prevParams,
          [`${betType}ForWeek`]: true,
          [`${betType}Timestamp`]: undefined,
        }));

        return;
      }

      setParams((prevParams) => ({
        ...prevParams,
        [`${betType}ForWeek`]: false,
        [`${betType}Timestamp`]: getTimestamp(value as any),
      }));
    };

  const getTimestamp = (period: DashboardQueryPeriod) => {
    if (period === 'all') {
      return RANKING_ALL_TIME_QUERY_PERIOD_TS;
    }

    // * getting month ago timestamp
    const currentDate = new Date();

    return Math.floor(getTime(subMonths(currentDate, 1)) / 1000);
  };

  return {
    battlesPeriod,
    handleChangeBattlesPeriod: handleChangePeriodFactory('battles'),

    callsPeriod,
    handleChangeCallsPeriod: handleChangePeriodFactory('calls'),

    dashboardQuery,
  };
};

import { tonApiInstance } from '../apiInstance';

import { DailyQuestRoutes } from './routes';
import {
  ApiGetUserProgress,
  ApiGetUserProgressResponse,
  ApiPostUpdateProgress,
  ApiPostUpdateProgressResponse,
} from './types';

export const getUserProgress: ApiGetUserProgress = (params) => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .get<ApiGetUserProgressResponse>(DailyQuestRoutes.progress(), { params })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postSetUserProgress: ApiPostUpdateProgress = () => {
  return new Promise((resolve, reject) => {
    tonApiInstance
      .post<ApiPostUpdateProgressResponse>(DailyQuestRoutes.progress())
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Nft } from '~shared/api';

import { ViewerWalletNftRarityFilter, ViewerWalletTab } from './types';

interface ViewerWalletState {
  selectedCards: Map<string, Nft>;
  rarityFilters: Set<ViewerWalletNftRarityFilter>;
  walletTab: ViewerWalletTab;
  isTournamentWalletTabAlreadyToggled: boolean;
}

const initialState: ViewerWalletState = {
  selectedCards: new Map(),
  rarityFilters: new Set([ViewerWalletNftRarityFilter.All]),
  walletTab: ViewerWalletTab.All,
  isTournamentWalletTabAlreadyToggled: false,
};

export const viewerWalletSlice = createSlice({
  name: 'viewerWallet',
  initialState,
  reducers: {
    toggleRarityFilter: (state, action: PayloadAction<ViewerWalletNftRarityFilter>) => {
      const targetTab = action.payload;

      if (targetTab === ViewerWalletNftRarityFilter.All) {
        state.rarityFilters.clear();
        state.rarityFilters.add(ViewerWalletNftRarityFilter.All);
      } else {
        if (state.rarityFilters.has(targetTab)) {
          state.rarityFilters.delete(targetTab);

          if (state.rarityFilters.size === 0) {
            state.rarityFilters.add(ViewerWalletNftRarityFilter.All);
          }
        } else {
          state.rarityFilters.delete(ViewerWalletNftRarityFilter.All);
          state.rarityFilters.add(targetTab);
        }
      }
    },
    toggleSelectedCard: (state, action: PayloadAction<Nft>) => {
      const nft = action.payload;

      state.selectedCards.has(nft.token_id)
        ? state.selectedCards.delete(nft.token_id)
        : state.selectedCards.set(nft.token_id, nft);
    },
    clearSelectedCards: (state) => {
      state.selectedCards.clear();
    },
    selectWalletTab: (state, action: PayloadAction<ViewerWalletTab>) => {
      state.walletTab = action.payload;
    },
    markTournamentWalletTabAlreadyToggled: (state) => {
      state.isTournamentWalletTabAlreadyToggled = true;
    },

    reset: () => {
      return { ...initialState };
    },
  },
});

import { ApiCommonSport, ApiCommonSportEnum } from '~shared/api';
import { routes } from '~shared/config';

import { SportItemConfig } from './types';
import { SportsIllustrations } from './assets';

export const SportsConfig: Record<ApiCommonSport, SportItemConfig> = {
  [ApiCommonSportEnum.AmericanFootball]: {
    id: 'american-football',
    route: routes.sports.americanFootball,
    sport: ApiCommonSportEnum.AmericanFootball,
    label: 'NFL',
    icon: 'sport-american-football',
    illustration: SportsIllustrations.americanFootball,
    dates: null,
    league: null,
    tournament: null,
  },
  [ApiCommonSportEnum.Baseball]: {
    id: 'baseball',
    route: routes.sports.baseball,
    sport: ApiCommonSportEnum.Baseball,
    label: 'Baseball',
    icon: 'sport-baseball',
    illustration: SportsIllustrations.baseball,
    dates: null,
    league: null,
    tournament: null,
  },
  [ApiCommonSportEnum.Basketball]: {
    id: 'basketball',
    route: routes.sports.basketball,
    sport: ApiCommonSportEnum.Basketball,
    label: 'Basketball',
    icon: 'sport-basketball',
    illustration: SportsIllustrations.basketball,
    dates: null,
    league: null,
    tournament: null,
  },
  [ApiCommonSportEnum.Cricket]: {
    id: 'cricket',
    route: routes.sports.cricket,
    sport: ApiCommonSportEnum.Cricket,
    label: 'Cricket',
    icon: 'sport-cricket',
    illustration: SportsIllustrations.cricket,
    dates: null,
    league: null,
    tournament: null,
  },
  [ApiCommonSportEnum.Football]: {
    id: 'football',
    route: routes.sports.football,
    sport: ApiCommonSportEnum.Football,
    label: 'Football',
    icon: 'sport-football',
    illustration: SportsIllustrations.football,
    dates: null,
    league: null,
    tournament: null,
  },
  [ApiCommonSportEnum.Hockey]: {
    id: 'hockey',
    route: routes.sports.hockey,
    sport: ApiCommonSportEnum.Hockey,
    label: 'Hockey',
    icon: 'sport-hockey',
    illustration: SportsIllustrations.hockey,
    dates: null,
    league: null,
    tournament: null,
  },
  [ApiCommonSportEnum.MMA]: {
    id: 'mma',
    route: routes.sports.mma,
    sport: ApiCommonSportEnum.MMA,
    label: 'MMA',
    icon: 'sport-mma',
    illustration: SportsIllustrations.mma,
    dates: null,
    league: null,
    tournament: null,
  },
  [ApiCommonSportEnum.Tennis]: {
    id: 'tennis',
    route: routes.sports.tennis,
    sport: ApiCommonSportEnum.Tennis,
    label: 'Tennis',
    icon: 'sport-tennis',
    illustration: SportsIllustrations.tennis,
    dates: null,
    league: null,
    tournament: null,
  },
  [ApiCommonSportEnum.CS2]: {
    id: 'cs2',
    route: routes.sports.cs2,
    sport: ApiCommonSportEnum.CS2,
    label: 'CS 2',
    icon: 'sport-cs2',
    illustration: SportsIllustrations.eSport,
    dates: null,
    league: null,
    tournament: null,
  },
  [ApiCommonSportEnum.Dota]: {
    id: 'dota',
    route: routes.sports.dota,
    sport: ApiCommonSportEnum.Dota,
    label: 'Dota 2',
    icon: 'sport-dota',
    illustration: SportsIllustrations.eSport,
    dates: null,
    league: null,
    tournament: null,
  },
  [ApiCommonSportEnum.LeagueOfLegends]: {
    id: 'league-of-legends',
    route: routes.sports.leagueOfLegends,
    sport: ApiCommonSportEnum.LeagueOfLegends,
    label: 'League of Legends',
    icon: 'sport-league-of-legends',
    illustration: SportsIllustrations.eSport,
    dates: null,
    league: null,
    tournament: null,
  },
};

import { useEffect } from 'react';
import { format } from 'date-fns';

import { useEventModel } from '~entities/event';

export const useEventEasterEgg = () => {
  const { event, isOpen } = useEventModel();

  useEffect(() => {
    if (event && isOpen) {
      const handler = (keyboardEvent: KeyboardEvent) => {
        const { ctrlKey, metaKey, key } = keyboardEvent;

        if (ctrlKey && metaKey && key === 'e') {
          const homeTeamName = event.homeTeam.name;
          const awayTeamName = event.awayTeam.name;

          const formattedEventTime = format(event.date, 'dd/MM/yyyy');

          const searchString = `${homeTeamName} vs ${awayTeamName} ${formattedEventTime} prediction`;
          const searchParam = new URLSearchParams();
          searchParam.set('q', searchString);

          window.open(new URL('?' + searchParam.toString(), 'https://google.com/search'), '_blank');
        }
      };

      document.addEventListener('keydown', handler);

      return () => document.removeEventListener('keydown', handler);
    }
  });

  return;
};

import { useTranslation } from 'react-i18next';
import { FC } from 'react';

import { Stack } from '~shared/ui';

import { useEventModel } from '~entities/event';

import { EventDialogStatisticsRow } from './EventDialogStatisticsRow';

import { EventDialogStatisticsProps } from './types';

export const EventDialogStatistics: FC<EventDialogStatisticsProps> = () => {
  const { t } = useTranslation();

  const { eventStatisticsEntries } = useEventModel();

  if (eventStatisticsEntries.length === 0) {
    return null;
  }

  return (
    <Stack spacing={20 / 8} py={2}>
      {eventStatisticsEntries.map((row) => (
        <EventDialogStatisticsRow {...row} key={row.key} label={t(row.label)} />
      ))}
    </Stack>
  );
};

import { Backdrop, makeBackdropTransition } from '~shared/ui';

import { useDebouncedBooleanTransition } from '~shared/lib/hooks';
import { useTheme } from '~shared/lib/styles';

import { useBattleModel } from '~entities/battle';

const BackdropTransition = makeBackdropTransition(Backdrop, true);

export const NftDragBackdropConstructor = () => {
  const theme = useTheme();

  const debouncedIsOverlapBackdrop = useDebouncedBooleanTransition(
    useBattleModel().isOverlapBackdrop
  );

  return (
    <BackdropTransition
      open={debouncedIsOverlapBackdrop}
      style={{
        zIndex: theme.zIndex.modal,
        pointerEvents: debouncedIsOverlapBackdrop ? 'auto' : 'none',
      }}
    />
  );
};

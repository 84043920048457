import { FC } from 'react';

import { ToggleButtonPillContainerRoot } from './styled';

import { ToggleButtonPillContainerProps } from './types';

export const ToggleButtonPillContainer: FC<ToggleButtonPillContainerProps> = ({
  ...htmlAttributes
}) => {
  return <ToggleButtonPillContainerRoot {...htmlAttributes} />;
};

import { Dispatch, FC, SetStateAction } from 'react';

import { useViewerSelector } from '~entities/viewer';

import { Avatar, Button, Stack, Typography } from '~shared/ui';

import { useDailyQuestRewardModel } from '../model/model';
import { useDailyQuestRewardSelector } from '../model/selectors';

import { getImageUrl } from '../lib/getImageUrl';
import { formatDailyQuestKey } from '../lib/formatDailyQuestKey';

import {
  AdditionalBlock,
  AvatarImageShadow,
  AvatarImageWrapper,
  Count,
  DailyQuestDialog,
  GiftImage,
  GiftWrapper,
  ProcessBar,
  ProcessBarGift,
  StyledDialog,
} from './styled';

interface DayBannerProps {
  day: number;
  onButtonClick: Dispatch<SetStateAction<boolean>>;
}

interface FirstDayBannerProps {
  onButtonClick: Dispatch<SetStateAction<boolean>>;
}

export const DailyQuestRewardDialog: FC = () => {
  const { closeModal } = useDailyQuestRewardModel();
  const { countedDays, allDays, isOpen } = useDailyQuestRewardSelector();

  return (
    <StyledDialog open={isOpen} onClose={closeModal} width={340}>
      <DailyQuestDialog>
        <AdditionalBlock />
        <Typography color="secondary" zIndex={1} fontWeight={400} fontSize={14} mb={2.5}>
          Completed {countedDays}/{allDays}
        </Typography>
        {countedDays === 0 ? (
          <FirstDayBanner onButtonClick={closeModal} />
        ) : (
          <DayBanner day={countedDays} onButtonClick={closeModal} />
        )}
      </DailyQuestDialog>
    </StyledDialog>
  );
};

const FirstDayBanner: FC<FirstDayBannerProps> = ({ onButtonClick }) => {
  const { avatar } = useViewerSelector();

  return (
    <>
      <Typography zIndex={1} fontSize={22} fontWeight={700} textAlign="center" lineHeight={1.2}>
        Wow <br /> First day
      </Typography>
      <GiftWrapper>
        <AvatarImageWrapper>
          <AvatarImageShadow />
          <Avatar src={avatar?.src} size={70} zIndex={2} />
        </AvatarImageWrapper>
        <ProcessBar />
        <ProcessBarGift />
      </GiftWrapper>
      <Typography
        color="secondary"
        fontSize={14}
        lineHeight={1.3}
        textAlign="center"
        fontWeight={500}
      >
        Congratulations on your path to success! Complete daily tasks every day and receive valuable
        prizes from MainCard.
      </Typography>
      <Stack display="flex !important" flexDirection="row" gap="15px">
        <Button width={180} mt={2.5} onClick={() => onButtonClick(false)}>
          Ok, Thanks
        </Button>
      </Stack>
    </>
  );
};

const DayBanner: FC<DayBannerProps> = ({ day, onButtonClick }) => {
  const { dailyQuest } = useDailyQuestRewardSelector();

  if (!dailyQuest) {
    return;
  }

  return (
    <>
      <Typography zIndex={1} fontSize={22} fontWeight={700} textAlign="center" lineHeight={1.2}>
        New gift for you! <br /> {formatDailyQuestKey(dailyQuest.key)}
      </Typography>
      <GiftWrapper>
        <GiftImage src={getImageUrl(dailyQuest.image)} alt="" />
        <ProcessBar />
        <Count>
          <Typography fontSize={26} lineHeight={1.17} textAlign="center" fontWeight={700}>
            x{dailyQuest.amount}
          </Typography>
        </Count>
      </GiftWrapper>
      <Typography
        color="secondary"
        fontSize={14}
        lineHeight={1.3}
        textAlign="center"
        fontWeight={500}
        width="80%"
      >
        Congratulations! You have reached the {day} day of daily tasks, you will receive a gift -{' '}
        {formatDailyQuestKey(dailyQuest.key)}
      </Typography>
      <Stack display="flex !important" flexDirection="row" gap="15px">
        <Button width={180} mt={2.5} onClick={() => onButtonClick(false)}>
          Ok, Thanks
        </Button>
      </Stack>
    </>
  );
};

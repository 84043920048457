import { FC, Fragment, SyntheticEvent, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AvatarAction, Divider, Link, Menu, MenuItem } from '~shared/ui';
import { useMatchRoute, useSwitcher } from '~shared/lib/hooks';
import { routes } from '~shared/config';

import { useViewerModel } from '~entities/viewer';

import { useOnboardingModel } from '~features/onboarding';
import { SignOutButtonWrapper } from '~features/viewer/sign-out';

import { useContactUsModel } from '~widgets/contact-us';

import { UseReferral } from '~widgets/referal';

import { UserAreaProps } from './types';

export const UserArea: FC<UserAreaProps> = ({ showOnboardingMenuItem, size = 48 }) => {
  const { t } = useTranslation();
  const profileRef = useRef(null);
  const profileMenu = useSwitcher(false);
  const isProfileRoute = useMatchRoute(routes.profile);
  const { handleOpen } = UseReferral();

  const { authorized, avatar } = useViewerModel();
  const { onOpen: openOnboarding } = useOnboardingModel();

  const { contactUsDialog } = useContactUsModel();
  const handleMenuItemClick = useCallback(
    (callback?: Function) => (event: SyntheticEvent) => {
      profileMenu.switchOff();

      if (callback) {
        return callback(event);
      }
    },
    [profileMenu]
  );

  if (!authorized) {
    return null;
  }

  return (
    <Fragment>
      <AvatarAction
        ref={profileRef}
        src={avatar?.src}
        size={size}
        onClick={profileMenu.switchOn}
        selected={isProfileRoute || profileMenu.value}
        selectable
      />

      <Menu
        anchorEl={profileRef.current}
        open={profileMenu.value}
        onClose={profileMenu.switchOff}
        slotProps={menuSlotProps}
      >
        <Link to={routes.profile} onClick={handleMenuItemClick()} underline={false}>
          <MenuItem>{t('Menu.profile')}</MenuItem>
        </Link>

        {showOnboardingMenuItem && (
          <MenuItem onClick={handleMenuItemClick(() => openOnboarding('1'))}>
            {t('Other.showOnBoarding')}
          </MenuItem>
        )}

        <MenuItem onClick={contactUsDialog.switchOn}>{t('Menu.contactUs')}</MenuItem>
        <MenuItem onClick={handleOpen}>Reffer a friend</MenuItem>

        <Divider />

        <SignOutButtonWrapper>
          <MenuItem />
        </SignOutButtonWrapper>
      </Menu>
    </Fragment>
  );
};

const menuSlotProps = { root: { modifiers: [{ name: 'offset', options: { offset: [0, 8] } }] } };

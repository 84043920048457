import { styled } from '~shared/lib/styles';

export const BestAuctionListContainer = styled('div')(({ theme }) => ({
  width: '394px',
  maxWidth: 'calc(-36px + 100vw)',
  height: '176px',
  background: theme.palette.paper.paulo,
  borderRadius: '12px',
  overflow: 'hidden',
  display: 'flex',
  paddingTop: '5px',
}));

export const BestAuctionRightContainer = styled('div')({
  width: '66%',
  marginRight: '10px',
});

export const AuctionSellCardBets = styled('div')({
  width: '100%',
  marginTop: '10px',
});

export const AuctionBettorBrief = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const RareInfoCard = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: '5px',
});

export const CardInfo = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10px',
  marginBottom: '10px',
});

export const CardInfoBlock = styled('div')({});

export const BidContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

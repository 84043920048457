import { styled } from '~shared/lib/styles';

import { Box } from '../../layout';
import { Typography } from '../Typography';

import { ChipColor } from './types';

type ChipRootProps = {
  color: ChipColor;
};

export const ChipRoot = styled(Box, { name: 'Chip', slot: 'root' })<ChipRootProps>(({
  color,
  theme,
}) => {
  const CHIP_COLOR: Record<ChipColor, string> = {
    primary: theme.palette.primary.darker,
    secondary: theme.palette.violet.main,
    success: theme.palette.success.main,
  };

  return {
    padding: '1px 6px',
    backgroundColor: CHIP_COLOR[color],
    boxShadow: '0px 4.19688px 4.19688px rgba(0, 0, 0, 0.25)',
    borderRadius: '12.5px',
    display: 'inline-block',
    margin: 0,
  };
});

export const ChipLabel = styled(Typography, { name: 'Chip', slot: 'label' })(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

import { styled } from '~shared/lib/styles';
import { Box, Typography } from '~shared/ui';

export const NftBlockedRoot = styled(Box, { name: 'NftBlocked' })(({ theme }) => ({
  position: 'absolute',
  top: '52%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  alignItems: 'center',
  width: '100%',
  color: theme.palette.text.primary,
}));

export const NftBlockedText = styled(Typography, { name: 'NftBlocked', slot: 'text' })({
  textAlign: 'center',
  lineHeight: '13px',
  fontWeight: 700,
});

import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { BaseTextField, Button, Dialog, Link, Stack, Typography } from '~shared/ui';
import { sleep } from '~shared/lib/utils';

import { useContactUsModel } from '../model';

const validationSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Wrong email format'),
  subject: yup.string().required('Subject is required'),
  text: yup.string().required('Message is required'),
});

export const ContactUs = () => {
  const { t } = useTranslation();

  const { contactUsDialog, handlePostFeedback } = useContactUsModel();

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: '', subject: '', text: '' },
  });

  const handleClose = () => {
    contactUsDialog.switchOff();
    sleep<{}>(200).then(form.reset);
  };

  return (
    <Dialog open={contactUsDialog.open} onClose={handleClose} width={440}>
      <Stack spacing={24 / 8}>
        <Typography variant="h1" textAlign="center">
          {t('ContactUs.provide')}
        </Typography>

        <Typography color="secondary" align="center">
          {t('ContactUs.text')}
        </Typography>

        <FormProvider {...form}>
          <Stack component="form" spacing={24 / 8} onSubmit={form.handleSubmit(handlePostFeedback)}>
            <BaseTextField name="subject" label="Subject" />
            <BaseTextField name="email" label="Email" />
            <BaseTextField name="text" label={t('ContactUs.start')} multiline rows={6} />

            <Button type="submit">{t('ContactUs.send')}</Button>
          </Stack>
        </FormProvider>

        <Typography color="secondary" align="center">
          <Trans i18nKey="ContactUs.sendUsYourQuestions">
            Envíanos tus preguntas o sugerencias por correo electrónico a{' '}
            <Link to="mailto:support@maincard.io" color={(theme) => theme.palette.primary.main}>
              support@maincard.io
            </Link>
          </Trans>
        </Typography>
      </Stack>
    </Dialog>
  );
};

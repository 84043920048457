export const palette = {
  mode: 'dark' as const,

  primary: {
    main: '#029CF5',
    light: '#2EB2FF',
    dark: 'rgba(2, 156, 245, 0.5)',
    soft: '#AEBACB',
    contrastText: '#EFEFEF',
    darker: '#1C2F29',
  },
  paper: {
    main: '#0B0B0B',
    secondary: '#020202',

    paulo: '#1A1D20',
    dark: '#181818',
    semi: '#231D27',
    light: '#4A4A4A',
    grey: '#2C2F39',
    A75: '#262626',
    black: '#000000',
    A80: '#1A1D20',

    A90: 'rgba(11, 11, 11, 0.9)',

    contrastText: '#EFEFEF',
  },
  text: {
    primary: '#EFEFEF',
    secondary: 'rgba(255, 255, 255, 0.75)',
  },
  grey: {
    A75: 'rgba(255, 255, 255, 0.75)',
    A50: 'rgba(255, 255, 255, 0.5)',
    A30: 'rgba(255, 255, 255, 0.3)',
    A20: 'rgba(255, 255, 255, 0.2)',
    A10: 'rgba(255, 255, 255, 0.1)',
    A5: 'rgba(255, 255, 255, 0.05)',

    B40: 'rgba(0, 0, 0, 0.4)',
    B50: 'rgba(0, 0, 0, 0.5)',
    B80: 'rgba(0, 0, 0, 0.8)',
    B90: 'rgba(0, 0, 0, 0.9)',
  },
  violet: {
    main: '#9B1596',
    dark: '#231D27',
  },

  error: {
    main: '#F05D5D',
    dark: '#C40707',

    contrastText: '#EFEFEF',
  },
  success: {
    main: '#40CAA0',

    contrastText: '#EFEFEF',
  },
  info: {
    main: '#029CF5',

    contrastText: '#EFEFEF',
  },
  warn: {
    main: '#F57C00',
    secondary: '#FF922D',
  },
  rarity: {
    common: '#68737D',
    rare: '#449926',
    epic: '#1DA2BF',
    mythic: '#8326E0',
    legendary: '#E12350',
  },
};

export type Palette = typeof palette;

import { ToggleButtonPillContainer } from '~shared/ui';
import { styled } from '~shared/lib/styles';

export const ViewerWalletRarityFiltersContainer = styled(ToggleButtonPillContainer)(
  ({ theme }) => ({
    marginBottom: 8,

    [theme.breakpoints.down('mobile')]: {
      marginBottom: 16,
    },
  })
);

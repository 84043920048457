import { Badge as MuiBaseBadge, badgeClasses as muiBaseBadgeClasses } from '@mui/base';

import { styled } from '~shared/lib/styles';

export const badgeClasses = {
  ...muiBaseBadgeClasses,
  error: 'Badge-error',
  info: 'Badge-info',
  success: 'Badge-success',

  default: 'Badge-default',
  dot: 'Badge-dot',
};

export const UIKitBadge = styled(MuiBaseBadge)(({ theme }) => ({
  position: 'relative',
  verticalAlign: 'middle',
  display: 'inline-flex',

  [`&.${badgeClasses.default} .${badgeClasses.badge}`]: {
    minWidth: 20,
    paddingLeft: 6 - 1.5,
    paddingRight: 6 - 1.5,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 20 - 1.5 * 2 + 'px',
    textAlign: 'center',
    top: -8,
    right: -6,
  },

  [`&.${badgeClasses.dot} .${badgeClasses.badge}`]: {
    width: 10,
    height: 10,
    top: -1,
    right: -1,
  },

  [`& .${badgeClasses.badge}`]: {
    position: 'absolute',
    display: 'block',

    borderRadius: 100,
    border: `1.5px solid ${theme.palette.text.primary}`,
    color: theme.palette.text.primary,

    [`&.${badgeClasses.invisible}`]: {
      display: 'none',
    },
  },

  [`&.${badgeClasses.error}`]: {
    [`& .${badgeClasses.badge}`]: {
      background: theme.palette.error.dark,
    },
  },
  [`&.${badgeClasses.info}`]: {
    [`& .${badgeClasses.badge}`]: {
      background: theme.palette.primary.main,
    },
  },
  [`&.${badgeClasses.success}`]: {
    [`& .${badgeClasses.badge}`]: {
      background: theme.palette.success.main,
    },
  },
}));

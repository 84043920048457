import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dialog, Icon, Stack, Typography } from '~shared/ui';

import { useAuthModel } from '~widgets/auth/model';

import { AuthBackdrop, Paper } from '../styled';

import { AuthTelegram } from './AuthTelegram';
import { AuthMaincard } from './AuthMaincard';

export const AuthOptions = () => {
  const { open } = useAuthModel();

  const { t } = useTranslation();
  const [option, setOption] = useState<'telegram' | 'maincard'>();

  const handleChangeOption = (option: 'telegram' | 'maincard') => () => setOption(option);

  const renderOptionContent = () => {
    switch (option) {
      case 'maincard':
        return <AuthMaincard />;

      case 'telegram':
        return <AuthTelegram />;

      default:
        break;
    }
  };

  return (
    <Dialog
      open={open}
      slots={{
        paper: Paper,
        backdrop: AuthBackdrop,
      }}
      backdropInitialTransition={false}
    >
      {option ? (
        renderOptionContent()
      ) : (
        <Stack spacing={14 / 8}>
          <Typography variant="h2" textAlign="center">
            {t('WelcomePopUp.signIn')}
          </Typography>
          <Button
            startAdornment={<Icon name="social-telegram" size={18} />}
            onClick={handleChangeOption('telegram')}
          >
            {t('AuthOptions.withAccount', { option: 'Telegram' })}
          </Button>
          <Typography textAlign="center" color="secondary" fontSize="12px">
            {t('AuthOptions.toPlayOn', { chain: 'TON' })}
          </Typography>
          <Button
            startAdornment={<Icon name="notification-system" size={40} />}
            variant="outlined"
            onClick={handleChangeOption('maincard')}
          >
            {t('AuthOptions.withAccount', { option: 'Maincard' })}
          </Button>
          <Typography textAlign="center" color="secondary" fontSize="12px">
            {t('AuthOptions.toPlayOn', { chain: 'Polygon' })}
          </Typography>
        </Stack>
      )}
    </Dialog>
  );
};

import { useTonConnectModal } from '@tonconnect/ui-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useConnect } from '~entities/wallet/model';
import { useViewerModel } from '~entities/viewer';

import { Button } from '~shared/ui';

import {
  ConnectWalletOptionContainer,
  ConnectWalletOptionDescription,
  ConnectWalletOptionTitle,
  ConnectWalletOptionsRoot,
} from './styled';

export const ConnectWalletOptions: FC = () => {
  const { t } = useTranslation();

  const { open } = useTonConnectModal();
  const { openSignOut } = useViewerModel();

  const handleChangeWallet = async () => {
    try {
      await openSignOut();
    } catch {
      console.error('Failed to change wallet');
    }
  };

  return (
    <ConnectWalletOptionsRoot>
      <ConnectWalletOptionContainer>
        <ConnectWalletOptionTitle>{t('disconnectWallet.backToGame')}</ConnectWalletOptionTitle>
        <ConnectWalletOptionDescription>
          {t('disconnectWallet.cards')}
        </ConnectWalletOptionDescription>
        <Button fullWidth onClick={open}>
          {t('disconnectWallet.connect')}
        </Button>
      </ConnectWalletOptionContainer>
      <ConnectWalletOptionContainer>
        <ConnectWalletOptionTitle>{t('disconnectWallet.newWallet')}</ConnectWalletOptionTitle>
        <ConnectWalletOptionDescription>
          {t('disconnectWallet.register')}
        </ConnectWalletOptionDescription>
        <Button fullWidth onClick={handleChangeWallet}>
          {t('disconnectWallet.signUp')}
        </Button>
      </ConnectWalletOptionContainer>
    </ConnectWalletOptionsRoot>
  );
};

import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSet } from 'react-use';
import { Trans, useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@mui/base';

import { isValueInObjectRecursive, sleep } from '~shared/lib/utils';
import { Icon, MenuTab, MenuTabsList } from '~shared/ui';

import { isShouldShowMenuItem } from '~widgets/sports-menu';

import { SportsMenuList, SportsMenuListItem } from '../model';

import { SportsMenuIconFallback } from './SportsMenuIconFallback';
import { SportsMenuSidebarSlide } from './SportsMenuSidebarSlide';
import { SportsMenuSidebarSlidesContainer } from './SportsMenuSidebarSlidesContainer';

import {
  SportsMenuItem,
  SportsMenuItemLabel,
  SportsMenuSidebarRoot,
  SportsMenuSidebarSubmenuHeading,
  SportsMenuSidebarTabs,
  SportsMenuSubmenuPaper,
} from './styled';

import { SportsMenuSidebarProps } from './types';

export const SportsMenuSidebar: FC<SportsMenuSidebarProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [activeMenusSet, activeMenusSetActions] = useSet<Required<SportsMenuListItem>>();
  const activeMenus = Array.from(activeMenusSet);

  const handleNavigation = (item: SportsMenuListItem, isRootItem?: boolean) => async () => {
    const foundSiblingInMenus = activeMenus.find((menu) => menu.parentId === item.parentId);

    if (item.items) {
      // hide the displayed menu when clicked on another tab
      if (isRootItem && activeMenusSet.size > 0) {
        activeMenusSetActions.reset();

        // smooth transition
        await sleep(200);
      }

      // prevent displaying several submenus from one parent-menu
      if (foundSiblingInMenus) {
        activeMenusSetActions.remove(foundSiblingInMenus);

        // smooth transition
        await sleep(200);
      }

      activeMenusSetActions.add(item as Required<SportsMenuListItem>);
    } else if (item.route) {
      activeMenusSetActions.reset();
      navigate(item.route);
    }
  };

  const tabsValue = useMemo(() => {
    const selectedMenu = activeMenus[0];

    if (selectedMenu) {
      return selectedMenu.id;
    }

    const selectedSportsMenuItem = SportsMenuList.find((item) => {
      return isValueInObjectRecursive(item, location.pathname);
    });

    if (selectedSportsMenuItem) {
      return selectedSportsMenuItem.id;
    }

    return null;
  }, [location.pathname, activeMenus]);

  return (
    <ClickAwayListener onClickAway={activeMenusSetActions.reset}>
      <SportsMenuSidebarRoot>
        <SportsMenuSidebarTabs variant="sidebar" value={tabsValue}>
          <MenuTabsList>
            {SportsMenuList.map((tab, index) => (
              <MenuTab
                key={index}
                icon={<Icon name={tab.icon!} />}
                value={tab.id}
                onClickCapture={handleNavigation(tab, true)}
              >
                {tab.sidebarLabel ?? tab.label}
              </MenuTab>
            ))}
          </MenuTabsList>
        </SportsMenuSidebarTabs>

        <SportsMenuSidebarSlidesContainer>
          {activeMenus.map((item) => (
            <SportsMenuSidebarSlide key={item.id}>
              <SportsMenuSubmenuPaper>
                <SportsMenuSidebarSubmenuHeading>{item.label}</SportsMenuSidebarSubmenuHeading>

                {item.items
                  .filter((item) => isShouldShowMenuItem(item.dates))
                  .map((item) => (
                    <SportsMenuItem key={item.id} onClick={handleNavigation(item)}>
                      {item.icon ? (
                        <Icon size={36} name={item.icon} pr={6 / 8} />
                      ) : (
                        <SportsMenuIconFallback />
                      )}

                      <SportsMenuItemLabel>
                        {item.sectionLabel ? (
                          <Trans i18nKey="LeftSideBar.allEvents" t={t}>
                            All {{ sport: item.sectionLabel }} events
                          </Trans>
                        ) : (
                          item.label
                        )}
                      </SportsMenuItemLabel>

                      {item.items?.length && <Icon ml="auto" name="chevron-right" size={20} />}
                    </SportsMenuItem>
                  ))}
              </SportsMenuSubmenuPaper>
            </SportsMenuSidebarSlide>
          ))}
        </SportsMenuSidebarSlidesContainer>
      </SportsMenuSidebarRoot>
    </ClickAwayListener>
  );
};

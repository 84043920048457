import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { BattleResult } from '~shared/api';

import { NftCard, NftCardDrag, NftDragItemType } from '~entities/nft';
import { isDrawSlotAvailable } from '~entities/battle';
import { EventStatus, useEventModel } from '~entities/event';

import { useMediaQuery } from '~shared/lib/hooks';

import { Stack } from '~shared/ui';

import { EventChoice } from '../EventDialogCards/EventChoice';

import { useEventDrag } from '../../../../model';

import { EventDialogPlaceholder } from '../EventDialogPlaceholder';

import { EventWarningMessage } from '../../EventWarningMessage';

import { EventDialogCallProps } from './types';
import { CellsContainer } from './styled';
import { EventCallActions } from './EventCallActions';

export const EventDialogCall: FC<EventDialogCallProps> = () => {
  const { isMobile } = useMediaQuery();

  const {
    onAddCards,
    isViewMode,
    isLessOrEqualFiveMinutesLeft,
    isViewer,
    isAnyCardsPlaced,

    event,
    eventStatus,

    isViewerCall,

    noCallForEvent,

    choice,
    cards,
    call,
  } = useEventModel();

  const { t } = useTranslation();

  const isInitial = !isAnyCardsPlaced;

  const homeTeamCell = useEventDrag({
    result: BattleResult.HomeTeamWon,
    viewMode: isViewMode || call.callCreator?.choiceId === BattleResult.HomeTeamWon,
  });

  const drawCell = useEventDrag({
    result: BattleResult.Draw,
    viewMode: isViewMode || call.callCreator?.choiceId === BattleResult.Draw,
  });

  const awayTeamCell = useEventDrag({
    result: BattleResult.AwayTeamWon,
    viewMode: isViewMode || call.callCreator?.choiceId === BattleResult.AwayTeamWon,
  });

  const getPropsForAppropriateCell = (result: BattleResult) => {
    if (call.callCreator?.choiceId === result) {
      return {
        nft: (
          <NftCard
            nft={{
              token_id: call.callCreator.card!,
              isBlockedForEvent: eventStatus !== EventStatus.Passed,
            }}
            size="md"
          />
        ),
        disabled: true,
      };
    }

    if (call.callAcceptor?.choiceId === result) {
      return {
        nft: (
          <NftCard
            nft={{
              token_id: call.callAcceptor.card!,
              isBlockedForEvent: eventStatus !== EventStatus.Passed,
            }}
            size="md"
          />
        ),
        disabled: true,
      };
    }

    if (result !== choice) {
      return { onAddCards: onAddCards(result, isMobile) };
    }

    if (cards[0]) {
      return {
        nft: <NftCardDrag nft={cards[0]} dragKey={NftDragItemType.MoveOrLeave} hideStates />,
      };
    }

    return { onAddCards: onAddCards(result, isMobile) };
  };

  const homeTeamCellProps = getPropsForAppropriateCell(BattleResult.HomeTeamWon);
  const drawCellProps = getPropsForAppropriateCell(BattleResult.Draw);
  const awayTeamCellProps = getPropsForAppropriateCell(BattleResult.AwayTeamWon);

  const callActions = <EventCallActions />;

  const isReversedCells = isViewerCall && choice === BattleResult.AwayTeamWon && !call.callAcceptor;

  const renderContent = () => {
    switch (true) {
      case noCallForEvent:
        return (
          <EventDialogPlaceholder
            title={t('EventDialog.noBetType', { betType: t('EventDialog.call') })}
            description={t(
              isViewer
                ? 'EventDialog.youHaveNoBetTypeDescription'
                : 'EventDialog.haveNoBetTypeDescription',
              {
                betType: t('EventDialog.call'),
              }
            )}
          />
        );

      case !isViewerCall && isLessOrEqualFiveMinutesLeft:
        return (
          <EventDialogPlaceholder
            title={t('EventDialog.tooLittleTime')}
            description={t('EventDialog.tooLittleTimeDescription', {
              betType: t('EventDialog.call'),
            })}
          />
        );

      case isViewMode:
        return (
          <Stack spacing={20 / 8} mt={isMobile ? '20px' : 0}>
            <CellsContainer>
              {!call.callAcceptor && isReversedCells && (
                <EventChoice icon="time" label={t('EventDialog.waitingForOpponnent')} />
              )}
              {homeTeamCellProps.nft && <EventChoice {...homeTeamCellProps} />}
              {drawCellProps.nft && <EventChoice {...drawCellProps} />}
              {awayTeamCellProps.nft && <EventChoice {...awayTeamCellProps} />}
              {!call.callAcceptor && !isReversedCells && (
                <EventChoice icon="time" label={t('EventDialog.waitingForOpponnent')} />
              )}
            </CellsContainer>
            {eventStatus === EventStatus.Pending && (
              <EventWarningMessage>{t('EventDialog.cantChangeYourCall')}</EventWarningMessage>
            )}
            {callActions}
          </Stack>
        );

      default:
        return (
          <Stack spacing={20 / 8} mt={isMobile ? '20px' : 0}>
            <CellsContainer>
              <EventChoice
                dropRef={homeTeamCell.dropRef}
                isHighlighted={homeTeamCell.isCellHighlighted}
                disabled={!homeTeamCell.canDrop && homeTeamCell.isDragging}
                isInitial={isInitial}
                {...homeTeamCellProps}
                result={BattleResult.HomeTeamWon}
              />
              {isDrawSlotAvailable(event!.sport) && (
                <EventChoice
                  dropRef={drawCell.dropRef}
                  isHighlighted={drawCell.isCellHighlighted}
                  disabled={!drawCell.canDrop && drawCell.isDragging}
                  isInitial={isInitial}
                  {...drawCellProps}
                  result={BattleResult.Draw}
                />
              )}
              <EventChoice
                dropRef={awayTeamCell.dropRef}
                isHighlighted={awayTeamCell.isCellHighlighted}
                disabled={!awayTeamCell.canDrop && awayTeamCell.isDragging}
                isInitial={isInitial}
                {...awayTeamCellProps}
                result={BattleResult.AwayTeamWon}
              />
            </CellsContainer>
            {call.cards.length > 0 ? (
              <EventWarningMessage>
                {t('EventDialog.onlyOneCardForCalls')}. {t('EventDialog.cantChangeYourCall')}
              </EventWarningMessage>
            ) : (
              <EventWarningMessage>{t('EventDialog.onlyOneCardForCalls')}</EventWarningMessage>
            )}
            {callActions}
          </Stack>
        );
    }
  };

  return renderContent();
};

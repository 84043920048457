import { useQuery } from 'react-query';

import { isEventCurrent, isEventNotStarted, isEventPassed } from '~entities/event';

import { ApiGetBetsBetMappedData, getBets } from '~shared/api';

interface UseBetsQueryParams {
  wallet: string;
  queryKey?: Array<string>;

  filterCallback?: (
    value: ApiGetBetsBetMappedData,
    index?: number,
    array?: ApiGetBetsBetMappedData[]
  ) => boolean;
}

export const useBetsQuery = ({
  wallet,
  queryKey = ['bets', wallet],
  filterCallback,
}: UseBetsQueryParams) => {
  const { data, isLoading } = useQuery(
    queryKey,
    async () => {
      let bets = await getBets({ address: wallet }).then(({ bets }) =>
        bets.sort((a, b) => b.date.getTime() - a.date.getTime())
      );

      if (filterCallback) {
        bets = bets.filter(filterCallback);
      }

      return bets;
    },
    { enabled: !!wallet }
  );

  return {
    isLoading,
    isEmptyList: data && data.length === 0,

    list: data,
    passedList: data?.filter((bet) => isEventPassed(bet.result)),
    currentList: data?.filter(
      (bet) => isEventCurrent(bet.date, bet.result) || isEventNotStarted(bet.date)
    ),
  };
};

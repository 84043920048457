import { Swiper, SwiperSlide } from 'swiper/react';

import { styled } from '~shared/lib/styles';

export const UserProfileHeadInfoContainer = styled('div')(() => ({
  height: '180px',
  backgroundColor: '#1A1D20',
  padding: '20px',
  margin: '-30px -20px 0px -20px',
}));

export const UserProfileHeadInfoLeft = styled('div')(() => ({
  display: 'flex',
  marginBottom: '15px',
}));

export const UserProfileHeadInfoNickName = styled('div')(() => ({
  marginLeft: '10px',
}));

export const CardInfo = styled('div')({
  display: 'flex',
  marginTop: '10px',
  marginBottom: '10px',
});

export const CardInfoBlock = styled('div')({});

export const HorizontalListSwiper = styled(Swiper)({
  marginTop: '10px',
});

export const HorizontalListSlide = styled(SwiperSlide)(() => ({
  ['&.swiper-slide']: {
    width: 'max-content',
  },
}));

import { useQuery } from 'react-query';

import { getLeaderboard } from '~shared/api';

interface UseQueryLeaderboardParams {
  /**
   * Timestamp is seconds from which we start count ranking stats.
   * If not provided, ranking will be counted for last week
   */
  isWeek?: boolean;
}

export const useQueryLeaderboard = ({ isWeek }: UseQueryLeaderboardParams) => {
  const { data: list = [], isLoading } = useQuery(['getLeaderboard', isWeek], () => {
    return getLeaderboard({ isWeek });
  });

  return {
    isLoading,
    list,
  };
};

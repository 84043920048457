import { useTranslation } from 'react-i18next';

import { Button, CircularProgress, Dialog, Typography } from '~shared/ui';

import { UseReferral, useReferralQuery } from '../model';

import { ButtonClipBoardDone, ButtonContainer } from './styled';

export const Referral = () => {
  const { referralCode, copy, value, open, handleClose } = UseReferral();
  useReferralQuery();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose} width={440}>
      <Typography textAlign="center" fontSize={24} fontWeight={600}>
        {t('Other.Reffer')}
      </Typography>
      <Typography color="secondary" textAlign="center">
        {t('Other.youCanSend')}
      </Typography>
      <ButtonContainer>
        {referralCode ? (
          value.value === undefined ||
          value.value !== `${process.env.REACT_APP_BOT_LINK}${referralCode}` ? (
            <Button
              width="fit-content"
              onClick={() => copy(`${process.env.REACT_APP_BOT_LINK}${referralCode}`)}
            >
              Copy a link
            </Button>
          ) : (
            <ButtonClipBoardDone>{t('Other.linkToCopied')}</ButtonClipBoardDone>
          )
        ) : (
          <CircularProgress size={32} />
        )}
      </ButtonContainer>
    </Dialog>
  );
};

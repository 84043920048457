export const ViewerRoutes = {
  // Default set of avatars
  defaultAvatars: () => '/user/avatars/default',

  // Post custom user avatar
  userAvatar: () => '/user/avatar',

  // Update nickname
  setNickname: () => '/user/nickname',

  // Get viewer profile data
  profile: () => '/profile',

  // Get viewer dashboard data
  dashboard: (wallet: string) => `/user/dashboard/${wallet}`,
  tonDashboard: (wallet: string) => `/event/dashboard?address=${wallet}`,

  // Password restoration
  recoveryPassword: () => '/recovery_password',
  setPassword: () => '/set_password', // Set password on recovery

  disconnectWallet: () => '/user/disconnect_wallet',

  // Update password in profile
  updatePassword: () => '/update_password',

  checkNickname: () => '/check_nickname',

  // Bind wallet address to email
  setWallet: () => '/set_wallet',
  setTonWallet: () => '/set_ton_address',
  // Set that user passed onboarding
  setOnboardingPassed: () => '/user/onboarding',

  // Switch user language on backend
  language: () => '/user/language',
};

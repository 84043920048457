import { FC } from 'react';

import { NotificationActionRoot } from './styled';

import { NotificationActionProps } from './types';

export const NotificationAction: FC<NotificationActionProps> = ({
  onClick,
  onClickCapture,
  shouldCloseNotificationCenter = true,
  ...htmlAttributes
}) => {
  // this wrapper makes possible to programmatically
  // close notification center on action click
  const wrapOnClick = (eventHandler?: Function) => {
    if (eventHandler) {
      return function () {
        eventHandler(arguments);

        if (shouldCloseNotificationCenter) {
          document.body.click();
        }
      };
    }
  };

  return (
    <NotificationActionRoot
      onClick={wrapOnClick(onClick)}
      onClickCapture={wrapOnClick(onClickCapture)}
      {...htmlAttributes}
    />
  );
};

import { FC, Fragment } from 'react';

import { Stack, Typography } from '~shared/ui';

import { EventStatisticsRow } from '~entities/event';

import {
  EventDialogStatisticsDotsContainer,
  EventDialogStatisticsPointCircle,
  EventDialogStatisticsProgress,
  EventDialogStatisticsRowBottomContainer,
  EventDialogStatisticsRowMultipleValueContainer,
  EventDialogStatisticsRowTopContainer,
} from './styled';

export const EventDialogStatisticsRow: FC<EventStatisticsRow> = ({
  label,
  visualization,
  leftColumn,
  rightColumn,
}) => {
  const NotAvailable = 'N/A';

  switch (visualization) {
    case 'linear':
      return (
        <div>
          <EventDialogStatisticsRowTopContainer>
            <Typography fontWeight={600} align="left">
              {leftColumn.value ?? NotAvailable}
            </Typography>

            <Typography fontSize={13} align="center">
              {label}
            </Typography>

            <Typography fontWeight={600} align="right">
              {rightColumn.value ?? NotAvailable}
            </Typography>
          </EventDialogStatisticsRowTopContainer>

          <EventDialogStatisticsRowBottomContainer>
            <EventDialogStatisticsProgress
              progress={getProgress(leftColumn.value, leftColumn.overallValue)}
              reverse
              $result={leftColumn.result}
            />

            <EventDialogStatisticsProgress
              progress={getProgress(rightColumn.value, rightColumn.overallValue)}
              $result={rightColumn.result}
            />
          </EventDialogStatisticsRowBottomContainer>
        </div>
      );

    case 'dots':
      return (
        <div>
          <EventDialogStatisticsRowTopContainer>
            <EventDialogStatisticsDotsContainer $position="left">
              {leftColumn.length
                ? leftColumn.map((dot, key) => (
                    <EventDialogStatisticsPointCircle key={key} $result={dot} />
                  ))
                : NotAvailable}
            </EventDialogStatisticsDotsContainer>

            <Typography fontSize={13} align="center">
              {label}
            </Typography>

            <EventDialogStatisticsDotsContainer $position="right">
              {rightColumn.length
                ? rightColumn.map((dot, key) => (
                    <EventDialogStatisticsPointCircle key={key} $result={dot} />
                  ))
                : NotAvailable}
            </EventDialogStatisticsDotsContainer>
          </EventDialogStatisticsRowTopContainer>
        </div>
      );

    case 'multiple-value':
      return (
        <Fragment>
          <EventDialogStatisticsRowTopContainer>
            <span />

            <Typography fontSize={14} lineHeight={1} fontWeight={500} align="center">
              {label}
            </Typography>

            <span />
          </EventDialogStatisticsRowTopContainer>

          <EventDialogStatisticsRowMultipleValueContainer>
            <Typography align="left">
              {leftColumn.map((score, key) => (
                <Stack key={key} direction="row" spacing={10 / 8}>
                  <Typography fontWeight={600}>{score.value ?? NotAvailable}</Typography>
                  <Typography fontWeight={400} color="secondary">
                    {score.label}
                  </Typography>
                </Stack>
              ))}
            </Typography>

            <span />

            <Typography align="right">
              {rightColumn.map((score, key) => (
                <Stack key={key} direction="row" justifyContent="flex-end" spacing={10 / 8}>
                  <Typography fontWeight={400} color="secondary">
                    {score.label}
                  </Typography>
                  <Typography fontWeight={600}>{score.value ?? NotAvailable}</Typography>
                </Stack>
              ))}
            </Typography>
          </EventDialogStatisticsRowMultipleValueContainer>
        </Fragment>
      );

    case 'value':
      return (
        <div>
          <EventDialogStatisticsRowTopContainer>
            <Typography fontWeight={600} align="left">
              {leftColumn ? leftColumn : NotAvailable}
            </Typography>

            <Typography fontSize={13} align="center">
              {label}
            </Typography>

            <Typography fontWeight={600} align="right">
              {rightColumn ? rightColumn : NotAvailable}
            </Typography>
          </EventDialogStatisticsRowTopContainer>
        </div>
      );
  }
};

const getProgress = (score: number, overall: number) => {
  if (overall === 0) {
    return 0;
  }

  return (score / overall) * 100;
};

import { FC, Fragment } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, CreatePassword, RepeatPassword, Stack, Typography } from '~shared/ui';
import { useSwitcher } from '~shared/lib/hooks';

import { useRecoveryModel } from '../../model';

import { RecoveryChangePasswordForm, RecoveryChangePasswordProps } from './types';

export const RecoveryChangePassword: FC<RecoveryChangePasswordProps> = () => {
  const { t } = useTranslation();

  const submitButtonDisabled = useSwitcher(false);
  const { updatePassword } = useRecoveryModel();
  const form = useForm<RecoveryChangePasswordForm>({});

  const handleSubmit = form.handleSubmit(({ password }) => {
    submitButtonDisabled.switchOn();

    updatePassword({ password }).then(submitButtonDisabled.switchOff);
  });

  return (
    <Fragment>
      <Typography variant="h1" align="center" gutterBottom>
        {t('Account.changePassword')}
      </Typography>

      <FormProvider {...form}>
        <Stack component="form" onSubmit={handleSubmit} spacing={24 / 8}>
          <CreatePassword />
          <RepeatPassword />

          <Button disabled={submitButtonDisabled.value} type="submit">
            {t('Account.changePassword')}
          </Button>
        </Stack>
      </FormProvider>
    </Fragment>
  );
};

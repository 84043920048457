import { useTonAddress, useTonWallet } from '@tonconnect/ui-react';
import { Address, toNano } from '@ton/core';
import { useTranslation } from 'react-i18next';

import { NftRarity } from '~shared/api';

import { useConnect, useWriteContract } from '~entities/wallet';

interface UseBuyMysteryBoxProps {
  price: number;
  rarity: NftRarity;
}

export const useBuyMysteryBoxForMatic = ({ price, rarity }: UseBuyMysteryBoxProps) => {
  const { t } = useTranslation();
  const { openConnectWalletWarnDialog } = useConnect();
  const address = useTonAddress(true);

  const account = useTonWallet();

  const { write: openMysteryBox } = useWriteContract({
    contractName: 'CardCollection',
    method: 'RandomBox',
    transactionName: 'Opening mystery box',
    successMessage: `${t('Alerts.successfulOpenBox')}`,
    errorMessage: `${t('Errors.openMysteryBoxFailed')}`,
  });

  const handleBuyMysteryBox = async () => {
    if (!account) {
      openConnectWalletWarnDialog();

      return;
    }

    try {
      await openMysteryBox({
        args: {
          $$type: 'Mint',
          mint_to: Address.parse(address),
          rarity: BigInt(rarity),
        },
        value: toNano(price + 0.35),
      });
    } catch (e) {}
  };

  return {
    handleBuyMysteryBox,
  };
};

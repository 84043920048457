import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTonAddress } from '@tonconnect/ui-react';

import { useDispatch } from '~shared/lib/hooks';
import { getAllConsumables } from '~shared/api/consumables';

import { inventoryActions } from './slice';

export const useConsumablesQuery = () => {
  const dispatch = useDispatch();
  const address = useTonAddress(true);

  const { data, refetch } = useQuery(
    ['consumables', address],
    () => getAllConsumables({ address }),
    {
      staleTime: 1 * 60 * 1000,
      enabled: address.length !== 0,
    }
  );

  useEffect(() => {
    if (data) {
      dispatch(inventoryActions.set(data));
    }
  }, [data, dispatch]);

  return {
    data,
    refetch,
  };
};

import { styled } from '~shared/lib/styles';
import { BaseTextField, SelectField } from '~shared/ui';

export const StyledBaseTextField = styled(BaseTextField, {
  name: 'SellCardForm',
  slot: 'baseTextField',
})(() => ({
  maxWidth: '128px',
  div: {
    'borderRadius': '8px 0 0 8px',
    '&:hover': {
      boxShadow: 'none !important',
    },
  },
}));

export const StyledSelectField = styled(SelectField)({
  'borderRadius': '0 8px 8px 0',
  '&:hover': {
    boxShadow: 'none !important',
  },
});

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Stack, Typography } from '~shared/ui';

// todo: fsd
import { SellCard, useSellCardModel } from '~features/nft';
import { FixCard } from '~features/nft/fix-card';
import { useAuctionModel } from '~features/auction';
import { useInventorySelector } from '~widgets/inventory';

// import { useWriteContract } from '~entities/wallet';

// import { useDispatch } from '~shared/lib/hooks';

import { isNftAvailableForSell, isNftShouldBeRecovered } from '../../../lib';
import { useNftCardModel, useNftUnfreeze, useWrapNftAction } from '../../../model';

import { NftCardInfoActionsProps } from './types';
import { Root } from './styled';

export const NftCardInfoActions: FC<NftCardInfoActionsProps> = ({ nft, mobile }) => {
  const { t, i18n } = useTranslation();
  // const dispatch = useDispatch();

  const { onOpenCardInfo } = useNftCardModel();
  const { takeCardHandler } = useAuctionModel();
  const { unfreeze, unfreezeHandler } = useNftUnfreeze();

  const { handleWrapNftActions } = useWrapNftAction();

  const handleOpenCardInfo = () => {
    onOpenCardInfo(nft);
  };

  const buttonSize = mobile ? 'lg' : 'sm';

  if (nft.isOnAuction) {
    return (
      <Root onClick={handleWrapNftActions} $mobile={mobile}>
        {process.env.REACT_APP_FEATURE_FLAG_AUCTION_TAKE_CARD === 'true' &&
          nft.relatedAuction?.betsAcceptedUntil &&
          new Date() > new Date(nft.relatedAuction.betsAcceptedUntil) && (
            <Button
              size={buttonSize}
              onClick={() => takeCardHandler(nft.token_id, nft.relatedAuction?.lotId)}
            >
              {t('Wallet.takeCard')}
            </Button>
          )}
        <Button size={buttonSize} variant="outlined" onClick={handleOpenCardInfo}>
          {t('Other.info')}
        </Button>
      </Root>
    );
  }

  if (nft.isBlockedForEvent || nft.isOnEvent) {
    return (
      <Root onClick={handleWrapNftActions} $mobile={mobile}>
        <Button size={buttonSize} variant="outlined" onClick={handleOpenCardInfo}>
          {t('Other.info')}
        </Button>
      </Root>
    );
  }

  return (
    <Root onClick={handleWrapNftActions} $mobile={mobile}>
      {isNftAvailableForSell(nft) && (
        <SellCard size={buttonSize} nft={nft}>
          {t('Other.sell')}
        </SellCard>
      )}

      {isNftShouldBeRecovered(nft) && (
        <FixCard size={buttonSize} nft={nft} lang={i18n.language}>
          {t('Other.fixBtn')}
        </FixCard>
      )}

      {nft.isFreezed && unfreeze !== 0 && (
        <Button size={buttonSize} onClick={() => unfreezeHandler(nft.token_id)}>
          <Stack gap="4px" flexDirection="row" alignItems="center">
            <Typography>{t('Marketplace.unfreeze')}</Typography>
            <Icon name="unfreeze" size={20} />
            {unfreeze}
          </Stack>
        </Button>
      )}

      <Button size={buttonSize} variant="outlined" onClick={handleOpenCardInfo}>
        {t('Other.info')}
      </Button>

      {/* TODO: maybe we will have to get it back
       <RemoveCardFromEvent nft={nft} />
       */}
    </Root>
  );
};

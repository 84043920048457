import { Button as MuiBaseButton, buttonClasses as muiBaseButtonClasses } from '@mui/base';

import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui/layout';

const FRAME_WIDTH = 1;

export const buttonClasses = {
  ...muiBaseButtonClasses,
  inner: 'MuiButton-inner',

  contained: 'MuiButton-contained',
  outlined: 'MuIButton-outlined',
  text: 'MuiButton-text',

  xs: 'MuiButton-sizeExtraSmall',
  sm: 'MuiButton-sizeSmall',
  md: 'MuiButton-sizeMedium',
  lg: 'MuiButton-sizeLarge',

  primary: 'MuiButton-primary',
  error: 'MuiButton-error',
  success: 'MuiButton-success',

  fullWidth: 'MuiButton-fullWidth',
  ruLang: 'MuiButton-ruLang',
};

export const UIKitButton = styled(MuiBaseButton)(({ theme }) => ({
  WebkitAppearance: 'none',
  position: 'relative',
  flexShrink: 0,

  borderRadius: 8,
  overflow: 'hidden',
  padding: FRAME_WIDTH,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '14px',
  background: 'none',

  color: theme.palette.text.primary,
  border: 'none',

  transition: theme.transitions.create(['background', 'box-shadow', 'border', 'opacity']),

  [`&.${buttonClasses.fullWidth}`]: {
    width: '100%',
  },

  [`&.${buttonClasses.ruLang}`]: {
    fontSize: '11px',
  },

  [`&.${buttonClasses.contained}`]: {
    background: 'linear-gradient(to bottom, #52BCF8, #09A0F5)',

    [`& .${buttonClasses.inner}`]: {
      background: '#08A0F5',

      [`&.${buttonClasses.error}`]: {
        background: theme.palette.error.main,
      },
    },

    [`&:hover:not(.${buttonClasses.disabled}),
      &:focus:not(.${buttonClasses.disabled})`]: {
      background: 'linear-gradient(to bottom, #52BCF8, #09A0F5)',
      boxShadow: '0px 0px 8px #42BAFF',
    },

    [`&.${buttonClasses.error}`]: {
      background: 'linear-gradient(to bottom, #F58E8E, #F05E5E)',

      [`& .${buttonClasses.inner}`]: {
        background: theme.palette.error.main,
      },

      [`&:hover:not(.${buttonClasses.disabled}),
        &:focus:not(.${buttonClasses.disabled})`]: {
        background: 'linear-gradient(to bottom, #F58E8E, #F05E5E)',
        boxShadow: '0px 0px 8px #FF7043',
      },
    },

    [`&.${buttonClasses.success}`]: {
      background: theme.palette.success.main,
      [`& .${buttonClasses.inner}`]: {
        background: theme.palette.success.main,
      },

      [`&:hover:not(.${buttonClasses.disabled}),
        &:focus:not(.${buttonClasses.disabled})`]: {
        background: theme.palette.success.main,
        boxShadow: '0px 0px 8px #40CAA0',
      },
    },
  },

  [`&.${buttonClasses.outlined}`]: {
    border: `1px solid ${theme.palette.grey.A30}`,
    padding: FRAME_WIDTH - 1,

    [`&.${buttonClasses.success}`]: {
      borderColor: theme.palette.success.main,
      [`&:hover:not(.${buttonClasses.disabled}),
        &:focus:not(.${buttonClasses.disabled})`]: {
        borderColor: theme.palette.success.main,
        boxShadow: '0px 0px 8px #40CAA0',
      },
    },

    [`&.${buttonClasses.error}`]: {
      borderColor: theme.palette.error.main,

      [`&:hover:not(.${buttonClasses.disabled}),
        &:focus:not(.${buttonClasses.disabled})`]: {
        borderColor: theme.palette.error.main,
        boxShadow: '0px 0px 8px #FF7043',
      },
    },

    [`&:hover:not(.${buttonClasses.disabled}),
      &:focus:not(.${buttonClasses.disabled})`]: {
      border: '1px solid #2EB2FF',
      boxShadow: '0px 0px 8px rgba(66,186,255,0.54)',
    },
  },

  [`&.${buttonClasses.xs}`]: {
    [`& .${buttonClasses.inner}`]: {
      padding: `${6 - FRAME_WIDTH}px ${9 - FRAME_WIDTH}px`,
      fontSize: 13,
    },
  },
  [`&.${buttonClasses.sm}`]: {
    [`& .${buttonClasses.inner}`]: {
      padding: `${9 - FRAME_WIDTH}px ${16 - FRAME_WIDTH}px`,
    },
  },
  [`&.${buttonClasses.md}`]: {
    [`& .${buttonClasses.inner}`]: {
      padding: `${12 - FRAME_WIDTH}px ${16 - FRAME_WIDTH}px`,
    },
  },

  [`&.${buttonClasses.text}`]: {
    padding: 0,
    color: theme.palette.primary.main,
    overflow: 'initial',
    fontSize: 14,
    lineHeight: 20 / 14,
    fontWeight: 400,

    [`&.${buttonClasses.lg}`]: {
      fontSize: 12,
      lineHeight: 14 / 12,
    },

    [`& .${buttonClasses.inner}`]: {
      padding: 0,
    },

    [`&.${buttonClasses.success}`]: {
      color: theme.palette.success.main,
    },

    [`&:hover:not(.${buttonClasses.disabled}),
      &:focus:not(.${buttonClasses.disabled})`]: {
      [`& .${buttonClasses.inner}`]: {
        textDecoration: 'underline',
      },
    },
  },

  [`&.${buttonClasses.disabled}`]: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
}));

export const UIKitButtonInner = styled(Box)({
  padding: `${15 - FRAME_WIDTH}px ${16 - FRAME_WIDTH}px`,
  borderRadius: 7,
  pointerEvents: 'none',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const UIKitButtonContent = styled(Box)({});

export const UIKitAdornment = styled(Box)({
  height: 1,
  display: 'flex',
  alignItems: 'center',
});

export const UIKitStartAdornment = styled(UIKitAdornment)({
  marginRight: 10,
});

export const UIKitEndAdornment = styled(UIKitAdornment)({
  marginLeft: 10,
});

import { forwardRef, Fragment } from 'react';
import { useNavigate } from 'react-router';

import { routes } from '~shared/config';

import {
  Notification,
  NotificationAction,
  NotificationCaption,
  NotificationIconFactory,
  NotificationMessage,
  NotificationTransactionStatus,
} from '../../../factory';

import { NotificationDemoCardBurnedProps } from './types';
import { useTranslation } from 'react-i18next';

export const NotificationDemoCardBurned = forwardRef<
  HTMLDivElement,
  NotificationDemoCardBurnedProps
>(({ notification, place }, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { betsTotal, betsWon, totalReward, accuracy } = notification.payload;

  const handleClick = () => {
    navigate(routes.marketplace);
  };

  return (
    <Notification
      {...notification}
      place={place}
      ref={ref}
      icon={<NotificationIconFactory type="system" />}
    >
      <NotificationTransactionStatus type="success" icon={<Fragment />}>
        {t('Notifications.congratulations')}
      </NotificationTransactionStatus>

      <NotificationMessage>
        {t('Notifications.demoBurnedCardMessage', {
          betsTotal,
          accuracy,
          totalReward,
        })}
      </NotificationMessage>

      <NotificationCaption>{t('Notifications.demoBurnedCardCaption')}</NotificationCaption>

      <NotificationAction onClick={handleClick}>
        {t('Notifications.demoBurnedCardGoToMarketplace')}
      </NotificationAction>
    </Notification>
  );
});

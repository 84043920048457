import { BigNumber, ethers } from 'ethers';

import { MagicBox__factory, Multicall__factory, NFT__factory } from '~shared/contracts';
import { getProvider } from '~shared/lib/utils';

import {
  MARKETPLACE_AVAILABLE_RARITIES,
  MARKETPLACE_ITEMS,
  MarketplaceItem,
  MarketplaceItemType,
  getMarketplaceAsset,
} from '../../lib';

const getMarketplacePrices = async (marketplaceItemType: MarketplaceItemType) => {
  const isMysteryBox = marketplaceItemType === MarketplaceItemType.MysteryBox;

  // TODO: rewrite this part
  return {
    1: 0.3,
    2: 3,
    3: 30,
    4: 300,
    5: 0,
  };

  const provider = getProvider();
  const multiCallContract = Multicall__factory.connect(
    process.env.REACT_APP_ADDRESS_MULTICALL,
    provider
  );

  const contractAddress = isMysteryBox
    ? process.env.REACT_APP_ADDRESS_SK_MAGIC_BOX
    : process.env.REACT_APP_ADDRESS_SK_CARD;

  const factory = isMysteryBox ? MagicBox__factory : NFT__factory;

  const contract = factory.connect(contractAddress, provider);

  const contractAttached = contract.attach(contractAddress);
  const multiCallContractAttached = multiCallContract.attach(
    process.env.REACT_APP_ADDRESS_MULTICALL
  );

  const prices = {};

  const requests: Array<any> = [];

  for (const rarity of MARKETPLACE_AVAILABLE_RARITIES) {
    const method = isMysteryBox ? 'getBoxPrice' : 'cardPrice';
    const tx = await contractAttached.populateTransaction[method](rarity, {
      gasLimit: 100000,
    });

    requests.push({
      to: contractAddress,
      data: tx.data,
    });
  }

  const multiCallResults = await multiCallContractAttached.callStatic.multicall(requests);

  multiCallResults.forEach((result: string, index: number) => {
    prices[index] = Number(ethers.utils.formatEther(BigNumber.from(result)));
  });

  return prices;
};

export const getMarketplaceItems = async (): Promise<Array<MarketplaceItem>> => {
  const [mysteryBoxPrices, nftPrices] = await Promise.all([
    getMarketplacePrices(MarketplaceItemType.MysteryBox),
    getMarketplacePrices(MarketplaceItemType.Nft),
  ]);

  const marketplaceItemsFilledWithPriceAndImage = MARKETPLACE_ITEMS.map((item) => {
    const asset = getMarketplaceAsset({
      rarity: item.type === MarketplaceItemType.Unfreeze ? undefined : item.rarity,
      isMysteryBox: item.type === MarketplaceItemType.MysteryBox,
    });

    if (item.type === MarketplaceItemType.Unfreeze) {
      return {
        ...item,
        imagePath: asset,
      };
    }

    if (item.type === MarketplaceItemType.MysteryBox) {
      return {
        ...item,
        price: mysteryBoxPrices[item.rarity],
        imagePath: asset,
      };
    }

    return {
      ...item,
      price: nftPrices[item.rarity],
      imagePath: asset,
    };
  });

  return marketplaceItemsFilledWithPriceAndImage;
};

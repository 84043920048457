import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const StyledHotOfferContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: -7,
  top: 8,
  zIndex: 1,

  [theme.breakpoints.down('mobile')]: {
    left: 'initial',
    right: 0,
    top: 0,
  },
}));

import { FC } from 'react';

import { Avatar, Icon } from '~shared/ui';

import { ParticipantName, ParticipantPlace, ParticipantRoot } from './styled';
import { TournamentParticipantProps } from './types';

export const TournamentParticipant: FC<TournamentParticipantProps> = ({
  name,
  place,
  avatar,
  ...boxProps
}) => {
  return (
    <ParticipantRoot {...boxProps} spacing={10 / 8} direction="row">
      <ParticipantPlace>{place}</ParticipantPlace>
      {/* todo: remove mock after backend integration */}
      <Avatar src={avatar} size={24} />
      <ParticipantName>{name}</ParticipantName>
      {place === 1 && <Icon name="crown-gold" />}
    </ParticipantRoot>
  );
};

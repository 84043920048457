import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DailyQuestProgressDialogState } from './types';

const initialState: DailyQuestProgressDialogState = {
  isOpen: false,
};

export const dailyQuestProgressSlice = createSlice({
  name: 'dailyQuestProgress',
  initialState: initialState,
  reducers: {
    setToggle: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isOpen: action.payload,
    }),

    reset: () => {
      return { ...initialState };
    },
  },
});

export const dailyQuestProgressActions = dailyQuestProgressSlice.actions;

import { Web3ReactHooks, initializeConnector } from '@web3-react/core';
import { Actions, Connector } from '@web3-react/types';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { MetaMask } from '@web3-react/metamask';

import { useSyncExternalStore } from 'react';

import { isCoinbaseInjected, isMetamaskInjected } from '../lib';

import { Connection, ConnectionType, SupportedChainId } from './types';
import { WalletConnectV2 } from './WalletConnectV2';

function onError(error: Error) {
  // eslint-disable-next-line no-console
  console.debug(`web3-react error: ${error}`);
}

export const RPC_URLS = {
  [SupportedChainId.Polygon]: [process.env.REACT_APP_NODE_RPC_URL],
};

export const walletConnectV2Connection: Connection = new (class implements Connection {
  private initializer = (actions: Actions, defaultChainId = 137) =>
    new WalletConnectV2({ actions, defaultChainId, onError });

  installed = true;
  name = 'WalletConnect';
  icon = 'wallet-walletconnect' as any;
  type = ConnectionType.WalletConnectV2;
  getName = () => 'WalletConnect';
  getIcon = () => 'wallet-walletconnect';
  shouldDisplay = () => true;

  private activeConnector = initializeConnector<WalletConnectV2>(this.initializer);
  // The web3-react Provider requires referentially stable connectors, so we use proxies to allow lazy connections
  // whilst maintaining referential equality.
  private proxyConnector = new Proxy(
    {},
    {
      get: (target, p, receiver) => Reflect.get(this.activeConnector[0], p, receiver),
      getOwnPropertyDescriptor: (target, p) =>
        Reflect.getOwnPropertyDescriptor(this.activeConnector[0], p),
      getPrototypeOf: () => WalletConnectV2.prototype,
      set: (target, p, receiver) => Reflect.set(this.activeConnector[0], p, receiver),
    }
  ) as (typeof this.activeConnector)[0];
  private proxyHooks = new Proxy(
    {},
    {
      get: (target, p, receiver) => {
        return () => {
          // Because our connectors are referentially stable (through proxying), we need a way to trigger React renders
          // from outside of the React lifecycle when our connector is re-initialized. This is done via 'change' events
          // with `useSyncExternalStore`:
          const hooks = useSyncExternalStore(
            (onChange) => {
              this.onActivate = onChange;

              return () => (this.onActivate = undefined);
            },
            () => this.activeConnector[1]
          );

          return Reflect.get(hooks, p, receiver)();
        };
      },
    }
  ) as (typeof this.activeConnector)[1];

  private onActivate?: () => void;

  overrideActivate = (chainId?: any) => {
    // Always re-create the connector, so that the chainId is updated.
    this.activeConnector = initializeConnector((actions) => this.initializer(actions, chainId));
    this.onActivate?.();

    return false;
  };

  get connector() {
    return this.proxyConnector;
  }
  get hooks() {
    return this.proxyHooks;
  }
})();

// export const [web3WalletConnectV2, web3WalletConnectV2Hooks] = initializeConnector<WalletConnectV2>(
//   (actions) =>
//     new WalletConnectV2({
//       actions,
//       onError,
//       options: {
//         // todo: use env
//         projectId: '7be020cc515751d6603e426e823b5ad8',
//         chains: [Number(process.env.REACT_APP_NODE_CHAIN_ID)],
//         showQrModal: true,
//         qrModalOptions: {
//           themeVariables: {
//             '--wcm-z-index': '400',
//           },
//         },
//       },
//     })
// );

export const [web3Injected, web3InjectedHooks] = initializeConnector<MetaMask>(
  (actions) => new MetaMask({ actions, onError })
);

export const [web3CoinbaseWallet, web3CoinbaseWalletHooks] = initializeConnector<CoinbaseWallet>(
  (actions) =>
    new CoinbaseWallet({
      actions,
      onError,
      options: {
        url: RPC_URLS[SupportedChainId.Polygon][0],
        // @ts-ignore
        appName: 'Maincard',
        reloadOnDisconnect: false,
      },
    })
);

export const connections: Record<ConnectionType, Connection> = {
  [ConnectionType.Injected]: {
    name: 'Metamask',
    connector: web3Injected,
    hooks: web3InjectedHooks,
    type: ConnectionType.Injected,
    icon: 'wallet-metamask',
    installed: isMetamaskInjected(),
    deepLink: 'https://metamask.app.link/dapp/app.maincard.io/',
    downloadLink:
      'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
  },
  [ConnectionType.WalletConnectV2]: walletConnectV2Connection,
  [ConnectionType.Coinbase]: {
    name: 'Coinbase',
    connector: web3CoinbaseWallet,
    hooks: web3CoinbaseWalletHooks,
    type: ConnectionType.Coinbase,
    icon: 'wallet-coinbase',
    installed: isCoinbaseInjected(),
    deepLink: 'https://go.cb-w.com/dapp?cb_url=https://app.maincard.io/',
  },
};

export const connectors: Array<[Connector, Web3ReactHooks]> = Object.values(connections).map(
  ({ connector, hooks }) => [connector, hooks]
);

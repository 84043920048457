import { Button as MuiBaseButton } from '@mui/base';

import { styled } from '~shared/lib/styles';

export const toggleButtonClasses = {
  root: 'ToggleButton-root',

  selected: 'ToggleButton-selected',
  disabled: 'ToggleButton-disabled',

  tag: 'ToggleButton-tag',
  pill: 'ToggleButton-pill',
};

export const UIKitToggleButtonRoot = styled(MuiBaseButton, { name: 'ToggleButton', slot: 'root' })(
  ({ theme }) => ({
    background: theme.palette.grey.A5,
    color: theme.palette.text.secondary,
    padding: '4px 10px',
    borderRadius: 8,
    border: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    height: 32,

    fontSize: 14,
    fontWeight: 400,
    lineHeight: 24 / 14,

    transition: theme.transitions.create(['background', 'color']),

    [`&:hover:not(:disabled):not(.${toggleButtonClasses.selected})`]: {
      background: theme.palette.grey.A10,
    },

    [`&.${toggleButtonClasses.disabled}`]: {
      color: theme.palette.grey.A30,
      cursor: 'not-allowed',
    },

    [`&.${toggleButtonClasses.selected}`]: {
      background: theme.palette.grey.A20,
    },

    [`&.${toggleButtonClasses.pill}`]: {
      borderRadius: 999,
      fontSize: 13,
      lineHeight: 14 / 13,
      padding: '6px 10px',
      color: theme.palette.text.primary,

      [`&.${toggleButtonClasses.disabled}`]: {
        color: theme.palette.grey.A30,
        cursor: 'not-allowed',
      },

      // [`&:hover:not(:disabled):not(.${toggleButtonClasses.selected})`]: {
      //   background: theme.palette.primary.light,
      // },

      [`&.${toggleButtonClasses.selected}`]: {
        background: theme.palette.primary.main,
      },
    },
  })
);

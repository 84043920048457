import { Icon, IconAssetNamesType, PillMenu, Stack, Typography } from '~shared/ui';
import { useInventorySelector } from '~widgets/inventory/model';
import { useConsumablesQuery } from '~widgets/inventory/model/hooks';

export const Inventory = () => {
  useConsumablesQuery();
  const consumables = useInventorySelector();

  const canRenderInventoryConsumables =
    consumables.freeBet !== 0 || consumables.repair !== 0 || consumables.unfreeze !== 0;

  const canRenderMergeConsumables =
    consumables.rareMerge !== 0 ||
    consumables.epicMerge !== 0 ||
    consumables.legendaryMerge !== 0 ||
    consumables.mythicMerge !== 0;

  const canRenderTournamentConsumables = consumables.rareTournamentTicket !== 0;

  const isMenuDisabled = Object.values(consumables)
    .slice(0, -1)
    .every((consumable) => consumable === 0);

  const pillMenuLabel = (
    <Stack direction="row" spacing={10 / 8}>
      <Icon size={20} name="unfreeze" />
      {/* <Typography color="grey.A50">{consumables.unfreeze}</Typography> */}
    </Stack>
  );

  const renderMenuItem = (iconName: IconAssetNamesType, label: string, amount: number) => {
    if (amount === 0) {
      return;
    }

    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between" paddingBlock="4px">
        <Stack flexDirection="row" gap="10px" alignItems="center" justifyContent="center">
          <Icon size={20} name={iconName} />
          <Typography mt="0 !important">{label}</Typography>
        </Stack>
        <Typography>{amount}</Typography>
      </Stack>
    );
  };

  return (
    <PillMenu label={pillMenuLabel} disabled={isMenuDisabled}>
      <Stack paddingBlock="22px" paddingInline="16px" width={260}>
        {canRenderInventoryConsumables && (
          <Stack>
            <Typography color="grey.A50" paddingBottom="12px">
              Items
            </Typography>
            {renderMenuItem('unfreeze', 'Unfreezes', consumables.unfreeze)}
            {renderMenuItem('freebet', 'Freebets', consumables.freeBet)}
            {renderMenuItem('repair-icon', 'Repairs', consumables.repair)}
          </Stack>
        )}

        {canRenderMergeConsumables && (
          <Stack>
            <Typography color="grey.A50" paddingBlock="12px">
              Merge
            </Typography>
            {renderMenuItem('rare-merge', 'Rare', consumables.rareMerge)}
            {renderMenuItem('epic-merge', 'Epic', consumables.epicMerge)}
            {renderMenuItem('legendary-merge', 'Legendary', consumables.legendaryMerge)}
            {renderMenuItem('mythic-merge', 'Mythic', consumables.mythicMerge)}
          </Stack>
        )}

        {canRenderTournamentConsumables && (
          <Stack>
            <Typography color="grey.A50" paddingBlock="12px">
              Tournament tickets
            </Typography>
            {renderMenuItem('rare-tournament-ticket', 'Rare', consumables.rareTournamentTicket)}
            {renderMenuItem('epic-tournament-ticket', 'Epic', consumables.epicTournamentTicket)}
            {renderMenuItem(
              'legendary-tournament-ticket',
              'Legendary',
              consumables.legendaryTournamentTicket
            )}
            {renderMenuItem(
              'mythic-tournament-ticket',
              'Mythic',
              consumables.mythicTournamentTicket
            )}
          </Stack>
        )}
      </Stack>
    </PillMenu>
  );
};

import { FC, Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import { NftCard } from '~entities/nft';

import { Nft } from '~shared/api';
import { useSwitcher } from '~shared/lib/hooks';
import { SelectField, SelectOption, ToggleButton, Typography } from '~shared/ui';

import { VIEWER_WALLET_CARDS_FILTERS, ViewerWalletNftRarityFilter } from '~widgets/viewer-wallet';

import { useTheme } from '~shared/lib/styles';

import { useFilterForUserProfile } from '../model/hooks';

import { UserProfileCardsListProps } from './types';

import { FilterBlock, NftBlock, ViewerWalletFiltersContainer } from './styled';

export const UserProfileCardsList: FC<UserProfileCardsListProps> = (props) => {
  const { t } = useTranslation();

  const open = useSwitcher(false);
  const { palette } = useTheme();
  const { rarity, setRarity, filteredNfts, byRarity, toggleRarityFilter, nfts } =
    useFilterForUserProfile(props);

  const handleChangeLanguageCurry = (e: any, value: any) => {
    open.toggle();
    setRarity(value);
  };

  if (!filteredNfts) {
    return null;
  }

  return (
    <Fragment>
      <FilterBlock>
        <SelectField value={rarity} onChange={handleChangeLanguageCurry}>
          {options.map((currency) => (
            <SelectOption key={currency.value} value={currency.value}>
              {t(currency.label)}
            </SelectOption>
          ))}
        </SelectField>
      </FilterBlock>

      <ViewerWalletFiltersContainer>
        {VIEWER_WALLET_CARDS_FILTERS.map((tab) => {
          const nftsQuantity =
            tab.value === ViewerWalletNftRarityFilter.All
              ? nfts.length
              : nfts.filter((nft) => nft.rarity.valueOf() === tab.value.valueOf()).length;

          const hasNfts = nftsQuantity !== 0;

          return (
            <ToggleButton
              key={tab.value}
              value={tab.value}
              selected={byRarity.includes(tab.value)}
              disabled={!hasNfts}
              onChange={(event, value: ViewerWalletNftRarityFilter) => toggleRarityFilter(value)}
              variant="pill"
            >
              {t(tab.label)}
              {hasNfts ? (
                <Typography component="span" color={palette.grey.A50}>
                  &nbsp;{nftsQuantity}
                </Typography>
              ) : null}
            </ToggleButton>
          );
        })}
      </ViewerWalletFiltersContainer>

      <NftBlock>
        {filteredNfts &&
          filteredNfts.map((nft: any) => (
            <NftCard key={nft.token_id} nft={nft as Nft} mr={4} mt={2} />
          ))}
      </NftBlock>
    </Fragment>
  );
};

const options: Array<{ value: number; label: string }> = [
  { value: 0, label: 'Other.byRarity' },
  { value: 1, label: 'Other.byWinStreak' },
];

import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MarketplaceCurrencyValue } from '~features/marketplace/lib/currencyOptions';

import { MarketplaceBuyMysteryBoxForm } from '~features/marketplace/ui/MarketplaceBuyBoxForm';
import { useMediaQuery } from '~shared/lib/hooks';

import { Stack, Typography } from '~shared/ui';

import { MarketplaceDetailsEntries } from '../MarketplaceDetailsEntries';

import { MarketplaceMysteryBoxDetailsProps } from './types';

export const MarketplaceMysteryBoxDetails: FC<MarketplaceMysteryBoxDetailsProps> = ({
  marketplaceItem,
}) => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const [currency, setCurrency] = useState<MarketplaceCurrencyValue>(
    MarketplaceCurrencyValue.Matic
  );

  return (
    <Stack flex={1} spacing={20 / 8}>
      <MarketplaceDetailsEntries
        entries={[
          { label: `${t('Marketplace.lives')} `, value: '?' },
          {
            label: `${t('Marketplace.winStreak')} `,
            value: '?',
          },
          {
            label: `${t('Marketplace.winReward')} `,
            value: '?',
          },
          { label: `${t('Marketplace.freezeTime')} `, value: '?' },
          {
            label: `${t('Marketplace.repairPrice')} `,
            value: '?',
          },
        ]}
      />

      <Typography color="secondary" sx={{ fontSize: '13px' }}>
        A Mystery box with a probability of 98% will give you a Maincard of the type to which this
        box belongs. You have a 2% chance to drop a Maincard of the next rarity level. A secret box
        is 2% more expensive than a card of the same level.
      </Typography>

      <MarketplaceBuyMysteryBoxForm
        rarity={marketplaceItem.rarity}
        currency={currency}
        fixedLayout={isMobile}
        price={marketplaceItem.price}
        onChangeCurrency={setCurrency}
      />
    </Stack>
  );
};

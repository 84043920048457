import { AuthSteps, useAuthModel } from '~widgets/auth/model';

import {
  Authentication,
  ForgotPassword,
  Identification,
  IntroduceYourself,
  PasswordCreation,
  RecoveryLink,
  VerificationCode,
} from '../../steps';

const steps = {
  [AuthSteps.Identification]: Identification,

  [AuthSteps.Authentication]: Authentication,

  [AuthSteps.IntroduceYourself]: IntroduceYourself,
  [AuthSteps.CreatePassword]: PasswordCreation,
  [AuthSteps.VerificationCode]: VerificationCode,

  [AuthSteps.ForgotPassword]: ForgotPassword,
  [AuthSteps.RecoveryLink]: RecoveryLink,
};

export const AuthMaincard = () => {
  const { step } = useAuthModel();

  const Step = steps[step];

  return <Step />;
};

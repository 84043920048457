import { nftActions, useQueryViewerNfts } from '~entities/nft';

import { ViewerWalletTab, useViewerWalletModel } from '../model';

import { ViewerWalletContentPlaceholder } from './ViewerWalletContentPlaceholder';
import { ViewerWalletReturnDropZone } from './ViewerWalletReturnDropZone';
import { ViewerWalletRarityFilters } from './ViewerWalletRarityFilters';
import { ViewerWalletTournaments } from './ViewerWalletTournaments';
import { ViewerWalletActions } from './ViewerWalletActions';
import { ViewerWalletHeader } from './ViewerWalletHeader';
import { ViewerWalletList } from './ViewerWalletList';
import { ViewerWalletTabs } from './ViewerWalletTabs';

import { ViewerWalletContentContainer, ViewerWalletRoot } from './styled';

export const ViewerWallet = () => {
  const { walletTab, isSelectionMode, selectionModeType, showTabs } = useViewerWalletModel();
  const { nfts, isLoading, isFetched } = useQueryViewerNfts();

  const hasCardsCondition = !nfts.length && isFetched;

  const renderTab = () => {
    switch (walletTab) {
      case ViewerWalletTab.All:
        return <ViewerWalletList loading={isLoading} />;
      case ViewerWalletTab.Tournaments:
        return <ViewerWalletTournaments />;
    }
  };

  const showTabsSeparately = isSelectionMode ? selectionModeType !== 'bet' : true;

  return (
    <ViewerWalletRoot>
      {hasCardsCondition ? (
        <ViewerWalletContentPlaceholder />
      ) : (
        <ViewerWalletContentContainer>
          <ViewerWalletHeader />
          {showTabs && showTabsSeparately && <ViewerWalletTabs marginBottom />}
          <ViewerWalletRarityFilters />

          <ViewerWalletReturnDropZone />

          {renderTab()}
        </ViewerWalletContentContainer>
      )}

      <ViewerWalletActions />
    </ViewerWalletRoot>
  );
};

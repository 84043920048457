import { routes } from '~shared/config';

import { IconAssetNamesType } from '~shared/ui';

import {
  CS2LeaguesConfig,
  DotaLeaguesConfig,
  FootballLeaguesConfig,
  LeagueOfLegendsLeaguesConfig,
  SportItemConfig,
  SportsConfig,
  SportsIllustrations,
  TennisLeaguesConfig,
} from '~shared/config/sport';

export interface SportsMenuListItem {
  id: string;
  route: string;
  label: string;
  sidebarLabel?: string;
  sectionLabel?: string;
  parentId?: string;

  icon: IconAssetNamesType | null;
  illustration: string;
  dates: SportItemConfig['dates'];

  items?: SportsMenuListItem[];
}

export const SportsMenuList: SportsMenuListItem[] = [
  {
    id: 'overall',
    label: 'LeftSideBar.allEventsHome',
    sidebarLabel: 'Home',
    icon: 'sport-home',
    illustration: SportsIllustrations.all,
    route: routes.index,
    dates: null,
  },
  {
    ...SportsConfig.soccer,
    id: 'sport-soccer-menu',
    items: [
      { ...SportsConfig.soccer, label: 'All football events', sectionLabel: 'Football' },

      ...Object.values(FootballLeaguesConfig),
    ],
  },
  {
    id: 'esports',
    label: 'Esports',
    icon: 'sport-pad',
    illustration: SportsIllustrations.eSport,
    route: routes.sports.dota,
    dates: null,
    items: [
      {
        id: 'esports-all',
        label: 'All Esports events',
        sectionLabel: 'Esports',
        route: routes.sports.esports,
        icon: 'sport-pad',
        illustration: '',
        dates: null,
      },
      {
        ...SportsConfig.dota2,
        parentId: 'esports-all',
        id: 'sport-dota-menu',
        items: [
          { ...SportsConfig.dota2, label: 'All Dota 2 events', sectionLabel: 'Dota 2' },
          ...Object.values(DotaLeaguesConfig),
        ],
      },
      {
        ...SportsConfig.csgo,
        parentId: 'esports-all',
        id: 'sport-cs2-menu',
        items: [
          { ...SportsConfig.csgo, label: 'All CS 2 events', sectionLabel: 'CS 2' },
          ...Object.values(CS2LeaguesConfig),
        ],
      },
      {
        ...SportsConfig.lol,
        parentId: 'esports-all',
        id: 'sport-lol-menu',
        items: [
          {
            ...SportsConfig.lol,
            label: 'All League of Legends events',
            sectionLabel: 'League of Legends',
          },
          ...Object.values(LeagueOfLegendsLeaguesConfig),
        ],
      },
    ],
  },
  {
    ...SportsConfig.tennis,
    id: 'sport-tennis-menu',
    items: [
      { ...SportsConfig.tennis, label: 'All tennis events', sectionLabel: 'Tennis' },
      ...Object.values(TennisLeaguesConfig),
    ],
  },
];

import { styled } from '~shared/lib/styles';

export const ViewerWalletTournamentsPlaceholderRoot = styled('div', {
  name: 'ViewerWalletTournamentsPlaceholder',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  flex: 1,
  marginTop: 16,
}));

export const ViewerWalletTournamentsPlaceholderHeading = styled('div', {
  name: 'ViewerWalletTournamentsPlaceholder',
  slot: 'Heading',
})(() => ({
  fontSize: 15,
  fontWeight: 600,
  lineHeight: 18 / 15,
  letterSpacing: -0.2,

  marginTop: 6,
}));

export const ViewerWalletTournamentsPlaceholderCaption = styled('div', {
  name: 'ViewerWalletTournamentsPlaceholder',
  slot: 'Caption',
})(({ theme }) => ({
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 14 / 13,
  color: theme.palette.grey.A75,

  marginTop: 6,
}));

export const ViewerWalletTournamentsPlaceholderImage = styled('img', {
  name: 'ViewerWalletTournamentsPlaceholder',
  slot: 'Image',
})(() => ({
  maxWidth: 240,
  height: 'auto',
  width: '100%',
  objectPosition: 'center',
}));

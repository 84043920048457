import { Address, toNano } from '@ton/core';
import { useTranslation } from 'react-i18next';
import { useTonAddress, useTonWallet } from '@tonconnect/ui-react';

import { NftRarity } from '~shared/api';

import { useConnect, useWriteContract } from '~entities/wallet';

interface UseBuyCardForMaticProps {
  price: number;
  rarity: NftRarity;
}

export const useBuyCardForMatic = ({ price, rarity }: UseBuyCardForMaticProps) => {
  const { t } = useTranslation();
  const address = useTonAddress(true);
  const wallet = useTonWallet();
  const { write: buyNft } = useWriteContract({
    contractName: 'CardCollection',
    method: 'Mint',
    transactionName: 'Minting card',
    successMessage: `${t('Alerts.successfulMint')}`,
    errorMessage: `${t('Errors.cardPurchaseFailed')}`,
  });

  const { openConnectWalletWarnDialog } = useConnect();

  const handleBuyForMatic = async () => {
    // if (!tonBalance || tonBalance < price) {
    //   setIsLoading(false);

    //   closeTransactionStatusDialog();

    //   openSnackbar({ type: 'error', message: "You don't have enough TON" });

    //   return;
    // }

    // MOCK

    try {
      await buyNft({
        args: {
          $$type: 'Mint',
          mint_to: Address.parse(address),
          rarity: BigInt(rarity),
        },
        value: toNano(price + 0.35),
      });
    } catch (e) {}
  };

  const handleBuyCard = async () => {
    if (!wallet) {
      openConnectWalletWarnDialog();

      return;
    }

    await handleBuyForMatic();
  };

  return {
    handleBuyCard,
  };
};

import { styled } from '~shared/lib/styles';
import { Dialog, dialogClasses } from '~shared/ui';

export const JoinTournamentDialogRoot = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.up('mobile')]: {
    [`& .${dialogClasses.scrollPaper}`]: {
      marginRight: 320,
    },
  },
}));

export const DialogCaption = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: 'center',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 18 / 14,
}));

interface DragCellContainerProps {
  $count: number;
}

export const DragCellContainer = styled('div')<DragCellContainerProps>(({ theme, $count }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${$count}, 72px)`,
  gap: 16,
  alignSelf: 'center',

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: `repeat(${getRowCount($count)}, 72px)`,
  },
}));

const getRowCount = (count: number) => {
  switch (count) {
    case 2:
    case 4:
      return 2;
    case 6:
      return 3;
    case 8:
      return 4;
  }
};

import { FC } from 'react';
import { useDragDropManager, useDragLayer } from 'react-dnd';

import { NftDragItemType } from '../../model';

import { NftDragLayerPreview, NftDragLayerPreviewWrapper, NftDragLayerRoot } from './styled';

import { NftDragLayerProps, UseDragLayerCollectedProps } from './types';

export const NftDragLayer: FC<NftDragLayerProps> = () => {
  const manager = useDragDropManager();

  const { item, clientOffset, itemType } = useDragLayer<UseDragLayerCollectedProps>((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType() as NftDragItemType | null,
    clientOffset: monitor.getClientOffset(),
  }));

  const isOverAnyTarget = manager.getMonitor().getTargetIds().length > 0;
  const isNftItemType = Object.values(NftDragItemType).includes(itemType!);

  if (!item || !isNftItemType || !clientOffset) {
    return null;
  }

  return (
    <NftDragLayerRoot>
      <NftDragLayerPreviewWrapper style={getPreviewStyle(clientOffset.x, clientOffset.y)}>
        <NftDragLayerPreview nft={item.tokenId} $isOverAnyTarget={isOverAnyTarget} />
      </NftDragLayerPreviewWrapper>
    </NftDragLayerRoot>
  );
};

const getPreviewStyle = (x: number, y: number) => {
  return { transform: `translate(${x}px, ${y}px)` };
};

import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const ItemCard = styled(Box)(({ theme }) => ({
  background: theme.palette.grey.A5,
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '12px',
  gap: '70px',

  [theme.breakpoints.down('mobile')]: {
    gap: '20px',
    borderRadius: 0,
    padding: '20px 30px 30px 30px',
  },
}));

export const StyledMarketplaceItemImage = styled('img')(({ theme }) => ({
  maxHeight: '191px',
  maxWidth: '130px',
  marginBottom: '30px',
  objectFit: 'contain',

  [theme.breakpoints.down('mobile')]: {
    maxHeight: '146px',
    maxWidth: '100px',
    marginBottom: 0,
  },
}));

import { styled } from '~shared/lib/styles';

export const CoeffContainer = styled('div')(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(1px, 1fr))',
  alignItems: 'start',
  gap: '20px',
  marginTop: '10px',
}));

type CoeffWrapperType = {
  $color: string;
};

export const CoeffWrapper = styled('div')<CoeffWrapperType>(({ $color }) => {
  const colorMap: Record<string, string> = {
    red: 'rgba(204, 40, 79, 0.3)',
    green: 'rgba(89, 146, 54, 0.3)',
    blue: 'rgba(41, 137, 227, 0.3)',
  };

  return {
    flex: 1,
    borderRadius: '8px',
    padding: '8px',
    backgroundColor: colorMap[$color],
  };
});

export const Coeff = styled('div')<CoeffWrapperType>(({ $color }) => {
  const colorMap: Record<string, string> = {
    red: 'rgba(204, 40, 79, 1)',
    green: 'rgba(89, 146, 54, 1)',
    blue: 'rgba(41, 137, 227, 1)',
  };

  return {
    border: '1px solid rgba(255, 255, 255, 0.2)',
    padding: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    justifySelf: 'center',
    backgroundColor: colorMap[$color],

    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '11.74px',
    textTransform: 'uppercase',
  };
});
// export const BattleTeamCoeffContainer = styled('div')<BattleTeamCoeffContainerProps>(({
//   $size,
//   $variant,
//   $color,
// }) => {
//   const variantStyles: Record<BattleShellVariant, Record<BattleShellSize, CSSObject>> = {
//     square: {
//       sm: { fontSize: 12, lineHeight: 14 / 12, maxHeight: 20 },
//       md: { fontSize: 13, lineHeight: 14 / 13, maxHeight: 20 },
//       lg: { fontSize: 14, lineHeight: 14 / 14, maxHeight: 20 },
//     },

//     line: {
//       sm: { fontSize: 13, lineHeight: 14 / 12 },
//       md: { fontSize: 14, lineHeight: 14 / 13 },
//       lg: { fontSize: 15, lineHeight: 19 / 14 },
//     },

//     compact: {
//       sm: { fontSize: 13, lineHeight: 14 / 13 },
//       md: { fontSize: 13, lineHeight: 14 / 13 },
//       lg: { fontSize: 13, lineHeight: 14 / 13 },
//     },
//   };

//   const colorMap: Record<string, string> = {
//     red: 'rgba(204, 40, 79, 1)',
//     green: 'rgba(89, 146, 54, 1)',
//     blue: 'rgba(41, 137, 227, 1)',
//   };

//   const borderColorMap: Record<string, string> = {
//     red: 'rgba(204, 40, 79, 0.8)',
//     green: 'rgba(89, 146, 54, 0.8)',
//     blue: 'rgba(41, 137, 227, 0.8)',
//   };

//   return {
//     gridArea: 'coefficient-container',
//     backgroundColor: colorMap[$color],
//     borderColor: borderColorMap[$color],
//     padding: 3,
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     borderRadius: 5,
//     width: 82,
//     justifySelf: 'center',
//     ...variantStyles[$variant][$size],
//   };
// });

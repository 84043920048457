import { FC } from 'react';

import { Stack, Typography } from '~shared/ui';

import { Root } from './styled';

import { EventDialogPlaceholderProps } from './types';

export const EventDialogPlaceholder: FC<EventDialogPlaceholderProps> = ({ title, description }) => {
  return (
    <Root>
      <Stack spacing={10 / 8}>
        <Typography variant="h3" fontSize="16px" textAlign="center">
          {title}
        </Typography>
        <Typography color="secondary" textAlign="center">
          {description}
        </Typography>
      </Stack>
    </Root>
  );
};

import { addSeconds } from 'date-fns';

import {
  ApiGetTournamentMappedResponseData,
  ApiGetTournamentRawResponseData,
  ApiGetTournamentsMappedResponseData,
  ApiGetTournamentsRawResponseData,
  ApiPostJoinTournamentMappedResponseData,
  ApiPostJoinTournamentRawResponseData,
} from '~shared/api';
import { getAvatarAbsoluteUri } from '~shared/lib/utils';

export const mapJoinTournamentData = (
  data: ApiPostJoinTournamentRawResponseData
): ApiPostJoinTournamentMappedResponseData => {
  return {
    message: data.result,
    hash: data.transaction,
  };
};

export const mapTournamentsListData = (
  data: Array<ApiGetTournamentsRawResponseData>
): Array<ApiGetTournamentsMappedResponseData> => {
  return data.map(mapTournamentData).filter((tournament) => tournament.network === 'ton');
};

export const mapTournamentData = ({
  tournamentId,
  tournamentRarity,
  name,
  sport,
  network,
  status,
  tonContractAddress,
  ...tournament
}: ApiGetTournamentRawResponseData): ApiGetTournamentMappedResponseData => {
  const startDate = new Date(tournament.startTime);
  const endDate = addSeconds(startDate, tournament.durationSec);

  const joinPrices = [{ value: tournament.joinPrice, currency: tournament.joinCurrency }];

  return {
    name,
    tournamentRarity,
    tournamentId,
    startDate,
    endDate,
    sport,
    network,
    status,
    tonContractAddress,

    playersLimit: tournament.maxParticipants,

    players:
      tournament.players
        ?.sort((a, b) => a.place - b.place)
        .map((player) => ({
          ...player,
          // @ts-ignore
          loses: player.bets - player.wins,
          avatar: getAvatarAbsoluteUri(player.avatar),
        })) ?? [],

    joinCardsQuantity: tournament.joinCardQuantity,

    prizes: tournament.prizes
      .sort((a, b) => a.Place - b.Place)
      .map((prize) => ({
        value: prize.Value,
        currency: prize.Currency,
        place: prize.Place,
        items: prize.Items,
      })),

    // todo: WIP
    joinPrices,
    defaultJoinPrice: joinPrices[0],
  };
};

// const nameList = [
//   'Time',
//   'Past',
//   'Future',
//   'Dev',
//   'Fly',
//   'Flying',
//   'Soar',
//   'Soaring',
//   'Power',
//   'Falling',
//   'Fall',
//   'Jump',
//   'Cliff',
//   'Mountain',
//   'Rend',
//   'Red',
//   'Blue',
//   'Green',
//   'Yellow',
//   'Gold',
//   'Demon',
//   'Demonic',
//   'Panda',
//   'Cat',
//   'Kitty',
//   'Kitten',
//   'Zero',
//   'Memory',
//   'Trooper',
//   'XX',
//   'Bandit',
//   'Fear',
//   'Light',
//   'Glow',
//   'Tread',
//   'Deep',
//   'Deeper',
//   'Deepest',
//   'Mine',
//   'Your',
//   'Worst',
//   'Enemy',
//   'Hostile',
//   'Force',
//   'Video',
//   'Game',
//   'Donkey',
//   'Mule',
//   'Colt',
//   'Cult',
//   'Cultist',
//   'Magnum',
//   'Gun',
//   'Assault',
//   'Recon',
//   'Trap',
//   'Trapper',
//   'Redeem',
//   'Code',
//   'Script',
//   'Writer',
//   'Near',
//   'Close',
//   'Open',
//   'Cube',
//   'Circle',
//   'Geo',
//   'Genome',
//   'Germ',
//   'Spaz',
//   'Shot',
//   'Echo',
//   'Beta',
//   'Alpha',
//   'Gamma',
//   'Omega',
//   'Seal',
//   'Squid',
//   'Money',
//   'Cash',
//   'Lord',
//   'King',
//   'Duke',
//   'Rest',
//   'Fire',
//   'Flame',
//   'Morrow',
//   'Break',
//   'Breaker',
//   'Numb',
//   'Ice',
//   'Cold',
//   'Rotten',
//   'Sick',
//   'Sickly',
//   'Janitor',
//   'Camel',
//   'Rooster',
//   'Sand',
//   'Desert',
//   'Dessert',
//   'Hurdle',
//   'Racer',
//   'Eraser',
//   'Erase',
//   'Big',
//   'Small',
//   'Short',
//   'Tall',
//   'Sith',
//   'Bounty',
//   'Hunter',
//   'Cracked',
//   'Broken',
//   'Sad',
//   'Happy',
//   'Joy',
//   'Joyful',
//   'Crimson',
//   'Destiny',
//   'Deceit',
//   'Lies',
//   'Lie',
//   'Honest',
//   'Destined',
//   'Bloxxer',
//   'Hawk',
//   'Eagle',
//   'Hawker',
//   'Walker',
//   'Zombie',
//   'Sarge',
//   'Capt',
//   'Captain',
//   'Punch',
//   'One',
//   'Two',
//   'Uno',
//   'Slice',
//   'Slash',
//   'Melt',
//   'Melted',
//   'Melting',
//   'Fell',
//   'Wolf',
//   'Hound',
//   'Legacy',
//   'Sharp',
//   'Dead',
//   'Mew',
//   'Chuckle',
//   'Bubba',
//   'Bubble',
//   'Sandwich',
//   'Smasher',
//   'Extreme',
//   'Multi',
//   'Universe',
//   'Ultimate',
//   'Death',
//   'Ready',
//   'Monkey',
//   'Elevator',
//   'Wrench',
//   'Grease',
//   'Head',
//   'Theme',
//   'Grand',
//   'Cool',
//   'Kid',
//   'Boy',
//   'Girl',
//   'Vortex',
//   'Paradox',
// ];
//
// const mockPlayers: ApiGetTournamentsPlayer[] = [
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
//   { name: 'Diablo123', place: 0, wallet: '123', avatar: '', cards: [1234] },
// ].map((player, index) => {
//   const loses = 0; //Math.floor(Math.random() * 10);
//   const wins = 0; //Math.floor(Math.random() * 10);
//
//   return {
//     ...player,
//     name: nameList[Math.floor(Math.random() * nameList.length)],
//     place: ++index,
//
//     loses,
//     wins,
//     gainedMCN: 0, //Math.floor(Math.random() * 100000),
//     betsQuantity: loses + wins,
//   };
// });

import { ChangeEvent, FC, forwardRef, useCallback } from 'react';
import { motion } from 'framer-motion';
import { clsx } from 'clsx';

import { useControlled } from '~shared/lib/hooks';

import { Icon } from '../../dataDisplay';

import { UIKitCheckboxInput, UIKitCheckboxRoot, checkboxClasses } from './styled';
import { CheckCircle, CheckSquare } from './assets';

import { CheckboxProps } from './types';

export const Checkbox: FC<CheckboxProps> = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      round,
      checked,
      disabled,
      defaultChecked = false,
      onChange,
      className,
      required,
      value,
      id,
      inputRef,
      inputProps,
    },
    ref
  ) => {
    const [controlledChecked, setChecked] = useControlled({
      controlled: checked,
      default: defaultChecked,
    });

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (event.nativeEvent.defaultPrevented) {
          return;
        }

        const checked = event.target.checked;

        setChecked(checked);

        if (onChange) {
          onChange(event, checked);
        }
      },
      [onChange, setChecked]
    );

    return (
      <UIKitCheckboxRoot
        ref={ref}
        className={clsx(
          checkboxClasses.root,
          {
            [checkboxClasses.checked]: controlledChecked,
            [checkboxClasses.disabled]: disabled,
            [checkboxClasses.round]: round,
          },
          className
        )}
      >
        <motion.div
          className={checkboxClasses.iconContainer}
          animate={controlledChecked ? MotionVariant.Visible : MotionVariant.Hidden}
          variants={motionVariants}
          initial={MotionVariant.Hidden}
        >
          {round ? (
            <Icon className={checkboxClasses.icon} component={CheckCircle} size={14} />
          ) : (
            <Icon className={checkboxClasses.icon} component={CheckSquare} size={18} />
          )}
        </motion.div>

        <UIKitCheckboxInput
          {...inputProps}
          className={checkboxClasses.input}
          disabled={disabled}
          required={required}
          id={id}
          value={value}
          ref={inputRef}
          type="checkbox"
          checked={controlledChecked}
          onChange={handleChange}
        />
      </UIKitCheckboxRoot>
    );
  }
);

const MotionVariant = {
  Visible: 'visible',
  Hidden: 'hidden',
};

const motionVariants = {
  [MotionVariant.Visible]: {
    rotate: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 0.2, type: 'tween' },
  },
  [MotionVariant.Hidden]: {
    rotate: 10,
    y: -3,
    opacity: 0,
    transition: { duration: 0.2, type: 'tween' },
  },
};

import { FC } from 'react';

import { theme } from '~shared/lib/styles';
import { Avatar, Divider, Drawer, Typography } from '~shared/ui';

import {
  LeaderboardMobileAvatarContainer,
  LeaderboardMobileInfoContainer,
  LeaderboardMobileInfoRow,
  LeaderboardMobileNicknameContainer,
  LeaderboardRowName,
} from './styled';
import { LeaderboardInfoDrawerProps } from './types';

export const LeaderboardInfoDrawer: FC<LeaderboardInfoDrawerProps> = ({ leaderInfo, ...props }) => {
  return (
    <Drawer {...props}>
      <LeaderboardMobileNicknameContainer>
        <LeaderboardMobileAvatarContainer>
          <Avatar size={32} marginRight={1} src={leaderInfo.avatar} />
          <LeaderboardRowName>{leaderInfo.nickname}</LeaderboardRowName>
        </LeaderboardMobileAvatarContainer>
        <Typography variant="h2">{leaderInfo.place}</Typography>
      </LeaderboardMobileNicknameContainer>

      <Divider />

      <LeaderboardMobileInfoContainer>
        {/*<LeaderboardMobileInfoRow>*/}
        {/*  <Typography color={theme.palette.grey.A50} fontSize={16}>*/}
        {/*    Accuracy*/}
        {/*  </Typography>*/}
        {/*  <Typography fontSize={16}>{leaderInfo.winRate}%</Typography>*/}
        {/*</LeaderboardMobileInfoRow>*/}

        <LeaderboardMobileInfoRow>
          <Typography color={theme.palette.grey.A50} fontSize={16}>
            Votes
          </Typography>
          <Typography fontSize={16}>{leaderInfo.votes}</Typography>
        </LeaderboardMobileInfoRow>

        <LeaderboardMobileInfoRow>
          <Typography color={theme.palette.grey.A50} fontSize={16}>
            MCN
          </Typography>
          <Typography fontSize={16}>{leaderInfo.coins}</Typography>
        </LeaderboardMobileInfoRow>
      </LeaderboardMobileInfoContainer>
    </Drawer>
  );
};

import { useCallback } from 'react';

import { ApiGetTournamentsMappedResponseData, Nft, PartialNft } from '~shared/api';
import { useDispatch } from '~shared/lib/hooks';

import { nftActions, useNftCardModel } from '~entities/nft';

import { joinTournamentSlice } from './slice';
import { useJoinTournamentSelector } from './selector';

export const useJoinTournamentModel = () => {
  const dispatch = useDispatch();

  const { dialogOpen, tournament, nftsToJoin } = useJoinTournamentSelector();

  const { nfts } = useNftCardModel();
  const { onAddCardsToJoinTournament } = useNftCardModel();

  const openJoinTournamentDialog = useCallback(
    (tournament?: ApiGetTournamentsMappedResponseData) => {
      dispatch(joinTournamentSlice.actions.openDialog(tournament));
    },
    [dispatch]
  );

  const closeJoinTournamentDialog = useCallback(() => {
    dispatch(joinTournamentSlice.actions.closeDialog());
  }, [dispatch]);

  const addNftToJoinTournament = useCallback(
    (nftsToAdd: Array<Nft | PartialNft>) => {
      const foundFullNfts = nftsToAdd.map((nftToAdd) => {
        return nfts.find((nft) => nft.token_id.toString() === nftToAdd.token_id.toString())!;
      });

      dispatch(joinTournamentSlice.actions.addNftsToJoin(foundFullNfts));

      dispatch(
        nftActions.setNftHidden({ nftIds: nftsToAdd.map((nft) => nft.token_id), isHidden: true })
      );
    },
    [dispatch, nfts]
  );

  const removeNftFromJoin = useCallback(
    (tokenId: string | number) => {
      dispatch(joinTournamentSlice.actions.removeNftToJoin({ tokenId }));
    },
    [dispatch]
  );

  const clearAllCards = useCallback(() => {
    dispatch(joinTournamentSlice.actions.clearAllCards());

    dispatch(
      nftActions.setNftHidden({ nftIds: nftsToJoin.map((nft) => nft.token_id), isHidden: false })
    );
  }, [dispatch, nftsToJoin]);

  const openCardsSelection = useCallback(
    (isMobile: boolean) => {
      const isAllSlotsFilled = nftsToJoin.length === tournament!.joinCardsQuantity;

      if (isMobile && !isAllSlotsFilled) {
        return () => {
          onAddCardsToJoinTournament();
          closeJoinTournamentDialog();
        };
      }

      return;
    },
    [closeJoinTournamentDialog, nftsToJoin.length, onAddCardsToJoinTournament, tournament]
  );

  return {
    tournament,
    dialogOpen,
    openJoinTournamentDialog,
    closeJoinTournamentDialog,
    removeNftFromJoin,
    addNftToJoinTournament,
    clearAllCards,
    openCardsSelection,
    nftsToJoin,
  };
};

import { ReactComponent as IllustrationLogo } from './illustrations/logo.svg';
import { ReactComponent as IllustrationClock } from './illustrations/clock.svg';
import { ReactComponent as IllustrationWinstreak } from './illustrations/winstreak.svg';

import { ReactComponent as IllustrationWhiteClock } from './illustrations/white-clock.svg';

import { ReactComponent as IllustrationFootballBundesliga } from './illustrations/leagues/football-bundesliga.svg';
import { ReactComponent as IllustrationFootballLaLiga } from './illustrations/leagues/football-la-liga.svg';
import { ReactComponent as IllustrationFootballLigue1 } from './illustrations/leagues/football-ligue-1.svg';
import { ReactComponent as IllustrationFootballPremierLeague } from './illustrations/leagues/football-premier-league.svg';
import { ReactComponent as IllustrationFootballSerieA } from './illustrations/leagues/football-serie-a.svg';
import { ReactComponent as IllustrationFootballUefaChampionsLeague } from './illustrations/leagues/football-uefa-champions-league.svg';
import { ReactComponent as IllustrationFootballUefaEuropaConferenceLeague } from './illustrations/leagues/football-uefa-europa-conference-league.svg';

import { ReactComponent as IllustrationUFC } from './illustrations/leagues/ufc.svg';
import { ReactComponent as IllustrationNBA } from './illustrations/leagues/nba.svg';
import { ReactComponent as IllustrationNHL } from './illustrations/leagues/nhl.svg';
import { ReactComponent as IllustrationIPL } from './illustrations/leagues/ipl.svg';
import { ReactComponent as IllustrationMLB } from './illustrations/leagues/mlb.svg';
import { ReactComponent as IllustrationNFL } from './illustrations/leagues/nfl.svg';

import { ReactComponent as IllustrationTennis } from './illustrations/leagues/tennis.svg';
import { ReactComponent as IllustrationTennisATP } from './illustrations/leagues/tennis-atp.svg';
import { ReactComponent as IllustrationTennisDavisCup } from './illustrations/leagues/tennis-davis-cup.svg';
import { ReactComponent as IllustrationTennisFederationCup } from './illustrations/leagues/tennis-federation-cup.svg';
import { ReactComponent as IllustrationTennisLegends } from './illustrations/leagues/tennis-legends.svg';
import { ReactComponent as IllustrationTennisWTA } from './illustrations/leagues/tennis-wta.svg';
import { ReactComponent as IllustrationTennisWTA125k } from './illustrations/leagues/tennis-wta-125k.svg';

export const illustrationsAssets = {
  'illustration-logo': IllustrationLogo,
  'illustration-clock': IllustrationClock,
  'illustration-winstreak': IllustrationWinstreak,

  'illustration-white-clock': IllustrationWhiteClock,

  'illustration-ufc': IllustrationUFC,
  'illustration-nba': IllustrationNBA,
  'illustration-nhl': IllustrationNHL,
  'illustration-ipl': IllustrationIPL,
  'illustration-mlb': IllustrationMLB,
  'illustration-nfl': IllustrationNFL,

  // tennis
  'illustration-tennis': IllustrationTennis,
  'illustration-tennis-atp': IllustrationTennisATP,
  'illustration-tennis-davis': IllustrationTennisDavisCup,
  'illustration-tennis-federation': IllustrationTennisFederationCup,
  'illustration-tennis-legends': IllustrationTennisLegends,
  'illustration-tennis-wta': IllustrationTennisWTA,
  'illustration-tennis-wta-125k': IllustrationTennisWTA125k,

  // football
  'illustration-football-bundesliga': IllustrationFootballBundesliga,
  'illustration-football-la-liga': IllustrationFootballLaLiga,
  'illustration-football-ligue-1': IllustrationFootballLigue1,
  'illustration-football-premier-league': IllustrationFootballPremierLeague,
  'illustration-football-serie-a': IllustrationFootballSerieA,
  'illustration-football-uefa-champions-league': IllustrationFootballUefaChampionsLeague,
  'illustration-football-uefa-europa-conference-league':
    IllustrationFootballUefaEuropaConferenceLeague,
};

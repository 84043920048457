import { FC, useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Button, EmailField, Icon, Link, Stack, Typography } from '~shared/ui';
import { useSwitcher } from '~shared/lib/hooks';

import { getGoogleOAuthUrl, useAuthModel } from '~widgets/auth';

import { TextAlignCenter } from '../styled';
import { AuthDialogTitle } from '../../AuthDialogTitle';

interface IdentificationForm {
  email: string;
}

export const Identification: FC = () => {
  const { t } = useTranslation();
  const submitting = useSwitcher(false);
  const form = useForm<IdentificationForm>({});

  const { identify } = useAuthModel();

  const handleSubmit: SubmitHandler<IdentificationForm> = useCallback(
    (data) => {
      submitting.switchOn();

      identify(data).then(submitting.switchOff);
    },
    [identify, submitting]
  );

  return (
    <TextAlignCenter>
      <AuthDialogTitle>{t('WelcomePopUp.welcome')}</AuthDialogTitle>

      <FormProvider {...form}>
        <Stack
          component="form"
          spacing={24 / 8}
          alignItems="stretch"
          textAlign="center"
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <Typography color="secondary" maxWidth={300} alignSelf="center" mx="auto">
            {t('WelcomePopUp.text')}
          </Typography>

          <EmailField autoFocus />

          <Button type="submit" disabled={submitting.value}>
            {t('WelcomePopUp.start')}
          </Button>

          {/* <Typography color="secondary">{t('WelcomePopUp.or')}</Typography>

          <Link to={getGoogleOAuthUrl()}>
            <Button
              startAdornment={<Icon name="social-google-colored" />}
              variant="outlined"
              width={1}
            >
              <Trans i18nKey="WelcomePopUp.signUpWith" t={t}>
                Sign Up with {{ provider: 'Google' }}
              </Trans>
            </Button>
          </Link> */}
        </Stack>
      </FormProvider>
    </TextAlignCenter>
  );
};

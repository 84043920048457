import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogTitle,
  DragCell,
  Icon,
  SelectOption,
  SelectValue,
  Stack,
  Typography,
} from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { NftCardDrag, NftDragItemType } from '~entities/nft';

import { useDropForMerge, useMerge, useMergeModel } from '../../../../model';

import { StyledSelectField } from './styled';

enum Currency {
  Ton = 'ton',
  Mcn = 'mcn',
}

export const MergeCardsDialog: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const {
    /***/
    mergeDialogOpen,

    firstNftToMerge,
    secondNftToMerge,

    openCardsToMergeSelection,
    cancelCardsMerge,
    removeAllCards,

    handleSuccessMerge,
  } = useMergeModel();

  const { dropRef, isFirstCellHighlighted, isSecondCellHighlighted } = useDropForMerge();

  const { mergePrice, isMergeAvailable, merge, rarity, iconName, selectedConsumablesCount } =
    useMerge(handleSuccessMerge);

  return (
    <Dialog open={mergeDialogOpen} onClose={cancelCardsMerge} width={440}>
      <Stack alignItems="center" spacing={(isMobile ? 20 : 24) / 8} ref={dropRef}>
        <DialogTitle>{t('Other.mergeHead')}</DialogTitle>

        <Typography textAlign="center">{t('Other.mergeText')}</Typography>

        <Stack spacing={56 / 8} direction="row" justifyContent="center" py={24 / 8}>
          <DragCell
            isHighlighted={isFirstCellHighlighted}
            isPlacedToCell={Boolean(firstNftToMerge)}
            onClick={openCardsToMergeSelection(isMobile)}
          >
            {firstNftToMerge ? (
              <NftCardDrag dragKey={NftDragItemType.Leave} nft={firstNftToMerge} />
            ) : (
              <Icon opacity={0.75} name="plus" />
            )}
          </DragCell>

          <DragCell
            isHighlighted={isSecondCellHighlighted}
            isPlacedToCell={Boolean(secondNftToMerge)}
            onClick={openCardsToMergeSelection(isMobile)}
          >
            {secondNftToMerge ? (
              <NftCardDrag dragKey={NftDragItemType.Leave} nft={secondNftToMerge} />
            ) : (
              <Icon opacity={0.75} name="plus" />
            )}
          </DragCell>
        </Stack>

        {/* <Stack direction="row" spacing={12 / 8} justifyContent="center">
          {mergePrice && (
            <StyledSelectField defaultValue={Currency.Ton} onChange={handleCurrencyChange}>
              <SelectOption value={Currency.Ton}>{mergePrice.ton} TON</SelectOption>
              <SelectOption value={Currency.Mcn}>{mergePrice.mcn} MCN</SelectOption>
            </StyledSelectField>
          )}
          <Button disabled={!isMergeAvailable} onClick={merge}>
            {t('Other.merge')}
          </Button>
        </Stack> */}

        <Stack gap="10px" width="100%">
          <Button flex={1} size="sm" disabled={!mergePrice} onClick={() => merge(false)}>
            <Stack flexDirection="row" alignItems="center" justifyContent="center" gap="8px">
              <Icon name="coin-maincoin" />
              <Typography>{mergePrice?.mcn} MCN</Typography>
            </Stack>
          </Button>
          <Button flex={1} size="sm" disabled={!mergePrice} onClick={() => merge(true)}>
            <Stack flexDirection="row" alignItems="center" justifyContent="center" gap="8px">
              <Icon name="coin-ton" />
              <Typography>{mergePrice?.ton} TON</Typography>
            </Stack>
          </Button>
          <Button
            flex={1}
            size="sm"
            variant="outlined"
            disabled={!isMergeAvailable && selectedConsumablesCount === 0}
            onClick={() => merge(false, true)}
          >
            <Stack flexDirection="row" alignItems="center" justifyContent="center" gap="8px">
              <Icon name={iconName} />
              <Typography>
                {selectedConsumablesCount} {t(rarity)} merge items
              </Typography>
            </Stack>
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

import { useInitData } from '@vkruglikov/react-telegram-web-app';
import { useEffect } from 'react';

import { useViewerModel } from '~entities/viewer';

import { useCreateReferralMutation } from './useCreateReferralMutation';

export const useReferralVerification = () => {
  const { authorized } = useViewerModel();
  const { mutate } = useCreateReferralMutation();
  const [initDataUnsafe] = useInitData();

  useEffect(() => {
    if (authorized && initDataUnsafe && initDataUnsafe.start_param) {
      mutate({
        referralCode: initDataUnsafe.start_param,
        extra: '',
      });
    }
  }, [authorized, mutate, initDataUnsafe]);

  return;
};

import React, { PropsWithChildren } from 'react';

import { Box, Button, Link, Logo, Stack, Typography } from '~shared/ui';
import { routes } from '~shared/config';

export class ErrorBoundary extends React.Component<
  PropsWithChildren,
  { hasError: boolean; errorMessage: string }
> {
  constructor(props: any) {
    super(props);

    this.state = { hasError: false, errorMessage: '' };

    this.resolveError = this.resolveError.bind(this);
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMessage: error.message };
  }

  resolveError() {
    this.setState({ hasError: false, errorMessage: '' });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Stack
          spacing={24 / 8}
          height="100vh"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Logo size={48} />

          <Box display="flex" flexDirection="column" alignItems="center">
            {/* TODO: rework this later */}
            <Typography variant="h1" align="center" pt={2}>
              {!this.state.errorMessage.includes('enhancements')
                ? 'Oops!'
                : 'Maincard Under Maintenance'}
            </Typography>
            <Typography
              variant="h3"
              fontSize={14}
              maxWidth={320}
              fontWeight={400}
              mt={1}
              color="secondary"
            >
              {this.state.errorMessage}
            </Typography>
          </Box>
          {!this.state.errorMessage.includes('enhancements') && (
            <Link to={routes.index} underline={false}>
              <Button onClick={this.resolveError}>Return to Home</Button>
            </Link>
          )}
        </Stack>
      );
    }

    return this.props.children;
  }
}

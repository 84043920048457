import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TournamentPlayersBadgeLabel,
  TournamentPlayersBadgeRoot,
  TournamentPlayersBadgeValue,
} from './styled';

import { TournamentPlayersBadgeProps } from './types';

export const TournamentPlayersBadge: FC<TournamentPlayersBadgeProps> = ({
  playersCount,
  playersLimit,
}) => {
  const { t } = useTranslation();

  return (
    <TournamentPlayersBadgeRoot>
      <TournamentPlayersBadgeLabel>{t('Tournaments.players')}</TournamentPlayersBadgeLabel>

      <TournamentPlayersBadgeValue $limitReached={playersCount === playersLimit}>
        {playersCount}/{playersLimit}
      </TournamentPlayersBadgeValue>
    </TournamentPlayersBadgeRoot>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { fromNano } from '@ton/core';

import { Button } from '~shared/ui';

import { useJoinTournamentModel } from '../../model';

import { JoinTournamentProps } from './types';

export const JoinTournamentButton: FC<JoinTournamentProps> = ({ tournament, ...buttonProps }) => {
  const { t } = useTranslation();

  const { openJoinTournamentDialog } = useJoinTournamentModel();

  const { defaultJoinPrice } = tournament;

  const handleToggleOpen = () => {
    openJoinTournamentDialog(tournament);
  };

  return (
    <Button onClick={handleToggleOpen} {...buttonProps}>
      {t('Tournaments.joinButton', {
        currency: defaultJoinPrice.currency,
        price: fromNano(defaultJoinPrice.value),
      })}
    </Button>
  );
};

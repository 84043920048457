import { FC, PropsWithChildren } from 'react';

import { Icon, Stack, Typography } from '~shared/ui';

import { EventWarningMessageProps } from './types';

export const EventWarningMessage: FC<PropsWithChildren<EventWarningMessageProps>> = ({
  children,
}) => {
  return (
    <Stack direction="row" spacing={12 / 8}>
      <Icon name="warn" size={20} />
      <Typography color="secondary">{children}</Typography>
    </Stack>
  );
};

import { FC, Fragment } from 'react';

import { htmlDefaultLayoutBottomAnchorId } from '~shared/config';
import { Box, CircularProgress } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { TournamentDialogContent } from './TournamentDialogContent';

import { TournamentDialogRoot, TournamentMobileDialogRoot } from './styled';
import { TournamentDialogProps } from './types';

export const TournamentDialog: FC<TournamentDialogProps> = ({
  tournament,
  joinButton,
  loading,

  ...dialogProps
}) => {
  const { isMobile } = useMediaQuery();

  const DialogSlot = isMobile ? TournamentMobileDialogRoot : TournamentDialogRoot;

  const renderContent = () => {
    if (!loading && tournament) {
      return (
        <TournamentDialogContent
          tournament={tournament}
          joinButton={joinButton}
          onClose={dialogProps.onClose}
        />
      );
    }

    if (loading) {
      return (
        <Box flex={1} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={48} />
        </Box>
      );
    }
  };

  return (
    <DialogSlot
      $sport={tournament?.sport}
      width={720}
      disableTransition={isMobile}
      hideBackdrop={isMobile}
      container={isMobile ? document.getElementById(htmlDefaultLayoutBottomAnchorId) : undefined}
      slots={isMobile ? { closeButton: <Fragment /> } : {}}
      {...dialogProps}
    >
      {renderContent()}
    </DialogSlot>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ConfirmationDialog } from '~shared/ui';

import { useViewerSignOutModel } from '~features/viewer/sign-out';

export const SignOutDialog: FC = () => {
  const { t } = useTranslation();

  const { signOut, closeSignOut, signOutDialogOpen } = useViewerSignOutModel();

  return (
    <ConfirmationDialog
      open={signOutDialogOpen}
      onClose={closeSignOut}
      title={t('SignOut.signOut')}
      cancelButtonText={t('SignOut.no')}
      targetButton={
        <Button color="error" onClick={signOut}>
          {t('SignOut.signOut')}
        </Button>
      }
    >
      {t('SignOut.really')}
    </ConfirmationDialog>
  );
};

import { customScrollbar, styled } from '~shared/lib/styles';

export const ViewerWalletTournamentsRoot = styled('div', { name: 'ViewerWalletTournaments' })(
  () => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',

    marginLeft: -16,
    marginRight: -16,
    paddingTop: 1,
    width: 'calc(100% + 32px)',

    overflowY: 'hidden',
  })
);

export const ViewerWalletTournamentsList = styled('div', {
  name: 'ViewerWalletTournaments',
  slot: 'list',
})(() => ({
  flex: 1,
  paddingLeft: 16,
  paddingRight: 16,

  ...customScrollbar,

  overflowY: 'scroll',
}));

import { Nft } from '~shared/api';

import { NftSortOrder } from './types';

export const getSortedNfts = (
  nfts: Array<Nft>,
  params: { raritySort: NftSortOrder | false; winStreakSort: NftSortOrder | false } = {
    raritySort: 'asc',
    winStreakSort: 'desc',
  }
) => {
  let sortedArray = nfts.sort((a, b) => Number(a.token_id) - Number(b.token_id));

  if (params.winStreakSort) {
    if (params.winStreakSort === 'asc') {
      sortedArray = sortedArray.sort((a, b) => a.winStreak - b.winStreak);
    } else {
      sortedArray = sortedArray.sort((a, b) => b.winStreak - a.winStreak);
    }
  }

  if (params.raritySort) {
    if (params.raritySort === 'asc') {
      sortedArray = sortedArray.sort((a, b) => a.rarity - b.rarity);
    } else {
      sortedArray = sortedArray.sort((a, b) => b.rarity - a.rarity);
    }
  }

  return sortedArray;
};

import { NFT_RARITY_TO_READABLE_RARITY_NAME_MAP } from '~entities/nft';

import { ViewerWalletNftRarityFilter } from '../model';

export const VIEWER_WALLET_CARDS_FILTERS: Array<{
  value: ViewerWalletNftRarityFilter;
  label: string;
}> = [
  {
    value: ViewerWalletNftRarityFilter.All,
    label: 'Tournaments.tabAll',
  },
  {
    value: ViewerWalletNftRarityFilter.Common,
    label: NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[ViewerWalletNftRarityFilter.Common],
  },
  {
    value: ViewerWalletNftRarityFilter.Rare,
    label: NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[ViewerWalletNftRarityFilter.Rare],
  },
  {
    value: ViewerWalletNftRarityFilter.Epic,
    label: NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[ViewerWalletNftRarityFilter.Epic],
  },
  {
    value: ViewerWalletNftRarityFilter.Legendary,
    label: NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[ViewerWalletNftRarityFilter.Legendary],
  },
  {
    value: ViewerWalletNftRarityFilter.Mythic,
    label: NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[ViewerWalletNftRarityFilter.Mythic],
  },
  {
    value: ViewerWalletNftRarityFilter.Demo,
    label: NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[ViewerWalletNftRarityFilter.Demo],
  },
];

import {
  CSSProperties,
  RefObject,
  SyntheticEvent,
  useCallback,
  useLayoutEffect,
  useState,
} from 'react';

import { sleep } from '~shared/lib/utils';
import { tabClasses } from '~shared/ui';

import { useResizeObserver } from './useResizeObserver';

export interface UseTabIndicatorReturn {
  position: CSSProperties;
  onChange: ChangeHandler;
  ref: RefObject<Element>;
}

type ChangeHandler = (event: SyntheticEvent | null, value: number | string | null) => void;

type UseTabIndicator = {
  (props: {
    selectedTabSelector?: string;
    tabSelector?: string;
    onChange?: ChangeHandler;
  }): UseTabIndicatorReturn;
};

export const useTabIndicator: UseTabIndicator = ({
  selectedTabSelector = `.${tabClasses.selected}`,
  tabSelector = `.${tabClasses.root}`,
  onChange,
}) => {
  const [position, setPosition] = useState<CSSProperties>({});
  const [valueHandler, setValueHandler] = useState<any>();
  const [ref] = useResizeObserver();

  const handler = useCallback(() => {
    if (ref.current) {
      const selectedTab = ref.current.querySelector(selectedTabSelector) as HTMLElement;

      if (selectedTab) {
        setPosition({
          width: selectedTab.clientWidth,
          transform: `translateX(${selectedTab.offsetLeft}px)`,
        });
      }
    }
  }, [ref, selectedTabSelector]);

  const handleChange: ChangeHandler = useCallback(
    (e, value) => {
      if (onChange) {
        onChange(e, value);
      }

      setValueHandler(value);
    },
    [onChange]
  );

  useLayoutEffect(() => {
    // todo: maybe better solution
    sleep(50).then(() => {
      handler();
    });
  }, [handler, valueHandler]);

  useLayoutEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver(handler);

      const itemsToObserve = Array.from(ref.current.querySelectorAll(tabSelector));

      for (const element of itemsToObserve) {
        observer.observe(element);
      }

      return () => observer.disconnect();
    }
  }, [handler, ref, tabSelector]);

  return { onChange: handleChange, position, ref };
};

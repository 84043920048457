export enum BattleView {
  List = 'list',
  Grid = 'grid',
}

export enum BattlePeriod {
  Past = 'past',
  Future = 'future',
}

export enum BattleEventTypeTab {
  Battles = 'battles',
  // Calls = 'calls',
}

export enum BattleEventTab {
  AllEvents = 'allEvents',
  MyEvents = 'myEvents',
}

import { FC } from 'react';

import { ApiCommonSportEnum } from '~shared/api';
import { Container, Stack } from '~shared/ui';

import { Announcements } from '~widgets/announcements';
import { CommonMultipleEventsList } from '~widgets/common-multiple-events-list';

export const EsportsPage: FC = () => {
  return (
    <Stack flex={1} spacing={12 / 8}>
      <Announcements />

      <Container flex={1} display="flex" flexDirection="column">
        <CommonMultipleEventsList
          label="Esports"
          sport={[
            ApiCommonSportEnum.Dota,
            ApiCommonSportEnum.CS2,
            ApiCommonSportEnum.LeagueOfLegends,
          ]}
        />
      </Container>
    </Stack>
  );
};

import { customScrollbar, styled } from '~shared/lib/styles';
import { Dialog, dialogClasses } from '~shared/ui';

export const TournamentDialogRoot = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    minHeight: 628,
    maxHeight: 800,
    transition: theme.transitions.create('background'),
    display: 'flex',
  },

  [`& .${dialogClasses.content}`]: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 30,

    backgroundColor: theme.palette.paper.secondary,
    backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/tournaments/tournament-bg.jpg'})`,
    backgroundPositionY: 'top',
    backgroundPositionX: 'center',
    backgroundSize: '100% auto',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'local',

    ...customScrollbar,
  },
}));

// todo: common mobile dialog
export const TournamentMobileDialogRoot = styled(Dialog)(({ theme }) => ({
  position: 'absolute',
  inset: 0,

  [`& .${dialogClasses.scrollPaper}`]: {
    overflowY: 'auto',

    ...customScrollbar,
  },

  [`& .${dialogClasses.paper}`]: {
    flex: 1,
    height: '100%',

    // overrides
    borderRadius: 0,
    margin: 0,
    maxWidth: 'unset',
    maxHeight: 'unset',
  },

  [`& .${dialogClasses.content}`]: {
    padding: '20px 12px',
    height: '100%',
    flex: 1,

    backgroundColor: theme.palette.paper.secondary,
    backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/tournaments/tournament-bg.jpg'})`,
    backgroundPositionY: 'top',
    backgroundPositionX: 'center',
    backgroundSize: 'auto 244px',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'local',
  },
}));

import { useWeb3React } from '@web3-react/core';

import { useIsAnyNftDialogOpen, useNftSelector } from '~entities/nft';
import { useViewerSelector } from '~entities/viewer';

import { useJoinTournamentModel } from '~features/tournament';

// TODO: rework to ton later
export const useViewerWalletSidebarModel = () => {
  const isConnected = !!useWeb3React().account;
  const isAnyNftDialogOpen = useIsAnyNftDialogOpen();
  const { wallet } = useViewerSelector();
  const { isDropZoneOpen } = useNftSelector();
  const { dialogOpen: joinTournamentDialogOpen } = useJoinTournamentModel();

  const isModalOverlap =
    isAnyNftDialogOpen || joinTournamentDialogOpen || (isDropZoneOpen && !isAnyNftDialogOpen);

  const isWalletBoundToEmail = Boolean(wallet);

  return {
    isConnected,
    isWalletBoundToEmail,
    isModalOverlap,
  };
};

import { ReactNode, createElement } from 'react';

import { ApiGetBetsBetMappedData } from '~shared/api';

import { useBattleAutoStyle } from '~entities/battle';
import { isEventCurrent, isEventNotStarted, isEventPassed } from '~entities/event';

import { BattleBet } from '../ui';

interface UseBetListProps {
  list?: Array<ApiGetBetsBetMappedData>;
  user?: {
    nickname: string;
    avatar?: string;
    address: string;
  };
}

interface UseBetList {
  (params: UseBetListProps): {
    list: Array<ReactNode>;
    passedList: Array<ReactNode>;
    currentList: Array<ReactNode>;
  };
}

export const useBetList: UseBetList = ({ list = [], user }) => {
  const style = useBattleAutoStyle('line');

  const renderBet = (bet: ApiGetBetsBetMappedData) => {
    return createElement(BattleBet, {
      ...bet,
      key: bet.eventId,
      user,
      ...style,
    });
  };

  return {
    list: list.map(renderBet),
    passedList: list.filter((bet) => isEventPassed(bet.result)).map(renderBet),
    currentList: list
      .filter((bet) => isEventCurrent(bet.date, bet.result) || isEventNotStarted(bet.date))
      .map(renderBet),
  };
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DailyQuestState } from './types';

const initialState: DailyQuestState = {
  isOpen: false,
  countedDays: 0,
  allDays: 0,
  rewardList: {},
  dailyQuest: null,
};

export const dailyQuestRewardSlice = createSlice({
  name: 'dailyQuestReward',
  initialState: initialState,
  reducers: {
    setToggle: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isOpen: action.payload,
    }),

    set: (state, action: PayloadAction<Partial<DailyQuestState>>) => ({
      ...state,
      ...action.payload,
    }),

    reset: () => {
      return { ...initialState };
    },
  },
});

export const dailyQuestRewardActions = dailyQuestRewardSlice.actions;

import { Address, Cell, toNano } from '@ton/core';
import { useTonAddress } from '@tonconnect/ui-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiGetAuctionCardMappedData } from '~shared/api';
import { useDispatch } from '~shared/lib/hooks';

import { useViewerModel } from '~entities/viewer';
import { useTonClient, useWriteContract } from '~entities/wallet';

import { auctionActions, getAuctionLotAddress, useAuctionSelector } from '~features/auction';
import { nftActions } from '~entities/nft';

import { MAX_HOT_OFFER_CARDS_AMOUNT } from './config';

export const useAuctionModel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const account = useTonAddress(true);
  const client = useTonClient();
  const { auctionCards, isLoading } = useAuctionSelector();
  const { tonAddress, avatar, nickname } = useViewerModel();

  const viewerAddress = tonAddress ? Address.normalize(tonAddress) : '';

  // const gaslessPlaceBidCall = useCallGasless<ApiPostPlaceBidToAuctionRequestData>({
  //   callback: postPlaceBidToAuction,
  //   transactionName: 'Place Bid',
  //   successMessage: `${t('Alerts.successfulBid')}`,
  //   errorMessage: `${t('Errors.errorBid')}`,
  // });

  // const gaslessPermitPlaceBidCall = useCallGasless<ApiPostPermitRequestData>({
  //   callback: postPermit,
  //   transactionName: 'Approving MCN',
  //   successMessage: `${t('Alerts.approveMCN')}`,
  //   errorMessage: `${t('Errors.approveMCN')}`,
  // });

  // MOCK

  const { write: placeBidTon } = useWriteContract({
    contractName: 'AuctionLot',
    method: 'PlaceBet',
    transactionName: 'Place Bid',
    successMessage: `${t('Alerts.successfulBid')}`,
    errorMessage: `${t('Errors.errorBid')}`,
  });

  const { write: placeBidMcn } = useWriteContract({
    contractName: 'MainToken',
    method: 'JettonTransfer',
    transactionName: 'Place Bid',
    successMessage: `${t('Alerts.successfulBid')}`,
    errorMessage: `${t('Errors.errorBid')}`,
  });

  const { write: takeCard } = useWriteContract({
    contractName: 'AuctionLot',
    method: 'CloseLot',
    transactionName: 'Take Card',
    successMessage: `${t('Alerts.takeCard')}`,
    errorMessage: `${t('Errors.errorTakeCard')}`,
  });

  const takeCardHandler = async (tokenId: string, lotId?: number) => {
    if (!account) {
      return;
    }

    await takeCard({
      args: {
        $$type: 'CloseLot',
      },
      value: toNano('0.2'),
      additionalArgs: {
        lotId,
      },
    });

    dispatch(nftActions.takeCardFromAuction(tokenId));
  };

  const handlePlaceBid = async (lotId: number, bet: number, currency: 'TON' | 'MCN') => {
    if (!account || !client) {
      return;
    }

    if (currency === 'TON') {
      await placeBidTon({
        args: {
          $$type: 'PlaceBet',
          bet: BigInt(bet),
        },
        value: toNano(bet + 0.2),
        additionalArgs: {
          lotId,
        },
      });
    } else {
      const lotAddress = await getAuctionLotAddress(client, lotId);

      await placeBidMcn({
        args: {
          $$type: 'JettonTransfer',
          query_id: BigInt(0),
          amount: toNano(bet),
          custom_payload: null,
          destination: lotAddress,
          response_destination: lotAddress,
          forward_ton_amount: toNano('0.3'),
          forward_payload: Cell.EMPTY,
        },
        value: toNano('0.5'),
      });
    }

    dispatch(
      auctionActions.setBidToLot({
        bet,
        bettor: tonAddress,
        bettorNicname: nickname,
        lotId,
        bettorAvatar: avatar?.src,
      })
    );
  };

  const timeNow = Date.now();

  const onOpenCardDetailedInfo =
    (nickname: string, avatarUrl: string, tokenId: string, creatorWalletAddress: string) => () => {
      dispatch(
        nftActions.setDetailedInfo({
          open: true,
          tokenId,
          nickname,
          avatarUrl,
          walletAddress: creatorWalletAddress,
          viewMode: true,
        })
      );
    };

  const auctionCardsMySales = useMemo(
    () =>
      auctionCards.filter((card) => {
        return viewerAddress === card.creator;
      }),
    [auctionCards, viewerAddress]
  );

  const auctionCardsMyBetsWithoutSort = useMemo(
    () =>
      auctionCards.filter((card) => {
        return viewerAddress === card.bestBettor;
      }),
    [auctionCards, viewerAddress]
  );

  const auctionCardsMyBets = useMemo(
    () =>
      auctionCardsMyBetsWithoutSort.sort(
        (a: any, b: any) => Date.parse(b.betsAcceptedUntil) - Date.parse(a.betsAcceptedUntil)
      ),
    [auctionCardsMyBetsWithoutSort]
  );

  const auctionCardsAll = useMemo(
    () =>
      auctionCards.filter((card) => {
        return card.betsAcceptedUntil > timeNow;
      }),

    [auctionCards, timeNow]
  );

  const hotOfferCards = useMemo(
    () =>
      auctionCardsAll
        .sort((a, b) => a.betsAcceptedUntil - b.betsAcceptedUntil)
        .filter((card) => card.creator !== viewerAddress)
        .slice(0, MAX_HOT_OFFER_CARDS_AMOUNT),
    [auctionCardsAll, viewerAddress]
  );

  const isHotOfferCard = (card: ApiGetAuctionCardMappedData) =>
    hotOfferCards.some((hotOfferCard) => hotOfferCard.tokenId === card.tokenId);

  return {
    isLoading,
    auctionCardsAll,
    auctionCardsMyBets,
    auctionCardsMySales,
    hotOfferCards,

    onOpenCardDetailedInfo,
    handlePlaceBid,
    takeCardHandler,
    isHotOfferCard,
  };
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LinearProgress, Stack } from '~shared/ui';

// todo: fix FSD `features -> entities` import collision
import { useAuctionAcceptBetTimer } from '~features/auction';

import { NftCardInfoStatusAuctionText } from './styled';
import { NftCardInfoStatusAuctionProps } from './types';

export const NftCardInfoStatusAuction: FC<NftCardInfoStatusAuctionProps> = ({
  betsAcceptedUntil,
  message,
  ...stackProps
}) => {
  const { t } = useTranslation();

  const { hoursLeft, minutesLeft, progress, accepted } =
    useAuctionAcceptBetTimer(betsAcceptedUntil);

  const blockedForAuctionMessage = message || t('Events.BlockedAuction');

  return (
    <Stack spacing={6 / 8} {...stackProps}>
      <NftCardInfoStatusAuctionText>
        {accepted
          ? `${blockedForAuctionMessage} ${hoursLeft}hr:${minutesLeft}min`
          : t('Marketplace.finished')}
      </NftCardInfoStatusAuctionText>
      {accepted && <LinearProgress progress={progress} progressColor="primary" height={4} />}
    </Stack>
  );
};

import { Address, OpenedContract, TonClient } from '@ton/ton';

import { Auction } from '~shared/contracts/tact_Auction';

export const getAuctionLotAddress = async (client: TonClient, lotId: number) => {
  const auctionContractInstance = new Auction(
    Address.parse(process.env.REACT_APP_ADDRESS_TON_AUCTION)
  );

  const auctionContract = client.open(auctionContractInstance) as OpenedContract<Auction>;

  return await auctionContract.getGetAuctionLotAddress(BigInt(lotId));
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Heading } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { TournamentsList } from '~widgets/tournaments-list';

export const TournamentsPage: FC = () => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();

  return (
    <Container flex={1} display="flex" flexDirection="column">
      <Heading
        variant="h1"
        fontSize={isMobile ? 20 : 34}
        lineHeight={isMobile ? 30 / 20 : 51 / 34}
        mb={isMobile ? 0 : 10 / 8}
        mt={isMobile ? -10 / 8 : 0}
        py={10 / 8}
      >
        {t('Other.tournaments')}
      </Heading>

      <TournamentsList />
    </Container>
  );
};

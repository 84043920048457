import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContentPlaceholder,
  HeadingTab,
  HeadingTabs,
  HeadingTabsList,
  Stack,
  TabsProps,
} from '~shared/ui';
import { useMediaQuery, useSearchParamsState } from '~shared/lib/hooks';

import { ProfileDialog } from '~widgets/profile-dialog';

import {
  RANKING_ALL_TIME_QUERY_PERIOD_TS,
  RANKING_TESTNET_QUERY_PERIOD_TS,
  useLeaderboardModel,
  useQueryLeaderboard,
} from '../model';

import { LeaderboardRowShimmer } from './LeaderboardRowShimmer';
import { LeaderboardTableHead } from './LeaderboardTableHead';
import { LeaderboardTableRow } from './LeaderboardTableRow';

export enum LeaderboardTab {
  Testnet = 'testnet',
  Total = 'main',
  CurrentWeek = 'currentWeek',
}

export const LeaderboardList = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();
  const [tab, setTab] = useSearchParamsState<LeaderboardTab>('period', LeaderboardTab.Testnet);

  const { getIsLeaderViewer, openProfileDialog, closeProfileDialog } = useLeaderboardModel();

  const { list, isLoading } = useQueryLeaderboard({
    isWeek: tab === LeaderboardTab.CurrentWeek,
  });

  const handleTabChange: TabsProps['onChange'] = (event, tab) => {
    setTab(tab as LeaderboardTab);
  };

  const hasLeaders = list.length !== 0;

  const renderLeaderboard = () => {
    switch (true) {
      // case true:
      //   return (
      //     <Stack height="100%" justifyContent="center" alignItems="center">
      //       <NotAvailable />
      //     </Stack>
      //   );

      case isLoading:
        return (
          <Stack spacing={14 / 8}>
            <LeaderboardTableHead />
            <LeaderboardShimmer />
          </Stack>
        );
      case hasLeaders:
        return (
          <Stack spacing={14 / 8}>
            <LeaderboardTableHead />

            {list.map((leader) => (
              <LeaderboardTableRow
                key={leader.place}
                leader={leader}
                isViewer={getIsLeaderViewer(leader)}
                isMobile={isMobile}
                onProfileDialogOpen={() => {
                  // console.log(getLeaderboardQueryPeriodTimestamp(tab));
                }}
                // onProfileDialogOpen={openProfileDialog(leader.walletAddress)}
              />
            ))}
          </Stack>
        );

      default:
        return <ContentPlaceholder heading={t('Other.oops')} message={t('Other.leaders')} />;
    }
  };

  return (
    <Fragment>
      <HeadingTabs gutters value={tab} onChange={handleTabChange}>
        <HeadingTabsList>
          <HeadingTab value={LeaderboardTab.Testnet}>Main</HeadingTab>
          {/*<HeadingTab value={LeaderboardTab.Total}>{t('Tabs.main')}</HeadingTab>*/}
          <HeadingTab value={LeaderboardTab.CurrentWeek}>{t('Tabs.currentWeek')}</HeadingTab>
        </HeadingTabsList>
      </HeadingTabs>

      {renderLeaderboard()}

      <ProfileDialog onClose={closeProfileDialog} />
    </Fragment>
  );
};

const LeaderboardShimmer = () => (
  <Stack mt={14 / 8} spacing={14 / 8}>
    {Array.from(Array(6)).map((_, key) => (
      <LeaderboardRowShimmer key={key} />
    ))}
  </Stack>
);

const getLeaderboardQueryPeriodTimestamp = (tab: LeaderboardTab) => {
  switch (tab) {
    case LeaderboardTab.Total:
      return RANKING_ALL_TIME_QUERY_PERIOD_TS;

    case LeaderboardTab.Testnet:
      return RANKING_ALL_TIME_QUERY_PERIOD_TS;

    case LeaderboardTab.CurrentWeek:
      return RANKING_TESTNET_QUERY_PERIOD_TS;

    default:
      return undefined;
  }
};

import { useTranslation } from 'react-i18next';

import { Button, Stack, Typography } from '~shared/ui';
import { useAuthModel } from '~widgets/auth';
import { useViewerModel } from '~entities/viewer';

export const AuthTelegram = () => {
  const { t } = useTranslation();

  const { tonSignIn, tonSignUp, onClose } = useAuthModel();
  const { fetchProfile, verification } = useViewerModel();

  const handleSuccessfulAuth = () => {
    fetchProfile().then(() => {
      verification();
      onClose();
    });
  };

  const handleTelegramAuth = () => {
    tonSignUp()
      .then(handleSuccessfulAuth)
      .catch((err) => {
        if (err?.response?.data?.result === 'user duplicate') {
          tonSignIn().then(handleSuccessfulAuth);
        }
      });
  };

  return (
    <Stack spacing={24 / 8}>
      <Typography variant="h2">{t('AuthOptions.logIn')}</Typography>
      <Stack spacing={8 / 8}>
        <Typography>1. {t('Telegram.getNotifiedAboutModeration')}</Typography>
        <Typography>2. {t('Telegram.leaveReviews')}</Typography>
        <Typography>3. {t('Telegram.receiveCatalogUpdates')}</Typography>
      </Stack>
      <Stack alignItems="center">
        <Button fullWidth onClick={handleTelegramAuth}>
          {t('AuthOptions.logIn')}
        </Button>
      </Stack>
      <Typography textAlign="center">
        {t('Telegram.noTelegram')}{' '}
        <a target="_blank" href="https://telegram.org" rel="noreferrer">
          {t('Telegram.website')}
        </a>
      </Typography>
    </Stack>
  );
};

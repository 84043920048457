import { FC } from 'react';

import { Box, BoxProps } from '~shared/ui';

export const TournamentPrizeContainer: FC<BoxProps> = ({ ...boxProps }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, min(58px))',
        gridTemplateRows: '1fr',
        gridAutoRows: '0px',
        overflow: 'hidden',
        columnGap: 24 / 8,
      }}
      {...boxProps}
    />
  );
};

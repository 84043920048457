import { Address } from '@ton/core';
import { useQuery } from 'react-query';
import { useTonAddress } from '@tonconnect/ui-react';

import { ApiCommonLeague, ApiCommonSport, getBattles } from '~shared/api';

import { useSuppliedNftBattleList } from './useSuppliedNftBattleList';

export const useBattlesListQuery = ({
  sport,
  league,
}: {
  sport: Array<ApiCommonSport>;
  league?: ApiCommonLeague;
}) => {
  const address = useTonAddress(true);

  const { data, isLoading } = useQuery(
    ['battles', sport, league, address],
    () =>
      getBattles({
        // TODO: remove this mock later
        sport: sport.filter((sp) => sp !== 'tennis'),
        league,
        address: address && address.length !== 0 ? Address.normalize(address) : undefined,
      }),
    {
      // refetchInterval: 5000,
      staleTime: STALE_TIME,
    }
  );

  const { auditionedList } = useSuppliedNftBattleList(data ?? []);

  return {
    list: auditionedList || [],
    isEmptyList: isLoading ? false : data?.length === 0,
    isLoading,
  };
};

const STALE_TIME = 5 * 60 * 1000;
